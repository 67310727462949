import {
  hoursToMilliseconds,
  millisecondsToHours,
  millisecondsToMinutes,
  millisecondsToSeconds,
} from 'date-fns';
import { FocusTimerStatus } from './types';
import { FocusTimerType } from './FocusSettingsModal';

export const formatTimer = (timeLeft: number) => {
  if (millisecondsToSeconds(timeLeft) < 60) {
    return `${millisecondsToSeconds(timeLeft)}s`;
  } else if (millisecondsToMinutes(timeLeft) >= 1 && millisecondsToMinutes(timeLeft) < 60) {
    return `${millisecondsToMinutes(timeLeft)}m`;
  } else if (millisecondsToHours(timeLeft) >= 1) {
    return `${millisecondsToHours(timeLeft)}h ${millisecondsToMinutes(
      timeLeft - hoursToMilliseconds(millisecondsToHours(timeLeft)),
    )}m`;
  }
  return '0s';
};

export const getSpendTime = (
  startTime?: number,
  resumeTime?: number | null,
  fixedPeriod?: number | null,
  previousSpendTime?: number | null,
  focusTimerStatus?: FocusTimerStatus | null,
  focusTimerType?: FocusTimerType | null,
) => {
  const fullFixedPeriod = fixedPeriod ? fixedPeriod * 1000 : 1.8e6;
  const time = Date.now();
  const maxOpenTimer = 8.64e7;
  let spendTime: number;
  if (!startTime) {
    return 0;
  }
  if (previousSpendTime) {
    if (resumeTime) {
      spendTime = time - resumeTime * 1000 + previousSpendTime * 1000;
    } else {
      spendTime = previousSpendTime * 1000;
    }
  } else {
    spendTime = time - startTime * 1000;
  }

  if (focusTimerStatus === FocusTimerStatus.PAUSED && previousSpendTime) {
    spendTime = previousSpendTime * 1000;
  }

  if (spendTime > fullFixedPeriod && focusTimerType !== FocusTimerType.OPEN) {
    spendTime = fullFixedPeriod;
  } else if (focusTimerType === FocusTimerType.OPEN && spendTime > maxOpenTimer) {
    spendTime = maxOpenTimer;
  } else if (spendTime < 0) {
    spendTime = 0;
  }

  return spendTime;
};
