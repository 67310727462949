import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client';
import { tutorialVar } from 'apollo/stateFields/tutorial/tutorialField';
import { completeVar } from './stateFields/complete/completeFields';
import { dndVar } from './stateFields/dnd/dndFields';
import { manageWorkspacesVar } from './stateFields/manageWorkspaces/manageWorkspacesField';
import { userSettingsVar } from './stateFields/userSettings/userSettingsField';
import { authVar } from './stateFields/auth/authFields';
import { boardEditedEntityVar } from './stateFields/boardEditedEntity/boardEditedEntityFileds';
import { searchBarVar } from './stateFields/searchBar/searchBarFileds';
import { errorVar } from './stateFields/error/errorFields';
import { boardFiltersVar } from './stateFields/boardFilters/boardFiltersFields';
import { coursesVar } from './stateFields/courses/coursesFields';
import { utilsVar } from './stateFields/utils/utilsFields';
import { filterInputVar } from './stateFields/filterInput/filterInputFields';
import { recommendVar } from './stateFields/recommend/recommendFields';
import { stripeVar } from './stateFields/stripe/stripeFields';
import { CoursesSettings, StripePricesResponse } from '../graphql/types';
import { highlightedEntitiesVar } from './stateFields/highlightedEnntities/highlightedEntityField';


const cache: InMemoryCache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case 'AcceptInviteResult':
        return `AcceptInviteResult:${responseObject.workspaceId}:${responseObject.email}:${responseObject.id}`;
      case 'Workspace':
        if (responseObject.createDate) {
          return `Workspace:${responseObject.id}:${responseObject.createDate}`;
        } else {
          return defaultDataIdFromObject(responseObject);
        }
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
  typePolicies: {
    Query: {
      fields: {
        fetchAndFilterUserWorkspacesWithEntities: {
          keyArgs: false,
        },
        fetchUserWorkspacesWithEntities: {
          keyArgs: false,
        },
        searchUserWorkspacesEntities: {
          keyArgs: false,
          merge(existing, incoming, { args }) {
            if (
              !existing ||
              !args!.offset ||
              (!incoming.action.count && !incoming.note.count && !incoming.outcome.count)
            ) {
              return incoming;
            }

            return {
              ...incoming,
              action: {
                ...incoming.action,
                count: incoming.action.count,
                data: [...existing.action.data, ...incoming.action.data],
              },
              note: {
                ...incoming.note,
                count: incoming.note.count,
                data: [...existing.note.data, ...incoming.note.data],
              },
              outcome: {
                ...incoming.outcome,
                count: incoming.outcome.count,
                data: [...existing.outcome.data, ...incoming.outcome.data],
              },
            };
          },
        },
        // fetchUserSettings: {
        //   merge(existing, incoming, { args }) {
        //     console.log(existing, incoming);
        //     return incoming;
        //   },
        // },
        fetchUserSettings: {
          read(data: CoursesSettings = {} as CoursesSettings, { readField }) {
            if (!data) {
              return data;
            }
            const foundationMasterClassIds = data?.coursesConsts?.Foundations?.videos
              .slice()
              .filter((video) => {
                // @ts-ignore
                const cachedVideo = readField('name', video);
                // @ts-ignore
                return cachedVideo?.indexOf('Masterclass') !== -1;
              })
              .map((video) => {
                // @ts-ignore
                const cachedId = readField('id', video);
                // @ts-ignore
                return cachedId;
              });
            const remoteMasterClassIds = data?.coursesConsts?.MasteringPersonalEffectiveness?.videos
              .slice()
              .filter((video) => {
                // @ts-ignore
                const cachedVideo = readField('name', video);
                // @ts-ignore
                return cachedVideo?.indexOf('Masterclass') !== -1;
              })
              .map((video) => {
                // @ts-ignore
                const cachedId = readField('id', video);
                // @ts-ignore
                return cachedId;
              });

            const lastWatchedFoundationId = foundationMasterClassIds?.some(
              (id) => id === data?.coursesVars?.Foundations?.lastWatchedId,
            )
              ? // @ts-ignore
                data?.coursesVars?.Foundations?.lastWatchedId - 1
              : data?.coursesVars?.Foundations?.lastWatchedId;

            const lastWatchedRemoteId = remoteMasterClassIds?.some(
              (id) => id === data?.coursesVars?.MasteringPersonalEffectiveness?.lastWatchedId,
            )
              ? // @ts-ignore
                data?.coursesVars?.MasteringPersonalEffectiveness?.lastWatchedId - 1
              : data?.coursesVars?.MasteringPersonalEffectiveness?.lastWatchedId;

            const newData = {
              ...data,
              coursesVars: {
                ...data.coursesVars,
                Foundations: {
                  ...data.coursesVars?.Foundations,
                  lastWatchedId: lastWatchedFoundationId,
                },
                MasteringPersonalEffectiveness: {
                  ...data?.coursesVars?.MasteringPersonalEffectiveness,
                  lastWatchedId: lastWatchedRemoteId,
                },
              },
            };

            return { ...newData };
          },
        },
        fetchStripePrices: {
          read(data: StripePricesResponse[] = []) {
            if (!data) {
              return data;
            }
            return data?.map((item) => {
              return {
                ...item,
                metadata: JSON.parse(item.metadata),
              };
            });
          },
        },
        tutorial: {
          read() {
            return tutorialVar();
          },
        },
        complete: {
          read() {
            return completeVar();
          },
        },
        manageWorkspaces: {
          read() {
            return manageWorkspacesVar();
          },
        },
        dnd: {
          read() {
            return dndVar();
          },
        },
        userSettingsField: {
          read() {
            return userSettingsVar();
          },
        },
        auth: {
          read() {
            return authVar();
          },
        },
        boardEditedEntity: {
          read() {
            return boardEditedEntityVar();
          },
        },
        searchBar: {
          read() {
            return searchBarVar();
          },
        },
        error: {
          read() {
            return errorVar();
          },
        },
        boardFilters: {
          read() {
            return boardFiltersVar();
          },
        },
        courses: {
          read() {
            return coursesVar();
          },
        },
        utils: {
          read() {
            return utilsVar();
          },
        },
        filterInput: {
          read() {
            return filterInputVar();
          },
        },
        recommend: {
          read() {
            return recommendVar();
          },
        },
        stripe: {
          read() {
            return stripeVar();
          },
        },
        highlightedEntities: {
          read() {
            return highlightedEntitiesVar();
          },
        },
      },
    },
  },
});

export { cache };
