import React from 'react';
import { DatePicker, DatePickerProps } from 'formik-antd';
import { Tooltip } from 'antd';
import { createGlobalStyle } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import theme from '../../../styles/styled-components/theme';

const Style = createGlobalStyle`
  .ant-picker-cell-disabled {
    &::before {
      background-color: #fff;
    }

    .ant-picker-cell-inner {
      cursor: default;
      pointer-events: auto;
    }

    &.ant-picker-cell-today {
      .ant-picker-cell-inner {
        &::before {
          z-index: -1;
        }
      }
    }

    &:hover {
      .ant-picker-cell-inner {
        background: rgba(255, 123, 118, 0.1) !important;
        border: 1px solid var(--color-red);
        color: var(--color-red);

        &::before {
          border: 0;
        }
      }
    }
  }
`;

const DatePickerField = ({
  disabledDateTooltip,
  ...datePickerProps
}: DatePickerProps & { showToday: boolean; disabledDateTooltip: string; }) => {
  const isTouchDevice = useMediaQuery({ query: theme.device.tablet.max });
  return (
    <div>
      <Style />
      <DatePicker
        {...datePickerProps}
        inputReadOnly={isTouchDevice}
        dateRender={(current) => {
          return (
            <div className="ant-picker-cell-inner">
              {datePickerProps.disabledDate && datePickerProps.disabledDate(current) ? (
                <Tooltip
                  color="#fff"
                  title={() => (
                    <span style={{ color: 'var(--color-dark-blue)' }}>{disabledDateTooltip}</span>
                  )}
                >
                  {current.date()}
                </Tooltip>
              ) : (
                current.date()
              )}
            </div>
          );
        }}
      />
    </div>
  );
};

export { DatePickerField };
