import { checkIsShownEntity } from './checkIsShownEntity';
import { getCacheEntityById } from './getCacheEntityById';
import { updatedCacheData } from './updatedCacheData';
import { updateWorkspaceTagsData } from './updateWorkspaceTagsData';
import { parseJwt } from './parseJwt';

export type Filter = {
  workspaceId: number;
  tagIds: number[];
};

export enum OperationType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  ARCHIVE = 'ARCHIVE',
}

export enum EntityType {
  ACTION = 'ACTION',
  OUTCOME = 'OUTCOME',
  NOTE = 'NOTE',
  WORKSPACE = 'WORKSPACE',
}

export enum EntityName {
  ACTION = 'Action',
  OUTCOME = 'Outcome',
  NOTE = 'Incoming note',
  WORKSPACE = 'Workspace',
}

export {
  getCacheEntityById,
  updatedCacheData,
  checkIsShownEntity,
  updateWorkspaceTagsData,
  parseJwt,
};
