import React from 'react';
import styled, { css } from 'styled-components';

import { GroupBoxProps } from './types';

const StyledContainer = styled.div<Pick<GroupBoxProps, 'withBorder'>>`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;

  ${({ withBorder }) => withBorder && css`
    border-bottom: 1px solid #20263520;
  `}
`;

const StyledTitle = styled.span<Pick<GroupBoxProps, 'titleInBold'>>`
  margin-bottom: 12px;

  ${({ titleInBold }) => titleInBold && css`
    font-weight: 700;
  `}
`;

const StyledContent = styled.div``;

const GroupBox = ({
  className,
  title,
  children,
  titleInBold = true,
  withBorder = true,
}: GroupBoxProps) => (
  <StyledContainer className={className} withBorder={withBorder}>
    {title && (
      <StyledTitle titleInBold={titleInBold}>
        {title}
      </StyledTitle>
    )}
    <StyledContent>
      {children}
    </StyledContent>
  </StyledContainer>
);

export { GroupBox };
