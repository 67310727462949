import * as routePaths from 'core/constants/routePaths';
import { PageLinkProps } from '../PageView/types';

const userProfileLinks: PageLinkProps[] = [
  {
    text: 'User Profile',
    to: routePaths.USER_PROFILE_PATH,
    isVisibleForLogged: true,
    isVisibleForUnLogged: false,
  },
  {
    text: 'Available plans',
    to: routePaths.USER_PLANS_PATH,
    isVisibleForLogged: true,
    isVisibleForUnLogged: false,
  },
];

export default userProfileLinks;
