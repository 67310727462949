import React from 'react';
import {
  StyledCursiveTipsText,
  StyledTipsHeader,
  StyledTipsText,
  StyledTipsTitle,
} from '../StyledComponents';

const CoreSkillsTips = () => {
  return (
    <div>
      <StyledTipsHeader>Hacks, Tips & Tricks - Core Skills</StyledTipsHeader>
      <StyledTipsText>
        Personal effectiveness is <StyledCursiveTipsText>personal</StyledCursiveTipsText>, so
        BillionMinds tends to steer clear of recommending specific life hacks that may not work for
        the individual. However, we do provide a small number, which have been shown to be effective
        across a large proportion of our users. Try these, and let us know what works and what
        doesn’t!
      </StyledTipsText>
      <StyledTipsTitle>Early Morning Brisk Walk</StyledTipsTitle>
      <StyledTipsText>
        Do this within 2 hours of waking for at least 15 mins, and walk fast enough so you can talk
        but you cannot sing. It helps you sleep, and lowers your risk of a bunch of different
        diseases.
      </StyledTipsText>
      <StyledTipsTitle>Put your phone face down or out of sight</StyledTipsTitle>
      <StyledTipsText>
        Just the presence of a phone within eyeshot can distract you. So when it’s time to focus,
        either at work, or on others outside of work, put your phone in a draw or a bag, or as a
        minimum, face down on the table.
      </StyledTipsText>
      <StyledTipsTitle>Take breaks before your brain does</StyledTipsTitle>
      <StyledTipsText>
        Take a short break (2-5 minutes) every 30 minutes or so, and a longer one (min 20 minutes)
        every 2 hours or so. This has been shown to increase sustained performance.
      </StyledTipsText>
      <StyledTipsTitle>Change WHERE you work</StyledTipsTitle>
      <StyledTipsText>
        If you are struggling for motivation, a simple change of scenery can help. Try a coffee
        shop, a library, or even a different room in the house.
      </StyledTipsText>
      <StyledTipsTitle>Create a consistent night time routine</StyledTipsTitle>
      <StyledTipsText>
        Try to establish a consistent routine starting at least 90 minutes before bedtime, and
        aspire to be in bed at the same time each night (including weekends). It leads to better
        sleep quality, and more refreshment the next day.
      </StyledTipsText>
      <StyledTipsTitle>Put your phone to bed before you go to bed yourself</StyledTipsTitle>
      <StyledTipsText>
        Adding this to your routine can help your brain disconnect from your phone, helping you shut
        down, and may even improve sleep quality.
      </StyledTipsText>
      <StyledTipsTitle>Create accountability by sharing your journey with others</StyledTipsTitle>
      <StyledTipsText>
        You can simply tell others about your BillionMinds journey, or nominate them to join you on
        it using the “Invite a Friend” button in this tool.
      </StyledTipsText>
    </div>
  );
};

export default CoreSkillsTips;
