import { gql } from '@apollo/client';
import { ACTION_FIELDS } from '../../actions/graphql/fragments';
import { OUTCOME_FIELDS } from '../../outcomes/graphql/fragments';
import { TAG_FIELDS } from './fragments';

export const FETCH_USER_WORKSPACES_WITH_ENTITIES = gql`
  query FetchUserWorkspacesWithEntities {
    fetchUserWorkspacesWithEntities {
      id
      name
      description
      permission
      isNewShared
      createDate
      actions {
        id
        name
        status
      }
      outcomes {
        id
        name
      }
      notes {
        id
        name
      }
      tags {
        id
        name
      }
    }
  }
`;

export const FETCH_AND_FILTER_WORKSPACES_WITH_ENTITIES = gql`
  query FetchAndFilterUserWorkspacesWithEntities($filters: [FilterUserWorkspacesInput]!) {
    fetchAndFilterUserWorkspacesWithEntities(filters: $filters) {
      all {
        id
        name
        description
        permission
        isNewShared
        createDate
        actions {
          id
          name
          status
        }
        outcomes {
          id
          name
        }
        notes {
          id
          name
        }
        tags {
          id
          name
        }
      }
      filter {
        id
        name
        permission
        isNewShared
        actions {
          ...actionFields
        }
        outcomes {
          ...outcomeFields
        }
        tags {
          ...tagFields
        }
      }
    }
  }
  ${ACTION_FIELDS}
  ${OUTCOME_FIELDS}
  ${TAG_FIELDS}
`;

export const FETCH_USER_WORKSPACES_WITH_INVITED_COUNT = gql`
  query FetchUserWorkspacesWithInvitedCount {
    fetchUserWorkspacesWithInvitedCount {
      id
      name
      description
      permission
      usersCount
      isNewShared
    }
  }
`;

export const FETCH_USER_WORKSPACE = gql`
  query FetchUserWorkspace($workspaceId: Int = -1) {
    fetchUserWorkspace(workspaceId: $workspaceId) {
      id
      name
    }
  }
`;

export const SEARCH_USER_WORKSPACE_TAGS = gql`
  query SearchUserWorkspaceTags($workspaceId: Int = -1, $query: String = "") {
    searchUserWorkspaceTags(workspaceId: $workspaceId, query: $query) {
      id
      name
      workspaceId
      createDate
      updateDate
      actionId
    }
  }
`;

export const FETCH_USER_WORKSPACE_TAGS = gql`
  query FetchUserWorkspaceTags($workspaceId: Int = -1) {
    fetchUserWorkspaceTags(workspaceId: $workspaceId) {
      id
      name
      workspaceId
      createDate
      updateDate
      actionId
    }
  }
`;

export const SEARCH_USER_WORKSPACES = gql`
  query SearchUserWorkspaces($query: String = "") {
    searchUserWorkspaces(query: $query) {
      id
      name
    }
  }
`;
