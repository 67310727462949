import React, { useEffect, useRef } from 'react';
import { ModalToDrawerTransformerProps } from './types';
import { useMediaQuery } from 'react-responsive';
import { theme } from '../../../styles/styled-components';
import styled from 'styled-components';
import { Drawer } from 'antd';

const StyledDrawer = styled(Drawer)`
  top: 0 !important;
`;

const ModalToDrawerTransformer = ({
  isOpened, onClose, children,
  breakpointFrom = theme.size.tablet,
  breakpointTo = theme.size.desktop,
  modalComponent, clickOutside,
  width = 384,
  closable = false,
}: ModalToDrawerTransformerProps) => {
  const isMinWidth = useMediaQuery({ query: `(max-width: ${breakpointTo}px)` });
  const isMaxWidth = useMediaQuery({ query: `(min-width: ${breakpointFrom}px)` });
  const openedData = useRef(false);

  const isDrawer = isMinWidth && isMaxWidth;

  useEffect(() => {
    if (isDrawer && isOpened && openedData.current) {
      onClose();
    }
    openedData.current = isOpened;
  }, [clickOutside, isOpened]);

  const drawerEl = (
    <StyledDrawer
      getContainer={() => document.body}
      visible={isOpened}
      placement="right"
      closable={closable}
      onClose={onClose}
      width="100%"
      contentWrapperStyle={{
        maxWidth: width,
        boxShadow: 'none',
      }}
      bodyStyle={{
        padding: 0,
        backgroundColor: 'var(--color-main-grey-2)',
      }}
    >
      {children}
    </StyledDrawer>
  );

  const renderModal = () => {
    if (modalComponent && isOpened) {
      return React.cloneElement(modalComponent, {}, children);
    } else if (children && isOpened) {
      return children;
    }

    return null;
  }

  return (
    isDrawer
    ? drawerEl
    : renderModal()
  );
}

export default ModalToDrawerTransformer;