import { gql, makeVar } from '@apollo/client';

export type ModalSetting = null | true | false;
export type EntityType = 'Action' | 'Outcome';

export interface UserSettingsValue {
  defaultWorkspaceId: number;
  defaultWorkspaceName: string;
  startDefaultWorkspaceId: number;
  shouldUpdateStatus: ModalSetting;
  shouldUpdateFilterInput: ModalSetting;
  shouldUpdateStartDate: ModalSetting;
  shouldActionLinkOutcome: ModalSetting;
  shouldOutcomeLinkAction: ModalSetting;
  isEditing: boolean;
  isSettingsLoaded: boolean;
  unassignedWorkspaceId: number;
}

export const userSettingsValueInitialValue: UserSettingsValue = {
  defaultWorkspaceId: -1,
  defaultWorkspaceName: '',
  startDefaultWorkspaceId: -1,
  shouldUpdateStatus: null,
  shouldUpdateFilterInput: null,
  shouldActionLinkOutcome: null,
  shouldOutcomeLinkAction: null,
  shouldUpdateStartDate: null,
  isEditing: false,
  isSettingsLoaded: false,
  unassignedWorkspaceId: 0,
};

export const userSettingsVar = makeVar<UserSettingsValue>(userSettingsValueInitialValue);
export const USER_SETTINGS_STATE = gql`
  query userSettingsField {
    userSettingsField @client
  }
`;
