import { CompleteOutcomeModal } from './CompleteOutcomeModal';
import React, { useCallback, useEffect, useMemo } from 'react';
import { CurrentOutcomeModal } from './CurrentOutcomeModal';
import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { FetchOutcome, UpdateOutcome } from '../../../outcomes/types';
import { UPDATE_OUTCOME } from '../../../outcomes/graphql/mutations';
import { getUpdateFuncForUpdateOutcome } from '../../../interactions/outcome/getUpdateFuncForUpdateOutcome';
import { Outcome, OutcomeStatus } from '../../../../core/types/outcome';
import { sendEvent } from '../../../../core/integrations/sentry/events';
import { UpdateUserSettings } from '../../../../graphql/types';
import { UPDATE_SETTINGS } from '../../../../graphql/mutations';
import { updateOutcomeByStatus } from '../../../../core/utils/outcome';
import { omit } from 'lodash-es';
import { EntityType, getCacheEntityById } from '../../../utils';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../../apollo/stateFields/filterInput/filterInputFields';
import { filterMutation } from '../../../../apollo/stateFields/filterInput';
import { completeMutation } from '../../../../apollo/stateFields/complete';
import {
  COMPLETE_STATE,
  CompleteValue,
} from '../../../../apollo/stateFields/complete/completeFields';
import { FETCH_OUTCOME } from '../../../outcomes/graphql/queries';

const CompleteOutcomeManager = React.memo(() => {
  const apolloClient = useApolloClient();
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const { setUpdateFilterInputId } = filterMutation;
  const { data: completeData } = useQuery(COMPLETE_STATE);
  const {
    shouldUpdateStatusToComplete,
    shouldUpdateStatusToCurrent,
    outcomeToComplete,
    outcomeToIncomplete,
  }: CompleteValue = completeData?.complete;
  const { setOutcomeToComplete, setOutcomeToIncomplete } = completeMutation;
  const [getOutcome, { data: outcome, error: outcomeError, loading: outcomeLoading }] =
    useLazyQuery<FetchOutcome>(FETCH_OUTCOME, { fetchPolicy: 'no-cache' });

  const [updateSettings] = useMutation<UpdateUserSettings>(UPDATE_SETTINGS);
  const [updateOutcomeMutation, { loading: mutateLoading }] =
    useMutation<UpdateOutcome>(UPDATE_OUTCOME);
  const dataForUpdateFunction = useMemo(() => {
    return {
      filterInput,
      apolloClient,
      setOutcomeToComplete,
      setOutcomeToIncomplete,
      setUpdateFilterInputId,
    };
  }, [filterInput, apolloClient]);

  const updateOutcome = useCallback(
    async (status: OutcomeStatus, outcomeValue: Outcome) => {
      let outcome = getCacheEntityById(
        apolloClient,
        outcomeValue.id,
        EntityType.OUTCOME,
        filterInput,
      ) as Outcome;
      if (!outcome) {
        const fetchingOutcome = await getOutcome({
          variables: {
            outcomeId: outcomeValue.id,
            workspaceId: outcomeValue.workspaceId,
          },
        });
        if (fetchingOutcome.data?.fetchUserWorkspaceOutcome) {
          outcome = fetchingOutcome.data?.fetchUserWorkspaceOutcome;
        }
      }
      let outcomeValues = updateOutcomeByStatus(
        outcome,
        outcome.status as OutcomeStatus,
        status,
      ) as Outcome & {
        actionIds: number[];
        tagIds: number[];
      };
      const actionIds = outcomeValues.actions?.map((action) => action.id) || [];
      const tagIds = outcomeValues.tags?.map((tag) => tag.id) || [];
      outcomeValues.actionIds = actionIds;
      outcomeValues.tagIds = tagIds;
      outcomeValues = omit(outcomeValues, '__typename', 'isAllActionsDone', 'actions', 'tags');
      if (outcome) {
        await updateOutcomeMutation({
          update: getUpdateFuncForUpdateOutcome(outcome, dataForUpdateFunction),
          variables: {
            workspaceId: outcome.workspaceId,
            outcomeValues,
          },
        });
      }
    },
    [updateOutcomeMutation, apolloClient, filterInput],
  );

  const checkSaveSettings = useCallback(
    async (shouldUpdateStatusToComplete?: boolean, shouldUpdateStatusToCurrent?: boolean) => {
      if (shouldUpdateStatusToComplete === false || shouldUpdateStatusToComplete === true) {
        await updateSettings({
          variables: {
            settings: {
              shouldUpdateStatusToComplete: shouldUpdateStatusToComplete,
            },
          },
        });
      } else if (shouldUpdateStatusToCurrent === false || shouldUpdateStatusToCurrent === true) {
        await updateSettings({
          variables: {
            settings: {
              shouldUpdateStatusToCurrent: shouldUpdateStatusToCurrent,
            },
          },
        });
      }
      return;
    },
    [shouldUpdateStatusToComplete, shouldUpdateStatusToCurrent, filterInput, updateSettings],
  );

  const onCancel = useCallback(
    async (shouldUpdateStatusToComplete?: boolean, shouldUpdateStatusToCurrent?: boolean) => {
      await checkSaveSettings(shouldUpdateStatusToComplete, shouldUpdateStatusToCurrent);
      outcomeToComplete && sendEvent('outcome_cancel_autocomplete', 'Cancel outcome autocomplete');
      outcomeToIncomplete && sendEvent('outcome_cancel_autocurrent', 'Cancel outcome autocurrent');
      setOutcomeToComplete(null);
      setOutcomeToIncomplete(null);
    },
    [checkSaveSettings, outcomeToComplete, outcomeToIncomplete],
  );

  const onOk = useCallback(
    async (shouldUpdateStatusToComplete?: boolean, shouldUpdateStatusToCurrent?: boolean) => {
      await checkSaveSettings(shouldUpdateStatusToComplete, shouldUpdateStatusToCurrent);
      outcomeToComplete &&
        sendEvent('outcome_confirm_autocomplete', 'Outcome confirm autocomplete');
      outcomeToIncomplete &&
        sendEvent('outcome_confirm_autocurrent', 'Outcome confirm autocurrent');
      if (outcomeToComplete) {
        await updateOutcome(OutcomeStatus.COMPLETED, outcomeToComplete);
        setOutcomeToComplete(null);
        return;
      }
      if (outcomeToIncomplete) {
        await updateOutcome(OutcomeStatus.CURRENT, outcomeToIncomplete);
        setOutcomeToIncomplete(null);
        return;
      }
    },
    [updateOutcome, outcomeToComplete, outcomeToIncomplete, checkSaveSettings],
  );

  useEffect(() => {
    if (outcomeToComplete && shouldUpdateStatusToComplete) {
      updateOutcome(OutcomeStatus.COMPLETED, outcomeToComplete).then(() => {
        setOutcomeToComplete(null);
      });
      return;
    }
    if (outcomeToIncomplete && shouldUpdateStatusToCurrent) {
      updateOutcome(OutcomeStatus.CURRENT, outcomeToIncomplete).then(() => {
        setOutcomeToIncomplete(null);
      });
      return;
    }
  }, [outcomeToComplete, outcomeToIncomplete]);

  return (
    <>
      {outcomeToComplete && shouldUpdateStatusToComplete === null && (
        <CompleteOutcomeModal
          onOkClick={onOk}
          onCancelClick={onCancel}
          outcome={outcomeToComplete}
          loading={outcomeLoading || mutateLoading}
        />
      )}
      {outcomeToIncomplete && shouldUpdateStatusToCurrent === null && (
        <CurrentOutcomeModal
          onOkClick={onOk}
          onCancelClick={onCancel}
          outcome={outcomeToIncomplete}
          loading={outcomeLoading || mutateLoading}
        />
      )}
    </>
  );
});

export { CompleteOutcomeManager };
