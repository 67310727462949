export const ROOT_PATH = '/';

export const SIGN_IN_PATH = `${ROOT_PATH}sign-in`;
export const SIGN_UP_PATH = `${ROOT_PATH}sign-up`;
export const ACCESS_SIGN_UP_PATH = `${ROOT_PATH}RWPSignup`;
export const PASSWORD_RECOVERY_PATH = `${ROOT_PATH}password-recovery`;
export const SET_NEW_PASSWORD_PATH = `${ROOT_PATH}set-new-password`;
export const SET_EMAIL_PATH = `${ROOT_PATH}set-email`;
export const LINKEDIN_REDIRECT_PATH = `${ROOT_PATH}auth/linkedin/callback`;

export const PAGE_PATH = `${ROOT_PATH}pages`;
export const CONTACT_US_PATH = `${PAGE_PATH}/contact-us`;
export const FAQ_PATH = `${PAGE_PATH}/faq`;

export const USER_PROFILE_PATH = `${ROOT_PATH}user-profile`;
export const USER_PLANS_PATH = `${ROOT_PATH}available-plans`;

export const ONBOARDING_PATH = `${PAGE_PATH}/onboarding`;
export const ADMIN_PANEL_PATH = `${PAGE_PATH}/admin-panel`;

export const ACTION_PATH = `${ROOT_PATH}actions`;
export const OUTCOME_PATH = `${ROOT_PATH}outcomes`;
export const OBJECTIVE_PATH = `${ROOT_PATH}objectives`;
export const PEOPLE_PATH = `${ROOT_PATH}people`;
