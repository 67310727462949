import { gql, makeVar } from '@apollo/client';

export interface RecommendValue {
  showRecommendPopup: boolean;
  shouldRemind: boolean;
  remindDate: Date | string | undefined | null;
  withAdditionalBtns: boolean;
  isAlreadyReminded: boolean;
}

export const recommendInitialValue: RecommendValue = {
  showRecommendPopup: false,
  shouldRemind: false,
  remindDate: undefined,
  withAdditionalBtns: false,
  isAlreadyReminded: false,
};

export const recommendVar = makeVar<RecommendValue>(recommendInitialValue);
export const RECOMMEND_STATE = gql`
  query Recommend {
    recommend @client
  }
`;
