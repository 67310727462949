import styled from 'styled-components'

import theme from 'core/styles/styled-components/theme';

const Container = styled.div`
  margin: 0 auto;
  padding: 20px;

  @media ${theme.device.mobile.min} {
    max-width: 400px;
  }

  @media ${theme.device.tablet.min} {
    max-width: 640px;
    padding: 20px 0;
  }

  @media ${theme.device.desktop.min} {
    max-width: 800px;
  }
`

export default Container
