import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Radio, Switch } from 'antd';
import { Header } from '../../core/components/layout';
import { theme } from '../../core/styles/styled-components';
import { useMediaQuery } from 'react-responsive';
import { PageNavigationBar } from '../PageView/PageNavigationBar';
import userProfileLinks from '../UserProfile/userProfileLinks';
import {
  StyledContainer,
  StyledContentContainer,
} from '../CommonStyledComponents/CommonStyledComponents';
import styled from 'styled-components';
import {
  PLANS_TYPES,
  STRIPE_STATE,
  StripeValue,
} from '../../apollo/stateFields/stripe/stripeFields';
import { stripeMutation } from '../../apollo/stateFields/stripe';
import AvailablePlanCard from './AvailablePlanCard/AvailablePlanCard';
import { FETCH_STRIPE_PRICES } from '../../graphql/queries';
import {
  BmSubscriptionTypes,
  FetchStripePrices,
  StripeSubscriptionIntervals, UserSubscriptionInfo,
} from '../../graphql/types';
import { getFilteredDataByTypeAndInterval } from '../utils';
import { Loader } from '../../core/components/common';
import StripeDrawer from './StripeDrawer/StripeDrawer';
import { INIT_STRIPE_CUSTOMER } from '../../graphql/mutations';
import PurchaseDrawer from './PurchaseDrawer/PurchaseDrawer';

const { Button: RadioButton, Group } = Radio;

export const StyledPageContainer = styled.div`
  width: 100%;
  max-width: 100%;
  flex: 1;
  font-size: 1rem;
  margin-top: 2.112rem;
  padding: 0 1rem;

  @media ${theme.device.desktop.max} {
    max-width: calc(100% - 230px);
  }

  @media ${theme.device.tablet.max} {
    padding: 0 120px;
    max-width: 100%;
  }

  @media ${theme.device.mobile.max} {
    padding: 0 1rem;
  }
`;

const StyledHeaderWrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--color-dark-blue);

  @media ${theme.device.standardDesktop.max} {
    flex-direction: column;
  }
`;

const StyledHeaderInnerWrapper = styled(StyledHeaderWrapper)`
  width: 66%;
  margin-bottom: 0;

  @media ${theme.device.standardDesktop.max} {
    width: 100%;
    flex-direction: row;
  }

  @media ${theme.device.mobile.max} {
    width: 100%;
    flex-direction: column;
  }
`;

const StyledTitle = styled.div`
  && {
    width: 33%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 0;

    @media ${theme.device.standardDesktop.max} {
      width: 100%;
      font-size: 22px;
      margin-bottom: 28px;
    }
  }
`;

const StyledRadioGroup = styled(Group)<{ disabled?: boolean }>`
  && {
    display: flex;
    align-items: center;
    height: 52px;
    padding: 6px;

    background-color: var(--color-white);
    border-radius: 40px;
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

    @media ${theme.device.mobile.max} {
      margin-bottom: 28px;
    }
  }
`;

const StyledLeftRadioButton = styled(RadioButton)`
  && {
    min-width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    height: 40px;
    border: 1px solid transparent;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;

    &.ant-radio-button-wrapper-checked {
      background: var(--color-blue);
      border-color: var(--color-blue) !important;
      font-weight: 700;
    }

    &.ant-radio-button-wrapper::before {
      display: none;
    }
    &.ant-radio-button-wrapper:hover {
      border: 1px solid var(--color-blue) !important;
    }

    &.ant-radio-button-wrapper-checked:hover {
      background: var(--color-blue);
    }
    &.ant-radio-button-wrapper-disabled {
      color: var(--color-white);
    }
  }
`;

const StyledRightRadioButton = styled(StyledLeftRadioButton)`
  && {
    margin-left: 2px;
    &.ant-radio-button-wrapper-checked {
      background: var(--color-purple-strong);
      border-color: var(--color-purple-strong) !important;
    }

    &.ant-radio-button-wrapper::before {
      display: none;
    }
    &.ant-radio-button-wrapper:hover {
      border: 1px solid var(--color-purple-strong) !important;
    }

    &.ant-radio-button-wrapper-checked:hover {
      background: var(--color-purple-strong);
    }
    &.ant-radio-button-wrapper-disabled {
      color: unset;
      background: var(--color-white);
    }
  }
`;

const StyledBillingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media ${theme.device.tablet.max} {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
`;

const StyledBillingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledSaveInfo = styled.div`
  width: 95px;
  background: #ffc227;
  border-radius: 4px;
  padding: 6px 8px;
  margin-right: 16px;

  font-weight: 700;
  font-size: 10px;
  line-height: 12px;

  @media ${theme.device.tablet.max} {
    margin-right: 0;
    margin-top: 4px;
    width: 170px;
  }
`;

const StyledSwitchInfo = styled.div`
  width: 100%;
  margin-right: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
`;

const StyledSwitchText = styled.div<{ isChecked: boolean }>`
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;

  font-weight: ${({ isChecked }) => (isChecked ? '700' : '400')};
  transition: all ease-in-out 200ms;
`;

const StyledSwitch = styled(Switch)`
  && {
    margin: 0 12px;
    background-color: var(--color-dark-blue);
  }
`;

const StyledCardPlansContainer = styled.div`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;

    @media ${theme.device.desktop.min} {
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      overflow: auto;
    }
  }
`;

const AvailablePlansPage = () => {
  const { data, loading } = useQuery<FetchStripePrices>(FETCH_STRIPE_PRICES, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });
  const { data: stripeData } = useQuery(STRIPE_STATE);
  const { plansVariant, billingVariant }: StripeValue = stripeData?.stripe;
  const { setPlansVariant, setBillingVariant } = stripeMutation;
  const isDesktop = useMediaQuery({ query: theme.device.desktop.min });

  const filteredPlans = getFilteredDataByTypeAndInterval(
    data?.fetchStripePrices,
    plansVariant,
    billingVariant,
  );

  const getSavePercent = () => {
    if (data?.fetchStripePrices) {
      const coachMonthPlan = data.fetchStripePrices.find(
        (plan) =>
          plan.bmSubscriptionPlan === BmSubscriptionTypes.CoachLed_Individual &&
          plan.interval === StripeSubscriptionIntervals.month,
      );
      const coachYearPlan = data.fetchStripePrices.find(
        (plan) =>
          plan.bmSubscriptionPlan === BmSubscriptionTypes.CoachLed_Individual &&
          plan.interval === StripeSubscriptionIntervals.year,
      );
      const selfYearPlan = data.fetchStripePrices.find(
        (plan) =>
          plan.bmSubscriptionPlan === BmSubscriptionTypes.SelfGuided_Individual &&
          plan.interval === StripeSubscriptionIntervals.year,
      );
      const selfMonthPlan = data.fetchStripePrices.find(
        (plan) =>
          plan.bmSubscriptionPlan === BmSubscriptionTypes.SelfGuided_Individual &&
          plan.interval === StripeSubscriptionIntervals.month,
      );
      if (coachMonthPlan && coachYearPlan && selfYearPlan && selfMonthPlan) {
        const selfMonthAmount = selfMonthPlan.amount * 12;
        const coachMonthAmount = coachMonthPlan.amount * 12;
        const self = ((selfMonthAmount - selfYearPlan.amount) / selfMonthAmount) * 100;
        const coach = ((coachMonthAmount - coachYearPlan.amount) / coachMonthAmount) * 100;

        return Math.round(Math.max(coach, self));
      }
    }

    return 0;
  };

  useEffect(() => {
    return () => {
      setBillingVariant(StripeSubscriptionIntervals.year);
    };
  }, []);

  return (
    <StyledContainer>
      <Header isThin={true} />
      <StyledContentContainer>
        <PageNavigationBar links={userProfileLinks} shoulShow={isDesktop} />
        <StyledPageContainer>
          <StyledHeaderWrapper>
            <StyledTitle>Available plans</StyledTitle>
            <StyledHeaderInnerWrapper>
              <StyledRadioGroup
                disabled={true}
                buttonStyle="solid"
                value={plansVariant}
                onChange={(e) => setPlansVariant(e.target.value)}
              >
                <StyledLeftRadioButton value={PLANS_TYPES.Individual}>
                  Individual
                </StyledLeftRadioButton>
                <StyledRightRadioButton value={PLANS_TYPES.Teams}>Business</StyledRightRadioButton>
              </StyledRadioGroup>
              <StyledBillingContainer>
                {billingVariant === StripeSubscriptionIntervals.month && (
                  <StyledSaveInfo>Save up to {getSavePercent()}% by paying yearly</StyledSaveInfo>
                )}
                <StyledBillingWrapper>
                  {/*{isDesktop && <StyledSwitchInfo>Billing:</StyledSwitchInfo>}*/}
                  <StyledSwitchText isChecked={billingVariant === StripeSubscriptionIntervals.year}>
                    Annually
                  </StyledSwitchText>
                  <StyledSwitch
                    checked={billingVariant === StripeSubscriptionIntervals.month}
                    onChange={() => {
                      if (billingVariant === StripeSubscriptionIntervals.year) {
                        setBillingVariant(StripeSubscriptionIntervals.month);
                      } else {
                        setBillingVariant(StripeSubscriptionIntervals.year);
                      }
                    }}
                  />
                  <StyledSwitchText
                    isChecked={billingVariant === StripeSubscriptionIntervals.month}
                  >
                    Monthly
                  </StyledSwitchText>
                </StyledBillingWrapper>
              </StyledBillingContainer>
            </StyledHeaderInnerWrapper>
          </StyledHeaderWrapper>
          <StyledCardPlansContainer>
            {filteredPlans && !loading ? (
              <>
                {filteredPlans.map((card) => {
                  return <AvailablePlanCard key={card.bmSubscriptionPlanId} cardItem={card} />;
                })}
              </>
            ) : (
              <Loader size="large" centered={true} />
            )}
          </StyledCardPlansContainer>
        </StyledPageContainer>
      </StyledContentContainer>
      <StripeDrawer startPeriod={billingVariant} />
      <PurchaseDrawer />
    </StyledContainer>
  );
};

export default AvailablePlansPage;
