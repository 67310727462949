import React, { SVGAttributes, FC } from "react";

export const CloseIcon: FC<SVGAttributes<SVGElement>> = ({ color = '#202635', ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M6 6L19.1716 19.1716" stroke={color} strokeLinecap="round" />
      <path d="M19.1719 6L6.0003 19.1716" stroke={color} strokeLinecap="round" />
    </svg>
  );
};
