import React, { useEffect, useMemo, useRef, useState } from 'react';
import CourseVideoNotification from './CourseVideoNotification';
import { useMutation, useQuery } from '@apollo/client';
import { FetchCoursesSettings, UpdateCoursesSettings } from '../../../graphql/types';
import { FETCH_COURSES_SETTINGS } from '../../../graphql/queries';
import { STRIPE_STATE, StripeValue } from '../../../apollo/stateFields/stripe/stripeFields';
import {
  ActiveVideoInfo,
  COURSES_STATE,
  CoursesFields,
} from '../../../apollo/stateFields/courses/coursesFields';
import { getIsAvailableVideo, getIsReadyForTest } from '../utils';
import { coursesMutation } from '../../../apollo/stateFields/courses';
import { differenceInMilliseconds, isBefore } from 'date-fns';
import { UPDATE_COURSES_SETTINGS } from '../../../graphql/mutations';

const CoursesNotificationManager = () => {
  const { data } = useQuery<FetchCoursesSettings>(FETCH_COURSES_SETTINGS);
  const { data: coursesData } = useQuery(COURSES_STATE);
  const { firstCourseTitle, secondCourseTitle, activeVideo }: CoursesFields = coursesData?.courses;
  const { data: stripeData } = useQuery(STRIPE_STATE);
  const { isSubscriptionExpired, isSubscriptionLoading }: StripeValue = stripeData?.stripe;
  const { setShowVideoPlayer } = coursesMutation;
  const [visible, setVisible] = useState<boolean>(false);
  const [shouldRefresh, setShouldRefresh] = useState<number>(0);

  const activeTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [updateSettings] = useMutation<UpdateCoursesSettings>(UPDATE_COURSES_SETTINGS);
  const [skipTime, setSkipTime] = useState<number | null | undefined>(undefined);

  const isSkipped = useMemo(() => {
    return skipTime ? isBefore(new Date(), new Date(skipTime * 1000)) : false;
  }, [skipTime]);

  const masteringVarsData = useMemo(
    () => data?.fetchUserSettings.coursesVars?.[secondCourseTitle],
    [data?.fetchUserSettings, secondCourseTitle],
  );
  const foundationVarsData = useMemo(
    () => data?.fetchUserSettings.coursesVars?.[firstCourseTitle],
    [data?.fetchUserSettings, firstCourseTitle],
  );
  const masteringConstData = useMemo(
    () => data?.fetchUserSettings.coursesConsts?.[secondCourseTitle],
    [data?.fetchUserSettings, secondCourseTitle],
  );
  const foundationConstData = useMemo(
    () => data?.fetchUserSettings.coursesConsts?.[firstCourseTitle],
    [data?.fetchUserSettings, firstCourseTitle],
  );

  const isFoundationDone = useMemo(() => {
    return getIsReadyForTest(firstCourseTitle, foundationVarsData, foundationConstData);
  }, [foundationVarsData, foundationConstData, firstCourseTitle]);

  const isMasteringDone = useMemo(() => {
    return getIsReadyForTest(secondCourseTitle, masteringVarsData, masteringConstData);
  }, [masteringVarsData, masteringConstData, secondCourseTitle]);

  const videoForNotification = useMemo((): ActiveVideoInfo | null => {
    if (isMasteringDone && isFoundationDone) {
      return null;
    }
    if (
      typeof masteringVarsData?.lastWatchedId === 'number' &&
      !!masteringVarsData?.dateLastWatched &&
      !!masteringConstData &&
      (isFoundationDone || masteringConstData?.isCourseAvailable) &&
      masteringVarsData?.watchedVideoIds &&
      masteringVarsData?.watchedVideoIds?.length
    ) {
      const lastWatchedId =
        masteringVarsData?.watchedVideoIds![masteringVarsData?.watchedVideoIds?.length! - 1];
      let nextVideo = masteringConstData?.videos?.find((video) => video.id === lastWatchedId + 1);
      if (nextVideo && nextVideo.name.indexOf('Masterclass') !== -1) {
        nextVideo = masteringConstData?.videos?.find((video) => video.id === lastWatchedId + 2);
      }
      if (nextVideo && nextVideo.name.indexOf('Masterclass') === -1) {
        if (!isSubscriptionExpired && !isSubscriptionLoading) {
          return {
            video: nextVideo,
            courseTitle: secondCourseTitle,
          };
        } else {
          if (
            getIsAvailableVideo(
              nextVideo,
              true,
              masteringVarsData.watchedVideoIds,
              lastWatchedId,
              masteringVarsData.dateLastWatched,
              masteringConstData,
              isSubscriptionExpired,
            ) &&
            !isSubscriptionLoading
          ) {
            return {
              video: nextVideo,
              courseTitle: secondCourseTitle,
            };
          }
        }
      }
    }
    if (
      typeof foundationVarsData?.lastWatchedId === 'number' &&
      !!foundationVarsData?.dateLastWatched &&
      !!foundationConstData
    ) {
      const nextVideo = foundationConstData?.videos?.find(
        (video) => video.id === foundationVarsData?.lastWatchedId! + 1,
      );
      if (nextVideo && nextVideo.name.indexOf('Masterclass') === -1) {
        if (!isSubscriptionExpired) {
          return {
            video: nextVideo,
            courseTitle: firstCourseTitle,
          };
        } else {
          if (
            getIsAvailableVideo(
              nextVideo,
              true,
              foundationVarsData.watchedVideoIds,
              foundationVarsData.lastWatchedId,
              foundationVarsData.dateLastWatched,
              foundationConstData,
              isSubscriptionExpired,
            )
          ) {
            return {
              video: nextVideo,
              courseTitle: firstCourseTitle,
            };
          }
        }
      }
    }

    const firstMasteringVideo = masteringConstData?.videos[1];
    const firstFoundationVideo = foundationConstData?.videos[0];
    if (
      !masteringVarsData?.lastWatchedId &&
      isFoundationDone &&
      foundationConstData?.videos?.some(
        (video) => video.id !== foundationVarsData?.lastWatchedId! + 1,
      ) &&
      firstMasteringVideo
    ) {
      return {
        video: firstMasteringVideo,
        courseTitle: secondCourseTitle,
      };
    }
    if (
      !foundationVarsData?.lastWatchedId &&
      !foundationVarsData?.watchedVideoIds?.length &&
      firstFoundationVideo
    ) {
      return {
        video: firstFoundationVideo,
        courseTitle: firstCourseTitle,
      };
    }

    return null;
  }, [
    data?.fetchUserSettings,
    isSubscriptionExpired,
    isSubscriptionLoading,
    isFoundationDone,
    isMasteringDone,
    firstCourseTitle,
    secondCourseTitle,
  ]);

  useEffect(() => {
    if (
      !activeVideo &&
      !isSubscriptionLoading &&
      !!videoForNotification &&
      videoForNotification.video.name &&
      !isSkipped
    ) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [
    videoForNotification,
    isSubscriptionLoading,
    activeVideo,
    data?.fetchUserSettings,
    shouldRefresh,
    skipTime,
  ]);

  const handleClose = async () => {
    setVisible(false);
    const skipNotificationTime = Math.round((Date.now() + 8.64e7) / 1000);
    await updateSettings({
      variables: {
        settings: {
          skipNotificationTime: skipNotificationTime,
        },
      },
    });
    setSkipTime(skipNotificationTime);
  };

  useEffect(() => {
    if (skipTime === undefined && !!data?.fetchUserSettings.skipNotificationTime) {
      setSkipTime(data?.fetchUserSettings.skipNotificationTime);
    }
    if (data?.fetchUserSettings?.skipNotificationTime === null) {
      setSkipTime(null);
      setShouldRefresh((old) => old + 1);
    }
  }, [data?.fetchUserSettings]);

  useEffect(() => {
    if (isSkipped && skipTime) {
      if (activeTimeout.current) {
        clearTimeout(activeTimeout.current);
      }
      activeTimeout.current = setTimeout(() => {
        setShouldRefresh((old) => old + 1);
      }, differenceInMilliseconds(skipTime * 1000 + 10000, new Date()));
    }

    return () => {
      if (activeTimeout.current) {
        clearTimeout(activeTimeout.current);
      }
    };
  }, [data?.fetchUserSettings?.skipNotificationTime, skipTime, isSkipped]);

  if (!data?.fetchUserSettings) {
    return null;
  }

  return (
    <>
      <CourseVideoNotification
        isMasterclass={videoForNotification?.video.name.indexOf('Masterclass') !== -1}
        visible={visible}
        title={videoForNotification?.video.name}
        onClose={handleClose}
        onWatch={() => {
          setVisible(false);
          if (videoForNotification) {
            setShowVideoPlayer(videoForNotification);
          }
        }}
      />
    </>
  );
};

export default CoursesNotificationManager;
