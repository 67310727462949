import {
  boardFiltersInitialValue,
  boardFiltersVar,
  DoneColumnFilterTypes,
  ToDoColumnFilterTypes,
} from './boardFiltersFields';

const resetBoardFilters = () => {
  const value = {
    ...boardFiltersInitialValue,
  };
  boardFiltersVar(value);
};

const setShowDoneColumnFilter = (payload: boolean) => {
  const value = {
    ...boardFiltersVar(),
    showDoneColumnFilter: payload,
  };
  boardFiltersVar(value);
};

const setDoneColumnFilter = (payload: DoneColumnFilterTypes) => {
  const value = {
    ...boardFiltersVar(),
    doneColumnFilter: payload,
  };
  boardFiltersVar(value);
};

const setDoneColumnStartDate = (payload: Date | null) => {
  const value = {
    ...boardFiltersVar(),
    doneColumnStartDate: payload,
  };
  boardFiltersVar(value);
};

const setDoneColumnEndDate = (payload: Date | null) => {
  const value = {
    ...boardFiltersVar(),
    doneColumnEndDate: payload,
  };
  boardFiltersVar(value);
};

const setToDoColumnStartDate = (payload: Date | null) => {
  const value = {
    ...boardFiltersVar(),
    toDoColumnStartDate: payload,
  };
  boardFiltersVar(value);
};

const setShowToDoColumnFilter = (payload: boolean) => {
  const value = {
    ...boardFiltersVar(),
    showToDoColumnFilter: payload,
  };
  boardFiltersVar(value);
};

const setToDoColumnFilter = (payload: ToDoColumnFilterTypes) => {
  const value = {
    ...boardFiltersVar(),
    toDoColumnFilter: payload,
  };
  boardFiltersVar(value);
};

const setToDoColumnEndDate = (payload: Date | null) => {
  const value = {
    ...boardFiltersVar(),
    toDoColumnEndDate: payload,
  };
  boardFiltersVar(value);
};

export {
  setShowToDoColumnFilter,
  setToDoColumnFilter,
  setToDoColumnStartDate,
  setToDoColumnEndDate,
  setShowDoneColumnFilter,
  setDoneColumnFilter,
  setDoneColumnStartDate,
  setDoneColumnEndDate,
  resetBoardFilters,
};
