import React from 'react';
import { InputNumber, Select } from 'formik-antd';
import { capitalize, isFinite, omit } from 'lodash';
import { SelectField } from '../../../../../core/components/form';
import { ArrowDownIcon } from '../../../../../core/icons';
import { DurationLabel, DurationType } from '../../../../../core/types/duration';
import styled from 'styled-components';
import { ActionTimeFormat } from '../types';
import { convertFromMs, convertToMs } from '../utils';

const StyledDurationRow = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  .duration-input {
    border: 0;
    border-radius: 4px;
    display: flex;
    height: 40px;
    width: 64px;
    margin: 0 2px 0 0;
    input {
      color: ${(props) => (props.isDisabled ? 'rgba(0, 0, 0, 0.25)' : 'var(--color-dark-blue)')};
      height: 100%;
    }
  }
  .duration-type-select {
    margin: 0 0 0 auto;
    width: 113px;
  }

  .ant-select-arrow svg path {
    fill: ${(props) => (props.isDisabled ? 'rgba(0, 0, 0, 0.25)' : 'var(--color-dark-blue)')};
  }
`;

const DurationRowComponent = ({
  disabled = false,
  actualValue,
  estimatedValue,
  setFieldValue,
  actionTimeFormat,
  error,
  disabledSelect,
  onChangePoints,
  onChangeType,
  onBlurPoints = () => {},
}: {
  disabled?: boolean;
  actualValue?: number | null;
  estimatedValue?: number | null;
  setFieldValue: (field: string, value: any) => void;
  actionTimeFormat?: ActionTimeFormat | null;
  error?: boolean;
  disabledSelect?: boolean;
  onChangePoints?: (name: string, value: string) => void;
  onChangeType?: (type: string) => void;
  onBlurPoints?: () => void;
}) => {
  const estimatedPoints = estimatedValue !== null ? Number(estimatedValue) : estimatedValue;

  const actualPoints = actualValue !== null ? Number(actualValue) : actualValue;

  return (
    <>
      <StyledDurationRow isDisabled={disabled}>
        <InputNumber
          disabled={disabled}
          name="estimatedTime"
          className="duration-input"
          min={0}
          keyboard={false}
          onChange={(value: any) => {
            if (!isFinite(value) && !isFinite(actualPoints)) {
              setFieldValue('actionTimeFormat', ActionTimeFormat.HOURS);
              return;
            }
            const parsedValue = `${Number(value).toFixed(2)}`;
            setFieldValue('estimatedTime', Number(parsedValue));
            onChangePoints && onChangePoints('estimatedTime', parsedValue);
          }}
          decimalSeparator={'.'}
          onBlur={onBlurPoints}
        />
        <InputNumber
          disabled={disabled}
          name="spendTime"
          className="duration-input"
          min={0}
          keyboard={false}
          onChange={(value: any) => {
            // if (!isFinite(value) && !isFinite(estimatedPoints)) {
            //   setFieldValue('actionTimeFormat', undefined);
            //   return;
            // }
            const parsedValue = `${Number(value).toFixed(2)}`;
            setFieldValue('spendTime', Number(parsedValue));
            onChangePoints && onChangePoints('spendTime', value);
          }}
          decimalSeparator={'.'}
          onBlur={onBlurPoints}
        />
        <SelectField
          name="actionTimeFormat"
          className="duration-type-select"
          suffixIcon={<ArrowDownIcon />}
          onChange={(value: any) => {
            if (estimatedValue && actionTimeFormat) {
              const oldValue = convertToMs(estimatedValue, actionTimeFormat);
              setFieldValue('estimatedTime', convertFromMs(oldValue, value));
            }
            if (actualValue && actionTimeFormat) {
              const oldValue = convertToMs(actualValue, actionTimeFormat);
              setFieldValue('spendTime', convertFromMs(oldValue, value));
            }
            onChangeType && onChangeType(value);
          }}
          disabled={disabled || disabledSelect}
          $error={error}
        >
          {[ActionTimeFormat.MINUTES, ActionTimeFormat.HOURS, ActionTimeFormat.DAYS].map(
            (label, key) => (
              <Select.Option key={key} value={label}>
                {capitalize(label)}
              </Select.Option>
            ),
          )}
        </SelectField>
      </StyledDurationRow>
    </>
  );
};

export default DurationRowComponent;
