import React, { useEffect, useState } from 'react';
import { StyledButtonsContainer, StyledDescription, StyledTabContainer } from '../StyledComponents';
import { Collapse, Input, Select, Upload, UploadProps } from 'antd';
import { toast } from 'react-toastify';
import { Button, DatePicker } from '../../../core/components/common';
import { getTime, isPast } from 'date-fns';
import { CoursesGroups, emailRegex } from '../../const';
import UserInfo from '../UserInfo/UserInfo';
import { useMutation } from '@apollo/client';
import {
  ADMIN_COURSES_BY_EMAIL,
  ADMIN_SUBSCRIPTION_EXP_BY_EMAIL,
} from '../../../graphql/mutations';
import { UploadOutlined } from '@ant-design/icons';
import { API_REST_UPLOAD_USERS_URL } from '../../../core/integrations/api/config';
import { useAuth } from '../../../context/authContext';
import axios from 'axios';

const { Panel } = Collapse;

const IndividualUsers = () => {
  const { idToken } = useAuth();
  const [changeExpirationByEmail, { loading: changeExpirationByEmailLoading }] = useMutation<any>(
    ADMIN_SUBSCRIPTION_EXP_BY_EMAIL,
  );
  const [changeCoursesByEmail, { loading: changeCoursesByEmailLoading }] =
    useMutation<any>(ADMIN_COURSES_BY_EMAIL);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  // First section
  const [field1, setField1] = useState('');
  const [field2, setField2] = useState(CoursesGroups[1]);
  const firstSubmitDisabled = !field1.length && !emailRegex.test(field1);
  const handleFirstSectionSubmit = async () => {
    changeCoursesByEmail({
      variables: {
        values: {
          email: field1,
          coursesAvailableList: field2,
        },
      },
    })
      .then(() => {
        setIsSuccess(true);
      })
      .catch(() => {
        setErrorMessage('Ooops');
      });
  };
  const handleFirstSectionReset = () => {
    setField1('');
    setField2(CoursesGroups[1]);
  };
  // Second section
  const [field3, setField3] = useState('');
  const [field4, setField4] = useState<number | undefined>(undefined);
  const secondSubmitDisabled = !field3.length || !field4 || field4 < 0 || !emailRegex.test(field3);
  const handleSecondSectionSubmit = () => {
    changeExpirationByEmail({
      variables: {
        values: {
          email: field3,
          expiration: field4,
        },
      },
    })
      .then(() => {
        setIsSuccess(true);
      })
      .catch(() => {
        setErrorMessage('Ooops');
      });
  };
  const handleSecondSectionReset = () => {
    setField3('');
    setField4(undefined);
  };
  // Third section
  const [field5, setField5] = useState('');
  const thirdSubmitDisabled = !field5.length || !emailRegex.test(field5);

  const handleThirdSectionSubmit = () => {
    changeExpirationByEmail({
      variables: {
        values: { email: field5, expiration: Math.floor((Date.now() + 3.154e10) / 1000) },
      },
    })
      .then(() => {
        setIsSuccess(true);
      })
      .catch(() => {
        setErrorMessage('Ooops');
      });
  };
  const handleThirdSectionReset = () => {
    setField5('');
  };

  // Fourth section
  const [field6, setField6] = useState('');
  const [field7, setField7] = useState(CoursesGroups[1]);
  const forthSubmitDisabled = !field6.length && !emailRegex.test(field6);
  const handleForthSectionSubmit = async () => {
    changeCoursesByEmail({
      variables: {
        values: {
          email: field6,
          coursesAvailableList: field7,
        },
      },
    })
      .then(() => {
        setIsSuccess(true);
      })
      .catch(() => {
        setErrorMessage('Ooops');
      });
  };
  const handleForthSectionReset = () => {
    setField6('');
    setField7(CoursesGroups[1]);
  };

  // Fifth section
  const [field8, setField8] = useState('');
  const [showUserInfo, setShowUserInfo] = useState(false);
  const fifthSubmitDisabled = !field8.length && !emailRegex.test(field8);
  const handleFifthSectionSubmit = async () => {
    setShowUserInfo(true);
  };
  const handleFifthSectionReset = () => {
    setField8('');
    setShowUserInfo(false);
  };

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      setErrorMessage(null);
    }
  }, [errorMessage]);
  useEffect(() => {
    if (isSuccess) {
      toast.success('Successful');
      setIsSuccess(false);
    }
  }, [isSuccess]);

  const props: UploadProps = {
    action: API_REST_UPLOAD_USERS_URL,
    headers: {
      'Content-Type': 'multipart/form-data',
      authorization: `Bearer ${idToken}`,
      Accept: '*/*',
    },
    customRequest: (options: any) => {
      const data = new FormData();
      data.append('file', options.file);
      const config = {
        headers: {
          authorization: `Bearer ${idToken}`,
          'content-type': 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
        },
      };
      axios
        .post(options.action, data, config)
        .then((res: any) => {
          options.onSuccess(res.data, options.file);
        })
        .catch((err: Error) => {
          console.log(err);
        });
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        setIsSuccess(true);
      } else if (info.file.status === 'error') {
        setErrorMessage('Ooops');
      }
    },
  };

  return (
    <StyledTabContainer>
      <Collapse>
        <Panel header="Change (and Reset) Curriculum" key="1">
          <StyledDescription>
            Change curriculum option gives Admin possibility to enrol a user on a different
            curriculum. Admin has to chose curriculum and add user’s email. The user will start a
            new curriculum from scratch, all progress on the previous curriculum is lost.
          </StyledDescription>
          <Input
            value={field1}
            onChange={(e) => setField1(e.target.value)}
            bordered
            size="large"
            placeholder="user email"
          />
          <Select
            labelInValue
            defaultValue={{ value: 1, label: 'Fundamentals, Mastery' }}
            style={{ width: '100%' }}
            onChange={(value) => {
              setField2(CoursesGroups[value.value]);
            }}
            options={[
              {
                value: 0,
                label: 'Foundations, Mastering Personal Effectiveness',
              },
              {
                value: 1,
                label: 'Fundamentals, Mastery',
              },
              {
                value: 2,
                label: 'Fundamentals (For Managers), Mastery (For Managers)',
              },
            ]}
          />
          <StyledButtonsContainer>
            <Button isSecondary onClick={handleFirstSectionReset}>
              Reset
            </Button>
            <Button
              isLoading={changeCoursesByEmailLoading}
              isDisabled={firstSubmitDisabled}
              onClick={handleFirstSectionSubmit}
            >
              Submit
            </Button>
          </StyledButtonsContainer>
        </Panel>
		{/* FOR NOW JUST COMMENTED, 
		MIGHT GET RETURNED BACK IN THE FUTURE */}
        {/* <Panel header="Extend Free Trial" key="2">
          <StyledDescription>
            Extend Trial period option gives a user more time to work with the tool free of charge
            without buying a subscription. System Admin has to set the date when Free trial will end
            and add email of a certain user.
          </StyledDescription>
          <Input
            value={field3}
            onChange={(e) => setField3(e.target.value)}
            bordered
            size="large"
            placeholder="user email"
          />
          <DatePicker
            style={{ width: '100%' }}
            size={'large'}
            disabledDate={(current) => current && isPast(current)}
            onChange={(date) => {
              if (date) {
                setField4(Math.floor(getTime(date) / 1000));
              }
            }}
          />
          <StyledButtonsContainer>
            <Button isSecondary onClick={handleSecondSectionReset}>
              Reset
            </Button>
            <Button
              isLoading={changeExpirationByEmailLoading}
              isDisabled={secondSubmitDisabled}
              onClick={handleSecondSectionSubmit}
            >
              Submit
            </Button>
          </StyledButtonsContainer>
        </Panel> */}
        <Panel header="Assign to one year Free Plan" key="3">
          <StyledDescription>
            Self-Guided Free Plan is a Free 1 year plan that gives users the ability to access the
            tool for a year. Admin has to add email of user(s) to be set on a plan.
          </StyledDescription>
          <Input
            value={field5}
            onChange={(e) => setField5(e.target.value)}
            bordered
            size="large"
            placeholder="user email"
          />
          <StyledButtonsContainer>
            <Button isSecondary onClick={handleThirdSectionReset}>
              Reset
            </Button>
            <Button
              isLoading={changeExpirationByEmailLoading}
              isDisabled={thirdSubmitDisabled}
              onClick={handleThirdSectionSubmit}
            >
              Submit
            </Button>
          </StyledButtonsContainer>
        </Panel>
        <Panel header="Pre-allocate curriculum" key="4">
          <StyledDescription>
		  	Pre-allocate curriculum option gives Admin possibility to assign a specific curriculum to a
            user. Admin has to choose curriculum and add user email. When user signs up into the
            tool, he is already enrolled in a specific curriculum.
          </StyledDescription>
          <Input
            value={field6}
            onChange={(e) => setField6(e.target.value)}
            bordered
            size="large"
            placeholder="user email"
          />
          <Select
            labelInValue
            defaultValue={{ value: 1, label: 'Fundamentals, Mastery' }}
            style={{ width: '100%' }}
            onChange={(value) => {
              setField7(CoursesGroups[value.value]);
            }}
            options={[
              {
                value: 0,
                label: 'Foundations, Mastering Personal Effectiveness',
              },
              {
                value: 1,
                label: 'Fundamentals, Mastery',
              },
              {
                value: 2,
                label: 'Fundamentals (For Managers), Mastery (For Managers)',
              },
            ]}
          />
          <StyledButtonsContainer>
            <Button isSecondary onClick={handleForthSectionReset}>
              Reset
            </Button>
            <Button
              isLoading={changeCoursesByEmailLoading}
              isDisabled={forthSubmitDisabled}
              onClick={handleForthSectionSubmit}
            >
              Submit
            </Button>
          </StyledButtonsContainer>
        </Panel>
        <Panel header="View and edit user info" key="5">
          <StyledDescription>
            View user profile option gives Admin possibility to view and edit user details. Admin
            can edit user's name, surname, free subscription expiry date and curriculum type.
          </StyledDescription>
          <Input
            value={field8}
            onChange={(e) => {
              setShowUserInfo(false);
              setField8(e.target.value);
            }}
            bordered
            size="large"
            placeholder="user email"
          />
          <StyledButtonsContainer>
            <Button isSecondary onClick={handleFifthSectionReset}>
              Reset
            </Button>
            <Button isDisabled={fifthSubmitDisabled} onClick={handleFifthSectionSubmit}>
              Search
            </Button>
          </StyledButtonsContainer>
          {field8 && showUserInfo && (
            <UserInfo
              email={field8}
              onSuccess={() => setIsSuccess(true)}
              onError={() => setErrorMessage('Ooops')}
            />
          )}
        </Panel>
        <Panel header="Add users" key="6">
          <StyledDescription>Add users by csv file.</StyledDescription>

          <StyledButtonsContainer>
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </StyledButtonsContainer>
        </Panel>
      </Collapse>
    </StyledTabContainer>
  );
};

export default IndividualUsers;
