import React from 'react';
import { Select } from 'formik-antd';
import { Select as AntdSelect } from 'antd';
import { SelectProps } from 'formik-antd/src/select/index';
import styled, { createGlobalStyle } from 'styled-components';
import { ArrowDownIcon } from '../../../icons';
import { WorkspaceSelectProps } from './types';
import { DEFAULT_WORKSPACE_NAME } from '../../../constants/others';
import { theme } from '../../../styles/styled-components';

const { Option } = Select;

const Style = createGlobalStyle`
  .rc-virtual-list .ant-select-item-option-selected {
    background-color: transparent;
    font-weight: 700;
  }
`;

const StyledSelect = styled(Select).attrs<SelectProps>({
  suffixIcon: <ArrowDownIcon />,
})`
  && {
    cursor: pointer;
    &:not(.ant-select-customize-input) .ant-select-selector {
      height: 32px;
      background: transparent;
    }

    &.ant-select-single {
      .ant-select-selector .ant-select-selection-item,
      .ant-select-selector .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
        line-height: 32px;
      }

      &:not(.ant-select-customize-input) .ant-select-selector {
        padding: 0 36px 0 0;
      }
    }

    .ant-select-arrow {
      right: 21px;
    }
  }
`;

const StyledAntdSelect = styled(AntdSelect).attrs<SelectProps>({
  suffixIcon: <ArrowDownIcon />,
})`
  && {
    cursor: pointer;
    &:not(.ant-select-customize-input) .ant-select-selector {
      height: 32px;
      background: transparent;

      span {
        background-color: transparent;
        border: none;
      }
    }

    &.ant-select-single {
      .ant-select-selector .ant-select-selection-item,
      .ant-select-selector .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
        line-height: 32px;
      }

      &:not(.ant-select-customize-input) .ant-select-selector {
        padding: 0 36px 0 0;
      }
    }

    .ant-select-arrow {
      right: 21px;

      @media ${theme.device.mobile.max} {
        top: 47%;
      }
    }
  }
`;

const StyledText = styled.span<{ isUnassigned?: boolean }>`
  padding: 1px 4px 2px 3px;
  border-radius: 2px;
  background-color: ${({ isUnassigned }) =>
    isUnassigned ? 'rgba(255, 194, 39, 0.15)' : 'transparent'};
  border: ${({ isUnassigned }) =>
    isUnassigned ? '1px solid rgba(255, 194, 39, 0.2)' : '1px solid transparent'};

  @media ${theme.device.mobile.max} {
    font-size: 12px;
  }
`;

const WorkspaceSelect = ({ name, options, withoutFormik, ...props }: WorkspaceSelectProps) => {
  return (
    <>
      <Style />
      {withoutFormik ? (
        <StyledAntdSelect {...props}>
          {options.map((option) => (
            <Option value={option.value} key={option.value}>
              <StyledText isUnassigned={option.label === DEFAULT_WORKSPACE_NAME}>
                {option.label}
              </StyledText>
            </Option>
          ))}
        </StyledAntdSelect>
      ) : (
        <StyledSelect name={name} {...props}>
          {options.map((option) => (
            <Option value={option.value} key={option.value}>
              <StyledText isUnassigned={option.label === DEFAULT_WORKSPACE_NAME}>
                {option.label}
              </StyledText>
            </Option>
          ))}
        </StyledSelect>
      )}
    </>
  );
};

export { WorkspaceSelect };
