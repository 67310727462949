import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Checkbox as AntdCheckbox, Input as AntdInput, List, Modal, Tooltip } from 'antd';
import {
  ExtendedWorkSpace,
  ManageWorkSpacesModalContentProps,
  ManageWorkSpacesModalProps,
  WorkspaceType,
} from './types';
import ModalToDrawerTransformer from '../../../../core/components/common/ModalToDrawerTransformer/ModalToDrawerTransformer';
import { Button } from '../../../../core/components/common';
import { ButtonProps } from 'antd/lib/button/button';
import { ReactComponent as PlusIcon } from 'core/svg/plus2.svg';
import { Permission, Workspace } from '../../../../core/types/workspace';
import { Formik } from 'formik';
import { Checkbox, Form, Input, SubmitButton } from 'formik-antd';
import { isEmpty } from 'lodash';
import { validationSchema } from './validation';
import { Filter } from '../../../utils';
import { WorkspaceItem } from '../WorkspaceItem/WorkspaceItem';
import {
  NOT_ASSIGNED_WORKSPACE_NAME,
  WORKSPACE_TYPE_SELECTED,
  WORKSPACE_TYPE_UNSELECTED,
} from '../../../../core/constants/others';
import Fuse from 'fuse.js';
import { sendEvent } from '../../../../core/integrations/sentry/events';
import { toast, ToastContent } from 'react-toastify';
import useCreateUserWorkspace from '../../interactions/useCreateUserWorkspace';
import useUpdateUserWorkspace from '../../interactions/useUpdateUserWorkspace';
import { UpdateUserSettings } from '../../../../graphql/types';
import { UPDATE_SETTINGS } from '../../../../graphql/mutations';
import { useMutation, useQuery } from '@apollo/client';
import { theme } from '../../../../core/styles/styled-components';
import { useMediaQuery } from 'react-responsive';
import { InviteCard } from '../ShareWorkspace/InviteCard/InviteCard';
import FilterWorkspaceTagsList from './FilterWorkspaceTagsList';
import useSubscribeEntity from '../../../../hooks/useSubscribeEntity';
import { SUBSCRIBE_WORKSPACE } from '../../../../graphql/subscriptions';
import { replaceBackSlash } from '../../../../core/utils/global';
import { filterMutation } from '../../../../apollo/stateFields/filterInput';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../apollo/stateFields/userSettings/userSettingsField';
import { userSettingsMutation } from '../../../../apollo/stateFields/userSettings';
import { manageWorkspacesMutation } from '../../../../apollo/stateFields/manageWorkspaces';
import {
  MANAGE_WORKSPACES_STATE,
  ManageWorkspacesValue,
} from '../../../../apollo/stateFields/manageWorkspaces/manageWorkspacesField';
import SubscriptionExpiredInfo from './SubscriptionExpiredInfo';
import { STRIPE_STATE, StripeValue } from '../../../../apollo/stateFields/stripe/stripeFields';
import ExpiredTooltip from '../../../../core/components/common/ExpiredTooltip/ExpiredTooltip';
import { Link } from 'react-router-dom';
import { USER_PLANS_PATH } from '../../../../core/constants/routePaths';

const StyledModal = styled(Modal)`
  margin: 0;
  padding: 0;

  && {
    @media ${theme.device.mobile.max} {
      height: var(--app-height);
      width: 100% !important;
      left: 0 !important;
      top: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      max-width: 100%;
    }
  }

  .ant-modal-content {
    background-color: var(--color-main-grey-2);
    border-radius: 3px;
    height: 652px;
    width: 480px;

    @media ${theme.device.mobile.max} {
      height: 100%;
      width: 100%;
      border-radius: 0;
    }
  }

  .ant-modal-body {
    position: relative;
    height: 100%;
    padding: 32px 22px 0 22px;

    @media ${theme.device.mobile.max} {
      padding: 32px 16px 16px;
    }
  }
`;

const StyledTitle = styled.h3`
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  margin: 0 0 0 10px;

  @media ${theme.device.mobile.max} {
    font-size: 20px;
    margin: 0;
  }
`;

const StyledControlContainer = styled.div`
  margin: 15px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledWorkspacesSearchInput = styled(AntdInput.Search)`
  .ant-input-wrapper {
    display: flex;
    width: 135px;
    margin: 0 0 0 auto;

    @media ${theme.device.tablet.max} {
      width: 170px;
    }
  }

  .ant-input {
    font-size: 12px;
    order: 1;
    padding-right: 0;
    border-radius: 0;

    ::-webkit-input-placeholder {
      /* Edge */
      color: var(--color-dark-blue);
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--color-dark-blue);
    }

    ::placeholder {
      color: var(--color-dark-blue);
    }

    &:focus {
      border-bottom: 1px solid rgba(32, 38, 53, 0.1);
    }
  }

  .ant-input-search-button {
    background-color: initial;
    border: 0;
    box-shadow: none;
    order: 0;
    width: 32px;

    svg {
      color: var(--color-dark-blue);
    }
  }

  .ant-input-group-addon {
    background-color: initial;
    border: 0;
    line-height: 1;
    margin-right: 5px;
    padding: 0;
    width: 16px;
  }
`;

const StyledAntdCheckbox = styled(AntdCheckbox)`
  margin-right: 5.78px;

  .ant-checkbox-inner {
    background-color: #fff;
    border-color: #202635;

    &::after {
      border-color: var(--color-dark-blue);
    }
  }

  .ant-checkbox-checked::after {
    border-color: #fff !important;
  }
`;

const StyledWrapper = styled.div<{ showForm: boolean }>`
  overflow-y: auto;
  overflow-x: hidden;
  height: 589px;

  @media ${theme.device.tablet.max} {
    height: calc(var(--app-height) - 47px);

    ${({ showForm }) =>
      showForm &&
      css`
        height: calc(var(--app-height) - 400px);
      `}
  }

  @media ${theme.device.mobile.max} {
    height: calc(var(--app-height) - 82px);
    margin: 24px -16px 0 -16px;
    padding: 0 16px 20px 16px;

    ${({ showForm }) =>
      showForm &&
      css`
        height: calc(var(--app-height) - 430px);
      `}
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #a4a6ad;
    border-radius: 10px;
  }

  ${({ showForm }) =>
    showForm &&
    css`
      height: 240px;
    `}
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 5.78px;

  .ant-checkbox-inner {
    background-color: #fff;
    border-color: #202635 !important;

    &::after {
      border-color: var(--color-dark-blue) !important;
    }
  }
`;

const StyledCheckboxWrapper = styled.div<{ margin?: boolean }>`
  color: var(--color-dark-blue);
  font-size: 12px;
  white-space: nowrap;

  ${({ margin }) =>
    margin &&
    css`
      margin: 0 0 10px 4px;
    `}
`;

const StyledButton: React.FunctionComponent<ButtonProps & { isDisabled?: boolean }> = styled(
  Button,
)`
  position: absolute;
  bottom: 22px;
  right: 22px;
  height: 48px;
  width: 48px;
  background-color: #202635;
  color: white;
  box-shadow: 0px 76px 94px rgba(0, 0, 0, 0.0446939),
    0px 52.6461px 59.6886px rgba(0, 0, 0, 0.0746939),
    0px 34.344px 35.9009px rgba(0, 0, 0, 0.0958776), 0px 20.5621px 20.4991px rgba(0, 0, 0, 0.114122),
    0px 10.7685px 11.3458px rgba(0, 0, 0, 0.135306), 0px 4.43149px 6.30321px rgba(0, 0, 0, 0.165306),
    0px 1.01924px 3.23382px rgba(0, 0, 0, 0.21);

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-color: #a4a6ad;
    `}

  @media ${theme.device.mobile.max} {
    bottom: 16px;
    right: 16px;
  }
`;

const StyledCreateAndEditForm = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #e5e6e9;
  padding: 20px 22px;

  @media ${theme.device.mobile.max} {
    padding: 20px 16px;
  }
`;

const StyledTitleForm = styled.h4`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 12px 10px;

  @media ${theme.device.mobile.max} {
    font-size: 14px;
    line-height: 16px;
  }
`;

const StyledInputName = styled(Input)`
  height: 44px;
  margin-bottom: 16px;

  @media ${theme.device.mobile.max} {
    height: 40px;
  }
`;

const StyledTextArea = styled(Input.TextArea)`
  && {
    height: 60px !important;
    min-height: 60px !important;
    margin-bottom: 20px;
    padding: 14px 11px;
    line-height: 16px !important;
  }
`;

const StyledButtonContainer = styled.div`
  margin: 24px -20px 0 -20px;
  padding: 20px 20px 0 20px;
  border-top: 1px solid #dbdcdf;

  @media ${theme.device.mobile.max} {
    margin: 24px -16px 0 -16px;
    padding: 20px 16px 0 16px;
  }
`;

const StyledCancelButton = styled(Button)`
  background-color: transparent;
  height: 32px;
  color: #202635;
  font-weight: normal;
  margin-left: 8px;
  font-size: 14px;

  @media ${theme.device.mobile.max} {
    border: 1px solid rgba(32, 38, 53, 0.4);
    margin-left: 16px;
  }
`;

const StyledList = styled(List)`
  // margin: 24px -22px 0 -22px;
  // padding: 0 22px 20px 22px;
  // overflow-y: auto;
  // height: 518px;
  //
  // @media ${theme.device.tablet.max} {
  //   height: calc(var(--app-height) - 190px);
  // }
  //
  // @media ${theme.device.mobile.max} {
  //   height: calc(var(--app-height) - 129px);
  //   margin: 24px -16px 0 -16px;
  //   padding: 0 16px 20px 16px;
  // }
  //
  // &::-webkit-scrollbar {
  //   width: 4px;
  // }
  //
  // &::-webkit-scrollbar-track {
  //   background: transparent;
  // }
  //
  // &::-webkit-scrollbar-thumb {
  //   background: #a4a6ad;
  //   border-radius: 10px;
  // }
`;

const StyledSharedList = styled(List)`
  margin: 12px -22px 0 -22px;
  padding: 0 22px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #a4a6ad;
    border-radius: 10px;
  }
`;

const StyledContent = styled.div`
  @media ${theme.device.tablet.max} {
    height: var(--app-height);
    position: relative;
    padding: 16px 12px 0 16px;
  }

  @media ${theme.device.mobile.max} {
    height: 100%;
    position: static;
    padding: 0;
  }
`;

const StyledLink = styled(Link)`
  align-items: center;
  color: var(--color-white);
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  &:focus,
  &:hover,
  &:active {
    color: var(--color-white);
    opacity: 0.8;
  }
`;

const getInitialWorkSpaceSpaceData = (
  workspace: Workspace,
  type: WorkspaceType = WORKSPACE_TYPE_UNSELECTED,
  isDefault: boolean = false,
  addToBoard: boolean = false,
): ExtendedWorkSpace => {
  return {
    ...workspace,
    isDefault,
    addToBoard,
    type,
  };
};

const initialWorkspace = {
  name: '',
  description: '',
  isDefault: false,
  addToBoard: false,
  type: undefined,
};

const ManageWorkSpacesModalContent = React.memo(
  ({ workspaces, invites, filterInput, visible }: ManageWorkSpacesModalContentProps) => {
    const nameRef = useRef<any>(null);
    const { data: manageWorkspacesData } = useQuery(MANAGE_WORKSPACES_STATE);
    const { editedWorkspaceId }: ManageWorkspacesValue = manageWorkspacesData?.manageWorkspaces;
    const { data: stripeData } = useQuery(STRIPE_STATE);
    const { isSubscriptionExpired, expiredSubscriptionText }: StripeValue = stripeData?.stripe;
    const { setEditedWorkspace } = manageWorkspacesMutation;
    const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
    const { defaultWorkspaceId }: UserSettingsValue = userSettingsData?.userSettingsField;
    const { setDefaultWorkspaceId } = userSettingsMutation;
    const { toggleFilterInputWorkspace } = filterMutation;
    const [currentLifeSpace, setCurrentLifeSpace] =
      useState<Partial<ExtendedWorkSpace>>(initialWorkspace);
    const [showForm, setShowForm] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [filterTags, setFilterTags] = useState<Permission[]>([
      Permission.VIEWER,
      Permission.EDITOR,
      Permission.PRIMARY_OWNER,
    ]);
    const [createUserWorkspace] = useCreateUserWorkspace();
    const [updateUserWorkspace] = useUpdateUserWorkspace();
    const [updateSettings] = useMutation<UpdateUserSettings>(UPDATE_SETTINGS);
    const viewerPermission = useMemo(
      () => currentLifeSpace?.permission === Permission.VIEWER || false,
      [currentLifeSpace],
    );

    const filteredWorkspaces = useMemo(() => {
      return [...workspaces]
        .filter((w) => w.id !== defaultWorkspaceId)
        .filter((w) => w.name !== NOT_ASSIGNED_WORKSPACE_NAME)
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name));
    }, [workspaces, defaultWorkspaceId]);

    const defaultWorkspace: ExtendedWorkSpace = useMemo(() => {
      const workspace = workspaces.find((w) => w.id === defaultWorkspaceId) as Workspace;
      const isAddedToBoard = filterInput.find(
        (filter: Filter) => filter.workspaceId === workspace?.id,
      );
      const type = !!isAddedToBoard ? WORKSPACE_TYPE_SELECTED : WORKSPACE_TYPE_UNSELECTED;
      return getInitialWorkSpaceSpaceData(workspace, type, true, !!isAddedToBoard);
    }, [workspaces, filterInput, defaultWorkspaceId]);

    const selectedWorkspaces: ExtendedWorkSpace[] = useMemo(() => {
      return filteredWorkspaces
        .filter((w) => filterInput.find((filter: Filter) => filter.workspaceId === w.id))
        .slice()
        .map((w) => getInitialWorkSpaceSpaceData(w, WORKSPACE_TYPE_SELECTED, false, true))
        .sort((a, b) => a.name.localeCompare(b.name));
    }, [filteredWorkspaces, filterInput]);

    const unSelectedWorkspaces: ExtendedWorkSpace[] = useMemo(() => {
      return filteredWorkspaces
        .filter((w) => !filterInput.some((filter: Filter) => filter.workspaceId === w.id))
        .slice()
        .map((w) => getInitialWorkSpaceSpaceData(w, WORKSPACE_TYPE_UNSELECTED, false, false))
        .sort((a, b) => a.name.localeCompare(b.name));
    }, [filteredWorkspaces, filterInput]);

    const allWorkspaces = workspaces.length
      ? [defaultWorkspace, ...selectedWorkspaces, ...unSelectedWorkspaces]
      : [];

    useSubscribeEntity(SUBSCRIBE_WORKSPACE, editedWorkspaceId, editedWorkspaceId);

    useEffect(() => {
      if (allWorkspaces.length === 1) {
        setShowForm(true);
      } else if (!editedWorkspaceId) {
        setShowForm(false);
      }
    }, [workspaces]);

    useEffect(() => {
      if (editedWorkspaceId) {
        const editedWorkspace = allWorkspaces.find(
          (w) => w.id === editedWorkspaceId,
        ) as ExtendedWorkSpace;
        setCurrentLifeSpace(editedWorkspace);

        if (!showForm) {
          setShowForm(true);
        }
      } else {
        setCurrentLifeSpace(initialWorkspace);
        setShowForm(false);
      }
    }, [editedWorkspaceId, workspaces]);

    useEffect(() => {
      if (!visible) {
        if (editedWorkspaceId) {
          setEditedWorkspace(null);
          setCurrentLifeSpace(initialWorkspace);
        }
        if (showForm) {
          setShowForm(false);
        }
      }
    }, [visible]);

    const handleSetSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    };

    const handleToggleShowForm = useCallback(
      (workspaceId?: number) => () => {
        setShowForm((prev) => !prev);
        if (workspaceId) {
          setEditedWorkspace(workspaceId);
        } else {
          setEditedWorkspace(null);
        }
      },
      [showForm, workspaces],
    );

    const handleToggleSetDefault = async (defaultWorkspaceId: number) => {
      await updateSettings({
        variables: {
          settings: {
            defaultWorkspaceId,
          },
        },
      }).then(() => {
        setDefaultWorkspaceId(defaultWorkspaceId);
      });
    };

    const handleToggleAddToBoard = (id: number) => {
      toggleFilterInputWorkspace(id);
    };

    const handleCreateOrUpdate = async (values: any) => {
      try {
        const {
          __typename,
          id,
          type,
          isDefault,
          permission,
          isNewShared,
          usersCount,
          addToBoard,
          ...newValues
        } = values;
        if (id) {
          await updateUserWorkspace({
            variables: {
              workspace: {
                id,
                ...newValues,
                name: replaceBackSlash(newValues.name),
              },
            },
          });
          sendEvent('workspace-update', 'Workspace update', {
            Name: newValues.name,
          });

          if (addToBoard !== currentLifeSpace.addToBoard) {
            handleToggleAddToBoard(id);
          }
        } else {
          const { data } = await createUserWorkspace({
            variables: {
              workspace: {
                ...newValues,
                name: replaceBackSlash(newValues.name),
              },
            },
          });
          const { id } = data?.createUserWorkspace as Workspace;

          sendEvent('workspace-create', 'Workspace create', {
            Name: newValues.name,
          });

          if (isDefault) {
            await handleToggleSetDefault(id);
          }
          if (addToBoard) {
            await handleToggleAddToBoard(id);
          }
        }
      } catch (error) {
        console.error(error);
        toast(error as ToastContent);
      } finally {
        setEditedWorkspace(null);
        setCurrentLifeSpace(initialWorkspace);
        setShowForm(false);
      }
    };

    const renderWorkspaceItems = () => {
      let data = allWorkspaces;

      if (searchText) {
        data = new Fuse(data!, { keys: ['name'], threshold: 0.2 })
          .search(searchText)
          .map((w) => ({ ...w.item }))
          .filter((workspace) => filterTags.some((tag) => tag === workspace.permission));
      } else {
        data = allWorkspaces.filter((workspace) =>
          filterTags.some((tag) => tag === workspace.permission),
        );
      }

      return data.map((workspace, index) => {
        if (!workspace?.id) {
          return null;
        }
        return (
          <WorkspaceItem
            key={`${workspace.id}${index}`}
            workspace={workspace}
            onSelect={handleToggleAddToBoard}
            onEdit={handleToggleShowForm}
            onSetDefault={handleToggleSetDefault}
          />
        );
      });
    };

    const controlsEl = (
      <StyledControlContainer>
        <StyledCheckboxWrapper>
          {/*<StyledAntdCheckbox*/}
          {/*  checked={false}*/}
          {/*  onClick={() => {}}*/}
          {/*/>*/}
          {/*Show Archived*/}
        </StyledCheckboxWrapper>
        <StyledWorkspacesSearchInput
          onChange={handleSetSearchText}
          placeholder="Search Spaces"
          bordered={false}
        />
      </StyledControlContainer>
    );

    const AddBtnTooltipTitle = (
      <>
        You cannot create a new Space because your {expiredSubscriptionText}. To enable this, please{' '}
        <StyledLink to={USER_PLANS_PATH}>select a paid plan </StyledLink>
      </>
    );

    const addButtonEl = !showForm && (
      <ExpiredTooltip
        placement={'leftTop'}
        withoutArrow={true}
        visible={isSubscriptionExpired}
        title={AddBtnTooltipTitle}
      >
        <StyledButton
          isDisabled={isSubscriptionExpired}
          shape="circle"
          onClick={handleToggleShowForm()}
        >
          <PlusIcon />
        </StyledButton>
      </ExpiredTooltip>
    );

    const createAndEditFormEl = showForm && (
      <StyledCreateAndEditForm>
        <Formik
          enableReinitialize
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={currentLifeSpace}
          validationSchema={validationSchema}
          onSubmit={handleCreateOrUpdate}
        >
          {({ values, isSubmitting, errors, dirty }) => {
            useEffect(() => {
              nameRef?.current?.focus({
                cursor: 'end',
              });
            }, []);
            return (
              <Form>
                <StyledTitleForm>
                  {values.id ? 'Edit ' : 'Create new '}
                  Space
                </StyledTitleForm>
                <StyledInputName
                  ref={nameRef}
                  name="name"
                  disabled={viewerPermission}
                  maxLength={64}
                  value={values.name}
                  autoComplete="off"
                  placeholder={values.id ? 'Name of Space' : 'Name of new Space'}
                />
                <StyledTextArea
                  disabled={viewerPermission}
                  autoSize={{ minRows: 3, maxRows: 3 }}
                  name="description"
                  maxLength={1000}
                  value={values.description}
                  placeholder="Description of Space"
                />
                <StyledCheckboxWrapper margin>
                  <StyledCheckbox name="addToBoard" />
                  Add to the Board
                </StyledCheckboxWrapper>
                {!values.id && (
                  <StyledCheckboxWrapper margin>
                    <StyledCheckbox name="isDefault" />
                    Set as default
                  </StyledCheckboxWrapper>
                )}
                <StyledButtonContainer>
                  <SubmitButton
                    disabled={!isEmpty(errors) || !dirty}
                    loading={isSubmitting}
                    type="primary"
                    shape="round"
                  >
                    {values?.id ? 'Save Changes' : 'Create New Space'}
                  </SubmitButton>
                  {(allWorkspaces.length > 1 || values.id) && (
                    <StyledCancelButton onClick={handleToggleShowForm()}>Cancel</StyledCancelButton>
                  )}
                </StyledButtonContainer>
              </Form>
            );
          }}
        </Formik>
      </StyledCreateAndEditForm>
    );

    const workspaceListEl = visible && <StyledList>{renderWorkspaceItems()}</StyledList>;
    const workspaceSharedListEl = (
      <StyledSharedList>
        {invites?.map((invite) => (
          <InviteCard key={invite.id} invite={invite} />
        ))}
      </StyledSharedList>
    );

    return (
      <StyledContent>
        <StyledTitle>Manage Spaces</StyledTitle>
        <StyledWrapper showForm={showForm}>
          {isSubscriptionExpired && <SubscriptionExpiredInfo />}
          {invites && workspaceSharedListEl}
          {controlsEl}
          <FilterWorkspaceTagsList
            workspaces={allWorkspaces}
            filterTags={filterTags}
            setFilterTags={setFilterTags}
            searchText={searchText}
          />
          {workspaceListEl}
          {addButtonEl}
        </StyledWrapper>
        {createAndEditFormEl}
      </StyledContent>
    );
  },
);

const ManageWorkSpacesModal = React.memo(
  ({
    visible = false,
    onClose,
    style,
    clickOutside,
    workspaces,
    invites,
    filterInput,
  }: ManageWorkSpacesModalProps) => {
    const isTablet = useMediaQuery({ query: theme.device.tablet.max });

    return (
      <ModalToDrawerTransformer
        isOpened={visible}
        onClose={onClose}
        clickOutside={clickOutside}
        width={480}
        closable={isTablet}
        modalComponent={
          <StyledModal
            getContainer={() => document.body}
            visible={visible}
            onCancel={onClose}
            footer={null}
            mask={false}
            closable={true}
            style={style}
          />
        }
      >
        {visible && (
          <ManageWorkSpacesModalContent
            workspaces={workspaces}
            invites={invites}
            filterInput={filterInput}
            visible={visible}
          />
        )}
      </ModalToDrawerTransformer>
    );
  },
);

export default ManageWorkSpacesModal;
