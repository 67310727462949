import React, { useEffect } from 'react';
import { Column, ColumnType } from '../../../../../context/dndContext/types';
import DoneColumnFilter from '../../../../common/components/ColumnFilters/DoneColumnFilter/DoneColumnFilter';
import ToDoColumnFilter from '../../../../common/components/ColumnFilters/ToDoColumnFilter/ToDoColumnFilter';
import { Action } from '../../../../../core/types/action';
import {
  Draggable,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import styled from 'styled-components';
import { EntityCardColumn } from '../../../../common/components';
import { theme } from '../../../../../core/styles/styled-components';
import ColumnActionCard from '../ActionCard/ColumnActionCard';
import { FetchWorkspaceUserInvitesResult, Workspace } from '../../../../../core/types/workspace';
import { ContextMenuOption } from '../../../../../core/components/common/Card/types';

const StyledColumnWrapper = styled.div`
  && {
    display: flex;
    align-items: flex-start;
  }
`;

const StyledCardWrapper = styled.div`
  && {
    padding-bottom: 2px;
  }
`;

const StyledActionCardColumn = styled(EntityCardColumn)`
  padding: 17px 8px 8px 8px;
  background-color: transparent;
  border-radius: 0;
  width: 285px;
  max-width: 368px;
  height: 100%;

  @media ${theme.device.largeDesktop.min} {
    padding: 17px 12px 8px 12px;
  }

  @media ${theme.device.standardDesktop.min} {
    width: calc((100vw - 70px) / 5);
  }

  @media ${theme.device.largeDesktop.min} {
    width: calc((100vw - 421px - 24px) / 4);
  }

  @media ${theme.device.tablet.max} {
    width: 365px;
  }

  @media ${theme.device.mobile.max} {
    width: 278px;
  }

  .column-card-wrapper {
    padding-right: 0;
  }
`;

export type ActionColumn = {
  className?: string;
  isDropDisabled?: boolean;
  isCombineEnabled?: boolean;
  isMovingDisabled?: boolean;
  isDragDisabled?: boolean;
  workspaces: Workspace[];
  column: Column;
  filteredActions: Action[];
  renderActionClone: (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    rubric: DraggableRubric,
  ) => JSX.Element;
  getWorkspaceInvitesById: (
    id: number,
  ) => { count: number; users: FetchWorkspaceUserInvitesResult[]; workspaceId: number } | undefined;
  onCardFormSubmit: (name: any, workspaceId: any, status: any) => Promise<void>;
  onActionWorkspaceChange: (action: Action, option: any, oldWorkspaceId?: any) => Promise<void>;
  handleContextMenuOptionClick: (action: Action) => (option: ContextMenuOption) => void;
};

const ActionColumn = React.memo(
  ({
    column,
    workspaces,
    isDropDisabled,
    isCombineEnabled,
    isMovingDisabled,
    filteredActions,
    renderActionClone,
    onCardFormSubmit,
    onActionWorkspaceChange,
    handleContextMenuOptionClick,
    getWorkspaceInvitesById,
    isDragDisabled,
  }: ActionColumn) => {
    let viewport = column.entityIds.length - filteredActions.length;
    viewport = viewport > 0 ? viewport + 100 : 100;
    return (
      <StyledColumnWrapper className="column-wrapper" key={column.title}>
        <StyledActionCardColumn
          className="action-card-column"
          key={column.title}
          isDropDisabled={isDropDisabled}
          isCombineEnabled={isCombineEnabled}
          isMovingDisabled={isMovingDisabled}
          entityName="Action"
          name={column.title}
          title={column.title === ColumnType.Todo ? 'To do' : column.title}
          cardData={column.entityIds}
          viewport={viewport}
          columnFilter={
            column.title === ColumnType.Done ? (
              <DoneColumnFilter />
            ) : column.title === ColumnType.Todo ? (
              <ToDoColumnFilter />
            ) : undefined
          }
          renderCard={(i, actionId) => {
            const entity = filteredActions.find((action: Action) => action.id === actionId);
            if (entity !== undefined && !entity.isArchived) {
              return (
                <ColumnActionCard
                  workspaces={workspaces}
                  isDraggable={!isDragDisabled}
                  key={`${actionId}`}
                  draggablePosition={i}
                  action={entity}
                  onActionWorkspaceChange={onActionWorkspaceChange}
                  handleContextMenuOptionClick={handleContextMenuOptionClick}
                  getWorkspaceInvitesById={getWorkspaceInvitesById}
                />
              );
            }
            return (
              <Draggable draggableId={`${actionId}`} index={i} key={`${actionId}`}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div style={{ height: '1px', marginTop: '-1px', padding: '0' }} />
                  </div>
                )}
              </Draggable>
            );
          }}
          renderClone={renderActionClone}
          onSubmit={({ name, workspaceId }) =>
            onCardFormSubmit(name, workspaceId, column.title.toUpperCase())
          }
          dataTutorial={column.title === ColumnType.Backlog ? 0 : undefined}
        />
      </StyledColumnWrapper>
    );
  },
);

export default ActionColumn;
