import { version } from '../../../package.json';

export const WEB_APP_VERSION = version;
export const DEFAULT_WORKSPACE_NAME = 'Unassigned';
export const NOT_ASSIGNED_WORKSPACE_NAME = 'Not_assigned';
export const NODE_ENV =
  (window as any).REACT_APP_NODE_ENV ?? process.env.REACT_APP_NODE_ENV ?? process.env.NODE_ENV;
export const WORKSPACE_TYPE_DEFAULT = 'default';
export const WORKSPACE_TYPE_NEW = 'new';
export const WORKSPACE_TYPE_SELECTED = 'selected';
export const WORKSPACE_TYPE_UNSELECTED = 'unselected';
export const PROD_DOMAIN = 'billionminds.com';
export const FACEBOOK_APP_ID = '1048070435601904';