import { gql, makeVar } from '@apollo/client';

export interface AuthUser {
  uid?: string;
  email?: string;
  displayName?: string;
  photoURL?: string;
}

export interface AuthValue {
  idToken: string | undefined;
  isNewUser: boolean;
  isRegistered: boolean;
  userId: number | undefined;
  authUser: AuthUser | undefined;
  isAdmin: boolean;
}

export const authInitialValue: AuthValue = {
  idToken: undefined,
  isNewUser: false,
  isRegistered: false,
  userId: undefined,
  authUser: undefined,
  isAdmin: false,
};

export const authVar = makeVar<AuthValue>(authInitialValue);

export const AUTH_STATE = gql`
  query Auth {
    auth @client
  }
`;
