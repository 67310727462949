import { gql } from '@apollo/client';
import { TAG_FIELDS } from '../../common/graphql/fragments';

export const OUTCOME_FIELDS = gql`
  fragment outcomeFields on Outcome {
    id
    name
    status
    description
    startDate
    endDate
    updateDate
    completeDate
    workspaceId
    isArchived
    createDate
    actions {
      id
    }
    tags {
      name
      id
    }
  }
`;

export const OUTCOME_FIELDS_UNARCHIVE = gql`
  fragment outcomeFieldsUnarchive on Outcome {
    id
    name
    status
    description
    startDate
    endDate
    updateDate
    completeDate
    workspaceId
    isArchived
    actions {
      id
      actualEndDate
      actualPoints
      estimatedPoints
      actualStartDate
      assigneeId
      description
      endDate
      name
      outcome {
        id
        name
      }
      startDate
      status
      tags {
        ...tagFields
      }
      updateDate
      workspaceId
      isArchived
    }
    tags {
      name
      id
    }
  }

  ${TAG_FIELDS}
`;
