import { ApolloClient, from, fromPromise, HttpLink, Observable, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { toast } from 'react-toastify';
import { WEB_APP_VERSION } from 'core/constants/others';
import { API_GRAPHQL_URL, API_WS_GRAPHQL_URL } from '../core/integrations/api/config';
import { cache } from './cache';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';

import { firebase } from '../core/integrations/firebase/init';
import {
  CUSTOM_ENTITY_TYPES,
  CUSTOM_ERROR_MESSAGE,
  errorVar,
} from './stateFields/error/errorFields';
import { stripeVar } from './stateFields/stripe/stripeFields';
import { setContext } from '@apollo/client/link/context';
import { getEntityByOperationName } from './utils';
import { OPERATION_NAME } from './types';
import { boardEditedEntityVar } from './stateFields/boardEditedEntity/boardEditedEntityFileds';
import { FETCH_ACTION, FIX_ACTION } from '../features/actions/graphql/queries';

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  const { operationName } = operation;

  if (
    networkError?.message === 'Failed to fetch' &&
    (operationName === 'FetchAllNotes' ||
      operationName === 'FetchUserWorkspacesWithInvitedCount' ||
      operationName === 'FetchUserWorkspaceActions' ||
      operationName === 'filterUserWorkspaces' ||
      operationName === 'FetchUserInvites')
  ) {
    return Observable.of(operation);
  }
  if (
    graphQLErrors &&
    graphQLErrors.find(
      (error: any) =>
        +error.code === 406 &&
        error.message ===
          'This promotion code cannot be redeemed because the associated customer has prior transactions.',
    )
  ) {
    stripeVar({
      ...stripeVar(),
      errorMessage: 'Your payment was not processed, because this coupon is not valid.',
    });
    return Observable.of(operation);
  }
  if (operationName === 'FixAction') {
    return Observable.of(operation);
  }
  if (
    graphQLErrors &&
    graphQLErrors.find(
      (error: any) =>
        +error.code === 409 && error.message === 'ACTION_WITH_MULTIPLE_OUTCOMES_ERROR',
    )
  ) {
    const brokenActionIds = JSON.parse(graphQLErrors[0]?.extensions?.response?.error) as any[];
    if (brokenActionIds && brokenActionIds.length) {
      const queries = brokenActionIds.map((action, index) =>
        apolloClient.query({
          query: FETCH_ACTION,
          fetchPolicy: 'network-only',
          variables: {
            actionId: action?.actionId,
            workspaceId: action?.workspaceIds[0] || undefined,
          },
        }),
      );
      Promise.all(queries).then(() => {
        forward(operation);
      });
    }
  }
  if (graphQLErrors && graphQLErrors.find((error: any) => error.code === 401) && forward) {
    try {
      return fromPromise(
        firebase
          .auth()
          .currentUser!.getIdToken(true)
          .catch((error) => {
            return;
          }),
      )
        .filter((value) => {
          return Boolean(value);
        })
        .flatMap((accessToken) => {
          const oldHeaders = operation.getContext().headers;
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `${accessToken}`,
            },
          });

          return forward(operation);
        });
    } catch (e) {
      toast.info('Please sign in to your account.');
      firebase.auth().signOut();
    }
  } else if (
    graphQLErrors &&
    graphQLErrors.find(
      (error: any) => error.message === 'NOT_ALLOWED_ON_ARCHIVED' && error.code === 402,
    )
  ) {
    if (!errorVar().errorMessage) {
      const currentError = graphQLErrors.find((error: any) => error.code === 402);
      errorVar({
        errorMessage: currentError?.extensions?.exception?.message,
        errorCode: currentError?.extensions?.code,
        entityType:
          (operationName === OPERATION_NAME.UpdateAction ||
            operationName === OPERATION_NAME.MoveAction) &&
          operation?.variables?.actionValues?.outcomeId
            ? CUSTOM_ENTITY_TYPES.ACTION_OR_OUTCOME
            : getEntityByOperationName(operation.operationName),
      });
      return Observable.of(operation);
    }
    return Observable.of(operation);
  } else if (
    graphQLErrors &&
    graphQLErrors.find(
      (error: any) => error.message === 'INVALID_METHOD_INPUT' && error.code === 402,
    )
  ) {
    if (!errorVar().errorMessage) {
      const currentError = graphQLErrors.find(
        (error: any) => error.message === 'INVALID_METHOD_INPUT',
      );
      errorVar({
        errorMessage: currentError?.extensions?.exception?.message,
        errorCode: currentError?.extensions?.code,
        entityType:
          operationName === OPERATION_NAME.MoveAction &&
          operation?.variables?.actionValues?.outcomeId
            ? CUSTOM_ENTITY_TYPES.ACTION_OR_OUTCOME
            : getEntityByOperationName(operationName),
      });
      return Observable.of(operation);
    }
    return Observable.of(operation);
  } else if (
    graphQLErrors &&
    graphQLErrors.find((error: any) => error.message === "Cannot read property '0' of undefined")
  ) {
    if (!errorVar().errorMessage) {
      if (
        (boardEditedEntityVar().actionEditedEntity &&
          operationName === OPERATION_NAME.FetchAction &&
          operation?.variables?.workspaceId !==
            boardEditedEntityVar().actionEditedEntity?.workspaceId) ||
        (boardEditedEntityVar().outcomeEditedEntity &&
          operationName === OPERATION_NAME.FetchOutcome &&
          operation?.variables?.workspaceId !==
            boardEditedEntityVar().outcomeEditedEntity?.workspaceId)
      ) {
        return Observable.of(operation);
      } else {
        errorVar({
          errorMessage: CUSTOM_ERROR_MESSAGE.ENTITY_SPACE_CHANGED,
          entityType: getEntityByOperationName(operationName),
          errorCode: undefined,
        });
      }
    }
    return Observable.of(operation);
  } else if (
    graphQLErrors &&
    graphQLErrors.find((error: any) => error.message === 'NO_RELATION' && error.code === 405)
  ) {
    if (operationName === OPERATION_NAME.RemoveUserFromWorkspace) {
      return Observable.of(operation);
    }
    if (!errorVar().errorMessage) {
      const currentError = graphQLErrors.find((error: any) => error.code === 405);
      // if (operation.operationName === OPERATION_NAME.FetchActions) {
      //   errorVar({
      //     errorMessage: CUSTOM_ERROR_MESSAGE.ENTITY_SPACE_CHANGED,
      //     errorCode: currentError?.extensions?.code,
      //     entityType: CUSTOM_ENTITY_TYPES.OUTCOME,
      //   });
      // } else if (
      //   operationName === OPERATION_NAME.FetchOutcomes ||
      //   operationName === OPERATION_NAME.FetchUserWorkspaceTags
      // ) {
      //   errorVar({
      //     errorMessage: CUSTOM_ERROR_MESSAGE.ENTITY_SPACE_CHANGED,
      //     errorCode: currentError?.extensions?.code,
      //     entityType: CUSTOM_ENTITY_TYPES.ACTION,
      //   });
      // } else {
      errorVar({
        errorMessage: currentError?.extensions?.exception?.message,
        errorCode: currentError?.extensions?.code,
        errorWorkspaces: currentError?.extensions?.exception?.response?.error,
        errorVariables: operation.variables,
        errorOperation: operationName as OPERATION_NAME,
        entityType:
          operationName === OPERATION_NAME.CreateAction &&
          operation?.variables?.actionValues?.outcomeId
            ? CUSTOM_ENTITY_TYPES.OUTCOME
            : operation.operationName === OPERATION_NAME.UpdateAction &&
              operation?.variables?.actionValues?.outcomeId
            ? CUSTOM_ENTITY_TYPES.ACTION_OR_OUTCOME
            : getEntityByOperationName(operationName),
      });
      // }
      return Observable.of(operation);
    }
    return Observable.of(operation);
  } else if (graphQLErrors && !graphQLErrors[0].message) {
    toast.error('Oops. An error has occurred.');
    return Observable.of(operation);
  } else if (
    graphQLErrors &&
    graphQLErrors.find((error: any) => error.code === 'BAD_USER_INPUT') &&
    (graphQLErrors.find((error: any) => error.code === 'BAD_USER_INPUT')?.message ===
      'STRIPE_SUBSCRIPTION_ALREADY_EXIST' ||
      graphQLErrors.find((error: any) => error.code === 'BAD_USER_INPUT')?.message ===
        'STRIPE_SET_COOKIE_ERROR')
  ) {
    return Observable.of(operation);
  } else if (graphQLErrors && graphQLErrors.find((error: any) => error.code === 'BAD_USER_INPUT')) {
    if (!errorVar().errorMessage) {
      const currentError = graphQLErrors.find((error: any) => error.code === 'BAD_USER_INPUT');
      errorVar({
        errorMessage: currentError?.extensions?.code,
        errorCode: currentError?.extensions?.response?.statusCode,
        entityType: getEntityByOperationName(operationName),
      });
      return Observable.of(operation);
    }
  }
  return Observable.of(operation);
});

export function getLink(idToken: string) {
  const httpLink = new HttpLink({
    uri: API_GRAPHQL_URL,
    headers: {
      authorization: idToken,
      'client-name': 'Billionminds [web]',
      'client-version': WEB_APP_VERSION,
    },
  });

  const wsLink = new WebSocketLink({
    uri: API_WS_GRAPHQL_URL,
    options: {
      reconnect: true,
      lazy: true,
      inactivityTimeout: 5000,
      connectionParams: {
        Authorization: idToken,
      },
    },
  });

  const authLink = setContext((operation, { headers }) => {
    const headersWithAdmin = {
      ...headers,
      admin: 'a75d4e00-16c9-4bf2-9e9d-3a81aaa7b589',
    };
    return {
      headers:
        operation.operationName === OPERATION_NAME.FetchWorkspaceInvitesById ||
        operation.operationName === OPERATION_NAME.FetchWorkspaceInvitesByIds
          ? headersWithAdmin
          : headers,
    };
  });

  return split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    authLink.concat(httpLink),
  );
}

export const setApolloLink = (idToken: string) => {
  return from([errorLink, getLink(idToken)]);
};

export const apolloClient = new ApolloClient({
  cache,
  connectToDevTools: true,
});
