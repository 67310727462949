import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { ReactComponent as ArrowDown } from '../../../../outcomes/components/OutcomeStatusFilter/svg/arrowDown.svg';
import styled from 'styled-components';
import { SharedRoleSelectorProps } from '../types';
import { ReactComponent as CheckImage } from '../../../../../core/svg/check.svg';
import { getRoleText } from '../utils';
import { useMediaQuery } from 'react-responsive';
import theme from '../../../../../core/styles/styled-components/theme';

const StyledDropdown = styled(Dropdown)`
  && {
    text-align: left;
  }
`;

const StyledMenu = styled(Menu)`
  background-color: var(--color-main-grey-2);
  border-radius: 3px;
  box-shadow: 0px 1px 32px rgba(32, 38, 53, 0.2);

  .ant-dropdown-menu-item {
    position: relative;
    background-color: inherit;
    min-width: 125px;
    padding-left: 26px;

    &:hover {
      font-weight: 700;
    }

    & svg {
      position: absolute;
      top: 50%;
      left: 7px;
      transform: translateY(-55%);
    }
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 0;
  height: 40px;
  box-shadow: none;
  padding: 0 7px;
  font-weight: 400;

  span {
    color: var(--color-dark-blue);
  }

  svg {
    margin-left: 10px;
  }
`;

const StyledText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 0;
  height: 40px;
  box-shadow: none;
  padding: 0 7px;
  font-weight: 400;

  color: var(--color-dark-blue);
`;

const SharedRoleSelector = ({
  onSelect,
  role,
  roles,
  className,
  disabled,
  isText,
}: SharedRoleSelectorProps) => {
  const isMobile = useMediaQuery({ query: theme.device.mobile.max });

  return (
    <>
      {isText ? (
        <StyledText>{getRoleText(role, isMobile)}</StyledText>
      ) : (
        <StyledDropdown
          disabled={disabled}
          className={className}
          trigger={['click']}
          overlay={() => (
            <StyledMenu
              selectable
              defaultSelectedKeys={[role]}
              selectedKeys={[role]}
              onSelect={onSelect}
            >
              {roles.map((userRole) => {
                return (
                  <Menu.Item key={userRole}>
                    {userRole === role && <CheckImage />}
                    {getRoleText(userRole, isMobile)}
                  </Menu.Item>
                );
              })}
            </StyledMenu>
          )}
        >
          <StyledButton>
            {getRoleText(role, isMobile)}
            <ArrowDown />
          </StyledButton>
        </StyledDropdown>
      )}
    </>
  );
};

export default SharedRoleSelector;
