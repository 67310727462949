interface LinkedinVars {
  REACT_APP_LINKEDIN_REDIRECT_URI: string;
  REACT_APP_LINKEDIN_CLIENT_SECRET: string;
  REACT_APP_LINKEDIN_CLIENT_ID: string;
  REACT_APP_LINKEDIN_AUTHORIZATION_URL: string;
  REACT_APP_LINKEDIN_ACCESS_TOKEN_URL: string;
}

const env = (window as any)?.REACT_APP_LINKEDIN_REDIRECT_URI ? window : process.env;

export const {
  REACT_APP_LINKEDIN_REDIRECT_URI: LINKEDIN_REDIRECT_URI,
  REACT_APP_LINKEDIN_CLIENT_SECRET: LINKEDIN_CLIENT_SECRET,
  REACT_APP_LINKEDIN_CLIENT_ID: LINKEDIN_CLIENT_ID,
  REACT_APP_LINKEDIN_AUTHORIZATION_URL: LINKEDIN_AUTHORIZATION_URL,
  REACT_APP_LINKEDIN_ACCESS_TOKEN_URL: LINKEDIN_ACCESS_TOKEN_URL,
} = env as unknown as LinkedinVars;
