import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Drawer } from 'antd';
import { kebabCase } from 'lodash-es';
import { ActiveLink } from '../../../common';
import { UserMenu } from '../UserMenu';
import links from './links';
import { HamburgerMenuProps } from './types';
import InfoMenu from '../InfoMenu/InfoMenu';
import { sendEvent } from '../../../../integrations/sentry/events';
import { ReactComponent as ContactIcon } from '../../../../svg/common/contact.svg';
import { ReactComponent as HelpIcon } from '../../../../svg/common/help.svg';
import { ReactComponent as LearningIcon } from '../../../../svg/common/learning.svg';
import { ReactComponent as PodcastIcon } from '../../../../svg/common/podcast.svg';
import { ReactComponent as BlogIcon } from '../../../../svg/common/blog.svg';
import { ReactComponent as ProductivityTipsIcon } from '../../../../svg/common/work-tip.svg';
import { ReactComponent as LinkIcon } from '../../../../svg/common/link.svg';
import { useQuery } from '@apollo/client';
import { AUTH_STATE, AuthValue } from '../../../../../apollo/stateFields/auth/authFields';

const ACTIVE_CLASS_NAME = 'hamburger-menu__active-link';

const StyledDrawer = styled(Drawer)`
  top: 0 !important;
`;

const StyledPageLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 53px 0 10px 0;

  &::after {
    content: '';
    display: block;
    height: 1px;
    margin: 16px 16px 0;
    background-color: #20263510;
  }
`;

const StyledActiveLink = styled(ActiveLink).attrs({
  activeClassName: ACTIVE_CLASS_NAME,
})`
  width: 100%;
  height: 44px;
  padding: 0 1rem;
  font-size: 20px;
  line-height: 16px;
  position: relative;

  &.${ACTIVE_CLASS_NAME} {
    // This color appears to be specific to this component. That's why we didn't create a CSS var for it.
    background-color: var(--color-grey-2);
  }

  svg {
    margin-right: 12px;
  }
`;

const StyledInfoMenuContainer = styled.div`
  margin-bottom: 24px;

  &::after {
    content: '';
    display: block;
    height: 1px;
    margin: 10px 16px 0 16px;
    background-color: #20263510;
  }
`;

const StyledDivider = styled.div`
  height: 1px;
  margin: 16px;
  background-color: #20263510;
`;

const StyledUserMenuContainer = styled.div`
  padding-bottom: 60px;
`;

const StyledUserMenu = styled(UserMenu)``;

const StyledLinkIcon = styled(LinkIcon)`
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  margin-right: 20px !important;
`;

const StyledLearningIcon = styled(LearningIcon)`
  color: #000000;
  transform: scale(0.9);
`;

const HamburgerMenu = React.memo(
  ({ className, visible, onClose, onMenuItemClick }: HamburgerMenuProps) => {
    const { data } = useQuery(AUTH_STATE);
    const { authUser }: AuthValue = data?.auth;

    const getIcon = (label: string) => {
      switch (label) {
        case 'Contact us':
          return <ContactIcon />;
        case 'F.A.Q.':
          return <HelpIcon />;
        case 'Blog':
          return <BlogIcon />;
        case 'Podcast':
          return <PodcastIcon />;
        case 'Productivity Tips':
          return <ProductivityTipsIcon />;
        default:
          return null;
      }
    };

    const renderPageLinks = (
      linkArray: typeof links,
      onMenuItemClick: HamburgerMenuProps['onMenuItemClick'],
    ) => {
      return linkArray.map(
        ({ text, to, isVisibleForLogged, isVisibleForUnLogged, isDivider, isBlank }) => {
          if ((authUser && isVisibleForLogged) || (!authUser && isVisibleForUnLogged)) {
            return (
              <div key={`${kebabCase(text)}-link`}>
                <StyledActiveLink
                  target={isBlank ? '_blank' : undefined}
                  key={`${kebabCase(text)}-link`}
                  to={to}
                  onClick={() => {
                    text === 'Learning materials' &&
                      sendEvent('learning_opened', 'Learning opened');
                    onMenuItemClick();
                  }}
                >
                  {getIcon(text)}
                  {text}
                  {isBlank && <StyledLinkIcon />}
                </StyledActiveLink>
                {isDivider && <StyledDivider />}
              </div>
            );
          }
        },
      );
    };

    useEffect(() => {
      if (visible) {
        const test = document.querySelector('[data-id="menu-drawer"] .ant-drawer-body');
        test?.scrollTo(0, 0);
      }
    }, [visible]);

    return (
      <StyledDrawer
        data-id="menu-drawer"
        className={className}
        visible={visible}
        placement="right"
        onClose={onClose}
        width="100%"
        contentWrapperStyle={{
          maxWidth: 384,
          boxShadow: 'none',
        }}
        bodyStyle={{
          padding: 0,
          backgroundColor: 'var(--color-main-grey)',
        }}
      >
        <StyledPageLinkContainer>{renderPageLinks(links, onMenuItemClick)}</StyledPageLinkContainer>
        {authUser && (
          <StyledInfoMenuContainer>
            <InfoMenu isForHamburgerMenu callback={onClose} />
          </StyledInfoMenuContainer>
        )}
        <StyledUserMenuContainer>
          <StyledUserMenu showDropdown={false} />
        </StyledUserMenuContainer>
      </StyledDrawer>
    );
  },
);

export { HamburgerMenu };

