import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FetchUserSettings, UpdateUserSettings } from '../../../graphql/types';
import { UPDATE_SETTINGS } from '../../../graphql/mutations';
import {
  FETCH_USER_WORKSPACES_WITH_ENTITIES,
  FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
} from '../../../features/common/graphql/queries';
import { ActionStatus } from '../../types/action';
import { sortEntitiesByDate } from '../../../features/actions/utils';
import {
  FetchAndFilterUserWorkspacesWithEntitiesRest,
  FetchUserWorkspacesWithEntities,
  FetchUserWorkspacesWithInvitedCount,
  FilterUserWorkspaces,
} from '../../../features/workspaces/types';
import { FILTER_USER_WORKSPACES } from '../../../features/workspaces/graphql/queries';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../apollo/stateFields/filterInput/filterInputFields';
import { filterMutation } from '../../../apollo/stateFields/filterInput';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../apollo/stateFields/userSettings/userSettingsField';
import { dndDispatch } from '../../../context/dndContext/dndContext';
import { BoardColumns, ColumnType } from '../../../context/dndContext/types';
import {
  addEntityIds,
  deleteDuplicates,
  deleteEntityIds,
  setBoardColumns,
  setFetchAllLoading,
  setIsUpdated,
} from 'context/dndContext/DndActions';
import useDndContext from '../../../context/dndContext/useDndContext';
import { apolloClient } from '../../../apollo/client';
import { removeKey } from '../../utils/removeKeyFn';
import { FETCH_SETTINGS } from '../../../graphql/queries';
import { Filter } from '../../../features/utils';
import { setTimeoutPromise } from '../../utils/promiseTimeout';
import { useUserWorkspaceContext } from '../../../context/userWorkspaceContext';
import { useDebounce } from '../../../hooks/useDebounce';
import axios from 'axios';
import { API_BASE_URL } from '../../integrations/api/config';
import { useAuth } from '../../../context/authContext';
import { addTypenameToAllWorkspaces } from '../../utils/mapRestToGraph';

const UserDndManager: FC = () => {
  const fetchAndFilterRef = useRef<boolean>(false);
  const { actions, outcomes } = useUserWorkspaceContext();
  const { boardColumns, isUpdated } = useDndContext();
  const dispatch = dndDispatch;
  const { outcomeColumn, noteColumn, backlogColumn, toDoColumn, doingColumn, doneColumn } =
    boardColumns;
  const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
  const { isSettingsLoaded, defaultWorkspaceId }: UserSettingsValue =
    userSettingsData?.userSettingsField;
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const { toggleFilterInputWorkspace, removeFilterInputWorkspace } = filterMutation;
  const { data: settingsData } = useQuery<FetchUserSettings>(FETCH_SETTINGS);
  const [updateSettings] = useMutation<UpdateUserSettings>(UPDATE_SETTINGS);
  const [loaded, setLoaded] = useState<boolean>(false);
  const prevLoadedStateRef = useRef<boolean>(false);
  const allDataLoaded = useRef<boolean>(false);
  const { idToken } = useAuth();
  const { data: workspacesResponse } = useQuery<FetchUserWorkspacesWithInvitedCount>(
    FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
    {
      fetchPolicy: 'cache-only',
      nextFetchPolicy: 'cache-and-network',
    },
  );
  const { data: fetchAllWorkspaces, refetch } = useQuery<FetchUserWorkspacesWithEntities>(
    FETCH_USER_WORKSPACES_WITH_ENTITIES,
    {
      fetchPolicy: 'cache-only',
    },
  );
  const [fetchedRESTWorkspaces, setFetchedRESTWorkspaces] =
    useState<FetchAndFilterUserWorkspacesWithEntitiesRest | null>(null);
  const [loading, setLoading] = useState(false);
  const fetchAllDndDataRest = async (filters: Filter[]) => {
    const res = await axios.post(
      `${API_BASE_URL}workspace/filter`,
      {
        filters,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          authorization: `Bearer ${idToken}`,
        },
      },
    );
    return res;
  };
  const isBoardColumnsLoaded = useMemo(() => {
    const isWithLength = Object.values(boardColumns)?.some((column) => !!column?.entityIds?.length);
    return isWithLength || (!isWithLength && !actions.length && !outcomes.length);
  }, [boardColumns, actions, outcomes]);

  useEffect(() => {
    if (loading) {
      prevLoadedStateRef.current = true;
      setLoaded(false);
    } else if (!loading && prevLoadedStateRef.current) {
      setLoaded(true);
      prevLoadedStateRef.current = false;
    }
    dispatch(setFetchAllLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (fetchedRESTWorkspaces && allDataLoaded?.current !== true) {
      apolloClient.writeQuery<FilterUserWorkspaces>({
        query: FILTER_USER_WORKSPACES,
        data: {
          filterUserWorkspaces: addTypenameToAllWorkspaces(fetchedRESTWorkspaces.filter),
        },
        variables: {
          filters: filterInput,
        },
      });
      apolloClient.writeQuery<FetchUserWorkspacesWithEntities>({
        query: FETCH_USER_WORKSPACES_WITH_ENTITIES,
        data: {
          fetchUserWorkspacesWithEntities: addTypenameToAllWorkspaces(
            fetchedRESTWorkspaces.all,
            true,
          ),
        },
        variables: {
          filters: filterInput,
        },
      });
      allDataLoaded.current = true;
    }
  }, [fetchedRESTWorkspaces]);

  const allWorkspaces = useMemo(() => {
    return fetchAllWorkspaces?.fetchUserWorkspacesWithEntities || [];
  }, [fetchAllWorkspaces?.fetchUserWorkspacesWithEntities]);

  const allActions = useMemo(
    () => allWorkspaces.map((w) => (w.actions ? w.actions : [])).flat(1),
    [allWorkspaces],
  );
  const backlogActions = useMemo(
    () => allActions.filter((action) => action.status === ActionStatus.BACKLOG),
    [allActions],
  );
  const toDoActions = useMemo(
    () => allActions.filter((action) => action.status === ActionStatus.TODO),
    [allActions],
  );
  const doingActions = useMemo(
    () => allActions.filter((action) => action.status === ActionStatus.DOING),
    [allActions],
  );
  const doneActions = useMemo(
    () => allActions.filter((action) => action.status === ActionStatus.DONE),
    [allActions],
  );

  const allOutcomes = useMemo(
    () =>
      allWorkspaces
        .map((w) => (w.outcomes ? w.outcomes : []))
        .flat(1)
        .sort(sortEntitiesByDate),
    [allWorkspaces],
  );

  const allNotes = useMemo(
    () =>
      allWorkspaces
        .map((w) => (w.notes ? w.notes : []))
        .flat(1)
        .sort(sortEntitiesByDate),
    [allWorkspaces],
  );

  const checkForUnusedEntities = useCallback(() => {
    if (!isBoardColumnsLoaded || !allWorkspaces?.length) {
      return;
    }
    let outcomeToDelete: number[] = [];
    outcomeColumn.entityIds.forEach((outcomeId) => {
      if (!allOutcomes.some((outcome) => outcome.id === outcomeId)) {
        outcomeToDelete.push(outcomeId);
      }
    });
    dispatch(deleteEntityIds({ entityIds: outcomeToDelete, columnTitle: ColumnType.Outcome }));

    let noteToDelete: number[] = [];
    noteColumn.entityIds.forEach((noteId) => {
      if (!allNotes.some((note) => noteId === note.id)) {
        noteToDelete.push(noteId);
      }
    });
    dispatch(deleteEntityIds({ entityIds: noteToDelete, columnTitle: ColumnType.Note }));

    let backlogToDelete: number[] = [];
    backlogColumn.entityIds.forEach((actionId) => {
      const foundAction = backlogActions.find((action) => actionId === action.id);
      if (!foundAction || foundAction?.status !== ActionStatus.BACKLOG) {
        backlogToDelete.push(actionId);
      }
    });
    dispatch(deleteEntityIds({ entityIds: backlogToDelete, columnTitle: ColumnType.Backlog }));

    let toDoToDelete: number[] = [];
    toDoColumn.entityIds.forEach((actionId) => {
      const foundAction = toDoActions.find((action) => actionId === action.id);
      if (!foundAction || foundAction?.status !== ActionStatus.TODO) {
        toDoToDelete.push(actionId);
      }
    });
    dispatch(deleteEntityIds({ entityIds: toDoToDelete, columnTitle: ColumnType.Todo }));

    let doingToDelete: number[] = [];
    doingColumn.entityIds.forEach((actionId, index) => {
      const foundAction = doingActions.find((action) => actionId === action.id);
      if (!foundAction || foundAction?.status !== ActionStatus.DOING) {
        doingToDelete.push(actionId);
      }
    });
    dispatch(deleteEntityIds({ entityIds: doingToDelete, columnTitle: ColumnType.Doing }));

    let doneToDelete: number[] = [];
    doneColumn.entityIds.forEach((actionId) => {
      const foundAction = doneActions.find((action) => actionId === action.id);
      if (!foundAction || foundAction?.status !== ActionStatus.DONE) {
        doneToDelete.push(actionId);
      }
    });
    dispatch(deleteEntityIds({ entityIds: doneToDelete, columnTitle: ColumnType.Done }));
    dispatch(deleteDuplicates());
  }, [allWorkspaces, allNotes, allActions, allOutcomes, boardColumns]);

  const checkForNewOrderEntities = useCallback(() => {
    if (!allWorkspaces?.length) {
      return;
    }
    const outomeIds: number[] = [];
    allOutcomes.forEach((outcome) => {
      if (!outcomeColumn.entityIds.some((id) => id === outcome.id)) {
        outomeIds.push(outcome.id);
      }
    });
    dispatch(addEntityIds({ entityIds: outomeIds, columnTitle: ColumnType.Outcome }));

    const noteIds: number[] = [];
    allNotes.forEach((note) => {
      if (!noteColumn.entityIds.some((id) => id === note.id)) {
        noteIds.push(note.id);
      }
    });
    dispatch(addEntityIds({ entityIds: noteIds, columnTitle: ColumnType.Note }));

    const backlogIds: number[] = [];
    backlogActions.forEach((action) => {
      if (!backlogColumn.entityIds.some((id) => id === action.id)) {
        backlogIds.push(action.id);
      }
    });
    dispatch(addEntityIds({ entityIds: backlogIds, columnTitle: ColumnType.Backlog }));

    const toDoIds: number[] = [];
    toDoActions.forEach((action) => {
      if (!toDoColumn.entityIds.some((id) => id === action.id)) {
        toDoIds.push(action.id);
      }
    });
    dispatch(addEntityIds({ entityIds: toDoIds, columnTitle: ColumnType.Todo }));

    const doingIds: number[] = [];
    doingActions.forEach((action) => {
      if (!doingColumn.entityIds.some((id) => id === action.id)) {
        doingIds.push(action.id);
      }
    });
    dispatch(addEntityIds({ entityIds: doingIds, columnTitle: ColumnType.Doing }));

    const doneIds: number[] = [];
    doneActions.forEach((action) => {
      if (!doneColumn.entityIds.some((id) => id === action.id)) {
        doneIds.push(action.id);
      }
    });
    dispatch(addEntityIds({ entityIds: doneIds, columnTitle: ColumnType.Done }));
  }, [allWorkspaces, allNotes, allActions, allOutcomes, boardColumns]);

  const checkFilterInput = useCallback(() => {
    filterInput.forEach((input) => {
      if (!allWorkspaces.some((workspace) => workspace.id === input.workspaceId)) {
        removeFilterInputWorkspace(input.workspaceId);
      }
    });
  }, [filterInput, allWorkspaces]);

  const debouncedSettingsUpdate = useDebounce((boardColumns: BoardColumns) => {
    if (isBoardColumnsLoaded) {
      updateSettings({
        variables: {
          settings: {
            boardColumns,
            filterInput,
          },
        },
      });
    }
  }, 700);

  useEffect(() => {
    if (settingsData?.fetchUserSettings?.boardColumns) {
      const existedBoardColumns = removeKey(
        settingsData?.fetchUserSettings.boardColumns,
        '__typename',
      );
      const isBoardColumnsLoaded = Object.values(existedBoardColumns as BoardColumns)?.some(
        (column) => !!column?.entityIds?.length,
      );

      isBoardColumnsLoaded && dispatch(setBoardColumns(existedBoardColumns));
    }
  }, []);

  useEffect(() => {
    if (
      isSettingsLoaded &&
      allWorkspaces.length &&
      (isBoardColumnsLoaded || (!isBoardColumnsLoaded && actions.length && outcomes.length))
    ) {
      if (!isUpdated) {
        checkForNewOrderEntities();
        checkFilterInput();
        dispatch(setIsUpdated(true));
        return;
      }
      debouncedSettingsUpdate(boardColumns);
    }
  }, [boardColumns, isSettingsLoaded, isUpdated, allWorkspaces]);

  useEffect(() => {
    if (isSettingsLoaded && !!allWorkspaces.length && isUpdated) {
      checkForNewOrderEntities();
      debouncedSettingsUpdate(boardColumns);
    }
  }, [allWorkspaces]);

  //for test checking unused entities
  useEffect(() => {
    if (loaded && isUpdated && isBoardColumnsLoaded) {
      setTimeoutPromise(5).then(() => {
        checkForUnusedEntities();
        debouncedSettingsUpdate(boardColumns);
      });
    }
  }, [loaded, isUpdated, isBoardColumnsLoaded]);

  useEffect(() => {
    if (fetchAndFilterRef.current) {
      refetch();
    }
  }, [isSettingsLoaded, workspacesResponse?.fetchUserWorkspacesWithInvitedCount.length]);

  useEffect(() => {
    const fetchData = async (filters: Filter[]) => {
      setLoading(true);
      const data = await fetchAllDndDataRest(filters);
      if (data.data) {
        fetchAndFilterRef.current = true;
        setFetchedRESTWorkspaces(data.data);
      }
      setLoading(false);
    };
    if (
      isSettingsLoaded &&
      filterInput.length &&
      !!workspacesResponse?.fetchUserWorkspacesWithInvitedCount.length &&
      !fetchAndFilterRef.current
    ) {
      let filterInputCopy = filterInput.slice();
      let filters = filterInputCopy;
      if (filters.length === 0) {
        toggleFilterInputWorkspace(defaultWorkspaceId);
        filters = [{ workspaceId: defaultWorkspaceId, tagIds: [] }];
      }
      fetchData(filters);
    }
  }, [
    isSettingsLoaded,
    filterInput,
    workspacesResponse?.fetchUserWorkspacesWithInvitedCount.length,
  ]);

  return <></>;
};

export { UserDndManager };
