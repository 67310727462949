import { OutcomeStatus } from '../../core/types/outcome';

export enum ColumnType {
  Outcome = 'Outcome',
  Backlog = 'Backlog',
  Todo = 'Todo',
  Doing = 'Doing',
  Done = 'Done',
  Note = 'Note',
}
export interface Column {
  id: number;
  title: ColumnType;
  entityIds: number[];
}
export interface BoardColumns {
  outcomeColumn: Column;
  backlogColumn: Column;
  toDoColumn: Column;
  doingColumn: Column;
  doneColumn: Column;
  noteColumn: Column;
}

export interface DndContextType {
  boardColumns: BoardColumns;
  currentOutcomeColumnStatus: OutcomeStatus;
  isUpdated: boolean;
  fetchAllLoading: boolean;
  changingSpaceEntityId: null | number;
}
export type DndColumnPayload = {
  entityId: number;
  columnTitle: ColumnType;
};
export type DndColumnsPayload = {
  entityIds: number[];
  columnTitle: ColumnType;
};

export enum DndActionTypes {
  SetCurrentOutcomeColumnStatus = 'SET_CURRENT_OUTCOME_COLUMN_STATUS',
  SetChangingSpaceEntityId = 'SET_CHANGING_SPACE_ENTITY_ID',
  SetIsUpdated = 'SET_IS_UPDATED',
  SetBoardColumns = 'SET_BOARD_COLUMNS',
  AddEntityId = 'ADD_ENTITY_ID',
  AddEntityIds = 'ADD_ENTITIES_IDS',
  DeleteEntityId = 'DELETE_ENTITY_ID',
  DeleteEntityIds = 'DELETE_ENTITY_IDS',
  DeleteEntityIdFromAllColumns = 'DELETE_ENTITY_ID_FROM_ALL_COLUMNS',
  UpdateEntityId = 'UPDATE_ENTITY_ID',
  DeleteDuplicates = 'DELETE_DUPLICATES',
  ChangeEntityIds = 'CHANGE_ENTITY_IDS',
  ResetDnd = 'RESET_DND',
  FetchAllLoading = 'FETCH_ALL_LOADING',
}

export interface SetCurrentOutcomeColumnStatus {
  type: DndActionTypes.SetCurrentOutcomeColumnStatus;
  payload: OutcomeStatus;
}
export interface SetChangingSpaceEntityId {
  type: DndActionTypes.SetChangingSpaceEntityId;
  payload: number | null;
}
export interface SetIsUpdated {
  type: DndActionTypes.SetIsUpdated;
  payload: boolean;
}
export interface SetBoardColumns {
  type: DndActionTypes.SetBoardColumns;
  payload: BoardColumns;
}
export interface SetFetchAllLoading {
  type: DndActionTypes.FetchAllLoading;
  payload: boolean;
}
export interface AddEntityId {
  type: DndActionTypes.AddEntityId;
  payload: DndColumnPayload;
}
export interface AddEntityIds {
  type: DndActionTypes.AddEntityIds;
  payload: DndColumnsPayload;
}
export interface DeleteEntityId {
  type: DndActionTypes.DeleteEntityId;
  payload: DndColumnPayload;
}
export interface DeleteEntityIds {
  type: DndActionTypes.DeleteEntityIds;
  payload: DndColumnsPayload;
}
export interface DeleteEntityIdFromAllColumns {
  type: DndActionTypes.DeleteEntityIdFromAllColumns;
  payload: { entityId: number };
}
export interface UpdateEntityId {
  type: DndActionTypes.UpdateEntityId;
  payload: DndColumnPayload;
}
export interface DeleteDuplicates {
  type: DndActionTypes.DeleteDuplicates;
}
export interface ChangeEntityIds {
  type: DndActionTypes.ChangeEntityIds;
  payload: { entityIds: number[]; columnTitle: ColumnType };
}
export interface ResetDndAction {
  type: DndActionTypes.ResetDnd;
}

export type DndActions =
  | SetCurrentOutcomeColumnStatus
  | SetChangingSpaceEntityId
  | SetIsUpdated
  | SetBoardColumns
  | AddEntityId
  | AddEntityIds
  | DeleteEntityId
  | DeleteEntityIds
  | DeleteEntityIdFromAllColumns
  | UpdateEntityId
  | DeleteDuplicates
  | ChangeEntityIds
  | SetFetchAllLoading
  | ResetDndAction;
