import { CUSTOM_ENTITY_TYPES } from '../apollo/stateFields/error/errorFields';
import { FetchUserWorkspaceAction, FetchUserWorkspaceNote } from '../features/actions/types';
import { FETCH_ACTION, FETCH_NOTE } from '../features/actions/graphql/queries';
import { FetchOutcome } from '../features/outcomes/types';
import { FETCH_OUTCOME } from '../features/outcomes/graphql/queries';
import { useCallback, useEffect, useState } from 'react';
import { Action } from '../core/types/action';
import { Outcome } from '../core/types/outcome';
import { Note } from '../core/types/note';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { Entity } from '../core/types/entity';

export const useLazyLoadEntity = (entityType: CUSTOM_ENTITY_TYPES, workspaceId?: number) => {
  const [data, setData] = useState<Action | Outcome | Note | undefined | null>(null);
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [getAction, { data: action, error: actionError, loading: actionLoading }] =
    useLazyQuery<FetchUserWorkspaceAction>(FETCH_ACTION, {
      fetchPolicy: 'no-cache',
    });
  const [getOutcome, { data: outcome, error: outcomeError, loading: outcomeLoading }] =
    useLazyQuery<FetchOutcome>(FETCH_OUTCOME, { fetchPolicy: 'no-cache' });
  const [getNote, { data: note, error: noteError, loading: noteLoading }] =
    useLazyQuery<FetchUserWorkspaceNote>(FETCH_NOTE, { fetchPolicy: 'no-cache' });

  const getEntity = useCallback(
    async (entity: Entity) => {
      switch (entityType) {
        case CUSTOM_ENTITY_TYPES.ACTION:
          return (
            !actionLoading &&
            (await getAction({
              variables: {
                actionId: entity.id,
                workspaceId: workspaceId || entity.workspaceId,
              },
            }))
          );
        case CUSTOM_ENTITY_TYPES.OUTCOME:
          return (
            !outcomeLoading &&
            (await getOutcome({
              variables: {
                outcomeId: entity.id,
                workspaceId: workspaceId || entity.workspaceId,
              },
            }))
          );
        case CUSTOM_ENTITY_TYPES.NOTE:
          return await getNote({
            variables: {
              noteId: entity.id,
              workspaceId: workspaceId || entity.workspaceId,
            },
          });
      }
    },
    [workspaceId, actionLoading, outcomeLoading, noteLoading, entityType],
  );

  useEffect(() => {
    resetData();
  }, [workspaceId]);

  useEffect(() => {
    switch (entityType) {
      case CUSTOM_ENTITY_TYPES.ACTION:
        if (action?.fetchUserWorkspaceAction) {
          setData(action?.fetchUserWorkspaceAction);
        }
        setError(actionError);
        setLoading(actionLoading);
        break;
      case CUSTOM_ENTITY_TYPES.OUTCOME:
        if (outcome?.fetchUserWorkspaceOutcome) {
          setData(outcome.fetchUserWorkspaceOutcome);
        }
        setError(outcomeError);
        setLoading(outcomeLoading);
        break;
      case CUSTOM_ENTITY_TYPES.NOTE:
        if (note?.fetchUserWorkspaceNote) {
          setData(note?.fetchUserWorkspaceNote);
        }
        setError(noteError);
        setLoading(noteLoading);
        break;
    }
  }, [action, outcome, note, actionLoading, outcomeLoading, noteLoading, entityType]);

  const resetData = () => {
    setData(null);
    setError(undefined);
    setLoading(false);
  };

  return {
    getEntity,
    data,
    error,
    loading,
    resetData,
  };
};
