import React from 'react';
import {
  StyledCursiveTipsText,
  StyledTipsHeader,
  StyledTipsText,
  StyledTipsTitle,
} from '../StyledComponents';

const MasterYourUnpredictableDayTips = () => {
  return (
    <div>
      <StyledTipsHeader>Hacks, Tips & Tricks - Master Your Unpredictable Day</StyledTipsHeader>
      <StyledTipsText>
        Personal effectiveness is <StyledCursiveTipsText>personal</StyledCursiveTipsText>, so
        BillionMinds tends to steer clear of recommending specific life hacks that may not work for
        the individual. However, we do provide a small number, which have been shown to be effective
        across a large proportion of our users. Try these, and let us know what works and what
        doesn’t!
      </StyledTipsText>
      <StyledTipsTitle>Be prepared for times when change happens more often</StyledTipsTitle>
      <StyledTipsText>
        For example, many BillionMinders go into meetings with the Incoming element already open, so
        they can enter them in real time.
      </StyledTipsText>
      <StyledTipsTitle>
        Separate collecting information about changes from taking action on them
      </StyledTipsTitle>
      <StyledTipsText>
        The CTA routine (Collect, Triage, Act) is a repeatable process that allows you to reduce the
        uncertainty associated with change.
      </StyledTipsText>
      <StyledTipsTitle>Consider when to take on change of your own making</StyledTipsTitle>
      <StyledTipsText>
        Every change you take on (positive or negative) creates additional work for you. A change
        may be important to make, but you may not be ready to take it on right away because of how
        busy the rest of your life is. If you are NOT ready, start planning the steps you need to
        take to simplify your life so you can take on the change.
      </StyledTipsText>
      <StyledTipsTitle>Communicate changes to others ahead of time</StyledTipsTitle>
      <StyledTipsText>
        Any change you undergo is likely to have a knock on effect on others, and the sooner that
        others are aware of change, the easier they can adapt to it. So as you become aware of
        change, ask yourself “who else will be affected by this change?” and form a plan on how to
        communicate it to them.
      </StyledTipsText>
      <StyledTipsTitle>Reframe change that happens to you as an opportunity</StyledTipsTitle>
      <StyledTipsText>
        Each change alters the underlying dynamic in which you work and live. When a significant
        change happens, use it as an opportunity to reevaluate your life, and the role you want to
        play, but…
      </StyledTipsText>
      <StyledTipsTitle>
        Be cautious about your decision making at times of significant change
      </StyledTipsTitle>
      <StyledTipsText>
        Significant change, such as a major life event causes stress, and this impacts our ability
        to make good decisions. At these times, be sure to emphasize self-care and seek the counsel
        of others before you make any additional decisions.
      </StyledTipsText>
    </div>
  );
};

export default MasterYourUnpredictableDayTips;
