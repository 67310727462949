import React, { useContext, createContext, useMemo, useCallback, useEffect } from 'react';
import { ApolloClient, useQuery } from '@apollo/client';

import { Workspace } from 'core/types/workspace';
import { Action } from 'core/types/action';
import { Outcome } from 'core/types/outcome';
import { FilterUserWorkspaces } from '../features/workspaces/types';
import { FILTER_USER_WORKSPACES } from '../features/workspaces/graphql/queries';
import { Filter } from 'features/utils';
import { checkWithTagsIsShownEntity } from '../features/utils/checkIsShownEntity';
import usePollDataOnActivity from '../hooks/usePollDataOnActivity';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../apollo/stateFields/filterInput/filterInputFields';
import useDndContext from './dndContext/useDndContext';
import { FETCH_FOCUS_TIMER } from '../features/actions/graphql/queries';
import { FetchFocusTimer } from '../features/actions/types';

interface IContext {
  workspaces: Workspace[];
  actions: Action[];
  outcomes: Outcome[];
  refetch: () => void;
  loading: boolean;
}

const UserWorkspaceContext = createContext<IContext>({
  workspaces: [],
  actions: [],
  outcomes: [],
  refetch: () => undefined,
  loading: false,
});

const UserWorkspaceProvider = React.memo(({ children }: { children: any }) => {
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const { isUpdated } = useDndContext();

  const { data, refetch, loading, startPolling, stopPolling } = useQuery<FilterUserWorkspaces>(
    FILTER_USER_WORKSPACES,
    {
      skip: filterInput.length === 0,
      variables: { filters: filterInput },
      fetchPolicy: 'cache-only',
    },
  );

  const {
    refetch: timerRefetch,
    startPolling: startTimerPolling,
    stopPolling: stopTimerPolling,
  } = useQuery<FetchFocusTimer>(FETCH_FOCUS_TIMER);

  const handleFilterRefetch = useCallback(() => {
    filterInput.length !== 0 && refetch();
    return;
  }, [refetch, filterInput]);

  const handleStartPolling = useCallback(
    (timer: number) => {
      if (filterInput.length !== 0) {
        return startPolling(timer);
      }
      return;
    },
    [startPolling, filterInput],
  );

  usePollDataOnActivity(
    handleStartPolling,
    stopPolling,
    handleFilterRefetch,
    undefined,
    !filterInput.length,
  );
  usePollDataOnActivity(startTimerPolling, stopTimerPolling, timerRefetch, undefined);

  const { filterUserWorkspaces = [] } = data || {};

  const userWorkspaces = useMemo(
    () => (filterInput.length || filterUserWorkspaces ? filterUserWorkspaces : []),
    [filterUserWorkspaces, filterInput],
  );

  const actions = useMemo(
    () => userWorkspaces.map((w) => (w.actions ? w.actions : [])).flat(1),
    [userWorkspaces],
  );

  const outcomes = useMemo(
    () => userWorkspaces.map((w) => (w.outcomes ? w.outcomes : [])).flat(1),
    [userWorkspaces],
  );

  const handleRefetch = useCallback(() => {
    if (refetch) {
      refetch();
    }
  }, [refetch]);

  const value = useMemo(() => {
    return {
      workspaces: userWorkspaces,
      actions,
      outcomes,
      refetch: handleRefetch,
      loading,
    };
  }, [userWorkspaces, actions, outcomes, handleRefetch, loading]);

  useEffect(() => {
    if (filterInput.length !== 0 && isUpdated) {
      refetch();
    }
  }, [filterInput, refetch]);

  return <UserWorkspaceContext.Provider value={value}>{children}</UserWorkspaceContext.Provider>;
});

const useUserWorkspaceContext = () => {
  return useContext(UserWorkspaceContext);
};

type Operation = 'remove' | 'update' | 'create' | 'archive' | 'unarchive';

const mutateAction = (
  client: ApolloClient<any>,
  entity: Action,
  operation: Operation,
  filterInput: Filter[],
  actionToUpdate?: Action,
) => {
  const data = client.readQuery<FilterUserWorkspaces>({
    query: FILTER_USER_WORKSPACES,
    variables: {
      filters: filterInput,
    },
  });

  if (!data) {
    return;
  }

  const action = entity;
  const updatedData = { ...data };

  switch (operation) {
    case 'create':
      updatedData.filterUserWorkspaces = updatedData.filterUserWorkspaces.map(
        (workspace: Workspace) => {
          if (workspace.id === entity.workspaceId) {
            const actions = workspace.actions || [];
            let outcomes = workspace.outcomes?.slice() || [];
            if (!!entity.outcome?.id) {
              let updatedOutcome = outcomes.find((outcome) => outcome.id === entity.outcome?.id);
              if (updatedOutcome) {
                const updatedOutcomes = [
                  ...outcomes.filter((outcome) => outcome.id !== updatedOutcome!.id),
                  {
                    ...updatedOutcome,
                    actions: updatedOutcome?.actions
                      ? [...updatedOutcome?.actions, entity]
                      : [entity],
                  },
                ];
                return checkWithTagsIsShownEntity(action, filterInput)
                  ? { ...workspace, actions: [action, ...actions], outcomes: updatedOutcomes }
                  : { ...workspace, actions, outcomes: updatedOutcomes };
              }
            }

            return checkWithTagsIsShownEntity(action, filterInput)
              ? { ...workspace, actions: [action, ...actions] }
              : workspace;
          }
          return workspace;
        },
      );
      break;
    case 'remove':
      updatedData.filterUserWorkspaces = updatedData.filterUserWorkspaces.map(
        (workspace: Workspace) => {
          if (workspace.id === entity.workspaceId) {
            const actions = workspace.actions || [];
            let outcomes = workspace.outcomes?.slice() || [];

            if (entity.outcome?.id) {
              outcomes = outcomes.map((outcome) => {
                if (outcome.id === action.outcome?.id) {
                  return {
                    ...outcome,
                    actions: outcome?.actions
                      ? [...outcome.actions.filter((oldAction) => oldAction.id !== entity.id)]
                      : [],
                  };
                }
                return outcome;
              });
            }
            return {
              ...workspace,
              actions: actions.filter((action: Action) => action.id !== entity.id),
              outcomes,
            };
          }
          return workspace;
        },
      );
      break;
    case 'update':
      updatedData.filterUserWorkspaces = updatedData.filterUserWorkspaces.map(
        (workspace: Workspace) => {
          const actions = workspace.actions || [];
          let outcomes = workspace.outcomes?.slice() || [];

          if (action.outcome && action.workspaceId === workspace.id) {
            outcomes = outcomes.map((outcome) => {
              if (outcome.id === action.outcome?.id) {
                return {
                  ...outcome,
                  actions: outcome?.actions
                    ? outcome.actions?.some((oAction) => oAction.id === action.id)
                      ? [...outcome.actions]
                      : [...outcome?.actions, action]
                    : [action],
                };
              } else if (
                outcome.id === actionToUpdate?.outcome?.id &&
                actionToUpdate?.outcome?.id !== action.outcome?.id
              ) {
                return {
                  ...outcome,
                  actions: outcome?.actions
                    ? [...outcome.actions.filter((oldAction) => oldAction.id !== entity.id)]
                    : [],
                };
              }
              return outcome;
            });
          } else if (
            actionToUpdate &&
            actionToUpdate?.outcome &&
            actionToUpdate.workspaceId === workspace.id
          ) {
            outcomes = outcomes.map((outcome) => {
              if (outcome.id === actionToUpdate?.outcome?.id && action.outcome === null) {
                return {
                  ...outcome,
                  actions: outcome?.actions
                    ? [...outcome.actions.filter((oldAction) => oldAction.id !== entity.id)]
                    : [],
                };
              }
              return outcome;
            });
          }

          const filter = filterInput.find((filter) => filter.workspaceId === action.workspaceId);
          const shouldShow = filter?.tagIds.some((id) => action.tags?.some((tag) => tag.id === id));

          if (actionToUpdate?.workspaceId && actionToUpdate?.workspaceId !== action.workspaceId) {
            if (workspace.id === actionToUpdate?.workspaceId) {
              return {
                ...workspace,
                actions: actions.filter((action) => action.id !== entity.id),
                outcomes,
              };
            }
            if (
              workspace.id === action.workspaceId &&
              filter?.tagIds.length &&
              action?.tags &&
              (!action.tags?.length || !shouldShow)
            ) {
              return {
                ...workspace,
                outcomes,
              };
            } else if (workspace.id === action.workspaceId && !filter?.tagIds.length) {
              return {
                ...workspace,
                actions: [action, ...actions],
                outcomes,
              };
            }
          }

          if (workspace.id === entity.workspaceId) {
            if (entity.isArchived) {
              return {
                ...workspace,
                actions: actions.filter((action: Action) => action.id !== entity.id),
                outcomes,
              };
            } else if (actionToUpdate?.isArchived && !entity.isArchived) {
              return {
                ...workspace,
                actions: [action, ...actions],
                outcomes,
              };
            } else if (filter?.tagIds.length && !shouldShow) {
              return {
                ...workspace,
                actions: [...actions.filter((action: Action) => action.id !== entity.id)],
                outcomes,
              };
            }

            return {
              ...workspace,
              actions: [action, ...actions.filter((action: Action) => action.id !== entity.id)],
              outcomes,
            };
          }
          return workspace;
        },
      );
  }

  client.writeQuery<FilterUserWorkspaces>({
    query: FILTER_USER_WORKSPACES,
    data: updatedData,
    variables: {
      filters: filterInput,
    },
  });
};

const mutateOutcome = (
  client: ApolloClient<any>,
  entity: Outcome,
  operation: Operation,
  filterInput: Filter[],
  outcomeToUpdate?: Outcome,
  withActions?: boolean,
) => {
  const data = client.readQuery<FilterUserWorkspaces>({
    query: FILTER_USER_WORKSPACES,
    variables: {
      filters: filterInput,
    },
  });

  if (!data) {
    return;
  }

  const outcome = entity;
  const updatedData = { ...data };

  switch (operation) {
    case 'create':
      updatedData.filterUserWorkspaces = updatedData.filterUserWorkspaces.map(
        (workspace: Workspace) => {
          if (workspace.id === entity.workspaceId) {
            const outcomes = workspace.outcomes || [];
            return { ...workspace, outcomes: [outcome, ...outcomes] };
          }
          return workspace;
        },
      );
      break;
    case 'remove':
      updatedData.filterUserWorkspaces = updatedData.filterUserWorkspaces.map(
        (workspace: Workspace) => {
          if (workspace.id === entity.workspaceId) {
            const outcomes = workspace.outcomes || [];
            return {
              ...workspace,
              outcomes: outcomes.filter((outcome: Outcome) => outcome.id !== entity.id),
            };
          }
          return workspace;
        },
      );
      break;
    case 'update':
      updatedData.filterUserWorkspaces = updatedData.filterUserWorkspaces.map(
        (workspace: Workspace) => {
          const outcomes = workspace.outcomes || [];
          const actions = workspace.actions || [];

          if (outcomeToUpdate && outcomeToUpdate.workspaceId !== outcome.workspaceId) {
            if (workspace.id === outcomeToUpdate.workspaceId) {
              return {
                ...workspace,
                outcomes: outcomes.filter((outcome) => outcome.id !== entity.id),
                actions: outcome.actions?.length
                  ? actions.filter(
                      (action) =>
                        !outcome.actions?.some((outcomeAction) => outcomeAction.id === action.id),
                    )
                  : actions,
              };
            }
            if (workspace.id === outcome.workspaceId) {
              return {
                ...workspace,
                outcomes: [outcome, ...outcomes],
                actions: outcome.actions?.length
                  ? [
                      ...actions.filter((action) => {
                        return !outcome.actions?.some(
                          (outcomeAction) => outcomeAction.id === action.id,
                        );
                      }),
                      ...outcome.actions.map((action) => ({
                        ...action,
                        workspaceId: outcome.workspaceId,
                      })),
                    ]
                  : actions,
              };
            }
          }
          return workspace;
        },
      );
      break;
    case 'archive':
      updatedData.filterUserWorkspaces = updatedData.filterUserWorkspaces.map(
        (workspace: Workspace) => {
          const actions = workspace.actions || [];
          let outcomes = workspace.outcomes?.slice() || [];

          if (outcomeToUpdate && workspace.id === entity.workspaceId && entity.isArchived) {
            const updatedActions = actions
              .filter((item) =>
                outcomeToUpdate.actions?.some((archivedItem) => archivedItem.id === item.id),
              )
              .map((updatedItem) => {
                return withActions ? { ...updatedItem } : { ...updatedItem, outcome: null };
              });
            const unUpdatedActions = actions.filter(
              (item) =>
                !outcomeToUpdate.actions?.some((archivedItem) => archivedItem.id === item.id),
            );
            if (withActions) {
              return {
                ...workspace,
                actions: [...unUpdatedActions],
                outcomes: outcomes.filter((outcome: Outcome) => outcome.id !== entity.id),
              };
            } else {
              return {
                ...workspace,
                actions: [...unUpdatedActions, ...updatedActions],
                outcomes: outcomes.filter((outcome: Outcome) => outcome.id !== entity.id),
              };
            }
          }

          return workspace;
        },
      );
      break;
    case 'unarchive':
      updatedData.filterUserWorkspaces = updatedData.filterUserWorkspaces.map(
        (workspace: Workspace) => {
          const actions = workspace.actions || [];
          let outcomes = workspace.outcomes?.slice() || [];

          if (
            outcomeToUpdate?.isArchived &&
            !entity.isArchived &&
            workspace.id === entity.workspaceId
          ) {
            if (entity.actions?.length) {
              return {
                ...workspace,
                outcomes: [entity, ...outcomes],
                actions: [...actions, ...entity.actions],
              };
            }
            return {
              ...workspace,
              outcomes: [entity, ...outcomes],
            };
          }

          return workspace;
        },
      );
      break;
  }

  client.writeQuery<FilterUserWorkspaces>({
    query: FILTER_USER_WORKSPACES,
    data: updatedData,
    variables: {
      filters: filterInput,
    },
  });
};

export { UserWorkspaceProvider, useUserWorkspaceContext, mutateAction, mutateOutcome };
