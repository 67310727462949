import React, { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { omit } from 'lodash';
import Highlighter from 'react-highlight-words';
import { NoteCardProps } from './types';
import { EntityCard } from '../../../../../common/components';
import { capitalize } from 'lodash-es';
import { NOTE_MENU_OPTIONS_BY_STATE, NoteMenuOptions } from '../types';
import { toast, ToastContent } from 'react-toastify';
import { useApolloClient, useQuery } from '@apollo/client';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../../../../../common/graphql/queries';
import { ConfirmModal } from '../../../../../common/components/ConfirmModal';
import { getOutputWorkspaceId } from '../utils';
import useDeleteNote from '../../../../../interactions/note/useDeleteNote';
import { ContextMenuOption } from '../../../../../../core/components/common/Card/types';
import { sortWorkspaces } from '../../utils';
import WorkspaceSelectMenu from '../../../../../common/components/WorkspaceSelectMenu/WorkspaceSelectMenu';
import { NOT_ASSIGNED_WORKSPACE_NAME } from '../../../../../../core/constants/others';
import { sendEvent } from '../../../../../../core/integrations/sentry/events';
import { useMediaQuery } from 'react-responsive';
import theme from '../../../../../../core/styles/styled-components/theme';
import { ReactComponent as LockIcon } from '../svg/lock.svg';
import { SharedPeopleIcon } from '../../../../../workspaces/components/ShareWorkspace/SharedPeopleIcon/SharedPeopleIcon';
import { FetchUserWorkspacesWithInvitedCount } from '../../../../../workspaces/types';
import { Permission } from '../../../../../../core/types/workspace';
import { EntityName } from '../../../../../utils';
import useMoveNote from '../../../../../interactions/note/useMoveNote';
import useUpdateNote from '../../../../../interactions/note/useUpdateNote';
import { useCheckSharedEntityMoved } from '../../../../../../hooks/useCheckSharedEntityMoved';
import { CUSTOM_ENTITY_TYPES } from '../../../../../../apollo/stateFields/error/errorFields';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../../../apollo/stateFields/userSettings/userSettingsField';
import { boardEditedEntityMutation } from '../../../../../../apollo/stateFields/boardEditedEntity';
import useWorkspaceInvites from '../../../../../../hooks/useWorkspaceInvites';

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledSharedPeopleIcon = styled(SharedPeopleIcon)`
  margin-right: 10px;
  margin-left: 2px;
`;

const StyledNoteWorkspaceWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
  line-height: 1;
`;

const StyledNoteWorkspace = styled.span<{ isShared?: boolean }>`
  padding: 0.167rem;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  font-size: 0.5rem;
  background-color: ${(props) =>
    props.isShared ? 'rgba(255, 194, 39, 0.4)' : 'rgba(177, 190, 249, 0.4)'};
  color: ${(props) => (props.isShared ? '#4A3602' : '#102DBC')};

  @media ${theme.device.mobile.max} {
    font-size: 9px;
  }
`;

const StyledNoteName = styled.span`
  line-height: 1.286;
  font-size: 0.667rem;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  @media ${theme.device.mobile.max} {
    font-size: 12px;
  }

  @media ${theme.device.desktop.min} {
    max-width: 300px;
  }
`;

const StyledNoteDescription = styled(StyledNoteName)`
  font-weight: 500;
`;

const StyledLockIcon = styled(LockIcon)`
  margin-right: 5px;
  margin-bottom: -2px;
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledDate = styled.span`
  color: #a4a6ad;
  font-weight: 400;
  font-size: 0.563rem;
  white-space: nowrap;

  @media ${theme.device.mobile.max} {
    font-size: 9px;
  }
`;

const StyledWorkspaceSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledBottomContent = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
`;

const StyledConfirmModal = styled(ConfirmModal)`
  && {
    .ant-modal-body > div {
      div:last-child {
        margin-bottom: 0;
        margin-top: 20px;
      }
    }
  }
`;

const StyledEntityCard = styled(({ isShared, disabled, ...rest }) => <EntityCard {...rest} />)<{
  disabled?: boolean;
  isShared?: boolean;
}>`
  position: relative;
  &:before {
    content: '';
    height: calc(100% - 6px);
    width: 1px;
    background-color: ${({ isShared }) => (isShared ? 'var(--color-yellow)' : 'transparent')};

    position: absolute;
    left: -1px;
    top: 3px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      ${StyledNoteDescription},
      ${StyledDate} {
        color: var(--color-grey);
      }
    `}

  .options-button {
    top: 6px;
  }
`;

const setDateFormat = (date: string) => {
  return new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(
    new Date(date),
  );
};

const NoteCard = React.memo(({ note, highlightText, isNested, ...restOfProps }: NoteCardProps) => {
  const apolloClient = useApolloClient();
  const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
  const { defaultWorkspaceId }: UserSettingsValue = userSettingsData?.userSettingsField;
  const { showEditNoteModal, showConvertNoteToActionModal, showConvertNoteToOutcomeModal } =
    boardEditedEntityMutation;
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const { data: workspacesResponse } = useQuery<FetchUserWorkspacesWithInvitedCount>(
    FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
  );
  const workspaces =
    workspacesResponse?.fetchUserWorkspacesWithInvitedCount
      .slice()
      .sort((a, b) => sortWorkspaces(a, b, defaultWorkspaceId)) || [];
  const [deleteNote] = useDeleteNote(note);
  const [updateNote] = useUpdateNote(note);
  const [moveNote, { loading: loadingMoveNote }] = useMoveNote(note);

  useCheckSharedEntityMoved(note, CUSTOM_ENTITY_TYPES.NOTE, note?.workspaceId);

  const isMobile = useMediaQuery({ query: theme.device.mobile.max });
  const { getWorkspaceInvitesById } = useWorkspaceInvites();

  if (!note?.workspaceId) {
    return null;
  }

  const sharedUsersCount = getWorkspaceInvitesById(note.workspaceId)?.count || 0;
  const isShared = sharedUsersCount > 1;
  const viewerPermission = useMemo(
    () =>
      workspaces.find((workspace) => workspace.id === note?.workspaceId)?.permission ===
        Permission.VIEWER || false,
    [workspaces, note],
  );
  const editorPermission = useMemo(
    () =>
      workspaces.find((workspace) => workspace.id === note?.workspaceId)?.permission ===
        Permission.EDITOR || false,
    [workspaces, note],
  );

  const onArchiveNote = useCallback(
    async (archiveState: boolean) => {
      try {
        await updateNote({
          variables: {
            note: {
              id: note.id,
              isArchived: archiveState,
            },
            workspaceId: note.workspaceId,
          },
        });
        archiveState &&
          sendEvent('note_archive', 'Archive note', {
            'Note ID': note.id,
          });
      } catch (error) {
        console.error(error);
        toast(error as ToastContent);
      }
    },
    [updateNote, note, apolloClient],
  );

  const closeDeleteNoteModal = useCallback(() => {
    setIsDeleteConfirm(false);
  }, []);

  const onDeleteNoteModalSubmit = useCallback(async () => {
    try {
      await deleteNote({
        variables: {
          workspaceId: getOutputWorkspaceId(note.workspaceId, defaultWorkspaceId!),
          note: omit(note, '__typename'),
        },
      });
      sendEvent('note-delete', 'Note delete', {
        'Note ID': note.id,
      });
    } catch (error) {
      console.error(error);
      toast(error as ToastContent);
    } finally {
      closeDeleteNoteModal();
    }
  }, [deleteNote, note]);

  const onNoteWorkspaceChange = useCallback(
    async (option, oldWorkspaceId = -1) => {
      const newWorkspaceId = option.value;
      try {
        await moveNote({
          variables: {
            note: { id: note.id },
            workspaceId: oldWorkspaceId,
            targetWorkspaceId: newWorkspaceId,
          },
        });
      } catch (error) {
        console.error(error);
        toast(error as ToastContent);
      }
    },
    [note],
  );

  const handleContextMenuOptionClick = useCallback(
    async (option: ContextMenuOption) => {
      const label = option.label;

      switch (label) {
        case NoteMenuOptions.EDIT_NOTE.label:
          showEditNoteModal(note);
          break;
        case NoteMenuOptions.VIEW_NOTE.label:
          showEditNoteModal(note);
          break;
        // case NoteMenuOptions.DELETE_NOTE.label:
        //   setIsDeleteConfirm(true);
        //   break;
        case NoteMenuOptions.ARCHIVE_NOTE.label:
          await onArchiveNote(true);
          break;
        case NoteMenuOptions.UNARCHIVE_NOTE.label:
          await onArchiveNote(false);
          break;
        case NoteMenuOptions.CONVERT_NOTE_TO_ACTION.label:
          showConvertNoteToActionModal(note);
          break;
        case NoteMenuOptions.CONVERT_NOTE_TO_OUTCOME.label:
          showConvertNoteToOutcomeModal(note);
          break;
      }
    },
    [onArchiveNote],
  );

  const workspaceName = workspacesResponse?.fetchUserWorkspacesWithInvitedCount.find(
    (workspace) => workspace.id === note.workspaceId,
  )?.name;

  return (
    <>
      <StyledEntityCard
        disabled={loadingMoveNote}
        isShared={isShared}
        draggableId={'' + note.id}
        isViewOnly={viewerPermission}
        title={
          <StyledTitleContainer>
            <StyledNoteName>
              {note.isArchived && <StyledLockIcon />}
              {highlightText ? (
                <Highlighter
                  textToHighlight={capitalize(note.name)}
                  searchWords={[highlightText]}
                  highlightStyle={{
                    padding: 0,
                    backgroundColor: '#aff8db',
                    borderRadius: '2px',
                  }}
                />
              ) : (
                capitalize(note.name)
              )}
            </StyledNoteName>
          </StyledTitleContainer>
        }
        onDoubleClick={() => {
          showEditNoteModal(note);
        }}
        highlightedContextMenuOptions={[
          NoteMenuOptions.EDIT_NOTE,
          NoteMenuOptions.VIEW_NOTE,
          NoteMenuOptions.CONVERT_NOTE_TO_ACTION,
          NoteMenuOptions.CONVERT_NOTE_TO_OUTCOME,
        ]}
        contextMenuOptions={
          viewerPermission
            ? NOTE_MENU_OPTIONS_BY_STATE.FOR_VIEWER
            : note.isArchived
            ? NOTE_MENU_OPTIONS_BY_STATE.ARCHIVED
            : NOTE_MENU_OPTIONS_BY_STATE.NOT_ARCHIVED
        }
        onContextMenuOptionClick={handleContextMenuOptionClick}
        {...restOfProps}
      >
        <>
          <StyledContentContainer>
            {note?.description && (
              <StyledNoteDescription>
                {highlightText ? (
                  <Highlighter
                    textToHighlight={note.description}
                    searchWords={[highlightText]}
                    highlightStyle={{
                      padding: 0,
                      backgroundColor: '#aff8db',
                      borderRadius: '2px',
                    }}
                  />
                ) : (
                  note.description
                )}
              </StyledNoteDescription>
            )}
            <StyledBottomContent>
              {workspaceName !== NOT_ASSIGNED_WORKSPACE_NAME && (
                <>
                  <StyledNoteWorkspaceWrapper>
                    {isNested || viewerPermission ? (
                      <StyledWorkspaceSelectContainer>
                        <StyledNoteWorkspace isShared={isShared}>
                          {capitalize(workspaceName)}
                        </StyledNoteWorkspace>
                      </StyledWorkspaceSelectContainer>
                    ) : (
                      <WorkspaceSelectMenu
                        isMultiSorted
                        disabled={loadingMoveNote}
                        entityName={EntityName.NOTE}
                        currentWorkspaceId={note.workspaceId}
                        onContextMenuOptionClick={(option) => {
                          if (option?.value === note.workspaceId) {
                            return;
                          }
                          onNoteWorkspaceChange(option, note.workspaceId);
                          sendEvent('note-move', 'Update workspace of note');
                        }}
                      >
                        <StyledNoteWorkspace isShared={isShared}>
                          {capitalize(workspaceName)}
                        </StyledNoteWorkspace>
                      </WorkspaceSelectMenu>
                    )}
                  </StyledNoteWorkspaceWrapper>
                  {isShared && <StyledSharedPeopleIcon count={sharedUsersCount} />}
                </>
              )}
              {note?.createDate && !note?.updateDate && (
                <StyledDate>{setDateFormat(note?.createDate)}</StyledDate>
              )}
              {note?.createDate && note?.updateDate && (
                <StyledDate>{`Updated: ${setDateFormat(
                  note?.updateDate,
                )} / Created: ${setDateFormat(note?.createDate)}`}</StyledDate>
              )}
            </StyledBottomContent>
          </StyledContentContainer>
        </>
      </StyledEntityCard>
      {isDeleteConfirm && (
        <StyledConfirmModal
          visible={isDeleteConfirm}
          title="You are going to delete this Incoming note"
          confirmText={isMobile ? 'Delete' : 'Delete this Incoming note'}
          onCancel={closeDeleteNoteModal}
          onOk={onDeleteNoteModalSubmit}
        />
      )}
    </>
  );
});

export { NoteCard };
