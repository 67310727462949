import { Skeleton } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StyledSkeleton = styled(Skeleton.Input)`
  && {
    width: 100% !important;
  }
`;

export const ScrollSeekPlaceholder = ({ height, index }: { height: number; index: number }) => {
  if (height === 1 || height === 0) {
    return null;
  }

  return (
    <div
      style={{
        height,
        boxSizing: 'border-box',
        overflow: 'hidden',
      }}
    >
      <StyledSkeleton
        active={true}
        size={'large'}
        style={{
          height: height - 2,
        }}
      />
    </div>
  );
};
