import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('This field is required')
    .email('This email is invalid'),
  password: Yup.string()
    .required('This field is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=^.*[!@#$%^&*()_~`+\-=\[\]{};':"\\|,.<>\/?])(?=.{9,})/,
      "Minimum of 9 characters long and contain at least one uppercase and one lowercase letter (A, z), one numeric character (0-9), and one special character (such as !, %, @, or #)"
    ),
  firstName:
    Yup.string()
      .required('This field is required'),
  lastName:
    Yup.string()
      .required('This field is required'),
});