import React from 'react';
import { useQuery } from '@apollo/client';
import { AUTH_STATE, AuthValue } from '../../../../apollo/stateFields/auth/authFields';
import styled, { css } from 'styled-components';
import { Image } from 'antd';

const StyledImage = styled(({ size, withDropdown, ...rest }) => <Image {...rest} />)<{
  size?: number;
  withDropdown?: string;
}>`
  color: transparent;
  && {
    width: ${({ size }) => (size ? `${size}px` : '32px')};
    height: ${({ size }) => (size ? `${size}px` : '32px')};
    cursor: ${({ withDropdown }) => (withDropdown === 'true' ? 'pointer' : 'auto')};

    border-radius: 50%;
  }
`;

const StyledDefaultImage = styled.div<{ size?: number; withDropdown?: string }>`
  color: #202635;
  && {
    width: ${({ size }) => (size ? `${size}px` : '32px')};
    height: ${({ size }) => (size ? `${size}px` : '32px')};
    cursor: ${({ withDropdown }) => (withDropdown === 'true' ? 'pointer' : 'auto')};

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(270deg, #eff0e1 4.28%, #e3ebee 100%);
    font-weight: 700;
    font-size: ${({ size }) => (size ? `${(size * 20) / 32}px` : '20px')};
    line-height: 32;
  }
`;

interface UserAvatarProps {
  size?: number;
  withDropdown?: string;
  className?: string;
}

const UserAvatar = ({ className, size, withDropdown, ...props }: UserAvatarProps) => {
  const { data } = useQuery(AUTH_STATE);
  const { authUser }: AuthValue = data?.auth;

  return (
    <div {...props}>
      {authUser?.photoURL ? (
        <StyledImage
          withDropdown={withDropdown}
          preview={false}
          src={authUser?.photoURL || ''}
          alt="avatar"
          size={size}
        />
      ) : (
        <StyledDefaultImage size={size} withDropdown={withDropdown}>
          {authUser?.displayName?.substring(0, 1).toUpperCase()}
        </StyledDefaultImage>
      )}
    </div>
  );
};

export default UserAvatar;
