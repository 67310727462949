import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';

import * as config from './config';
import analytics = firebase.analytics;

function init() {
  if (!firebase.apps.length && typeof window !== 'undefined') {
    firebase.initializeApp(config);
    analytics.isSupported().then((yes) => (yes ? firebase.analytics() : null));
  }
}

init();

export { firebase };
