const API_BASE_URL = (window as any).REACT_APP_API_BASE_URL ?? process.env.REACT_APP_API_BASE_URL;
// const API_GRAPHQL_URL = `https://${API_BASE_URL}graphql`;
const API_GRAPHQL_URL = `${API_BASE_URL}graphql`;
const API_WS_GRAPHQL_URL = `ws${API_BASE_URL.slice(4)}graphql`;

const API_REST_COURSE_URL = `${API_BASE_URL}system/course/set-by-email`;
const API_REST_COURSE_DOMAIN_URL = `${API_BASE_URL}system/course/set-by-domain`;
const API_REST_SUBSCRIPTION_URL = `${API_BASE_URL}system/change-subscription-expiration`;
const API_REST_CORPORATE_ACC_URL = `${API_BASE_URL}system/corporate-account`;
const API_REST_UPLOAD_USERS_URL = `${API_BASE_URL}corporate-account/upload`;
const API_REST_CORPORATE_ACC_SG_URL = `${API_BASE_URL}system/corporate-account/set-self-guided-free`;

export {
  API_BASE_URL,
  API_GRAPHQL_URL,
  API_WS_GRAPHQL_URL,
  API_REST_COURSE_URL,
  API_REST_SUBSCRIPTION_URL,
  API_REST_CORPORATE_ACC_URL,
  API_REST_COURSE_DOMAIN_URL,
  API_REST_CORPORATE_ACC_SG_URL,
  API_REST_UPLOAD_USERS_URL,
};
