import { gql } from '@apollo/client';
import { COURSES_CONST, COURSES_VAR, DND_COLUMN_FIELDS } from './fragments';

export const FETCH_SETTINGS = gql`
  query FetchUserSettings {
    fetchUserSettings {
      boardColumns {
        outcomeColumn {
          ...dndColumnFields
        }
        backlogColumn {
          ...dndColumnFields
        }
        toDoColumn {
          ...dndColumnFields
        }
        doingColumn {
          ...dndColumnFields
        }
        doneColumn {
          ...dndColumnFields
        }
        noteColumn {
          ...dndColumnFields
        }
      }
      filterInput {
        workspaceId
        tagIds
      }
      shouldUpdateStatusToComplete
      shouldUpdateStatusToCurrent
      setStartDateToToday
      setUpdateFilterInput
      shouldOutcomeLinkAction
      shouldActionLinkOutcome
      defaultWorkspaceId
      tutorialPassedStep
      recommendedCompanyRemindDate
      recommendedCompany {
        email
        name
        url
      }
      userPreferences {
        roles
        title
      }
      doneColumnFilter {
        name
        filter
        startDate
        endDate
      }
      toDoColumnFilter {
        name
        filter
        startDate
        endDate
      }
      coursePassedStep
      timezone
      subscriptionReminderTime
      freeTrialReminderTime
    }
  }
  ${DND_COLUMN_FIELDS}
`;

export const FETCH_COURSES_SETTINGS = gql`
  query FetchUserSettings {
    fetchUserSettings {
      coursesConsts {
        ...coursesConstFields
      }
      coursesVars {
        ...coursesVarFields
      }
      skipNotificationTime
    }
  }
  ${COURSES_CONST}
  ${COURSES_VAR}
`;

export const FETCH_FOCUS_TIMER_SETTINGS = gql`
  query FetchUserSettings {
    fetchUserSettings {
      focusTimerFixedPeriod
      focusTimerIsBipSound
      focusTimerType
    }
  }
`;

export const FETCH_STRIPE_PRICES = gql`
  query FetchStripePrices {
    fetchStripePrices {
      amount
      bmSubscriptionPlan
      bmSubscriptionPlanId
      created
      currency
      interval
      updated
      metadata
      bmSubscriptionProductId
    }
  }
`;

export const FETCH_USER_BM_SUBSCRIPTION = gql`
  query FetchUserBmSubscription {
    fetchUserBmSubscription {
      amount
      bmSubscriptionProvider
      bmSubscriptionPlanId
      bmSubscriptionPlan
      error
      expiration
      externalCustomerId
      externalUnfinishedSubscriptionId
      externalSubscriptionId
      id
      interval
      isTrialUsed
    }
  }
`;

export const FETCH_STRIPE_SUBSCRIPTION = gql`
  query FetchStripeSubscription {
    fetchStripeSubscription {
      bmSubscription {
        amount
        bmSubscriptionPlan
        bmSubscriptionPlanId
        bmSubscriptionProvider
        createDate
        createDateFreeSubscription
        error
        expiration
        externalCustomerId
        externalSubscriptionId
        externalUnfinishedSubscriptionId
        id
        interval
        isEligableToFreeSubscription
        isTrialUsed
      }
      subscription {
        amount
        clientSecret
        canceledAt
        currency
        currentPeriodEnd
        currentPeriodStart
        interval
        isCancelAtPeriodEnd
        status
        subscriptionId
      }
      unfinishedSubscription {
        amount
        clientSecret
        currency
        currentPeriodEnd
        currentPeriodStart
        interval
        isCancelAtPeriodEnd
        status
        subscriptionId
      }
    }
  }
`;

export const VALIDATE_STRIPE_PROMO_CODE = gql`
  query ValidateStripePromoCode(
    $promoCodeId: String!
    $bmSubscriptionPlanId: String!
    $bmSubscriptionProductId: String!
  ) {
    validateStripePromoCode(
      promoCodeId: $promoCodeId
      bmSubscriptionPlanId: $bmSubscriptionPlanId
      bmSubscriptionProductId: $bmSubscriptionProductId
    ) {
      active
      code
      coupon {
        amountOff
        currency
        duration
        durationInMonth
        id
        maxRedemptions
        name
        percentOff
        timesRedeemed
        valid
      }
      expiresAt
      id
      maxRedemptions
    }
  }
`;

export const FETCH_SUBSCRIPTION_CHANGE_DISCOUNT = gql`
  query FetchSubscriptionChangeDiscount($newBmSubscriptionPlanId: String!) {
    fetchSubscriptionChangeDiscount(newBmSubscriptionPlanId: $newBmSubscriptionPlanId) {
      discount
      #        numberOfRemainingMonths
      #        numberOfUsedMonth
      #        currentSubscriptionId
      #        currentPlan
      #        newPlan
    }
  }
`;

export const FETCH_STRIPE_CREDIT_CARD = gql`
  query FetchStripeCreditCard {
    fetchStripeCreditCard {
      brand
      country
      exp_month
      exp_year
      last4
    }
  }
`;

export const FETCH_STRIPE_UPCOMING_INVOICE = gql`
  query FetchStripeUpcomingInvoice {
    fetchStripeUpcomingInvoice {
      hostedInvoiceUrl
      id
      periodEnd
      periodStart
      subtotal
      total
      totalDiscountAmounts {
        amount
        discount
      }
    }
  }
`;

export const FETCH_STRIPE_LATEST_INVOICE = gql`
  query FetchStripeLatestInvoice {
    fetchStripeLatestInvoice {
      hostedInvoiceUrl
      id
      periodEnd
      periodStart
      subtotal
      total
      totalDiscountAmounts {
        amount
        discount
      }
    }
  }
`;

export const FETCH_USER = gql`
  query fetchUser {
    fetchUser {
      firstName
      lastName
      email
      name
    }
  }
`;
export const FETCH_USER_BY_EMAIL = gql`
  query FetchUserByEmail($email: String!) {
    fetchUserByEmail(email: $email) {
      bmSubscription {
        expiration
        bmSubscriptionPlan
        bmSubscriptionPlanId
      }
      coursesAvailableList
      email
      name
      id
    }
  }
`;
