import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { ChangeLinkModalProps } from './types';
import { ReactComponent as ArrowIcon } from 'core/svg/arrow-right-2.svg';
import { FILTER_USER_WORKSPACES } from '../../../../workspaces/graphql/queries';
import { EntityType, getCacheEntityById } from '../../../../utils';
import useUpdateAction from '../../../../interactions/action/useUpdateAction';
import { sendEvent } from '../../../../../core/integrations/sentry/events';
import { ConfirmModal } from '../../../../common/components/ConfirmModal';
import { OutcomeCard } from '../../../../outcomes/components/OutcomeCard';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { UpdateUserSettings } from '../../../../../graphql/types';
import { UPDATE_SETTINGS } from '../../../../../graphql/mutations';
import { theme } from '../../../../../core/styles/styled-components';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../../../../common/graphql/queries';
import { Action } from '../../../../../core/types/action';
import useMoveAction from '../../../../interactions/action/useMoveAction';
import { userSettingsMutation } from '../../../../../apollo/stateFields/userSettings';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../../apollo/stateFields/userSettings/userSettingsField';

const StyledModal = styled(ConfirmModal)`
  .ant-modal-content {
    background-color: var(--color-main-grey-2);
    border-radius: 3px;
  }

  .ant-modal-body {
    padding: 41px 16px 31px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
      width: 100%;
    }

    & button:first-child {
      margin-right: 16px;
    }
  }

  .ant-modal-close-icon {
    color: var(--color-dark-blue);
  }
`;

const StyledModalContentContainer = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  @media ${theme.device.mobile.max} {
    margin-bottom: 20px;
  }
`;

const StyledOutcomeCard = styled(OutcomeCard)`
  @media ${theme.device.mobile.min} {
    width: 105px;
  }

  @media ${theme.device.tablet.min} {
    width: 100%;
  }
`;

const StyledModalCheckBoxContainer = styled.div`
  margin-top: 28px;

  @media ${theme.device.mobile.max} {
    margin-top: 40px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  && {
    margin: 0;

    .ant-checkbox + span {
      padding-left: 4px;
    }

    .ant-checkbox-inner {
      background-color: #fff;

      &::after {
        border-color: var(--color-dark-blue);
      }
    }
  }
`;

const StyledArrowIcon = styled(ArrowIcon)`
  width: 16px;
  height: 9.5px;
  margin: 12px 20px 0 20px;

  @media ${theme.device.mobile.max} {
    width: 40px;
    margin: 12px;
  }
`;

const settingNames = {
  [EntityType.ACTION]: 'shouldActionLinkOutcome',
  [EntityType.OUTCOME]: 'shouldOutcomeLinkAction',
};

const ChangeLinkModal = ({ data }: ChangeLinkModalProps) => {
  const { outcomeNew, outcomeOld, variables: values, filterInput, callback, type, actionId } = data;
  const apolloClient = useApolloClient();
  const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
  const { shouldActionLinkOutcome, shouldOutcomeLinkAction }: UserSettingsValue =
    userSettingsData?.userSettingsField;
  const { setShouldActionLinkOutcome, setShouldOutcomeLinkAction } = userSettingsMutation;
  const [updateAction, { loading: updateActionLoading }] = useUpdateAction();
  let variables = values;
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);

  const action = getCacheEntityById(
    apolloClient,
    actionId,
    EntityType.ACTION,
    filterInput,
  ) as Action;
  const [moveAction, { loading: moveActionLoading }] = useMoveAction(action);

  const [updateSettings] = useMutation<UpdateUserSettings>(UPDATE_SETTINGS);
  const [isRememberSetting, setRememberSetting] = useState(false);
  const isHideModal =
    (shouldActionLinkOutcome === false && type === EntityType.ACTION) ||
    (shouldOutcomeLinkAction === false && type === EntityType.OUTCOME);

  const toggleRememberSetting = useCallback(() => {
    setRememberSetting((prev) => !prev);
  }, []);

  useEffect(() => {
    if (isHideModal) {
      handleUpdate();
    }
  }, []);

  const handleClose = () => {
    callback(null);
  };

  const handleUpdate = async () => {
    if (moveActionLoading || updateActionLoading || updateLoading) {
      return;
    }
    setUpdateLoading(true);
    if (
      (!!outcomeOld &&
        !!variables.actionValues.outcomeId &&
        outcomeOld.id !== variables.actionValues.outcomeId) ||
      (outcomeNew?.workspaceId !== action?.workspaceId && !!outcomeOld)
    ) {
      variables =
        !!outcomeOld && outcomeOld.id !== variables.actionValues.outcomeId
          ? {
              ...variables,
              outcomeId: outcomeOld?.id,
              targetOutcomeId: variables.actionValues.outcomeId,
              targetWorkspaceId: variables.targetWorkspaceId || action.workspaceId,
            }
          : variables;
      await moveAction({
        refetchQueries: [{ query: FETCH_USER_WORKSPACES_WITH_INVITED_COUNT }],
        variables,
      });
    } else if (
      !!variables.actionValues.outcomeId &&
      outcomeNew?.workspaceId !== action?.workspaceId
    ) {
      variables = {
        ...variables,
        targetOutcomeId: variables.actionValues.outcomeId,
      };

      await moveAction({
        refetchQueries: [{ query: FETCH_USER_WORKSPACES_WITH_INVITED_COUNT }],
        variables,
      });
    } else {
      await updateAction({
        variables,
        refetchQueries: [
          ...(!!filterInput.length
            ? [{ query: FILTER_USER_WORKSPACES, variables: { filters: filterInput } }]
            : []),
        ],
      });
    }
    sendEvent('dnd_action_outcome_link', 'Action link with Outcome', {
      'Action ID': actionId,
      'Outcome ID': outcomeNew?.id,
    });
    if (isRememberSetting) {
      await updateSettings({
        variables: {
          settings: {
            [settingNames[type]]: false,
          },
        },
      });
      type === EntityType.OUTCOME
        ? setShouldOutcomeLinkAction(false)
        : setShouldActionLinkOutcome(false);
    }
    setUpdateLoading(false);
    handleClose();
  };

  const middleContentEL = (
    <StyledModalContentContainer>
      {outcomeOld && (
        <>
          <StyledOutcomeCard outcome={outcomeOld} isSimple />
          <StyledArrowIcon />
        </>
      )}
      {outcomeOld ? (
        <StyledOutcomeCard outcome={outcomeNew} isSimple />
      ) : (
        <OutcomeCard outcome={outcomeNew} isSimple />
      )}
    </StyledModalContentContainer>
  );

  const endContentEl = (
    <StyledModalCheckBoxContainer>
      <StyledCheckbox checked={isRememberSetting} onChange={toggleRememberSetting}>
        Remember this setting
      </StyledCheckbox>
    </StyledModalCheckBoxContainer>
  );

  if (isHideModal) {
    return <div></div>;
  }

  return (
    <StyledModal
      visible={true}
      closable={true}
      onCancel={handleClose}
      onOk={handleUpdate}
      title="You are about to change the link between an Action and an Outcome"
      cancelText="Cancel"
      confirmText="Change the link"
      middleContent={middleContentEL}
      endContent={endContentEl}
      isConfirmLoading={moveActionLoading || updateActionLoading || updateActionLoading}
    />
  );
};

export default ChangeLinkModal;
