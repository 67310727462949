import React from 'react';
import styled from 'styled-components';
import { Radio, Space, Typography } from 'antd';
import { ONBOARDING_ANSWERS_BY_QUESTION, OnboardingAnswers, OnboardingQuestions } from '../types';
import QuestionnaireButton from '../QuestionnaireButton/QuestionnaireButton';
import { tutorialMutation } from '../../../apollo/stateFields/tutorial';
import { useQuery } from '@apollo/client';
import { TUTORIAL_STATE, TutorialValue } from '../../../apollo/stateFields/tutorial/tutorialField';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled(Typography.Text)`
  display: block;
  color: var(--color-dark-blue);
  margin-bottom: 8px;
  margin-top: 40px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`;

const Questionnaire = () => {
  const { data: tutorialData } = useQuery(TUTORIAL_STATE);
  const { onboardingQuestionnaire }: TutorialValue = tutorialData?.tutorial;
  const { setOnboardingQuestionnaire } = tutorialMutation;
  const question1 = onboardingQuestionnaire.find(
    (question) => question.question === OnboardingQuestions.QUESTION_1,
  )!;
  const question2 = onboardingQuestionnaire.find(
    (question) => question.question === OnboardingQuestions.QUESTION_2,
  )!;
  // const question3 = onboardingQuestionnaire.find(
  //   (question) => question.question === OnboardingQuestions.QUESTION_3,
  // )!;

  return (
    <StyledWrapper>
      <StyledTitle>Where do you do most of your work? </StyledTitle>
      <Radio.Group value={question1.answerIndex} style={{ width: '100%', margin: '16px 0' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          {ONBOARDING_ANSWERS_BY_QUESTION.QUESTION_1.map((answer, idx) => {
            return (
              <QuestionnaireButton
                key={answer + idx}
                index={idx}
                answer={
                  answer === OnboardingAnswers.ANSWER_4 && question1.answerIndex === 3
                    ? question1.answer
                    : answer
                }
                selectedAnswer={question1.answer}
                isChangeable={answer === OnboardingAnswers.ANSWER_4}
                setAnswer={(value?: string) => {
                  setOnboardingQuestionnaire({
                    answer: value ? value : answer,
                    question: OnboardingQuestions.QUESTION_1,
                    answerIndex: idx,
                  });
                }}
              />
            );
          })}
        </Space>
      </Radio.Group>
      <StyledTitle>WHEN do you do most of your work?</StyledTitle>
      <Radio.Group value={question2.answerIndex} style={{ width: '100%', margin: '16px 0' }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          {ONBOARDING_ANSWERS_BY_QUESTION.QUESTION_2.map((answer, idx) => {
            return (
              <QuestionnaireButton
                key={answer + idx}
                index={idx}
                answer={
                  answer === OnboardingAnswers.ANSWER_8 && question2.answerIndex === 3
                    ? question2.answer
                    : answer
                }
                selectedAnswer={question2.answer}
                isChangeable={answer === OnboardingAnswers.ANSWER_8}
                setAnswer={(value?: string) => {
                  setOnboardingQuestionnaire({
                    answer: value ? value : answer,
                    question: OnboardingQuestions.QUESTION_2,
                    answerIndex: idx,
                  });
                }}
              />
            );
          })}
        </Space>
      </Radio.Group>
      {/*<StyledTitle>*/}
      {/*  How open are you to modifying the way you approach work in the context of your broader life?*/}
      {/*</StyledTitle>*/}
      {/*<Radio.Group value={question3.answerIndex} style={{ width: '100%', margin: '16px 0' }}>*/}
      {/*  <Space direction="vertical" style={{ width: '100%' }}>*/}
      {/*    {ONBOARDING_ANSWERS_BY_QUESTION.QUESTION_3.map((answer, idx) => {*/}
      {/*      return (*/}
      {/*        <QuestionnaireButton*/}
      {/*          key={answer + idx}*/}
      {/*          index={idx}*/}
      {/*          answer={answer}*/}
      {/*          selectedAnswer={question3.answer}*/}
      {/*          setAnswer={() => {*/}
      {/*            setOnboardingQuestionnaire({*/}
      {/*              answer,*/}
      {/*              question: OnboardingQuestions.QUESTION_3,*/}
      {/*              answerIndex: idx,*/}
      {/*            });*/}
      {/*          }}*/}
      {/*        />*/}
      {/*      );*/}
      {/*    })}*/}
      {/*  </Space>*/}
      {/*</Radio.Group>*/}
    </StyledWrapper>
  );
};

export default Questionnaire;
