import React from 'react';

export const ActionIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3.5" y="3.50012" width="17" height="19" rx="1.5" stroke="#202635" />
      <rect x="8" y="2.00012" width="8" height="3" fill="#202635" />
      <rect x="10" y="1.00012" width="4" height="2" rx="1" fill="#202635" />
      <rect x="8" y="8.00012" width="8" height="1" fill="#202635" />
      <rect x="8" y="11.0001" width="8" height="1" fill="#202635" />
      <rect x="8" y="14.0001" width="8" height="1" fill="#202635" />
      <rect x="8" y="17.0001" width="4" height="1" fill="#202635" />
    </svg>
  );
};
