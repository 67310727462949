import { Action, ActionStatus } from 'core/types/action';
import { Outcome, OutcomeStatus } from '../../core/types/outcome';
import { getNowDate } from '../../core/utils/date';
import { isBefore, isEqual, parseISO, startOfDay } from 'date-fns';
import { Note } from '../../core/types/note';
import { getActualActionDates } from '../../core/utils/action';

export function sortActionCardsByDateAndName(actionA: Action, actionB: Action) {
  const dateA = actionA.startDate && new Date(actionA.startDate).getTime();
  const dateB = actionB.startDate && new Date(actionB.startDate).getTime();
  const endDateA = actionA.endDate && new Date(actionA.endDate).getTime();
  const endDateB = actionB.endDate && new Date(actionB.endDate).getTime();
  const nameA = actionA?.name?.toLowerCase() || '';
  const nameB = actionB?.name?.toLowerCase() || '';

  if (dateA && dateB && dateA !== dateB) {
    return dateA - dateB;
  }
  if (dateA && !dateB) {
    return -1;
  }
  if (dateB && !dateA) {
    return 1;
  }

  if (!dateB && !dateA) {
    if (endDateA && endDateB && endDateA !== endDateB) {
      return endDateA - endDateB;
    }
    if (endDateA && !endDateB) {
      return -1;
    }
    if (endDateB && !endDateA) {
      return 1;
    }
  }

  if (!dateA && !dateB && !endDateA && !endDateB) {
    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }
  }

  return 0;
}

export const sortEntitiesByDate = (
  a: Action | Note | Outcome,
  b: Action | Note | Outcome,
): number => {
  if (a.updateDate && b.updateDate) {
    if (a.updateDate > b.updateDate) {
      return -1;
    } else if (a.updateDate < b.updateDate) {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  }
  if (!b.updateDate && a.updateDate && a.updateDate > b.createDate) {
    return -1;
  }
  if (!a.updateDate && b.updateDate && b.updateDate > a.createDate) {
    return 1;
  }
  if (a.createDate > b.createDate) {
    return -1;
  } else if (a.createDate < b.createDate) {
    return 1;
  } else {
    return a.name.localeCompare(b.name);
  }
};

export const adjustActualDateAccordingStatus = ({
  status,
  actualStartDate,
  actualEndDate,
  setActualStartDate,
  setActualEndDate,
  prevStatus,
  prevActualStartDate,
}: {
  status: ActionStatus;
  actualStartDate?: string;
  actualEndDate?: string;
  setActualStartDate: (value: string | undefined) => void;
  setActualEndDate: (value: string | undefined) => void;
  prevStatus?: ActionStatus;
  prevActualStartDate?: string;
}) => {
  switch (status) {
    case ActionStatus.BACKLOG:
      setActualStartDate(undefined);
      setActualEndDate(undefined);
      return;
    case ActionStatus.TODO:
      if (prevStatus) {
        const [newActualStartDate] = getActualActionDates(
          prevStatus,
          status,
          true,
          prevActualStartDate,
        );
        setActualStartDate(newActualStartDate);
      } else {
        setActualStartDate(undefined);
      }
      setActualEndDate(undefined);
      return;
    case ActionStatus.DOING:
      if (prevStatus) {
        const [newActualStartDate] = getActualActionDates(
          prevStatus,
          status,
          false,
          prevActualStartDate,
        );
        setActualStartDate(newActualStartDate);
      } else {
        setActualStartDate(getNowDate());
      }
      setActualEndDate(undefined);
      return;
    case ActionStatus.DONE:
      if (
        (actualStartDate &&
          actualEndDate &&
          (isBefore(new Date(actualStartDate), new Date(actualEndDate)) ||
            isEqual(
              startOfDay(new Date(actualStartDate)),
              startOfDay(new Date(actualStartDate)),
            ))) ||
        !actualStartDate ||
        !actualEndDate
      ) {
        if (prevStatus) {
          const [newActualStartDate] = getActualActionDates(
            prevStatus,
            status,
            true,
            prevActualStartDate,
          );
          setActualStartDate(newActualStartDate);
        } else {
          setActualStartDate(getNowDate());
        }
        setActualEndDate(getNowDate());
      }
      return;
    default:
      return;
  }
};

export const adjustDateAccordingStatus = ({
  status,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: {
  status: OutcomeStatus;
  startDate?: string;
  endDate?: string;
  setStartDate: (value: string | undefined) => void;
  setEndDate: (value: string | undefined) => void;
}) => {
  switch (status) {
    case OutcomeStatus.CURRENT:
      setStartDate(getNowDate());
      if (endDate && startDate && !isBefore(new Date(startDate), new Date(endDate))) {
        setEndDate(undefined);
      }
      return;
    case OutcomeStatus.FUTURE:
      setStartDate(undefined);
      setEndDate(undefined);
      return;
    case OutcomeStatus.COMPLETED:
      if (endDate && startDate && isBefore(new Date(endDate), new Date(startDate))) {
        setStartDate(getNowDate());
      } else if (startDate && !endDate && !isBefore(new Date(startDate), new Date(getNowDate()))) {
        setStartDate(getNowDate());
      } else if (!startDate) {
        setStartDate(getNowDate());
      }
      setEndDate(getNowDate());
      return;
    default:
      return;
  }
};

export const adjustStatusAccordingStartDate = ({
  startDate,
  currentStatus,
  setStatus,
}: {
  startDate: string | undefined;
  currentStatus: OutcomeStatus | undefined;
  setStatus: (value: OutcomeStatus) => void;
}) => {
  if (startDate && currentStatus) {
    if (
      currentStatus !== OutcomeStatus.FUTURE &&
      isBefore(new Date(getNowDate()), new Date(startDate))
    ) {
      setStatus(OutcomeStatus.FUTURE);
    } else if (
      currentStatus === OutcomeStatus.FUTURE &&
      isEqual(startOfDay(new Date(startDate)), parseISO(getNowDate()))
    ) {
      setStatus(OutcomeStatus.CURRENT);
    }
  }
};
