import React, { FC } from 'react';
// @ts-ignore
import AsyncCreatableSelect from 'react-select/async-creatable';
// @ts-ignore
import { Option, ReactSelectProps } from 'react-select';
import { useField, FieldProps } from 'formik';
import styled from 'styled-components';
import { debounce } from 'lodash';

import { Messages } from 'core/constants/messages';
import { TagOption } from '../../../../features/common/components/form/AddTags/AddTags';

const StyledAsyncCreatableSelect = styled(AsyncCreatableSelect)`
  & > div {
    max-height: 150px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #a4a6ad;
      border-radius: 10px;
    }
  }
`;

const customStyles = {
  menuList: () => ({
    maxHeight: 80,
    overflowY: 'auto',
  }),
  multiValueLabel: (provided: any, state: any) => ({
    ...provided,
    color: state.selectProps.isDisabled ? 'rgba(0, 0, 0, 0.25)' : 'var(--color-dark-blue)',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: '5px 8px',
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    color: state.selectProps.isDisabled ? 'rgba(0, 0, 0, 0.25)' : 'var(--color-dark-blue)',
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: state.selectProps.isDisabled ? 'rgba(0, 0, 0, 0.25)' : 'var(--color-dark-blue)',
  }),
};

interface PropsType {
  [x: string]: any;
  name: string;
  onChange?: (tags: any[]) => void;
}

const CreatableSelectField: FC<ReactSelectProps & FieldProps> = ({
  name,
  search,
  onChange,
  isDisabled,
  ...props
}: PropsType) => {
  const [field, meta, helpers] = useField({ ...props, name });
  const { setValue } = helpers;

  const formatCreateLabel = (inputValue: string) => {
    return `${inputValue} (New ${props.createLabel})`;
  };

  const loadOptions = (inputValue: string) =>
    new Promise((resolve, reject) => {
      const result = search(inputValue);
      resolve(result);
    });

  const debouncedLoadOptions = debounce((key, resolve, reject) => {
    loadOptions(key).then(resolve).catch(reject);
  }, 300);

  return (
    <StyledAsyncCreatableSelect
      isDisabled={isDisabled}
      isMulti
      styles={customStyles}
      name={field.name}
      instanceId={props.instanceId}
      onChange={(options: TagOption[]) => {
        const newOptions = options?.map((item: TagOption) => {
          item.name = item.label;
          return item;
        });
        setValue(newOptions);
        onChange && onChange(newOptions);
      }}
      loadOptions={debouncedLoadOptions}
      formatCreateLabel={formatCreateLabel}
      search={search}
      placeholder="Search..."
      components={{ DropdownIndicator: null }}
      noOptionsMessage={() => Messages.NO_RESULTS}
      {...props}
    />
  );
};

export { CreatableSelectField };
