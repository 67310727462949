const size = {
  mobile: 320,
  tablet: 768,
  desktop: 1024,
  standardDesktop: 1440,
  largeDesktop: 1920,
  hugeDesktop: 2560,
};

const device = {
  smallMobile: {
    max: `(max-width: ${size.mobile}px)`,
  },
  mobile: {
    min: `(min-width: ${size.mobile}px)`,
    max: `(max-width: ${size.tablet - 1}px)`,
  },
  tablet: {
    min: `(min-width: ${size.tablet}px)`,
    max: `(max-width: ${size.desktop}px)`,
  },
  desktop: {
    min: `(min-width: ${size.desktop + 1}px)`,
    max: `(max-width: ${size.standardDesktop}px)`,
  },
  standardDesktop: {
    min: `(min-width: ${size.standardDesktop + 1}px)`,
    max: `(max-width: ${size.standardDesktop - 1}px)`,
  },
  largeDesktop: {
    min: `(min-width: ${size.largeDesktop}px)`,
    max: `(max-width: ${size.largeDesktop}px)`,
  },
  hugeDesktop: {
    min: `(min-width: ${size.largeDesktop + 1}px)`,
    max: `(max-width: ${size.hugeDesktop}px)`,
  },
};

const theme = {
  size,
  device,
};

export default theme;
