import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {
  CONTACT_US_PATH,
  FAQ_PATH,
  LINKEDIN_REDIRECT_PATH,
  PASSWORD_RECOVERY_PATH,
  SET_EMAIL_PATH,
  SET_NEW_PASSWORD_PATH,
} from 'core/constants/routePaths';
import { ContactUs } from 'pages/ContactUs';
import { FAQ } from 'pages/FAQ';
import UnauthenticatedApp from '../UnauthenticatedApp';
import { AuthenticatedApp } from '../AuthenticatedApp';
import { Loader } from '../common';
import { useAuth } from '../../../context/authContext';
import { useQuery } from '@apollo/client';
import { AUTH_STATE, AuthValue } from '../../../apollo/stateFields/auth/authFields';
import PasswordRecovery from '../../../features/Auth/components/PasswordRecovery/PasswordRecovery';
import EnterNewPassword from '../../../features/Auth/components/PasswordRecovery/EnterNewPassword';
import SetEmail from '../../../features/Auth/components/SetEmail/SetEmail';

const AppController = React.memo(
  ({
    isPassRefreshed,
    setIsPassRefreshed,
  }: {
    isPassRefreshed?: boolean;
    setIsPassRefreshed?: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const { data } = useQuery(AUTH_STATE);
    const { authUser }: AuthValue = data?.auth;
    const { idToken, user } = useAuth();

    if (authUser) {
      Sentry.setUser({
        id: authUser?.uid,
        email: authUser?.email,
        username: authUser?.displayName,
      });
    } else if (idToken) {
      Sentry.setUser({
        id: user?.uid,
        email: user?.email,
        username: user?.displayName,
      });
    }

    //
    // useEffect(() => {
    //   if (!user) {
    //     return;
    //   }
    //
    //   const userTimeTracker = riveted;
    //
    //   userTimeTracker.init({
    //     idleTimeout: 10,
    //     sendOnIdleTimeout: true,
    //     eventHandler: function(data: number) {
    //       Sentry.withScope((scope) => {
    //         Sentry.setTag("activity-duration", `${data}s`);
    //         sendEvent('user-activity', 'User activity', {
    //           'Activity duration': `${data}s`,
    //         });
    //       });
    //       userTimeTracker.reset();
    //     }
    //   });
    //
    //   return () => {
    //     userTimeTracker.reset();
    //   };
    // }, [user]);

    return (
      <>
        <Switch>
          <Route path={CONTACT_US_PATH} component={ContactUs} />
          <Route path={FAQ_PATH} component={FAQ} />
          <Route exact path={LINKEDIN_REDIRECT_PATH}>
            <Loader size="large" centered={true} />
          </Route>
          <Route exact path={PASSWORD_RECOVERY_PATH} component={PasswordRecovery} />
          <Route exact path={SET_EMAIL_PATH} component={SetEmail} />
          <Route exact path={`${SET_NEW_PASSWORD_PATH}/:email`}>
            {isPassRefreshed !== undefined && setIsPassRefreshed !== undefined && (
              <EnterNewPassword
                isPassRefreshed={isPassRefreshed}
                setIsPassRefreshed={setIsPassRefreshed}
              />
            )}
          </Route>
          <Route path="/">{idToken ? <AuthenticatedApp /> : <UnauthenticatedApp />}</Route>
        </Switch>
      </>
    );
  },
);

export default AppController;
