import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { Link, useRouteMatch } from 'react-router-dom';
import { isFunction } from 'lodash-es';

import { ActiveLinkProps } from './types';

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: var(--color-dark-blue);
  font-size: 1rem;
`;

const ActiveLink = ({
  className,
  activeClassName = '',
  children,
  to,
  onClick,
  target,
}: ActiveLinkProps) => {
  const match = useRouteMatch({ path: to });
  const isActive = !!match;
  const linkWrapperClassName = classnames(className, {
    [activeClassName]: activeClassName && isActive,
  });

  return (
    <StyledLink
      className={linkWrapperClassName}
      to={{ pathname: to }}
      onClick={onClick}
      target={target}
    >
      {isFunction(children) ? children(isActive) : children}
    </StyledLink>
  );
};

export { ActiveLink };
