import { recommendInitialValue, recommendVar } from './recommendFields';

const resetRecommend = () => {
  const value = {
    ...recommendInitialValue,
  };
  recommendVar(value);
};

const setShowRecommendPopup = (payload: boolean) => {
  const value = {
    ...recommendVar(),
    showRecommendPopup: payload,
  };
  recommendVar(value);
};

const setShouldRemind = (payload: boolean) => {
  const value = {
    ...recommendVar(),
    shouldRemind: payload,
  };
  recommendVar(value);
};

const setIsAlreadyReminded = (payload: boolean) => {
  const value = {
    ...recommendVar(),
    isAlreadyReminded: payload,
  };
  recommendVar(value);
};

const setRemindDate = (payload: Date | string | undefined | null) => {
  const value = {
    ...recommendVar(),
    remindDate: payload,
  };
  recommendVar(value);
};

const setWithAdditionalBtns = (payload: boolean) => {
  const value = {
    ...recommendVar(),
    withAdditionalBtns: payload,
  };
  recommendVar(value);
};

export {
  setShowRecommendPopup,
  resetRecommend,
  setShouldRemind,
  setIsAlreadyReminded,
  setRemindDate,
  setWithAdditionalBtns,
};
