import React, { useEffect, useState } from 'react';
import { NominateFriendProps } from './types';
import styled, { css } from 'styled-components';
import { ReactComponent as ShareIcon } from './svg/share.svg';
import { ReactComponent as MailIcon } from './svg/mail.svg';
import { ReactComponent as FacebookIcon } from './svg/facebook.svg';
import { ReactComponent as MessengerIcon } from './svg/messenger.svg';
import { ReactComponent as LinkedinIcon } from './svg/linkedin.svg';
import { ReactComponent as TwitterIcon } from './svg/twitter.svg';
import { ReactComponent as WhatsAppIcon } from './svg/whatsApp.svg';
import { ReactComponent as CloseIcon } from 'core/svg/close.svg';
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { theme } from '../../../../styles/styled-components';
import { sendEvent } from '../../../../integrations/sentry/events';
import { FACEBOOK_APP_ID } from '../../../../constants/others';
import { useMediaQuery } from 'react-responsive';

const StyledContainer = styled.div<{ isSimple: boolean; isOpen?: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 24px;
  padding: 2px 4px;
  border-radius: 3px;

  ${({ isSimple, isOpen }) =>
    !isSimple &&
    isOpen &&
    css`
      background-color: #2d3643;
    `}

  ${({ isSimple }) =>
    isSimple &&
    css`
      margin-left: 0;
    `}

  @media ${theme.device.tablet.max} {
    margin-left: 12px;
  }

  @media ${theme.device.mobile.max} {
    margin-left: 12px;
  }
`;

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
  }
`;

const StyledShareList = styled.div<{ visible: boolean }>`
  display: flex;
  align-items: center;
  width: 0;
  overflow: hidden;
  transition: width ease 0.4s;

  ${({ visible }) =>
    visible &&
    css`
      width: 310px;
    `}

  .shared-item {
    height: 32px;
    width: 32px;
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5.33333px;
    background-color: transparent;
    transition: all ease 0.5s;

    svg {
      path {
        fill: #dadada;
      }
    }

    &:hover {
      background: rgba(235, 236, 239, 0.1) !important;

      svg {
        path {
          fill: white;
        }
      }
    }
  }

  .shared-item--mail {
    svg {
      path {
        fill: transparent;
        stroke: #dadada;
      }
    }

    &:hover {
      svg {
        path {
          fill: transparent;
          stroke: white;
        }
      }
    }
  }
`;

const StyledMenuShareList = styled.div`
  display: flex;
  align-items: center;

  .shared-item {
    height: 28px;
    width: 28px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4.66667px;
    background-color: transparent;
    transition: all ease 0.5s;

    &:first-child {
      @media ${theme.device.tablet.max} {
        svg {
          width: 20px !important;
          height: 20px !important;
        }
      }
    }

    @media ${theme.device.tablet.max} {
      height: 32px;
      width: 32px;
      margin-right: 16px;
      border-radius: 5.66667px;

      svg {
        width: 27px !important;
        height: 27px !important;
      }
    }

    svg {
      margin: 0 !important;

      path {
        fill: #202635;
      }
    }

    &:hover {
      background: #ffffff !important;
    }
  }

  .shared-item--mail {
    svg {
      path {
        fill: transparent;
        stroke: #202635;
      }
    }
  }
`;

const StyledMenuContainer = styled.div`
  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #202635;

    @media ${theme.device.tablet.max} {
      font-size: 20px;
      line-height: 16px;
    }
  }
`;

const StyledDivider = styled.div`
  height: 24px;
  width: 1px;
  margin: 0 8px;
  background: #ffffff;
  opacity: 0.1;
`;

const StyledCloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 5.33333px;
  transition: all ease-in-out 0.2s;

  &:hover {
    background: rgba(235, 236, 239, 0.1) !important;
  }
`;

const shareUrl = 'https://app.billionminds.com/sign-up';
const quote =
  'Hi there,' +
  '\n' +
  'I’m using a platform that is helping me build great remote work skills. I can even get certified as a Remote Work Professional! I’d like to invite you to join me on it.' +
  '\n' +
  'It’s called BillionMinds, and you can get a 21 day free trial by clicking here';
const quote2 = `
I’m using a platform that is helping me build great remote work skills. I can even get certified as a Remote Work Professional! You can sign up for a 21 day free trial here
`;

const emailSubject = 'BillionMinds personal effectiveness tool';

const NominateFriend = ({ isSimple }: NominateFriendProps) => {
  const [visible, setVisible] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const isDesktop = useMediaQuery({ query: theme.device.desktop.min });

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsAndroid(userAgent.indexOf('android') !== -1);
  }, []);

  const handleToggleVisible = () => {
    setVisible((prev) => !prev);
  };

  const handleSendSentryEvent = (platform: string) => () => {
    sendEvent(`user-share-${platform}`, '');
  };

  const goToFacebookMessenger = () => {
    window.location.href =
      'fb-messenger://share?link=' +
      encodeURIComponent(shareUrl + '?share=facebook-messenger') +
      '&app_id=' +
      encodeURIComponent(FACEBOOK_APP_ID);
  };

  const getFacebookMessengerButton = () => {
    if (isDesktop) {
      return (
        <FacebookMessengerShareButton
          onClick={handleSendSentryEvent('facebook-messenger')}
          className="shared-item"
          url={shareUrl + '?share=facebook-messenger'}
          appId={FACEBOOK_APP_ID}
        >
          <MessengerIcon />
        </FacebookMessengerShareButton>
      );
    }
    return (
      <div
        className="shared-item"
        onClick={() => {
          goToFacebookMessenger();
          handleSendSentryEvent('facebook-messenger');
        }}
      >
        <MessengerIcon />
      </div>
    );
  };

  const renderShareItems = () => {
    return (
      <>
        {isAndroid ? null : (
          <EmailShareButton
            className="shared-item shared-item--mail"
            url={shareUrl + '?share=email'}
            subject={emailSubject}
            body={quote}
          >
            <MailIcon onClick={handleSendSentryEvent('email')} />
          </EmailShareButton>
        )}
        <FacebookShareButton
          onClick={handleSendSentryEvent('facebook')}
          className="shared-item"
          url={shareUrl + '?share=facebook'}
          quote={quote2}
        >
          <FacebookIcon />
        </FacebookShareButton>
        {getFacebookMessengerButton()}
        <LinkedinShareButton
          onClick={handleSendSentryEvent('linkedin')}
          className="shared-item"
          url={shareUrl + '?share=linkedin'}
          title={quote2}
        >
          <LinkedinIcon />
        </LinkedinShareButton>
        <TwitterShareButton
          onClick={handleSendSentryEvent('twitter')}
          className="shared-item"
          url={shareUrl + '?share=twitter'}
          title={quote2}
        >
          <TwitterIcon />
        </TwitterShareButton>
        <WhatsappShareButton
          onClick={handleSendSentryEvent('whatsapp')}
          className="shared-item"
          url={shareUrl + '?share=whatsapp'}
          title={quote}
        >
          <WhatsAppIcon />
        </WhatsappShareButton>
        {!isSimple && (
          <>
            <StyledDivider />
            <StyledCloseButton onClick={() => setVisible(false)}>
              <CloseIcon />
            </StyledCloseButton>
          </>
        )}
      </>
    );
  };

  const renderHeaderContent = () => {
    return <StyledShareList visible={visible}>{renderShareItems()}</StyledShareList>;
  };

  const renderHeaderMenuContent = () => {
    return (
      <StyledMenuContainer>
        <span>Invite a friend</span>
        <StyledMenuShareList>{renderShareItems()}</StyledMenuShareList>
      </StyledMenuContainer>
    );
  };

  const buttonEl = (
    <StyledButton onClick={handleToggleVisible}>
      <ShareIcon />
      {visible && <span>Invite a friend</span>}
    </StyledButton>
  );

  const getContent = () => {
    if (isSimple) {
      return renderHeaderMenuContent();
    }

    return (
      <>
        {buttonEl}
        {renderHeaderContent()}
      </>
    );
  };

  return (
    <StyledContainer isOpen={visible} isSimple={!!isSimple}>
      {getContent()}
    </StyledContainer>
  );
};

export default NominateFriend;
