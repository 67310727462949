import { gql } from '@apollo/client';
import { WORKSPACE_FIELDS } from '../features/workspaces/graphql/fragments';
import { ACTION_FIELDS } from '../features/actions/graphql/fragments';
import { OUTCOME_FIELDS } from '../features/outcomes/graphql/fragments';
import { NOTE_FIELDS } from '../features/actions/graphql/fragments';

export const SUBSCRIBE_WORKSPACE = gql`
  subscription SubscribeToChanges($triggerId: Int!) {
    subscribeToChanges(triggerId: $triggerId) {
      ... on Workspace {
        ...workspaceFields
      }
    }
  }
  ${WORKSPACE_FIELDS}
`;

export const SUBSCRIBE_ACTION = gql`
  subscription SubscribeToChanges($triggerId: Int!) {
    subscribeToChanges(triggerId: $triggerId) {
      ... on Action {
        ...actionFields
      }
    ... on ActionHistoricOutcome {
      action {
        ...actionFields
      }
      historicOutcome {
         ...outcomeFields
      }
    }      
    }
  }
  ${ACTION_FIELDS}
  ${OUTCOME_FIELDS}
`;

export const SUBSCRIBE_OUTCOME = gql`
  subscription SubscribeToChanges($triggerId: Int!) {
    subscribeToChanges(triggerId: $triggerId) {
      ... on Outcome {
        ...outcomeFields
      }
    }
  }
  ${OUTCOME_FIELDS}
`;

export const SUBSCRIBE_NOTE = gql`
    subscription SubscribeToChanges($triggerId: Int!) {
        subscribeToChanges(triggerId: $triggerId) {
            ... on Note {
                ...noteFields
            }
        }
    }
    ${NOTE_FIELDS}
`;
