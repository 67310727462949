import { searchBarValueInitialValue, searchBarVar, SearchQueryVariables } from './searchBarFileds';

const setSearchVariables = (payload: SearchQueryVariables | undefined) => {
  const value = {
    ...searchBarVar(),
    queryVariables: payload,
  };
  searchBarVar(value);
};

const toggleSearchBar = (isOpened: boolean) => {
  const value = {
    ...searchBarVar(),
    isOpened,
  };
  searchBarVar(value);
};

const resetSearchBar = () => {
  const value = {
    ...searchBarValueInitialValue,
  };
  searchBarVar(value);
};

export { setSearchVariables, toggleSearchBar, resetSearchBar };
