import React from 'react';
import { Select } from 'formik-antd';
import styled from 'styled-components';

const SelectField = styled(({ $error, ...rest }) => <Select {...rest} />)<{ $error?: boolean }>`
  border-color: ${(props) => (props.$error ? 'var(--color-red)' : 'transparent')};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
`;

export { SelectField };
