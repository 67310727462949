import { compareAsc, format, formatISO, isBefore, isValid, parseISO, startOfDay } from 'date-fns';
// TODO: replace moment with date-fns
import moment, { Moment } from 'moment';
import { EntityDate } from '../types/entity';

export const formatDate = (dateStr: string): string => {
  const dateObj = parseISO(dateStr);
  return isValid(dateObj) ? format(dateObj, 'MMM dd') : '';
};

export const getNowDate = () => formatISO(startOfDay(new Date()));

export const disabledStartDate = (current: Moment, endDate: string) => {
  return current && endDate ? current.startOf('day').isAfter(moment(endDate).endOf('day')) : false;
};

export const disabledEndDate = (current: Moment, startDate: string) => {
  return current && startDate
    ? current.startOf('day').isBefore(moment(startDate).startOf('day'))
    : false;
};

export const isDateBefore = (startDate: EntityDate, endDate: EntityDate) => {
  return startDate && endDate && isBefore(parseISO(startDate), parseISO(endDate));
};

export const isDateBeforeOrEqual = (startDate: EntityDate, endDate: EntityDate) => {
  if (!startDate || !endDate) {
    return false;
  }

  return (
    compareAsc(startOfDay(parseISO(startDate)), startOfDay(parseISO(endDate))) === 0 ||
    isBefore(parseISO(startDate), parseISO(endDate))
  );
};
