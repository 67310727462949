export const BUY_MASTERING_COURSE_LINK =
  'https://effectiveness.me/get-started/ols/products/theelements';
export const FOUNDATION_COURSE_TESTING_LINK = 'https://s3h38vezssn.typeform.com/to/l3MYqwpx';
export const MASTERING_COURSE_TESTING_LINK = 'https://s3h38vezssn.typeform.com/to/WUvmc2E1';
export const FUNDAMENTALS_COURSE_TESTING_LINK = 'https://s3h38vezssn.typeform.com/to/WfSsm9eK';
export const REMOTE_COURSE_TESTING_LINK = 'https://s3h38vezssn.typeform.com/to/xSI3zr12';
export const FUNDAMENTALS_MANAGER_COURSE_TESTING_LINK =
  'https://s3h38vezssn.typeform.com/to/OO0GCTgD';
export const REMOTE_MANAGER_COURSE_TESTING_LINK = 'https://s3h38vezssn.typeform.com/to/c1PIk38u';

export const PREMIUM_USERS_EMAILS = [
  'avolosevich@solvd.com',
  'g.scheenstra@star-associates.com',
  'billionminds@mailto.plus',
  'julie@ixler8.com',
];
