import { FetchUserWorkspaceActions, UpdateAction } from '../../actions/types';
import { FETCH_ACTIONS } from '../../actions/graphql/queries';
import {
  checkIsShownEntity,
  Filter,
  OperationType,
  updatedCacheData,
  updateWorkspaceTagsData,
} from '../../utils';
import { mutateAction } from '../../../context/userWorkspaceContext';
import { ApolloCache, ApolloClient } from '@apollo/client';
import { Action } from '../../../core/types/action';
import { checkActionForComplete, checkOutcomeForComplete } from '../utils';
import { completeMutation } from '../../../apollo/stateFields/complete';
import { filterMutation } from '../../../apollo/stateFields/filterInput';

export const getUpdateFuncForUpdateAction = (
  apolloClient: ApolloClient<any>,
  actionToMove: Action,
  filterInput: Filter[],
) => {
  const { setOutcomeToComplete, setOutcomeToIncomplete } = completeMutation;
  const { setUpdateFilterInputId } = filterMutation;

  return (cache: ApolloCache<UpdateAction>, { data }: any) => {
    if (actionToMove === null) {
      return;
    }

    const { fetchUserWorkspaceActions: oldWorkspaceActions = [] } =
      cache.readQuery<FetchUserWorkspaceActions>({
        query: FETCH_ACTIONS,
        variables: {
          workspaceId: actionToMove.workspaceId,
        },
      }) || {};

    const { fetchUserWorkspaceActions: newWorkspaceActions = [] } =
      cache.readQuery<FetchUserWorkspaceActions>({
        query: FETCH_ACTIONS,
        variables: {
          workspaceId: data?.moveUserWorkspaceAction.action.workspaceId,
        },
      }) || {};

    cache.writeQuery<FetchUserWorkspaceActions>({
      query: FETCH_ACTIONS,
      variables: {
        workspaceId: actionToMove.workspaceId,
      },
      data: {
        fetchUserWorkspaceActions: updatedCacheData(
          oldWorkspaceActions,
          data?.moveUserWorkspaceAction.action,
          OperationType.DELETE,
        ),
      },
    });

    cache.writeQuery<FetchUserWorkspaceActions>({
      query: FETCH_ACTIONS,
      variables: {
        workspaceId: data?.moveUserWorkspaceAction.action.workspaceId,
      },
      data: {
        fetchUserWorkspaceActions: updatedCacheData(
          newWorkspaceActions,
          data?.moveUserWorkspaceAction.action,
          OperationType.CREATE,
        ),
      },
    });

    if (data?.moveUserWorkspaceAction.action) {
      mutateAction(
        apolloClient,
        data.moveUserWorkspaceAction.action,
        'update',
        filterInput,
        actionToMove,
      );
      updateWorkspaceTagsData(apolloClient, data?.moveUserWorkspaceAction.action);

      if (!checkIsShownEntity(data.moveUserWorkspaceAction.action, filterInput)) {
        setUpdateFilterInputId({
          type: 'Action',
          workspaceId: data.moveUserWorkspaceAction.action.workspaceId,
          id: data.moveUserWorkspaceAction.action.id,
        });
      }
      if (data?.moveUserWorkspaceAction?.action) {
        checkActionForComplete(
          setOutcomeToComplete,
          setOutcomeToIncomplete,
          data.moveUserWorkspaceAction.action,
        );
      }
      if (
        data?.moveUserWorkspaceAction?.historicOutcome &&
        data?.moveUserWorkspaceAction?.historicOutcome?.isAllActionsDone !== null
      ) {
        checkOutcomeForComplete(
          setOutcomeToComplete,
          setOutcomeToIncomplete,
          data.moveUserWorkspaceAction.historicOutcome,
        );
      }
    }
  };
};
