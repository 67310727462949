import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { IconButtonProps } from './types';

const StyledContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  // We only allow mouse events on the container, not on the SVG itself or its path.
  & svg {
    pointer-events: none;
  }
`;

const IconButton = forwardRef<HTMLDivElement, IconButtonProps>((
  {
    className,
    icon: Icon,
    onClick,
  },
  ref,
) => (
  <StyledContainer ref={ref} className={className} onClick={onClick}>
    <Icon />
  </StyledContainer>
));

export { IconButton };
