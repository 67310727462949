import { gql, makeVar } from '@apollo/client';
import { OutcomeStatus } from '../../../core/types/outcome';

export enum ColumnType {
  Outcome = 'Outcome',
  Backlog = 'Backlog',
  Todo = 'Todo',
  Doing = 'Doing',
  Done = 'Done',
  Note = 'Note',
}

export interface Column {
  id: number;
  title: ColumnType;
  entityIds: number[];
}

export interface BoardColumns {
  outcomeColumn: Column;
  backlogColumn: Column;
  toDoColumn: Column;
  doingColumn: Column;
  doneColumn: Column;
  noteColumn: Column;
}

export interface DndValue {
  boardColumns: BoardColumns;
  currentOutcomeColumnStatus: OutcomeStatus;
  isUpdated: boolean;
  changingSpaceEntityId: null | number;
}

export const dndInitialValue: DndValue = {
  boardColumns: {
    outcomeColumn: { id: 0, title: ColumnType.Outcome, entityIds: [] },
    backlogColumn: { id: 1, title: ColumnType.Backlog, entityIds: [] },
    toDoColumn: { id: 2, title: ColumnType.Todo, entityIds: [] },
    doingColumn: { id: 3, title: ColumnType.Doing, entityIds: [] },
    doneColumn: { id: 4, title: ColumnType.Done, entityIds: [] },
    noteColumn: { id: 5, title: ColumnType.Note, entityIds: [] },
  },
  currentOutcomeColumnStatus: OutcomeStatus.CURRENT,
  isUpdated: false,
  changingSpaceEntityId: null,
};

export const dndVar = makeVar<DndValue>(dndInitialValue);
export const DND_STATE = gql`
  query Dnd {
    dnd @client
  }
`;
