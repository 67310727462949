import { NewTagInput, Tag } from './tag';
import { Date } from 'core/types/date';
import { Action } from './action';
import { TagOption } from '../../features/common/components/form/AddTags/AddTags';

export enum OutcomeStatus {
  CURRENT = 'CURRENT',
  FUTURE = 'FUTURE',
  COMPLETED = 'COMPLETED',
}

export interface Outcome {
  __typename?: 'Outcome';
  id: number;
  name: string;
  workspaceId: number;
  assigneeId?: number;
  isArchived?: boolean;
  status?: OutcomeStatus;
  description?: string;
  startDate?: Date;
  endDate?: Date;
  updateDate?: Date;
  createDate: string;
  completeDate?: Date;
  actions?: Action[];
  tags?: Tag[];
  isAllActionsDone?: boolean | null;
}

export interface NewOutcomeInput {
  name: string;
  workspaceId?: number;
  assigneeId?: number;
  createDate?: string;
  updateDate?: string;
  status?: OutcomeStatus;
  isArchived?: boolean;
  description?: string;
  startDate?: string;
  endDate?: string;
  completeDate?: string;
  actionIds?: number[];
  newTags?: NewTagInput[];
  tagIds?: number[];
}

export interface OutcomeInput {
  id?: number;
  name?: string;
  workspaceId?: number;
  assigneeId?: number;
  createDate?: string;
  updateDate?: string;
  status?: OutcomeStatus;
  isArchived?: boolean;
  description?: string;
  startDate?: string;
  endDate?: string;
  completeDate?: string;
  actionIds?: number[];
  inputTags?: TagOption[];
  tagIds?: number[];
}
