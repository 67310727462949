import { useCallback, useState } from 'react';

export interface TextInputField {
  initialValue: string;
  checkFn?: (value: string) => boolean;
  errorMsg?: string;
}

const useTextInputField = ({ initialValue, checkFn, errorMsg }: TextInputField) => {
  const [state, setState] = useState<string>(initialValue);
  const [error, setError] = useState<string>('');

  const handleBlur = useCallback(() => {
    if (checkFn && !checkFn(state)) {
      errorMsg ? setError(errorMsg) : setError('Validation error');
    }
  }, [state, errorMsg]);

  const handleChange = useCallback(
    (e: any) => {
      if (error) {
        setError('');
      }
      setState(e?.target?.value);
    },
    [error],
  );

  return { state, setState, error, setError, handleBlur, handleChange };
};

export default useTextInputField;
