import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  ACTION_PATH,
  ADMIN_PANEL_PATH,
  ONBOARDING_PATH,
  USER_PLANS_PATH,
  USER_PROFILE_PATH,
} from 'core/constants/routePaths';
import { UserWorkspaceProvider } from 'context/userWorkspaceContext';
import { UserSettingsManager } from 'core/components/UserSettingsManager/UserSettingsManager';
import { ActionBoard } from 'features/actions';
import { Loader } from '../common';
import { UserDndManager } from '../UserDndManager/UserDndManager';
import Onboarding from '../../../pages/Onboarding/Onboarding';
import { useMutation, useQuery } from '@apollo/client';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../apollo/stateFields/userSettings/userSettingsField';
import { Header } from '../layout';
import { DndContextProvider } from '../../../context/dndContext/dndContext';
import UserProfilePage from '../../../pages/UserProfile/UserProfilePage';
import AvailablePlansPage from '../../../pages/AvailablePlansPage/AvailablePlansPage';
import { useCheckExpiredSubscription } from '../../../hooks/useCheckExpiredSubscription';
import { INIT_STRIPE_CUSTOMER } from '../../../graphql/mutations';
import { InitStripeCustomer } from '../../../graphql/types';
import AdminPanel from '../../../pages/AdminPanel/AdminPanel';
import UnauthenticatedApp from '../UnauthenticatedApp';
import { AUTH_STATE, AuthValue } from '../../../apollo/stateFields/auth/authFields';

const DependedComponent = React.memo(() => {
  const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
  const { defaultWorkspaceId }: UserSettingsValue = userSettingsData?.userSettingsField;

  if (defaultWorkspaceId === -1) {
    return <Loader size="large" centered={true} />;
  }

  return (
    <>
      <Header title="My Life" />
      <ActionBoard />
    </>
  );
});

const AuthenticatedApp = () => {
  useCheckExpiredSubscription();
  const [initStripeCustomer] = useMutation<InitStripeCustomer>(INIT_STRIPE_CUSTOMER);
  const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
  const { isSettingsLoaded }: UserSettingsValue = userSettingsData?.userSettingsField;
  const { data } = useQuery(AUTH_STATE);
  const { isAdmin }: AuthValue = data?.auth;

  useEffect(() => {
    initStripeCustomer();
  }, []);

  return (
    <DndContextProvider>
      <UserWorkspaceProvider>
        {isSettingsLoaded && <UserDndManager />}

        <>
          <Route exact path={ONBOARDING_PATH}>
            <Onboarding />
          </Route>
          <Route exact path={USER_PROFILE_PATH}>
            <UserProfilePage />
          </Route>
          <Route exact path={USER_PLANS_PATH}>
            <AvailablePlansPage />
          </Route>
          <Route exact path={ADMIN_PANEL_PATH}>
            {isAdmin ? <AdminPanel /> : <Redirect to={ACTION_PATH} />}
          </Route>
          <Route exact path={ACTION_PATH}>
            <>
              <UserSettingsManager />
              <DependedComponent />
            </>
          </Route>
        </>
      </UserWorkspaceProvider>
    </DndContextProvider>
  );
};

export { AuthenticatedApp };
