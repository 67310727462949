import { Button, DatePicker, Modal, Radio, Space } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import { ReactComponent as BurgerIcon } from '../../../../../core/svg/burger.svg';
import { theme } from '../../../../../core/styles/styled-components';

const { RangePicker } = DatePicker;

export const StyledBurgerIcon = styled(({ isAnytime, ...rest }) => <BurgerIcon {...rest} />)<{
  isAnytime?: boolean;
}>`
  color: ${({ isAnytime }) => (isAnytime ? `var(--color-dark-blue)` : `var(--color-white)`)};
`;

export const StyledAddActionButton = styled(Button)<{ isAnytime?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  padding-left: 0;
  box-shadow: none;
  font-weight: 400;
  font-size: 9px;
  //margin-left: 10px;
  margin-right: 5px;
  border: 1px solid rgba(32, 38, 53, 0.2);

  background-color: ${({ isAnytime }) => (isAnytime ? `transparent` : `var(--color-dark-blue)`)};

  span {
    font-size: 9px;
    font-weight: 500;
    color: ${({ isAnytime }) => (isAnytime ? `var(--color-dark-blue)` : `var(--color-white)`)};
  }

  &:hover,
  &:focus {
    background-color: ${({ isAnytime }) => (isAnytime ? `transparent` : `var(--color-dark-blue)`)};
    border: 1px solid rgba(32, 38, 53, 0.4);
  }

  & > *:first-child {
    margin-right: 5px;
  }
`;

export const StyledModal = styled(Modal)`
  margin: 0;
  padding: 0;

  && {
    @media ${theme.device.mobile.max} {
      height: var(--app-height);
      width: 100% !important;
      left: 0 !important;
      top: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      max-width: 100%;
    }
  }

  .ant-modal-content {
    background-color: var(--color-main-grey-2);
    border-radius: 3px;
    height: auto;
    width: 300px;

    @media ${theme.device.mobile.max} {
      height: 100%;
      width: 100%;
      border-radius: 0;
    }
  }

  .ant-modal-body {
    position: relative;
    height: 100%;
    padding: 16px;

    @media ${theme.device.mobile.max} {
      padding: 16px;
    }
  }
`;

export const StyledSpace = styled(Space)`
  width: 100%;
  .ant-space-item {
    width: 100%;
  }
`;

export const StyledRadio = styled(Radio)`
  && {
    width: 100%;
  }
  margin-bottom: 4px;

  span.ant-radio + * {
    width: 100%;
  }
`;

export const StyledRadioHeader = styled.div`
  margin: 0 0 15px;
  font-weight: 700;
`;

export const StyledRadioContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  color: var(--color-dark-blue);
  font-size: 14px;
  font-weight: 400;

  span {
    font-weight: 500;
    font-size: 9px;
    color: var(--color-dark-blue);
    opacity: 0.5;
    align-self: center;
  }
`;

export const StyledBackLink = styled.div`
  display: flex;
  align-items: center;

  font-size: 12px;
  font-weight: 400;
  color: rgba(32, 38, 53, 0.7);
  line-height: 18px;
  cursor: pointer;
  margin-bottom: 12px;

  span {
    margin-left: 4px;
  }
`;

export const StyledCustomHeader = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: var(--color-dark-blue);
  line-height: 16px;
  margin-bottom: 20px;
`;

export const StyledRangePicker = styled(RangePicker)`
  && {
    width: 100%;

    .ant-picker-clear {
      display: none;
    }

    .ant-picker-range-arrow {
      display: none;
    }

    .ant-picker-range-arrow::after {
      display: none;
    }
  }
`;

export const StyledCustomDate = styled.span`
  color: rgba(32, 38, 53, 0.7);
`;

export const GlobalStyle = createGlobalStyle`
  .ant-picker-panel-container .ant-picker-panels {
    display: flex;
    flex-direction: column;
  }
  
  .ant-picker-range-arrow::after {
    display: none;
  }
`;
