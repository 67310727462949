import React, { FC } from 'react'
import styled from 'styled-components'
import { Button, Space, Typography } from 'antd'

import theme from 'core/styles/styled-components/theme';
import { ReactComponent as IconGooglePlus } from '../../svg/google-plus.svg'
import { ReactComponent as IconLinkedIn } from '../../svg/linkedin.svg'
import { ReactComponent as IconFacebook } from '../../svg/facebook.svg'

const Title = styled(Typography.Title)`
  &.ant-typography {
    color: var(--color-dark-blue);
    font-size: 20px;
    font-weight: 700;

    @media ${theme.device.tablet.min} {
      font-size: 24px;
    }
  }
`

const StyledButton = styled(Button)`
  border-radius: 4px;
  color: var(--color-white);
  height: 40px;
  line-height: 1;
  position: relative;
  width: 100%;

  svg {
    left: 15px;
    position: absolute;
  }
`

const ButtonGooglePlus = styled(StyledButton)`
  border-color: var(--color-grey);
  color: var(--color-dark-blue);
`

const ButtonLinkedin = styled(StyledButton)`
  background-color: var(--color-linkedin);
`

const ButtonFacebook = styled(StyledButton)`
  background-color: var(--color-facebook);
`

const Social: FC = () => {
  return (
    <>
      <Title>Sign In</Title>
      <Space style={{ width: '100%' }} size="middle" direction="vertical">
        <ButtonGooglePlus icon={<IconGooglePlus />}>
          Sign in with <b> Google</b>
        </ButtonGooglePlus>
        <ButtonLinkedin icon={<IconLinkedIn />}>
          Sign in with <b> LinkedIn</b>
        </ButtonLinkedin>
        <ButtonFacebook icon={<IconFacebook />}>
          Sign in with <b> Facebook</b>
        </ButtonFacebook>
      </Space>
    </>
  )
}

export default Social
