import { ContactChannel } from './types';
import { ReactComponent as FacebookIcon } from './svg/facebook.svg';
import { ReactComponent as LinkedInIcon } from './svg/linkedin.svg';
import { ReactComponent as TwitterIcon } from './svg/twitter.svg';

const contactChannels: ContactChannel[] = [
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/risingbillion/',
    icon: FacebookIcon,
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/risingbillion',
    icon: TwitterIcon,
  },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/company/billionminds/',
    icon: LinkedInIcon,
  },
];

export default contactChannels;
