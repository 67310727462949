import { Outcome } from 'core/types/outcome';

export type OutcomeCardColumnProps = {
  onCardDoubleClick: (outcome: Outcome) => void;
};

export const ContextMenuOptions = {
  EDIT_OUTCOME: { label: 'Edit Outcome' },
  VIEW_OUTCOME: { label: 'View Outcome' },
  DELETE: { label: 'Delete' },
  COMPLETE_OUTCOME: { label: 'Complete Outcome' },
  MAKE_CURRENT: { label: 'Make Current' },
  MAKE_FUTURE: { label: 'Make Future' },
  ARCHIVE: { label: 'Archive' },
  UNARCHIVE: { label: 'Unarchive' },
};
