import React from 'react';
import { Header } from '../../core/components/layout';
import { Tabs } from 'antd';
import { ACTION_PATH } from '../../core/constants/routePaths';
import IndividualUsers from './Users/IndividualUsers';
import CorporateUsers from './Users/CorporateUsers';
import Curriculum from './Curriculum/Curriculum';
import {
  PageWrapper,
  StyledContainer,
  StyledLink,
  StyledTabsContainer,
  StyledTitle,
  StyledTitleWrapper,
} from './StyledComponents';

const AdminPanel = () => {
  return (
    <StyledContainer>
      <Header isThin={true} />
      <PageWrapper>
        <StyledTitleWrapper>
          <StyledTitle>Admin panel </StyledTitle>
          <StyledLink to={ACTION_PATH}>Back to board</StyledLink>
        </StyledTitleWrapper>
        <StyledTabsContainer>
          <Tabs tabPosition={'left'}>
            <Tabs.TabPane tab="Individual Users" key="Individual">
              <IndividualUsers />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Corporate Users" key="Corporate">
              <CorporateUsers />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Curriculum" key="Curriculum">
              <Curriculum />
            </Tabs.TabPane>
          </Tabs>
        </StyledTabsContainer>
      </PageWrapper>
    </StyledContainer>
  );
};

export default AdminPanel;
