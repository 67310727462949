import { gql, makeVar } from '@apollo/client';
import { Entity } from '../../../core/types/entity';
import { DataChangeLinkModal } from '../../../features/actions/components/ActionBoard/ChangeLinkModal/types';
import { Action } from '../../../core/types/action';
import { Outcome } from '../../../core/types/outcome';

export interface BoardEditedEntityValue {
  actionEditedEntity: Action | null;
  noteEditedEntity: Entity | null;
  convertNoteToActionEntity: Entity | null;
  convertNoteToOutcomeEntity: Entity | null;
  outcomeEditedEntity: Outcome | null;
  updateOutcomeWithActionEntity: Action | null;
  outcomeArchiveEditedEntity: Entity | null;
  isNewInformationDrawerOpen: boolean;
  dataChangeLinkModal: DataChangeLinkModal | null;
  showFocusSettingsForActionId: null | number;
  focusTimerCounter: number;
}

export const boardEditedEntityInitialValue: BoardEditedEntityValue = {
  actionEditedEntity: null,
  noteEditedEntity: null,
  convertNoteToActionEntity: null,
  convertNoteToOutcomeEntity: null,
  outcomeEditedEntity: null,
  updateOutcomeWithActionEntity: null,
  outcomeArchiveEditedEntity: null,
  isNewInformationDrawerOpen: false,
  dataChangeLinkModal: null,
  showFocusSettingsForActionId: null,
  focusTimerCounter: 0,
};

export const boardEditedEntityVar = makeVar<BoardEditedEntityValue>(boardEditedEntityInitialValue);
export const BOARD_EDITED_ENTITY_STATE = gql`
  query BoardEditedEntity {
    boardEditedEntity @client
  }
`;
