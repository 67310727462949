import React from 'react';
import {
  StyledCursiveTipsText,
  StyledOutLink,
  StyledTipsHeader,
  StyledTipsText,
  StyledTipsTitle,
} from '../StyledComponents';

const TakeControlTips = () => {
  return (
    <div>
      <StyledTipsHeader>Hacks, Tips & Tricks - Take Control Of Your Day</StyledTipsHeader>
      <StyledTipsText>
        Personal effectiveness is <StyledCursiveTipsText>personal</StyledCursiveTipsText>, so
        BillionMinds tends to steer clear of recommending specific life hacks that may not work for
        the individual. However, we do provide a small number, which have been shown to be effective
        across a large proportion of our users. Try these, and let us know what works and what
        doesn’t!
      </StyledTipsText>
      <StyledTipsTitle>Block off a period in your calendar for decision making</StyledTipsTitle>
      <StyledTipsText>
        Once you know your optimal time for decision making, block off a decision making time in
        that period in your daily calendar.
      </StyledTipsText>
      <StyledTipsTitle>Know when to “Eat That Frog”</StyledTipsTitle>
      <StyledTipsText>
        “The Frog” is the action you dislike the most. It’s got to be done, and in general it’s
        better to do it earlier in the day. But find a great time to do it, and it might be after
        you do something you love - for more details see the video{' '}
        <StyledOutLink target={'_blank'} href={'https://www.youtube.com/watch?v=A2Wg1YFSmKE'}>
          OK, I’ll Eat That Frog, but I’ll Tell You WHEN
        </StyledOutLink>
        .
      </StyledTipsText>
      <StyledTipsTitle>Only take on Actions if you can do them</StyledTipsTitle>
      <StyledTipsText>
        Saying no can be tough, but reframing saying yes as a promise can help - for more details
        see the video{' '}
        <StyledOutLink target={'_blank'} href={'https://www.youtube.com/watch?v=FHz74F4BXyQ&t'}>
          Saying No
        </StyledOutLink>
        .
      </StyledTipsText>
      <StyledTipsTitle>Add a buffer for the unexpected</StyledTipsTitle>
      <StyledTipsText>
        The majority of us overestimate the time we will have to do things in the future, and the
        further out it is, the more it feels like we have nothing but time. BillionMinds will help
        you build the skills you need to understand your future capacity better, but in the meantime
        try blocking off parts of your calendar as a buffer for the unknown. Even if you mark these
        blocks as free, it can help remind you that you WILL be busy once those weeks roll around.
      </StyledTipsText>
      <StyledTipsTitle>Control your WIP</StyledTipsTitle>
      <StyledTipsText>
        WIP is Work in Progress, and it’s what you are DOING at any one time (corresponding to the
        Doing column in the BillionMinds tool). Start using a Kanban for your activities such as the
        BillionMinds tool, and make sure that you never have more than 3 things in WIP at any one
        time. This will help you stay focused, and get more done overall.
      </StyledTipsText>
      <StyledTipsTitle>Connect your actions to focus periods</StyledTipsTitle>
      <StyledTipsText>
        We can typically focus for at most around 30 minutes, so block off time into those chunks
        and try to focus on a single action for that chunk of time. This will not only help you get
        more done, but it will also improve your focus, something which is a learned skill you will
        develop further over the next few weeks.
      </StyledTipsText>
    </div>
  );
};

export default TakeControlTips;
