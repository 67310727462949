import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { StyledButtonsContainer, StyledDescription, StyledTabContainer } from '../StyledComponents';
import { Collapse, Input, Select } from 'antd';
import { Button, DatePicker } from '../../../core/components/common';
import { CoursesGroups, domainRegex } from '../../const';
import { getTime, isPast } from 'date-fns';
import { useMutation } from '@apollo/client';
import {
  ADMIN_COURSES_BY_DOMAIN,
  ADMIN_CREATE_CORPORATE_ACC,
  ADMIN_EXPIRATION_BY_DOMAIN,
} from '../../../graphql/mutations';

const { Panel } = Collapse;

const CorporateUsers = () => {
  const [createCorporateAcc, { loading: createCorporateAccLoading }] = useMutation<any>(
    ADMIN_CREATE_CORPORATE_ACC,
  );
  const [updateCoursesByDomain, { loading: updateCoursesByDomainLoading }] =
    useMutation<any>(ADMIN_COURSES_BY_DOMAIN);
  const [updateExpirationByDomain, { loading: updateExpirationByDomainLoading }] = useMutation<any>(
    ADMIN_EXPIRATION_BY_DOMAIN,
  );

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  // First section - Assign corporate curriculum
  const [field1, setField1] = useState('');
  const [field2, setField2] = useState(CoursesGroups[1]);
  const [expiration, setExpiration] = useState<number | undefined>(undefined);
  const firstSubmitDisabled = !field1.length || !domainRegex.test(field1);
  const handleFirstSectionSubmit = async () => {
    createCorporateAcc({
      variables: {
        values: {
          domain: field1,
          coursesAvailableList: field2,
        },
      },
    })
      .then(() => {
        if (expiration) {
          updateExpirationByDomain({
            variables: {
              values: {
                domain: field1,
                expiration,
              },
            },
          })
            .then(() => {
              setIsSuccess(true);
            })
            .catch(() => {
              setErrorMessage('Ooops');
            });
        }
      })
      .catch(() => {
        setErrorMessage('Ooops');
      });
  };
  const handleFirstSectionReset = () => {
    setField1('');
    setField2(CoursesGroups[2]);
    setExpiration(undefined);
  };

  // Second section - Change curriculum
  const [field3, setField3] = useState('');
  const [field4, setField4] = useState(CoursesGroups[1]);
  const secondSubmitDisabled = !field3.length || !domainRegex.test(field3);
  const handleSecondSectionSubmit = async () => {
    updateCoursesByDomain({
      variables: {
        values: {
          domain: field3,
          coursesAvailableList: field4,
        },
      },
    })
      .then(() => {
        setIsSuccess(true);
      })
      .catch(() => {
        setErrorMessage('Ooops');
      });
  };
  const handleSecondSectionReset = () => {
    setField3('');
    setField4(CoursesGroups[1]);
  };

  // Third section - Modify Corporate License Expiration
  const [field5, setField5] = useState('');
  const [field6, setField6] = useState<number | undefined>(undefined);

  const thirdSubmitDisabled = !field5.length || !field6 || field6 < 0 || !domainRegex.test(field5);

  const handleThirdSectionSubmit = async () => {
    updateExpirationByDomain({
      variables: {
        values: {
          domain: field5,
          expiration: field6,
        },
      },
    })
      .then(() => {
        setIsSuccess(true);
      })
      .catch(() => {
        setErrorMessage('Ooops');
      });
  };
  const handleThirdSectionReset = () => {
    setField5('');
    setField6(undefined);
  };

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      setErrorMessage(null);
    }
  }, [errorMessage]);
  useEffect(() => {
    if (isSuccess) {
      toast.success('Successful');
      setIsSuccess(false);
    }
  }, [isSuccess]);

  return (
    <StyledTabContainer>
      <Collapse>
        <Panel header="Create Corporate Domain and Pre-allocate curriculum" key="1">
          <StyledDescription>
            Create corporate domain and Pre-allocate curriculum option gives Admin possibility to create a
            new company domain in the system and assign a specific curriculum to the organization.
            Admin has to add company domain, choose curriculum and set Free Subscription expiry date
            (if applicable)
          </StyledDescription>
          <Input
            value={field1}
            onChange={(e) => setField1(e.target.value)}
            bordered
            size="large"
            placeholder="organization domain (ex: gusto.com)"
          />
          <Select
            labelInValue
            defaultValue={{ value: 1, label: 'Fundamentals, Mastery' }}
            style={{ width: '100%' }}
            onChange={(value) => {
              setField2(CoursesGroups[value.value]);
            }}
            options={[
              {
                value: 0,
                label: 'Foundations, Mastering Personal Effectiveness',
              },
              {
                value: 1,
                label: 'Fundamentals, Mastery',
              },
              {
                value: 2,
                label: 'Fundamentals (For Managers), Mastery (For Managers)',
              },
            ]}
          />
          <DatePicker
            style={{ width: '100%' }}
            size={'large'}
            disabledDate={(current) => current && isPast(current)}
            onChange={(date) => {
              if (date) {
                setExpiration(Math.floor(getTime(date) / 1000));
              } else {
                setExpiration(undefined);
              }
            }}
          />
          <StyledButtonsContainer>
            <Button isSecondary onClick={handleFirstSectionReset}>
              Reset
            </Button>
            <Button
              isLoading={createCorporateAccLoading}
              isDisabled={firstSubmitDisabled}
              onClick={handleFirstSectionSubmit}
            >
              Submit
            </Button>
          </StyledButtonsContainer>
        </Panel>
        <Panel header="Change (and Reset) Curriculum" key="2">
          <StyledDescription>
            Change curriculum option gives Admin possibility to assign a different curriculum to a
            user. Admin has to chose curriculum and add user’s email/company domain. The user will
            start a new curriculum from scratch, all progress in the previous curriculum is lost.
          </StyledDescription>
          <Input
            value={field3}
            onChange={(e) => setField3(e.target.value)}
            bordered
            size="large"
            placeholder="organization domain (ex: gusto.com)"
          />
          <Select
            labelInValue
            defaultValue={{ value: 1, label: 'Fundamentals, Mastery' }}
            style={{ width: '100%' }}
            onChange={(value) => {
              setField4(CoursesGroups[value.value]);
            }}
            options={[
              {
                value: 0,
                label: 'Foundations, Mastering Personal Effectiveness',
              },
              {
                value: 1,
                label: 'Fundamentals, Mastery',
              },
              {
                value: 2,
                label: 'Fundamentals (For Managers), Mastery (For Managers)',
              },
            ]}
          />
          <StyledButtonsContainer>
            <Button isSecondary onClick={handleSecondSectionReset}>
              Reset
            </Button>
            <Button
              isLoading={updateCoursesByDomainLoading}
              isDisabled={secondSubmitDisabled}
              onClick={handleSecondSectionSubmit}
            >
              Submit
            </Button>
          </StyledButtonsContainer>
        </Panel>
        <Panel header="Assign Users to 1 Year Free Plan" key="3">
          <StyledDescription>
            Self-Guided Free Plan is a Free 1 year plan that gives users possibility to access
            platform for a year free of charge. Admin has to add company domain that users belong
            to.
          </StyledDescription>
          <Input
            value={field5}
            onChange={(e) => setField5(e.target.value)}
            bordered
            size="large"
            placeholder="organization domain (ex: gusto.com)"
          />
          <DatePicker
            style={{ width: '100%' }}
            size={'large'}
            disabledDate={(current) => current && isPast(current)}
            onChange={(date) => {
              if (date) {
                setField6(Math.floor(getTime(date) / 1000));
              }
            }}
          />
          <StyledButtonsContainer>
            <Button isSecondary onClick={handleThirdSectionReset}>
              Reset
            </Button>
            <Button
              isLoading={updateExpirationByDomainLoading}
              isDisabled={thirdSubmitDisabled}
              onClick={handleThirdSectionSubmit}
            >
              Submit
            </Button>
          </StyledButtonsContainer>
        </Panel>
      </Collapse>
    </StyledTabContainer>
  );
};

export default CorporateUsers;
