import React, { createContext, useReducer } from 'react';
import { DndContextType } from './types';
import dndReducer, { dndInitialValue } from './dndReducer';

const DndValueContext = createContext<DndContextType>(dndInitialValue);

const DndDispatchContext = createContext<{ dispatch: React.Dispatch<any> }>({
  dispatch: () => null,
});

let dndDispatch: React.Dispatch<any> = () => null;

const DndContextProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = useReducer(dndReducer, dndInitialValue, undefined);
  dndDispatch = dispatch;
  return <DndValueContext.Provider value={state}>{children}</DndValueContext.Provider>;
};

export { DndContextProvider, DndValueContext, dndDispatch };
