import { Typography } from 'antd';
import styled from 'styled-components';
import { theme } from '../../core/styles/styled-components';

const { Title } = Typography;
export const StyledContainer = styled.div`
  background-color: var(--color-main-grey);
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-bottom: 15px;
`;
export const StyledContentContainer = styled.div`
  display: flex;

  @media ${theme.device.tablet.max} {
    padding-top: 113px;
  }

  @media ${theme.device.mobile.max} {
    padding-top: 70px;
  }
`;
export const StyledPageContainer = styled.div`
  flex: 1;
  font-size: 1rem;
  margin-top: 2.112rem;
  padding: 0 1rem;
`;
export const StyledTitle = styled(Title)`
  && {
    margin-bottom: 40px;
    font-size: 1.77rem;
    font-weight: 700;

    @media ${theme.device.mobile.max} {
      margin-bottom: 22px;
    }
  }
`;
