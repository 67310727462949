import React, { useEffect, useState } from 'react';
import { Typography, List } from 'antd';
import styled from 'styled-components';

import { ReactComponent as Tick } from '../svg/tick.svg';
import theme from 'core/styles/styled-components/theme';
import { useLocation } from 'react-router-dom';
import { ACCESS_SIGN_UP_PATH } from '../../../core/constants/routePaths';

const items = [
  {
    key: 1,
    render: () => (
      <div style={{ marginTop: '8px' }}>
        Get <b>more</b> done in <b>less</b> time
      </div>
    ),
  },
  {
    key: 2,
    render: () => (
      <div>
        Integrate <b>work</b> with the rest of your <b>life</b>
      </div>
    ),
  },
  {
    key: 3,
    render: () => (
      <div>
        Find <b>meaning</b> and <b>motivation</b> in your work
      </div>
    ),
  },
  {
    key: 4,
    render: () => (
      <div>
        Master <b>unexpected disruption</b> to your plans without stress
      </div>
    ),
  },
];
const newItems = [
  {
    key: 1,
    render: () => <div>Stay Productive When No One is Looking</div>,
  },
  {
    key: 2,
    render: () => <div>Master Self-Motivation</div>,
  },
  {
    key: 3,
    render: () => <div>Find Balance in You Day</div>,
  },
  {
    key: 4,
    render: () => <div>Master the Unpredictable</div>,
  },
];
const accessItems = [
  {
    key: 1,
    render: () => (
      <div>
        One Year of Access to our Remote Work Skills Development Environment - Web and Mobile
      </div>
    ),
  },
  {
    key: 2,
    render: () => <div>Over 100 Microlearning videos, with more published each month</div>,
  },
  {
    key: 3,
    render: () => <div>Access to live skills workouts</div>,
  },
  {
    key: 4,
    render: () => <div>Join BillionMinds Remote Work Community</div>,
  },
];

const Container = styled.div`
  margin-bottom: 40px;

  @media ${theme.device.desktop.min} {
    max-width: 350px;
  }

  @media ${theme.device.tablet.max} {
    max-width: 270px;
  }

  @media ${theme.device.mobile.max} {
    max-width: 100%;
    padding-top: 17px;
    margin-bottom: 24px;
  }
`;

const StyledTitle = styled(Typography.Title)`
  &.ant-typography {
    color: var(--color-dark-blue);
    font-size: 20px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 16px;

    @media ${theme.device.tablet.min} {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 30px;
    }
    @media ${theme.device.mobile.max} {
      line-height: 24px;
    }
  }
`;

const StyledSubTitle = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;

  @media ${theme.device.mobile.max} {
    display: none;
  }
`;

const StyledMeta = styled(List.Item.Meta)`
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: -25px;

  .ant-list-item-meta-avatar {
    position: relative;

    svg {
      position: absolute;
      top: -8px;
    }
  }

  .ant-list-item-meta-content {
    margin-left: 50px;

    @media ${theme.device.tablet.min} {
      max-width: 204px;
    }
  }

  .ant-list-item-meta-title {
    color: var(--color-dark-blue);
    line-height: 16px;
  }
`;

const StyledList = styled(List)`
  @media ${theme.device.mobile.max} {
    display: none;
  }
`;

const Features = () => {
  // const [isAccess, setIsAccess] = useState<boolean>(false);
  // const location = useLocation();
  // useEffect(() => {
  //   setIsAccess(location.pathname === ACCESS_SIGN_UP_PATH);
  // }, [location.pathname]);

  return (
    <Container>
      <StyledTitle level={2}>Are you ready to hone skills for the Future of Work?</StyledTitle>
      <StyledSubTitle>
        BillionMinds helps you embed skills that allow you to thrive in the most unstructured and
        ambiguous work environments. BillionMinds offers specialized programs for individual
        employees and managers who work in flexible work environments, such as hybrid of remote
        workplaces. Our simple 10 minute Learn/Do Experiences will help you:
      </StyledSubTitle>
      <StyledList
        dataSource={newItems}
        renderItem={(item: any) => (
          <StyledMeta key={item.key} avatar={<Tick />} title={item.render()} />
        )}
      />
    </Container>
  );
};

export default Features;
