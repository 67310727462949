import { gql, makeVar } from '@apollo/client';
import { Outcome } from '../../../core/types/outcome';
import { ModalSetting } from '../userSettings/userSettingsField';

export interface CompleteValue {
  shouldUpdateStatusToComplete: ModalSetting;
  shouldUpdateStatusToCurrent: ModalSetting;
  outcomeToComplete: null | Outcome;
  outcomeToIncomplete: null | Outcome;
}

export const completeInitialValue: CompleteValue = {
  shouldUpdateStatusToComplete: null,
  shouldUpdateStatusToCurrent: null,
  outcomeToComplete: null,
  outcomeToIncomplete: null,
};

export const completeVar = makeVar<CompleteValue>(completeInitialValue);
export const COMPLETE_STATE = gql`
  query Tutorial {
    complete @client
  }
`;
