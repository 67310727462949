import { createGlobalStyle } from 'styled-components';

import theme from './theme';

// TODO: replace #202635 with var(--color-dark-blue) all across the app

const GlobalStyle = createGlobalStyle`
  :root {
    --app-height: 100%;
    --color-green: #3cba00;
    --color-yellow: #ffc227;
    --color-dark-blue: #202635;
    --color-blue: #20b4f3;
    --color-blue-2: #102DBC;
    --color-white: #ffffff;
    --color-grey: #a4a6ad;
    --color-grey-2: #e5e6ea;
    --color-main-grey: #ebecef;
    --color-main-grey-2: #eff0f2;
    --color-dark-grey: #d5d7dd;
    --color-main-blue: #0cdffc;
    --color-main-dark-blue: #1269ee;
    --color-main-light-grey: #c5ccdd;
    --color-main-error-red: #ba0000;
    --color-tag-minty-green: #74b5b5;
    --color-tag-coldy-red: #b57483;
    --color-facebook: #3e8fc3;
    --color-linkedin: #1568bf;
    --color-red: #FF7B76;
    --color-light-purple: rgba(177, 190, 249, 0.4);
    --color-purple: rgba(177, 190, 249, 0.4);
    --color-purple-strong: #7340DD;
    --color-bright-purple: #B1BEF9;
    --color-deep-gray: #3c4751;
    --gradient-sign-in-background: linear-gradient(90deg, rgba(179, 228, 239, 0.3) 13.09%, rgba(189, 250, 184, 0.3) 98.89%);
    --gradient-green-blue: linear-gradient(
      180deg,
      var(--color-green) 0%,
      var(--color-blue) 36.16%
    );
    --gradient-action-background: linear-gradient(
      270deg,
      #eff0e1 4.28%,
      #e3ebee 100%
    );
    --gradient-result-background: linear-gradient(
      270deg,
      #98cee1 4.28%,
      #edc0f1 100%
    );
    --gradient-main-logo: linear-gradient(
      90deg,
      var(--color-main-blue) 13.09%,
      var(--color-main-dark-blue) 98.89%
    );
    --font-family-base: 'Raleway', sans-serif;
  }

  html {
    @media ${theme.device.mobile.min} {
      font-size: 14px;
    }

    @media ${theme.device.tablet.min} {
      font-size: 18px;
    }

    @media ${theme.device.desktop.min} {
      font-size: 18px;
    }
    font-family: var(--font-family-base);
  }

  body {
    background-color: var(--color-main-grey);
  }

  #root {
    height: 100%;
  }

  .ant-input, 
  textarea.ant-input,
  .ant-select-selector,
  .ant-picker {
    margin-bottom: 16px;
    border: none;
    border-radius: 4px;
  }

  textarea.ant-input {
    height: 64px;
  }

  .ant-select {
    width: 100%;
    margin-bottom: 16px;
    line-height: 34px;
  }

  .ant-select-selector {
    margin: 0;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    border:none;

    .ant-select-selection-search-input {
      height: 100%;
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 40px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border:none;
    border-radius: 4px;
  }

  .ant-picker {
    width: 146px;

    .ant-picker-suffix {
      line-height: 0;
    }
  }

  .ant-picker-cell .ant-picker-cell-inner {
    border-radius: 14px;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: var(--color-blue);
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: var(--color-blue);
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-radius: 14px;
  }

  .ant-picker-content th,
  .ant-picker-content td {
    font-size: 12px;
  }

  .ant-picker-content th {
    font-weight: 700;
  }

  .ant-select-item-option-content {
    display: inline-flex;
    align-items: center;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
  
  .ant-modal-wrap-scroll {
    @media ${theme.device.mobile.max} {
      overflow: auto !important;
    }
  }

  @media ${theme.device.tablet.max} {
    input,
    select:focus,
    textarea {
      font-size: 16px !important;
      line-height: 14px !important;
    }
  }

  .scroll-disabled {
    overflow: hidden;
    position: fixed;
  }

  .ant-modal-root {
    .ant-modal-wrap.ant-modal-centered{
      @supports (-webkit-overflow-scrolling: touch) {
        -webkit-overflow-scrolling: auto;
      }
    }    
    .ant-modal-wrap{
      overflow: hidden;
    }
    
  }
  
  .col--fit {
    min-width: 0;
    flex-basis: auto !important;
  }

  .col--fill {
    min-width: 0;
    flex: 1 1 0% !important;
    flex-basis: 0% !important;
  }
  
  .ant-select-disabled .ant-select-arrow{
    opacity: 0.25;
  }
`;

export default GlobalStyle;
