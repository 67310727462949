import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as SVGLogo } from '../../svg/logo.svg';
import theme from 'core/styles/styled-components/theme';

const StyledLink = styled(Link)`
  display: flex;
`;

const StyledLogo = styled(SVGLogo)`
  // width: 95px;
  //
  // @media ${theme.device.tablet.min} {
  //   width: 125px;
  // }
  //
  // @media ${theme.device.desktop.min} {
  //   width: 152px;
  // }
`;

const Logo: FC = () => {
  return (
    <StyledLink to="/">
      <StyledLogo />
    </StyledLink>
  );
};

export default Logo;
