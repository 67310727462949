import styled from 'styled-components';
import { Image } from 'antd';

export const StyledImage = styled(Image)`
  color: transparent;
  && {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
`;
export const StyledDefaultImage = styled.div`
  color: #202635;

  && {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(270deg, #eff0e1 4.28%, #e3ebee 100%);

    font-weight: 700;
    font-size: 18px;
  }
`;
