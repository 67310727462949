import React, { MouseEvent } from 'react';
import styled, { css } from 'styled-components';

import { Loader } from '../Loader';
import { ButtonProps } from './types';

const StyledButton = styled.button<Pick<ButtonProps, 'isSecondary' | 'isDisabled'>>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.444rem 0.889rem;
  background-color: var(--color-dark-blue);
  border: 0;
  border-radius: 32px;
  color: var(--color-white);
  font-size: 0.778rem;
  font-weight: 600;
  cursor: pointer;
  outline: 0;

  ${({ isSecondary }) =>
    isSecondary &&
    css`
      background-color: transparent;
      color: var(--color-dark-blue);
      font-weight: 400;
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

const StyledIconWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  margin-right: 0.278rem;

  > svg {
    pointer-events: none;
  }
`;

const StyledLoader = styled(Loader)`
  color: inherit;
`;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, children, isSecondary, isDisabled, isLoading, htmlType = 'button', onClick, icon },
    ref,
  ) => {
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      if (!isDisabled && !isLoading && onClick) {
        onClick(event);
      }
    };

    return (
      <StyledButton
        className={className}
        type={htmlType}
        onClick={handleClick}
        isSecondary={isSecondary}
        isDisabled={isDisabled}
        ref={ref}
      >
        {icon && <StyledIconWrapper>{icon}</StyledIconWrapper>}
        {isLoading ? <StyledLoader size="small" /> : children}
      </StyledButton>
    );
  },
);

export { Button };
