import React from 'react';
import { SectionName } from '../../../graphql/types';
import { ReactComponent as LeftArrowIcon } from '../../../core/svg/arrow-left.svg';
import styled from 'styled-components';
import CoreSkillsTips from './Tips/CoreSkillsTips';
import { theme } from '../../../core/styles/styled-components';
import TakeControlTips from './Tips/TakeControlTips';
import FindMeaningTips from './Tips/FindMeaningTips';
import IntegrateWorkTips from './Tips/IntegrateWorkTips';
import { coursesMutation } from '../../../apollo/stateFields/courses';
import MasterYourUnpredictableDayTips from './Tips/MasterYourUnpredictableDayTips';

const StyledTipsLink = styled.div`
  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
  margin-bottom: 12px;

  span {
    margin-left: 8px;
    color: var(--color-grey);
  }

  svg {
    color: var(--color-dark-blue);
  }
`;

const StyledWrapper = styled.div`
  padding: 0 8px 80px;

  @media ${theme.device.tablet.max} {
    padding: 15px 8px 150px;
  }
`;

const TipsContent = ({ sectionName }: { sectionName: SectionName }) => {
  const { setTipsSection } = coursesMutation;

  const getTipsContent = () => {
    switch (sectionName) {
      case 'Core_Skills':
        return <CoreSkillsTips />;
      case 'Take_Back_Control_of_Your_Day':
        return <TakeControlTips />;
      case 'Find_Meaning_in_Your_Day':
        return <FindMeaningTips />;
      case 'Integrate_Work_With_Your_Life':
        return <IntegrateWorkTips />;
      case 'Master_Your_Unpredictable_Day':
        return <MasterYourUnpredictableDayTips />;

      default:
        return null;
    }
  };
  return (
    <StyledWrapper>
      <StyledTipsLink
        onClick={() => {
          setTipsSection(null);
        }}
      >
        <LeftArrowIcon />
        <span>Mastering Personal Effectiveness</span>
      </StyledTipsLink>
      {getTipsContent()}
    </StyledWrapper>
  );
};

export default TipsContent;
