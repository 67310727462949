import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Modal, Typography } from 'antd';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { SubmitButton, Form } from 'formik-antd';
import { ArrowDownIcon } from '../../../../../../core/icons';
import { theme } from '../../../../../../core/styles/styled-components';
import { EditNoteContentProps, EditNoteProps } from './types';
import { Button, Loader, Select } from '../../../../../../core/components/common';
import { isEmpty, omit } from 'lodash';
import bodyScrollLock from '../../../../../../core/utils/bodyScrollLock';
import {
  DebouncedTextAreaInput,
  DebouncedTextInput,
} from '../../../../../../core/enchancedComponents/DebouncedTextField/DebouncedTextField';
import { Permission, Workspace } from '../../../../../../core/types/workspace';
import { renameNotAssignedWorkspace } from '../utils';
import useSubscribeEntity from '../../../../../../hooks/useSubscribeEntity';
import { SUBSCRIBE_NOTE } from '../../../../../../graphql/subscriptions';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  FETCH_USER_WORKSPACE,
  FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
} from '../../../../../common/graphql/queries';
import { sortWorkspaces } from '../../utils';
import { sendEvent } from '../../../../../../core/integrations/sentry/events';
import { toast, ToastContent } from 'react-toastify';
import { Note } from '../../../../../../core/types/note';
import { useCheckSharedEntityMoved } from '../../../../../../hooks/useCheckSharedEntityMoved';
import {
  CUSTOM_ENTITY_TYPES,
  CUSTOM_ERROR_MESSAGE,
} from '../../../../../../apollo/stateFields/error/errorFields';
import { useLazyLoadEntity } from '../../../../../../hooks/useLazyLoadEntity';
import useMoveNote from '../../../../../interactions/note/useMoveNote';
import useUpdateNote from '../../../../../interactions/note/useUpdateNote';
import {
  FetchUserWorkspace,
  FetchUserWorkspacesWithInvitedCount,
} from '../../../../../workspaces/types';
import { errorMutation } from '../../../../../../apollo/stateFields/error';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../../../apollo/stateFields/userSettings/userSettingsField';
import { boardEditedEntityMutation } from '../../../../../../apollo/stateFields/boardEditedEntity';
import {
  BOARD_EDITED_ENTITY_STATE,
  BoardEditedEntityValue,
} from '../../../../../../apollo/stateFields/boardEditedEntity/boardEditedEntityFileds';

const { Title } = Typography;

const StyledTitle = styled(Title)`
  @media ${theme.device.mobile.max} {
    font-size: 20px !important;
    margin-bottom: 19px !important;
  }
`;

const StyledModal = styled(Modal)`
  display: inline-flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  pointer-events: auto;

  @media ${theme.device.tablet.max} {
    position: fixed;
    top: 0 !important;
    right: 0 !important;
    left: auto !important;
    width: 384px !important;
    margin: 0 !important;
  }

  @media ${theme.device.mobile.max} {
    left: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
  }
`;

const StyledContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
  background: #d5d7dd;
`;

const StyledForm = styled(Form)`
  background: #d5d7dd;

  @media ${theme.device.desktop.min} {
    display: flex;
    min-height: 100%;
  }
`;

const StyledMainContent = styled.div`
  padding: 32px 24px 0;
  background: var(--color-main-grey-2);

  @media ${theme.device.desktop.min} {
    flex: 1;
    padding: 24px 32px 24px 40px;
  }

  @media ${theme.device.tablet.max} {
    height: var(--app-height);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 0;
  }

  @media ${theme.device.mobile.max} {
    padding: 32px 16px 0;
  }
`;

const StyledInputName = styled(DebouncedTextInput)`
  && {
    line-height: 40px;
    height: 40px;
  }
`;

const StyledDebouncedTextAreaInput = styled(DebouncedTextAreaInput)`
  && {
    max-height: 160px !important;
    height: 160px !important;
  }
`;

const StyledLabel = styled.label`
  display: block;
  font-weight: 700;
  margin-bottom: 0.4285em;
  color: #202635;
  line-height: 1.1428;
`;

const StyledSelect = styled(Select)`
  && {
    .ant-select-item.ant-select-item-option {
      background-color: var(--color-white);

      &:hover {
        background-color: #e0e0e0;
      }
    }
  }
`;

const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.689rem;
  margin-top: 0.689rem;

  @media ${theme.device.tablet.max} {
    justify-content: flex-start;
    margin: 0 0 0 -24px;
    padding: 20px 24px;
    background-color: #e5e6ea;
    width: 384px;
  }

  @media ${theme.device.mobile.max} {
    margin: 0 0 0 -16px;
    padding: 20px 16px;
    width: 100vw;
  }
`;

const StyledButton = styled(Button)`
  height: 1.778rem;
`;

const StyledCancelButton = styled(StyledButton)`
  border: 1px solid var(--color-dark-blue);
  font-weight: 600;

  @media ${theme.device.tablet.max} {
    margin-left: 16px;
    height: 32px;
    font-size: 14px;
  }
`;

export const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const EditNoteModalContent = React.memo(
  ({ noteEditedEntity, onCancel, notes }: EditNoteContentProps) => {
    const [note, setNote] = useState<Note | null>(null);
    const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
    const { defaultWorkspaceId }: UserSettingsValue = userSettingsData?.userSettingsField;
    const { data: workspacesResponse } = useQuery<FetchUserWorkspacesWithInvitedCount>(
      FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
    );
    const [updateNote, { data: updateNoteData }] = useUpdateNote(note);
    const [moveNote, { data: moveNoteData }] = useMoveNote(note);
    const updatedData = useMemo(() => {
      return updateNoteData?.updateUserWorkspaceNote || moveNoteData?.moveUserWorkspaceNote || null;
    }, [updateNoteData, moveNoteData]);
    const [currentWorkspaceId, setCurrentWorkspaceId] = useState<number | undefined>(undefined);
    const {
      getEntity,
      data: noteData,
      resetData: resetNoteData,
    } = useLazyLoadEntity(CUSTOM_ENTITY_TYPES.NOTE, noteEditedEntity?.workspaceId);
    const { setErrorMessage, setErrorWorkspaces, setEntityType } = errorMutation;
    const prevWorkspace = useRef<Workspace | null>(null);

    const [getWorkspace] = useLazyQuery<FetchUserWorkspace>(FETCH_USER_WORKSPACE, {
      fetchPolicy: 'network-only',
    });

    const workspaces =
      workspacesResponse?.fetchUserWorkspacesWithInvitedCount
        .slice()
        .sort((a, b) => sortWorkspaces(a, b, defaultWorkspaceId)) || [];

    const workspace = useMemo(
      () => workspaces.find((workspace) => workspace.id === note?.workspaceId),
      [workspaces, note],
    );

    const validationSchema = Yup.object().shape({
      name: Yup.string().max(256, 'Max length of name is 256 characters!').required(),
      description: Yup.string()
        .max(1000, 'Max length of description is 1000 characters!')
        .nullable(),
      workspaceId: Yup.number().nullable(),
    });
    const viewerPermission = useMemo(
      () =>
        workspaces.find((workspace) => workspace.id === note?.workspaceId)?.permission ===
          Permission.VIEWER || false,
      [workspace],
    );
    const editorPermission = useMemo(
      () =>
        workspaces.find((workspace) => workspace.id === note?.workspaceId)?.permission ===
          Permission.EDITOR || false,
      [workspace],
    );

    const onSubmit = useCallback(
      async (noteVariables) => {
        if (note) {
          const variables =
            note.workspaceId !== noteVariables.workspaceId
              ? {
                  note: omit(noteVariables, '__typename', 'isArchived'),
                  workspaceId: note.workspaceId,
                  targetWorkspaceId: noteVariables.workspaceId,
                }
              : {
                  note: omit(noteVariables, '__typename', 'isArchived'),
                  workspaceId: note.workspaceId,
                };
          try {
            if (note.workspaceId !== noteVariables.workspaceId) {
              await moveNote({
                variables,
              });
            } else {
              await updateNote({
                variables,
              });
            }
            sendEvent('note-edit', 'Submit note edit', {
              'Note ID': note.id,
            });
          } catch (error) {
            console.error(error);
            toast(error as ToastContent);
          } finally {
            onCancel();
          }
        }
      },
      [moveNote, updateNote, note],
    );

    const { data: subscriptionData } = useSubscribeEntity(
      SUBSCRIBE_NOTE,
      note?.workspaceId,
      note?.id,
    );
    useCheckSharedEntityMoved(
      updatedData ? updatedData : note,
      updatedData ? CUSTOM_ENTITY_TYPES.NOTE : CUSTOM_ENTITY_TYPES.NOTE,
      updatedData ? updatedData?.workspaceId : currentWorkspaceId,
    );

    useEffect(() => {
      if (noteEditedEntity) {
        bodyScrollLock.enable();
      }
    }, [noteEditedEntity]);

    useEffect(() => {
      if (note) {
        setCurrentWorkspaceId(note.workspaceId);
      }
    }, [note]);

    useEffect(() => {
      let editedNote = null;
      if (noteEditedEntity) {
        editedNote = notes?.find((a) => a.id === noteEditedEntity.id) || null;
        if (!editedNote) {
          if (!noteData) {
            getEntity(noteEditedEntity);
          } else {
            editedNote = noteData as Note;
            setNote(editedNote);
          }
        } else {
          setNote(editedNote);
        }
      } else {
        resetNoteData();
      }
    }, [noteData, notes, noteEditedEntity, currentWorkspaceId]);

    useEffect(() => {
      if (note) {
        if (!workspace) {
          if (prevWorkspace.current?.id) {
            getWorkspace({
              variables: {
                workspaceId: prevWorkspace.current?.id,
              },
            }).then((res) => {
              if (!res.data?.fetchUserWorkspace.id) {
                setErrorMessage(CUSTOM_ERROR_MESSAGE.NO_RELATION);
                setErrorWorkspaces([
                  {
                    id: prevWorkspace.current?.id!,
                    permission: Permission.VIEWER,
                    type: 'Workspace',
                    name: prevWorkspace.current?.name,
                  },
                ]);
              }
            });
          }
        } else {
          prevWorkspace.current = workspace;
        }
      }
    }, [workspaces, note]);

    useEffect(() => {
      if (!!subscriptionData?.subscribeToChanges) {
        if (!workspaces.some((w) => w.id === subscriptionData?.subscribeToChanges?.workspaceId)) {
          setErrorMessage(CUSTOM_ERROR_MESSAGE.ENTITY_SPACE_CHANGED);
          setEntityType(CUSTOM_ENTITY_TYPES.NOTE);
        }
      }
    }, [subscriptionData?.subscribeToChanges]);

    if (!workspace && note) {
      onCancel();
      return null;
    }

    return (
      <StyledContainer>
        {note ? (
          <Formik
            enableReinitialize
            validateOnChange={true}
            validateOnBlur={true}
            initialValues={note}
            validationSchema={validationSchema}
            onSubmit={() => {}}
          >
            {({ values, setFieldValue, isSubmitting, errors, dirty, handleReset }) => {
              return (
                <StyledForm>
                  <StyledMainContent>
                    <div>
                      <StyledTitle level={3}>
                        {viewerPermission ? `View Incoming note` : `Edit Incoming note`}
                      </StyledTitle>
                      <StyledLabel>Space</StyledLabel>
                      <StyledSelect
                        disabled={note?.isArchived || viewerPermission}
                        name="workspaceId"
                        defaultValue={[workspaces[0].name]}
                        suffixIcon={<ArrowDownIcon />}
                        placeholder="Select Space"
                        options={workspaces
                          ?.filter(
                            (workspace) =>
                              workspace?.permission !== Permission.VIEWER ||
                              note?.workspaceId === workspace.id ||
                              false,
                          )
                          .map((workspace: any) => ({
                            label: renameNotAssignedWorkspace(workspace.name),
                            value: workspace.id,
                          }))}
                        onChange={() => {
                          setCurrentWorkspaceId(values.workspaceId);
                        }}
                      />
                      <StyledLabel>Name</StyledLabel>
                      <StyledInputName
                        key={`${note?.isArchived}${viewerPermission}-name`}
                        disabled={note?.isArchived || viewerPermission}
                        name="name"
                        maxLength={256}
                        value={values.name}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('name', e?.target?.value);
                        }}
                        autoComplete="off"
                      />
                      <StyledLabel>Description</StyledLabel>
                      <StyledDebouncedTextAreaInput
                        key={`${note?.isArchived}${viewerPermission}-description`}
                        disabled={note?.isArchived || viewerPermission}
                        autoSize={{ minRows: 6, maxRows: 8 }}
                        name="description"
                        maxLength={1000}
                        value={values.description}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                          setFieldValue('description', e?.target?.value);
                        }}
                      />
                    </div>
                    <StyledButtonContainer>
                      <SubmitButton
                        disabled={
                          !isEmpty(errors) || !dirty || note?.isArchived || viewerPermission
                        }
                        loading={isSubmitting}
                        type="primary"
                        shape="round"
                        onClick={async () => {
                          await onSubmit(values);
                        }}
                      >
                        {note?.isArchived ? 'Incoming note is archived' : 'Save Changes'}
                      </SubmitButton>
                      <StyledCancelButton
                        isSecondary
                        isDisabled={viewerPermission}
                        onClick={() => {
                          handleReset();
                          onCancel && onCancel();
                        }}
                      >
                        Cancel
                      </StyledCancelButton>
                    </StyledButtonContainer>
                  </StyledMainContent>
                </StyledForm>
              );
            }}
          </Formik>
        ) : (
          <StyledLoader />
        )}
      </StyledContainer>
    );
  },
);

const EditNoteModal = React.memo(({ notes }: EditNoteProps) => {
  const { data: boardEditedData } = useQuery(BOARD_EDITED_ENTITY_STATE);
  const { noteEditedEntity }: BoardEditedEntityValue = boardEditedData?.boardEditedEntity;
  const { showEditNoteModal } = boardEditedEntityMutation;

  const onCancel = () => {
    showEditNoteModal(null);
  };

  const handleOnClose = () => {
    bodyScrollLock.disable();
  };

  return (
    <StyledModal
      destroyOnClose
      afterClose={handleOnClose}
      getContainer={() => document.documentElement}
      onCancel={onCancel}
      visible={!!noteEditedEntity?.id}
      closable
      centered
      mask={false}
      footer={null}
      width={530}
      bodyStyle={{
        padding: 0,
        backgroundColor: 'var(--color-main-grey-2)',
      }}
      style={{
        top: '0',
        left: '20%',
      }}
    >
      {!!noteEditedEntity?.id && (
        <EditNoteModalContent
          noteEditedEntity={noteEditedEntity}
          notes={notes}
          onCancel={onCancel}
        />
      )}
    </StyledModal>
  );
});

export default EditNoteModal;
