import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ACCESS_SIGN_UP_PATH, SIGN_IN_PATH, SIGN_UP_PATH } from 'core/constants/routePaths';
import SignIn from 'features/Auth/components/SignIn';
import { Container, Features, Header } from '../../../features/Auth/components';
import { Col, Row } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import backgroundImage from '../../../features/Auth/svg/background.svg';
import SignUp from '../../../features/Auth/components/SignUp';
import { theme } from '../../styles/styled-components';
import AccessSignUp from '../../../features/Auth/components/AccessSignUp/AccessSignUp';

const GlobalStyle = createGlobalStyle`
  body {
    background: var(--gradient-sign-in-background);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // &:after {
    //   background-image: url(${backgroundImage});
    //   background-size: cover;
    //   bottom: 0;
    //   content: '';
    //   left: 0;
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    //   z-index: -1;
    // }
  }
`;

const StyledContainer = styled(Container)`
  min-height: var(--app-height);
`;

const StyledCol = styled(Col)`
  @media ${theme.device.mobile.max} {
    width: 100%;
  }
`;

const UnauthenticatedApp = React.memo(() => {
  return (
    <StyledContainer data-id="sign-in-page">
      <GlobalStyle />
      <Header />
      <Row justify="space-between">
        <StyledCol flex={1}>
          <Features />
        </StyledCol>
        <StyledCol>
          <Switch>
            <Route exact path={SIGN_UP_PATH} component={SignUp} />
            <Route exact path={SIGN_IN_PATH} component={SignIn} />
            <Route exact path={ACCESS_SIGN_UP_PATH} component={AccessSignUp} />
            <Redirect from="*" to={SIGN_IN_PATH} />
          </Switch>
        </StyledCol>
      </Row>
    </StyledContainer>
  );
});

export default UnauthenticatedApp;
