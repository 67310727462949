import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Input, Select, Spin, Tabs, Typography } from 'antd';
import { CloseIcon, SearchIcon } from '../../../../icons';
import { useQuery, useLazyQuery, useApolloClient } from '@apollo/client';
import { orderBy } from 'lodash';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../../../../../features/common/graphql/queries';
import { ActionCard } from '../../../../../features/actions/components/ActionBoard/ActionCard';
import { SEARCH_ENTITIES } from './graphql/queries';
import { SearchBarProps, SearchResponse } from './types';
import { OutcomeCard } from '../../../../../features/outcomes/components/OutcomeCard';
import { NoteCard } from '../../../../../features/actions/components/ActionBoard/NewInformation/NoteCard/NoteCard';
import { sendEvent } from '../../../../integrations/sentry/events';
import { theme } from '../../../../styles/styled-components';
import { useMediaQuery } from 'react-responsive';
import ModalToDrawerTransformer from '../../../common/ModalToDrawerTransformer/ModalToDrawerTransformer';
import bodyScrollLock from '../../../../utils/bodyScrollLock';
import { ACTION_PATH } from '../../../../constants/routePaths';
import { useHistory } from 'react-router-dom';
import { IHeaderHistory } from '../types';
import {
  SEARCH_BAR_STATE,
  SearchBarValue,
  SearchQueryVariables,
} from '../../../../../apollo/stateFields/searchBar/searchBarFileds';
import { removeSpecialChars, replaceBackSlash } from '../../../../utils/global';
import { FetchUserWorkspacesWithInvitedCount } from '../../../../../features/workspaces/types';
import { searchBarMutation } from '../../../../../apollo/stateFields/searchBar';

const { TabPane } = Tabs;
const { Text } = Typography;

const StyledSearchBar = styled.div`
  position: relative;

  @media ${theme.device.tablet.max} {
    margin: 0;
  }
`;

const StyledBackdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  background: rgba(32, 38, 53, 0.2);

  @media ${theme.device.tablet.max} {
    display: none;
  }
`;

const StyledSearchInput = styled(Input)`
  && {
    width: 354px;
    height: 36px;
    position: absolute;
    left: -354px;
    transform: translateY(-50%);
    z-index: 910;
    padding-right: 7px;
    padding-left: 7px;
    box-shadow: 0 1px 32px rgba(32, 38, 53, 0.2);
    border: none;
    border-radius: 3px;

    &:hover {
      z-index: 1010;
    }

    & > input.ant-input {
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      border-right: 1px solid rgba(32, 38, 53, 0.1);
      border-radius: 0;
    }

    @media ${theme.device.tablet.max} {
      position: static;
      transform: none;
      margin: 16px;
      box-shadow: none;
    }

    @media ${theme.device.mobile.max} {
      width: -webkit-fill-available;
      box-shadow: 0 1px 32px rgba(32, 38, 53, 0.2);

      & .ant-input-suffix {
        display: initial;
      }

      & > input.ant-input {
        border-right: 1px solid rgba(32, 38, 53, 0.1);
      }
    }
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

const StyledSearchResultBox = styled.div`
  min-width: 354px;
  left: -354px;
  top: 20px;
  max-height: calc(var(--app-height) - 75px);
  min-height: 180px;
  padding: 11px 8px;
  overflow: auto;
  position: absolute;
  z-index: 910;
  background: #eff0f2;
  box-shadow: 0 1px 32px rgba(32, 38, 53, 0.2);
  border-radius: 3px;

  .ant-tabs {
    color: var(--color-dark-blue);
    overflow: unset;

    > .ant-tabs-nav {
      padding: 0 3px;

      .ant-tabs-nav-list {
        border-bottom: 1px solid #c5ccdd;
      }

      .ant-tabs-ink-bar {
        height: 1px;
        bottom: -1px;
      }

      .ant-tabs-nav-more {
        padding: 0 4px;
      }
    }
  }

  .ant-tabs-tab {
    padding: 1px 0 7px;

    + .ant-tabs-tab {
      margin-left: 16px;
    }

    small {
      margin-left: 4px;
      font-size: 9px;
    }
  }

  .ant-select-dropdown {
    &[style] {
      width: auto !important;
    }
  }

  .ant-tabs-nav-list {
    width: 100%;
  }

  @media ${theme.device.tablet.max} {
    position: static;
    margin: 0 16px 16px;
    padding: 0;
    box-shadow: none;
    max-height: calc(var(--app-height) - 140px);
  }

  @media ${theme.device.mobile.max} {
    max-height: calc(var(--app-height) - 68px);
    min-width: auto;
  }

  @media ${theme.device.tablet.min} {
    max-width: 354px;
  }
`;

const StyledWorkspaceSelectBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledWorkspaceSelect = styled(Select)`
  && {
    display: inline-block;
    margin: 0;
    line-height: normal;

    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: auto;

        &::after {
          line-height: normal;
        }
      }
    }

    .ant-select-selector .ant-select-selection-item,
    .ant-select-selector .ant-select-selection-placeholder {
      line-height: normal;
    }
  }
`;

const StyledSpin = styled(Spin)`
  display: block;
`;

const StyledModalContainer = styled.div<{ isOpened: boolean }>`
  @media ${theme.device.mobile.max} {
    ${({ isOpened }) =>
      isOpened &&
      css`
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: var(--color-main-grey-2);
        z-index: 1000;
      `}
  }
`;

const SearchBar = React.memo(({ clickOutside }: SearchBarProps) => {
  const [tab, setTab] = useState('all');
  const [query, setQuery] = useState('');
  const [offset, setOffset] = useState(0);
  const limit = 10;
  const [workspaceId, setWorkspaceId] = useState(0);
  const apolloClient = useApolloClient();
  const isDesktop = useMediaQuery({ query: theme.device.desktop.min });
  const history = useHistory<IHeaderHistory>();
  const { data: searchBarData } = useQuery(SEARCH_BAR_STATE);
  const { isOpened }: SearchBarValue = searchBarData?.searchBar;
  const { setSearchVariables, toggleSearchBar } = searchBarMutation;

  const [
    querySearch,
    {
      data: searchData,
      loading: searchLoading,
      refetch: refetchQuerySearch,
      called: searchCalled,
      fetchMore: queryMoreSearch,
      networkStatus: searchStatus,
      variables,
    },
  ] = useLazyQuery<SearchResponse>(SEARCH_ENTITIES, { notifyOnNetworkStatusChange: true });
  const searchEntities = searchData?.searchUserWorkspacesEntities;

  const { data: workspacesResponse } = useQuery<FetchUserWorkspacesWithInvitedCount>(
    FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
  );
  const workspaces = workspacesResponse?.fetchUserWorkspacesWithInvitedCount || [];

  const getFilteredEntities = useCallback(
    (entities) => {
      const sortedEntities = orderBy(entities, ['name', 'updateDate']);

      if (workspaceId === 0) {
        return sortedEntities;
      }

      return sortedEntities.filter((entity) => entity.workspaceId === workspaceId);
    },
    [workspaceId],
  );

  const allEntities = searchEntities
    ? [...searchEntities.action.data, ...searchEntities.note.data, ...searchEntities.outcome.data]
    : [];
  const outcomes = searchEntities ? searchEntities.outcome.data : [];
  const actions = searchEntities ? searchEntities.action.data : [];
  const notes = searchEntities ? searchEntities.note.data : [];
  const allFilterdEntities = getFilteredEntities(allEntities);
  const filterdOutcomes = getFilteredEntities(outcomes);
  const filterdActions = getFilteredEntities(actions);
  const filterdNotes = getFilteredEntities(notes);
  const allEntitiesCount = searchEntities
    ? searchEntities.action.count + searchEntities?.note.count + searchEntities?.outcome.count
    : 0;
  const outcomesCount = searchEntities ? searchEntities.outcome.count : 0;
  const actionsCount = searchEntities ? searchEntities.action.count : 0;
  const notesCount = searchEntities ? searchEntities.note.count : 0;

  const queryWithoutSpecialChars = removeSpecialChars(query);

  useEffect(() => {
    const v = variables as SearchQueryVariables | undefined;
    setSearchVariables(v);
  }, [variables]);

  useEffect(() => {
    if (!isDesktop) {
      if (isOpened) {
        bodyScrollLock.enable();
      } else {
        bodyScrollLock.disable();
      }
    }
  }, [isOpened]);

  const search = (query = '') => {
    if (searchCalled) {
      refetchQuerySearch!({
        query,
        offset: 0,
        limit,
      });
      return;
    }

    querySearch({
      variables: {
        query,
        offset,
        limit,
      },
    });
  };

  const clearSearch = () => {
    apolloClient.writeQuery({
      query: SEARCH_ENTITIES,
      data: {
        searchUserWorkspacesEntities: {
          note: {
            data: [],
            count: 0,
          },
          outcome: {
            data: [],
            count: 0,
          },
          action: {
            data: [],
            count: 0,
          },
        },
      },
      variables: {
        query: '',
      },
    });
    // search();
  };

  const onChangeQuery = (e: ChangeEvent<HTMLInputElement>) => {
    const queryInput = e.target.value;

    if (!queryInput.trim() && !query) {
      return;
    }

    setQuery(queryInput);
    setOffset(0);

    if (!queryInput || !queryInput.trim()) {
      clearSearch();
      return;
    }

    search(replaceBackSlash(queryInput));
  };

  const openSearchBar = () => {
    if (history.location.pathname === ACTION_PATH) {
      toggleSearchBar(true);
    } else {
      history.push(ACTION_PATH, { action: 'isSearchBarOpen' });
    }

    sendEvent('open-search', 'Open search bar');
  };

  const closeSearchBar = () => {
    toggleSearchBar(false);
    setTab('all');
    setQuery('');
    setWorkspaceId(0);
    setOffset(0);
  };

  const onChangeTab = (key: any) => {
    setTab(key);
  };

  const isFetchNeeded = (tabName: string) => {
    switch (tabName) {
      case 'all':
        return allEntities.length !== allEntitiesCount;
      case 'outcomes':
        return outcomes.length !== outcomesCount;
      case 'actions':
        return actions.length !== actionsCount;
      case 'notes':
        return notes.length !== notesCount;
    }
  };

  const onScroll = (e: SyntheticEvent<HTMLDivElement>) => {
    const scrollBlock = e.target as HTMLDivElement;
    const isScrollBottom =
      scrollBlock.scrollHeight - Math.abs(scrollBlock.scrollTop + 1) <= scrollBlock.clientHeight;

    if (isScrollBottom && isFetchNeeded(tab)) {
      setOffset((prevOffset) => prevOffset + 1);
      queryMoreSearch!({
        variables: {
          query,
          offset: offset + 1,
          limit,
        },
      });
    }
  };

  const firstQueryLoading = (searchLoading && searchStatus === 1) || searchStatus === 2;
  const moreQueryLoading = searchLoading && searchStatus === 3;

  const counter = (count: number) => !!count && <small>{count}</small>;

  // TODO: uncomment for future develop after mvp
  // const workspaceSelect = workspaces && (
  //   <StyledWorkspaceSelectBlock>
  //     Space:
  //     <StyledWorkspaceSelect
  //       bordered={false}
  //       suffixIcon={<ArrowDownIcon />}
  //       value={workspaceId}
  //       options={[
  //         {
  //           label: 'All',
  //           value: 0,
  //         },
  //         ...workspaces.map((workspace) => ({
  //           label: workspace.name,
  //           value: workspace.id,
  //         })),
  //       ]}
  //       onChange={(value) => {
  //         setWorkspaceId(value as number);
  //       }}
  //     />
  //   </StyledWorkspaceSelectBlock>
  // );

  useEffect(() => {
    const { state } = history.location;
    if (state && state.action === 'isSearchBarOpen') {
      openSearchBar();
      history.push(ACTION_PATH, { action: '' });
    }
  }, [history.location.pathname]);

  const modalEl = (
    <StyledModalContainer isOpened={isOpened}>
      <StyledBackdrop onClick={closeSearchBar} />
      <StyledSearchInput
        autoFocus
        value={query}
        prefix={<SearchIcon />}
        suffix={<StyledCloseIcon onClick={closeSearchBar} />}
        onChange={onChangeQuery}
      />
      {query && (
        <StyledSearchResultBox id="search-result" onScroll={onScroll}>
          <Tabs activeKey={tab} onChange={onChangeTab}>
            <TabPane
              tab={
                <>
                  <span>All</span>
                  {counter(allEntitiesCount)}
                </>
              }
              key="all"
            >
              {firstQueryLoading ? (
                <StyledSpin />
              ) : (
                <>
                  {/*{!!allEntities.length && workspaceSelect}*/}
                  {!!allFilterdEntities.length ? (
                    allFilterdEntities.map((entity) => {
                      switch (entity.__typename) {
                        case 'Action':
                          return (
                            <ActionCard
                              showStatusSelect
                              isNested
                              isEditOutcomeList={false}
                              key={entity.id}
                              action={entity}
                              highlightText={queryWithoutSpecialChars}
                            />
                          );
                        case 'Outcome':
                          return (
                            <OutcomeCard
                              isNested
                              key={entity.id}
                              outcome={entity}
                              highlightText={queryWithoutSpecialChars}
                            />
                          );
                        case 'Note':
                          return (
                            <NoteCard
                              isNested
                              key={entity.id}
                              note={entity}
                              highlightText={queryWithoutSpecialChars}
                            />
                          );
                        default:
                          return null;
                      }
                    })
                  ) : (
                    <Text>
                      No results were found with <Text strong>{query}</Text>
                    </Text>
                  )}
                  {moreQueryLoading && <StyledSpin />}
                </>
              )}
            </TabPane>
            <TabPane
              tab={
                <>
                  <span>Outcomes</span>
                  {counter(outcomesCount)}
                </>
              }
              key="outcomes"
            >
              {firstQueryLoading ? (
                <StyledSpin />
              ) : (
                <>
                  {/*{!!outcomes.length && workspaceSelect}*/}
                  {!!filterdOutcomes.length ? (
                    filterdOutcomes.map((outcome) => (
                      <OutcomeCard
                        isNested
                        key={outcome.id}
                        outcome={outcome}
                        highlightText={queryWithoutSpecialChars}
                      />
                    ))
                  ) : (
                    <Text>
                      No Outcomes were found with <Text strong>{query}</Text>
                    </Text>
                  )}
                  {moreQueryLoading && <StyledSpin />}
                </>
              )}
            </TabPane>
            <TabPane
              tab={
                <>
                  <span>Actions</span>
                  {counter(actionsCount)}
                </>
              }
              key="actions"
            >
              {firstQueryLoading ? (
                <StyledSpin />
              ) : (
                <>
                  {/*{!!actions.length && workspaceSelect}*/}
                  {!!filterdActions.length ? (
                    filterdActions.map((action) => (
                      <ActionCard
                        showStatusSelect
                        isNested
                        isEditOutcomeList={false}
                        key={action.id}
                        action={action}
                        highlightText={queryWithoutSpecialChars}
                      />
                    ))
                  ) : (
                    <Text>
                      No Actions were found with <Text strong>{query}</Text>
                    </Text>
                  )}
                  {moreQueryLoading && <StyledSpin />}
                </>
              )}
            </TabPane>
            <TabPane
              tab={
                <>
                  <span>Incoming</span>
                  {counter(notesCount)}
                </>
              }
              key="new-information"
            >
              {firstQueryLoading ? (
                <StyledSpin />
              ) : (
                <>
                  {/*{!!notes.length && workspaceSelect}*/}
                  {!!filterdNotes.length ? (
                    filterdNotes.map((note) => (
                      <NoteCard
                        isNested
                        key={note.id}
                        note={note}
                        highlightText={queryWithoutSpecialChars}
                      />
                    ))
                  ) : (
                    <Text>
                      No Incoming were found with <Text strong>{query}</Text>
                    </Text>
                  )}
                  {moreQueryLoading && <StyledSpin />}
                </>
              )}
            </TabPane>
          </Tabs>
        </StyledSearchResultBox>
      )}
    </StyledModalContainer>
  );

  const mobileContent = (
    <>
      <Button
        shape="circle"
        type="text"
        icon={isOpened ? <CloseIcon color="#FFFFFF" /> : <SearchIcon color="#FFFFFF" />}
        onClick={isOpened ? closeSearchBar : openSearchBar}
      />
      <ModalToDrawerTransformer
        isOpened={isOpened}
        onClose={closeSearchBar}
        clickOutside={clickOutside}
      >
        {modalEl}
      </ModalToDrawerTransformer>
    </>
  );

  const desktopContent = isOpened ? (
    modalEl
  ) : (
    <Button
      shape="circle"
      type="text"
      icon={<SearchIcon color="#FFFFFF" />}
      onClick={openSearchBar}
    />
  );

  return <StyledSearchBar>{isDesktop ? desktopContent : mobileContent}</StyledSearchBar>;
});

export { SearchBar };
