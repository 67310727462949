import { gql } from '@apollo/client';

import { OUTCOME_FIELDS } from './fragments';

export const FETCH_OUTCOMES = gql`
  query FetchOutcomes($workspaceId: Int = -1) {
    fetchUserWorkspaceOutcomes(workspaceId: $workspaceId) {
      ...outcomeFields
    }
  }

  ${OUTCOME_FIELDS}
`;

export const FETCH_OUTCOME = gql`
  query FetchOutcome($outcomeId: Int!, $workspaceId: Int!) {
    fetchUserWorkspaceOutcome(outcomeId: $outcomeId, workspaceId: $workspaceId) {
      ...outcomeFields
    }
  }

  ${OUTCOME_FIELDS}
`;
