import { useApolloClient, useMutation, useQuery } from '@apollo/client';

import { FETCH_ACTIONS } from '../../actions/graphql/queries';
import { DUPLICATE_ACTION } from '../../actions/graphql/mutations';
import { FetchUserWorkspaceActions } from '../../actions/types';
import { OperationType, updatedCacheData } from '../../utils';
import { mutateAction } from 'context/userWorkspaceContext';
import { capitalize } from 'lodash-es';
import { ColumnType } from 'apollo/stateFields/dnd/dndFields';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../apollo/stateFields/filterInput/filterInputFields';
import { dndDispatch } from '../../../context/dndContext/dndContext';
import { addEntityId } from '../../../context/dndContext/DndActions';

const useDuplicateAction = () => {
  const apolloClient = useApolloClient();
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;

  const dispatch = dndDispatch;

  return useMutation(DUPLICATE_ACTION, {
    update(cache, { data }) {
      const { fetchUserWorkspaceActions = [] } =
        cache.readQuery<FetchUserWorkspaceActions>({
          query: FETCH_ACTIONS,
          variables: {
            workspaceId: data?.duplicateUserWorkspaceAction.workspaceId,
          },
        }) || {};

      cache.writeQuery<FetchUserWorkspaceActions>({
        query: FETCH_ACTIONS,
        variables: {
          workspaceId: data?.duplicateUserWorkspaceAction.workspaceId,
        },
        data: {
          fetchUserWorkspaceActions: updatedCacheData(
            fetchUserWorkspaceActions,
            data?.duplicateUserWorkspaceAction,
            OperationType.CREATE,
          ),
        },
      });
      if (data?.duplicateUserWorkspaceAction) {
        dispatch(
          addEntityId({
            entityId: data.duplicateUserWorkspaceAction.id,
            columnTitle: capitalize(
              data.duplicateUserWorkspaceAction.status.toLowerCase(),
            ) as ColumnType,
          }),
        );
        mutateAction(apolloClient, data.duplicateUserWorkspaceAction, 'create', filterInput);
      }
    },
  });
};

export default useDuplicateAction;
