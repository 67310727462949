import { useMutation } from '@apollo/client';

import { UPDATE_USER_WORKSPACE } from '../graphql/mutations';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../../common/graphql/queries';
import { FetchUserWorkspacesWithInvitedCount, UpdateUserWorkspace } from '../types';

const useUpdateUserWorkspace = () => {
  return useMutation<UpdateUserWorkspace>(UPDATE_USER_WORKSPACE, {
    update(cache, { data }) {
      const { fetchUserWorkspacesWithInvitedCount = [] } =
        cache.readQuery<FetchUserWorkspacesWithInvitedCount>({
          query: FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
        }) || {};

      cache.writeQuery<FetchUserWorkspacesWithInvitedCount>({
        query: FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
        data: {
          fetchUserWorkspacesWithInvitedCount: data
            ? [
                data.updateUserWorkspace,
                ...fetchUserWorkspacesWithInvitedCount.filter(
                  ({ id }) => id !== data.updateUserWorkspace.id,
                ),
              ]
            : fetchUserWorkspacesWithInvitedCount,
        },
      });
    },
  });
};

export default useUpdateUserWorkspace;
