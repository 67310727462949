import styled, { css } from 'styled-components';
import { SharedPeopleIcon } from '../../../../workspaces/components/ShareWorkspace/SharedPeopleIcon/SharedPeopleIcon';
import { ReactComponent as LockIcon } from '../../../../../core/svg/lock.svg';
import theme from '../../../../../core/styles/styled-components/theme';
import { Dates } from './Dates';
import { EntityCard } from '../../../../common/components';
import { Col } from 'antd';
import PriorityMenu from './PriorityMenu';

export const StyledPriorityMenu = styled(PriorityMenu)`
  position: absolute;
  top: 9px;
  right: 25px;
`;

export const StyledSharedPeopleIcon = styled(SharedPeopleIcon)`
  margin-right: 5px;
  margin-left: 2px;
  min-width: 20px;
`;

export const StyledLockIcon = styled(LockIcon)`
  width: 10px;
  height: 10px;
  margin-right: 4px;
  margin-bottom: -1px;
  //position: absolute;
  //bottom: 5px;
  //right: 1px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0 5px 0;
`;

export const StyledPreposition = styled.span`
  margin-right: 0.278rem;
  color: var(--color-grey);
  font-size: 0.5rem;
`;

export const StyledActionWorkspaceWrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  word-wrap: unset;
  width: 100%;
  min-height: 15px;
`;

export const StyledTagsWrapper = styled.div`
  display: flex;
  width: calc(100% - 1rem);
  overflow: hidden;
`;

export const StyledActionWorkspace = styled.span<{ isShared?: boolean; isFocusTimer?: boolean }>`
  margin-right: 2px;
  padding: 3px 3px;
  background-color: ${(props) =>
    props.isShared ? 'rgba(255, 194, 39, 0.4)' : 'rgba(177, 190, 249, 0.4)'};
  color: ${(props) => (props.isShared ? '#4A3602' : '#102DBC')};
  border-radius: 2px;
  font-size: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ isFocusTimer, isShared }) => {
    if (isFocusTimer) {
      return css`
        color: var(--color-white);
      `;
    } else if (isShared) {
      return css`
        color: #4a3602;
      `;
    } else {
      return css`
        color: #102dbc;
      `;
    }
  }}

  @media ${theme.device.mobile.min} {
    max-width: 140px;
  }

  @media ${theme.device.tablet.min} {
    max-width: 170px;
  }

  @media ${theme.device.desktop.min} {
    max-width: 190px;
  }
`;

export const StyledNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledActionName = styled.span`
  margin-bottom: 0.25rem;
  line-height: 1.286;
  font-size: 0.667rem;
  font-weight: 600;
  max-width: 88%;
  overflow: hidden;
  //overflow-y: visible;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; // add scroll
  -webkit-box-orient: vertical;

  @media ${theme.device.desktop.min} {
    max-width: 300px;
  }
`;

export const StyledActionBody = styled.div`
  line-height: 1;
`;

export const StyledActionOutcomeWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  line-height: 1;
  font-size: 9px;
`;

export const StyledActionOutcome = styled.div<{ isFocusTimer?: boolean }>`
  color: var(--color-dark-blue);
  max-width: 165px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;

  ${({ isFocusTimer }) => {
    if (isFocusTimer) {
      return css`
        color: var(--color-white);
      `;
    }
  }}

  @media ${theme.device.tablet.min} {
    max-width: 220px;
  }
`;

export const StyledDates = styled(Dates)``;

export const StyledEntityCard = styled(({ isTransparent, isShared, disabled, ...rest }) => (
  <EntityCard {...rest} />
))<{
  isTransparent?: boolean | null;
  disabled?: boolean;
  isShared?: boolean;
}>`
  position: relative;
  transition: all ease-in-out 200ms;
  &:before {
    content: '';
    height: calc(100% - 6px);
    width: 1px;
    background-color: ${({ isShared }) => (isShared ? 'var(--color-yellow)' : 'transparent')};

    position: absolute;
    left: -1px;
    top: 3px;
  }

  .options-button {
    top: 6px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      ${StyledActionOutcome},
      ${StyledDates} {
        color: var(--color-grey);
      }
    `}

  @media ${theme.device.desktop.min} {
    ${({ isTransparent }) =>
      isTransparent &&
      css`
        opacity: 0.4;
      `}
  }
`;

export const StyledWorkspaceSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 155px;

  // move to bottom place only
  // @media ${theme.device.mobile.min} {
  //   max-width: {(props) => (props.statusSelectPlace === 'top' ? '130px' : '155px')};
  // }

  @media ${theme.device.desktop.min} {
    max-width: unset;
  }
`;

export const StyledCol = styled(Col)`
  width: 100%;
  // move to bottom place only
  // @media ${theme.device.mobile.min} {
  //   max-width: {(props) => (props.statusSelectPlace === 'top' ? '150px' : '100%;')};
  // }
  //
  // @media ${theme.device.tablet.min} {
  //   max-width: {(props) => (props.statusSelectPlace === 'top' ? '175px' : '100%;')};
  // }
  //
  // @media ${theme.device.desktop.min} {
  //   width: 100%;
  // }
`;

export const StyledHeadLeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledNameRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const StyledIconWrapper = styled.div`
  margin-top: -1px;
`;
