import { Outcome, OutcomeStatus } from 'core/types/outcome';
import { getNowDate, isDateBefore } from './date';
import { Action } from '../types/action';

export const newOutcome = (status: OutcomeStatus) => {
  const nowDate = getNowDate();
  switch (status) {
    case OutcomeStatus.CURRENT:
      return {
        startDate: nowDate,
      };
    case OutcomeStatus.COMPLETED:
      return {
        startDate: nowDate,
        endDate: nowDate,
        completeDate: nowDate,
      };
    default:
      return {};
  }
};

const updateOutcomeOnFutureStatus = (outcome: Outcome, status: OutcomeStatus): Outcome => {
  const updatedOutcome = Object.assign({}, outcome);
  const { startDate, endDate } = updatedOutcome;
  const nowDate = getNowDate();
  switch (status) {
    case OutcomeStatus.CURRENT:
      updatedOutcome.startDate = nowDate;
      updatedOutcome.endDate =
        isDateBefore(updatedOutcome.startDate, endDate) && endDate ? endDate : null;
      updatedOutcome.status = OutcomeStatus.CURRENT;
      break;
    case OutcomeStatus.COMPLETED: {
      updatedOutcome.endDate =
        isDateBefore(startDate, endDate) && endDate ? endDate : nowDate;
      updatedOutcome.startDate =
        isDateBefore(startDate, updatedOutcome.endDate) && startDate ? startDate : nowDate;
      updatedOutcome.completeDate = nowDate;
      updatedOutcome.status = OutcomeStatus.COMPLETED;
      break;
    }
  }
  return updatedOutcome;
};

const updateOutcomeOnCompletedStatus = (outcome: Outcome, status: OutcomeStatus): Outcome => {
  const updatedOutcome = Object.assign({}, outcome);
  const nowDate = getNowDate();
  switch (status) {
    case OutcomeStatus.CURRENT: {
      updatedOutcome.endDate = null;
      updatedOutcome.startDate = getNowDate();
      updatedOutcome.status = OutcomeStatus.CURRENT;
      break;
    }
    case OutcomeStatus.FUTURE: {
      updatedOutcome.endDate = null;
      updatedOutcome.startDate = null;
      updatedOutcome.status = OutcomeStatus.FUTURE;
      break;
    }
    case OutcomeStatus.COMPLETED: {
      updatedOutcome.completeDate = updatedOutcome.endDate ? updatedOutcome.endDate : nowDate;
      break;
    }
  }
  return updatedOutcome;
};

const updateOutcomeOnCurrentStatus = (outcome: Outcome, status: OutcomeStatus): Outcome => {
  const updatedOutcome = Object.assign({}, outcome);
  const { startDate, endDate } = updatedOutcome;
  const nowDate = getNowDate();
  const datesValid = isDateBefore(startDate, endDate);

  switch (status) {
    case OutcomeStatus.COMPLETED: {
      updatedOutcome.endDate =
        isDateBefore(startDate, endDate) && endDate ? endDate : nowDate;
      updatedOutcome.startDate =
        isDateBefore(startDate, updatedOutcome.endDate) && startDate ? startDate : nowDate;
      updatedOutcome.completeDate = nowDate;
      updatedOutcome.status = OutcomeStatus.COMPLETED;
      break;
    }
    case OutcomeStatus.FUTURE: {
      updatedOutcome.startDate =
        datesValid && startDate && isDateBefore(nowDate, startDate) ? startDate : null;
      updatedOutcome.endDate =
        datesValid && endDate && isDateBefore(nowDate, endDate) ? endDate : null;
      updatedOutcome.status = OutcomeStatus.FUTURE;
    }
  }
  return updatedOutcome;
};

const updateFunctionByOutcomeStatus = {
  [OutcomeStatus.FUTURE]: updateOutcomeOnFutureStatus,
  [OutcomeStatus.COMPLETED]: updateOutcomeOnCompletedStatus,
  [OutcomeStatus.CURRENT]: updateOutcomeOnCurrentStatus,
};

export const updateOutcomeByStatus = (
  outcome: Outcome,
  prevStatus: OutcomeStatus,
  newStatus: OutcomeStatus,
) => {
  return updateFunctionByOutcomeStatus[prevStatus](outcome, newStatus);
};

export const updateOutcomeActions = (outcome: Outcome, actionId: number) => {
  return {
    id: outcome.id,
    actionIds: outcome.actions?.map(a => a.id).concat(actionId)
  }
}

export const updateActionOutcome = (action: Action, outcomeId: number) => {
  return {
    id: action.id,
    inputTags: action.tags?.map(t => ({ name : t.name })),
    outcomeId,
  }
}
