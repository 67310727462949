import React from 'react';
import styled, { css } from 'styled-components';
// import backgroundImage1 from '../../img/bg-step1.jpg';
import backgroundImage2 from '../../img/bg-step2.jpg';
import backgroundImage4 from '../../img/bg-step4.jpg';
import backgroundImage5 from '../../img/bg-step5.jpg';
import { ReactComponent as GraphImage } from '../../img/graph.svg';
import { useQuery } from '@apollo/client';
import { TUTORIAL_STATE, TutorialValue } from '../../../apollo/stateFields/tutorial/tutorialField';
import { theme } from '../../../core/styles/styled-components';

const StyledImage = styled.div<{ step: number }>`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:after {
    background-size: cover;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;

    ${({ step }) => {
      switch (step) {
        // case 1:
        //   return css`
        //     background-image: url(${backgroundImage1});
        //   `;
        case 1:
          return css`
            background-image: url(${backgroundImage2});
          `;
        // case 3:
        //   return css`
        //     background-image: url(${backgroundImage3});
        //   `;
        case 3:
          return css`
            background-image: url(${backgroundImage4});
          `;
        case 4:
          return css`
            background-image: url(${backgroundImage5});
          `;
        default:
          return css`
            background-image: url(${backgroundImage2});
          `;
      }
    }}
  }
`;

const StyledGraphWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--color-white);

  @media ${theme.device.largeDesktop.min} {
    display: flex;
    justify-content: center;
  }
`;

const StyledGraphImage = styled(GraphImage)`
  margin-top: 220px;

  @media ${theme.device.largeDesktop.min} {
    margin-top: 160px;
    margin-left: -100px;
  }
`;

const GraphElement = () => {
  return (
    <StyledGraphWrapper>
      <StyledGraphImage />
    </StyledGraphWrapper>
  );
};

const OnboardingImage = () => {
  const { data: tutorialData } = useQuery(TUTORIAL_STATE);
  const { onboardingStep }: TutorialValue = tutorialData?.tutorial;
  return <>{onboardingStep === 2 ? <GraphElement /> : <StyledImage step={onboardingStep} />}</>;
};

export default OnboardingImage;
