import { Workspace } from '../../../../core/types/workspace';
import { Action, ActionStatus } from '../../../../core/types/action';
import {
  DoneColumnFilterTypes,
  ToDoColumnFilterTypes,
} from '../../../../apollo/stateFields/boardFilters/boardFiltersFields';
import {
  differenceInDays,
  hoursToMilliseconds,
  isEqual,
  isWithinInterval,
  milliseconds,
  minutesToMilliseconds,
  startOfDay,
} from 'date-fns';
import { ActionTimeFormat } from './types';

export const sortWorkspaces = (a: Workspace, b: Workspace, defaultWorkSpaceId: number): number => {
  if (a.id === defaultWorkSpaceId) {
    return -1;
  } else if (b.id === defaultWorkSpaceId) {
    return 1;
  } else {
    return a.name.localeCompare(b.name);
  }
};

export const sortActionsByUpdateDate = (a: Action, b: Action): number => {
  if (a.updateDate && b.updateDate) {
    if (a.updateDate > b.updateDate) {
      return -1;
    } else if (a.updateDate < b.updateDate) {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  }
  return a.name.localeCompare(b.name);
};

export const filterActionsByDoneDate = (
  actions: Action[],
  filter: DoneColumnFilterTypes,
  interval?: { start: Date; end: Date },
) => {
  const startOfDayNewDate = startOfDay(new Date());
  return actions
    .filter((action) => action.status === ActionStatus.DONE)
    .filter((action) => {
      if (!action.actualEndDate) {
        return false;
      }
      const startOfDayEndDate = startOfDay(new Date(action.actualEndDate));
      const difference = differenceInDays(startOfDayNewDate, startOfDayEndDate);
      switch (filter) {
        case DoneColumnFilterTypes.ANYTIME:
          return true;
        case DoneColumnFilterTypes.TODAY:
          return isEqual(startOfDay(new Date(action.actualEndDate)), startOfDayNewDate);
        case DoneColumnFilterTypes.YESTERDAY:
          return difference <= 1 && difference >= 0;
        case DoneColumnFilterTypes.LAST_7:
          return difference <= 6 && difference >= 0;
        case DoneColumnFilterTypes.LAST_14:
          return difference <= 13 && difference >= 0;
        case DoneColumnFilterTypes.LAST_30:
          return difference <= 29 && difference >= 0;
        case DoneColumnFilterTypes.CUSTOM:
          return interval
            ? isWithinInterval(startOfDay(new Date(action.actualEndDate)), interval)
            : true;
        default:
          return true;
      }
    });
};

export const filterActionsByToDoDate = (
  actions: Action[],
  filter: ToDoColumnFilterTypes,
  interval?: { start: Date; end: Date },
) => {
  const startOfDayNewDate = startOfDay(new Date());
  return actions
    .filter((action) => action.status === ActionStatus.TODO)
    .filter((action) => {
      const startOfDayStartDate = action?.startDate
        ? startOfDay(new Date(action.startDate))
        : undefined;
      const startOfDayEndDate = action?.endDate ? startOfDay(new Date(action.endDate)) : undefined;
      const startOfDayActualStartDate = action?.actualStartDate
        ? startOfDay(new Date(action.actualStartDate))
        : undefined;
      const startDifference = startOfDayStartDate
        ? differenceInDays(startOfDayStartDate, startOfDayNewDate)
        : undefined;
      const endDifference = startOfDayEndDate
        ? differenceInDays(startOfDayEndDate, startOfDayNewDate)
        : undefined;
      const actualEndDifference = startOfDayActualStartDate
        ? differenceInDays(startOfDayActualStartDate, startOfDayNewDate)
        : undefined;

      switch (filter) {
        case ToDoColumnFilterTypes.ANYTIME:
          return true;
        case ToDoColumnFilterTypes.TODAY:
          return (
            (startDifference !== undefined && startDifference <= 0) ||
            (endDifference !== undefined && endDifference <= 0) ||
            (actualEndDifference !== undefined && actualEndDifference <= 0)
          );
        case ToDoColumnFilterTypes.TOMORROW:
          return (
            (startDifference !== undefined && startDifference <= 1) ||
            (endDifference !== undefined && endDifference <= 1) ||
            (actualEndDifference !== undefined && actualEndDifference <= 1)
          );
        case ToDoColumnFilterTypes.NEXT_7:
          return (
            (startDifference !== undefined && startDifference <= 6 && startDifference >= 0) ||
            (endDifference !== undefined && endDifference <= 6 && endDifference >= 0) ||
            (actualEndDifference !== undefined &&
              actualEndDifference <= 6 &&
              actualEndDifference >= 0)
          );
        case ToDoColumnFilterTypes.NEXT_14:
          return (
            (startDifference !== undefined && startDifference <= 13 && startDifference >= 0) ||
            (endDifference !== undefined && endDifference <= 13 && endDifference >= 0) ||
            (actualEndDifference !== undefined &&
              actualEndDifference <= 13 &&
              actualEndDifference >= 0)
          );
        case ToDoColumnFilterTypes.NEXT_30:
          return (
            (startDifference !== undefined && startDifference <= 29 && startDifference >= 0) ||
            (endDifference !== undefined && endDifference <= 29 && endDifference >= 0) ||
            (actualEndDifference !== undefined &&
              actualEndDifference <= 29 &&
              actualEndDifference >= 0)
          );
        case ToDoColumnFilterTypes.NO_DUE_DATE:
          return !action.startDate && !action.endDate && !action.actualStartDate;
        case ToDoColumnFilterTypes.CUSTOM:
          return interval
            ? (!!action?.startDate &&
                isWithinInterval(startOfDay(new Date(action.startDate)), interval)) ||
                (!!action?.endDate &&
                  isWithinInterval(startOfDay(new Date(action?.endDate)), interval)) ||
                (!!action?.actualStartDate &&
                  isWithinInterval(startOfDay(new Date(action?.actualStartDate)), interval))
            : true;
        default:
          return true;
      }
    });
};

export const filterActionsByStatus = (actions: Action[], status: ActionStatus) => {
  return actions.filter((action) => action.status === status);
};

export const convertToMs = (value: number, format: ActionTimeFormat) => {
  switch (format) {
    case ActionTimeFormat.MINUTES:
      return milliseconds({ minutes: value });
    case ActionTimeFormat.HOURS:
      return milliseconds({ hours: value });
    case ActionTimeFormat.DAYS:
      return milliseconds({ days: value });
  }
};

export const convertFromMs = (value: number, format: ActionTimeFormat) => {
  switch (format) {
    case ActionTimeFormat.MINUTES:
      return Number((value / 60000).toFixed(2));
    case ActionTimeFormat.HOURS:
      return Number((value / 3.6e6).toFixed(2));
    case ActionTimeFormat.DAYS:
      return Number((value / 8.64e7).toFixed(2));
  }
};
