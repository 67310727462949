import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Input, Tooltip } from 'antd';
import { useField } from 'formik';
import { Label } from 'core/components/common';
import { ReactComponent as HelpIcon } from '../../../svg/help.svg';

import { InputTextFieldProps } from './types';
import { theme } from '../../../styles/styled-components';

const StyledContainer = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
`;

const StyledLabelAndInputContainer = styled.div<Pick<InputTextFieldProps, 'labelPosition'>>`
  display: flex;
  flex-direction: ${({ labelPosition }) => (labelPosition === 'above' ? 'column' : 'row')};
`;

const StyledLabel = styled(Label)`
  margin-bottom: 8px;
`;

const StyledInput = styled(({ isError, ...props }) => <Input {...props} />)<{ isError: boolean }>`
  height: 40px;
  width: 100%;
  font-size: 14px;
  margin-bottom: 8px !important;
  border: none;
  border-radius: 4px;

  input {
    font-size: 14px;
    margin: 0;
  }

  .ant-tooltip {
    top: 44px !important;
    left: 0 !important;

    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      width: 330px;
      padding: 16px;
      font-size: 12px;
      line-height: 14px;
      background: #eff0f2;
      box-shadow: 0px 9px 49px rgba(0, 0, 0, 0.07), 0px 5.2992px 25.5584px rgba(0, 0, 0, 0.050624),
        0px 2.7216px 11.9952px rgba(0, 0, 0, 0.039312),
        0px 1.0944px 5.2528px rgba(0, 0, 0, 0.030688), 0px 0.2448px 2.2736px rgba(0, 0, 0, 0.019376);
      border-radius: 3px;
      color: #202635;

      @media ${theme.device.mobile.max} {
        width: auto;
      }
    }
  }

  ${({ isError }) =>
    isError &&
    css`
      border: 1px solid #ff7b76 !important;
    `}
`;

const StyledError = styled.span`
  font-weight: normal;
  font-size: 9px;
  line-height: 11px;
  color: var(--color-main-error-red);
  padding-bottom: 12px;
`;

// TODO: Although `any` is used in the Ant Design documentation, I'd like to find
// a better type for this generic parameter.
const InputTextField = forwardRef<any, InputTextFieldProps>(
  (
    {
      className,
      inputClassName,
      errorClassName,
      name,
      displayError,
      id,
      label,
      labelPosition = 'above',
      labelClassName,
      type,
      errorMessage,
      ...restOfProps
    },
    ref,
  ) => {
    const [field, meta] = useField(name);

    const isError = displayError && ((meta.error && meta.touched) || errorMessage);

    const passwordInformation = type === 'password' && (
      <Tooltip
        placement="bottom"
        title="Password security requirements: minimum of 9 characters long and contain at least one uppercase and one lowercase letter (A, z), one numeric character (0-9), and one special character (such as !, %, @, or #)"
      >
        <HelpIcon />
      </Tooltip>
    );

    return (
      <StyledContainer className={className}>
        <StyledLabelAndInputContainer labelPosition={labelPosition}>
          {label && <StyledLabel className={labelClassName} text={label} htmlFor={id} />}
          <StyledInput
            isError={isError}
            autoComplete="off"
            type={type}
            ref={ref}
            className={inputClassName}
            id={id}
            {...field}
            {...restOfProps}
            suffix={passwordInformation}
          />
        </StyledLabelAndInputContainer>
        {isError && (
          <StyledError className={errorClassName}>{meta.error || errorMessage}</StyledError>
        )}
      </StyledContainer>
    );
  },
);

export { InputTextField };
