export enum OPERATION_NAME {
  UpdateNote = 'UpdateNote',
  ConvertNoteToAction = 'ConvertNoteToAction',
  ConvertNoteToOutcome = 'ConvertNoteToOutcome',
  MoveNote = 'MoveNote',
  FetchUserWorkspaceNote = 'FetchUserWorkspaceNote',
  CreateAction = 'CreateAction',
  UpdateAction = 'UpdateAction',
  MoveAction = 'MoveAction',
  FetchAction = 'FetchAction',
  FetchActions = 'FetchActions',
  UpdateOutcome = 'UpdateOutcome',
  FetchOutcomes = 'FetchOutcomes',
  FetchUserWorkspaceTags = 'FetchUserWorkspaceTags',
  ArchiveOutcome = 'ArchiveOutcome',
  UnarchiveOutcome = 'UnarchiveOutcome',
  MoveOutcome = 'MoveOutcome',
  DuplicateAction = 'DuplicateAction',
  FetchOutcome = 'FetchOutcome',
  FetchWorkspaceInvitesById = 'FetchWorkspaceInvitesById',
  FetchWorkspaceInvitesByIds = 'FetchWorkspaceInvitesByIds',
  RemoveUserFromWorkspace = 'RemoveUserFromWorkspace',
  FetchAndFilterUserWorkspacesWithEntities = 'FetchAndFilterUserWorkspacesWithEntities',
  FilterUserWorkspaces = 'FilterUserWorkspaces',
  FetchUserWorkspacesWithInvitedCount = 'FetchUserWorkspacesWithInvitedCount',
}

export const ENTITY_NAME_BY_OPERATION_NAME = {
  OUTCOME: [
    OPERATION_NAME.UpdateOutcome,
    OPERATION_NAME.ArchiveOutcome,
    OPERATION_NAME.UnarchiveOutcome,
    OPERATION_NAME.MoveOutcome,
    OPERATION_NAME.FetchOutcome,
  ],
  ACTION: [
    OPERATION_NAME.UpdateAction,
    OPERATION_NAME.MoveAction,
    OPERATION_NAME.CreateAction,
    OPERATION_NAME.FetchAction,
    OPERATION_NAME.DuplicateAction,
  ],
  NOTE: [
    OPERATION_NAME.UpdateNote,
    OPERATION_NAME.ConvertNoteToAction,
    OPERATION_NAME.ConvertNoteToOutcome,
    OPERATION_NAME.MoveNote,
    OPERATION_NAME.FetchUserWorkspaceNote,
  ],
  WORKSPACE: [
    OPERATION_NAME.FetchAndFilterUserWorkspacesWithEntities,
    OPERATION_NAME.FilterUserWorkspaces,
    OPERATION_NAME.FetchUserWorkspacesWithInvitedCount,
  ],
};
