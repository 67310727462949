import {
  coursesVar,
  coursesInitialValue,
  ActiveVideoInfo,
  CoursesTitle,
  CourseOperation,
} from './coursesFields';
import { SectionName } from '../../../graphql/types';

const setShowCoursesPopup = (payload: boolean) => {
  const value = {
    ...coursesVar(),
    showCoursesPopup: payload,
  };
  coursesVar(value);
};

const setShowVideoPlayer = (payload: ActiveVideoInfo | null) => {
  const value = {
    ...coursesVar(),
    activeVideo: payload,
  };
  coursesVar(value);
};

const setCanceledNotificationVideo = (payload: ActiveVideoInfo | null) => {
  const value = {
    ...coursesVar(),
    canceledNotificationVideo: payload,
  };
  coursesVar(value);
};

const setTimezone = (payload: string) => {
  const value = {
    ...coursesVar(),
    timezone: payload,
  };
  coursesVar(value);
};

const setTutorialStep = (payload: number) => {
  const value = {
    ...coursesVar(),
    tutorialStep: payload,
  };
  coursesVar(value);
};

const setFirstCourseTitle = (payload: CoursesTitle) => {
  const value = {
    ...coursesVar(),
    firstCourseTitle: payload,
  };
  coursesVar(value);
};

const setSecondCourseTitle = (payload: CoursesTitle) => {
  const value = {
    ...coursesVar(),
    secondCourseTitle: payload,
  };
  coursesVar(value);
};

const setExpandCard = (payload: { title: CoursesTitle; operation: CourseOperation }) => {
  let value;

  if (payload.operation === 'add') {
    value = {
      ...coursesVar(),
      expandCards: [...coursesVar().expandCards, payload.title],
    };
  } else {
    value = {
      ...coursesVar(),
      expandCards: [...coursesVar().expandCards.filter((cardTitle) => cardTitle !== payload.title)],
    };
  }
  coursesVar(value);
};

const setExpandSection = (payload: { titles: SectionName[] | SectionName }) => {
  let value;

  if (Array.isArray(payload.titles)) {
    value = {
      ...coursesVar(),
      expandSections: [...payload.titles],
    };
  } else {
    value = {
      ...coursesVar(),
      expandSections: [payload.titles],
    };
  }
  coursesVar(value);
};

const setTipsSection = (payload: null | SectionName) => {
  const value = {
    ...coursesVar(),
    tipsSection: payload,
  };
  coursesVar(value);
};

const resetCourses = () => {
  const value = {
    ...coursesInitialValue,
  };
  coursesVar(value);
};

export {
  setFirstCourseTitle,
  setSecondCourseTitle,
  setShowCoursesPopup,
  setShowVideoPlayer,
  setCanceledNotificationVideo,
  setTimezone,
  setTipsSection,
  setExpandCard,
  setExpandSection,
  setTutorialStep,
  resetCourses,
};
