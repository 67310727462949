import React from 'react';
import {
  ADMIN_PANEL_PATH,
  USER_PLANS_PATH,
  USER_PROFILE_PATH,
} from '../../../../constants/routePaths';
import { ReactComponent as UserIcon } from '../../../../svg/common/user.svg';
import { ReactComponent as AdminIcon } from '../../../../svg/common/learning.svg';
import { ReactComponent as AvailablePlansIcon } from '../../../../svg/common/available-plans.svg';
import { ReactComponent as LogoutIcon } from '../../../../svg/common/logout.svg';
import { UserMenuItem } from './types';

export const getUserMenuItems = (isAdmin: boolean): UserMenuItem[] => {
  return [
    {
      title: 'User Profile',
      path: USER_PROFILE_PATH,
      icon: <UserIcon />,
    },
    {
      title: 'Available plans',
      path: USER_PLANS_PATH,
      icon: <AvailablePlansIcon />,
    },
    ...(isAdmin
      ? [
          {
            title: 'Admin panel',
            path: ADMIN_PANEL_PATH,
            icon: <AdminIcon />,
          },
        ]
      : []),
    {
      title: 'Divider',
    },
    {
      title: 'Log out',
      icon: <LogoutIcon />,
    },
  ];
};
