import React from 'react';
import { Permission } from '../../../../core/types/workspace';
import { getRoleText } from '../ShareWorkspace/utils';
import { ReactComponent as StarIcon } from '../../../../core/svg/star.svg';
import styled, { css } from 'styled-components';

export const StyledLabel = styled.div<{ isDefault?: boolean; isShared?: boolean; isNew?: boolean }>`
  display: flex;
  align-items: center;
  height: 16px;
  padding: 2px 3px;
  margin-right: 4px;
  font-weight: 500;
  font-size: 9px;
  line-height: 10px;
  border-radius: 2px;
  color: rgba(16, 45, 188, 1);
  border: 1px solid rgba(177, 190, 249, 0.4);
  background-color: transparent;

  svg {
    width: 8px;
    height: 8px;
    margin-right: 4px;
  }

  ${({ isDefault }) =>
    isDefault &&
    css`
      background-color: var(--color-purple);
      color: var(--color-blue-2);
      border-color: rgba(177, 190, 249, 0.4);
    `}

  ${({ isShared }) =>
    isShared &&
    css`
      background-color: transparent;
      color: rgba(74, 54, 2, 1);
      border-color: rgba(255, 194, 39, 0.4);
    `}
  
    ${({ isNew }) =>
    isNew &&
    css`
      background: rgba(198, 248, 188, 0.5);
      color: #206200;
      border-color: rgba(177, 190, 249, 0.4);
    `}
`;

const WorkspaceLabel = ({
  permission,
  isShared,
  isDefault,
  isNew,
  isMobile
}: {
  permission?: Permission;
  isShared?: boolean;
  isDefault?: boolean;
  isNew?: boolean;
  isMobile?: boolean;
}) => {
  return isNew ? (
    <StyledLabel isNew>
      <span>NEW</span>
    </StyledLabel>
  ) : isDefault ? (
    <StyledLabel isDefault>
      <StarIcon />
      <span>Default</span>
    </StyledLabel>
  ) : permission ? (
    <StyledLabel isShared={isShared}>
      <span>{getRoleText(permission, isMobile)}</span>
    </StyledLabel>
  ) : null;
};

export default WorkspaceLabel;
