import { gql } from '@apollo/client';

import { ACTION_FIELDS, FOCUS_TIMER_FIELDS, NOTE_FIELDS } from './fragments';
import { OUTCOME_FIELDS } from '../../outcomes/graphql/fragments';

//Actions
export const CREATE_ACTION = gql`
  mutation CreateAction($actionValues: NewActionInput!, $workspaceId: Int = -1) {
    createUserWorkspaceAction(action: $actionValues, workspaceId: $workspaceId) {
      ...actionFields
    }
  }
  ${ACTION_FIELDS}
`;

export const UPDATE_ACTION = gql`
  mutation UpdateAction(
    $actionValues: ActionInput!
    $workspaceId: Int = -1
    $targetWorkspaceId: Int
  ) {
    updateUserWorkspaceAction(
      action: $actionValues
      workspaceId: $workspaceId
      targetWorkspaceId: $targetWorkspaceId
    ) {
      ...actionFields
    }
  }
  ${ACTION_FIELDS}
`;

export const MOVE_ACTION = gql`
  mutation MoveAction(
    $actionValues: ActionInput!
    $workspaceId: Int = -1
    $targetWorkspaceId: Int
    $outcomeId: Int
    $targetOutcomeId: Int
  ) {
    moveUserWorkspaceAction(
      action: $actionValues
      workspaceId: $workspaceId
      targetWorkspaceId: $targetWorkspaceId
      outcomeId: $outcomeId
      targetOutcomeId: $targetOutcomeId
    ) {
      action {
        ...actionFields
      }
      historicOutcome {
        isAllActionsDone
        ...outcomeFields
      }
    }
  }
  ${ACTION_FIELDS}
  ${OUTCOME_FIELDS}
`;

export const DELETE_ACTION = gql`
  mutation DeleteAction($actionValues: ActionInput!, $workspaceId: Int = -1) {
    deleteUserWorkspaceAction(action: $actionValues, workspaceId: $workspaceId)
  }
`;

export const DUPLICATE_ACTION = gql`
  mutation DuplicateAction($actionId: Int!, $workspaceId: Int = -1) {
    duplicateUserWorkspaceAction(actionId: $actionId, workspaceId: $workspaceId) {
      ...actionFields
    }
  }
  ${ACTION_FIELDS}
`;

//Notes
export const CREATE_NOTE = gql`
  mutation CreateNote($note: NewNoteInput!, $workspaceId: Int = -1) {
    createUserWorkspaceNote(note: $note, workspaceId: $workspaceId) {
      ...noteFields
    }
  }
  ${NOTE_FIELDS}
`;

export const UPDATE_NOTE = gql`
  mutation UpdateNote($note: NoteInput!, $workspaceId: Int = -1, $targetWorkspaceId: Int) {
    updateUserWorkspaceNote(
      note: $note
      workspaceId: $workspaceId
      targetWorkspaceId: $targetWorkspaceId
    ) {
      ...noteFields
    }
  }
  ${NOTE_FIELDS}
`;

export const MOVE_NOTE = gql`
  mutation MoveNote($note: NoteInput!, $workspaceId: Int = -1, $targetWorkspaceId: Int = -1) {
    moveUserWorkspaceNote(
      note: $note
      workspaceId: $workspaceId
      targetWorkspaceId: $targetWorkspaceId
    ) {
      ...noteFields
    }
  }
  ${NOTE_FIELDS}
`;

export const CONVERT_NOTE_TO_OUTCOME = gql`
  mutation ConvertNoteToOutcome($noteId: Int!, $workspaceId: Int = -1, $outcome: NewOutcomeInput!) {
    convertNoteToUserWorkspaceOutcome(
      noteId: $noteId
      workspaceId: $workspaceId
      outcome: $outcome
    ) {
      ...outcomeFields
    }
  }
  ${OUTCOME_FIELDS}
`;

export const CONVERT_NOTE_TO_ACTION = gql`
  mutation ConvertNoteToAction($noteId: Int!, $workspaceId: Int = -1, $action: NewActionInput!) {
    convertNoteToUserWorkspaceAction(noteId: $noteId, workspaceId: $workspaceId, action: $action) {
      ...actionFields
    }
  }
  ${ACTION_FIELDS}
`;

export const DELETE_NOTE = gql`
  mutation DeleteNote($note: NoteInput!, $workspaceId: Int = -1) {
    deleteUserWorkspaceNote(note: $note, workspaceId: $workspaceId)
  }
`;

export const START_FOCUS_TIMER = gql`
  mutation CreateFocusTimer($actionId: Int!) {
    createFocusTimer(actionId: $actionId) {
      ...focusTimerFields
    }
  }
  ${FOCUS_TIMER_FIELDS}
`;

export const CANCEL_FOCUS_TIMER = gql`
  mutation CancelFocusTimer {
    cancelFocusTimer
  }
`;

export const PAUSE_FOCUS_TIMER = gql`
  mutation PauseFocusTimer($spendTime: Int!) {
    pauseFocusTimer(spendTime: $spendTime) {
      ...focusTimerFields
    }
  }
  ${FOCUS_TIMER_FIELDS}
`;

export const RESUME_FOCUS_TIMER = gql`
  mutation ResumeFocusTimer {
    resumeFocusTimer {
      ...focusTimerFields
    }
  }
  ${FOCUS_TIMER_FIELDS}
`;

export const FINISH_FOCUS_TIMER = gql`
  mutation FinishFocusTimer($spendTime: Int!) {
    finishFocusTimer(spendTime: $spendTime)
  }
`;
