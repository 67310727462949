import React from 'react';
import styled from 'styled-components';
import { Dropdown, Menu, Button } from 'antd';
import { useAuth } from 'context/authContext';
import { UserMenuItem, UserMenuProps } from './types';
import { useQuery } from '@apollo/client';
import { AUTH_STATE, AuthValue } from '../../../../../apollo/stateFields/auth/authFields';
import { useHistory, useLocation } from 'react-router-dom';
import UserAvatar from '../../../common/UserAvatar/UserAvatar';
import { getUserMenuItems } from './getUserMenuItems';

const StyledMenu = styled(Menu)`
  min-width: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-main-grey-2);
`;

const StyledDivider = styled.div`
  width: 90%;
  height: 1px;
  margin: 8px 0;

  background-color: var(--color-dark-blue);
  opacity: 0.1;
`;

const StyledMenuItem = styled(Menu.Item)<{ isActive: boolean }>`
  width: 100%;
  transition: all ease-in-out 200ms;
  background-color: ${(props) => (props.isActive ? 'var(--color-grey-2)' : 'transparent')};

  &:hover {
    background-color: var(--color-grey);
  }

  & span {
    display: flex;
    align-items: center;
  }
`;

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  color: var(--color-dark-blue);
`;

const StyledMobileUserWrapper = styled.div`
  width: 100%;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledUserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
  color: var(--color-dark-blue);
  max-width: 200px;
`;

const StyledUserName = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 6px;

  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledUserEmail = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledButtonBack = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  height: 32px;
  width: 78px;
  background-color: transparent;
  border-radius: 36px;
  color: var(--color-dark-blue);
  font-weight: 400;
`;

const StyledCustomMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledCustomMenuItem = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 7px 24px;
  cursor: pointer;
  transition: all ease-in-out 200ms;
  background-color: ${(props) => (props.isActive ? 'var(--color-grey-2)' : 'transparent')};

  &:hover {
    background-color: var(--color-grey);
  }
`;

const StyledLinkWrapper = styled.div`
  width: 100%;
`;

const UserMenu = ({ className, showDropdown = true }: UserMenuProps) => {
  const { signOut } = useAuth();
  const { data } = useQuery(AUTH_STATE);
  const { authUser, isAdmin }: AuthValue = data?.auth;
  const history = useHistory();
  const location = useLocation();

  if (!authUser) {
    return null;
  }

  const handleLogout = async () => {
    signOut && (await signOut());
  };

  const logoutMobileEl = (
    <StyledMobileUserWrapper>
      <StyledUserInfoWrapper>
        <UserAvatar size={40} withDropdown={showDropdown.toString()} />
        <StyledUserInfo>
          <StyledUserName>{authUser?.displayName ? authUser?.displayName : ' '}</StyledUserName>
          <StyledUserEmail>
            {authUser?.email?.includes('@') && !authUser?.email?.includes('appleid')
              ? authUser?.email
              : ''}
          </StyledUserEmail>
        </StyledUserInfo>
      </StyledUserInfoWrapper>
      <StyledButtonBack onClick={handleLogout}>Log Out</StyledButtonBack>
    </StyledMobileUserWrapper>
  );

  const renderMenuItem = (value: UserMenuItem, index: number) => {
    if (!showDropdown && !value?.path) {
      return null;
    }
    if (showDropdown && !value?.path && value.title === 'Divider') {
      return <StyledDivider key={value.title + index} />;
    }
    const isActive = location.pathname === value.path;
    const handleClick = () => {
      if (value.path) {
        history.push(value.path);
      } else {
        handleLogout();
      }
    };
    const itemContent = (
      <>
        <StyledIconWrapper>{value.icon}</StyledIconWrapper>
        {value.title}
      </>
    );
    if (showDropdown) {
      return (
        <StyledMenuItem isActive={isActive} key={value.title} onClick={handleClick}>
          {itemContent}
        </StyledMenuItem>
      );
    } else {
      return (
        <StyledCustomMenuItem isActive={isActive} key={value.title} onClick={handleClick}>
          {itemContent}
        </StyledCustomMenuItem>
      );
    }
  };

  return (
    <div className={className}>
      {showDropdown ? (
        <Dropdown
          arrow
          placement="bottomRight"
          trigger={['click']}
          overlay={<StyledMenu>{getUserMenuItems(isAdmin).map(renderMenuItem)}</StyledMenu>}
        >
          <UserAvatar withDropdown={showDropdown.toString()} />
        </Dropdown>
      ) : (
        <StyledLinkWrapper>
          {logoutMobileEl}
          <StyledCustomMenu>{getUserMenuItems(isAdmin).map(renderMenuItem)}</StyledCustomMenu>
        </StyledLinkWrapper>
      )}
    </div>
  );
};

export { UserMenu };
