export const checkWebView = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const isWebview = params.get('webview');

  if (isWebview) {
    localStorage.setItem('webview', 'true');
  }
};

export const isWebView = () => {
  return !!localStorage.getItem('webview');
};