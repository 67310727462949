import { gql } from '@apollo/client';
import { ACTION_FIELDS, NOTE_FIELDS } from '../../../../../../features/actions/graphql/fragments';
import { OUTCOME_FIELDS } from '../../../../../../features/outcomes/graphql/fragments';

export const SEARCH_ENTITIES = gql`
  query searcEntities($query: String = "", $offset: Int, $limit: Int) {
    searchUserWorkspacesEntities(query: $query, offset: $offset, limit: $limit) {
      note {
        data { ...noteFields }
        count
      }
      action {
        data { ...actionFields }
        count
      }
      outcome {
        data { ...outcomeFields }
        count
      }
    }
  }

  ${ACTION_FIELDS}
  ${NOTE_FIELDS}
  ${OUTCOME_FIELDS}
`;
