import { Action } from '../../core/types/action';
import { Outcome } from '../../core/types/outcome';
import { Filter } from './index';

export const checkWithTagsIsShownEntity = (
  entity: Action | Outcome,
  filterInput: Filter[],
): boolean => {
  return filterInput.some((filter) => {
    if (filter.workspaceId === entity.workspaceId) {
      if (filter.tagIds.length && entity.__typename === 'Action') {
        if (entity.tags?.length) {
          return filter.tagIds.some((filterTagId) => {
            return entity.tags!.some((entityTag) => entityTag.id === filterTagId);
          });
        } else {
          return false;
        }
      } else {
        return filter.workspaceId === entity.workspaceId;
      }
    }
  });
};

export const checkIsShownEntity = (entity: Action | Outcome, filterInput: Filter[]): boolean => {
  return filterInput.some((filter) => filter.workspaceId === entity.workspaceId);
};
