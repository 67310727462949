import { getNowDate } from './date';
import { ActionStatus } from '../types/action';

export const newAction = (status: ActionStatus) => {
  const nowDate = getNowDate();

  switch (status) {
    case ActionStatus.DOING:
      return {
        actualStartDate: nowDate,
      };
    case ActionStatus.DONE:
      return {
        actualStartDate: nowDate,
        actualEndDate: nowDate,
      };
    default:
      return {};
  }
};

export function getActualActionDates(
  prevStatus: string,
  newStatus: string,
  isSetToToday: boolean,
  actualStartDate?: string | null
) {
  const nowDate = getNowDate();

  const datesMap: { [key: string]: any } = {
    [ActionStatus.BACKLOG]: {
      [ActionStatus.TODO]: [],
      [ActionStatus.DOING]: [nowDate],
      [ActionStatus.DONE]: [nowDate, nowDate],
    },
    [ActionStatus.TODO]: {
      [ActionStatus.BACKLOG]: [null],
      [ActionStatus.DOING]: [actualStartDate ? actualStartDate : nowDate],
      [ActionStatus.DONE]: [actualStartDate ? actualStartDate : nowDate, nowDate],
    },
    [ActionStatus.DOING]: {
      [ActionStatus.BACKLOG]: [null],
      [ActionStatus.TODO]: [actualStartDate ? actualStartDate : null],
      [ActionStatus.DONE]: [actualStartDate, nowDate],
    },
    [ActionStatus.DONE]: {
      [ActionStatus.BACKLOG]: [null, null],
      [ActionStatus.TODO]: [actualStartDate ? actualStartDate : null, null],
      [ActionStatus.DOING]: [isSetToToday ? nowDate : actualStartDate ? actualStartDate : undefined, null],
    },
  };

  return datesMap[prevStatus][newStatus];
}
