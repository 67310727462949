import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { MOVE_ACTION } from '../../actions/graphql/mutations';
import { FetchUserWorkspaceActions, MoveAction } from '../../actions/types';
import { FETCH_ACTIONS } from '../../actions/graphql/queries';
import {
  checkIsShownEntity,
  OperationType,
  updatedCacheData,
  updateWorkspaceTagsData,
} from '../../utils';
import { mutateAction } from '../../../context/userWorkspaceContext';
import { Action } from '../../../core/types/action';
import { checkActionForComplete, checkOutcomeForComplete } from '../utils';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../apollo/stateFields/filterInput/filterInputFields';
import { filterMutation } from '../../../apollo/stateFields/filterInput';
import { completeMutation } from '../../../apollo/stateFields/complete';

const useMoveAction = (actionToMove: Action | null) => {
  const apolloClient = useApolloClient();
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const { setOutcomeToComplete, setOutcomeToIncomplete } = completeMutation;
  const { setUpdateFilterInputId } = filterMutation;

  return useMutation<MoveAction>(MOVE_ACTION, {
    update(cache, { data }) {
      if (actionToMove === null) {
        return;
      }

      const { fetchUserWorkspaceActions: oldWorkspaceActions = [] } =
        cache.readQuery<FetchUserWorkspaceActions>({
          query: FETCH_ACTIONS,
          variables: {
            workspaceId: actionToMove.workspaceId,
          },
        }) || {};

      const { fetchUserWorkspaceActions: newWorkspaceActions = [] } =
        cache.readQuery<FetchUserWorkspaceActions>({
          query: FETCH_ACTIONS,
          variables: {
            workspaceId: data?.moveUserWorkspaceAction.action.workspaceId,
          },
        }) || {};

      cache.writeQuery<FetchUserWorkspaceActions>({
        query: FETCH_ACTIONS,
        variables: {
          workspaceId: actionToMove.workspaceId,
        },
        data: {
          fetchUserWorkspaceActions: updatedCacheData(
            oldWorkspaceActions,
            data?.moveUserWorkspaceAction.action,
            OperationType.DELETE,
          ),
        },
      });

      cache.writeQuery<FetchUserWorkspaceActions>({
        query: FETCH_ACTIONS,
        variables: {
          workspaceId: data?.moveUserWorkspaceAction.action.workspaceId,
        },
        data: {
          fetchUserWorkspaceActions: updatedCacheData(
            newWorkspaceActions,
            data?.moveUserWorkspaceAction.action,
            OperationType.CREATE,
          ),
        },
      });

      if (data?.moveUserWorkspaceAction.action) {
        mutateAction(
          apolloClient,
          data.moveUserWorkspaceAction.action,
          'update',
          filterInput,
          actionToMove,
        );
        updateWorkspaceTagsData(apolloClient, data?.moveUserWorkspaceAction.action);

        if (!checkIsShownEntity(data.moveUserWorkspaceAction.action, filterInput)) {
          setUpdateFilterInputId({
            type: 'Action',
            workspaceId: data.moveUserWorkspaceAction.action.workspaceId,
            id: data.moveUserWorkspaceAction.action.id,
          });
        }
        if (data?.moveUserWorkspaceAction?.action) {
          checkActionForComplete(
            setOutcomeToComplete,
            setOutcomeToIncomplete,
            data.moveUserWorkspaceAction.action,
          );
        }
        if (
          data?.moveUserWorkspaceAction?.historicOutcome &&
          data?.moveUserWorkspaceAction?.historicOutcome?.isAllActionsDone !== null
        ) {
          checkOutcomeForComplete(
            setOutcomeToComplete,
            setOutcomeToIncomplete,
            data.moveUserWorkspaceAction.historicOutcome,
          );
        }
      }
    },
  });
};

export default useMoveAction;
