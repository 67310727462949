import { filterInputInitialValue, filterInputVar } from './filterInputFields';
import { EntityType, userSettingsVar } from '../userSettings/userSettingsField';
import { Filter } from '../../../features/utils';
import { stripeVar } from '../stripe/stripeFields';

type SetTagsPayload = {
  workspaceId: number;
  tagIds: number[];
};

type DeselectTagPayload = {
  workspaceId: number;
  tagId: number;
};

const resetFilterInput = () => {
  const value = {
    ...filterInputInitialValue,
  };
  filterInputVar(value);
};

const setFilterInput = (payload: Filter[]) => {
  let value;
  let updatedPayload = payload.filter((w) => w.workspaceId !== -1);

  if (!updatedPayload.length) {
    if (userSettingsVar().defaultWorkspaceId !== -1) {
      updatedPayload = [{ workspaceId: userSettingsVar().defaultWorkspaceId, tagIds: [] }];
    } else if (userSettingsVar().startDefaultWorkspaceId) {
      updatedPayload = [{ workspaceId: userSettingsVar().startDefaultWorkspaceId, tagIds: [] }];
    } else {
      return;
    }
  }

  if (stripeVar().isSubscriptionExpired) {
    if (payload.length >= 2) {
      value = {
        ...filterInputVar(),
        filterInput:
          [{ workspaceId: userSettingsVar().defaultWorkspaceId, tagIds: [] }] || [
            { workspaceId: userSettingsVar().startDefaultWorkspaceId, tagIds: [] },
          ] ||
          [],
      };
    } else {
      value = {
        ...filterInputVar(),
        filterInput: payload || [],
      };
    }
  } else {
    value = {
      ...filterInputVar(),
      filterInput: payload || [],
    };
  }

  filterInputVar(value);
};

const addFilterInputWorkspace = (payload: number) => {
  const isSelectedWorkspace = filterInputVar().filterInput.some(
    (filter: Filter) => filter.workspaceId === payload,
  );
  let value;

  if (stripeVar().isSubscriptionExpired) {
    value = {
      ...filterInputVar(),
      filterInput: [{ workspaceId: payload, tagIds: [] }],
    };
  } else {
    value = {
      ...filterInputVar(),
      filterInput: isSelectedWorkspace
        ? filterInputVar().filterInput
        : [...filterInputVar().filterInput, { workspaceId: payload, tagIds: [] }],
    };
  }
  filterInputVar(value);
};

const removeFilterInputWorkspace = (payload: number) => {
  const value = {
    ...filterInputVar(),
    filterInput: filterInputVar().filterInput.filter(
      (filter: Filter) => filter.workspaceId !== payload,
    ),
  };

  filterInputVar(value);
};

const toggleFilterInputWorkspace = (payload: number) => {
  const isSelectedWorkspace = filterInputVar().filterInput.some(
    (filter: Filter) => filter.workspaceId === payload,
  );
  let value;
  if (stripeVar().isSubscriptionExpired) {
    value = {
      ...filterInputVar(),
      filterInput: isSelectedWorkspace
        ? filterInputVar().filterInput.filter((filter: Filter) => filter.workspaceId !== payload)
        : [{ workspaceId: payload, tagIds: [] }],
    };
  } else {
    value = {
      ...filterInputVar(),
      filterInput: isSelectedWorkspace
        ? filterInputVar().filterInput.filter((filter: Filter) => filter.workspaceId !== payload)
        : [...filterInputVar().filterInput, { workspaceId: payload, tagIds: [] }],
    };
  }

  filterInputVar(value);
};

const addFilterInputWorkspaces = (payload: number[]) => {
  let value;
  if (stripeVar().isSubscriptionExpired) {
    value = {
      ...filterInputVar(),
      filterInput: [
        {
          workspaceId: payload[payload.length - 1],
          tagIds: [],
        },
      ],
    };
  } else {
    value = {
      ...filterInputVar(),
      filterInput: [
        ...filterInputVar().filterInput,
        ...payload.map((id) => {
          return {
            workspaceId: id,
            tagIds: [],
          };
        }),
      ],
    };
  }

  filterInputVar(value);
};

const setFilterInputWorkspaceTags = (payload: SetTagsPayload) => {
  const tagIds = payload.tagIds;

  const value = {
    ...filterInputVar(),
    filterInput: filterInputVar().filterInput.map((filter) => {
      return payload.workspaceId === filter.workspaceId ? { ...filter, tagIds } : filter;
    }),
  };

  filterInputVar(value);
};

const deselectTag = (payload: DeselectTagPayload) => {
  const value = {
    ...filterInputVar(),
    filterInput: filterInputVar().filterInput.map((filter: Filter) => {
      return filter.workspaceId === payload.workspaceId
        ? { ...filter, tagIds: filter.tagIds.filter((id: number) => id !== payload.tagId) }
        : filter;
    }),
  };

  filterInputVar(value);
};

const setUpdateFilterInputId = (
  payload: null | {
    type: EntityType;
    workspaceId: number;
    id: number;
  },
) => {
  let value = {
    ...filterInputVar(),
    updateFilterInputId: filterInputVar().updateFilterInputId,
  };
  if (payload === null) {
    value.updateFilterInputId = payload;
  } else {
    if (filterInputVar().updateFilterInputId?.length) {
      const oldUpdateFilterInputId = filterInputVar().updateFilterInputId!;
      let newUpdateFilterInputId;
      if (payload.type === 'Outcome') {
        if (oldUpdateFilterInputId.some((oldInput) => oldInput.type === 'Outcome')) {
          newUpdateFilterInputId = [
            ...oldUpdateFilterInputId?.filter((oldInput) => oldInput.type !== 'Outcome'),
            payload,
          ];
        } else {
          newUpdateFilterInputId = [...oldUpdateFilterInputId, payload];
        }
      } else {
        newUpdateFilterInputId = [
          ...oldUpdateFilterInputId.filter((oldInput) => oldInput.id !== payload.id),
          payload,
        ];
      }
      value.updateFilterInputId = newUpdateFilterInputId;
    } else {
      value.updateFilterInputId = [payload];
    }
  }
  value.updateFilterInputId = value.updateFilterInputId
    ? value.updateFilterInputId?.filter(
        (updateFilter) =>
          !filterInputVar().filterInput.some(
            (filter) => filter.workspaceId === updateFilter.workspaceId,
          ),
      )
    : value.updateFilterInputId;
  filterInputVar(value);
};

export {
  addFilterInputWorkspace,
  removeFilterInputWorkspace,
  toggleFilterInputWorkspace,
  setFilterInputWorkspaceTags,
  deselectTag,
  resetFilterInput,
  setUpdateFilterInputId,
  addFilterInputWorkspaces,
  setFilterInput,
};
