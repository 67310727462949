import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Radio, Space } from 'antd';

import { Button } from 'core/components/common';
import { ArchiveOptions, OutcomeArchiveModalProps } from './types';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { useMediaQuery } from 'react-responsive';
import theme from '../../../../../core/styles/styled-components/theme';
import { useQuery } from '@apollo/client';
import {
  SEARCH_BAR_STATE,
  SearchBarValue,
} from '../../../../../apollo/stateFields/searchBar/searchBarFileds';

const StyledModal = styled(Modal)``;

const StyledModalInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledMessage = styled.span`
  margin-bottom: 1.777rem;
  line-height: 1.2;
  font-size: 1.112rem;
  font-weight: 600;
`;

const StyledRadioGroup = styled.div`
  margin-bottom: 1.777rem;
`;

const StyledRadio = styled(Radio)`
  width: 236px;
  line-height: 1.142;
`;

const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
`;

const StyledButtonWrapper = styled.div`
  margin-right: 40px;
  text-align: center;
`;

const StyledButton = styled(Button)`
  height: 1.778rem;
  margin-bottom: 5px;
`;

const StyledCancelButton = styled(StyledButton)`
  border: 1px solid var(--color-dark-blue);
  font-weight: 600;
`;

const StyledSubmitButton = styled(StyledButton)`
  white-space: nowrap;
`;

const StyledNote = styled.div`
  text-align: center;
  font-size: 14px;
`;

const OutcomeArchiveModal = ({
  count,
  onCancel,
  onConfirm,
  ...modalProps
}: OutcomeArchiveModalProps) => {
  const { data: searchBarData } = useQuery(SEARCH_BAR_STATE);
  const { isOpened: isOpenedSearchBar }: SearchBarValue = searchBarData?.searchBar;
  const [option, setOption] = useState<ArchiveOptions>(ArchiveOptions.WITH_ACTIONS);
  const isMobile = useMediaQuery({ query: theme.device.mobile.max });

  const onChangeOption = (e: RadioChangeEvent) => {
    setOption(e.target.value);
  };

  const handleConfirm = () => {
    onConfirm(option);
  };

  const multipleEnd = count > 1 ? 's' : '';

  return (
    <StyledModal
      centered
      getContainer={() => document.documentElement}
      closable={false}
      onCancel={onCancel}
      footer={null}
      width={isMobile ? '312px' : '480px'}
      bodyStyle={{
        padding: '1.777rem 2.222rem 1.777rem 2.666rem',
        backgroundColor: 'var(--color-main-grey-2)',
      }}
      mask={!isOpenedSearchBar}
      {...modalProps}
    >
      <StyledModalInnerContainer>
        <StyledMessage>
          You are about to archive an Outcome with {count} corresponding Action{multipleEnd}
        </StyledMessage>
        <StyledRadioGroup>
          <Radio.Group onChange={onChangeOption} value={option}>
            <Space direction="vertical" size="middle">
              <StyledRadio value={ArchiveOptions.WITH_ACTIONS}>
                Archive this Outcome and its Corresponding Action{multipleEnd}
              </StyledRadio>
              <StyledRadio value={ArchiveOptions.KEEP_ACTIONS}>
                Archive Outcome but keep its corresponding Action{multipleEnd}
              </StyledRadio>
            </Space>
          </Radio.Group>
        </StyledRadioGroup>
        <StyledButtonContainer>
          <StyledButtonWrapper>
            <StyledSubmitButton onClick={handleConfirm}>Archive Outcome</StyledSubmitButton>
            <StyledNote>
              {option === ArchiveOptions.WITH_ACTIONS
                ? `and archive Action${multipleEnd}`
                : `but keep Action${multipleEnd}`}
            </StyledNote>
          </StyledButtonWrapper>
          <StyledButtonWrapper>
            <StyledCancelButton isSecondary onClick={onCancel}>
              Cancel
            </StyledCancelButton>
          </StyledButtonWrapper>
        </StyledButtonContainer>
      </StyledModalInnerContainer>
    </StyledModal>
  );
};

export { OutcomeArchiveModal };
