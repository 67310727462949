import * as Sentry from '@sentry/react';

export function sendEvent(name: string, spanDescription: string, spanData: any = {}) {
  const transaction = Sentry.startTransaction({ name });
  const span = transaction.startChild({
    op: 'task',
    description: spanDescription,
    data: {
      ...spanData,
      Timestamp: Date.now()
    }
  });

  span.finish();
  transaction.finish();
}
