import { useEffect } from 'react';

const useOutsideClick = (
  ref?: React.MutableRefObject<HTMLElement | null>,
  depthValue?: string,
  callback?: () => void,
  ...allowedElements: React.MutableRefObject<HTMLElement | null>[]
) => {
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const { target } = event;

      if (target instanceof HTMLElement && callback && ref && ref.current) {
        const insideMainElement = ref.current.contains(target);
        const insideAllowedElements = allowedElements.some(({ current }) =>
          current?.contains(target),
        );

        if (!insideMainElement && !insideAllowedElements) {
          callback();
        }
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [depthValue]);
};

export default useOutsideClick;
