import { completeInitialValue, completeVar } from './completeFields';
import { Outcome } from '../../../core/types/outcome';

const resetComplete = () => {
  const value = {
    ...completeInitialValue,
  };
  completeVar(value);
};

const setShouldUpdateStatusToComplete = (payload: null | boolean) => {
  const value = {
    ...completeVar(),
    shouldUpdateStatusToComplete: payload,
  };
  completeVar(value);
};

const setShouldUpdateStatusToCurrent = (payload: null | boolean) => {
  const value = {
    ...completeVar(),
    shouldUpdateStatusToCurrent: payload,
  };
  completeVar(value);
};

const setOutcomeToComplete = (payload: null | Outcome) => {
  const value = {
    ...completeVar(),
    outcomeToComplete: payload,
  };
  completeVar(value);
};

const setOutcomeToIncomplete = (payload: null | Outcome) => {
  const value = {
    ...completeVar(),
    outcomeToIncomplete: payload,
  };
  completeVar(value);
};

export {
  setShouldUpdateStatusToComplete,
  setShouldUpdateStatusToCurrent,
  setOutcomeToComplete,
  setOutcomeToIncomplete,
  resetComplete,
};
