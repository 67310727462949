import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 1px;
  min-width: 1px;
`;

const HoverHandler = ({
  onMouseEnter,
  onMouseLeave,
  children,
}: {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  children: JSX.Element;
}): JSX.Element => {
  return (
    <StyledWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
    </StyledWrapper>
  );
};

export default HoverHandler;
