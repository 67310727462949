import { isAfter, startOfDay } from 'date-fns';
import {
  BmSubscriptionTypes,
  StripePrices,
  StripeSubscriptionIntervals,
  UserSubscriptionInfo,
} from '../../graphql/types';

export enum UpgradeDowngradeStatus {
  Downgrade = 'Downgrade',
  Upgrade = 'Upgrade',
  Same = 'Same',
  Forbidden = 'Forbidden',
}

export const shouldShowExpiredPopup = (
  prevShowTime: number | null | undefined,
  isSubscriptionExpiredTrial: boolean,
) => {
  if (!prevShowTime) {
    return true;
  }
  if (isSubscriptionExpiredTrial) {
    return isAfter(startOfDay(new Date()), startOfDay(new Date(prevShowTime * 1000)));
  } else {
    return isAfter(startOfDay(new Date()), startOfDay(new Date(prevShowTime * 1000)));
    // return Math.abs(differenceInDays(startOfDay(new Date()), startOfDay(prevShowTime * 1000))) >= 7;
  }
};

export const getUpgradeDowngradeStatus = (
  currentPlan?: UserSubscriptionInfo,
  checkedPlan?: StripePrices,
): UpgradeDowngradeStatus | undefined => {
  if (!currentPlan || !checkedPlan) {
    return undefined;
  }
  const currentPlanName = currentPlan?.bmSubscriptionPlan;
  const checkedPlanName = checkedPlan?.bmSubscriptionPlan;

  if (currentPlanName === checkedPlanName) {
    return UpgradeDowngradeStatus.Same;
  }
  switch (currentPlanName) {
    case BmSubscriptionTypes.Trial_Free:
      return UpgradeDowngradeStatus.Upgrade;
    //

    case BmSubscriptionTypes.SelfGuided_Free:
      if (checkedPlanName === BmSubscriptionTypes.SelfGuided_Individual) {
        return UpgradeDowngradeStatus.Same;
      }
      return UpgradeDowngradeStatus.Upgrade;
    //

    case BmSubscriptionTypes.SelfGuided_Individual:
      return UpgradeDowngradeStatus.Upgrade;
    //

    case BmSubscriptionTypes.CoachLed_Individual:
      if (currentPlan.interval === StripeSubscriptionIntervals.month) {
        if (checkedPlanName === BmSubscriptionTypes.SelfGuided_Individual) {
          return UpgradeDowngradeStatus.Downgrade;
        }
        return UpgradeDowngradeStatus.Upgrade;
      } else {
        if (checkedPlanName === BmSubscriptionTypes.SelfGuided_Individual) {
          return UpgradeDowngradeStatus.Forbidden;
        }
        return UpgradeDowngradeStatus.Upgrade;
      }
    //

    case BmSubscriptionTypes.FlexibleWorkProfessional_Individual:
      if (checkedPlanName === BmSubscriptionTypes.SelfGuided_Individual) {
        return UpgradeDowngradeStatus.Forbidden;
      }
      return UpgradeDowngradeStatus.Downgrade;
  }
  return undefined;
};

export const getOppositeDataPrice = (
  plans: StripePrices[] | undefined,
  currentPlan: BmSubscriptionTypes,
  currentInterval: StripeSubscriptionIntervals,
) => {
  if (!plans) {
    return undefined;
  }
  if (
    currentPlan === BmSubscriptionTypes.SelfGuided_Individual ||
    currentPlan === BmSubscriptionTypes.CoachLed_Individual
  ) {
    const oppositePlan = plans.find(
      (plan) => plan.bmSubscriptionPlan === currentPlan && plan.interval !== currentInterval,
    );
    if (oppositePlan) {
      return oppositePlan.amount / 100;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const getSaveAmount = (
  plans: StripePrices[] | undefined,
  currentPlan: BmSubscriptionTypes,
  currentInterval: StripeSubscriptionIntervals,
) => {
  if (!plans) {
    return undefined;
  }
  if (
    currentPlan === BmSubscriptionTypes.SelfGuided_Individual ||
    currentPlan === BmSubscriptionTypes.CoachLed_Individual
  ) {
    const oppositePlan = plans.find(
      (plan) => plan.bmSubscriptionPlan === currentPlan && plan.interval !== currentInterval,
    );
    const mainPlan = plans.find(
      (plan) => plan.bmSubscriptionPlan === currentPlan && plan.interval === currentInterval,
    );
    if (oppositePlan && mainPlan) {
      return (Math.abs(mainPlan.amount - oppositePlan.amount * 12) / 100).toFixed(0);
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};
