import React from 'react';
import { Dropdown, Menu, Button } from 'antd';
import { capitalize } from 'lodash-es';
import styled from 'styled-components';

import { OutcomeStatus } from 'core/types/outcome';
import { OutcomeStatusFilterProps } from './types';
import { ReactComponent as ArrowDown } from './svg/arrowDown.svg';
import { ReactComponent as CheckImage } from '../../../../core/svg/check.svg';

const StyledDropdown = styled(Dropdown)`
  && {
    text-align: left;
  }

  ul {
  }
`;

const StyledMenu = styled(Menu)`
  background-color: var(--color-main-grey-2);
  border-radius: 3px;
  box-shadow: 0px 1px 32px rgba(32, 38, 53, 0.2);

  .ant-dropdown-menu-item {
    position: relative;
    background-color: inherit;
    min-width: 125px;
    padding-left: 26px;

    &:hover {
      font-weight: 700;
    }

    & svg {
      position: absolute;
      top: 50%;
      left: 7px;
      transform: translateY(-55%);
    }
  }
`;

const StyledButton = styled(Button)`
  background: none;
  border: 0;
  box-shadow: none;
  padding: 0;

  &:hover,
  &:focus,
  &:active {
    background: none;
  }

  span {
    color: var(--color-dark-blue);
    //letter-spacing: ;
  }

  svg {
    margin-left: 10px;
  }
`;

const OutcomeStatusFilter = ({ onSelect, status }: OutcomeStatusFilterProps) => {
  return (
    <StyledDropdown
      trigger={['click']}
      overlay={() => (
        <StyledMenu selectable defaultSelectedKeys={[status]} onSelect={onSelect}>
          {Object.values(OutcomeStatus).map((menuStatus) => {
            return (
              <Menu.Item key={menuStatus}>
                {menuStatus === status && <CheckImage />}
                {capitalize(menuStatus)}
              </Menu.Item>
            );
          })}
        </StyledMenu>
      )}
    >
      <StyledButton>
        {capitalize(status)} Outcomes <ArrowDown />
      </StyledButton>
    </StyledDropdown>
  );
};

export { OutcomeStatusFilter };
