import axios from 'axios';
import { API_GRAPHQL_URL } from '../../../core/integrations/api/config';

type Response = {
  data: {
    data: any;
    errors: {
      message: string;
      code: string;
    }[];
  };
};

export const generateVerificationEmail = async (
  firstName: string,
  lastName: string,
  email: string,
  link?: string,
): Promise<Response> => {
  return await axios.post(
    `${API_GRAPHQL_URL}`,
    {
      query: `mutation generateVerificationEmail($firstName: String, $lastName: String, $email: String!, $link: String) {
                  generateVerificationEmail(firstName: $firstName, lastName: $lastName, email: $email, link: $link)
                }`,
      variables: {
        firstName,
        lastName,
        email,
        link,
      },
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        admin: 'a75d4e00-16c9-4bf2-9e9d-3a81aaa7b589',
      },
    },
  );
};

export const generatePasswordRecoveryEmail = async (email: string): Promise<Response> => {
  return await axios.post(
    `${API_GRAPHQL_URL}`,
    {
      query: `mutation generatePasswordRecoveryEmail($email: String!) {
                  generatePasswordRecoveryEmail(email: $email)
                }`,
      variables: { email },
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        admin: 'a75d4e00-16c9-4bf2-9e9d-3a81aaa7b589',
      },
    },
  );
};

export const generatePasswordChangedEmail = async (token: string): Promise<Response> => {
  return await axios.post(
    `${API_GRAPHQL_URL}`,
    {
      query: `mutation generatePasswordChangedEmail {
          generatePasswordChangedEmail
      }`,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `${token}`,
      },
    },
  );
};

export const setUserEmailVerified = async (idToken: string) => {
  return await axios.post(
    `${API_GRAPHQL_URL}`,
    {
      query: `mutation setUserEmailVerified {
                  setUserEmailVerified {
                    email
                  }
                }`,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `${idToken}`,
      },
    },
  );
};

export const checkFirebaseUserByEmail = async (email: string): Promise<Response> => {
  return await axios.post(
    `${API_GRAPHQL_URL}`,
    {
      query: `query checkFirebaseUserByEmail($email: String!) {
                  checkFirebaseUserByEmail(email: $email)
                }`,
      variables: { email },
    },
    {
      headers: {
        admin: 'a75d4e00-16c9-4bf2-9e9d-3a81aaa7b589',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    },
  );
};

export const getUserInfoFromDB = async (token: string): Promise<Response> => {
  return await axios.post(
    `${API_GRAPHQL_URL}`,
    {
      query: `query fetchUser {
        fetchUser {
          email
          id
          isFBaccountWithPhone
        }
      }`,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `${token}`,
      },
    },
  );
};

export const updateUser = async (
  isFBaccountWithPhone: boolean,
  token: string,
): Promise<Response> => {
  return await axios.post(
    `${API_GRAPHQL_URL}`,
    {
      query: `mutation updateUserEntity($userParams: UserInput!) {
        updateUserEntity(userParams: $userParams) {
          email
          isFBaccountWithPhone
        }
      }`,
      variables: {
        userParams: { isFBaccountWithPhone },
      },
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `${token}`,
      },
    },
  );
};

export const initNewFBUser = async (token: string): Promise<Response> => {
  return await axios.post(
    `${API_GRAPHQL_URL}`,
    {
      query: `mutation {
                initUser {
                  isActivated
                  id
                  email
                  firstName
                  lastName
                  isRegistered
                  workspace {
                    id
                  }
                }
              }`,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: `${token}`,
      },
    },
  );
};
