import * as routePaths from '../../../../constants/routePaths';
import {
  LEARNING_MATERIAL_LINK,
  BLOG_LINK,
  PODCAST_LINK,
  PRODUCTIVITY_TIPS,
} from '../../../../constants/links';

const links = [
  {
    text: 'Back to App',
    to: routePaths.ACTION_PATH,
    isVisibleForLogged: true,
    isVisibleForUnLogged: false,
    isDivider: true,
  },
  {
    text: 'Contact us',
    to: routePaths.CONTACT_US_PATH,
    isVisibleForLogged: true,
    isVisibleForUnLogged: true,
    isDivider: false,
  },
  {
    text: 'F.A.Q.',
    to: routePaths.FAQ_PATH,
    isVisibleForLogged: true,
    isVisibleForUnLogged: true,
    isDivider: false,
  },
  {
    text: 'Blog',
    to: BLOG_LINK,
    isVisibleForUnLogged: false,
    isVisibleForLogged: true,
    isDivider: false,
    isBlank: true,
  },
  {
    text: 'Podcast',
    to: PODCAST_LINK,
    isVisibleForUnLogged: false,
    isVisibleForLogged: true,
    isDivider: false,
    isBlank: true,
  },
  {
    text: 'Productivity Tips',
    to: PRODUCTIVITY_TIPS,
    isVisibleForUnLogged: false,
    isVisibleForLogged: true,
    isDivider: false,
    isBlank: true,
  },
  {
    text: 'Sign In',
    to: routePaths.SIGN_IN_PATH,
    isVisibleForUnLogged: true,
    isVisibleForLogged: false,
    isDivider: false,
  },
];

export default links;

