export enum FocusTimerStatus {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
}

export interface FocusTimer {
  actionId: number;
  createDate: string;
  fixedPeriodSelected: number;
  id: number;
  resumeTime: number;
  spendTime: number;
  startTime: number;
  status: FocusTimerStatus;
  updateDate: string;
  userId: number;
  isBipSound: boolean | null;
}
