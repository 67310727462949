import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { theme } from '../../../../core/styles/styled-components';
import { SignInAndSignUpLayoutProps } from './types';
import { ReactComponent as CreditCardIcon } from '../../svg/credit-card.svg';

const StyledContainer = styled.div`
  width: 330px;

  @media ${theme.device.tablet.max} {
    padding-bottom: 80px;
  }

  @media ${theme.device.mobile.max} {
    width: 100%;
  }
`;

const StyledTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  margin-bottom: 8px;

  @media ${theme.device.mobile.max} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const StyledText = styled.p`
  font-size: 14px;
  line-height: 16px;
`;

const StyledSubtitle = styled.div`
  margin-bottom: 24px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledCreditCardInfo = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

const StyledCreditCardIcon = styled(CreditCardIcon)`
  margin-right: 8px;
`;

const StyledDivider = styled.div`
  margin: 24px 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;

  span {
    font-size: 9px;
    line-height: 11px;
    color: rgba(32, 38, 53, 0.7);
    padding: 0 12px;
  }

  div {
    height: 1px;
    width: 100%;
    background: #9bdbdc;
    opacity: 0.7;
  }
`;

const SignInAndSignUpLayout = ({
  title,
  formComponent,
  socialAuthComponent,
  showSuccessMessage,
  subtitle,
  isAccess,
}: SignInAndSignUpLayoutProps) => {
  const dividerEl = socialAuthComponent && (
    <StyledDivider>
      <div />
      <span>Or</span>
      <div />
    </StyledDivider>
  );

  const successMessageEl = (
    <div>
      <StyledTitle>Check your email</StyledTitle>
      <StyledText>
        We have sent a link to your email address, <br />
        so that we can verify your identity.
      </StyledText>
    </div>
  );

  const subtitleEl = subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>;
  const metaInfo =
    title === 'Sign in'
      ? {
          title: 'App Sign-In | BillionMinds',
          description:
            'Work/life optimization software integrated with skills exercises, micro-learning videos, and certified coaching. Based on behavioral science.',
        }
      : {
          title: 'Get Started | BillionMinds',
          description:
            "Sign up as an individual or an organization to master flexible work with the world's first behavioral science-based productivity platform.",
        };

  if (showSuccessMessage) {
    return successMessageEl;
  }

  return (
    <StyledContainer>
      <Helmet>
        <meta name="title" key="title" content={metaInfo.title} />
        <meta name="description" key="description" content={metaInfo.description} />
      </Helmet>
      {title === 'Sign in' ? (
        <StyledTitle>{title}</StyledTitle>
      ) : (
        <StyledTitleContainer>
          <StyledTitle>{isAccess ? 'Access BillionMinds Platform' : title}</StyledTitle>
          {!isAccess && (
            <StyledCreditCardInfo>
              <StyledCreditCardIcon />
              No credit card required
            </StyledCreditCardInfo>
          )}
        </StyledTitleContainer>
      )}
      {subtitleEl}
      {socialAuthComponent}
      {dividerEl}
      {formComponent}
    </StyledContainer>
  );
};

export default SignInAndSignUpLayout;
