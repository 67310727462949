import React from 'react';
import styled from 'styled-components';
import { Select } from 'formik-antd';

import { ReactComponent as ArrowDownIcon } from 'core/svg/arrow-down.svg';

const { Option, OptGroup } = Select;

const StyledSelect = styled((props) => <Select {...props} />).attrs({
  suffixIcon: <ArrowDownIcon />,
})<{ disabled?: boolean }>`
  .ant-select-arrow svg path {
    fill: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.25)' : 'var(--color-dark-blue)')};
  }
`;

export { StyledSelect as Select, OptGroup as OptionGroup, Option };
