import React from 'react';
import styled, { css } from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

import { Card } from 'core/components/common';
import { EntityCardProps } from './types';
import { EntityType, getCacheEntityById } from '../../../utils';
import { Outcome } from '../../../../core/types/outcome';
import { Action } from '../../../../core/types/action';
import { ColumnType } from 'apollo/stateFields/dnd/dndFields';
import { ReactComponent as ViewIcon } from './svg/view.svg';
import HoverHandler from '../HoverHandler/HoverHandler';
import { highlightedEntitiesMutation } from '../../../../apollo/stateFields/highlightedEnntities';

const StyledHeaderMainInfoWrapper = styled.div`
  display: flex;
`;

const StyledIconWrapper = styled.div`
  flex: none;
`;

const StyledTitleContainer = styled.div`
  flex: 1;
  width: calc(100% - 1rem);
`;

const StyledTitleWrapper = styled.div`
  flex: 1;
  width: 100%;
`;

const StyledEntityWrapper = styled.div<{
  isCombine?: boolean;
  isLeftContainer?: boolean;
}>`
  ${(props) =>
    props.isCombine &&
    css`
      transform: none !important;
    `}

  & > div {
    ${(props) =>
      props.isCombine &&
      css`
        background: rgba(32, 38, 53, 0.06);
        border: 1px dashed rgba(32, 38, 53, 0.4);
      `}
    ${(props) =>
      props.isLeftContainer &&
      css`
        background: white;
      `}
  }
`;

const StyledCopy = styled.div<{ entityName?: string }>`
  & > div {
    ${(props) =>
      props.entityName === EntityType.OUTCOME &&
      css`
        background: rgba(32, 38, 53, 0.06);
      `}
    border: 1px solid rgba(32, 38, 53, 0.4);
  }
`;

const StyledViewOnlyContainer = styled.div<{ entityName?: string }>`
  display: flex;
  align-items: center;
  font-size: 9px;
  line-height: 12px;
  padding-bottom: 2px;

  ${(props) =>
    props.entityName === EntityType.ACTION &&
    css`
      margin-top: -2px;
    `}

  svg {
    margin-left: 4px !important;
  }
`;

function renderCard(
  icon: any,
  title: any,
  isViewOnly?: boolean,
  entityName?: string,
  ...rest: any
) {
  const restProps = rest[0];

  return (
    <Card
      headerContent={
        <StyledHeaderMainInfoWrapper>
          <StyledIconWrapper>{icon}</StyledIconWrapper>
          <StyledTitleContainer>
            {isViewOnly && (
              <StyledViewOnlyContainer entityName={entityName}>
                <span>View only </span>
                <ViewIcon />
              </StyledViewOnlyContainer>
            )}
            <StyledTitleWrapper>{title}</StyledTitleWrapper>
          </StyledTitleContainer>
        </StyledHeaderMainInfoWrapper>
      }
      {...restProps}
    >
      {restProps.children}
    </Card>
  );
}

const EntityCard = React.memo(
  ({
    icon,
    title,
    key,
    draggableId,
    isDraggable,
    draggablePosition,
    entityName,
    entity,
    apolloClient,
    filterInput,
    isViewOnly,
    ...rest
  }: EntityCardProps) => {
    const cardEl = renderCard(icon, title, isViewOnly, entityName, rest);
    const { setHoveredOutcomeId } = highlightedEntitiesMutation;

    const checkLeftContainer = (isDragging: boolean, draggingOver?: string) => {
      switch (entityName) {
        case EntityType.OUTCOME:
          return isDragging && draggingOver !== ColumnType.Outcome;
        case EntityType.ACTION:
          return isDragging && draggingOver === ColumnType.Outcome;
        default:
          return false;
      }
    };

    const checkCombine = (combineTargetFor?: string | null) => {
      if (combineTargetFor) {
        if (entityName === EntityType.OUTCOME) {
          const outcome = entity as Outcome;
          if (apolloClient && filterInput) {
            const isOutcome = getCacheEntityById(
              apolloClient,
              +combineTargetFor,
              EntityType.OUTCOME,
              filterInput,
            ) as Outcome;
            if (isOutcome) return false;
          }

          return !outcome.actions?.find((action) => action.id === +combineTargetFor);
        }
        if (entityName === EntityType.ACTION) {
          const action = entity as Action;
          return !(action.outcome && action.outcome.id === +combineTargetFor);
        }
      }
      return false;
    };

    if (!isDraggable) {
      return cardEl;
    }

    return (
      // @ts-ignore
      <Draggable draggableId={draggableId} index={draggablePosition!} key={key ? key : draggableId}>
        {(provided, { combineTargetFor, draggingOver, isDragging }) => {
          const isCopyLeftContainer = checkLeftContainer(isDragging, draggingOver);
          const isCombine = checkCombine(combineTargetFor);

          if (entityName !== EntityType.OUTCOME) {
            return (
              <>
                <StyledEntityWrapper
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  isCombine={isCombine}
                  isLeftContainer={isCopyLeftContainer}
                  style={{ ...provided.draggableProps.style, outlineStyle: 'none' }}
                >
                  {cardEl}
                </StyledEntityWrapper>
                {isCopyLeftContainer && <StyledCopy entityName={entityName}>{cardEl}</StyledCopy>}
              </>
            );
          }

          return (
            <HoverHandler
              onMouseEnter={() => {
                if (entity?.id) {
                  setHoveredOutcomeId(entity?.id);
                }
              }}
              onMouseLeave={() => setHoveredOutcomeId(null)}
            >
              <>
                <StyledEntityWrapper
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  isCombine={isCombine}
                  isLeftContainer={isCopyLeftContainer}
                  style={{ ...provided.draggableProps.style, outlineStyle: 'none' }}
                >
                  {cardEl}
                </StyledEntityWrapper>
                {isCopyLeftContainer && <StyledCopy entityName={entityName}>{cardEl}</StyledCopy>}
              </>
            </HoverHandler>
          );
        }}
      </Draggable>
    );
  },
);

export { EntityCard };
