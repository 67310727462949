import React from 'react';
import { ReactComponent as ReadyIcon } from '../../img/ready.svg';
import { ReactComponent as ReadyMobileIcon } from '../../img/ready-mobile.svg';
import { useMediaQuery } from 'react-responsive';
import theme from '../../../core/styles/styled-components/theme';
import styled from 'styled-components';

const StyledContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0 70px;
`;

const StyledReadyMobileIcon = styled(ReadyMobileIcon)`
  width: 100%;
`;

const StyledReadyIcon = styled(ReadyIcon)`
  width: 100%;
`;

const ReadyContent = () => {
  const isMobile = useMediaQuery({ query: theme.device.mobile.max });

  return (
    <StyledContent>{isMobile ? <StyledReadyMobileIcon /> : <StyledReadyIcon />}</StyledContent>
  );
};

export default ReadyContent;
