import { ComponentProps, MouseEvent } from 'react';
import { Drawer } from 'antd';

import { ActionIcon, OutcomeIcon } from 'core/icons';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  && {
    width: 14px;
    height: 14px;
    left: -20px;
    position: absolute;
    svg {
      width: 100%;
      height: 100%;
    }
  }
`;
const StyledActionIcon = () => {
  return (
    <StyledWrapper>
      <ActionIcon />
    </StyledWrapper>
  );
};
const StyledOutcomeIcon = () => {
  return (
    <StyledWrapper>
      <OutcomeIcon />
    </StyledWrapper>
  );
};

export type NewInformationProps = Omit<ComponentProps<typeof Drawer>, 'onClose'> & {
  clickOutside?: boolean;
};

export type NewInformationTabsProps = {
  onChange: (tab: number) => any;
  activeTab: number;
};

export type NewInformationTab = {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  isActive: boolean;
  isDivider: boolean;
};

export const NoteMenuOptions = {
  EDIT_NOTE: { label: 'Edit' },
  VIEW_NOTE: { label: 'View Incoming note' },
  CONVERT_NOTE_TO_ACTION: { label: 'Convert to Action', icon: StyledActionIcon },
  CONVERT_NOTE_TO_OUTCOME: { label: 'Convert to Outcome', icon: StyledOutcomeIcon },
  DELETE_NOTE: { label: 'Delete' },
  ARCHIVE_NOTE: { label: 'Archive' },
  UNARCHIVE_NOTE: { label: 'Unarchive' },
};

export const NOTE_MENU_OPTIONS_BY_STATE = {
  ARCHIVED: [NoteMenuOptions.UNARCHIVE_NOTE],
  FOR_VIEWER: [NoteMenuOptions.VIEW_NOTE],
  NOT_ARCHIVED: [
    NoteMenuOptions.EDIT_NOTE,
    NoteMenuOptions.CONVERT_NOTE_TO_ACTION,
    NoteMenuOptions.CONVERT_NOTE_TO_OUTCOME,
    NoteMenuOptions.ARCHIVE_NOTE,
  ],
};
