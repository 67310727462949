import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { FetchOutcomes, MoveOutcome } from '../../outcomes/types';
import { MOVE_OUTCOME } from '../../outcomes/graphql/mutations';
import { FETCH_OUTCOMES } from '../../outcomes/graphql/queries';
import {
  checkIsShownEntity,
  OperationType,
  updatedCacheData,
  updateWorkspaceTagsData,
} from '../../utils';
import { mutateOutcome } from '../../../context/userWorkspaceContext';
import { Outcome } from '../../../core/types/outcome';
import { checkOutcomeForComplete } from '../utils';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../apollo/stateFields/filterInput/filterInputFields';
import { filterMutation } from '../../../apollo/stateFields/filterInput';
import { completeMutation } from '../../../apollo/stateFields/complete';

const useMoveOutcome = (outcomeToMove: Outcome | null) => {
  const apolloClient = useApolloClient();
  const { setOutcomeToComplete, setOutcomeToIncomplete } = completeMutation;
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const { setUpdateFilterInputId } = filterMutation;

  return useMutation<MoveOutcome>(MOVE_OUTCOME, {
    update(cache, { data }) {
      if (outcomeToMove === null) {
        return;
      }
      const { fetchUserWorkspaceOutcomes: oldWorkspaceOutcomes = [] } =
        cache.readQuery<FetchOutcomes>({
          query: FETCH_OUTCOMES,
          variables: {
            workspaceId: outcomeToMove.workspaceId,
          },
        }) || {};

      const { fetchUserWorkspaceOutcomes: newWorkspaceOutcomes = [] } =
        cache.readQuery<FetchOutcomes>({
          query: FETCH_OUTCOMES,
          variables: {
            workspaceId: data?.moveUserWorkspaceOutcome.workspaceId,
          },
        }) || {};

      cache.writeQuery<FetchOutcomes>({
        query: FETCH_OUTCOMES,
        variables: {
          workspaceId: outcomeToMove.workspaceId,
        },
        data: {
          fetchUserWorkspaceOutcomes: updatedCacheData(
            oldWorkspaceOutcomes,
            data?.moveUserWorkspaceOutcome,
            OperationType.DELETE,
          ),
        },
      });

      cache.writeQuery<FetchOutcomes>({
        query: FETCH_OUTCOMES,
        variables: {
          workspaceId: data?.moveUserWorkspaceOutcome.workspaceId,
        },
        data: {
          fetchUserWorkspaceOutcomes: updatedCacheData(
            newWorkspaceOutcomes,
            data?.moveUserWorkspaceOutcome,
            OperationType.CREATE,
          ),
        },
      });
      if (data?.moveUserWorkspaceOutcome) {
        mutateOutcome(
          apolloClient,
          data.moveUserWorkspaceOutcome,
          'update',
          filterInput,
          outcomeToMove,
        );
        updateWorkspaceTagsData(apolloClient, data?.moveUserWorkspaceOutcome);
        checkOutcomeForComplete(
          setOutcomeToComplete,
          setOutcomeToIncomplete,
          data?.moveUserWorkspaceOutcome,
        );

        if (!checkIsShownEntity(data.moveUserWorkspaceOutcome, filterInput)) {
          setUpdateFilterInputId({
            type: 'Outcome',
            workspaceId: data.moveUserWorkspaceOutcome.workspaceId,
            id: data.moveUserWorkspaceOutcome.id,
          });
        }
      }
    },
  });
};

export default useMoveOutcome;
