import React, { useCallback, useEffect, useRef } from 'react';
import { Typography } from 'antd';
import { toast, ToastContent } from 'react-toastify';

import { CreatableSelectField } from 'core/components/form';
import { Tag } from 'core/types/tag';
import {
  FETCH_USER_WORKSPACE_TAGS,
  SEARCH_USER_WORKSPACE_TAGS,
} from 'features/common/graphql/queries';
import { useApolloClient, useQuery } from '@apollo/client';
import { FetchUserWorkspaceTags } from '../../../graphql/types';

type AddTagsProps = {
  tags: Tag[];
  workspaceId: number;
  isDisabled?: boolean;
  onChange?: (tags: any[]) => void;
  currentValue: TagOption[];
};

export interface TagOption {
  label: string;
  name: string;
  value: string | number;
  __isNew__?: boolean;
}

const AddTags = ({
  tags,
  workspaceId,
  onChange,
  isDisabled = false,
  currentValue,
}: AddTagsProps) => {
  const apolloClient = useApolloClient();
  const { data: tagsResponse } = useQuery<FetchUserWorkspaceTags>(FETCH_USER_WORKSPACE_TAGS, {
    variables: { workspaceId },
  });
  const updateCount = useRef(0);
  const prevTagCount = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (!(tags.length === 0 && prevTagCount.current === 0)) {
      updateCount.current = updateCount.current + 1;
    }
    prevTagCount.current = tags.length;
  }, [tags]);

  const searchTags = async (query: string) => {
    if (query) {
      try {
        const { data } = await apolloClient.query({
          query: SEARCH_USER_WORKSPACE_TAGS,
          variables: {
            query,
            workspaceId,
          },
          fetchPolicy: 'network-only',
        });

        return data.searchUserWorkspaceTags
          .filter(
            (tag: Tag) =>
              !currentValue.some(
                (currentTag) => currentTag.name.toLowerCase() === tag.name.toLowerCase(),
              ),
          )
          .map(
            (tag: Tag): TagOption => ({
              label: tag.name,
              name: tag.name,
              value: tag.id,
            }),
          );
      } catch (error) {
        console.error(error);
        toast(error as ToastContent);
      }
    }
  };

  const allTags = tagsResponse?.fetchUserWorkspaceTags
    ?.filter(
      (tag: Tag) =>
        !currentValue.some(
          (currentTag) => currentTag.name.toLowerCase() === tag.name.toLowerCase(),
        )
    )
    ?.map(
      (tag: Tag): TagOption => ({
        label: tag.name,
        name: tag.name,
        value: tag.id,
      }),
    );

  const tagsOptions = tags?.map(
    (tag: Tag): TagOption => ({
      label: tag.name,
      name: tag.name,
      value: tag.id,
    }),
  );

  const checkForExistedTags = useCallback(
    (value) => {
      const existedTags = tagsResponse?.fetchUserWorkspaceTags
        ? [...tags, ...currentValue, ...tagsResponse?.fetchUserWorkspaceTags]
        : [...tags, ...currentValue];

      return existedTags.some((tag) => tag?.name?.toLowerCase() === value.toLowerCase());
    },
    [tags, tagsResponse?.fetchUserWorkspaceTags],
  );

  return (
    <>
      <Typography.Title level={4}>Add tags</Typography.Title>
      <CreatableSelectField
        key={updateCount.current}
        defaultOptions={allTags}
        defaultValue={tagsOptions}
        name="inputTags"
        instanceId="tags"
        createLabel="Tag"
        search={searchTags}
        isDisabled={isDisabled || !workspaceId}
        isValidNewOption={(value: string) => {
          return value && value.length <= 16 && !checkForExistedTags(value);
        }}
        onInputChange={(value: string) => (value.length > 16 ? value.slice(0, 16) : value)}
        onChange={onChange}
      />
    </>
  );
};

export { AddTags };
