import React, { useEffect, useMemo, useRef } from 'react';
import { Col, Row } from 'antd';
import { useQuery } from '@apollo/client';
import Highlighter from 'react-highlight-words';
import { ActionCardProps } from './types';
import { getTrimmedString } from './utils';
import { Tags } from './Tags';
import { toast, ToastContent } from 'react-toastify';
import { ContextMenuOption } from '../../../../../core/components/common/Card/types';
import { ActionStatus } from '../../../../../core/types/action';
import { ActionStatusComponent } from '../common/ActionStatusComponent';
import { adjustActualDateAccordingStatus } from '../../../utils';
import { CONTEXT_MENU_OPTIONS_BY_STATE, ContextMenuOptions } from './constants';
import WorkspaceSelectMenu from '../../../../common/components/WorkspaceSelectMenu/WorkspaceSelectMenu';
import useDuplicateAction from '../../../../interactions/action/useDuplicateAction';
import { sendEvent } from '../../../../../core/integrations/sentry/events';
import { EntityType } from '../../../../utils';
import { Permission } from '../../../../../core/types/workspace';
import { FetchUserWorkspacesWithInvitedCount } from '../../../../workspaces/types';
import useMoveAction from '../../../../interactions/action/useMoveAction';
import useUpdateMoveAction from '../../../../interactions/action/useUpdateMoveAction';
import { boardEditedEntityMutation } from '../../../../../apollo/stateFields/boardEditedEntity';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../../../../common/graphql/queries';
import useWorkspaceInvites from '../../../../../hooks/useWorkspaceInvites';
import { ERROR_STATE, ErrorValue } from '../../../../../apollo/stateFields/error/errorFields';
import {
  StyledActionBody,
  StyledNameWrapper,
  StyledActionName,
  StyledActionOutcome,
  StyledActionOutcomeWrapper,
  StyledActionWorkspace,
  StyledActionWorkspaceWrapper,
  StyledDates,
  StyledEntityCard,
  StyledHeadLeftContainer,
  StyledPreposition,
  StyledSharedPeopleIcon,
  StyledTagsWrapper,
  StyledCol,
  StyledLockIcon,
  StyledWrapper,
  StyledWorkspaceSelectContainer,
} from './StyledComponents';
import { FETCH_FOCUS_TIMER } from '../../../graphql/queries';

const ActionCard = React.memo(
  ({
    action,
    showStatusSelect = false,
    isEditOutcomeList = false,
    highlightText,
    isNested,
    onUnselect,
    ...restOfProps
  }: ActionCardProps) => {
    const { loading, getWorkspaceInvitesById } = useWorkspaceInvites();
    const [updateAction] = useUpdateMoveAction(action);
    const [moveAction, { loading: loadingMoveAction }] = useMoveAction(action);
    const [duplicateActionQuery] = useDuplicateAction();

    const { showEditActionModal, setUpdateOutcomeWithActionEntity } = boardEditedEntityMutation;
    const { data: workspacesResponse } = useQuery<FetchUserWorkspacesWithInvitedCount>(
      FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
    );
    const workspaces = workspacesResponse?.fetchUserWorkspacesWithInvitedCount || [];
    const { data } = useQuery(ERROR_STATE);
    const { errorWorkspaces }: ErrorValue = data?.error;

    const isArchived = action.isArchived;
    const workspace = useMemo(
      () => workspaces.find((workspace) => workspace.id === action.workspaceId),
      [workspaces, action],
    );

    const workspaceName = workspace?.name
      ? getTrimmedString(workspace?.name, 41)
      : !!errorWorkspaces?.find((w) => Number(w.id) === action?.workspaceId)?.name
      ? getTrimmedString(
          errorWorkspaces?.find((w) => Number(w.id) === action?.workspaceId)?.name,
          41,
        )
      : '';
    const viewerPermission = workspace?.permission === Permission.VIEWER || false;
    const editorPermission =
      workspaces.find((workspace) => workspace.id === action?.workspaceId)?.permission ===
        Permission.EDITOR || false;
    const outcomeName = action?.outcome?.name
      ? getTrimmedString(action.outcome.name, 49)
      : undefined;

    const prevActionStatus = useRef<ActionStatus>(action.status);

    const sharedUsersCount = getWorkspaceInvitesById(action.workspaceId)?.count || 0;
    const isShared = sharedUsersCount > 1;

    useEffect(() => {
      prevActionStatus.current = action.status;
    }, [action.status]);

    const toggleArchiveAction = async (isArchived: boolean) => {
      try {
        await updateAction({
          variables: {
            actionValues: {
              id: action.id,
              isArchived,
            },
            workspaceId: action.workspaceId,
          },
          refetchQueries: [FETCH_FOCUS_TIMER],
        });

        sendEvent(
          isArchived ? 'action-archive' : 'action-unarchive',
          isArchived ? 'Action archive' : 'Action unarchive',
          {
            'Action ID': action.id,
          },
        );
      } catch (error) {
        console.error(error);
        toast(error as ToastContent);
      }
    };

    const duplicateAction = async () => {
      try {
        const resp = await duplicateActionQuery({
          variables: {
            actionId: action.id,
            workspaceId: action.workspaceId,
          },
        });
        const newAction = resp.data?.duplicateUserWorkspaceAction;
        if (newAction?.outcome && newAction) {
          setUpdateOutcomeWithActionEntity(newAction);
        }
        sendEvent('action-duplicate', 'Action duplicate', {
          'Action ID': action.id,
        });
      } catch (error) {
        console.error(error);
        toast(error as ToastContent);
      }
    };

    const handleContextMenuOptionClick = (option: ContextMenuOption) => {
      const label = option.label;
      switch (label) {
        case ContextMenuOptions.EDIT_ACTION.label:
          showEditActionModal(action);
          break;
        case ContextMenuOptions.VIEW_ACTION.label:
          showEditActionModal(action);
          break;
        case ContextMenuOptions.DUPLICATE_ACTION.label:
          duplicateAction();
          break;
        case ContextMenuOptions.ARCHIVE_ACTION.label:
          toggleArchiveAction(true);
          break;
        case ContextMenuOptions.UNARCHIVE_ACTION.label:
          toggleArchiveAction(false);
          break;
        case ContextMenuOptions.REMOVE_ACTION.label:
          onUnselect && onUnselect();
          break;
      }
    };

    const closeEditActionForm = () => {
      showEditActionModal(null);
    };

    const onActionWorkspaceChange = async (option: any, oldWorkspaceId = -1) => {
      const newWorkspaceId = option?.value;
      try {
        await moveAction({
          variables: {
            actionValues: {
              id: action.id,
              workspaceId: newWorkspaceId,
              inputTags: action.tags?.map((tag) => ({ name: tag.name })),
            },
            outcomeId: action?.outcome?.id,
            workspaceId: oldWorkspaceId,
            targetWorkspaceId: newWorkspaceId,
          },
        });
      } catch (error) {
        console.error(error);
        toast(error as ToastContent);
      }
    };

    const onActionStatusChange = async (actionValues: any) => {
      try {
        await updateAction({
          variables: {
            actionValues: {
              ...actionValues,
              id: action.id,
              actualStartDate: actionValues.actualStartDate || null,
              actualEndDate: actionValues.actualEndDate || null,
            },
            workspaceId: action.workspaceId,
          },
          refetchQueries: [FETCH_FOCUS_TIMER],
        });
        sendEvent('action-edit', 'Action edit', {
          'Action ID': action?.id,
        });
      } catch (error) {
        console.error(error);
        toast(error as ToastContent);
      } finally {
        closeEditActionForm();
      }
    };

    const icon = isArchived ? <StyledLockIcon /> : null;

    if (!workspace) {
      return null;
    }

    return (
      <>
        <StyledEntityCard
          isShared={isShared}
          disabled={loadingMoveAction}
          draggableId={'' + action.id}
          entity={action}
          entityName={EntityType.ACTION}
          isViewOnly={viewerPermission}
          title={
            <>
              <StyledNameWrapper>
                <StyledActionName>
                  {isArchived && icon}
                  {highlightText ? (
                    <Highlighter
                      textToHighlight={action.name!}
                      searchWords={[highlightText]}
                      highlightStyle={{
                        padding: 0,
                        backgroundColor: '#aff8db',
                        borderRadius: '2px',
                      }}
                    />
                  ) : (
                    action.name
                  )}
                </StyledActionName>
                {/* move to bottom place only */}
                {/*{showStatusSelect && statusSelectPlace === 'top' && (*/}
                {/*  <div>*/}
                {/*    <ActionStatusComponent*/}
                {/*      withoutFormik={isNested}*/}
                {/*      small*/}
                {/*      disabled={isArchived || viewerPermission}*/}
                {/*      value={action.status}*/}
                {/*      onChange={async (value: ActionStatus) => {*/}
                {/*        let input = {*/}
                {/*          status: value,*/}
                {/*          actualStartDate: action.actualStartDate,*/}
                {/*          actualEndDate: action.actualEndDate,*/}
                {/*        };*/}
                {/*        adjustActualDateAccordingStatus({*/}
                {/*          status: value,*/}
                {/*          actualStartDate: action?.startDate?.toString(),*/}
                {/*          actualEndDate: action?.endDate?.toString(),*/}
                {/*          setActualStartDate: (value) => (input.actualStartDate = value),*/}
                {/*          setActualEndDate: (value) => (input.actualEndDate = value),*/}
                {/*          prevStatus: prevActionStatus.current,*/}
                {/*          prevActualStartDate: action?.actualStartDate?.toString(),*/}
                {/*        });*/}
                {/*        prevActionStatus.current = value;*/}
                {/*        await onActionStatusChange(input);*/}
                {/*      }}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*)}*/}
              </StyledNameWrapper>
              <Row wrap={false} justify="space-between">
                <StyledCol
                  flex={'none'}
                  // statusSelectPlace={isNested ? 'bottom' : undefined}
                  className="col--fill"
                >
                  <StyledActionWorkspaceWrapper>
                    <StyledHeadLeftContainer className="col-fit">
                      {isNested || viewerPermission ? (
                        <StyledWorkspaceSelectContainer
                        // statusSelectPlace={isNested ? 'bottom' : undefined}
                        >
                          <StyledActionWorkspace isShared={isShared}>
                            {workspaceName}
                          </StyledActionWorkspace>
                        </StyledWorkspaceSelectContainer>
                      ) : (
                        <WorkspaceSelectMenu
                          isMultiSorted
                          disabled={isArchived || isNested || loadingMoveAction}
                          currentWorkspaceId={action.workspaceId}
                          onContextMenuOptionClick={async (option) => {
                            if (option?.value === action.workspaceId) {
                              return;
                            }
                            await onActionWorkspaceChange(option, action.workspaceId);
                            sendEvent('action-move', 'Update workspace of action');
                          }}
                        >
                          <StyledActionWorkspace isShared={isShared}>
                            {workspaceName}
                          </StyledActionWorkspace>
                        </WorkspaceSelectMenu>
                      )}
                      {isShared && <StyledSharedPeopleIcon count={sharedUsersCount} />}
                    </StyledHeadLeftContainer>
                    {action.tags && action.tags?.length > 0 && (
                      <StyledTagsWrapper className="col--fill">
                        <Tags tags={action.tags} loadingOtherComponent={loading} />
                      </StyledTagsWrapper>
                    )}
                  </StyledActionWorkspaceWrapper>
                </StyledCol>
              </Row>
            </>
          }
          onDoubleClick={() => showEditActionModal(action)}
          highlightedContextMenuOptions={[
            ContextMenuOptions.EDIT_ACTION,
            ContextMenuOptions.VIEW_ACTION,
            ContextMenuOptions.DUPLICATE_ACTION,
            ContextMenuOptions.ARCHIVE_ACTION,
            ContextMenuOptions.UNARCHIVE_ACTION,
          ]}
          contextMenuOptions={
            viewerPermission
              ? CONTEXT_MENU_OPTIONS_BY_STATE.FOR_VIEWER
              : isArchived
              ? CONTEXT_MENU_OPTIONS_BY_STATE.ARCHIVED
              : isNested && isEditOutcomeList
              ? CONTEXT_MENU_OPTIONS_BY_STATE.NOT_ARCHIVED_NESTED
              : CONTEXT_MENU_OPTIONS_BY_STATE.NOT_ARCHIVED
          }
          onContextMenuOptionClick={handleContextMenuOptionClick}
          {...restOfProps}
        >
          <StyledActionBody>
            {outcomeName && (
              <StyledActionOutcomeWrapper>
                <StyledPreposition>with</StyledPreposition>
                <StyledActionOutcome>{outcomeName}</StyledActionOutcome>
              </StyledActionOutcomeWrapper>
            )}
            <Row wrap={true} justify="space-between" align={'middle'}>
              <Col flex={'none'}>
                <StyledDates action={action} />
              </Col>
              {showStatusSelect && (
                <Col flex={'auto'}>
                  <StyledWrapper>
                    <ActionStatusComponent
                      withoutFormik={isNested}
                      small
                      disabled={isArchived || viewerPermission}
                      defaultValue={action.status}
                      value={action.status}
                      onChange={(value: ActionStatus) => {
                        let input = {
                          status: value,
                          actualStartDate: action.actualStartDate,
                          actualEndDate: action.actualStartDate,
                        };
                        adjustActualDateAccordingStatus({
                          status: value,
                          actualStartDate: action?.startDate?.toString(),
                          actualEndDate: action?.endDate?.toString(),
                          setActualStartDate: (value) => (input.actualStartDate = value),
                          setActualEndDate: (value) => (input.actualEndDate = value),
                          prevStatus: prevActionStatus.current,
                          prevActualStartDate: action?.actualStartDate?.toString(),
                        });
                        prevActionStatus.current = value;
                        onActionStatusChange(input);
                      }}
                    />
                  </StyledWrapper>
                </Col>
              )}
            </Row>
          </StyledActionBody>
        </StyledEntityCard>
      </>
    );
  },
);

export { ActionCard };
