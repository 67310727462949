import {
  CUSTOM_ENTITY_TYPES,
  CUSTOM_ERROR_MESSAGE,
  ERROR_STATE,
  ErrorValue,
} from '../apollo/stateFields/error/errorFields';
import { Workspace } from '../core/types/workspace';
import { useApolloClient, useQuery } from '@apollo/client';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../features/common/graphql/queries';
import { useEffect, useMemo, useRef } from 'react';
import { FETCH_ACTION, FETCH_NOTE } from '../features/actions/graphql/queries';
import { FetchUserWorkspaceAction, FetchUserWorkspaceNote } from '../features/actions/types';
import { FetchOutcome } from '../features/outcomes/types';
import { FETCH_OUTCOME } from '../features/outcomes/graphql/queries';
import { Entity } from '../core/types/entity';
import { FetchUserWorkspacesWithInvitedCount } from '../features/workspaces/types';
import { errorMutation } from '../apollo/stateFields/error';

const useCheckSharedEntityMoved = <T extends Entity>(
  entity: T | null | undefined,
  entityType: CUSTOM_ENTITY_TYPES,
  workspaceId?: number,
  isLoading?: boolean,
  shouldStopChecking?: boolean,
) => {
  const { data } = useQuery(ERROR_STATE);
  const { errorMessage }: ErrorValue = data?.error;
  const { setErrorMessage, setEntityType } = errorMutation;
  const apolloClient = useApolloClient();
  const { data: workspacesResponse, refetch } = useQuery<FetchUserWorkspacesWithInvitedCount>(
    FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
  );
  const workspaces: Workspace[] =
    workspacesResponse?.fetchUserWorkspacesWithInvitedCount.slice() || [];
  const entityRef = useRef<T | undefined>(undefined);
  const activeTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const removeActiveTimeout = () => {
    if (activeTimeout.current) {
      clearTimeout(activeTimeout.current);
    }
  };

  const isShared = useMemo(() => {
    const workspaceId = entity
      ? entity.workspaceId
      : entityRef.current
      ? entityRef.current.workspaceId
      : undefined;
    const currentSpace = workspaces?.find((w) => w.id === workspaceId);
    if (currentSpace?.usersCount) {
      return currentSpace?.usersCount > 1 || false;
    }
    return false;
  }, [workspaces, entity]);

  const loadEntity = async (type: CUSTOM_ENTITY_TYPES, entity?: T) => {
    if (!entity || useCheckSharedEntityMoved) {
      return null;
    }
    switch (type) {
      case CUSTOM_ENTITY_TYPES.ACTION:
        return apolloClient
          .query<FetchUserWorkspaceAction>({
            query: FETCH_ACTION,
            variables: {
              actionId: entity.id,
              workspaceId: workspaceId || entity.workspaceId,
            },
            fetchPolicy: 'no-cache',
          })
          .then((data) => data.data.fetchUserWorkspaceAction)
          .catch((e) => null);

      case CUSTOM_ENTITY_TYPES.OUTCOME:
        return apolloClient
          .query<FetchOutcome>({
            query: FETCH_OUTCOME,
            variables: {
              outcomeId: entity.id,
              workspaceId: workspaceId || entity.workspaceId,
            },
            fetchPolicy: 'no-cache',
          })
          .then((data) => data.data.fetchUserWorkspaceOutcome)
          .catch((e) => null);

      case CUSTOM_ENTITY_TYPES.NOTE:
        return apolloClient
          .query<FetchUserWorkspaceNote>({
            query: FETCH_NOTE,
            variables: {
              noteId: entity.id,
              workspaceId: workspaceId || entity.workspaceId,
            },
            fetchPolicy: 'no-cache',
          })
          .then((data) => data.data.fetchUserWorkspaceNote)
          .catch((e) => null);
    }
  };

  useEffect(() => {
    // entity && removeActiveTimeout();
    if (!!entity) {
      entityRef.current = entity;
      removeActiveTimeout();
    }
    if (!isLoading) {
      if (isShared && !entity) {
        if (entityRef.current !== undefined) {
          refetch().then((res) => {
            const isEntitySpaceAvailable = res.data?.fetchUserWorkspacesWithInvitedCount?.some(
              (w) => w.id === entityRef.current?.workspaceId,
            );
            activeTimeout.current = setTimeout(() => {
              loadEntity(entityType, entityRef.current).then(async (entityData) => {
                if (!entityData && !errorMessage && isEntitySpaceAvailable && !shouldStopChecking) {
                  setErrorMessage(CUSTOM_ERROR_MESSAGE.ENTITY_SPACE_CHANGED);
                  setEntityType(entityType);
                  entityRef.current = undefined;
                }
              });
            }, 0);
          });
        }
      }
    }
    return () => {
      removeActiveTimeout();
    };
  }, [entity, isShared]);

  return { isShared };
};

export { useCheckSharedEntityMoved };
