import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as LinkedInIcon } from '../../svg/linkedin.svg';
import { ReactComponent as FacebookIcon } from '../../svg/facebook.svg';
import { ReactComponent as GoogleIcon } from '../../svg/google-plus.svg';
import { ReactComponent as AppleIcon } from '../../svg/apple.svg';
import {
  LINKEDIN_AUTHORIZATION_URL,
  LINKEDIN_CLIENT_ID,
  LINKEDIN_REDIRECT_URI,
} from '../../../../core/integrations/linkedin/config';
import { Button } from 'antd';
import { FirebaseSocialAuthProps } from './types';
import { useAuth } from '../../../../context/authContext';
import { checkPlatform } from '../../../utils/checkPlatform';
import { isWebView } from '../../../utils/checkWebView';
import SamlButton from '../SamlButton/SamlButton';

import { useGoogleLogin } from '@react-oauth/google';
import { firebase } from '../../../../core/integrations/firebase/init';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { SIGN_IN_PATH } from '../../../../core/constants/routePaths';
import { API_BASE_URL } from '../../../../core/integrations/api/config';
import { hash } from '../../../../core/utils/hash';

const StyledContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledButton = styled(({ isGoogle, isFacebook, ...rest }) => <Button {...rest} />)<{
  isGoogle?: boolean;
  isFacebook?: boolean;
}>`
  margin-top: 16px;
  height: 40px;
  position: relative;
  width: 100%;
  border-radius: 4px;
  font-family: var(--font-family-base);
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  font-weight: normal;

  background-color: var(--color-linkedin);
  border: 0;
  color: var(--color-white);

  ${({ isGoogle }) =>
    isGoogle &&
    css`
      background-color: var(--color-white);
      border: 1px solid #a4a6ad;
      color: var(--color-dark-blue);
    `}

  ${({ isFacebook }) =>
    isFacebook &&
    css`
      background-color: var(--color-facebook);
    `}

  &:hover,
  &:focus,
  &:active {
    background-color: var(--color-linkedin);
    color: var(--color-white);

    ${({ isGoogle }) =>
      isGoogle &&
      css`
        background-color: var(--color-white);
        border: 1px solid #a4a6ad;
        color: var(--color-dark-blue);
      `}

    ${({ isFacebook }) =>
      isFacebook &&
      css`
        background-color: var(--color-facebook);
      `}
  }

  svg {
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }
`;

const getState = () => {
  return Math.random().toString(36).substring(7);
};

const getUrl = (state: string) => {
  const SCOPES = 'r_liteprofile r_emailaddress';
  return `${LINKEDIN_AUTHORIZATION_URL}?client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${encodeURIComponent(
    LINKEDIN_REDIRECT_URI,
  )}&response_type=code&state=${state}&scope=${encodeURIComponent(SCOPES)}`;
};

const FirebaseSocialAuth = ({ label }: FirebaseSocialAuthProps) => {
  const { signInWithGoogle, signInWithApple, signInWithFacebook, signIn } = useAuth();
  const nonce = 'apple';

  const isHideFaceBook = isWebView() && checkPlatform() !== 'ios';
  const isRedirectFlow = checkPlatform() === 'ios';

  const signInWithLinkedin = () => {
    const state = getState();
    const url = getUrl(state);
    window.open(url, '_self');
  };

  // Google OAuth2.0 sign in
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');

  const googleLoginWithRedirect = useGoogleLogin({
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: `${API_BASE_URL.slice(0, -5)}${SIGN_IN_PATH}`,
    onSuccess: (tokenResponse) => {
      return tokenResponse;
    },
    onError: (e) => {
      console.log(e);
    },
  });

  // Apple ID sign in
  const isAppleRedirect = searchParams.get('state') === 'apple';
  const appleIdToken = searchParams.get('id_token');

  useEffect(() => {
    // @ts-ignore // TODO: add interface for window
    if (window?.AppleID) {
      hash(nonce).then((resp) => {
        // @ts-ignore
        window?.AppleID?.auth.init({
          clientId: 'com.billionminds.billionminds',
          scope: 'name email',
          // Example 'https://main.app.billionminds.com/api/auth/apple',
          redirectURI: `${API_BASE_URL.slice(0, -5)}/api/auth/apple`,
          state: 'apple',
          nonce: resp,
          usePopup: false,
        });
      });
    }
  }, []);

  useEffect(() => {
    if (isAppleRedirect && appleIdToken && isRedirectFlow) {
      const appleProvider = new firebase.auth.OAuthProvider('apple.com');
      const credential = appleProvider.credential({
        idToken: appleIdToken,
        rawNonce: nonce,
      });
      firebase
        .auth()
        .signInWithCredential(credential)
        .then(async () => {
          const user = firebase.auth().currentUser;
          const token = await user?.getIdToken();
          if (user && token && signIn) {
            signIn(user, token);
          }
        })
        .catch((error) => {
          console.error('==error', error);
        });
    }
    if (code && isRedirectFlow && !isAppleRedirect) {
      axios
        .post(
          `${API_BASE_URL}system/get-google-oauth-tokens`,
          {
            code,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          },
        )
        .then((resp) => {
          const token = resp.data.id_token;
          const credential = firebase.auth.GoogleAuthProvider.credential(token);
          firebase
            .auth()
            .signInWithCredential(credential)
            .then(async () => {
              const user = firebase.auth().currentUser;
              const token = await user?.getIdToken();
              if (user && token && signIn) {
                signIn(user, token);
              }
            })
            .catch((error) => {
              console.log('error', error);
            });
        });
    }
  }, [location.search]);

  return (
    <StyledContainer>
      {isRedirectFlow ? (
        <StyledButton icon={<GoogleIcon />} onClick={googleLoginWithRedirect} isGoogle>
          <span>
            {label} with <b>Google</b>
          </span>
        </StyledButton>
      ) : (
        <StyledButton icon={<GoogleIcon />} onClick={signInWithGoogle} isGoogle>
          <span>
            {label} with <b>Google</b>
          </span>
        </StyledButton>
      )}
      {isRedirectFlow ? (
        <StyledButton
          icon={<AppleIcon />}
          onClick={() => {
            // @ts-ignore
            if (window?.AppleID?.auth) {
              // @ts-ignore
              window?.AppleID?.auth?.signIn();
            }
          }}
          isGoogle
        >
          {label} with <b> Apple</b>
        </StyledButton>
      ) : (
        <StyledButton icon={<AppleIcon />} onClick={signInWithApple} isGoogle>
          <span>
            {label} with <b>Apple</b>
          </span>
        </StyledButton>
      )}
      <StyledButton icon={<LinkedInIcon />} onClick={signInWithLinkedin}>
        <span>
          {label} with <b>LinkedIn</b>
        </span>
      </StyledButton>
      {!isHideFaceBook && (
        <StyledButton icon={<FacebookIcon />} onClick={signInWithFacebook} isFacebook>
          <span>
            {label} with <b>Facebook</b>
          </span>
        </StyledButton>
      )}
      <SamlButton label={label} />
    </StyledContainer>
  );
};

export default FirebaseSocialAuth;
