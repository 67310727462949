import React, { useCallback } from 'react';
import { Menu } from 'antd';
import styled, { css } from 'styled-components';
import { ACTION_PATH, CONTACT_US_PATH, FAQ_PATH } from '../../../../constants/routePaths';
import { ReactComponent as ContactIcon } from '../../../../svg/common/contact.svg';
import { ReactComponent as HelpIcon } from '../../../../svg/common/help.svg';
import { ReactComponent as PassedIcon } from '../../../../svg/common/passed.svg';
import { ReactComponent as NotPassedIcon } from '../../../../svg/common/not_passed.svg';
import { ReactComponent as PodcastIcon } from '../../../../svg/common/podcast.svg';
import { ReactComponent as BlogIcon } from '../../../../svg/common/blog.svg';
import { ReactComponent as ProductivityTipsIcon } from '../../../../svg/common/work-tip.svg';
import { ReactComponent as LinkIcon } from '../../../../svg/common/link.svg';
import { useHistory } from 'react-router-dom';
import { IHeaderHistory } from '../types';
import { InfoMenuProps } from './types';
import { BLOG_LINK, PODCAST_LINK, PRODUCTIVITY_TIPS } from '../../../../constants/links';
import NominateFriend from '../NominateFriend/NominateFriend';
import { useQuery } from '@apollo/client';
import { AUTH_STATE, AuthValue } from '../../../../../apollo/stateFields/auth/authFields';
import {
  TUTORIAL_STATE,
  TutorialValue,
} from '../../../../../apollo/stateFields/tutorial/tutorialField';
import { tutorialMutation } from '../../../../../apollo/stateFields/tutorial';
import { StyledButton } from '../../../../../features/common/components/CompleteOutcome/StyledComponents';
import { recommendMutation } from '../../../../../apollo/stateFields/recommend';
import { theme } from '../../../../styles/styled-components';

const { SubMenu } = Menu;

const StyledSubMenu = styled(({ isHighlight, ...rest }) => <SubMenu {...rest} />)<{
  isHighlight: boolean;
}>``;

const StyledMenu = styled(({ isForHamburgerMenu, isPaddingBottom, ...rest }) => (
  <Menu {...rest} />
))<{ isForHamburgerMenu?: boolean; isPaddingBottom: boolean }>`
  width: 236px;

  ${({ isPaddingBottom }) =>
    isPaddingBottom &&
    css`
      padding-bottom: 6px;
    `}

  .ant-menu-item-selected::after {
    display: none !important;
  }

  .ant-menu-item:first-child {
    margin-top: 4px !important;
  }

  .ant-menu-item {
    padding-left: 12px !important;
    height: 32px !important;
    line-height: 32px !important;
    margin: 0 !important;
    font-size: 14px;
  }

  .ant-menu-item--share {
    margin-top: 7px !important;
    height: 85px !important;
    background-color: transparent !important;

    .ant-menu-title-content {
      height: 100%;
      border-top: 1px solid #e9e9eb;
      align-items: flex-start;
      padding-top: 6px;
    }
  }

  .ant-menu-sub {
    background-color: #fff;

    & > li:nth-last-child(1) {
      margin-bottom: 4px !important;
    }

    .ant-menu-title-content {
      justify-content: space-between;
    }
  }

  .ant-menu-submenu-title {
    padding-top: 4px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: auto;
    margin: 4px 12px;
    border-top: 1px solid #e9e9eb;
    font-size: 14px;
    height: 36px !important;
    line-height: 32px !important;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 1px;
      width: 12px;
      background-color: #000000;
      right: 0;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      height: 12px;
      width: 1px;
      background-color: #000000;
      right: 5.5px;
    }
  }

  .ant-menu-submenu-open {
    .ant-menu-submenu-title {
      &:before {
        content: '';
        display: none;
      }
    }
  }

  .ant-menu-submenu-arrow {
    display: none;
  }

  .ant-menu-title-content {
    width: 100%;
    display: flex;
    align-items: center;

    svg {
      margin-left: -2px;
      margin-right: 12px;
    }
  }

  ${({ isForHamburgerMenu }) =>
    isForHamburgerMenu &&
    css`
      width: 100%;
      padding: 0 1rem;
      background-color: var(--color-main-grey);

      .ant-menu-submenu-title {
        width: auto;
        margin: 0;
        border-top: none;
        font-size: 20px;
        height: 44px !important;
        line-height: 44px !important;
      }

      .ant-menu-sub {
        background-color: var(--color-main-grey);

        & > li:nth-last-child(1) {
          margin-bottom: 0 !important;
        }

        .ant-menu-title-content {
          justify-content: space-between;
        }
      }

      .ant-menu-item {
        padding: 0 !important;
        height: 44px !important;
        line-height: 44px !important;
        margin: 0 !important;
        font-size: 20px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .ant-menu-item--share {
        height: 90px !important;
        margin-bottom: 5px !important;

        .ant-menu-title-content {
          height: 100%;
          align-items: flex-start;
          padding-top: 0;
          position: relative;

          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            height: 1px;
            margin: 0;
            background-color: #20263510;
            width: 100%;
          }
        }
      }
    `}
`;

const StyledHelpIcon = styled(HelpIcon)`
  color: #000000;
`;

const StyledLinkIcon = styled(LinkIcon)`
  position: absolute;
  right: 0;
`;

const StyledArrow = styled.i`
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  margin-right: 1px;
`;

const StyledMenuItemInner = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 0 !important;
  }
`;

const StyledRecommendButton = styled(StyledButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  height: 30px;
  width: 100%;
  border: none;
  background-color: var(--color-dark-blue);
  color: var(--color-white);

  &:hover,
  &:focus {
    background-color: var(--color-dark-blue);
    color: var(--color-white);
  }

  @media ${theme.device.tablet.max} {
    height: 48px;
  }
`;

const StyledRecommendMenuItem = styled(Menu.Item)`
  && {
    .ant-menu-title-content {
      display: flex;
      align-items: center;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      user-select: none;
    }
  }
`;

const steps = ['Actions', 'Outcomes', 'Spaces', 'Incoming', 'Skills Library'];

const InfoMenu = React.memo(({ isForHamburgerMenu, callback }: InfoMenuProps) => {
  const { data } = useQuery(AUTH_STATE);
  const { authUser }: AuthValue = data?.auth;
  const { data: tutorialData } = useQuery(TUTORIAL_STATE);
  const {
    currentStep: currentTutorialStep,
    completedSteps: completedTutorialSteps,
  }: TutorialValue = tutorialData?.tutorial;
  const { setCurrentStep } = tutorialMutation;
  const { setShowRecommendPopup } = recommendMutation;
  const history = useHistory<IHeaderHistory>();

  const goToLink = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history],
  );

  const goToOutside = (href: string) => () => {
    window.open(href, '_blank');
  };

  const goToStep = (step: number) => () => {
    if (callback) {
      callback();
    }
    if (history.location.pathname !== ACTION_PATH) {
      history.push(ACTION_PATH);
    }
    setCurrentStep(step);
  };

  const renderSteps = () => {
    return steps.map((step, index) => (
      <Menu.Item key={step} onClick={goToStep(index)}>
        <StyledMenuItemInner>
          {completedTutorialSteps.includes(index) ? <PassedIcon /> : <NotPassedIcon />}
          <span>{step}</span>
        </StyledMenuItemInner>
        <StyledArrow />
      </Menu.Item>
    ));
  };

  const linksEl = !isForHamburgerMenu && (
    <>
      <Menu.Item key="1" onClick={() => goToLink(CONTACT_US_PATH)}>
        <ContactIcon />
        <span>Contact us</span>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => goToLink(FAQ_PATH)}>
        <StyledHelpIcon />
        <span> F.A.Q.</span>
      </Menu.Item>
      {authUser && (
        <>
          <Menu.Item key="4" onClick={goToOutside(BLOG_LINK)}>
            <BlogIcon />
            <span>Blog</span>
            <StyledLinkIcon />
          </Menu.Item>
          <Menu.Item key="5" onClick={goToOutside(PODCAST_LINK)}>
            <PodcastIcon />
            <span>Podcast</span>
            <StyledLinkIcon />
          </Menu.Item>
          <Menu.Item key="6" onClick={goToOutside(PRODUCTIVITY_TIPS)}>
            <ProductivityTipsIcon />
            <span>Productivity Tips</span>
            <StyledLinkIcon />
          </Menu.Item>
        </>
      )}
    </>
  );

  const shareEl = authUser && (
    <Menu.Item key="7" className="ant-menu-item--share">
      <NominateFriend isSimple={true} />
    </Menu.Item>
  );

  const recommendEl = authUser && (
    <StyledRecommendMenuItem key="8" className="ant-menu-item--share">
      <StyledRecommendButton
        onClick={() => {
          setShowRecommendPopup(true);
          if (callback) {
            callback();
          }
        }}
      >
        Additional Company Benefits
      </StyledRecommendButton>
    </StyledRecommendMenuItem>
  );

  const subMenuEl = authUser && (
    <StyledSubMenu
      key="33"
      title="First Steps"
      data-tutorial="5"
      isHighlight={currentTutorialStep === 5}
    >
      {renderSteps()}
    </StyledSubMenu>
  );

  return (
    <StyledMenu
      isPaddingBottom={!authUser}
      key={currentTutorialStep}
      mode="inline"
      isForHamburgerMenu={isForHamburgerMenu}
    >
      {linksEl}
      {shareEl}
      {recommendEl}
      {subMenuEl}
    </StyledMenu>
  );
});

export default InfoMenu;

