import React from 'react';
import styled from 'styled-components';
import { Button, Drawer } from 'antd';
import { theme } from '../../../core/styles/styled-components';
import { useQuery } from '@apollo/client';
import { STRIPE_STATE, StripeValue } from '../../../apollo/stateFields/stripe/stripeFields';
import { stripeMutation } from '../../../apollo/stateFields/stripe';
import { setShowPurchaseInfoModal } from '../../../apollo/stateFields/stripe/stripeMutation';
import { ReactComponent as SuccessIcon } from '../../img/success.svg';
import { ReactComponent as FailIcon } from '../../img/fail.svg';
import { CloseIcon } from '../../../core/icons';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 100%;
    @media ${theme.device.mobile.min} {
      width: 100%;
    }
    @media ${theme.device.tablet.min} {
      width: 496px;
    }
    @media ${theme.device.desktop.min} {
      width: 496px;
      height: auto;
      top: 0;
      bottom: 0;
    }
  }
  .ant-drawer-content {
  }
  .ant-drawer-body {
    padding: 0;
  }
  h4 {
    font-size: 14px;
  }
  .ant-input,
  textarea.ant-input,
  .ant-picker {
    display: block;
  }
  .ant-input {
    line-height: 32px;
  }
  .ant-divider-horizontal {
    margin: 24px 0;
    &.mainDivider {
      margin-bottom: 0;
      @media ${theme.device.desktop.min} {
        display: none;
      }
    }
  }
`;

const StyledContainer = styled.form`
  position: relative;
  padding: 83px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background: var(--color-white);
  color: var(--color-dark-blue);
`;

const StyledTitle = styled.div`
  margin-top: 40px;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
`;

const StyledSubTitle = styled.div`
  margin-top: 12px;
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
`;

const StyledPurchaseButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  height: 48px;
  width: 100%;
  background-color: transparent;
  border-radius: 36px;
  color: var(--color-dark-blue);
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border-color: var(--color-dark-blue);
`;

export const StyledRemoveButton = styled(Button)`
  min-width: 0;
  width: 26px;
  height: 26px;
  margin: 0 8px;
  padding: 0;
  border-color: transparent;
`;

const StyledCloseButton = styled(StyledRemoveButton)`
  margin: 0;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 99999;
  @media ${theme.device.tablet} {
    top: 20px;
    right: 20px;
  }
  @media ${theme.device.desktop.min} {
    top: 20px;
    right: 20px;
  }
`;

const PurchaseDrawer = () => {
  const { data: stripeData } = useQuery(STRIPE_STATE);
  const { showPurchaseInfoModal, errorMessage }: StripeValue = stripeData?.stripe;
  const { setShowStripeModal, setShowPurchaseInfoModal, setErrorMessage } = stripeMutation;

  const handleNextButtonClick = () => {
    if (showPurchaseInfoModal === 'success') {
      setShowPurchaseInfoModal(null);
    } else {
      setErrorMessage('');
      setShowPurchaseInfoModal(null);
      setShowStripeModal(true);
    }
  };

  const handleClose = () => {
    setShowStripeModal(false);
    setShowPurchaseInfoModal(null);
  };

  return (
    <StyledDrawer
      getContainer={document.body}
      destroyOnClose
      maskClosable
      width=""
      placement="right"
      closable={false}
      onClose={handleClose}
      visible={!!showPurchaseInfoModal}
    >
      {!!showPurchaseInfoModal && (
        <>
          <StyledCloseButton ghost shape="circle" icon={<CloseIcon />} onClick={handleClose} />
          <StyledContainer>
            {showPurchaseInfoModal === 'success' ? <SuccessIcon /> : <FailIcon />}
            <StyledTitle>
              {showPurchaseInfoModal === 'success' ? 'Payment successful!' : 'Unsuccessful '}
            </StyledTitle>
            <StyledSubTitle>
              {showPurchaseInfoModal === 'success'
                ? 'Thank you for using BillionMinds'
                : !!errorMessage
                ? errorMessage
                : 'Your payment was not processed successfully'}
            </StyledSubTitle>
            <StyledPurchaseButton onClick={handleNextButtonClick}>
              {showPurchaseInfoModal === 'success' ? 'Close and continue' : 'Try Again'}
            </StyledPurchaseButton>
          </StyledContainer>
        </>
      )}
    </StyledDrawer>
  );
};

export default PurchaseDrawer;
