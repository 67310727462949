import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { LoaderProps } from './types';

const StyledSpin = styled(({ centered, ...rest }) => <Spin {...rest} />)<{ centered: boolean }>`
  ${(props) =>
    props.centered
      ? `align-items: center;
         bottom: 0;
         display: flex;
         justify-content: center;
         left: 0;
         position: fixed;
         right: 0;
         top: 0;`
      : ''}
`;

const Loader = ({ className, size = 'default', centered = false }: LoaderProps) => (
  <StyledSpin
    centered={centered}
    className={className}
    indicator={<LoadingOutlined />}
    size={size}
  />
);

export { Loader };
