import { gql, makeVar } from '@apollo/client';
import {
  Activity,
  OnboardingQuestions,
  Question,
  UserActivities,
} from '../../../pages/Onboarding/types';

export interface TutorialValue {
  showTutorialVideo: boolean;
  currentStep: number;
  completedSteps: number[];
  userPreferences: Activity[];
  onboardingStep: number;
  onboardingSpaces: Activity;
  onboardingQuestionnaire: Question[];
}

export const tutorialInitialValue: TutorialValue = {
  showTutorialVideo: false,
  currentStep: -1,
  completedSteps: [],
  userPreferences: [],
  onboardingStep: 1,
  onboardingSpaces: {
    title: UserActivities.BOTH,
    roles: [{ defaultSpaceName: 'Self', spaceName: 'Self' }],
  },
  onboardingQuestionnaire: [
    {
      question: OnboardingQuestions.QUESTION_1,
      answer: undefined,
      answerIndex: undefined,
    },
    {
      question: OnboardingQuestions.QUESTION_2,
      answer: undefined,
      answerIndex: undefined,
    },
  ],
};

export const tutorialVar = makeVar<TutorialValue>(tutorialInitialValue);
export const TUTORIAL_STATE = gql`
  query Tutorial {
    tutorial @client
  }
`;
