import React, { useCallback } from 'react';
import styled from 'styled-components';
import { ConfirmModal } from '../../../../common/components/ConfirmModal';
import { useMutation, useQuery } from '@apollo/client';
import { LeaveUserWorkspace } from '../../../types';
import { LEAVE_USER_WORKSPACE } from '../../../graphql/mutations';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../../../../common/graphql/queries';
import { FETCH_ALL_NOTES } from '../../../../actions/graphql/queries';
import { FILTER_USER_WORKSPACES } from '../../../graphql/queries';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../../../apollo/stateFields/filterInput/filterInputFields';
import { filterMutation } from '../../../../../apollo/stateFields/filterInput';
import {
  MANAGE_WORKSPACES_STATE,
  ManageWorkspacesValue,
} from '../../../../../apollo/stateFields/manageWorkspaces/manageWorkspacesField';
import { manageWorkspacesMutation } from '../../../../../apollo/stateFields/manageWorkspaces';

const StyledConfirmModal = styled(ConfirmModal)`
  && {
    .ant-modal-body > div {
      label:last-child {
        margin-top: 20px;
      }
    }
    justify-content: center;
    z-index: 1111;
  }
`;

const StyledMiddleContent = styled.p`
  && {
    text-align: center;
    margin: 0;
  }
`;

const LeaveWorkspaceModal = React.memo(() => {
  const { data: manageWorkspacesData } = useQuery(MANAGE_WORKSPACES_STATE);
  const { workspaceToLeave }: ManageWorkspacesValue = manageWorkspacesData?.manageWorkspaces;
  const { setLeaveWorkspace, setSharedWorkspace } = manageWorkspacesMutation;
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const { toggleFilterInputWorkspace } = filterMutation;

  const [leaveWorkspace] = useMutation<LeaveUserWorkspace>(LEAVE_USER_WORKSPACE);
  const handleLeave = useCallback(async () => {
    workspaceToLeave?.id &&
      filterInput.some((filter) => filter.workspaceId === workspaceToLeave?.id) &&
      toggleFilterInputWorkspace(workspaceToLeave.id);
    await leaveWorkspace({
      variables: {
        workspaceId: workspaceToLeave?.id,
      },
      refetchQueries: !!filterInput.filter((filter) => filter.workspaceId !== workspaceToLeave?.id)
        .length
        ? [
            { query: FETCH_USER_WORKSPACES_WITH_INVITED_COUNT },
            { query: FETCH_ALL_NOTES },
            {
              query: FILTER_USER_WORKSPACES,
              variables: {
                filters: filterInput.filter(
                  (filter) => filter.workspaceId !== workspaceToLeave?.id,
                ),
              },
            },
          ]
        : [{ query: FETCH_USER_WORKSPACES_WITH_INVITED_COUNT }, { query: FETCH_ALL_NOTES }],
    });
    setLeaveWorkspace(null);
    setSharedWorkspace(null);
  }, [leaveWorkspace, workspaceToLeave, filterInput]);

  if (!workspaceToLeave) {
    return null;
  }

  return (
    <>
      <StyledConfirmModal
        mask={false}
        getContainer={() => document.body}
        destroyOnClose
        closable={true}
        visible={!!workspaceToLeave}
        title="Do you want to leave this Space?"
        middleContent={
          <StyledMiddleContent>
            You will need a new invitation to return to this Space
          </StyledMiddleContent>
        }
        confirmText="Leave the Space"
        cancelText={'Cancel'}
        onCancel={() => setLeaveWorkspace(null)}
        onOk={handleLeave}
      />
    </>
  );
});

export default LeaveWorkspaceModal;
