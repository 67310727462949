import React, { useEffect, useRef, useState } from 'react';
import { Button, Progress } from 'antd';
import styled from 'styled-components';
import Timer from './Timer';
import { ReactComponent as TimerIcon } from '../svg/focus-timer.svg';
import { ReactComponent as ClockIcon } from '../svg/clock.svg';
import { ReactComponent as PauseIcon } from '../svg/pause.svg';
import { ReactComponent as ContinueIcon } from '../svg/continue.svg';
import { ReactComponent as CancelIcon } from '../svg/cancel.svg';
import { ReactComponent as FinishIcon } from '../svg/finish.svg';
import { useMutation, useQuery } from '@apollo/client';
import { FETCH_ACTION, FETCH_FOCUS_TIMER } from '../../../../graphql/queries';
import {
  CancelFocusTimer,
  CreateFocusTimer,
  FetchFocusTimer,
  FinishFocusTimer,
  PauseFocusTimer,
  ResumeFocusTimer,
} from '../../../../types';
import { FocusTimerStatus } from './types';
import {
  CANCEL_FOCUS_TIMER,
  FINISH_FOCUS_TIMER,
  PAUSE_FOCUS_TIMER,
  RESUME_FOCUS_TIMER,
  START_FOCUS_TIMER,
} from '../../../../graphql/mutations';
import { Action, ActionStatus } from '../../../../../../core/types/action';
import { getSpendTime } from './utils';
import { boardEditedEntityMutation } from '../../../../../../apollo/stateFields/boardEditedEntity';
// @ts-ignore
import sound from './assets/beep-sound.mp3';
import { FocusTimerType } from './FocusSettingsModal';
import { FetchFocusTimerSettings } from '../../../../../../graphql/types';
import { FETCH_FOCUS_TIMER_SETTINGS } from '../../../../../../graphql/queries';

const StyledFocusTimerWrapper = styled.div`
  width: 100%;
`;
const StyledTimerAndButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 0;
`;

export const StyledButton = styled(Button)<{ isContinue?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 0 6px;
  box-shadow: none;
  font-weight: 400;
  font-size: 9px;
  margin-left: 10px;
  border: 1px solid transparent;
  border-radius: 100px;

  background-color: ${(props) => (props.isContinue ? 'rgba(32, 38, 53, 0.85)' : 'transparent')};
  span {
    font-size: 9px;
    font-weight: 500;
    color: var(--color-white);
  }

  &:hover,
  &:focus {
    background-color: ${(props) => (props.isContinue ? 'rgba(32, 38, 53, 0.85)' : 'transparent')};
    border: 1px solid transparent;
  }

  & > *:first-child {
    margin-right: 5px;
  }
`;

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
`;

const StyledProgress = styled(Progress)<{ withDarkBorder: boolean }>`
  && {
    .ant-progress-inner {
      overflow: visible;
    }
    .ant-progress-bg::after {
      content: '';
      display: block;
      position: absolute;
      right: -3px;
      top: -2px;
      z-index: 1;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #3a444d;
      border: ${(props) =>
        props.withDarkBorder ? '1px solid rgba(32, 38, 53, 0.84)' : '1px solid #57677a'};
    }
  }
`;

const FocusTimer = ({ action }: { action: Action }) => {
  const { data: settingsData } = useQuery<FetchFocusTimerSettings>(FETCH_FOCUS_TIMER_SETTINGS);
  const { data: focusTimerData, refetch } = useQuery<FetchFocusTimer>(FETCH_FOCUS_TIMER);
  const [time, setTime] = useState(Date.now());
  const [startFocusTimer, { loading: startLoading }] =
    useMutation<CreateFocusTimer>(START_FOCUS_TIMER);
  const [cancelFocusTimer, { loading: cancelLoading }] =
    useMutation<CancelFocusTimer>(CANCEL_FOCUS_TIMER);
  const [pauseFocusTimer, { loading: pauseLoading }] =
    useMutation<PauseFocusTimer>(PAUSE_FOCUS_TIMER);
  const [resumeFocusTimer, { loading: resumeLoading }] =
    useMutation<ResumeFocusTimer>(RESUME_FOCUS_TIMER);
  const [finishFocusTimer, { loading: finishLoading }] =
    useMutation<FinishFocusTimer>(FINISH_FOCUS_TIMER);
  const refetchForFinishedTimer = useRef<boolean>(false);
  const { updateFocusTimerCounter } = boardEditedEntityMutation;
  const prevSpendTime = useRef<null | number>(null);
  const periodType = settingsData?.fetchUserSettings?.focusTimerType
    ? settingsData?.fetchUserSettings?.focusTimerType
    : FocusTimerType.FIXED;

  const fixedPeriod =
    periodType === FocusTimerType.FIXED
      ? focusTimerData?.fetchFocusTimer?.fixedPeriodSelected
        ? focusTimerData?.fetchFocusTimer?.fixedPeriodSelected * 1000
        : 1.8e6
      : 8.64e7;

  const spendTime = getSpendTime(
    focusTimerData?.fetchFocusTimer?.startTime,
    focusTimerData?.fetchFocusTimer?.resumeTime,
    fixedPeriod / 1000,
    focusTimerData?.fetchFocusTimer?.spendTime,
    focusTimerData?.fetchFocusTimer?.status,
  );

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (
      !refetchForFinishedTimer.current &&
      spendTime >= fixedPeriod &&
      action.status === ActionStatus.DOING &&
      prevSpendTime?.current &&
      prevSpendTime.current < spendTime
    ) {
      focusTimerData?.fetchFocusTimer?.isBipSound && new Audio(sound).play();
      refetch();
      refetchForFinishedTimer.current = true;
      updateFocusTimerCounter();
    }
  }, [time]);

  useEffect(() => {
    prevSpendTime.current = spendTime;
  }, [time]);

  if (!focusTimerData || action.status !== ActionStatus.DOING) {
    return null;
  }

  const percent = Math.round((spendTime * 100) / fixedPeriod);

  const handleStartOrPause = async () => {
    if (focusTimerData?.fetchFocusTimer?.status === FocusTimerStatus.ACTIVE) {
      if (spendTime < fixedPeriod) {
        await pauseFocusTimer({
          variables: {
            spendTime: Math.round(spendTime / 1000),
          },
          refetchQueries: [FETCH_FOCUS_TIMER],
        });
      } else {
        await finishFocusTimer({
          variables: {
            spendTime: Math.round(spendTime / 1000),
          },
          refetchQueries: [
            FETCH_FOCUS_TIMER,
            {
              query: FETCH_ACTION,
              variables: {
                actionId: action.id,
                workspaceId: action.workspaceId,
              },
            },
          ],
        });
      }
    } else {
      if (focusTimerData?.fetchFocusTimer?.spendTime) {
        await resumeFocusTimer({
          refetchQueries: [FETCH_FOCUS_TIMER],
        });
      } else {
        await startFocusTimer({
          variables: {
            actionId: action.id,
          },
          refetchQueries: [FETCH_FOCUS_TIMER],
        });
      }
    }
  };

  const handleCancel = async () => {
    await cancelFocusTimer({
      refetchQueries: [FETCH_FOCUS_TIMER],
    });
  };
  return (
    <StyledFocusTimerWrapper>
      <StyledTimerAndButtonsWrapper>
        {periodType === FocusTimerType.OPEN ? <ClockIcon /> : <TimerIcon />}
        <Timer timeLeft={fixedPeriod - spendTime} spendTime={spendTime} />
        <StyledButton
          isContinue={focusTimerData?.fetchFocusTimer?.status === FocusTimerStatus.PAUSED}
          onClick={handleStartOrPause}
          loading={startLoading || pauseLoading || resumeLoading || finishLoading}
        >
          <StyledIconWrapper>
            {focusTimerData?.fetchFocusTimer?.status === FocusTimerStatus.ACTIVE ? (
              spendTime >= fixedPeriod ? (
                <FinishIcon />
              ) : (
                <PauseIcon />
              )
            ) : (
              <ContinueIcon />
            )}
          </StyledIconWrapper>
          <span>
            {focusTimerData?.fetchFocusTimer?.status === FocusTimerStatus.ACTIVE
              ? spendTime >= fixedPeriod
                ? 'Finish and save'
                : 'Pause'
              : 'Continue'}
          </span>
        </StyledButton>
        <StyledButton onClick={handleCancel} loading={cancelLoading}>
          <StyledIconWrapper>
            <CancelIcon />
          </StyledIconWrapper>
          <span>Cancel</span>
        </StyledButton>
      </StyledTimerAndButtonsWrapper>
      <StyledProgress
        withDarkBorder={
          focusTimerData?.fetchFocusTimer?.status === FocusTimerStatus.ACTIVE &&
          spendTime <= fixedPeriod
        }
        percent={percent}
        showInfo={false}
        strokeColor={'#BCC2CA'}
        trailColor={'#798594'}
        size="small"
      />
    </StyledFocusTimerWrapper>
  );
};

export default FocusTimer;
