import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Dropdown } from 'antd';
import styled, { css } from 'styled-components';
import theme from '../../../styles/styled-components/theme';
import { IconButton } from '../../common';
import { HamburgerMenu } from './HamburgerMenu';
import { UserMenu } from './UserMenu';
import { ReactComponent as CloseIcon } from '../../../svg/common/close.svg';
import { ReactComponent as Logo } from '../../../svg/common/logo.svg';
import { ReactComponent as MenuIcon } from '../../../svg/common/menu.svg';
import { ReactComponent as LearningIcon } from '../../../svg/common/learning.svg';
import { ReactComponent as ChangesIcon } from '../../../svg/common/changes.svg';
import { ACTION_PATH } from '../../../constants/routePaths';
import { HeaderProps, IHeaderHistory } from './types';
import { SearchBar } from './SearchBar';
import { WorkspaceFilterModal } from 'features/workspaces/components/WorkspaceFilterModal';
import HeaderTagFilter from 'features/workspaces/components/HeaderTagFilter/HeaderTagFilter';
import { useMediaQuery } from 'react-responsive';
import InfoMenu from './InfoMenu/InfoMenu';
import { sendEvent } from '../../../integrations/sentry/events';
import VideoModal from '../../../../features/courses/VideoModal/VideoModal';
import VideoPopup from '../../../../features/courses/VideoPopup/VideoPopup';
import NominateFriend from './NominateFriend/NominateFriend';
import ErrorBoundary from '../../ErrorBoundary';
import { useQuery } from '@apollo/client';
import { AUTH_STATE, AuthValue } from '../../../../apollo/stateFields/auth/authFields';
import { boardEditedEntityMutation } from '../../../../apollo/stateFields/boardEditedEntity';
import { coursesMutation } from '../../../../apollo/stateFields/courses';
import {
  TUTORIAL_STATE,
  TutorialValue,
} from '../../../../apollo/stateFields/tutorial/tutorialField';
import RecommendCompanyDrawer from './RecommendCompanyDrawer/RecommendCompanyDrawer';
import CourseInfo from '../../../../features/courses/CourseInfo/CourseInfo';

const HEADER_HEIGHT = '3.5rem';

const StyledHeader = styled.header<{ isHighlight: boolean; rowCount: number; isThin?: boolean }>`
  --header-height: ${HEADER_HEIGHT};

  min-height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--color-deep-gray);
  ${({ isThin }) =>
    isThin &&
    css`
      --header-height: 70px !important;
    `}

  @media ${theme.device.desktop.min} {
    --header-height: 110px;
    ${({ isThin }) =>
      isThin &&
      css`
        --header-height: 70px !important;
      `}

    ${({ rowCount }) => {
      if (rowCount === 1) {
        return css`--header-height: 60px; !important;`;
      }
    }}
  }

  @media ${theme.device.tablet.max} {
    --header-height: 110px;
    ${({ isThin }) =>
      isThin &&
      css`
        --header-height: 70px !important;
      `}

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    ${({ isHighlight }) =>
      isHighlight &&
      css`
        position: static;
        z-index: initial;
      `}

    ${({ rowCount }) => {
      if (rowCount === 1) {
        return css`
          --header-height: 60px;
        `;
      }
      if (rowCount === 2) {
        return css`
          --header-height: 100px;
        `;
      }
    }}
  }

  @media ${theme.device.mobile.max} {
    --header-height: 110px;
    ${({ isThin }) => isThin && css`--header-height: 68px; !important;`}

    ${({ rowCount }) => {
      if (rowCount === 1) {
        return css`
          --header-height: 60px;
        `;
      }
      if (rowCount === 2) {
        return css`
          --header-height: 100px;
        `;
      }
    }}
  }
`;

const StyledActionButton = styled(Button)`
  width: 24px;
  height: 32px;
  min-width: 24px;
`;

const StyledChangesButton = styled(Button)`
  width: 104px;
  height: 30px;
  background-color: transparent !important;
  border: 1px solid #505a60 !important;
  border-radius: 4px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: white !important;
  padding: 5px 10px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #505a60 !important;
  }

  span {
    padding-left: 4px;
  }

  @media ${theme.device.tablet.max} {
    margin: 0 4px;
  }

  @media ${theme.device.mobile.max} {
    width: 30px;
    padding: 4px 3px;
    border: 1px solid transparent !important;
    margin: 0 4px;
  }
`;

const StyledIconButton = styled(({ isThin, ...rest }) => <IconButton {...rest} />)<{
  isThin?: boolean;
}>`
  width: 30px;
  height: 30px;
  margin-left: ${({ isThin }) => (isThin ? '10px' : '5px')};

  @media ${theme.device.desktop.min} {
    display: none;
    margin-left: ${({ isThin }) => (isThin ? '10px' : '0')};
  }

  @media ${theme.device.mobile.max} {
    margin-left: 0;
  }
`;

const StyledHamburgerMenu = styled(HamburgerMenu)`
  top: ${HEADER_HEIGHT};
`;

const StyledLogo = styled(Logo)`
  display: none;

  @media ${theme.device.desktop.min} {
    display: block;
    margin-right: 32px;
  }
`;

const StyledUserMenu = styled(UserMenu)`
  display: none;

  @media ${theme.device.desktop.min} {
    display: flex;
    align-items: center;
    margin: 0 28px;
  }
`;

const StyledHeaderInner = styled.div<{ rowCount: number; isThin?: boolean }>`
  width: 100%;
  height: 110px;
  display: flex;
  max-width: 1920px;
  flex-direction: column;
  justify-content: space-between;
  ${({ isThin }) =>
    isThin &&
    css`
      height: 70px !important;
      flex-direction: row;
    `}

  ${({ rowCount, isThin }) => {
    if (rowCount === 1 || isThin) {
      return css`
        height: 60px;
        padding: 15px 0 17px 0;
        justify-content: flex-end;
      `;
    }
  }}

  @media ${theme.device.tablet.max} {
    height: 110px;

    ${({ rowCount, isThin }) => {
      if (rowCount === 1) {
        return css`
          height: 60px;
        `;
      }
      if (rowCount === 2 && !isThin) {
        return css`
          padding: 16px 0 12px 0;
          height: 100px;
        `;
      }
    }}
  }

  @media ${theme.device.mobile.max} {
    height: 110px;

    ${({ rowCount, isThin }) => {
      if (rowCount === 1) {
        return css`
          height: 60px;
          padding: 16px 16px 5px 16px;
        `;
      }
      if (rowCount === 2 && !isThin) {
        return css`
          height: 100px;
          padding: 16px 16px 12px 16px;
        `;
      }
    }}
  }
`;

const StyledLearningIcon = styled(LearningIcon)`
  padding: 0;
  color: var(--color-white);
`;

const StyledLearningButton = styled(({ isHighlight, ...rest }) => (
  <StyledActionButton {...rest} />
))<{
  isHighlight: boolean;
}>`
  display: flex;
  align-items: center;

  ${({ isHighlight }) =>
    isHighlight &&
    css`
      z-index: 3001;
      background-color: #586b85;
      cursor: default !important;
    `}
`;

const StyledTutorialLearningButtonWrapper = styled.div<{ isHighlight: boolean }>`
  padding: 5px 8px;
  display: flex;
  align-items: center;

  ${({ isHighlight }) =>
    isHighlight &&
    css`
      z-index: 3001;
      border: 1px dashed #ffffff;
      border-radius: 40px;
      background-color: #586b85;
    `}
`;

const StyledTutorialCourseInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 42px;
`;

const StyledFilterButtonAndTagsContainer = styled.div<{ isHighlight: boolean }>`
  display: flex;
  width: 100%;
  padding: 7px 0;
  border: 1px solid transparent;

  ${({ isHighlight }) =>
    isHighlight &&
    css`
      z-index: 2000;
      border: 1px dashed #ffffff;
      border-radius: 4px;
      background-color: var(--color-deep-gray);

      @media ${theme.device.tablet.max} {
        width: calc(100vw - 36px);
      }
    `}
`;

const StyledDropdownContainer = styled.div<{ isHighlight: boolean }>`
  ${({ isHighlight }) =>
    isHighlight &&
    css`
      .ant-dropdown {
        z-index: 2000 !important;

        .ant-dropdown-arrow {
          display: none;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 10px;
          left: 0;
          right: 0;
          bottom: 40px;
          background-color: rgba(0, 0, 0, 0.45);
          z-index: 3000;
        }
      }
    `}
`;

const StyledHeaderRow = styled.div<{
  isThin?: boolean;
  isUnLogged?: boolean;
  rowNumber?: number;
}>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  @media ${theme.device.tablet.max} {
    ${({ isUnLogged }) =>
      isUnLogged &&
      css`
        justify-content: flex-end;
      `}
  }

  ${({ rowNumber }) => {
    if (rowNumber === 1) {
      return css`
        padding: 10px 24px;

        @media ${theme.device.mobile.max} {
          padding: 10px 8px;
        }
      `;
    }
    if (rowNumber === 2) {
      return css`
        background-color: #2a2f3b;
        min-height: 45px;

        @media ${theme.device.mobile.max} {
          padding: 0 8px;
          background-color: transparent;
        }
      `;
    }
  }}
`;

const StyledFlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledChangesButtonContainer = styled.div`
  display: flex;
  height: 44px;
  align-items: center;
  margin-left: 24px;
`;

const StyledRowInner = styled.div<{ isHighlight: boolean }>`
  ${({ isHighlight }) =>
    isHighlight &&
    css`
      z-index: 2000;
    `}
`;

export const allowedPathnames = ['actions', 'outcomes', 'objectives', 'people'];

const HeaderComponent = ({ className, isThin = false }: HeaderProps) => {
  const { data } = useQuery(AUTH_STATE);
  const { authUser }: AuthValue = data?.auth;
  const { setShowCoursesPopup } = coursesMutation;
  const { setIsNewInformationDrawerOpen } = boardEditedEntityMutation;
  const { data: tutorialData } = useQuery(TUTORIAL_STATE);
  const { currentStep: currentTutorialStep }: TutorialValue = tutorialData?.tutorial;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [toggleClick, setToggleClick] = useState(false);
  const headerRef = useRef<HTMLHeadElement>(null);
  const isDesktop = useMediaQuery({ query: theme.device.desktop.min });
  const isTablet = useMediaQuery({ query: theme.device.tablet.min });
  const isMobile = useMediaQuery({ query: theme.device.mobile.max });
  const history = useHistory<IHeaderHistory>();

  const pathname = history.location.pathname.slice(1);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const handleOpenNewInformation = () => {
    if (history.location.pathname === ACTION_PATH) {
      setIsNewInformationDrawerOpen(true);
    } else {
      history.push(ACTION_PATH);
      setIsNewInformationDrawerOpen(true);
    }
  };

  const handleClickOnHeader = (ev: SyntheticEvent) => {
    const parent = headerRef.current;
    const el = ev.target as Node;

    if (!isDesktop && parent && (parent.contains(el) || el === parent)) {
      setToggleClick((prev) => !prev); // only for tablet and mobile
      if (isMenuOpen) {
        setIsMenuOpen(false);
      }
    }
  };

  const handleChangeVisibleDropdown = (visible: boolean) => {
    setIsDropDownOpen(visible);
  };

  const handleShowCoursesPopup = () => {
    setShowCoursesPopup(true);
    sendEvent('learning_opened', 'Learning opened');
  };

  useEffect(() => {
    if (currentTutorialStep === 5 && !isDesktop) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }, [currentTutorialStep]);

  const getDropdownProps = useCallback(() => {
    if (currentTutorialStep === -1) {
      return {};
    }
    if (currentTutorialStep === 5) {
      return { visible: true };
    }
    return { visible: false };
  }, [currentTutorialStep]);

  const searchbarEl = authUser && (
    <ErrorBoundary isReset>
      <SearchBar clickOutside={toggleClick} />
    </ErrorBoundary>
  );

  const helpEl = isDesktop ? (
    <StyledDropdownContainer isHighlight={currentTutorialStep === 5}>
      <Dropdown
        arrow
        trigger={['click']}
        placement="bottomRight"
        overlay={<InfoMenu />}
        onVisibleChange={handleChangeVisibleDropdown}
        {...getDropdownProps()}
      >
        <StyledActionButton type="text" icon={isDropDownOpen ? <CloseIcon /> : <MenuIcon />} />
      </Dropdown>
    </StyledDropdownContainer>
  ) : (
    <StyledIconButton
      icon={isMenuOpen ? CloseIcon : MenuIcon}
      onClick={() => setIsMenuOpen(!isMenuOpen)}
    />
  );

  const hamburgerMenuEl = (
    <StyledHamburgerMenu visible={isMenuOpen} onClose={closeMenu} onMenuItemClick={closeMenu} />
  );

  const newInformationButtonEl = (
    <StyledChangesButton icon={<ChangesIcon />} onClick={handleOpenNewInformation}>
      {isMobile ? '' : 'Incoming'}
    </StyledChangesButton>
  );

  const courseEl = authUser && (
    <StyledFlexContainer>
      <StyledTutorialLearningButtonWrapper
        isHighlight={currentTutorialStep === 4}
        data-tutorial="4"
      >
        <StyledLearningButton
          disabled={currentTutorialStep === 4}
          isHighlight={currentTutorialStep === 4}
          shape="circle"
          type="text"
          icon={<StyledLearningIcon />}
          onClick={handleShowCoursesPopup}
        />
      </StyledTutorialLearningButtonWrapper>
      <StyledTutorialCourseInfoWrapper>
        <CourseInfo isShort={isThin} />
      </StyledTutorialCourseInfoWrapper>
    </StyledFlexContainer>
  );

  const courseRow = !isDesktop && authUser && (
    <StyledHeaderRow
      style={isThin ? { justifyContent: 'flex-start', padding: isTablet ? '0 16px' : '0' } : {}}
    >
      {courseEl}
    </StyledHeaderRow>
  );

  const getRowCount = () => {
    if (!authUser) {
      return 1;
    } else if (history.location.pathname !== ACTION_PATH) {
      return 2;
    }
    return 3;
  };

  if (isThin) {
    return (
      <StyledHeader
        className={className}
        isThin={isThin}
        onClick={handleClickOnHeader}
        ref={headerRef}
        isHighlight={currentTutorialStep === 2 || currentTutorialStep === 4}
        rowCount={getRowCount()}
      >
        <StyledHeaderInner isThin={isThin} rowCount={getRowCount()}>
          <StyledHeaderRow
            isThin
            isUnLogged={!authUser}
            style={
              !isDesktop
                ? { justifyContent: 'flex-end', width: '15%', padding: isTablet ? '0 16px' : '0' }
                : {}
            }
          >
            <StyledFlexContainer style={{ justifyContent: 'flex-start' }}>
              {isDesktop && <StyledLogo />}
            </StyledFlexContainer>
            <StyledFlexContainer style={{ justifyContent: 'flex-end' }}>
              {searchbarEl}
              {!isMobile && authUser && <NominateFriend />}
              <StyledUserMenu />
              {helpEl}
            </StyledFlexContainer>
          </StyledHeaderRow>
        </StyledHeaderInner>
        {hamburgerMenuEl}
        <VideoModal />
        <VideoPopup />
        <RecommendCompanyDrawer />
      </StyledHeader>
    );
  }

  return (
    <StyledHeader
      className={className}
      onClick={handleClickOnHeader}
      ref={headerRef}
      isHighlight={currentTutorialStep === 2 || currentTutorialStep === 4}
      rowCount={getRowCount()}
    >
      <StyledHeaderInner rowCount={getRowCount()}>
        <StyledHeaderRow isUnLogged={!authUser} rowNumber={1}>
          <StyledFlexContainer>
            <StyledLogo />
            {authUser && courseEl}
          </StyledFlexContainer>
          <StyledFlexContainer style={{ justifyContent: 'flex-end' }}>
            {searchbarEl}
            {isDesktop && authUser && (
              <StyledChangesButtonContainer className="col--fit">
                {newInformationButtonEl}
              </StyledChangesButtonContainer>
            )}
            {!isMobile && authUser && <NominateFriend />}
            {!isDesktop && authUser && newInformationButtonEl}
            <StyledUserMenu />
            {helpEl}
          </StyledFlexContainer>
        </StyledHeaderRow>
        {allowedPathnames.includes(pathname) && (
          <StyledHeaderRow rowNumber={2}>
            <StyledRowInner
              className={'col--fill'}
              isHighlight={currentTutorialStep === 2}
              data-tutorial="2"
            >
              {allowedPathnames.includes(pathname) && (
                <StyledFilterButtonAndTagsContainer isHighlight={currentTutorialStep === 2}>
                  <WorkspaceFilterModal />
                  <HeaderTagFilter />
                </StyledFilterButtonAndTagsContainer>
              )}
            </StyledRowInner>
          </StyledHeaderRow>
        )}
      </StyledHeaderInner>
      {hamburgerMenuEl}
      <VideoModal />
      <VideoPopup />
      <RecommendCompanyDrawer />
    </StyledHeader>
  );
};

export const Header = React.memo(HeaderComponent);
