import { FetchUserWorkspaceActions, UpdateAction } from '../../actions/types';
import { FETCH_ACTIONS } from '../../actions/graphql/queries';
import { Filter, OperationType, updatedCacheData, updateWorkspaceTagsData } from '../../utils';
import { mutateAction } from '../../../context/userWorkspaceContext';
import { ApolloCache, ApolloClient } from '@apollo/client';
import { Action } from '../../../core/types/action';
import { checkActionForComplete } from '../utils';
import { Outcome } from '../../../core/types/outcome';

export const getUpdateFuncForUpdateAction = (
  apolloClient: ApolloClient<any>,
  actionToUpdate: Action,
  filterInput: Filter[],
  setOutcomeToComplete: (value: Outcome | null) => void,
  setOutcomeToIncomplete: (value: Outcome | null) => void,
) => {
  return (cache: ApolloCache<UpdateAction>, { data }: any) => {
    const { fetchUserWorkspaceActions = [] } =
      cache.readQuery<FetchUserWorkspaceActions>({
        query: FETCH_ACTIONS,
        variables: {
          workspaceId: data?.updateUserWorkspaceAction.workspaceId,
        },
      }) || {};

    cache.writeQuery<FetchUserWorkspaceActions>({
      query: FETCH_ACTIONS,
      data: {
        fetchUserWorkspaceActions: updatedCacheData(
          fetchUserWorkspaceActions,
          data?.updateUserWorkspaceAction,
          OperationType.UPDATE,
        ),
      },
      variables: {
        workspaceId: data?.updateUserWorkspaceAction.workspaceId,
      },
    });
    if (apolloClient && data?.updateUserWorkspaceAction) {
      mutateAction(
        apolloClient,
        data?.updateUserWorkspaceAction,
        'update',
        filterInput,
        actionToUpdate,
      );
      updateWorkspaceTagsData(apolloClient, data?.updateUserWorkspaceAction);

      if (data?.updateUserWorkspaceAction?.outcome) {
        checkActionForComplete(
          setOutcomeToComplete,
          setOutcomeToIncomplete,
          data?.updateUserWorkspaceAction,
        );
      }
    }
  };
};
