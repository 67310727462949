import { FetchWorkspaceUserInvitesResult, Permission } from '../../../../core/types/workspace';
import { UserMenuRoles } from './types';
import { capitalize } from 'lodash-es';

export const checkEmail = (email: string) => {
  const isContainCyrillic = /[а-яА-ЯЁё]/.test(email);
  return (
    !isContainCyrillic &&
    !!email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
  );
};

export const checkDomain = (domain: string) => {
  return !!domain.match(/([a-z0-9]+\.)*[a-z0-9]+\.[a-z]+/);
};

enum UserPermission {
  VIEWER,
  MOVER,
  CONTRIBUTOR,
  EDITOR,
  SECONDARY_OWNER,
  PRIMARY_OWNER,
}

export const sortUsersByPermission = (users?: FetchWorkspaceUserInvitesResult[]) => {
  return users?.sort((user1, user2) => {
    if (UserPermission[user2.permission] !== UserPermission[user1.permission]) {
      return UserPermission[user2.permission] - UserPermission[user1.permission];
    } else {
      const value1 = user1.name || user1.email;
      const value2 = user2.name || user2.email;
      return value1.localeCompare(value2);
    }
  });
};

export const getRoleText = (role: string, short?: boolean) => {
  return role === UserMenuRoles.SECONDARY_OWNER
    ? short
      ? 'Sec. Owner'
      : 'Secondary Owner'
    : role === UserMenuRoles.PRIMARY_OWNER
    ? short
      ? 'Prim. Owner'
      : 'Primary Owner'
    : capitalize(role.toLowerCase());
};

export const getMenuOptionsByPermission = (
  permission: Permission,
  currentUserPermission: Permission,
  isCurrent: boolean,
) => {
  const userRole = permission as unknown as UserMenuRoles;
  switch (currentUserPermission) {
    case Permission.VIEWER:
      return isCurrent ? [userRole, UserMenuRoles.LEAVE] : [userRole];
    case Permission.EDITOR:
      return isCurrent
        ? [userRole, UserMenuRoles.LEAVE]
        : userRole === UserMenuRoles.PRIMARY_OWNER
        ? [userRole]
        : [userRole, UserMenuRoles.REMOVE];
    case Permission.PRIMARY_OWNER:
      return isCurrent ? [userRole] : [userRole, UserMenuRoles.REMOVE];
    default:
      return [];
  }
};
