import { gql } from '@apollo/client';

import { WORKSPACE_FIELDS, WORKSPACE_USER_INVITE_FIELDS } from './fragments';

export const CREATE_USER_WORKSPACE = gql`
  mutation CreateUserWorkspace($workspace: NewWorkspaceInput!) {
    createUserWorkspace(workspace: $workspace) {
      ...workspaceFields
    }
  }
  ${WORKSPACE_FIELDS}
`;

export const UPDATE_USER_WORKSPACE = gql`
  mutation UpdateUserWorkspace($workspace: WorkspaceInput!) {
    updateUserWorkspace(workspace: $workspace) {
      id
      ...workspaceFields
    }
  }
  ${WORKSPACE_FIELDS}
`;

export const CREATE_USER_INVITES = gql`
  mutation CreateUserWorkspaceInvites(
    $emails: [String!]!
    $permission: Permission!
    $workspaceId: Int!
  ) {
    createUserWorkspaceInvites(emails: $emails, permission: $permission, workspaceId: $workspaceId)
  }
`;

export const ACCEPT_USER_WORKSPACE_INVITE = gql`
  mutation AcceptUserWorkspaceInvite($workspaceId: Int!) {
    acceptUserWorkspaceInvite(workspaceId: $workspaceId) {
      ...workspaceUserInviteFields
    }
  }
  ${WORKSPACE_USER_INVITE_FIELDS}
`;

export const DECLINE_USER_WORKSPACE_INVITE = gql`
  mutation DeclineUserWorkspaceInvite($workspaceId: Int!) {
    declineUserWorkspaceInvite(workspaceId: $workspaceId)
  }
`;

export const CREATE_USER_WORKSPACES = gql`
  mutation CreateUserWorkspaces($workspaces: [NewWorkspaceInput]!) {
    createUserWorkspaces(workspaces: $workspaces) {
      ...workspaceFields
    }
  }
  ${WORKSPACE_FIELDS}
`;

export const UPDATE_WORKSPACE_RELATION = gql`
  mutation UpdateRelationUserWorkspace(
    $workspaceId: Int!
    $relationUserWokspace: RelationUserWokspaceInput!
  ) {
    updateRelationUserWorkspace(
      workspaceId: $workspaceId
      relationUserWokspace: $relationUserWokspace
    ) {
      ...workspaceFields
    }
  }
  ${WORKSPACE_FIELDS}
`;

export const LEAVE_USER_WORKSPACE = gql`
  mutation LeaveUserWorkspace($workspaceId: Int!) {
    leaveUserWorkspace(workspaceId: $workspaceId)
  }
`;

export const REMOVE_USER_FROM_WORKSPACE = gql`
  mutation RemoveUserFromWorkspace($email: String!, $workspaceId: Int!) {
    removeUserFromWorkspace(email: $email, workspaceId: $workspaceId)
  }
`;
