import React from 'react';
import { CircleIcon } from '../../../../../core/icons';
import { ReactComponent as DoneIcon } from '../../../../../core/svg/outcome/done.svg';
import { StatusSelect } from '../../../../../core/components/form';
import { ActionStatus } from '../../../../../core/types/action';
import { StatusSelectProps } from '../../../../../core/components/form/StatusSelect/types';
import styled from 'styled-components';

export const StyledStatusSelect = styled(StatusSelect)<{ disabled?: boolean }>`
  .ant-select-arrow svg path {
    fill: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.25)' : 'var(--color-dark-blue)')};
  }
`;

const ActionStatusComponent = React.memo(
  ({ ...actionStatusProps }: Omit<StatusSelectProps, 'name' | 'options'>) => {
    return (
      <StyledStatusSelect
        {...actionStatusProps}
        name="status"
        options={[
          {
            label: 'Backlog',
            value: ActionStatus.BACKLOG,
            icon: <CircleIcon />,
          },
          {
            label: 'To do',
            value: ActionStatus.TODO,
            icon: <CircleIcon color="#FFC227" />,
          },
          {
            label: 'Doing',
            value: ActionStatus.DOING,
            icon: <CircleIcon color="#3CBA00" />,
          },
          {
            label: 'Done',
            value: ActionStatus.DONE,
            icon: <DoneIcon />,
          },
        ]}
      />
    );
  },
);

export { ActionStatusComponent };
