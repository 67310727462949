import { ExtendedWorkSpace } from '../ManageWorkSpacesModal/types';

export type WorkspaceItemProps = {
  workspace: ExtendedWorkSpace;
  onSelect: (workspaceId: number) => void;
  onEdit: (workspaceId: number) => () => void;
  onSetDefault: (id: number) => void;
};

export const WorkSpaceMenuOptions = {
  EDIT_WORKSPACE: { label: 'Edit Space' },
  REMOVE_WORKSPACE: { label: 'Remove from the Board' },
  ADD_WORKSPACE: { label: 'Add to the Board' },
  SET_AS_DEFAULT_WORKSPACE: { label: 'Set as default' },
  SHARE: { label: 'Share' },
  LEAVE: { label: 'Leave' },
};
