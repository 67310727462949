import { ActionTimeFormat } from '../../features/actions/components/ActionBoard/types';
import { convertFromMs } from '../../features/actions/components/ActionBoard/utils';
import pluralize from 'pluralize';

export function parseDuration(
  value: number,
  timeFormat: ActionTimeFormat,
): { points: number; type: string; label: string } {
  const points = Math.round(convertFromMs(value * 1000, timeFormat));
  const type = timeFormat.charAt(0).toLowerCase();
  const label = pluralize(timeFormat, points);

  return {
    points,
    type,
    label,
  };
}
//
// export function parseDuration(value: string): {
//   points: string;
//   type: DurationType;
//   label: DurationLabel;
// } {
//   const points = value.match(REGEX_DURATION_POINTS)?.join('') as string;
//   const type = value.match(REGEX_DURATION_TYPE)?.join('') as DurationType;
//   const label = DurationLabelByType[type];
//   return {
//     points,
//     type,
//     label,
//   };
// }
