import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../../../core/components/common/Button';
import { Permission, Workspace } from '../../../../core/types/workspace';
import Fuse from 'fuse.js';

const StyledWrapper = styled.div`
  margin-bottom: 10px;
`;

const StyledFilterByTagButton = styled(Button)<{ isSelected?: boolean }>`
  background-color: transparent;
  color: rgba(32, 38, 53, 0.7);
  font-weight: normal;
  font-size: 14px;
  margin-right: 6px;

  border: 1px solid rgba(32, 38, 53, 0.1);
  border-radius: 4px;
  padding: 3px 6px;
  backdrop-filter: blur(20px);

  ${({ isSelected }) =>
    isSelected &&
    css`
      background: rgba(32, 38, 53, 0.06);
      border: 1px solid rgba(32, 38, 53, 0.1);
      color: var(--color-dark-blue);
    `}
`;

const FilterWorkspaceTagsList = ({
  workspaces,
  filterTags,
  setFilterTags,
  searchText,
  className,
}: {
  workspaces: Workspace[];
  filterTags: Permission[];
  setFilterTags: React.Dispatch<React.SetStateAction<Permission[]>>;
  searchText: string;
  className?: string;
}) => {
  const handleClick = useCallback(
    (value: Permission) => {
      filterTags.some((tag) => tag === value)
        ? setFilterTags((old: Permission[]) => old.filter((oldTag) => oldTag !== value))
        : setFilterTags((old: Permission[]) => [...old, value]);
    },
    [filterTags],
  );

  const searchedWorkspaces = useMemo(() => {
    return !!searchText
      ? new Fuse(workspaces!, { keys: ['name'], threshold: 0.2 })
          .search(searchText)
          .map((w) => ({ ...w.item }))
      : workspaces;
  }, [searchText, workspaces]);

  const viewCount = useMemo(() => {
    return searchedWorkspaces.filter((workspace) => workspace.permission === Permission.VIEWER)
      .length;
  }, [searchedWorkspaces]);

  const ownCount = useMemo(() => {
    return searchedWorkspaces.filter(
      (workspace) => workspace.permission === Permission.PRIMARY_OWNER,
    ).length;
  }, [searchedWorkspaces]);

  const editCount = useMemo(() => {
    return searchedWorkspaces.filter((workspace) => workspace.permission === Permission.EDITOR)
      .length;
  }, [searchedWorkspaces]);

  return (
    <StyledWrapper className={className}>
      <StyledFilterByTagButton
        onClick={() => handleClick(Permission.PRIMARY_OWNER)}
        isSelected={filterTags.some((tag) => tag === Permission.PRIMARY_OWNER)}
      >
        {`I own ${ownCount}`}
      </StyledFilterByTagButton>
      <StyledFilterByTagButton
        onClick={() => handleClick(Permission.EDITOR)}
        isSelected={filterTags.some((tag) => tag === Permission.EDITOR)}
      >
        {`I can edit ${editCount}`}
      </StyledFilterByTagButton>
      <StyledFilterByTagButton
        onClick={() => handleClick(Permission.VIEWER)}
        isSelected={filterTags.some((tag) => tag === Permission.VIEWER)}
      >
        {`I can view ${viewCount}`}
      </StyledFilterByTagButton>
    </StyledWrapper>
  );
};

export default FilterWorkspaceTagsList;
