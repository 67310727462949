import React from 'react';
import { FAQ } from './types';
import styled from 'styled-components';

const StyledOutLink = styled.a`
  font-weight: normal;
  font-size: 1rem;
  line-height: 14px;
  text-decoration: none;
  color: var(--color-main-dark-blue);
`;
const StyledP = styled.p`
  margin-bottom: 0;
`;

const FAQs: FAQ[] = [
  {
    question: 'Is this just a web application?',
    answer: (
      <p>
        In addition to the web application, BillionMinds has a companion app available for (iOS and
        Android). Just search for BillionMinds. In the future, we will update this to a more fully
        featured application, and provide applications for Apple Watch, and integrations into Alexa
        and Google Voice enabled devices. Wherever you are, we can be there with you!
      </p>
    ),
  },
  {
    question: 'I want to become more expert at optimizing my day, can you help me?',
    answer: (
      <p>
        Absolutely! Our Mastering Personal Effectiveness Course accomplishes just that. Specifically
        it helps you take control of your day, find meaning in your day, integrate work with the
        rest of your life, and thrive amidst uncertainty. You can order it right now{' '}
        <StyledOutLink
          target="_blank"
          href={'https://effectiveness.me/get-started/ols/products/theelements'}
        >
          here
        </StyledOutLink>
        .
        <br />
        <br />
        In addition we have a podcast -{' '}
        <StyledOutLink target="_blank" href={'https://player.fm/podcasts/billionminds'}>
          Way Too Busy
        </StyledOutLink>
        , and are on most social media platforms. Find us at :
        <ul>
          <li>
            <StyledOutLink target="_blank" href="https://www.facebook.com/risingbillion">
              Facebook
            </StyledOutLink>
          </li>
          <li>
            <StyledOutLink target="_blank" href="https://www.twitter.com/risingbillion">
              Twitter
            </StyledOutLink>
          </li>
          <li>
            <StyledOutLink target="_blank" href="https://www.linkedin.com/company/billionminds">
              LinkedIn
            </StyledOutLink>
          </li>
        </ul>
      </p>
    ),
  },
  {
    question: 'I have an issue with BillionMinds and need some support. What should I do?',
    answer: (
      <p>
        Please bear with us, as we are pre-release, but you still need support, and you will get it!
        Just e-mail us at{' '}
        <StyledOutLink href={'mailto:support@billionminds.com'}>
          support@billionminds.com
        </StyledOutLink>
        .
      </p>
    ),
  },
  {
    question: 'I have a feature idea or request. Where can I submit it?',
    answer: (
      <p>
        We want to hear every idea. Just e-mail us at{' '}
        <StyledOutLink href={'mailto:ideas@billionminds.com'}>ideas@billionminds.com</StyledOutLink>
        .
      </p>
    ),
  },
  {
    question: 'I love what you are doing, and want to join you. How can I help?',
    answer: (
      <p>
        We aren’t hiring right now, but we will be. Send us your details at{' '}
        <StyledOutLink href={'mailto:jobs@billionminds.com'}>jobs@billionminds.com</StyledOutLink>.
        Don’t worry if you don’t think you have the right qualifications, if you are passionate
        about making people more personally effective, we want to hear from you. If you want to help
        us in a more informal way e-mail us at{' '}
        <StyledOutLink href={'mailto:community@billionminds.com'}>
          community@billionminds.com
        </StyledOutLink>
        .
      </p>
    ),
  },
  {
    question: 'How do I follow you on social media?',
    answer: (
      <>
        <p style={{ marginBottom: '10px' }}>You can find us at:</p>
        <ul>
          <li>
            <StyledOutLink target="_blank" href="https://www.facebook.com/risingbillion">
              Facebook
            </StyledOutLink>
          </li>
          <li>
            <StyledOutLink target="_blank" href="https://www.twitter.com/risingbillion">
              Twitter
            </StyledOutLink>
          </li>
          <li>
            <StyledOutLink target="_blank" href="https://www.linkedin.com/company/billionminds">
              LinkedIn
            </StyledOutLink>
          </li>
        </ul>
      </>
    ),
  },
];

export default FAQs;
