import { highlightedEntitiesInitialValue, highlightedEntitiesVar } from './highlightedEntityField';

const resetHighlightedEntities = () => {
  const value = {
    ...highlightedEntitiesInitialValue,
  };
  highlightedEntitiesVar(value);
};

const setHoveredOutcomeId = (payload: null | number) => {
  const value = {
    ...highlightedEntitiesVar(),
    hoveredOutcomeId: payload,
  };
  highlightedEntitiesVar(value);
};

export { setHoveredOutcomeId, resetHighlightedEntities };
