import { gql, makeVar } from '@apollo/client';
import { CoursesVideo, SectionName } from '../../../graphql/types';

export enum CoursesTitle {
  Foundations = 'Foundations',
  MasteringPersonalEffectiveness = 'MasteringPersonalEffectiveness',
  Fundamentals = 'Fundamentals',
  RemoteWorkProfessional = 'RemoteWorkProfessional',
  FundamentalsManager = 'FundamentalsManager',
  RemoteWorkProfessionalManager = 'RemoteWorkProfessionalManager',
}

export type FirstCourseTitle = CoursesTitle.Fundamentals | CoursesTitle.Foundations | CoursesTitle.FundamentalsManager;
export type SecondCourseTitle =
  | CoursesTitle.MasteringPersonalEffectiveness
  | CoursesTitle.RemoteWorkProfessional | CoursesTitle.RemoteWorkProfessionalManager

export type CourseOperation = 'remove' | 'add';

export interface ActiveVideoInfo {
  video: CoursesVideo;
  courseTitle: string;
}

export interface CoursesFields {
  showCoursesPopup: boolean;
  activeVideo: ActiveVideoInfo | null;
  canceledNotificationVideo: ActiveVideoInfo | null;
  timezone: string;
  tutorialStep: number;
  tipsSection: null | SectionName;
  expandCards: CoursesTitle[];
  expandSections: SectionName[];
  firstCourseTitle: CoursesTitle;
  secondCourseTitle: CoursesTitle;
}

export const coursesInitialValue: CoursesFields = {
  showCoursesPopup: false,
  activeVideo: null,
  canceledNotificationVideo: null,
  timezone: '',
  tutorialStep: -1,
  tipsSection: null,
  expandCards: [],
  expandSections: [],
  firstCourseTitle: CoursesTitle.Foundations,
  secondCourseTitle: CoursesTitle.MasteringPersonalEffectiveness,
};

export const coursesVar = makeVar<CoursesFields>(coursesInitialValue);
export const COURSES_STATE = gql`
  query Courses {
    courses @client
  }
`;
