import { gql, makeVar } from '@apollo/client';
import { FetchWorkspaceUserInvitesResult, Workspace } from '../../../core/types/workspace';

export interface ManageWorkspacesValue {
  workspaceToShare: Workspace | null;
  workspaceToLeave: Workspace | null;
  userToRemove: FetchWorkspaceUserInvitesResult | null;
  isManageWorkspacesModalVisible: boolean;
  editedWorkspaceId: number | null;
}

export const manageWorkspacesInitialValue: ManageWorkspacesValue = {
  workspaceToShare: null,
  workspaceToLeave: null,
  userToRemove: null,
  isManageWorkspacesModalVisible: false,
  editedWorkspaceId: null,
};

export const manageWorkspacesVar = makeVar<ManageWorkspacesValue>(manageWorkspacesInitialValue);
export const MANAGE_WORKSPACES_STATE = gql`
  query ManageWorkspaces {
    manageWorkspaces @client
  }
`;
