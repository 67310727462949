import { gql, makeVar } from '@apollo/client';

export interface UtilsValue {
  sourceDragStartColumn: string | null;
}

export const utilsInitialValue: UtilsValue = {
  sourceDragStartColumn: null,
};

export const utilsVar = makeVar<UtilsValue>(utilsInitialValue);
export const UTILS_STATE = gql`
  query Utils {
    utils @client
  }
`;
