import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Input, Tooltip } from 'antd';
import { ReactComponent as SamlIcon } from '../../svg/saml.svg';
import { ReactComponent as InfoIcon } from '../../svg/info.svg';
import { useLocation, useParams } from 'react-router-dom';
import { ACCESS_SIGN_UP_PATH, SIGN_IN_PATH } from '../../../../core/constants/routePaths';
import { useAuth } from '../../../../context/authContext';
import { PasswordRecoveryParams } from '../PasswordRecovery/types';

const StyledContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
`;
const InputWrapper = styled.div<{ isShow: boolean }>`
  width: 100%;
  opacity: 0;
  height: 0;

  transition: all ease-in-out 0.4s;
  ${({ isShow }) =>
    isShow &&
    css`
      opacity: 1;
      height: auto;
    `}
`;
const StyledInfo = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 4px;
`;
const StyledInput = styled(Input)`
  margin-bottom: 8px;
`;

const StyledButton = styled(Button)`
  height: 40px;
  position: relative;
  width: 100%;
  border-radius: 4px;
  font-family: var(--font-family-base);
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  font-weight: normal;

  background-color: var(--color-dark-blue);
  border: 0;
  color: var(--color-white);

  &:hover,
  &:focus,
  &:active {
    background-color: var(--color-dark-blue);
    color: var(--color-white);
  }

  &:disabled,
  &:disabled:hover {
    background-color: rgba(32, 38, 53, 0.8);
    color: var(--color-white);
  }

  svg {
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }
`;

const SamlButton = ({ label }: { label: string }) => {
  const { signInWithSaml } = useAuth();
  const [showInput, setShowInput] = useState<boolean>(false);
  const location = useLocation();
  const match = location.pathname === ACCESS_SIGN_UP_PATH || location.pathname === SIGN_IN_PATH;
  const query = new URLSearchParams(location.search);
  const initialSlug = query.get('company') || 'Company Code';
  const [slug, setSlug] = useState<string>(initialSlug);

  const isDisabled = showInput && !slug.length;
  const handleBtnClick = () => {
    if (!showInput) {
      setShowInput(true);
    } else {
      signInWithSaml && slug.length && signInWithSaml(slug);
    }
  };

  if (!match) {
    return null;
  }

  return (
    <StyledContainer>
      <InputWrapper isShow={showInput}>
        <StyledInfo>
          Company Code
          <Tooltip
            color="#fff"
            title={() => (
              <span style={{ color: 'var(--color-dark-blue)' }}>
                You should have received this by e-mail but if you do not know it, reach out to us
                at "support@billionminds.com"
              </span>
            )}
          >
            <InfoIcon style={{ marginLeft: '4px' }} />
          </Tooltip>
        </StyledInfo>
        <StyledInput
          size={'large'}
          placeholder="my-team"
          value={slug}
          onChange={(e) => {
            setSlug(e.target.value);
          }}
        />
      </InputWrapper>
      <StyledButton
        loading={false}
        disabled={isDisabled}
        icon={<SamlIcon />}
        onClick={handleBtnClick}
      >
        <span>
          {label} with <b>Company Account</b>
        </span>
      </StyledButton>
    </StyledContainer>
  );
};

export default SamlButton;
