import React, { useCallback, useEffect, useState } from 'react';
import { Input } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import { FastField } from 'formik';
import { InputProps } from 'antd/lib/input/Input';
import { TextAreaProps } from 'antd/lib/input/TextArea';
import styled from 'styled-components';
import { DatePickerField } from '../../components/form/DatePickerField';

export const StyledTextArea = styled(Input.TextArea)<{ disabled?: boolean }>`
  resize: ${(props) => (props.disabled ? 'none' : 'vertical')};
`;

export const withTextFastField = (Component: (props: InputProps) => JSX.Element) => {
  return (props: InputProps) => {
    return <FastField name={props.name}>{() => <Component {...props} />}</FastField>;
  };
};

export const withAreaFastField = (Component: (props: TextAreaProps) => JSX.Element) => {
  return (props: TextAreaProps) => {
    return <FastField name={props.name}>{() => <Component {...props} />}</FastField>;
  };
};

export const DebouncedText = (props: InputProps): JSX.Element => {
  const [innerValue, setInnerValue] = useState(props.value);

  useEffect(() => {
    if (props.value) {
      setInnerValue(props.value as string);
    } else {
      setInnerValue('');
    }
  }, [props.value]);

  const debouncedHandleOnChange = useDebouncedCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onChange) {
        props.onChange(event);
      }
    },
    300,
  );

  const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    const newValue = event.currentTarget.value;
    setInnerValue(newValue);
    debouncedHandleOnChange(event);
  }, []);

  return <Input {...props} value={innerValue} onChange={handleOnChange} />;
};

export const DebouncedTextArea = (props: TextAreaProps): JSX.Element => {
  const [innerValue, setInnerValue] = useState(props.value);

  useEffect(() => {
    if (props.value) {
      setInnerValue(props.value as string);
    } else {
      setInnerValue('');
    }
  }, [props.value]);

  const debouncedHandleOnChange = useDebouncedCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (props.onChange) {
        props.onChange(event);
      }
    },
    300,
  );

  const handleOnChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.persist();

    const newValue = event.currentTarget.value;
    setInnerValue(newValue);
    debouncedHandleOnChange(event);
  }, []);

  return <StyledTextArea {...props} value={innerValue} onChange={handleOnChange} />;
};

export const DebouncedTextInput = withTextFastField(DebouncedText);
export const DebouncedTextAreaInput = withAreaFastField(DebouncedTextArea);
