import React, { ChangeEvent, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Input, Radio } from 'antd';
import { theme } from '../../../core/styles/styled-components';
import { OnboardingAnswers } from '../types';

interface QuestionnaireButtonProps {
  answer: string | undefined;
  selectedAnswer: string | undefined;
  setAnswer: (value?: string) => void;
  isChangeable?: boolean;
  className?: string;
  index: number;
}

const StyledRadioButtonWrapper = styled.div<{ isChecked?: boolean }>`
  width: 100%;
  min-height: 56px;
  padding: 0 15px 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: rgba(32, 38, 53, 0.01);
  border: 1px solid rgba(32, 38, 53, 0.1);
  border-radius: 16px;
  cursor: pointer;

  span.ant-radio + * {
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-top: 3px;
    margin-left: 8px;
    padding: 16px;

    ${({ isChecked }) =>
      isChecked &&
      css`
        font-weight: 700;
      `}
  }

  ${({ isChecked }) =>
    isChecked &&
    css`
      background: #ffffff;
      box-shadow: 9px 8px 53px #eae3e3;
    `}

  @media ${theme.device.mobile.max} {
    min-height: 64px;
  }
`;

const StyledRadioButton = styled(Radio)`
  display: flex;
  align-items: center;

  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  width: 100%;

  span.ant-radio-inner {
    width: 20px;
    height: 20px;

    &:after {
      width: 10px;
      height: 10px;
      top: 4px;
      left: 4px;
    }
  }
`;

const StyledInput = styled(Input)<{ isChecked?: boolean }>`
  && {
    padding-left: 0;
    margin: 0;
    width: 100%;
    background-color: transparent;

    font-size: 20px !important;
    line-height: 24px;

    ${({ isChecked }) =>
      isChecked &&
      css`
        font-weight: 700;
      `}
  }
`;

const QuestionnaireButton = ({
  className,
  answer,
  setAnswer,
  selectedAnswer,
  isChangeable,
  index,
}: QuestionnaireButtonProps) => {
  const [value, setValue] = useState<string | undefined>(answer);
  const inputRef = useRef<any>(null);

  const handleSave = () => {
    if (isChangeable) {
      setValue(value?.length ? value : OnboardingAnswers.ANSWER_4);
      setAnswer(value?.length ? value : OnboardingAnswers.ANSWER_4);
    } else {
      setAnswer();
    }
  };

  return (
    <StyledRadioButtonWrapper
      className={className}
      isChecked={answer === selectedAnswer}
      onClick={() => {
        isChangeable && inputRef.current && inputRef.current.focus();
        handleSave();
      }}
    >
      <StyledRadioButton value={index}>
        {isChangeable ? (
          <StyledInput
            isChecked={answer === selectedAnswer}
            ref={inputRef}
            maxLength={100}
            size={'large'}
            onBlur={() => {
              handleSave();
            }}
            value={value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setValue(e.target.value);
            }}
            onPressEnter={(e) => {
              handleSave();
              // @ts-ignore
              e.target.blur();
            }}
          />
        ) : (
          answer
        )}
      </StyledRadioButton>
    </StyledRadioButtonWrapper>
  );
};

export default QuestionnaireButton;
