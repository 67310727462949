import { gql } from '@apollo/client';

import { OUTCOME_FIELDS, OUTCOME_FIELDS_UNARCHIVE } from './fragments';

export const CREATE_OUTCOME = gql`
  mutation CreateOutcome($outcomeValues: NewOutcomeInput!, $workspaceId: Int = -1) {
    createUserWorkspaceOutcome(outcome: $outcomeValues, workspaceId: $workspaceId) {
      ...outcomeFields
    }
  }

  ${OUTCOME_FIELDS}
`;

export const UPDATE_OUTCOME = gql`
  mutation UpdateOutcome(
    $outcomeValues: OutcomeInput!
    $workspaceId: Int = -1
    $targetWorkspaceId: Int
  ) {
    updateUserWorkspaceOutcome(
      outcome: $outcomeValues
      workspaceId: $workspaceId
      targetWorkspaceId: $targetWorkspaceId
    ) {
      isAllActionsDone
      ...outcomeFields
    }
  }

  ${OUTCOME_FIELDS}
`;

export const ARCHIVE_OUTCOME = gql`
  mutation ArchiveOutcome($workspaceId: Int!, $outcomeId: Int!, $fullArchive: Boolean!) {
    archiveUserWorkspaceOutcome(
      outcomeId: $outcomeId
      workspaceId: $workspaceId
      fullArchive: $fullArchive
    ) {
      ...outcomeFields
    }
  }

  ${OUTCOME_FIELDS}
`;

export const UNARCHIVE_OUTCOME = gql`
  mutation UnarchiveOutcome($workspaceId: Int!, $outcomeId: Int!) {
    unarchiveUserWorkspaceOutcome(outcomeId: $outcomeId, workspaceId: $workspaceId) {
      ...outcomeFieldsUnarchive
    }
  }

  ${OUTCOME_FIELDS_UNARCHIVE}
`;

export const MOVE_OUTCOME = gql`
  mutation MoveOutcome(
    $outcomeValues: OutcomeInput!
    $workspaceId: Int = -1
    $targetWorkspaceId: Int = -1
  ) {
    moveUserWorkspaceOutcome(
      outcome: $outcomeValues
      workspaceId: $workspaceId
      targetWorkspaceId: $targetWorkspaceId
    ) {
      ...outcomeFields
      isAllActionsDone
    }
  }
  ${OUTCOME_FIELDS}
`;

export const DELETE_OUTCOME = gql`
  mutation DeleteOutcome($outcomeValues: OutcomeInput!, $workspaceId: Int = -1) {
    deleteUserWorkspaceOutcome(outcome: $outcomeValues, workspaceId: $workspaceId)
  }
`;
