export const getColumnHeight = (
  isMobile: boolean,
  isTablet: boolean,
  isDesktop: boolean,
  isAdding: boolean,
  entityName: string,
) => {
  let height = entityName === 'Outcome' ? 260 : 245;
  if (entityName === 'Note') {
    return isMobile
      ? document.body.getBoundingClientRect().height - 280
      : isTablet
      ? document.body.getBoundingClientRect().height - 300
      : document.body.getBoundingClientRect().height - 440;
  }
  if (isMobile) {
    height -= 10;
    return document.body.getBoundingClientRect().height - (isAdding ? height + 120 : height);
  }
  if (isTablet) {
    height += 10;
    return document.body.getBoundingClientRect().height - (isAdding ? height + 120 : height);
  }
  if (isDesktop) {
    height -= 25;
    return document.body.getBoundingClientRect().height - (isAdding ? height + 120 : height);
  }

  return document.body.getBoundingClientRect().height - (isAdding ? height + 120 : height);
};
