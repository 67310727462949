import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash-es';
import * as Yup from 'yup';
import { Button, Card } from 'core/components/common';
import { TextAreaField } from 'core/components/form';
import { Entity } from 'core/types/entity';
import { CreateEntityFormCardProps } from './types';
import useOutsideClick from '../../../../core/hooks/useOutsideClick';
import WorkspaceSelectMenu from '../WorkspaceSelectMenu/WorkspaceSelectMenu';
import theme from '../../../../core/styles/styled-components/theme';
import { EntityName } from '../../../utils';
import { useQuery } from '@apollo/client';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../../graphql/queries';
import { FetchUserWorkspacesWithInvitedCount } from '../../../workspaces/types';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../apollo/stateFields/userSettings/userSettingsField';

const ENTITY_NAME_KEY = 'name';

const ENTITY_NAME_MAX_CHAR_NUMBER = 256;

const TEXT_AREA_CLASS_NAME = 'create-entity-form__entity-name';

const StyledCard = styled(Card)`
  min-height: 112px;
`;

const StyledForm = styled(Form)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StyledContent = styled.div`
  flex: 1;
  display: flex;
`;

const StyledTextAreaField = styled(TextAreaField).attrs({
  textAreaClassName: TEXT_AREA_CLASS_NAME,
})`
  flex: 1;
  display: flex;
  padding-bottom: 0.278rem;

  .${TEXT_AREA_CLASS_NAME} {
    flex: 1;
    padding: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    font-size: 0.667rem;
    font-weight: 600;
    resize: none;
    line-height: 1;

    &::placeholder {
      font-weight: normal;
      font-size: 14px;
      color: #a3a6ad;
    }
  }

  &::-webkit-input-placeholder {
    color: #0bf;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: #0bf;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #0bf;
  }

  &:-ms-input-placeholder {
    color: #0bf;
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  padding: 15px 8px 10px;
  border-top: 1px solid rgba(32, 38, 53, 0.1);
`;

const StyledButton = styled(Button)`
  height: 20px;
  font-size: 12px;
`;

const StyledSubmitButton = styled(StyledButton)`
  margin-right: 8px;
  white-space: nowrap;
  padding: 8px 12px;
`;

const StyledCancelButton = styled(StyledButton)`
  padding: 0;
  border: 0;
`;

const StyledPreposition = styled.span`
  margin-right: 0.278rem;
  color: var(--color-grey);
  font-size: 0.5rem;
`;

const StyledActionWorkspace = styled.span<{ isShared?: boolean }>`
  margin-right: 2px;
  padding: 1px 3px;
  background-color: ${(props) =>
    props.isShared ? 'rgba(255, 194, 39, 0.4)' : 'rgba(177, 190, 249, 0.4)'};
  color: ${(props) => (props.isShared ? '#4A3602' : '#102DBC')};
  border-radius: 2px;
  font-size: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${theme.device.mobile.min} {
    max-width: 140px;
  }

  @media ${theme.device.tablet.min} {
    max-width: 170px;
  }

  @media ${theme.device.desktop.min} {
    max-width: 190px;
  }
`;

const StyledHeadContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 6px;
`;

const CreateEntityFormCard = ({
  onSubmit,
  onCancel,
  entityName,
  closeForm,
  isShowWorkspaceSelect = false,
  isCancelForClear,
  addButtonRef,
  isDefaultWorkspace,
  placeholder = '',
  initWorkspaceId,
  onFocus,
  disabled,
}: CreateEntityFormCardProps) => {
  const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
  const { defaultWorkspaceId, unassignedWorkspaceId }: UserSettingsValue =
    userSettingsData?.userSettingsField;
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const formRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState<string | undefined>(undefined);

  useOutsideClick(
    formRef,
    value,
    () => {
      closeForm && closeForm(!!value);
    },
    addButtonRef || formRef,
  );

  useEffect(() => {
    setTimeout(() => {
      if (textAreaRef.current) {
        textAreaRef.current.focus();
      }
    }, 1000);
  }, []);

  useEffect(() => {
    if (onFocus !== undefined) {
      if (textAreaRef.current) {
        textAreaRef.current.focus();
      }
    }
  }, [onFocus]);

  const isForCreate =
    (entityName === EntityName.ACTION && isShowWorkspaceSelect) ||
    (entityName === EntityName.OUTCOME && isShowWorkspaceSelect) ||
    isShowWorkspaceSelect;

  const createButtonLabel = entityName === EntityName.NOTE ? '' : entityName;

  const getInitWorkspaceId = useMemo(() => {
    if (initWorkspaceId) {
      return initWorkspaceId;
    }

    return entityName === EntityName.NOTE && !isDefaultWorkspace
      ? unassignedWorkspaceId
      : defaultWorkspaceId;
  }, [initWorkspaceId, entityName, isDefaultWorkspace, unassignedWorkspaceId, defaultWorkspaceId]);

  return (
    <div ref={formRef}>
      <Formik<Partial<Entity>>
        validateOnMount
        enableReinitialize
        initialValues={{
          [ENTITY_NAME_KEY]: '',
          workspaceId: getInitWorkspaceId,
        }}
        validationSchema={Yup.object({
          [ENTITY_NAME_KEY]: Yup.string().max(ENTITY_NAME_MAX_CHAR_NUMBER).required(),
          workspaceId: Yup.number().required(),
        })}
        onSubmit={onSubmit}
      >
        {({ errors, isSubmitting, handleSubmit, values, setFieldValue }) => {
          const onHandleSubmit = async () => {
            handleSubmit();
          };

          const { data: workspacesResponse } = useQuery<FetchUserWorkspacesWithInvitedCount>(
            FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
          );
          const workspaces = workspacesResponse?.fetchUserWorkspacesWithInvitedCount.slice() || [];
          const isShared = useMemo(() => {
            const entityWorkspace = workspaces.find(
              (workspace) => workspace?.id === values?.workspaceId,
            );
            return !!entityWorkspace?.usersCount && entityWorkspace?.usersCount > 1;
          }, [workspaces, values]);

          useEffect(() => {
            setValue && setValue(values[ENTITY_NAME_KEY]);
          }, [values[ENTITY_NAME_KEY]]);

          useEffect(() => {
            if (!isSubmitting) {
              setFieldValue(ENTITY_NAME_KEY, '');

              if (textAreaRef.current) {
                textAreaRef.current.focus();
              }
            }
          }, [isSubmitting]);

          return (
            <StyledCard>
              <StyledForm>
                {isForCreate && values.workspaceId && (
                  <StyledHeadContainer>
                    <StyledPreposition>in</StyledPreposition>
                    <WorkspaceSelectMenu
                      isForCreate={isForCreate}
                      currentWorkspaceId={values.workspaceId}
                      renderChildren={(workspaceName: string) => (
                        <StyledActionWorkspace isShared={isShared}>
                          {workspaceName}
                        </StyledActionWorkspace>
                      )}
                      onContextMenuOptionClick={async (option) => {
                        setFieldValue('workspaceId', option.value);
                      }}
                    />
                  </StyledHeadContainer>
                )}
                <StyledContent>
                  <StyledTextAreaField
                    placeholder={placeholder}
                    disabled={disabled}
                    autoFocus={!disabled}
                    ref={textAreaRef}
                    name={ENTITY_NAME_KEY}
                    maxLength={ENTITY_NAME_MAX_CHAR_NUMBER}
                    onPressEnter={onHandleSubmit}
                  />
                </StyledContent>
                <StyledButtonContainer>
                  <StyledSubmitButton
                    isDisabled={!isEmpty(errors) || disabled}
                    isLoading={isSubmitting}
                    htmlType="button"
                    onClick={onHandleSubmit}
                  >
                    Create {createButtonLabel}
                  </StyledSubmitButton>
                  <StyledCancelButton
                    isSecondary
                    onClick={() => {
                      if (isCancelForClear) {
                        setFieldValue(ENTITY_NAME_KEY, '');
                      } else {
                        onCancel && onCancel();
                      }
                    }}
                  >
                    Cancel
                  </StyledCancelButton>
                </StyledButtonContainer>
              </StyledForm>
            </StyledCard>
          );
        }}
      </Formik>
    </div>
  );
};

export { CreateEntityFormCard };
