import { ColumnType, DndActions, DndActionTypes, DndContextType } from './types';
import { OutcomeStatus } from '../../core/types/outcome';
import { mapValues, uniq, pullAll } from 'lodash';

export const dndInitialValue: DndContextType = {
  boardColumns: {
    outcomeColumn: { id: 0, title: ColumnType.Outcome, entityIds: [] },
    backlogColumn: { id: 1, title: ColumnType.Backlog, entityIds: [] },
    toDoColumn: { id: 2, title: ColumnType.Todo, entityIds: [] },
    doingColumn: { id: 3, title: ColumnType.Doing, entityIds: [] },
    doneColumn: { id: 4, title: ColumnType.Done, entityIds: [] },
    noteColumn: { id: 5, title: ColumnType.Note, entityIds: [] },
  },
  currentOutcomeColumnStatus: OutcomeStatus.CURRENT,
  isUpdated: false,
  changingSpaceEntityId: null,
  fetchAllLoading: true,
};

const dndReducer = (state: DndContextType = dndInitialValue, action: DndActions) => {
  switch (action.type) {
    case DndActionTypes.SetCurrentOutcomeColumnStatus:
      return { ...state, currentOutcomeColumnStatus: action.payload };
    case DndActionTypes.SetIsUpdated:
      return { ...state, isUpdated: action.payload };
    case DndActionTypes.FetchAllLoading:
      return { ...state, fetchAllLoading: action.payload };
    case DndActionTypes.SetChangingSpaceEntityId:
      return { ...state, changingSpaceEntityId: action.payload };
    case DndActionTypes.SetBoardColumns:
      return { ...state, boardColumns: action.payload };
    case DndActionTypes.AddEntityId:
      let value1 = mapValues(state.boardColumns, (column) => {
        if (column.title === action.payload.columnTitle) {
          return { ...column, entityIds: uniq([action.payload.entityId, ...column.entityIds]) };
        }
        return column;
      });
      return { ...state, boardColumns: value1 };
    case DndActionTypes.DeleteEntityId:
      const value2 = mapValues(state.boardColumns, (column) => {
        if (column.title === action.payload.columnTitle) {
          return {
            ...column,
            entityIds: column.entityIds.filter((id: number) => id !== action.payload.entityId),
          };
        }
        return column;
      });
      return { ...state, boardColumns: value2 };
    case DndActionTypes.DeleteEntityIdFromAllColumns:
      const value3 = mapValues(state.boardColumns, (column) => {
        return {
          ...column,
          entityIds: column.entityIds.filter((id: number) => id !== action.payload.entityId),
        };
      });
      return { ...state, boardColumns: value3 };
    case DndActionTypes.UpdateEntityId:
      const value4 = mapValues(state.boardColumns, (column) => {
        if (column.title === action.payload.columnTitle) {
          return {
            ...column,
            entityIds: column.entityIds.some((id: number) => id === action.payload.entityId)
              ? column.entityIds
              : uniq([action.payload.entityId, ...column.entityIds]),
          };
        }
        return column;
      });
      return { ...state, boardColumns: value4 };
    case DndActionTypes.DeleteDuplicates:
      let duplicates = 0;
      const value5 = mapValues(state.boardColumns, (column) => {
        if (new Set(column.entityIds).size !== column.entityIds.length) {
          duplicates += 1;
          return { ...column, entityIds: Array.from(new Set(column.entityIds)) };
        }
        return { ...column };
      });

      return duplicates === 0 ? { ...state } : { ...state, boardColumns: value5 };
    case DndActionTypes.ChangeEntityIds:
      const value6 = mapValues(state.boardColumns, (column) => {
        if (column.title === action.payload.columnTitle) {
          return { ...column, entityIds: uniq(action.payload.entityIds) };
        }
        return { ...column, entityIds: uniq(column.entityIds) };
      });
      return { ...state, boardColumns: value6 };
    case DndActionTypes.ResetDnd:
      return { ...dndInitialValue };

    case DndActionTypes.DeleteEntityIds:
      if (!action.payload.entityIds.length) {
        return state;
      }
      const value7 = mapValues(state.boardColumns, (column) => {
        if (column.title === action.payload.columnTitle) {
          return {
            ...column,
            entityIds: pullAll([...column.entityIds.slice()], [...action.payload.entityIds]),
          };
        }
        return column;
      });
      return { ...state, boardColumns: value7 };

    case DndActionTypes.AddEntityIds:
      let value8 = mapValues(state.boardColumns, (column) => {
        if (column.title === action.payload.columnTitle) {
          return { ...column, entityIds: uniq([...action.payload.entityIds, ...column.entityIds]) };
        }
        return column;
      });
      return { ...state, boardColumns: value8 };

    default:
      return state;
  }
};

export default dndReducer;
