import { ArchiveOutcome, FetchOutcomes } from '../../outcomes/types';
import { FETCH_OUTCOMES } from '../../outcomes/graphql/queries';
import { FetchUserWorkspaceActions } from '../../actions/types';
import { FETCH_ACTIONS } from '../../actions/graphql/queries';
import { Filter, OperationType, updatedCacheData } from '../../utils';
import { mutateOutcome } from '../../../context/userWorkspaceContext';
import { ApolloCache, ApolloClient, ReactiveVar } from '@apollo/client';
import { Outcome } from '../../../core/types/outcome';
import { Action } from '../../../core/types/action';
import { ColumnType, DndValue, dndVar } from 'apollo/stateFields/dnd/dndFields';
import { DndColumnPayload } from '../../../apollo/stateFields/dnd/dndMutation';
import React from 'react';
import {
  deleteEntityId,
  deleteEntityIdFromAllColumns,
} from '../../../context/dndContext/DndActions';

interface Variables {
  filterInput: Filter[];
  apolloClient: ApolloClient<any>;
  dispatch: React.Dispatch<any>;
}

export const getUpdateFuncForArchive = (
  withActions: boolean,
  outcomeToArchive: Outcome,
  dataForArchive: Variables,
) => {
  const { filterInput, apolloClient, dispatch } = dataForArchive;
  return (cache: ApolloCache<ArchiveOutcome>, { data }: any) => {
    const { fetchUserWorkspaceOutcomes = [] } =
      cache.readQuery<FetchOutcomes>({
        query: FETCH_OUTCOMES,
        variables: {
          workspaceId: data?.archiveUserWorkspaceOutcome.workspaceId,
        },
      }) || {};

    const { fetchUserWorkspaceActions = [] } =
      cache.readQuery<FetchUserWorkspaceActions>({
        query: FETCH_ACTIONS,
        variables: {
          workspaceId: data?.archiveUserWorkspaceOutcome.workspaceId,
        },
      }) || {};

    cache.writeQuery<FetchOutcomes>({
      query: FETCH_OUTCOMES,
      data: {
        fetchUserWorkspaceOutcomes: updatedCacheData(
          fetchUserWorkspaceOutcomes,
          data?.archiveUserWorkspaceOutcome,
          OperationType.DELETE,
        ),
      },
      variables: {
        workspaceId: data?.archiveUserWorkspaceOutcome.workspaceId,
      },
    });

    cache.writeQuery<FetchUserWorkspaceActions>({
      query: FETCH_ACTIONS,
      data: {
        fetchUserWorkspaceActions:
          data?.archiveUserWorkspaceOutcome.actions?.length && withActions
            ? [
                ...fetchUserWorkspaceActions.filter(
                  (action) =>
                    !data?.archiveUserWorkspaceOutcome.actions?.some(
                      (archivedAction: Action) => archivedAction.id === action.id,
                    ),
                ),
              ]
            : [
                ...fetchUserWorkspaceActions.filter(
                  (action) =>
                    !data?.archiveUserWorkspaceOutcome.actions?.some(
                      (archivedAction: Action) => archivedAction.id === action.id,
                    ),
                ),
                ...fetchUserWorkspaceActions
                  .filter((action) =>
                    data?.archiveUserWorkspaceOutcome.actions?.some(
                      (archivedAction: Action) => archivedAction.id === action.id,
                    ),
                  )
                  .map((action) => ({ ...action, outcome: null })),
              ],
      },
      variables: {
        workspaceId: data?.archiveUserWorkspaceOutcome.workspaceId,
      },
    });

    if (apolloClient && data?.archiveUserWorkspaceOutcome) {
      if (
        data.archiveUserWorkspaceOutcome.isArchived &&
        !outcomeToArchive.isArchived &&
        data.archiveUserWorkspaceOutcome.actions?.length
      ) {
        data.archiveUserWorkspaceOutcome.actions.forEach((action: Action) => {
          dispatch(
            deleteEntityIdFromAllColumns({
              entityId: action.id,
            }),
          );
        });
        dispatch(
          deleteEntityId({
            entityId: data.archiveUserWorkspaceOutcome.id,
            columnTitle: ColumnType.Outcome,
          }),
        );
      }

      if (
        data.archiveUserWorkspaceOutcome.isArchived &&
        !outcomeToArchive.isArchived &&
        !data.archiveUserWorkspaceOutcome.actions?.length
      ) {
        dispatch(
          deleteEntityId({
            entityId: data?.archiveUserWorkspaceOutcome.id,
            columnTitle: ColumnType.Outcome,
          }),
        );
      }

      mutateOutcome(
        apolloClient,
        data.archiveUserWorkspaceOutcome,
        'archive',
        filterInput,
        outcomeToArchive,
        withActions,
      );
    }
  };
};
