import { gql, makeVar } from '@apollo/client';
import { Filter } from '../../../features/utils';
import { EntityType } from '../userSettings/userSettingsField';

export interface FilterInputValue {
  filterInput: Filter[];
  updateFilterInputId:
    | null
    | {
        type: EntityType;
        workspaceId: number;
        id: number;
      }[];
}

export const filterInputInitialValue: FilterInputValue = {
  filterInput: [],
  updateFilterInputId: null,
};

export const filterInputVar = makeVar<FilterInputValue>(filterInputInitialValue);
export const FILTER_STATE = gql`
  query FilterInput {
    filterInput @client
  }
`;
