import React from 'react';
import styled from 'styled-components';
import { DragDropContext } from 'react-beautiful-dnd';

import { KanbanViewProps } from './types';
import { OutcomeCardColumn } from 'features/outcomes/components/OutcomeCardColumn';

const StyledContainer = styled.div`
  display: flex;
`;

const StyledColumnWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 0.167rem;
  }
`;

const StyledOutcomeCardColumn = styled(OutcomeCardColumn)`
  margin-right: 10px;
`;

const renderKanbanColumns = <T,>(
  columnConfig: KanbanViewProps<T>['columnConfig'],
  cardData: KanbanViewProps<T>['cardData'],
  renderColumn: KanbanViewProps<T>['renderColumn'],
) =>
  columnConfig.map((config) => (
    <StyledColumnWrapper key={config.name}>{renderColumn(config, cardData)}</StyledColumnWrapper>
  ));

const KanbanView = <T,>({
  className,
  cardData,
  columnConfig,
  renderColumn,
  onDragStart = () => undefined,
  onDragEnd = () => undefined,
}: KanbanViewProps<T>) => {
  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <StyledContainer className={className}>
        <StyledOutcomeCardColumn
          isDropDisabled={true}
          sourceDragStartColumn={null}
        />
        {renderKanbanColumns<T>(columnConfig, cardData, renderColumn)}
      </StyledContainer>
    </DragDropContext>
  );
};

export { KanbanView };
