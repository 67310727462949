import React, { useCallback, useMemo } from 'react';
import { sendEvent } from 'core/integrations/sentry/events';
import { ActionCard } from '../ActionCard';
import { newAction } from '../../../../../core/utils/action';
import { toast, ToastContent } from 'react-toastify';
import { useUserWorkspaceContext } from '../../../../../context/userWorkspaceContext';
import { DraggableChildrenFn } from 'react-beautiful-dnd';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import useCreateAction from '../../../../interactions/action/useCreateAction';
import { filterActionsByDoneDate, filterActionsByStatus, filterActionsByToDoDate } from '../utils';
import { Action, ActionPriority, ActionStatus } from '../../../../../core/types/action';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../../../apollo/stateFields/filterInput/filterInputFields';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../../apollo/stateFields/userSettings/userSettingsField';
import {
  BOARD_FILTERS_STATE,
  BoardFiltersValue,
} from '../../../../../apollo/stateFields/boardFilters/boardFiltersFields';
import useDndContext from '../../../../../context/dndContext/useDndContext';
import { EntityType, getCacheEntityById } from '../../../../utils';
import { ColumnType } from '../../../../../context/dndContext/types';
import ActionColumn from '../ActionColumn/ActionColumn';
import useDuplicateAction from '../../../../interactions/action/useDuplicateAction';
import {
  CancelFocusTimer,
  CreateFocusTimer,
  FetchFocusTimer,
  FinishFocusTimer,
  MoveAction,
  PauseFocusTimer,
  ResumeFocusTimer,
  UpdateAction,
} from '../../../types';
import {
  CANCEL_FOCUS_TIMER,
  FINISH_FOCUS_TIMER,
  MOVE_ACTION,
  PAUSE_FOCUS_TIMER,
  RESUME_FOCUS_TIMER,
  START_FOCUS_TIMER,
  UPDATE_ACTION,
} from '../../../graphql/mutations';
import { getUpdateFuncForUpdateAction } from '../../../../interactions/action/getUpdateFuncForMoveAction';
import { boardEditedEntityMutation } from '../../../../../apollo/stateFields/boardEditedEntity';
import { ContextMenuOption } from '../../../../../core/components/common/Card/types';
import { ContextMenuOptions, PriorityTitle } from '../ActionCard/constants';
import { getUpdateFuncForMoveUpdateAction } from '../../../../interactions/action/getUpdateFuncForMoveUpdateAction';
import { FetchUserWorkspacesWithInvitedCount } from '../../../../workspaces/types';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../../../../common/graphql/queries';
import useWorkspaceInvites from '../../../../../hooks/useWorkspaceInvites';
import { FETCH_ACTION, FETCH_FOCUS_TIMER } from '../../../graphql/queries';
import { getSpendTime } from '../ActionCard/FocusTimer/utils';

const ActionCardColumnsComponent = ({
  className,
  isDropDisabled = false,
  isCombineEnabled = false,
  isMovingDisabled = false,
  isDragDisabled = false,
}: {
  className?: string;
  isDropDisabled?: boolean;
  isCombineEnabled?: boolean;
  isMovingDisabled?: boolean;
  isDragDisabled?: boolean;
}) => {
  const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
  const { defaultWorkspaceId }: UserSettingsValue = userSettingsData?.userSettingsField;
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const { data: boardFiltersData } = useQuery(BOARD_FILTERS_STATE);
  const {
    doneColumnFilter,
    toDoColumnFilter,
    doneColumnStartDate,
    doneColumnEndDate,
    toDoColumnStartDate,
    toDoColumnEndDate,
  }: BoardFiltersValue = boardFiltersData?.boardFilters;
  const apolloClient = useApolloClient();
  const { boardColumns } = useDndContext();
  const dndActionColumns = {
    backlogColumn: boardColumns.backlogColumn,
    toDoColumn: boardColumns.toDoColumn,
    doingColumn: boardColumns.doingColumn,
    doneColumn: boardColumns.doneColumn,
  };
  const { data: workspacesResponse } = useQuery<FetchUserWorkspacesWithInvitedCount>(
    FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
  );
  const { setShowFocusSettingsForActionId } = boardEditedEntityMutation;
  const workspaces = workspacesResponse?.fetchUserWorkspacesWithInvitedCount || [];

  const { loading, getWorkspaceInvitesById } = useWorkspaceInvites();
  const { actions } = useUserWorkspaceContext();
  const [createAction] = useCreateAction();
  const [duplicateActionQuery] = useDuplicateAction();
  const [moveAction] = useMutation<MoveAction>(MOVE_ACTION);
  const [updateAction] = useMutation<UpdateAction>(UPDATE_ACTION);

  const { data: focusTimerData } = useQuery<FetchFocusTimer>(FETCH_FOCUS_TIMER);
  const [startFocusTimer] = useMutation<CreateFocusTimer>(START_FOCUS_TIMER);
  const [cancelFocusTimer] = useMutation<CancelFocusTimer>(CANCEL_FOCUS_TIMER);
  const [pauseFocusTimer] = useMutation<PauseFocusTimer>(PAUSE_FOCUS_TIMER);
  const [resumeFocusTimer] = useMutation<ResumeFocusTimer>(RESUME_FOCUS_TIMER);
  const [finishFocusTimer] = useMutation<FinishFocusTimer>(FINISH_FOCUS_TIMER);

  const { showEditActionModal, setUpdateOutcomeWithActionEntity } = boardEditedEntityMutation;
  const actionColumns = useMemo(() => {
    return Object.values(dndActionColumns);
  }, [dndActionColumns]);

  const onCardFormSubmit = useCallback(
    async (name, workspaceId, status) => {
      try {
        const currentSpaceFilterInput = filterInput.find((w) => w.workspaceId === workspaceId);
        const filterInputSpaceTags = currentSpaceFilterInput
          ? currentSpaceFilterInput.tagIds
          : undefined;
        const actionValues = {
          name,
          status,
          tagIds: filterInputSpaceTags,
          ...newAction(status),
        };
        await createAction({
          variables: { actionValues, workspaceId },
        });

        sendEvent('action-create', 'Action create', {
          Name: name,
          Status: status,
        });
      } catch (error) {
        console.error(error);
        toast(error as ToastContent);
      }
    },
    [createAction, defaultWorkspaceId, apolloClient, filterInput],
  );

  const renderActionClone: DraggableChildrenFn = (provided, snapshot, rubric) => {
    const action = getCacheEntityById(
      apolloClient,
      Number(rubric.draggableId),
      EntityType.ACTION,
      filterInput,
    ) as Action;

    return (
      <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
        <ActionCard action={action} />
      </div>
    );
  };

  const doneInterval =
    doneColumnStartDate && doneColumnEndDate
      ? {
          start: doneColumnStartDate,
          end: doneColumnEndDate,
        }
      : undefined;
  const toDoInterval =
    toDoColumnStartDate && toDoColumnEndDate
      ? {
          start: toDoColumnStartDate,
          end: toDoColumnEndDate,
        }
      : undefined;

  const filteredActions = useCallback(
    (title: string) => {
      return title === ColumnType.Done
        ? filterActionsByDoneDate(actions, doneColumnFilter, doneInterval)
        : title === ColumnType.Todo
        ? filterActionsByToDoDate(actions, toDoColumnFilter, toDoInterval)
        : filterActionsByStatus(actions, title.toUpperCase() as ActionStatus);
    },
    [actions, toDoColumnFilter, doneColumnFilter, doneInterval, toDoInterval],
  );

  const onActionWorkspaceChange = useCallback(
    async (action: Action, option: any, oldWorkspaceId = -1) => {
      const newWorkspaceId = option?.value;
      try {
        await moveAction({
          update: getUpdateFuncForUpdateAction(apolloClient, action, filterInput),
          variables: {
            actionValues: {
              id: action.id,
              workspaceId: newWorkspaceId,
              inputTags: action.tags?.map((tag) => ({ name: tag.name })),
            },
            outcomeId: action?.outcome?.id,
            workspaceId: oldWorkspaceId,
            targetWorkspaceId: newWorkspaceId,
          },
        });
      } catch (error) {
        console.error(error);
        toast(error as ToastContent);
      }
    },
    [moveAction, filterInput, apolloClient],
  );
  const duplicateAction = useCallback(
    async (action: Action) => {
      try {
        const resp = await duplicateActionQuery({
          variables: {
            actionId: action.id,
            workspaceId: action.workspaceId,
          },
        });
        const newAction = resp.data?.duplicateUserWorkspaceAction;
        if (newAction?.outcome && newAction) {
          setUpdateOutcomeWithActionEntity(newAction);
        }
        sendEvent('action-duplicate', 'Action duplicate', {
          'Action ID': action.id,
        });
      } catch (error) {
        console.error(error);
        toast(error as ToastContent);
      }
    },
    [duplicateActionQuery, filterInput, apolloClient],
  );
  const toggleArchiveAction = useCallback(
    async (action: Action, isArchived: boolean) => {
      try {
        await updateAction({
          update: getUpdateFuncForMoveUpdateAction(apolloClient, action, filterInput),
          variables: {
            actionValues: {
              id: action.id,
              isArchived,
            },
            workspaceId: action.workspaceId,
          },
          refetchQueries: [FETCH_FOCUS_TIMER],
        });

        sendEvent(
          isArchived ? 'action-archive' : 'action-unarchive',
          isArchived ? 'Action archive' : 'Action unarchive',
          {
            'Action ID': action.id,
          },
        );
      } catch (error) {
        console.error(error);
        toast(error as ToastContent);
      }
    },
    [updateAction, filterInput, apolloClient],
  );

  const changeActionPriority = useCallback(
    async (action: Action, priority: ActionPriority | null) => {
      try {
        await updateAction({
          update: getUpdateFuncForMoveUpdateAction(apolloClient, action, filterInput),
          variables: {
            actionValues: {
              id: action.id,
              priority,
            },
            workspaceId: action.workspaceId,
          },
        });
      } catch (error) {
        console.error(error);
        toast(error as ToastContent);
      }
    },
    [updateAction, filterInput, apolloClient],
  );
  const handleContextMenuOptionClick = (action: Action) => (option: ContextMenuOption) => {
    const label = option.label;

    if (ContextMenuOptions.SET_PRIORITY.children.some((children) => children.label === label)) {
      switch (label) {
        case PriorityTitle.IMPORTANT_URGENT:
          changeActionPriority(action, ActionPriority.IMPORTANT_URGENT);
          break;
        case PriorityTitle.URGENT:
          changeActionPriority(action, ActionPriority.URGENT);
          break;
        case PriorityTitle.IMPORTANT:
          changeActionPriority(action, ActionPriority.IMPORTANT);
          break;
        case PriorityTitle.NOT_DEFINED:
          changeActionPriority(action, null);
          break;
      }
      return;
    }

    switch (label) {
      case ContextMenuOptions.EDIT_ACTION.label:
        showEditActionModal(action);
        break;
      case ContextMenuOptions.VIEW_ACTION.label:
        showEditActionModal(action);
        break;
      case ContextMenuOptions.DUPLICATE_ACTION.label:
        duplicateAction(action);
        break;
      case ContextMenuOptions.ARCHIVE_ACTION.label:
        toggleArchiveAction(action, true);
        break;
      case ContextMenuOptions.UNARCHIVE_ACTION.label:
        toggleArchiveAction(action, false);
        break;
      case ContextMenuOptions.START_FOCUS_TIMER.label:
        startFocusTimer({
          variables: {
            actionId: action.id,
          },
          refetchQueries: [FETCH_FOCUS_TIMER],
        });
        break;
      case ContextMenuOptions.PAUSE_FOCUS_TIMER.label:
        if (focusTimerData) {
          const spendTime = getSpendTime(
            focusTimerData?.fetchFocusTimer.startTime,
            focusTimerData?.fetchFocusTimer?.resumeTime,
            focusTimerData?.fetchFocusTimer?.fixedPeriodSelected,
            focusTimerData?.fetchFocusTimer?.spendTime,
            focusTimerData?.fetchFocusTimer?.status,
          );
          pauseFocusTimer({
            variables: {
              spendTime: Math.round(Number(spendTime) / 1000),
            },
            refetchQueries: [FETCH_FOCUS_TIMER],
          });
          break;
        }
        break;

      case ContextMenuOptions.CONTINUE_FOCUS_TIMER.label:
        resumeFocusTimer({
          refetchQueries: [FETCH_FOCUS_TIMER],
        });
        break;
      case ContextMenuOptions.CANCEL_FOCUS_TIMER.label:
        cancelFocusTimer({
          refetchQueries: [FETCH_FOCUS_TIMER],
        });
        break;
      case ContextMenuOptions.SETTINGS_FOCUS_TIMER.label:
        setShowFocusSettingsForActionId(action.id);
        break;
      case ContextMenuOptions.FINISH_FOCUS_TIMER.label:
        if (focusTimerData) {
          const spendTime = getSpendTime(
            focusTimerData?.fetchFocusTimer.startTime,
            focusTimerData?.fetchFocusTimer?.resumeTime,
            focusTimerData?.fetchFocusTimer?.fixedPeriodSelected,
            focusTimerData?.fetchFocusTimer?.spendTime,
            focusTimerData?.fetchFocusTimer?.status,
          );
          finishFocusTimer({
            variables: {
              spendTime: Math.round(Number(spendTime) / 1000),
            },
            refetchQueries: [
              FETCH_FOCUS_TIMER,
              {
                query: FETCH_ACTION,
                variables: {
                  actionId: action.id,
                  workspaceId: action.workspaceId,
                },
              },
            ],
          });
          break;
        }
        break;
    }
  };

  return (
    <>
      {actionColumns.map((column) => {
        return (
          <ActionColumn
            key={column.title}
            column={column}
            workspaces={workspaces}
            isDropDisabled={isDropDisabled}
            isCombineEnabled={isCombineEnabled}
            isMovingDisabled={isMovingDisabled}
            filteredActions={filteredActions(column.title)}
            onCardFormSubmit={onCardFormSubmit}
            renderActionClone={renderActionClone}
            onActionWorkspaceChange={onActionWorkspaceChange}
            handleContextMenuOptionClick={handleContextMenuOptionClick}
            getWorkspaceInvitesById={getWorkspaceInvitesById}
            isDragDisabled={isDragDisabled}
          />
        );
      })}
    </>
  );
};

export const ActionCardColumns = React.memo(ActionCardColumnsComponent);
