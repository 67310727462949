import React from 'react';
import { PasswordRecoveryLayoutProps } from './types';
import styled from 'styled-components';
import { theme } from '../../../../core/styles/styled-components';

const StyledContainer = styled.div`
  width: 330px;

  @media ${theme.device.tablet.max} {
    padding-bottom: 80px;
  }

  @media ${theme.device.mobile.max} {
   width: 100%;
  }
`;

const StyledTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  margin-bottom: 8px;

  @media ${theme.device.mobile.max} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const StyledText = styled.p`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 24px;
`;

const PasswordRecoveryLayout = ({ formComponent, title, subtitle }: PasswordRecoveryLayoutProps) => {
  return (
    <StyledContainer>
      <StyledTitle>{title}</StyledTitle>
      {subtitle &&  <StyledText>{subtitle}</StyledText>}
      {formComponent}
    </StyledContainer>
  )
};

export default PasswordRecoveryLayout;