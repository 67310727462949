import React, { useState } from 'react';
import styled from 'styled-components';
import { Header } from '../../core/components/layout';
import { theme } from '../../core/styles/styled-components';
import UserAvatar from '../../core/components/common/UserAvatar/UserAvatar';
import { useQuery } from '@apollo/client';
import { AUTH_STATE, AuthValue } from '../../apollo/stateFields/auth/authFields';
import { useMediaQuery } from 'react-responsive';
import { PageNavigationBar } from '../PageView/PageNavigationBar';
import userProfileLinks from './userProfileLinks';
import {
  StyledContainer,
  StyledContentContainer,
  StyledPageContainer,
  StyledTitle,
} from '../CommonStyledComponents/CommonStyledComponents';
import UserProfileInfoPlans from '../../features/user/UserProfileInfoPlans';
import EditUserProfileModal from './EditUserProfileModal';
import { Button } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { authMutations } from '../../apollo/stateFields/auth';

const StyledUserInfo = styled.div`
  && {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
const StyledUserTextWrapper = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 24px;

    @media ${theme.device.mobile.max} {
      margin-left: 16px;
    }
  }
`;

const StyledUserName = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  margin-bottom: 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 24px;
  max-width: 250px;
  @media ${theme.device.desktop.min} {
    max-width: 600px;
  }
  @media ${theme.device.tablet.max} {
    max-width: 350px;
  }
  @media ${theme.device.mobile.max} {
    max-width: 240px;
    font-size: 20px;
    line-height: 24px;
  }
`;

const StyledUserEmail = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  max-width: 250px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media ${theme.device.desktop.min} {
    max-width: 600px;
  }
  @media ${theme.device.tablet.max} {
    max-width: 350px;
  }
  @media ${theme.device.mobile.max} {
    max-width: 240px;
    font-size: 14px;
    line-height: 16px;
  }
`;

const UserProfilePage = () => {
  const { data } = useQuery(AUTH_STATE);
  const { authUser }: AuthValue = data?.auth;
  const { setUserDisplayName } = authMutations;
  const isMobile = useMediaQuery({ query: theme.device.mobile.max });
  const isTablet = useMediaQuery({ query: theme.device.tablet.min });
  const [displayName, setDisplayName] = useState<string>(authUser?.displayName || '');
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);

  const handleCloseModal = (displayName?: string) => {
    if (displayName) {
      setDisplayName(displayName);
      setUserDisplayName(displayName);
    }
    setShowEditProfileModal(false);
  };

  return (
    <StyledContainer>
      <Header isThin={true} />
      <StyledContentContainer>
        <PageNavigationBar links={userProfileLinks} shoulShow={isTablet} />
        <StyledPageContainer>
          <StyledTitle>User Profile</StyledTitle>
          <StyledUserInfo>
            <UserAvatar size={isMobile ? 70 : 94} />
            <StyledUserTextWrapper>
              <StyledUserName>{displayName} </StyledUserName>
              <StyledUserEmail>
                {authUser?.email?.includes('@') && !authUser?.email.includes('appleid')
                  ? authUser?.email
                  : ''}
              </StyledUserEmail>
            </StyledUserTextWrapper>
            <Button
              shape="circle"
              icon={<EditFilled />}
              onClick={() => setShowEditProfileModal(true)}
              style={{ backgroundColor: 'var(--color-main-grey-2)', marginLeft: '5px' }}
            />
          </StyledUserInfo>
          <UserProfileInfoPlans />
        </StyledPageContainer>
      </StyledContentContainer>
      <EditUserProfileModal onClose={handleCloseModal} visible={showEditProfileModal} />
    </StyledContainer>
  );
};

export default UserProfilePage;
