import { DocumentNode, TypedDocumentNode, useApolloClient } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { getCacheWorkspaceById } from '../features/utils/getCacheEntityById';
import { Workspace } from '../core/types/workspace';

const useSubscribeEntity = (
  query: DocumentNode | TypedDocumentNode,
  workspaceId?: number | null,
  entityId?: number | null,
) => {
  const [data, setData] = useState<any>();
  const apolloClient = useApolloClient();
  const shouldSubscribe = useMemo(() => {
    // if (workspaceId === undefined) return true;
    if (!workspaceId) return false;
    const workspace = getCacheWorkspaceById(apolloClient, workspaceId) as Workspace;
    return (workspace && workspace.usersCount && workspace.usersCount > 1) || false;
  }, [workspaceId]);

  useEffect(() => {
    if (entityId && shouldSubscribe) {
      const observer = apolloClient.subscribe({
        query,
        variables: {
          triggerId: entityId,
        },
      });
      const subscription = observer.subscribe(({ data }) => {
        !!data && setData(data);
      });

      return () => {
        subscription.unsubscribe();
      };
    } else {
      setData(undefined);
    }
  }, [entityId, shouldSubscribe]);

  return { data, setData };
};

export default useSubscribeEntity;
