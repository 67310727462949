import { ActionInput, ActionStatus } from 'core/types/action';
import { OutcomeInput, OutcomeStatus } from 'core/types/outcome';
import { NoteInput, Note } from 'core/types/note';
import { NOT_ASSIGNED_WORKSPACE_NAME } from '../../../../../core/constants/others';

export function getOutcomeInitialValuesFromNote(
  note: NoteInput,
  initWorkspaceId: number = -1,
): OutcomeInput {
  const { createDate, updateDate, isDeleted, id, ...noteToOutcome } = note;
  return {
    ...noteToOutcome,
    startDate: undefined,
    endDate: undefined,
    status: OutcomeStatus.FUTURE,
    inputTags: [],
    workspaceId: initWorkspaceId,
  };
}

export function getActionInitialValuesFromNote(
  note: NoteInput,
  initWorkspaceId: number = -1,
): ActionInput {
  const { createDate, updateDate, id, isDeleted, workspaceId, ...noteToAction } = note;
  return {
    ...noteToAction,
    status: ActionStatus.BACKLOG,
    outcomeId: undefined,
    startDate: undefined,
    actualEndDate: undefined,
    actualStartDate: undefined,
    endDate: undefined,
    estimatedTime: undefined,
    spendTime: undefined,
    actionTimeFormat: undefined,
    assigneeId: undefined,
    inputTags: [],
    workspaceId: initWorkspaceId,
  };
}

export function getOutputWorkspaceId(id: number, defaultId: number): number {
  if (id === 0 || id === defaultId || id === -1) return defaultId;
  return id;
}

export const sortNotes = (a: Note, b: Note): number => {
  if (a.updateDate && b.updateDate) {
    if (a.updateDate > b.updateDate) {
      return -1;
    } else if (a.updateDate < b.updateDate) {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  }
  if (!b.updateDate && a.updateDate && a.updateDate > b.createDate) {
    return -1;
  }
  if (!a.updateDate && b.updateDate && b.updateDate > a.createDate) {
    return 1;
  }
  if (a.createDate > b.createDate) {
    return -1;
  } else if (a.createDate < b.createDate) {
    return 1;
  } else {
    return a.name.localeCompare(b.name);
  }
};

export const renameNotAssignedWorkspace = (name: string) => {
  return name === NOT_ASSIGNED_WORKSPACE_NAME ? 'Not Assigned' : name;
};
