import React from 'react';
import {
  StyledCursiveTipsText,
  StyledTipsHeader,
  StyledTipsText,
  StyledTipsTitle,
} from '../StyledComponents';

const IntegrateWorkTips = () => {
  return (
    <div>
      <StyledTipsHeader>Hacks, Tips & Tricks - Integrate Work with Your Life</StyledTipsHeader>
      <StyledTipsText>
        Personal effectiveness is <StyledCursiveTipsText>personal</StyledCursiveTipsText>, so
        BillionMinds tends to steer clear of recommending specific life hacks that may not work for
        the individual. However, we do provide a small number, which have been shown to be effective
        across a large proportion of our users. Try these, and let us know what works and what
        doesn’t!
      </StyledTipsText>
      <StyledTipsTitle>
        When creating a space, create the most important outcomes associated with it
      </StyledTipsTitle>
      <StyledTipsText>
        You may already have these outcomes, and just need to move them from an existing space into
        it (this is easily done by clicking on the current space on the front of the outcome and
        picking a new space).
      </StyledTipsText>
      <StyledTipsTitle>Use spaces to set expectations with others</StyledTipsTitle>
      <StyledTipsText>
        Find people who are important to you, and walk them through the spaces you have chosen. This
        will help them understand what is important to you in your life, and help keep you
        accountable to move each of the spaces forward.
      </StyledTipsText>
      <StyledTipsTitle>Share spaces with others</StyledTipsTitle>
      <StyledTipsText>
        At least some of our activities are performed in collaboration with others. Sharing a space
        with other people (for example colleagues or family members) allows you to create a unique
        picture of your own life, where parts of it are individual and parts of it are shared with
        others. To share a space, simply click “add users” on the space.
      </StyledTipsText>
      <StyledTipsTitle>Use spaces as part of your routine</StyledTipsTitle>
      <StyledTipsText>
        For example at the start of the day view all your spaces, and as your work day ends, switch
        off your work related spaces.
      </StyledTipsText>
      <StyledTipsTitle>Regularly view the less visited spaces</StyledTipsTitle>
      <StyledTipsText>
        This will help ensure you are spending at least 5% of your time advancing the outcomes in
        those spaces.
      </StyledTipsText>
      <StyledTipsTitle>Perform a “Space Audit” every 6 months</StyledTipsTitle>
      <StyledTipsText>
        Do these spaces reflect how you currently want to spend your time, or is there a better way
        to reflect it, based on how you or the world around you has changed?
      </StyledTipsText>
      <StyledTipsTitle>When you create a new space, consider archiving an old one</StyledTipsTitle>
      <StyledTipsText>
        As each space should correspond to 5% of your life, there is a limit to the number you will
        need.
      </StyledTipsText>
    </div>
  );
};

export default IntegrateWorkTips;
