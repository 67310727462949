import React from 'react';
import styled from 'styled-components';
import { useField, useFormikContext } from 'formik';
import { noop } from 'lodash-es';

import { DateRangePicker, Interval } from 'core/components/common';

import { DateRangePickerFieldProps } from './types';

const StyledContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

const StyledError = styled.span``;

const DateRangePickerField = ({
  className,
  errorClassName,
  name,
  displayError,
  id,
  onChange = noop,
  ...restOfProps
}: DateRangePickerFieldProps) => {
  const { setFieldValue } = useFormikContext();
  // eslint-disable-next-line
  const [_, meta] = useField(name);

  const handleChange = (dateRange: Interval) => {
    setFieldValue(name, dateRange);
    onChange(dateRange);
  };

  return (
    <StyledContainer className={className}>
      <DateRangePicker onChange={handleChange} {...restOfProps} />
      {displayError && meta.error && meta.touched && (
        <StyledError className={errorClassName}>{meta.error}</StyledError>
      )}
    </StyledContainer>
  );
};

export { DateRangePickerField };
