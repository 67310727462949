import { WorkspaceMenuOption } from './types';

export const sortWorkspaceOptions = (list: WorkspaceMenuOption[], defaultWorkspaceId: number) => {
  return list.sort((a, b) => {
    if (a.value === defaultWorkspaceId) {
      return -1;
    } else if (b.value === defaultWorkspaceId) {
      return 1;
    } else {
      return a.label.localeCompare(b.label);
    }
  })
};
