import {
  AddEntityId,
  AddEntityIds,
  ChangeEntityIds,
  DeleteDuplicates,
  DeleteEntityId,
  DeleteEntityIdFromAllColumns,
  DeleteEntityIds,
  DndActionTypes,
  DndColumnsPayload,
  ResetDndAction,
  SetBoardColumns,
  SetChangingSpaceEntityId,
  SetCurrentOutcomeColumnStatus,
  SetFetchAllLoading,
  SetIsUpdated,
  UpdateEntityId,
} from './types';
import { BoardColumns, ColumnType } from '../../apollo/stateFields/dnd/dndFields';
import { OutcomeStatus } from '../../core/types/outcome';
import { DndColumnPayload } from '../../apollo/stateFields/dnd/dndMutation';

export const resetDnd = (): ResetDndAction => {
  return { type: DndActionTypes.ResetDnd };
};
export const setBoardColumns = (payload: BoardColumns): SetBoardColumns => {
  return { type: DndActionTypes.SetBoardColumns, payload };
};
export const setFetchAllLoading = (payload: boolean): SetFetchAllLoading => {
  return { type: DndActionTypes.FetchAllLoading, payload };
};
export const setCurrentOutcomeColumnStatus = (
  payload: OutcomeStatus,
): SetCurrentOutcomeColumnStatus => {
  return { type: DndActionTypes.SetCurrentOutcomeColumnStatus, payload };
};
export const addEntityId = (payload: DndColumnPayload): AddEntityId => {
  return { type: DndActionTypes.AddEntityId, payload };
};
export const addEntityIds = (payload: DndColumnsPayload): AddEntityIds => {
  return { type: DndActionTypes.AddEntityIds, payload };
};
export const deleteEntityId = (payload: DndColumnPayload): DeleteEntityId => {
  return { type: DndActionTypes.DeleteEntityId, payload };
};
export const deleteEntityIds = (payload: DndColumnsPayload): DeleteEntityIds => {
  return { type: DndActionTypes.DeleteEntityIds, payload };
};
export const deleteEntityIdFromAllColumns = (payload: {
  entityId: number;
}): DeleteEntityIdFromAllColumns => {
  return { type: DndActionTypes.DeleteEntityIdFromAllColumns, payload };
};
export const updateEntityId = (payload: DndColumnPayload): UpdateEntityId => {
  return { type: DndActionTypes.UpdateEntityId, payload };
};
export const deleteDuplicates = (): DeleteDuplicates => {
  return { type: DndActionTypes.DeleteDuplicates };
};
export const changeEntityIds = (payload: {
  entityIds: number[];
  columnTitle: ColumnType;
}): ChangeEntityIds => {
  return { type: DndActionTypes.ChangeEntityIds, payload };
};
export const setIsUpdated = (payload: boolean): SetIsUpdated => {
  return { type: DndActionTypes.SetIsUpdated, payload };
};
export const setChangingSpaceEntityId = (payload: number | null): SetChangingSpaceEntityId => {
  return { type: DndActionTypes.SetChangingSpaceEntityId, payload };
};
