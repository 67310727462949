import React from 'react';
import {
  StyledCursiveTipsText,
  StyledTipsHeader,
  StyledTipsText,
  StyledTipsTitle,
} from '../StyledComponents';

const FindMeaningTips = () => {
  return (
    <div>
      <StyledTipsHeader>Hacks, Tips & Tricks - Find Meaning in Your Day</StyledTipsHeader>
      <StyledTipsText>
        Personal effectiveness is <StyledCursiveTipsText>personal</StyledCursiveTipsText>, so
        BillionMinds tends to steer clear of recommending specific life hacks that may not work for
        the individual. However, we do provide a small number, which have been shown to be effective
        across a large proportion of our users. Try these, and let us know what works and what
        doesn’t!
      </StyledTipsText>
      <StyledTipsTitle>When you create an outcome, create your first action!</StyledTipsTitle>
      <StyledTipsText>
        This is easy to do in the BillionMinds tool, and creates some momentum for the outcome you
        have defined. Just double tap on the outcome and create your first action right away.
      </StyledTipsText>
      <StyledTipsTitle>Rephrase outcomes so they mean something to you personally</StyledTipsTitle>
      <StyledTipsText>
        If you have agreed on an outcome with your manager, but it’s just not resonating with you
        personally, try rephrasing it into something that means the same, but use wording that rings
        more true to you. This could be making it more informal, or just using language that
        resonates with you personally.
      </StyledTipsText>
      <StyledTipsTitle>Share your most important outcomes with others</StyledTipsTitle>
      <StyledTipsText>
        This helps you be accountable for what you are doing. If you feel uncomfortable doing this,
        it may be that the outcome is not as important to you as you thought. If there is no one
        obvious to share them with, share them with your BillionMinds coach.
      </StyledTipsText>
      <StyledTipsTitle>Create outcomes in your personal life</StyledTipsTitle>
      <StyledTipsText>
        Defining outcomes for your personal life helps you to ensure that you are not deprioritizing
        it compared to your work life.
      </StyledTipsText>
      <StyledTipsTitle>
        Use “Future Outcomes” for outcomes you have not framed fully yet
      </StyledTipsTitle>
      <StyledTipsText>
        The BillionMinds tool is helpful for this, and you can reframe them as current outcomes when
        you are ready.
      </StyledTipsText>
      <StyledTipsTitle>Look at your outcomes on a regular basis</StyledTipsTitle>
      <StyledTipsText>
        Ask yourself if these outcomes are still meaningful, and should be reframed or removed. Also
        ask yourself if you are making progress against these outcomes.
      </StyledTipsText>
      <StyledTipsTitle>When an outcome is accomplished, reward yourself!</StyledTipsTitle>
      <StyledTipsText>
        Accomplishing an outcome is a major achievement, and is typically the result of consistent
        work over a period of time. You may want to even define the reward you will give yourself
        ahead of time, to keep you focused on accomplishing it.
      </StyledTipsText>
    </div>
  );
};

export default FindMeaningTips;
