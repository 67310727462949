import React, { useState } from 'react';
import PasswordRecoveryLayout from './PasswordRecoveryLayout';
import { Formik, FormikValues } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { InputTextField } from '../../../../core/components/form';
import { SIGN_IN_PATH } from '../../../../core/constants/routePaths';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { validationSchemaForPasswordRecovery } from './validations';
import { useAuth } from '../../../../context/authContext';
import SignInLayoutWrapper from '../../../../core/components/SignInLayoutWrapper/SignInLayoutWrapper';

const StyledButtonsContainer = styled.div<{ noPadding?: boolean }>`
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `}
`;

const StyledLink = styled(Link)`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  color: var(--color-main-dark-blue);
`;

const initialValues = {
  email: '',
};

const PasswordRecovery = () => {
  const { sentPasswordResetLink } = useAuth();
  const [email, setEmail] = useState('');

  const handleSentLink = async ({ email }: FormikValues) => {
    if (sentPasswordResetLink) {
      await sentPasswordResetLink(email);
      setEmail(email);
    }
  };

  const backLinkEl = <StyledLink to={SIGN_IN_PATH}>Return to Sign in</StyledLink>;

  const formEl = email ? (
    <StyledButtonsContainer noPadding>{backLinkEl}</StyledButtonsContainer>
  ) : (
    <Formik
      enableReinitialize
      validateOnChange={true}
      validateOnBlur={true}
      initialValues={initialValues}
      validationSchema={validationSchemaForPasswordRecovery}
      onSubmit={handleSentLink}
    >
      {({ isSubmitting, errors }) => {
        return (
          <Form>
            <InputTextField
              name="email"
              placeholder="Email"
              autoComplete="off"
              displayError={!!errors.email}
            />
            <StyledButtonsContainer>
              {backLinkEl}
              <SubmitButton loading={isSubmitting} type="primary" shape="round">
                Send link
              </SubmitButton>
            </StyledButtonsContainer>
          </Form>
        );
      }}
    </Formik>
  );

  const subtitleEl = email ? (
    <span>
      If we identify an account associated with that address you will receive an email to reset your
      password.
    </span>
  ) : (
    'We will send a recovery link to your email address'
  );

  return (
    <SignInLayoutWrapper>
      <PasswordRecoveryLayout
        title="Password recovery"
        subtitle={subtitleEl}
        formComponent={formEl}
      />
    </SignInLayoutWrapper>
  );
};

export default PasswordRecovery;
