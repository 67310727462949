import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import theme from 'core/styles/styled-components/theme';
import { CONTACT_US_PATH, FAQ_PATH } from 'core/constants/routePaths';

const StyledMenu = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  li:last-child {
    margin-left: 24px;
  }
`

const StyledLink = styled(Link)`
  font-weight: 700;
`

const Menu: FC = () => {
  return (
    <StyledMenu>
      <li>
        <StyledLink to={CONTACT_US_PATH}>Contact us</StyledLink>
      </li>
      <li>
        <StyledLink to={FAQ_PATH}>F.A.Q.</StyledLink>
      </li>
    </StyledMenu>
  )
}

export default Menu
