import { differenceInMinutes, isToday } from 'date-fns';
import { CoursesTitle } from '../../apollo/stateFields/courses/coursesFields';
import { CoursesConsts, CoursesVars, CoursesVideo, SectionName } from '../../graphql/types';

export const getIsAvailableVideo = (
  currentVideo: CoursesVideo,
  courseAvailable?: boolean,
  watchedIds?: number[] | null,
  lastWatchedId?: number | null,
  lastWatchedDate?: Date | string | null,
  currentConst?: CoursesConsts | null,
  isSubscriptionExpired?: boolean,
): boolean => {
  if (!courseAvailable) {
    return false;
  }
  if (checkCourseSpecialIdsEqual(currentVideo.id, 0)) {
    return true;
  }
  const masterClassVideo = currentConst?.videos.find(
    (constVideo) =>
      constVideo.id === currentVideo.id && constVideo?.name.indexOf('Masterclass') !== -1,
  );

  if (watchedIds) {
    if (watchedIds.some((watchedId) => watchedId === currentVideo.id)) {
      return true;
    } else if (
      lastWatchedId !== undefined &&
      lastWatchedDate &&
      !watchedIds.some((watchedId) => watchedId === currentVideo.id) &&
      watchedIds[watchedIds.length - 1] + 1 === currentVideo.id &&
      currentVideo.name.indexOf('Masterclass') !== -1
    ) {
      return true;
    } else if (
      lastWatchedId !== undefined &&
      lastWatchedDate &&
      !watchedIds.some((watchedId) => watchedId === currentVideo.id) &&
      watchedIds[watchedIds.length - 1] + 2 === currentVideo.id &&
      getRestTime(lastWatchedDate, isSubscriptionExpired) === 0 &&
      currentConst?.videos
        .find((video) => video.id === watchedIds[watchedIds.length - 1] + 1)
        ?.name.indexOf('Masterclass') !== -1
    ) {
      return true;
    } else if (
      lastWatchedId !== undefined &&
      lastWatchedDate &&
      !watchedIds.some((watchedId) => watchedId === currentVideo.id) &&
      masterClassVideo &&
      watchedIds?.some((id) => id === masterClassVideo?.id + 1)
    ) {
      return true;
    } else if (
      lastWatchedId !== undefined &&
      lastWatchedDate &&
      !watchedIds.some((watchedId) => watchedId === currentVideo.id) &&
      watchedIds[watchedIds.length - 1] + 1 === currentVideo.id &&
      getRestTime(lastWatchedDate, isSubscriptionExpired) === 0
    ) {
      return true;
    }
  }
  return false;
};

export const getTitle = (title: CoursesTitle) => {
  if (title === CoursesTitle.Foundations) {
    return 'Remote Work Fundamentals';
  } else if (title === CoursesTitle.Fundamentals) {
    return 'Fundamentals';
  } else if (title === CoursesTitle.FundamentalsManager) {
    return 'Fundamentals (For Managers)';
  } else if (title === CoursesTitle.MasteringPersonalEffectiveness) {
    return 'Remote Work Professional';
  } else if (title === CoursesTitle.RemoteWorkProfessional) {
    return 'Mastery';
  } else if (title === CoursesTitle.RemoteWorkProfessionalManager) {
    return 'Mastery (For Managers)';
  }
};

export const getCourseInfoText = (title?: CoursesTitle) => {
  if (title === CoursesTitle.Foundations) {
    return 'These videos provide you with the information you need to become measurably more effective at unstructured ambiguous work in the context of your broader life';
  } else if (title === CoursesTitle.Fundamentals) {
    return 'Core skills to help you thrive in unstructured ambiguous work environments';
  } else if (title === CoursesTitle.FundamentalsManager) {
    return 'Core skills to help you thrive as a manager in unstructured ambiguous work environments';
  } else if (title === CoursesTitle.MasteringPersonalEffectiveness) {
    return 'An in depth program of change to help you embed the skills you need to thrive at unstructured ambiguous work';
  } else if (title === CoursesTitle.RemoteWorkProfessional) {
    return 'Master organization, control, motivation, balance and resilience - all in sub-10 minute Learn/Do Experiences';
  } else if (title === CoursesTitle.RemoteWorkProfessionalManager) {
    return 'Master the skills of organization, control, motivation, balance and resilience - all in sub-10 minute Learn/Do Experiences for you and your team.';
  } else {
    return 'These videos provide you with the information you need to become measurably more effective at unstructured ambiguous work in the context of your broader life';
  }
};

export const getRestTime = (
  lastWatched: string | Date | undefined | null,
  isSubscriptionExpired?: boolean,
): number => {
  if (!isSubscriptionExpired || !lastWatched) {
    return 0;
  }
  const time = new Date(lastWatched);
  if (isToday(time)) {
    const nextDay = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1,
      0,
      0,
    );
    return (
      Math.abs(
        differenceInMinutes(
          new Date(),
          new Date(nextDay.getFullYear(), nextDay.getMonth(), nextDay.getDate(), 0, 0),
        ),
      ) / 60
    );
  } else {
    return 0;
  }
};

export const isNextAvailable = (
  title: CoursesTitle,
  video: CoursesVideo,
  lastWatchedVideoId: number | undefined | null,
  currentConst?: CoursesConsts | null,
) => {
  if (
    title === CoursesTitle.Foundations ||
    title === CoursesTitle.Fundamentals ||
    title === CoursesTitle.FundamentalsManager
  ) {
    return (
      (lastWatchedVideoId === null || lastWatchedVideoId === undefined
        ? -1
        : Number(lastWatchedVideoId)) +
        1 ===
      video.id
    );
  } else {
    if (!lastWatchedVideoId) {
      return checkCourseSpecialIdsEqual(video.id, 13);
    }
    if (lastWatchedVideoId + 1 === video.id) {
      return true;
    } else if (
      video.id === lastWatchedVideoId + 2 &&
      currentConst?.videos
        .find((constVideo) => constVideo.id === lastWatchedVideoId + 1)
        ?.name.indexOf('Masterclass') !== -1
    ) {
      return true;
    }

    return false;
  }
};

export const getIsRecommendedAvailable = (
  video: CoursesVideo,
  lastWatchedVideoIds?: number[] | null,
  currentConst?: CoursesConsts | null,
) => {
  // if (!currentConst?.isCourseAvailable) {
  //   return false;
  // }

  const masterClassVideo = currentConst?.videos.find(
    (constVideo) => constVideo.id === video.id && constVideo?.name.indexOf('Masterclass') !== -1,
  );

  if (masterClassVideo && lastWatchedVideoIds?.some((id) => id === masterClassVideo?.id + 1)) {
    return true;
  }
};

export const getPreviewVideo = (
  title: CoursesTitle,
  currentVar?: CoursesVars,
  currentConst?: CoursesConsts | null,
  isSubscriptionExpired?: boolean,
  isFoundationDone?: boolean,
): CoursesVideo | undefined => {
  if (
    !currentVar?.dateLastWatched &&
    (title === CoursesTitle.Fundamentals ||
      title === CoursesTitle.Foundations ||
      title === CoursesTitle.FundamentalsManager)
  ) {
    return currentConst?.videos[0];
  }

  if (
    isFoundationDone &&
    (title === CoursesTitle.RemoteWorkProfessional ||
      title === CoursesTitle.MasteringPersonalEffectiveness ||
      title === CoursesTitle.RemoteWorkProfessionalManager) &&
    (checkCourseSpecialIdsEqual(currentVar?.lastWatchedId, 12) || !currentVar?.lastWatchedId)
  ) {
    return currentConst?.videos[1];
  }
  const lastWatchedVideoId = currentVar?.watchedVideoIds?.length
    ? currentVar?.watchedVideoIds[currentVar?.watchedVideoIds?.length - 1]
    : undefined;
  if (getRestTime(currentVar?.dateLastWatched, isSubscriptionExpired) === 0) {
    if (
      title === CoursesTitle.Fundamentals ||
      title === CoursesTitle.Foundations ||
      title === CoursesTitle.FundamentalsManager
    ) {
      return (
        currentConst?.videos.find((video) => {
          return video.id === (lastWatchedVideoId || 0) + 1;
        }) || currentConst?.videos[0]
      );
    } else {
      if (
        !isFoundationDone &&
        (title === CoursesTitle.MasteringPersonalEffectiveness ||
          title === CoursesTitle.RemoteWorkProfessional ||
          title === CoursesTitle.RemoteWorkProfessionalManager)
      ) {
        return currentConst?.videos[0];
      }
      return (
        currentConst?.videos.find((video) => {
          return (
            video.id ===
            (lastWatchedVideoId ||
              (isFoundationDone
                ? title === CoursesTitle.MasteringPersonalEffectiveness
                  ? 12
                  : title === CoursesTitle.RemoteWorkProfessional
                  ? 91
                  : 190
                : title === CoursesTitle.MasteringPersonalEffectiveness
                ? 11
                : title === CoursesTitle.RemoteWorkProfessional
                ? 90
                : 189)) +
              1
          );
        }) || currentConst?.videos[1]
      );
    }
  } else {
    if (checkCourseSpecialIdsEqual(currentVar?.lastWatchedId, 10)) {
      return (
        currentConst?.videos.find((video) => checkCourseSpecialIdsEqual(video.id, 11)) ||
        currentConst?.videos[0]
      );
    }
    return (
      currentConst?.videos.find((video) => video.id === lastWatchedVideoId) ||
      currentConst?.videos[0]
    );
  }
};

export const getIsReadyForTest = (
  title: CoursesTitle,
  currentVar?: CoursesVars,
  currentConst?: CoursesConsts | null,
) => {
  if (
    title === CoursesTitle.Foundations ||
    title === CoursesTitle.Fundamentals ||
    title === CoursesTitle.FundamentalsManager
  ) {
    if (!!currentConst?.videos?.length) {
      const masterclassVideoCount = currentConst?.videos.filter(
        (video) => video.name.indexOf('Masterclass') !== -1,
      ).length;

      const isAllVideoWatched =
        !!currentVar?.watchedVideoIds?.length &&
        (currentVar?.watchedVideoIds?.length ===
          currentConst?.videos?.length - masterclassVideoCount ||
          currentVar?.watchedVideoIds?.length >
            currentConst?.videos?.length - masterclassVideoCount);

      return isAllVideoWatched;
    }

    return false;
  } else if (currentVar?.watchedVideoIds) {
    const shouldWatchVideos = currentConst?.videos
      .filter((video) => {
        return video.name.indexOf('Masterclass') === -1;
      })
      .filter((video) => !checkCourseSpecialIdsEqual(video.id, 12));

    const isAllNeedVideoWatched = shouldWatchVideos?.filter((video) => {
      return !currentVar?.watchedVideoIds?.some((watchedVideoId) => watchedVideoId === video.id);
    });

    const isLastVideoWatched =
      title === CoursesTitle.MasteringPersonalEffectiveness ||
      title === CoursesTitle.RemoteWorkProfessional ||
      title === CoursesTitle.RemoteWorkProfessionalManager
        ? currentConst?.videos[currentConst?.videos.length - 1].id ===
          currentVar?.watchedVideoIds[currentVar?.watchedVideoIds?.length - 1]
        : currentConst?.videos[currentConst?.videos.length - 2].id ===
          currentVar?.watchedVideoIds[currentVar?.watchedVideoIds?.length - 1];

    return isLastVideoWatched && isAllNeedVideoWatched?.length === 0;
  } else {
    return false;
  }
};

export const checkCourseSpecialIdsEqual = (
  checkNumber: number | undefined | null,
  oldSpecialNumber: number,
) => {
  if (!checkNumber) {
    return false;
  }
  switch (oldSpecialNumber) {
    case 0:
      return checkNumber === 0 || checkNumber === 79 || checkNumber === 181;
    case 10:
      return checkNumber === 10 || checkNumber === 89 || checkNumber === 193;
    case 11:
      return checkNumber === 11 || checkNumber === 90 || checkNumber === 194;
    case 12:
      return checkNumber === 12 || checkNumber === 91 || checkNumber === 195;
    case 13:
      return checkNumber === 13 || checkNumber === 92 || checkNumber === 196;
    default:
      return false;
  }
};

export const getWorkbookSize = (sectionName: SectionName): string => {
  switch (sectionName) {
    case 'Core_Skills':
      return 'PDF, 1 MB';
    case 'Take_Back_Control_of_Your_Day':
      return 'PDF, 231 KB';
    case 'Find_Meaning_in_Your_Day':
      return 'PDF, 198 KB';
    case 'Integrate_Work_With_Your_Life':
      return 'PDF, 213 KB';
    case 'Master_Your_Unpredictable_Day':
      return 'PDF, 213 KB';

    default:
      return 'PDF, 200 KB';
  }
};
