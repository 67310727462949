import React, { Component, ErrorInfo, ReactNode } from 'react'

interface Props {
  children: ReactNode
  isReset?: boolean
}

interface State {
  hasError: boolean
  errorCount: number
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorCount: 0
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      errorCount: 0
    }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    this.setState({
      errorCount: this.state.errorCount + 1
    })
  }

  public render() {
    if (this.state.hasError && !this.props.isReset) {
      return <h1>Sorry.. there was an error</h1>
    }

    return (
      <React.Fragment key={this.state.errorCount}>
        {this.props.children}
      </React.Fragment>
    )
  }
}

export default ErrorBoundary
