import { OperationType } from './index';
import { Entity } from '../../core/types/entity';

export function updatedCacheData<T extends Entity>(
  cacheData: Array<T>,
  entity: T | undefined | null,
  operation: OperationType,
): Array<T> {
  if (entity) {
    switch (operation) {
      case OperationType.CREATE:
        if (cacheData.some((data) => data.id === entity.id)) {
          return [...cacheData];
        }
        return [...cacheData, entity];
      case OperationType.UPDATE:
        return [entity, ...cacheData.filter((item) => item.id !== entity.id)];
      case OperationType.DELETE:
        return [...cacheData.filter((item) => item.id !== entity.id)];
      case OperationType.ARCHIVE:
        return [...cacheData.filter((item) => item.id !== entity.id)];
      default:
        return cacheData;
    }
  }
  return cacheData;
}
