import React from 'react';
import styled from 'styled-components';
import { ReactComponent as InfoIcon } from '../../../../core/svg/info.svg';
import { Button } from 'antd';
import { USER_PLANS_PATH } from '../../../../core/constants/routePaths';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { STRIPE_STATE, StripeValue } from '../../../../apollo/stateFields/stripe/stripeFields';

const StyledInfoWrapper = styled.div`
  margin: 12px 0 16px;
  padding: 15px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background: rgba(255, 194, 39, 0.25);
  border-radius: 4px;
`;
const StyledTextWrapper = styled.div`
  width: 70%;
  position: relative;
  padding-left: 36px;
`;
const StyledInfoIcon = styled(InfoIcon)`
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  margin-right: 15px;
`;
const StyledTitle = styled.div`
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`;
const StyledSubTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`;

const StyledButton = styled(Button)`
  border: 1px solid transparent;
  border-radius: 36px;
  height: 32px;
  padding: 9px 16px;

  font-weight: 700;
  font-size: 12px;
  line-height: 14px;

  &:focus,
  &:hover,
  &:active {
    background-color: var(--color-dark-blue);
    color: #fff;
  }
  background-color: var(--color-dark-blue);
  color: #fff;
`;

const SubscriptionExpiredInfo = () => {
  const history = useHistory();
  const { data: stripeData } = useQuery(STRIPE_STATE);
  const { expiredSubscriptionText }: StripeValue = stripeData?.stripe;

  return (
    <StyledInfoWrapper>
      <StyledTextWrapper>
        <StyledInfoIcon />
        <StyledTitle>{`Your ${expiredSubscriptionText}`}</StyledTitle>
        <StyledSubTitle>
          You cannot create new Spaces or add more than one Space to the Board at the same time.
        </StyledSubTitle>
      </StyledTextWrapper>
      <StyledButton
        onClick={() => {
          history.push(USER_PLANS_PATH);
        }}
      >
        Choose a plan
      </StyledButton>
    </StyledInfoWrapper>
  );
};

export default SubscriptionExpiredInfo;
