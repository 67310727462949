import { utilsInitialValue, utilsVar } from './utilsFields';

const resetUtils = () => {
  const value = {
    ...utilsInitialValue,
  };
  utilsVar(value);
};

const setSourceDragStartColumn = (payload: string | null) => {
  const value = {
    ...utilsVar(),
    sourceDragStartColumn: payload,
  };
  utilsVar(value);
};

export { setSourceDragStartColumn, resetUtils };
