import React, { SVGAttributes, FC } from "react";

export const CircleIcon: FC<SVGAttributes<SVGElement>> = ({ color = '#20B4F3', ...props }) => {
  return (
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_i)">
              <circle cx="6" cy="6" r="6" fill={color}/>
          </g>
          <circle cx="6" cy="6" r="5.5" stroke="black" strokeOpacity="0.2"/>
          <defs>
              <filter id="filter0_i" x="0" y="0" width="12" height="16" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="4"/>
                  <feGaussianBlur stdDeviation="2"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"/>
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
              </filter>
          </defs>
      </svg>
  );
};
