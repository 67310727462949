import { gql } from '@apollo/client';

import { ACTION_FIELDS } from 'features/actions/graphql/fragments';
import { TAG_FIELDS } from 'features/common/graphql/fragments';
import { OUTCOME_FIELDS } from 'features/outcomes/graphql/fragments';
import {
  USER_INVITE_FIELDS,
  WORKSPACE_USER_FIELDS,
  WORKSPACE_USER_INVITE_FIELDS,
} from './fragments';

export const FILTER_USER_WORKSPACES = gql`
  query filterUserWorkspaces($filters: [FilterUserWorkspacesInput]!) {
    filterUserWorkspaces(filters: $filters) {
      id
      name
      permission
      isNewShared
      actions {
        ...actionFields
      }
      outcomes {
        ...outcomeFields
      }
      tags {
        ...tagFields
      }
    }
  }
  ${ACTION_FIELDS}
  ${OUTCOME_FIELDS}
  ${TAG_FIELDS}
`;

export const FETCH_USER_INVITES = gql`
  query FetchUserInvites {
    fetchUserInvites {
      ...userInviteFields
    }
  }
  ${USER_INVITE_FIELDS}
`;

export const FETCH_WORKSPACE_INVITES_BY_ID = gql`
  query FetchWorkspaceInvitesById($workspaceId: Int!) {
    fetchWorkspaceInvitesById(workspaceId: $workspaceId) {
      count
      users {
        ...workspaceUserInviteFields
      }
    }
  }
  ${WORKSPACE_USER_INVITE_FIELDS}
`;

export const FETCH_WORKSPACE_INVITES_BY_IDS = gql`
  query FetchWorkspaceInvitesByIds($workspaceIds: [Int!]) {
    fetchWorkspaceInvitesByIds(workspaceIds: $workspaceIds) {
      count
      users {
        ...workspaceUserInviteFields
      }
      workspaceId
    }
  }
  ${WORKSPACE_USER_INVITE_FIELDS}
`;

export const SEARCH_USER_INVITES = gql`
  query SearchUserInvites($query: String! = "") {
    searchUserInvites(query: $query) {
      ...userFields
    }
  }
  ${WORKSPACE_USER_FIELDS}
`;
