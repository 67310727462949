import { useMutation } from '@apollo/client';
import { FETCH_ALL_NOTES, FETCH_NOTES } from '../../actions/graphql/queries';
import { DELETE_NOTE } from '../../actions/graphql/mutations';
import { DeleteNote, FetchUserWorkspaceNotes, FetchUserWorkspacesNotes } from '../../actions/types';
import { Note } from '../../../core/types/note';
import { OperationType, updatedCacheData } from '../../utils';
import { ColumnType, dndVar } from 'apollo/stateFields/dnd/dndFields';
import { dndDispatch } from '../../../context/dndContext/dndContext';
import { deleteEntityId } from '../../../context/dndContext/DndActions';

const useDeleteNote = (noteToDelete?: Note | null) => {
  const dispatch = dndDispatch;
  return useMutation<DeleteNote>(DELETE_NOTE, {
    update(cache) {
      const { fetchUserWorkspacesNotes = [] } =
        cache.readQuery<FetchUserWorkspacesNotes>({
          query: FETCH_ALL_NOTES,
        }) || {};

      const { fetchUserWorkspaceNotes = [] } =
        cache.readQuery<FetchUserWorkspaceNotes>({
          query: FETCH_NOTES,
          variables: {
            workspaceId: noteToDelete?.workspaceId,
          },
        }) || {};

      cache.writeQuery<FetchUserWorkspacesNotes>({
        query: FETCH_ALL_NOTES,
        data: {
          fetchUserWorkspacesNotes: updatedCacheData(
            fetchUserWorkspacesNotes,
            noteToDelete,
            OperationType.DELETE,
          ),
        },
      });

      cache.writeQuery<FetchUserWorkspaceNotes>({
        query: FETCH_NOTES,
        variables: {
          workspaceId: noteToDelete?.workspaceId,
        },
        data: {
          fetchUserWorkspaceNotes: updatedCacheData(
            fetchUserWorkspaceNotes,
            noteToDelete,
            OperationType.DELETE,
          ),
        },
      });

      if (noteToDelete) {
        dispatch(
          deleteEntityId({
            entityId: noteToDelete.id,
            columnTitle: ColumnType.Note,
          }),
        );
      }
    },
  });
};

export default useDeleteNote;
