import React from 'react';
import styled, { css } from 'styled-components';
import { Typography, Collapse } from 'antd';

import theme from 'core/styles/styled-components/theme';
import { PageView } from 'pages';
import FAQs from './FAQs';
import { ReactComponent as OpenIcon } from './svg/open.svg';
import { ReactComponent as CloseIcon } from './svg/close.svg';
import { FAQ as FAQType } from './types';

const { Title } = Typography;
const { Panel } = Collapse;

const commonIconStyles = css`
  &&&& {
    order: 1;
    position: static;
    padding: 0;
    width: 1.5rem;

    @media ${theme.device.mobile.max} {
      width: 1.72rem;
    }
  }
`;

const StyledContainer = styled.div``;

const StyledTitle = styled(Title)`
  && {
    margin-bottom: 1.334rem;
    font-size: 1.77rem;
    font-weight: 700;
  }
`;

const StyledCollapse = styled(Collapse)`
  &&& {
    background-color: transparent;

    .ant-collapse-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 0.889rem 0;
    }

    .ant-collapse-arrow {
      transform: none;
    }

    .ant-collapse-item {
      border-bottom-color: var(--color-main-light-grey);

      &:not(:first-child) {
        margin-top: 0.889rem;
      }
    }

    .ant-collapse-content-box {
      padding: 0 0 0.889rem 0;
    }
  }
`;

const StyledPanel = styled(Panel)``;

const StyledPanelHeader = styled.div`
  flex: 1;
  display: inline-flex;
`;

const StyledPanelContent = styled.div`
  line-height: 1.15;
  font-size: 1rem;

  & {
    > p:last-child {
      margin: 0;
    }

    > ul {
      margin: 0;
    }
  }

  @media ${theme.device.tablet.min} {
    line-height: 1.5;
  }
`;

const StyledQuestion = styled.span`
  margin-right: 1.714rem;
  line-height: 1.3;
  color: var(--color-main-dark-blue);
  font-size: 1.334rem;
  font-weight: 600;

  @media ${theme.device.mobile.max} {
    font-size: 1.45rem;
  }
`;

const StyledOpenIcon = styled(OpenIcon)`
  ${commonIconStyles}
`;

const StyledCloseIcon = styled(CloseIcon)`
  ${commonIconStyles}
`;

const renderFAQPanel = ({ question, answer }: FAQType, index: number) => (
  <StyledPanel
    key={index}
    header={
      <StyledPanelHeader>
        <StyledQuestion>{question}</StyledQuestion>
      </StyledPanelHeader>
    }
  >
    <StyledPanelContent>{answer}</StyledPanelContent>
  </StyledPanel>
);

const FAQ = () => (
  <PageView>
    <StyledContainer>
      <StyledTitle>F.A.Q.</StyledTitle>
      <StyledCollapse
        bordered={false}
        expandIcon={({ isActive }) => (isActive ? <StyledCloseIcon /> : <StyledOpenIcon />)}
        expandIconPosition="right"
      >
        {FAQs.map(renderFAQPanel)}
      </StyledCollapse>
    </StyledContainer>
  </PageView>
);

export { FAQ };
