import React, { useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Button, Divider, Dropdown, Menu } from 'antd';
import { kebabCase, noop } from 'lodash-es';

import { ReactComponent as ContextMenuIcon } from './svg/dots.svg';
import { CardProps } from './types';
import { SharedPeopleIcon } from '../../../../features/workspaces/components/ShareWorkspace/SharedPeopleIcon/SharedPeopleIcon';
import { ContextMenuOptions } from '../../../../features/workspaces/components/HeaderTagFilter/constants';
import { LeftOutlined } from '@ant-design/icons';
import { FocusTimerStatus } from '../../../../features/actions/components/ActionBoard/ActionCard/FocusTimer/types';

const StyledCardWrapper = styled.div`
  && {
    padding-bottom: 2px;
  }
`;

const StyledContainer = styled.div<{
  noBg?: boolean;
  focusTimerStatus?: FocusTimerStatus | undefined;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 8px;
  outline: none;

  ${({ noBg }) =>
    !noBg &&
    css`
      background: linear-gradient(
        180deg,
        rgba(252, 252, 252, 0.9) 0%,
        rgba(255, 255, 255, 0.9) 100%
      );
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 3px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    `}

  ${({ focusTimerStatus }) => {
    if (focusTimerStatus === FocusTimerStatus.ACTIVE) {
      return css`
        background: rgba(32, 38, 53, 0.84);
        border: 1px solid rgba(255, 255, 255, 0.15);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
      `;
    } else if (focusTimerStatus === FocusTimerStatus.PAUSED) {
      return css`
        background: #57677a;
        border: 1px solid rgba(255, 255, 255, 0.15);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
      `;
    }
  }}

  // For some reason, Ant Design doesn't allow passing a custom className prop to the
  // Dropdown, making the task of styling it impossible (styled-components depends
  // on the className prop to apply styles).
  & .ant-dropdown {
    z-index: 1;
  }
`;

const StyledHeader = styled.div`
  padding-right: 1rem;
`;

const StyledSubMenuTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  padding: 5px 25px;
  line-height: unset;

  &:hover {
    background-color: #e0e0e0;
  }

  span {
    align-items: center;
    display: flex;
    position: relative;

    & > svg {
      height: 16px;
      left: -25px;
      position: absolute;
    }
  }
`;

const StyledContextMenuButton = styled(Button)<{ isFocusTimer?: boolean }>`
  position: absolute;
  top: 3px;
  right: 0;
  width: 20px;
  height: 20px;
  min-width: 0;
  line-height: 1;
  background-color: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isFocusTimer }) => {
    if (isFocusTimer) {
      return css`
        color: var(--color-white);
      `;
    } else {
      return css`
        color: #2a3246;
      `;
    }
  }}
`;

const StyledContextMenuIcon = styled(ContextMenuIcon)`
  cursor: pointer;
`;

const StyledMenu = styled(Menu)`
  background-color: var(--color-main-grey-2);
`;

const StyledSubMenu = styled(Menu.SubMenu)`
  && {
    position: relative;

    .ant-dropdown-menu-submenu-expand-icon {
      display: none !important;
    }
    .ant-dropdown-menu-submenu-title {
      display: flex;
      font-size: 12px;
      font-weight: 700;
      padding: 5px 25px;
      line-height: unset;

      &:hover {
        background-color: #e0e0e0;
      }

      span {
        align-items: center;
        display: flex;
        position: relative;

        & > svg {
          height: 16px;
          left: -20px;
          position: absolute;
        }
      }
    }
  }
  .ant-dropdown-menu-submenu-popup ul {
    //background-color: var(--color-main-grey-2);
  }
`;

const StyledMenuOption = styled(({ highlighted, ...rest }) => <Menu.Item {...rest} />)<{
  highlighted?: boolean;
}>`
  font-size: 12px;
  font-weight: ${(props) => (props.highlighted ? 700 : 400)};
  padding: 5px 25px;
  line-height: unset;

  &:hover {
    background-color: #e0e0e0;
  }

  span {
    align-items: center;
    display: flex;
    position: relative;

    & > svg {
      left: -20px;
      position: absolute;
    }
  }
`;

const StyledSubMenuOption = styled(({ highlighted, ...rest }) => <Menu.Item {...rest} />)<{
  highlighted?: boolean;
}>`
  font-size: 12px;
  font-weight: ${(props) => (props.highlighted ? 700 : 400)};
  padding: 5px 10px 5px 25px;
  line-height: unset;

  &:hover {
    background-color: #e0e0e0;
  }

  span {
    align-items: center;
    display: flex;
    position: relative;

    & > svg {
      height: 16px;
      left: -20px;
      position: absolute;
    }
  }
`;

export const renderDefaultContextMenu: CardProps['renderContextMenu'] = (
  options,
  onOptionClick,
  highlightedContextMenuOptions = [],
  highlightedContextSubMenuOptions = [],
  item?: any,
): JSX.Element => {
  return (
    <StyledMenu>
      {options.map((option, index) => {
        return (
          <React.Fragment key={option.label + index}>
            {option?.children ? (
              <StyledSubMenu
                key={kebabCase(option.label)}
                title={
                  <>
                    <LeftOutlined />
                    <span>{option.label}</span>
                  </>
                }
              >
                {option?.children.map((childrenOpt) => {
                  return (
                    <StyledSubMenuOption
                      key={kebabCase(childrenOpt.label)}
                      onClick={() => onOptionClick(childrenOpt, item)}
                      highlighted={
                        !!highlightedContextSubMenuOptions.find((opt) => opt === childrenOpt.label)
                      }
                    >
                      {childrenOpt.icon && <childrenOpt.icon />} {childrenOpt.label}
                    </StyledSubMenuOption>
                  );
                })}
              </StyledSubMenu>
            ) : option.label === 'Divider' ? (
              <Divider key={kebabCase(option.label) + index} style={{ margin: 0 }} />
            ) : (
              <StyledMenuOption
                disabled={option?.disabled}
                key={kebabCase(option.label)}
                onClick={() => onOptionClick(option, item)}
                highlighted={!!highlightedContextMenuOptions.find((opt) => opt === option)}
              >
                {option.icon && <option.icon />} {option.label}
              </StyledMenuOption>
            )}
          </React.Fragment>
        );
      })}
    </StyledMenu>
  );
};

export const renderContextMenuWithSharedCount: CardProps['renderContextMenu'] = (
  options,
  onOptionClick,
  highlightedContextMenuOptions = [],
  highlightedContextSubMenuOptions = [],
  item,
  count,
): JSX.Element => {
  return (
    <StyledMenu>
      {options.map((option) => (
        <StyledMenuOption
          key={kebabCase(option.label)}
          onClick={() => onOptionClick(option, item)}
          highlighted={!!highlightedContextMenuOptions.find((opt) => opt === option)}
        >
          {option.icon && <option.icon />} {option.label}{' '}
          {!!count && count > 1 && option.label === ContextMenuOptions.SHARE.label && (
            <SharedPeopleIcon count={count} />
          )}
        </StyledMenuOption>
      ))}
    </StyledMenu>
  );
};

const Card = ({
  className,
  headerContent,
  children,
  noBg = false,
  onClick,
  onDoubleClick,
  contextMenuOptions = [],
  renderContextMenu = renderDefaultContextMenu,
  onContextMenuOptionClick = noop,
  highlightedContextMenuOptions = [],
  highlightedContextSubMenuOptions = [],
  sharedCount,
  disabled,
  focusTimerStatus,
}: CardProps) => {
  const containerRef = useRef(null);

  const getDropdownContainer = useCallback(() => {
    //  previous var ==> containerRef.current || document.body
    return document.body;
  }, []);

  return (
    <StyledCardWrapper>
      <StyledContainer
        ref={containerRef}
        className={className}
        noBg={noBg}
        onClick={onClick}
        onDoubleClick={(e) => {
          if (!disabled && onDoubleClick) {
            onDoubleClick(e);
          }
        }}
        focusTimerStatus={focusTimerStatus}
      >
        <StyledHeader>{headerContent}</StyledHeader>
        {contextMenuOptions.length > 0 && (
          <Dropdown
            disabled={disabled}
            trigger={['click']}
            overlay={renderContextMenu(
              contextMenuOptions,
              onContextMenuOptionClick,
              highlightedContextMenuOptions,
              highlightedContextSubMenuOptions,
              undefined,
              sharedCount,
            )}
            getPopupContainer={getDropdownContainer}
          >
            <StyledContextMenuButton
              data-testid="three-dots-menu"
              className="options-button"
              type="text"
              icon={<StyledContextMenuIcon />}
              isFocusTimer={!!focusTimerStatus}
            />
          </Dropdown>
        )}
        {children}
      </StyledContainer>
    </StyledCardWrapper>
  );
};

export { Card };
