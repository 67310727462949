import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../core/styles/styled-components';
import { STRIPE_STATE, StripeValue } from '../../../apollo/stateFields/stripe/stripeFields';
import { useQuery } from '@apollo/client';

const StyledFeaturesContainer = styled.div`
  margin-top: 28px;
  padding: 20px;
  background: #ffffff;
  border-radius: 12px;
  color: var(--color-dark-blue);

  @media ${theme.device.tablet.min} {
    max-width: 430px;
  }

  @media ${theme.device.desktop.min} {
    max-width: 570px;
  }
`;

const StyledTitle = styled.div`
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
`;

const StyledFeature = styled.div`
  position: relative;
  margin-left: 10px;
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  &:before {
    position: absolute;
    top: 6px;
    left: -10px;
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: var(--color-dark-blue);
  }
`;

const featureList = [
  'Remote Work Fundamentals and Remote Work Professional certifications are not available',
  'Workbooks are not available',
  'Hacks, tips and tricks are not available',
  'You can only access one video every 24 hours',
  'You can only view one Space at a time',
  'You cannot create a new Space',
  'You cannot add new members to an existing Space',
];

const NotAvailableFeatures = () => {
  const { data: stripeData } = useQuery(STRIPE_STATE);
  const { isSubscriptionExpired, expiredSubscriptionText }: StripeValue = stripeData?.stripe;

  if (!isSubscriptionExpired) {
    return null;
  }

  return (
    <StyledFeaturesContainer>
      <StyledTitle>
        Your {expiredSubscriptionText ? expiredSubscriptionText : 'free trial has ended'} and the
        following limitations are in place until you buy a plan:
      </StyledTitle>
      <>
        {featureList.map((feature) => (
          <StyledFeature>{feature}</StyledFeature>
        ))}
      </>
    </StyledFeaturesContainer>
  );
};

export default NotAvailableFeatures;
