import React from 'react';
import { NewInformationTab, NewInformationTabsProps } from './types';
import styled, { css } from 'styled-components';

const StyledContainer = styled.div`
  width: -webkit-fill-available;
  background: rgba(32, 38, 53, 0.06);
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.8), inset 0px 1px 2px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 4px;
  margin: 0 10px 10px 0;
`;

const StyledTab = styled.button<NewInformationTab>`
  width: 33.33%;
  height: 28px;
  outline: none;
  border: 1px solid transparent;
  background-color: transparent;
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
  position: relative;

  ${({ isDivider }) => isDivider && css`
    &:after {
      display: block;
      content: '';
      background: rgba(32, 38, 53, 0.1);
      width: 1px;
      height: 20px;
      position: absolute;
      top: 3px;
      left: 0;
    }
  `}
  
  ${({ isActive }) => isActive && css`
    background: linear-gradient(180deg, #FCFCFC 0%, #FFFFFF 100%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 3px;
    font-weight: bold;
    color: var(--color-main-dark-blue);
  `}
`;

const tabs = [
  {
    label: 'All',
    index: 0
  },
  {
    label: 'Not Assigned',
    index: 1
  },
  {
    label: 'Assigned',
    index: 2
  },
];

const NewInformationTabs = ({ onChange, activeTab }: NewInformationTabsProps) => {
  const handleChangeTab = (tab: number) => () => {
    onChange(tab);
  };

  const tabsEl = tabs.map(t => (
    <StyledTab
      onClick={handleChangeTab(t.index)}
      isActive={t.index === activeTab}
      isDivider={(activeTab === 0 && t.index === 2) || (activeTab === 2 && t.index === 1)}
    >
      {t.label}
    </StyledTab>
  ))
  return (
    <StyledContainer>
      {tabsEl}
    </StyledContainer>
  );
};

export default NewInformationTabs;