import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'antd';
import { ColumnActionCardProps } from './types';
import { getPriorityTitle, getTrimmedString } from './utils';
import { Tags } from './Tags';
import { CONTEXT_MENU_OPTIONS_BY_STATE, ContextMenuOptions, getFocusTimerMenu } from './constants';
import WorkspaceSelectMenu from '../../../../common/components/WorkspaceSelectMenu/WorkspaceSelectMenu';
import { sendEvent } from '../../../../../core/integrations/sentry/events';
import { EntityType } from '../../../../utils';
import { Permission } from '../../../../../core/types/workspace';
import { boardEditedEntityMutation } from '../../../../../apollo/stateFields/boardEditedEntity';
import {
  StyledActionBody,
  StyledActionName,
  StyledActionOutcome,
  StyledActionOutcomeWrapper,
  StyledActionWorkspace,
  StyledActionWorkspaceWrapper,
  StyledCol,
  StyledDates,
  StyledEntityCard,
  StyledHeadLeftContainer,
  StyledNameRow,
  StyledPreposition,
  StyledPriorityMenu,
  StyledSharedPeopleIcon,
  StyledTagsWrapper,
  StyledWorkspaceSelectContainer,
} from './StyledComponents';
import { useQuery } from '@apollo/client';
import { ERROR_STATE, ErrorValue } from '../../../../../apollo/stateFields/error/errorFields';
import styled, { css } from 'styled-components';
import theme from '../../../../../core/styles/styled-components/theme';
import FocusTimer from './FocusTimer/FocusTimer';
import { FetchFocusTimer } from '../../../types';
import { FETCH_FOCUS_TIMER } from '../../../graphql/queries';
import FocusSettingsModal, { FocusTimerType } from './FocusTimer/FocusSettingsModal';
import {
  BOARD_EDITED_ENTITY_STATE,
  BoardEditedEntityValue,
} from '../../../../../apollo/stateFields/boardEditedEntity/boardEditedEntityFileds';
import { getSpendTime } from './FocusTimer/utils';
import { FocusTimerStatus } from './FocusTimer/types';
import {
  HIGHLIGHTED_ENTITIES,
  HighlightedEntitiesValue,
} from '../../../../../apollo/stateFields/highlightedEnntities/highlightedEntityField';
import { FetchFocusTimerSettings } from '../../../../../graphql/types';
import { FETCH_FOCUS_TIMER_SETTINGS } from '../../../../../graphql/queries';

export const StyledName = styled(StyledActionName)<{ isFocusTimer?: boolean }>`
  max-width: 88%;

  ${({ isFocusTimer }) =>
    isFocusTimer &&
    css`
      color: var(--color-white);
    `}

  @media ${theme.device.desktop.min} {
    max-width: 200px;
  }
`;

const ColumnActionCard = React.memo(
  ({
    action,
    handleContextMenuOptionClick,
    onActionWorkspaceChange,
    workspaces,
    getWorkspaceInvitesById,
    ...restOfProps
  }: ColumnActionCardProps) => {
    const { data: boardEditedData } = useQuery(BOARD_EDITED_ENTITY_STATE);
    const { showFocusSettingsForActionId, focusTimerCounter }: BoardEditedEntityValue =
      boardEditedData?.boardEditedEntity;
    const { data: focusTimerData } = useQuery<FetchFocusTimer>(FETCH_FOCUS_TIMER);
    const { data: settingsData } = useQuery<FetchFocusTimerSettings>(FETCH_FOCUS_TIMER_SETTINGS);
    const { data: highlightData } = useQuery(HIGHLIGHTED_ENTITIES);
    const { hoveredOutcomeId }: HighlightedEntitiesValue = highlightData?.highlightedEntities;
    const { showEditActionModal } = boardEditedEntityMutation;
    const { data } = useQuery(ERROR_STATE);
    const { errorWorkspaces }: ErrorValue = data?.error;
    const actionHeaderRef = useRef<HTMLDivElement>(null);
    const [position, setPosition] = useState<{ x: number; y: number; width: number }>({
      x: 0,
      y: 0,
      width: 0,
    });
    const workspace = useMemo(
      () => workspaces.find((workspace) => workspace.id === action.workspaceId),
      [workspaces, action],
    );
    const workspaceName = workspace?.name
      ? getTrimmedString(workspace?.name, 41)
      : !!errorWorkspaces?.find((w) => Number(w.id) === action?.workspaceId)?.name
      ? getTrimmedString(
          errorWorkspaces?.find((w) => Number(w.id) === action?.workspaceId)?.name,
          41,
        )
      : '';
    const viewerPermission = workspace?.permission === Permission.VIEWER || false;
    const editorPermission =
      workspaces.find((workspace) => workspace.id === action?.workspaceId)?.permission ===
        Permission.EDITOR || false;
    const outcomeName = action?.outcome?.name
      ? getTrimmedString(action.outcome.name, 49)
      : undefined;

    const sharedUsersCount = getWorkspaceInvitesById(action.workspaceId)?.count || 0;
    const isShared = sharedUsersCount > 1;
    const handleOptionClick = handleContextMenuOptionClick(action);

    // useEffect(() => {
    //   if (loadingMoveAction) {
    //     dispatch(setChangingSpaceEntityId(action.id));
    //   } else {
    //     dispatch(setChangingSpaceEntityId(null));
    //   }
    // }, [loadingMoveAction]);

    const loadingMoveAction = false;
    const focusTimerStatus =
      action.id === focusTimerData?.fetchFocusTimer?.actionId
        ? focusTimerData?.fetchFocusTimer?.status || undefined
        : undefined;
    const focusTimerMenu = getFocusTimerMenu(
      action,
      focusTimerData?.fetchFocusTimer,
      settingsData?.fetchUserSettings?.focusTimerType,
    );

    const fixedPeriod = focusTimerData?.fetchFocusTimer?.fixedPeriodSelected
      ? focusTimerData?.fetchFocusTimer?.fixedPeriodSelected * 1000
      : 1.8e6;
    const spendTime = getSpendTime(
      focusTimerData?.fetchFocusTimer?.startTime ? focusTimerData?.fetchFocusTimer.startTime : 0,
      focusTimerData?.fetchFocusTimer?.resumeTime,
      focusTimerData?.fetchFocusTimer?.fixedPeriodSelected,
      focusTimerData?.fetchFocusTimer?.spendTime,
      focusTimerData?.fetchFocusTimer?.status,
    );

    useEffect(() => {
      if (actionHeaderRef.current) {
        setPosition({
          x: actionHeaderRef.current.getBoundingClientRect().right,
          y: actionHeaderRef.current.getBoundingClientRect().top,
          width: actionHeaderRef.current.getBoundingClientRect().width + 40,
        });
      }
    }, [showFocusSettingsForActionId]);

    return (
      <>
        <StyledEntityCard
          isTransparent={
            !!hoveredOutcomeId
              ? action?.outcome?.id
                ? hoveredOutcomeId !== action.outcome.id
                : true
              : null
          }
          isShared={isShared}
          disabled={loadingMoveAction}
          draggableId={'' + action.id}
          entity={action}
          entityName={EntityType.ACTION}
          isViewOnly={viewerPermission}
          focusTimerStatus={
            focusTimerStatus === FocusTimerStatus.ACTIVE &&
            spendTime >= fixedPeriod
              ? FocusTimerStatus.PAUSED
              : focusTimerStatus
          }
          title={
            <>
              <StyledNameRow ref={actionHeaderRef}>
                <StyledName isFocusTimer={!!focusTimerStatus}>{action.name}</StyledName>
                <StyledPriorityMenu
                  action={action}
                  handleContextMenuOptionClick={handleOptionClick}
                  disabled={viewerPermission || !!action.isArchived}
                />
              </StyledNameRow>
              <Row wrap={false} justify="space-between">
                <StyledCol flex={'none'} className="col--fill">
                  <StyledActionWorkspaceWrapper>
                    <StyledHeadLeftContainer className="col-fit">
                      {viewerPermission ? (
                        <StyledWorkspaceSelectContainer>
                          <StyledActionWorkspace
                            isShared={isShared}
                            isFocusTimer={!!focusTimerStatus}
                          >
                            {workspaceName}
                          </StyledActionWorkspace>
                        </StyledWorkspaceSelectContainer>
                      ) : (
                        <WorkspaceSelectMenu
                          isMultiSorted
                          disabled={loadingMoveAction || viewerPermission}
                          currentWorkspaceId={action.workspaceId}
                          onContextMenuOptionClick={async (option) => {
                            if (option?.value === action.workspaceId) {
                              return;
                            }
                            await onActionWorkspaceChange(action, option, action.workspaceId);
                            sendEvent('action-move', 'Update workspace of action');
                          }}
                        >
                          <StyledActionWorkspace
                            isShared={isShared}
                            isFocusTimer={!!focusTimerStatus}
                          >
                            {workspaceName}
                          </StyledActionWorkspace>
                        </WorkspaceSelectMenu>
                      )}
                      {isShared && (
                        <StyledSharedPeopleIcon
                          isFocusTimer={!!focusTimerStatus}
                          count={sharedUsersCount}
                        />
                      )}
                    </StyledHeadLeftContainer>
                    {action.tags && action.tags?.length > 0 && (
                      <StyledTagsWrapper className="col--fill">
                        <Tags
                          isFocusTimer={!!focusTimerStatus}
                          tags={action.tags}
                          loadingOtherComponent={
                            // loading
                            false
                          }
                        />
                      </StyledTagsWrapper>
                    )}
                  </StyledActionWorkspaceWrapper>
                </StyledCol>
              </Row>
            </>
          }
          onDoubleClick={() => showEditActionModal(action)}
          highlightedContextMenuOptions={[
            ContextMenuOptions.EDIT_ACTION,
            ContextMenuOptions.VIEW_ACTION,
            ContextMenuOptions.DUPLICATE_ACTION,
            ContextMenuOptions.ARCHIVE_ACTION,
            ContextMenuOptions.UNARCHIVE_ACTION,
            ContextMenuOptions.SET_PRIORITY,
          ]}
          highlightedContextSubMenuOptions={[getPriorityTitle(action.priority)]}
          contextMenuOptions={
            viewerPermission
              ? CONTEXT_MENU_OPTIONS_BY_STATE.FOR_VIEWER
              : [...CONTEXT_MENU_OPTIONS_BY_STATE.NOT_ARCHIVED_WITH_PRIORITY, ...focusTimerMenu]
          }
          onContextMenuOptionClick={handleOptionClick}
          {...restOfProps}
        >
          <StyledActionBody>
            {outcomeName && (
              <StyledActionOutcomeWrapper>
                <StyledPreposition>with</StyledPreposition>
                <StyledActionOutcome isFocusTimer={!!focusTimerStatus}>
                  {outcomeName}
                </StyledActionOutcome>
              </StyledActionOutcomeWrapper>
            )}
            <Row wrap={true} justify="space-between" align={'middle'}>
              <Col flex={'none'}>
                <StyledDates isFocusTimer={!!focusTimerStatus} action={action} />
              </Col>
            </Row>
            {action.id === focusTimerData?.fetchFocusTimer?.actionId && (
              <Row wrap={true} justify="space-between" align={'middle'}>
                <FocusTimer action={action} />
              </Row>
            )}
          </StyledActionBody>
        </StyledEntityCard>
        <FocusSettingsModal actionId={action.id} position={position} />
      </>
    );
  },
);

export default ColumnActionCard;
