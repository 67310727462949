import React from 'react';

export const OutcomeIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12.0001" r="9.5" stroke="#202635" />
      <circle cx="12" cy="12.0001" r="5.5" stroke="#202635" />
      <circle cx="12" cy="12.0001" r="2" fill="#202635" />
      <path
        d="M11.6464 11.6465C11.4512 11.8418 11.4512 12.1584 11.6464 12.3536C11.8417 12.5489 12.1583 12.5489 12.3536 12.3536L11.6464 11.6465ZM22 2.50009L22 9.80709e-05L21 9.60478e-05L21 2.50009L22 2.50009ZM21.5 3.00009L24 3.00009L24 2.00009L21.5 2.00009L21.5 3.00009ZM12.3536 12.3536L19.8535 4.85365L19.1464 4.14654L11.6464 11.6465L12.3536 12.3536ZM20 4.50009L20 2.00009L19 2.00011L19 4.5001L20 4.50009ZM19.5 5.0001L22 5.0001L22 4.0001L19.5 4.0001L19.5 5.0001ZM19.8535 4.85365L20.8536 3.85365L20.1465 3.14654L19.1464 4.14654L19.8535 4.85365ZM20.8536 3.85365L21.8536 2.85365L21.1465 2.14654L20.1465 3.14654L20.8536 3.85365ZM21 3.50009L21 1.0001L20 1.0001L20 3.5001L21 3.50009ZM20.5 4.0001L23 4.0001L23 3.0001L20.5 3.00009L20.5 4.0001Z"
        fill="#202635"
      />
    </svg>
  );
};
