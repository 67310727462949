import { OutcomeStatus } from 'core/types/outcome';
import { ContextMenuOptions } from './types';

//comment delete option for mvp demo
export const CONTEXT_MENU_OPTIONS_BY_STATUS = {
  ARCHIVED: [
    ContextMenuOptions.EDIT_OUTCOME,
    ContextMenuOptions.UNARCHIVE,
    // ContextMenuOptions.DELETE,
  ],
  FOR_VIEWER: [ContextMenuOptions.VIEW_OUTCOME],
  [OutcomeStatus.CURRENT]: [
    ContextMenuOptions.EDIT_OUTCOME,
    ContextMenuOptions.COMPLETE_OUTCOME,
    ContextMenuOptions.MAKE_FUTURE,
    ContextMenuOptions.ARCHIVE,
    // ContextMenuOptions.DELETE,
  ],
  [OutcomeStatus.COMPLETED]: [
    ContextMenuOptions.EDIT_OUTCOME,
    ContextMenuOptions.MAKE_CURRENT,
    ContextMenuOptions.MAKE_FUTURE,
    ContextMenuOptions.ARCHIVE,
    //ContextMenuOptions.DELETE,
  ],
  [OutcomeStatus.FUTURE]: [
    ContextMenuOptions.EDIT_OUTCOME,
    ContextMenuOptions.MAKE_CURRENT,
    ContextMenuOptions.COMPLETE_OUTCOME,
    ContextMenuOptions.ARCHIVE,
    //  ContextMenuOptions.DELETE,
  ],
};

export const CONTEXT_MENU_OPTION_TO_STATUS = {
  [ContextMenuOptions.MAKE_CURRENT.label]: OutcomeStatus.CURRENT,
  [ContextMenuOptions.MAKE_FUTURE.label]: OutcomeStatus.FUTURE,
  [ContextMenuOptions.COMPLETE_OUTCOME.label]: OutcomeStatus.COMPLETED,
};
