import { Tag, NewTagInput } from 'core/types/tag';
import { Action } from 'core/types/action';
import { Outcome } from 'core/types/outcome';
import { Note } from 'core/types/note';

export enum Permission {
  VIEWER = 'VIEWER',
  MOVER = 'MOVER',
  CONTRIBUTOR = 'CONTRIBUTOR',
  EDITOR = 'EDITOR',
  SECONDARY_OWNER = 'SECONDARY_OWNER',
  PRIMARY_OWNER = 'PRIMARY_OWNER',
}

enum UserRole {
  ADMIN,
  GUEST,
  OWNER,
  USER,
}

export interface User {
  assigneeActions?: [Action];
  assigneeOutcomes?: [Outcome];
  createDate?: string;
  email: string;
  externalAccount: string;
  externalIdentity?: string;
  firstName?: string;
  id: number;
  invitedUsersIds?: [number];
  isActivated?: boolean;
  lastName?: string;
  name?: string;
  permission: Permission;
  role: UserRole;
  updateDate?: string;
  workspaces?: [Workspace];
}

export interface Workspace {
  __typename?: 'Workspace';
  id: number;
  name: string;
  userId?: number;
  createDate?: string;
  updateDate?: string;
  description?: string;
  actions?: Action[];
  tags?: Tag[];
  outcomes?: Outcome[];
  notes?: Note[];
  permission: Permission;
  usersCount?: number;
  isNewShared?: boolean;
}

export interface WorkspaceWithCountOnly {
  __typename?: 'Workspace';
  id: number;
  name: string;
  userId?: number;
  createDate?: string;
  updateDate?: string;
  description?: string;
  permission: Permission;
  usersCount?: number;
  isNewShared?: boolean;
}

export interface NewWorkspaceInput {
  name: string;
  userId?: number;
  createDate?: string;
  updateDate?: string;
  description?: string;
  actionIds?: number[];
  tagIds?: number[];
  outcomeIds?: number[];
  noteIds?: number[];
  newTags?: NewTagInput[];
  permission?: Permission;
  isNewShared?: boolean;
}

export interface WorkspaceInput {
  id: number;
  name?: string;
  userId?: number;
  createDate?: string;
  updateDate?: string;
  description?: string;
  actionIds?: number[];
  tagIds?: number[];
  outcomeIds?: number[];
  noteIds?: number[];
  newTags?: NewTagInput[];
  permission?: Permission;
  isNewShared?: boolean;
}

export interface FilterUserWorkspacesInput {
  workspaceId: number;
  tagIds?: number[];
}

export interface FetchUserInvitesResult {
  createDate: string;
  description?: string;
  id: number;
  inviteFromUser: User;
  name: string;
  permission?: Permission;
  updateDate?: string;
  usersCount: number;
}

export interface FetchWorkspaceUserInvitesResult {
  email: string;
  externalIdentity: string;
  firstName?: string;
  id: number;
  isAcceptedInvite?: boolean;
  isActivated?: boolean;
  lastName?: string;
  name?: string;
  photoURL?: string;
  permission: Permission;
  workspace: Workspace;
}
