import React from 'react';
import { DatePickerField } from '../../../../../core/components/form';
import moment, { Moment } from 'moment';
import { disabledEndDate, disabledStartDate } from '../../../../../core/utils/date';
import { ArrowDownIcon, CloseIcon } from '../../../../../core/icons';
import styled from 'styled-components';
import { Button } from 'antd';

export const StyledLabel = styled.label`
  display: block;
  margin-bottom: 0.4285em;
  color: #202635;
  line-height: 1.1428;
  font-weight: 700;
`;

export const StyledDataRow = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: center;

  ${StyledLabel} {
    width: 70px;
    margin-bottom: 0;
  }

  .ant-picker {
    margin: 0;
  }
`;

export const StyledRemoveButton = styled(Button)<{ disabled?: boolean }>`
  min-width: 0;
  width: 24px;
  height: 24px;
  margin: 0 8px;
  padding: 0;
  border: none;

  svg path {
    stroke: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.25)' : 'var(--color-dark-blue)')};
  }
`;

export const StyledDateLabel = styled(StyledLabel)`
  font-weight: 500;
`;

export const StyledDatePickerField = styled(DatePickerField)<{ disabled?: boolean }>`
  .ant-picker-suffix svg path {
    fill: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.25)' : 'var(--color-dark-blue)')};
  }
`;

type DateField = {
  name: string;
  label: string;
  value: string | undefined;
  disabled?: boolean;
  onChange?: (value: any) => void;
  hideRemoveDateButton?: boolean;
};

const DataRowComponent = ({
  startDate,
  endDate,
  setFieldValue,
}: {
  startDate: DateField;
  endDate: DateField;
  setFieldValue: (field: string, value: any) => void;
}) => {
  return (
    <>
      <StyledDataRow>
        <StyledDateLabel>{startDate.label}</StyledDateLabel>
        <StyledDatePickerField
          allowClear={false}
          showToday={false}
          name={startDate.name}
          value={startDate.value ? moment(startDate.value) : null}
          disabled={startDate.disabled}
          disabledDate={(current: Moment) =>
            endDate.value ? disabledStartDate(current, endDate.value) : false
          }
          suffixIcon={<ArrowDownIcon />}
          format="MM / DD / YYYY"
          disabledDateTooltip="Start date cannot be greater than end date"
          onChange={startDate?.onChange}
        />
        {startDate.value && !startDate.hideRemoveDateButton && (
          <StyledRemoveButton
            ghost
            disabled={startDate.disabled}
            shape="circle"
            icon={<CloseIcon />}
            onClick={() => {
              setFieldValue(startDate.name, null);
              startDate?.onChange && startDate?.onChange(null);
            }}
          />
        )}
      </StyledDataRow>
      <StyledDataRow>
        <StyledDateLabel>{endDate.label}</StyledDateLabel>
        <StyledDatePickerField
          allowClear={false}
          showToday={false}
          name={endDate.name}
          value={endDate.value ? moment(endDate.value) : null}
          disabled={endDate.disabled}
          disabledDate={(current: Moment) =>
            startDate.value ? disabledEndDate(current, startDate.value) : false
          }
          suffixIcon={<ArrowDownIcon />}
          format="MM / DD / YYYY"
          disabledDateTooltip="End date cannot be earlier than start date"
          onChange={endDate?.onChange}
        />
        {endDate.value && !endDate.hideRemoveDateButton && (
          <StyledRemoveButton
            ghost
            disabled={startDate.disabled}
            shape="circle"
            icon={<CloseIcon />}
            onClick={() => {
              setFieldValue(endDate.name, null);
              endDate?.onChange && endDate?.onChange(null);
            }}
          />
        )}
      </StyledDataRow>
    </>
  );
};

export default DataRowComponent;
