import { useLayoutEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash-es';

export const useLatest = (value: any) => {
  const valueRef = useRef(value);
  useLayoutEffect(() => {
    valueRef.current = value;
  }, [value]);
  return valueRef;
};

export const useDebounce = (cb: (...args: any) => any, ms: number) => {
  const latestCb = useLatest(cb);
  return useMemo(
    () =>
      debounce((...args) => {
        latestCb.current(...args);
      }, ms),
    [ms],
  );
};
