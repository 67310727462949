import styled from 'styled-components';

export const StyledTipsHeader = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: var(--color-dark-blue);
  margin: 8px 0 16px;
`;

export const StyledTipsText = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--color-dark-blue);
`;

export const StyledCursiveTipsText = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--color-dark-blue);
  font-style: italic;
`;

export const StyledTipsTitle = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  color: var(--color-dark-blue);
  margin: 24px 0 8px;
`;

export const StyledOutLink = styled.a`
  font-weight: normal;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-main-dark-blue);
`;
