import styled from 'styled-components';
import { Checkbox, Modal } from 'antd';
import theme from '../../../../core/styles/styled-components/theme';
import { ReactComponent as ArrowIcon } from './svg/arrow.svg';
import { Button } from '../../../../core/components/common';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-height: 480px;
    height: 100%;
    width: 312px;
    margin: auto;
    overflow-y: hidden;
  }
  .ant-modal-body {
    padding: 32px;
    height: 100%;
  }
  @media ${theme.device.tablet.min} {
    .ant-modal-content {
      width: 480px;
    }
  }
`;

export const StyledModalInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: none;
  height: 100%;
`;

export const StyledMessageWrapper = styled.div`
  text-align: center;
`;

export const StyledSubtitleWrapper = styled.div`
  text-align: center;
  width: 200px;
`;

export const StyledMessage = styled.h2`
  line-height: 24px;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
`;

export const StyledSubtitle = styled.h3`
  line-height: 14px;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 25px;
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

export const StyledContentText = styled.h3`
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  margin: 0 4px;
`;

export const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  @media ${theme.device.mobile.max} {
    flex-direction: column;
    align-items: center;
  }

  @media ${theme.device.tablet.min} {
    flex-direction: row;
  }
`;

export const StyledButton = styled(Button)`
  height: 1.778rem;
`;

export const StyledCancelButton = styled(StyledButton)`
  border: 1px solid var(--color-grey);
  margin-right: 48px;
  font-weight: 600;

  @media ${theme.device.mobile.max} {
    margin: 0 0 16px 0;
  }

  @media ${theme.device.tablet.min} {
    margin: 0 48px 0 0;
  }
`;

export const StyledSubmitButton = styled(StyledButton)``;

export const StyledCheckbox = styled(Checkbox)`
  && {
    margin: 0 0 5px 0;

    span:last-child {
      max-width: 390px;
      word-wrap: break-word;
    }

    .ant-checkbox-inner {
      background-color: #fff;

      &::after {
        border-color: var(--color-dark-blue);
      }
    }
  }
`;
