import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Button, Drawer } from 'antd';
import { theme } from '../../../core/styles/styled-components';
import { STRIPE_STATE, StripeValue } from '../../../apollo/stateFields/stripe/stripeFields';
import { useQuery } from '@apollo/client';
import { stripeMutation } from '../../../apollo/stateFields/stripe';
import { StripeSubscriptionIntervals } from '../../../graphql/types';
import StripeForm from './StripeForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CloseIcon } from '../../../core/icons';

const StyledDrawer = styled(Drawer)<{ hide: boolean }>`
  ${({ hide }) =>
    hide &&
    css`
      opacity: 0;
    `}

  .ant-drawer-content-wrapper {
    width: 100%;
    @media ${theme.device.mobile.min} {
      width: 100%;
    }
    @media ${theme.device.tablet.min} {
      width: 767px;
    }
    @media ${theme.device.desktop.min} {
      width: 767px;
      height: auto;
      top: 0;
      bottom: 0;
    }
  }
  .ant-drawer-content {
  }
  .ant-drawer-body {
    padding: 0;
  }
  h4 {
    font-size: 14px;
  }
  .ant-input,
  textarea.ant-input,
  .ant-picker {
    display: block;
  }
  .ant-input {
    line-height: 32px;
  }
  .ant-divider-horizontal {
    margin: 24px 0;
    &.mainDivider {
      margin-bottom: 0;
      @media ${theme.device.desktop.min} {
        display: none;
      }
    }
  }
`;

export const StyledRemoveButton = styled(Button)`
  min-width: 0;
  width: 26px;
  height: 26px;
  margin: 0 8px;
  padding: 0;
  border-color: transparent;
`;

const StyledCloseButton = styled(StyledRemoveButton)`
  margin: 0;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 99999;
  @media ${theme.device.tablet} {
    top: 20px;
    right: 20px;
  }
  @media ${theme.device.desktop.min} {
    top: 20px;
    right: 20px;
  }
`;

const StripeDrawer = ({ startPeriod }: { startPeriod: StripeSubscriptionIntervals }) => {
  const { data: stripeData } = useQuery(STRIPE_STATE);
  const { showStripeModal }: StripeValue = stripeData?.stripe;
  const { setShowStripeModal, setIsForSubscriptionChange } = stripeMutation;

  const prevShowStripeModalValue = useRef<boolean | 'hide'>(false);

  useEffect(() => {
    prevShowStripeModalValue.current = showStripeModal;
    if (showStripeModal === false) {
      setIsForSubscriptionChange(null);
    }
  }, [showStripeModal]);

  const stripePromise = loadStripe(
    `${
      // @ts-ignore
      window.REACT_APP_STRIPE_PUBLIC_KEY || process.env.REACT_APP_STRIPE_PUBLIC_KEY
    }`,
  );

  return (
    <>
      <StyledDrawer
        getContainer={document.body}
        destroyOnClose
        maskClosable
        width=""
        placement="right"
        closable={false}
        onClose={() => {
          setShowStripeModal(false);
        }}
        visible={!!showStripeModal}
        hide={
          showStripeModal === 'hide' ||
          (!showStripeModal && prevShowStripeModalValue.current === 'hide')
        }
      >
        <StyledCloseButton
          ghost
          shape="circle"
          icon={<CloseIcon />}
          onClick={() => {
            setShowStripeModal(false);
          }}
        />
        <Elements stripe={stripePromise}>
          <StripeForm startPeriod={startPeriod} />
        </Elements>
      </StyledDrawer>
    </>
  );
};

export default StripeDrawer;
