import { useMutation } from '@apollo/client';
import { FETCH_ALL_NOTES, FETCH_NOTES } from '../../actions/graphql/queries';
import { CREATE_NOTE } from '../../actions/graphql/mutations';
import { CreateNote, FetchUserWorkspaceNotes, FetchUserWorkspacesNotes } from '../../actions/types';
import { OperationType, updatedCacheData } from '../../utils';
import { ColumnType } from 'apollo/stateFields/dnd/dndFields';
import { dndDispatch } from '../../../context/dndContext/dndContext';
import { addEntityId } from '../../../context/dndContext/DndActions';

const useCreateNote = () => {
  const dispatch = dndDispatch;
  return useMutation<CreateNote>(CREATE_NOTE, {
    update(cache, { data }) {
      const { fetchUserWorkspacesNotes = [] } =
        cache.readQuery<FetchUserWorkspacesNotes>({
          query: FETCH_ALL_NOTES,
        }) || {};

      const { fetchUserWorkspaceNotes = [] } =
        cache.readQuery<FetchUserWorkspaceNotes>({
          query: FETCH_NOTES,
          variables: {
            workspaceId: data?.createUserWorkspaceNote.workspaceId,
          },
        }) || {};

      cache.writeQuery<FetchUserWorkspacesNotes>({
        query: FETCH_ALL_NOTES,
        data: {
          fetchUserWorkspacesNotes: updatedCacheData(
            fetchUserWorkspacesNotes,
            data?.createUserWorkspaceNote,
            OperationType.CREATE,
          ),
        },
      });

      cache.writeQuery<FetchUserWorkspaceNotes>({
        query: FETCH_NOTES,
        variables: {
          workspaceId: data?.createUserWorkspaceNote.workspaceId,
        },
        data: {
          fetchUserWorkspaceNotes: updatedCacheData(
            fetchUserWorkspaceNotes,
            data?.createUserWorkspaceNote,
            OperationType.CREATE,
          ),
        },
      });

      if (data?.createUserWorkspaceNote) {
        dispatch(
          addEntityId({
            entityId: data?.createUserWorkspaceNote.id,
            columnTitle: ColumnType.Note,
          }),
        );
      }
    },
  });
};

export default useCreateNote;
