import { gql } from '@apollo/client';

export const TAG_FIELDS = gql`
  fragment tagFields on Tag {
    id
    name
    workspaceId
    createDate
    updateDate
    actionId
  }
`;
