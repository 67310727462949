import { gql, makeVar } from '@apollo/client';

export enum DoneColumnFilterTypes {
  ANYTIME = 'Anytime',
  TODAY = 'Today',
  YESTERDAY = 'Since yesterday',
  LAST_7 = 'Last 7 days',
  LAST_14 = 'Last 14 days',
  LAST_30 = 'Last 30 days',
  CUSTOM = 'Select custom period',
}

export enum ToDoColumnFilterTypes {
  ANYTIME = 'Anytime',
  TODAY = 'Today',
  TOMORROW = 'By tomorrow',
  NEXT_7 = 'In the next 7 days',
  NEXT_14 = 'In the next 14 days',
  NEXT_30 = 'In the next 30 days',
  NO_DUE_DATE = 'No date',
  CUSTOM = 'Select custom period',
}

export const DONE_COLUMN_FILTERS_VALUES: DoneColumnFilterTypes[] = [
  DoneColumnFilterTypes.ANYTIME,
  DoneColumnFilterTypes.TODAY,
  DoneColumnFilterTypes.YESTERDAY,
  DoneColumnFilterTypes.LAST_7,
  DoneColumnFilterTypes.LAST_14,
  DoneColumnFilterTypes.LAST_30,
  DoneColumnFilterTypes.CUSTOM,
];

export const TODO_COLUMN_FILTERS_VALUES: ToDoColumnFilterTypes[] = [
  ToDoColumnFilterTypes.ANYTIME,
  ToDoColumnFilterTypes.TODAY,
  ToDoColumnFilterTypes.TOMORROW,
  ToDoColumnFilterTypes.NEXT_7,
  ToDoColumnFilterTypes.NEXT_14,
  ToDoColumnFilterTypes.NEXT_30,
  ToDoColumnFilterTypes.NO_DUE_DATE,
  ToDoColumnFilterTypes.CUSTOM,
];

export interface BoardFiltersValue {
  showDoneColumnFilter: boolean;
  doneColumnFilter: DoneColumnFilterTypes;
  doneColumnStartDate: Date | null;
  doneColumnEndDate: Date | null;
  showToDoColumnFilter: boolean;
  toDoColumnFilter: ToDoColumnFilterTypes;
  toDoColumnStartDate: Date | null;
  toDoColumnEndDate: Date | null;
}

export const boardFiltersInitialValue: BoardFiltersValue = {
  showDoneColumnFilter: false,
  doneColumnFilter: DoneColumnFilterTypes.ANYTIME,
  doneColumnStartDate: null,
  doneColumnEndDate: null,
  showToDoColumnFilter: false,
  toDoColumnFilter: ToDoColumnFilterTypes.ANYTIME,
  toDoColumnStartDate: null,
  toDoColumnEndDate: null,
};

export const boardFiltersVar = makeVar<BoardFiltersValue>(boardFiltersInitialValue);
export const BOARD_FILTERS_STATE = gql`
  query BoardFilters {
    boardFilters @client
  }
`;
