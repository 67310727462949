import React, { useState } from 'react';
import { Button, Descriptions } from 'antd';
import EditUserModal from '../EditUserModal/EditUserModal';
import styled from 'styled-components';
import { EditFilled } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { FetchUserByEmail } from '../../../graphql/types';
import { FETCH_USER_BY_EMAIL } from '../../../graphql/queries';
import { format } from 'date-fns';
import { Loader } from '../../../core/components/common';

const StyledContainer = styled.div`
  && {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    .ant-descriptions-item-container {
      align-items: center;
    }
    .ant-descriptions-item-content {
      align-items: center;
    }
    .ant-descriptions-item-label {
      color: var(--color-grey);
    }
  }
`;
const StyledDescriptionItem = styled(Descriptions.Item)``;
const StyledEditButton = styled(Button)``;

export enum EditModalType {
  DisplayName = 'DisplayName',
  CurriculumType = 'CurriculumType',
  SubscriptionExpiration = 'SubscriptionExpiration',
}

const UserInfo = ({
  email,
  onSuccess,
  onError,
}: {
  email: string;
  onSuccess: () => void;
  onError: () => void;
}) => {
  const {
    data: userData,
    loading,
    refetch,
  } = useQuery<FetchUserByEmail>(FETCH_USER_BY_EMAIL, {
    variables: { email },
  });
  const [showEditModal, setShowEditModal] = useState<EditModalType | null>(null);
  const firstName = userData?.fetchUserByEmail.name?.split(' ')[0];
  const lastName = userData?.fetchUserByEmail.name?.split(' ')[1];
  const expirationDate = userData?.fetchUserByEmail?.bmSubscription?.expiration
    ? +userData?.fetchUserByEmail?.bmSubscription?.expiration * 1000
    : 0;
  return (
    <StyledContainer>
      {loading && <Loader size={'large'} />}
      {!loading && !!userData?.fetchUserByEmail?.email ? (
        <>
          <Descriptions title="User Info" size={'default'}>
            <StyledDescriptionItem label="Email">{email}</StyledDescriptionItem>
            <StyledDescriptionItem label="Display name">
              {`${firstName} ${lastName} `}
              <StyledEditButton
                size={'small'}
                shape="circle"
                icon={<EditFilled />}
                onClick={() => setShowEditModal(EditModalType.DisplayName)}
                style={{ marginLeft: '5px' }}
              />
            </StyledDescriptionItem>
            <StyledDescriptionItem label="Curriculum">
              {`${userData?.fetchUserByEmail?.coursesAvailableList[0]} ${userData?.fetchUserByEmail?.coursesAvailableList[1]}`}
              <StyledEditButton
                size={'small'}
                shape="circle"
                icon={<EditFilled />}
                onClick={() => setShowEditModal(EditModalType.CurriculumType)}
                style={{ marginLeft: '5px' }}
              />
            </StyledDescriptionItem>
            <StyledDescriptionItem label="Subscription">
              {userData?.fetchUserByEmail?.bmSubscription?.bmSubscriptionPlan}
            </StyledDescriptionItem>
            <StyledDescriptionItem label="Expire">
              {format(expirationDate, 'MMM dd, yyyy')}
              <StyledEditButton
                size={'small'}
                shape="circle"
                icon={<EditFilled />}
                onClick={() => setShowEditModal(EditModalType.SubscriptionExpiration)}
                style={{ marginLeft: '5px' }}
              />
            </StyledDescriptionItem>
          </Descriptions>
          <EditUserModal
            email={email}
            onError={onError}
            onSuccess={() => {
              refetch();
              onSuccess();
            }}
            type={showEditModal}
            onClose={() => {
              setShowEditModal(null);
            }}
          />
        </>
      ) : null}
    </StyledContainer>
  );
};

export default UserInfo;
