import React, { useEffect, useRef, useState } from 'react';
import PasswordRecoveryLayout from './PasswordRecoveryLayout';
import { Formik, FormikValues } from 'formik';
import { validationSchemaForEnterNewPassword } from './validations';
import { Form, SubmitButton } from 'formik-antd';
import { InputTextField } from '../../../../core/components/form';
import {
  SIGN_IN_PATH,
  PASSWORD_RECOVERY_PATH,
  SET_NEW_PASSWORD_PATH,
} from '../../../../core/constants/routePaths';
import styled, { css } from 'styled-components';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../../../context/authContext';
import { firebase } from '../../../../core/integrations/firebase/init';
import { useParams } from 'react-router-dom';
import { PasswordRecoveryParams } from './types';
import SignInLayoutWrapper from '../../../../core/components/SignInLayoutWrapper/SignInLayoutWrapper';

const StyledButtonsContainer = styled.div<{ noPadding?: boolean }>`
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `}
`;

const StyledLink = styled(Link)`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  color: var(--color-main-dark-blue);
`;

const initialValues = {
  password: '',
};

const EnterNewPassword = ({
  isPassRefreshed,
  setIsPassRefreshed,
}: {
  isPassRefreshed: boolean;
  setIsPassRefreshed: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const { firebaseSignOut, setNewPassword, signOut: signOutApp } = useAuth();
  const params = useParams<PasswordRecoveryParams>();
  const { search } = useLocation();
  const url = search.slice(5, search.length).split('?reloaded=true')[0];
  const history = useHistory();

  const signOut = () => {
    localStorage.removeItem('emailForSignIn');
    firebaseSignOut && firebaseSignOut();
  };

  const goToBack = () => history.push(PASSWORD_RECOVERY_PATH);

  useEffect(() => {
    async function signInAndSetNewPassword() {
      if (firebase.auth().isSignInWithEmailLink(url)) {
        let email = localStorage.getItem('emailForSignIn');

        if (!email && params?.email) {
          email = params.email;
          localStorage.setItem('emailForSignIn', email);
        }

        if (email && !isPassRefreshed) {
          try {
            setIsPassRefreshed(true);
            await firebase.auth().signInWithEmailLink(email, url);
          } catch (_) {
            goToBack();
          }
        } else {
          goToBack();
        }
      }
    }

    if (firebase.auth().currentUser && url.length && search.indexOf('?reloaded=true') === -1) {
      signOutApp &&
        signOutApp(`${SET_NEW_PASSWORD_PATH}/${params.email}?url=${url}?reloaded=true`).then(() =>
          history.push(`${SET_NEW_PASSWORD_PATH}/${params.email}?url=${url}?reloaded=true`),
        );
      return;
    } else if (
      !firebase.auth().currentUser &&
      search.indexOf('?reloaded=true') === -1 &&
      search.indexOf('?finished=true') === -1
    ) {
      history.push(`${SET_NEW_PASSWORD_PATH}/${params.email}?url=${url}?reloaded=true`);
      return;
    } else if (
      !isPassRefreshed &&
      search.indexOf('?reloaded=true') !== -1 &&
      search.indexOf('?finished=true') === -1
    ) {
      signInAndSetNewPassword();
    }
    // window.addEventListener('beforeunload', signOut);
    // return () => signOut();
  }, [search]);

  useEffect(() => {
    if (search.indexOf('?finished=true') !== -1) {
      setShowSuccess(true);
    }
  }, [search]);

  const handleSetNewPassword = async ({ password }: FormikValues) => {
    if (setNewPassword) {
      setIsPassRefreshed(false);
      if (firebase.auth().currentUser) {
        const appToken = await firebase.auth().currentUser?.getIdToken(true);
        await setNewPassword(password, appToken);
        signOutApp &&
          signOutApp(`${SET_NEW_PASSWORD_PATH}/${params.email}?finished=true`)
            .then(() => setShowSuccess(true))
            .then(() => localStorage.removeItem('sign-out'));

        window.localStorage.removeItem('emailForSignIn');
      }
      // window.removeEventListener('beforeunload', signOut);
    }
  };

  const backLinkEl = <StyledLink to={SIGN_IN_PATH}>Return to Sign in</StyledLink>;

  const formEl = showSuccess ? (
    <StyledButtonsContainer noPadding>{backLinkEl}</StyledButtonsContainer>
  ) : (
    <Formik
      enableReinitialize
      validateOnChange={true}
      validateOnBlur={true}
      initialValues={initialValues}
      validationSchema={validationSchemaForEnterNewPassword}
      onSubmit={handleSetNewPassword}
    >
      {({ isSubmitting, errors }) => {
        return (
          <Form>
            <InputTextField
              name="password"
              placeholder="New password"
              type="password"
              autoComplete="off"
              displayError={!!errors.password}
            />
            <StyledButtonsContainer>
              {backLinkEl}
              <SubmitButton loading={isSubmitting} type="primary" shape="round">
                Save
              </SubmitButton>
            </StyledButtonsContainer>
          </Form>
        );
      }}
    </Formik>
  );

  const subtitleEl = showSuccess && 'You can now login to your account';
  const titleEl = showSuccess ? 'Password changed successfully' : 'Enter new password';

  return (
    <SignInLayoutWrapper>
      <PasswordRecoveryLayout title={titleEl} subtitle={subtitleEl} formComponent={formEl} />
    </SignInLayoutWrapper>
  );
};

export default EnterNewPassword;
