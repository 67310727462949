import { CreatableSelectOption } from 'core/components/form';
import { createTag } from 'core/utils/tag';

export const getNewTags = (workspaceId: number, tags: CreatableSelectOption[]) => {
  return workspaceId && tags
    ? tags
        .filter((tag: CreatableSelectOption) => tag.__isNew__)
        .map((tag: CreatableSelectOption) => {
          return createTag(tag.value, workspaceId);
        })
    : [];
};

export const getSelectedTags = (tags: CreatableSelectOption[]) => {
  return (
    tags &&
    tags
      .filter((tag: CreatableSelectOption) => !tag.__isNew__)
      .map((tag: any) => {
        return tag.value || tag.id;
      })
  );
};
