import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import styled, { createGlobalStyle, css } from 'styled-components';
import { theme } from '../../../styles/styled-components';

const ArrowStyle = createGlobalStyle`
  .expiredTooltip.ant-tooltip {
    .ant-tooltip-arrow {
      display: none !important;
    }
  }
  `;

const GeneralStyle = createGlobalStyle<{ width?: string; overlayClassName: string }>`
  ${({ width, overlayClassName }) =>
    css`
      .${overlayClassName}.expiredTooltip.ant-tooltip {
        .ant-tooltip-inner {
          width: ${width ? width : '100%'};
          color: white;
          min-height: 48px;
          border-radius: 3px;
          padding: 12px;
          min-width: 250px;
          font-size: 14px;
          line-height: 16px;
        }
      }
    `}
  `;

const AbsolutePositionStyle = createGlobalStyle<{
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  overlayClassName: string;
}>`
  ${({ top, left, right, bottom, overlayClassName }) =>
    css`
      .${overlayClassName}.expiredTooltip.ant-tooltip {
        .ant-tooltip-inner {
          position: absolute;
          top: ${top ? top : 'unset'};
          left: ${left ? left : 'unset'};
          right: ${right ? right : 'unset'};
          bottom: ${bottom ? bottom : 'unset'};
        }
      }
    `}
`;

const StyledTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExpiredTooltip = ({
  children,
  width,
  title,
  overlayClassName = 'defaultTooltip',
  visible = true,
  withoutArrow,
  placement = 'bottom',
  position,
  className,
}: {
  children: ReactElement | string;
  width?: string;
  title: ReactElement | string;
  overlayClassName?: string;
  visible?: boolean;
  withoutArrow?: boolean;
  placement?: TooltipPlacement;
  position?: {
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
  };
  className?: string;
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const tooltipTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    return () => {
      !!tooltipTimeout.current && clearTimeout(tooltipTimeout.current);
    };
  }, []);

  return (
    <StyledTooltipWrapper
      onMouseEnter={() => {
        setShowTooltip(true);
      }}
      onMouseLeave={() => {
        tooltipTimeout.current = setTimeout(() => {
          setShowTooltip(false);
        }, 300);
      }}
      onMouseOver={() => {
        if (tooltipTimeout.current) {
          if (tooltipTimeout.current) {
            clearTimeout(tooltipTimeout.current);
          }
        }
      }}
      className={className}
    >
      <GeneralStyle width={width} overlayClassName={overlayClassName} />
      {position && <AbsolutePositionStyle overlayClassName={overlayClassName} {...position} />}
      {withoutArrow && <ArrowStyle />}
      <Tooltip
        visible={visible && showTooltip}
        getTooltipContainer={() => document.body}
        placement={placement}
        title={title}
        color={'#202635'}
        overlayClassName={`expiredTooltip ${overlayClassName}`}
      >
        {children}
      </Tooltip>
    </StyledTooltipWrapper>
  );
};

export default ExpiredTooltip;
