import { ReactComponent as Icon1 } from '../img/1.svg';
import { ReactComponent as Icon2 } from '../img/2.svg';
import { ReactComponent as Icon3 } from '../img/3.svg';
import { ReactComponent as Icon4 } from '../img/4.svg';
import { ReactComponent as Icon5 } from '../img/5.svg';
import { ReactComponent as Icon6 } from '../img/6.svg';
import { ReactComponent as Icon7 } from '../img/7.svg';
import { ReactComponent as Icon8 } from '../img/8.svg';
import { ReactComponent as Icon9 } from '../img/9.svg';
import { ReactComponent as Icon10 } from '../img/10.svg';
import { ReactComponent as Icon11 } from '../img/11.svg';
import { ReactComponent as Icon12 } from '../img/12.svg';
import React from 'react';
import { ExpectListItemProps } from './types';

export const WORK_ROLES = [
  'Student',
  'Intern',
  'Individual Contributor',
  'Manager',
  'Director',
  'VP',
  'Executive',
  'Board Member',
  'Consultant',
  'Professional',
];

export const NON_WORK_ROLES = [
  'Spouse',
  'Parent',
  'Pet Owner',
  'Volunteer',
  'Artist',
  'Author',
  'Athlete',
  'Nature Lover',
  'Sports Fan',
];

export const EXPECT_LIST_ITEMS: ExpectListItemProps[] = [
  {
    title: 'Improve productivity',
    Icon: Icon1,
  },
  {
    title: 'Build up skills',
    Icon: Icon2,
  },
  {
    title: 'Get more free time',
    Icon: Icon3,
  },
  {
    title: 'Find motivation',
    Icon: Icon4,
  },
  {
    title: 'Get a fullfilling life',
    Icon: Icon5,
  },
  {
    title: 'Grow as employee',
    Icon: Icon6,
  },
  {
    title: 'Grow as person',
    Icon: Icon7,
  },
  {
    title: 'Structure workday',
    Icon: Icon8,
  },
  {
    title: 'Take control of your day',
    Icon: Icon9,
  },
  {
    title: 'Get a promotion',
    Icon: Icon10,
  },
  {
    title: 'Master the unexpected',
    Icon: Icon11,
  },
  {
    title: 'Do more in a less time',
    Icon: Icon12,
  },
];
