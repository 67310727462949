import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { FETCH_ALL_NOTES, FETCH_NOTES } from '../../actions/graphql/queries';
import { CONVERT_NOTE_TO_OUTCOME } from '../../actions/graphql/mutations';
import {
  ConvertNoteToOutcome,
  FetchUserWorkspaceNotes,
  FetchUserWorkspacesNotes,
} from '../../actions/types';
import { FetchOutcomes } from '../../outcomes/types';
import { FETCH_OUTCOMES } from '../../outcomes/graphql/queries';
import { Note } from '../../../core/types/note';
import {
  checkIsShownEntity,
  OperationType,
  updatedCacheData,
  updateWorkspaceTagsData,
} from '../../utils';
import { mutateOutcome } from '../../../context/userWorkspaceContext';
import { ColumnType } from '../../../apollo/stateFields/dnd/dndFields';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../apollo/stateFields/filterInput/filterInputFields';
import { filterMutation } from '../../../apollo/stateFields/filterInput';
import { dndDispatch } from '../../../context/dndContext/dndContext';
import { addEntityId, deleteEntityId } from '../../../context/dndContext/DndActions';

const useConvertNoteToOutcome = (noteToConvertOutcome?: Note | null) => {
  const apolloClient = useApolloClient();
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const { setUpdateFilterInputId } = filterMutation;
  const dispatch = dndDispatch;

  return useMutation<ConvertNoteToOutcome>(CONVERT_NOTE_TO_OUTCOME, {
    update(cache, { data }) {
      if (noteToConvertOutcome === null) {
        return;
      }
      const { fetchUserWorkspacesNotes = [] } =
        cache.readQuery<FetchUserWorkspacesNotes>({
          query: FETCH_ALL_NOTES,
        }) || {};

      const { fetchUserWorkspaceNotes = [] } =
        cache.readQuery<FetchUserWorkspaceNotes>({
          query: FETCH_NOTES,
          variables: {
            workspaceId: noteToConvertOutcome?.workspaceId,
          },
        }) || {};

      const { fetchUserWorkspaceOutcomes = [] } =
        cache.readQuery<FetchOutcomes>({
          query: FETCH_OUTCOMES,
          variables: {
            workspaceId: data?.convertNoteToUserWorkspaceOutcome.workspaceId,
          },
        }) || {};

      cache.writeQuery<FetchUserWorkspacesNotes>({
        query: FETCH_ALL_NOTES,
        data: {
          fetchUserWorkspacesNotes: updatedCacheData(
            fetchUserWorkspacesNotes,
            noteToConvertOutcome,
            OperationType.DELETE,
          ),
        },
      });

      cache.writeQuery<FetchUserWorkspaceNotes>({
        query: FETCH_NOTES,
        variables: {
          workspaceId: noteToConvertOutcome?.workspaceId,
        },
        data: {
          fetchUserWorkspaceNotes: updatedCacheData(
            fetchUserWorkspaceNotes,
            noteToConvertOutcome,
            OperationType.DELETE,
          ),
        },
      });

      cache.writeQuery<FetchOutcomes>({
        query: FETCH_OUTCOMES,
        variables: {
          workspaceId: data?.convertNoteToUserWorkspaceOutcome.workspaceId,
        },
        data: {
          fetchUserWorkspaceOutcomes: updatedCacheData(
            fetchUserWorkspaceOutcomes,
            data?.convertNoteToUserWorkspaceOutcome,
            OperationType.CREATE,
          ),
        },
      });
      if (data?.convertNoteToUserWorkspaceOutcome) {
        noteToConvertOutcome &&
          dispatch(
            deleteEntityId({
              entityId: noteToConvertOutcome.id,
              columnTitle: ColumnType.Note,
            }),
          ),
          dispatch(
            addEntityId({
              entityId: data?.convertNoteToUserWorkspaceOutcome.id,
              columnTitle: ColumnType.Outcome,
            }),
          ),
          mutateOutcome(
            apolloClient,
            data.convertNoteToUserWorkspaceOutcome,
            'create',
            filterInput,
          );

        data.convertNoteToUserWorkspaceOutcome.tags?.length &&
          updateWorkspaceTagsData(apolloClient, data.convertNoteToUserWorkspaceOutcome);

        if (!checkIsShownEntity(data.convertNoteToUserWorkspaceOutcome, filterInput)) {
          setUpdateFilterInputId({
            type: 'Outcome',
            workspaceId: data.convertNoteToUserWorkspaceOutcome.workspaceId,
            id: data.convertNoteToUserWorkspaceOutcome.id,
          });
        }
      }
    },
  });
};

export default useConvertNoteToOutcome;
