import React from 'react';
import styled from 'styled-components';
import { isString } from 'lodash-es';

import { WorkspaceProps } from './types';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitleWrapper = styled.div``;

const StyledWorkspaceName = styled.h2``;

const StyledToolbarWrapper = styled.div``;

const StyledToolbar = styled.div``;

const StyledToolbarItemWrapper = styled.div``;

const StyledContentContainer = styled.div``;

const renderTitle = (title: WorkspaceProps['title']) => {
  if (isString(title)) {
    return <StyledWorkspaceName>{title}</StyledWorkspaceName>;
  }

  return title;
};

const renderToolbarItem = (toolbarItem: JSX.Element, index: number) => (
  <StyledToolbarItemWrapper key={index}>
    {toolbarItem}
  </StyledToolbarItemWrapper>
);

const Workspace = ({
  className,
  title,
  toolbarItems,
  content,
}: WorkspaceProps) => (
  <StyledContainer className={className}>
    <StyledHeader>
      <StyledTitleWrapper>
        {renderTitle(title)}
      </StyledTitleWrapper>
      <StyledToolbarWrapper>
        {toolbarItems.length && (
          <StyledToolbar>
            {toolbarItems.map(renderToolbarItem)}
          </StyledToolbar>
        )}
      </StyledToolbarWrapper>
    </StyledHeader>
    <StyledContentContainer>
      {content}
    </StyledContentContainer>
  </StyledContainer>
);

export { Workspace };
