import { useMutation } from '@apollo/client';
import { FETCH_ALL_NOTES, FETCH_NOTES } from '../../actions/graphql/queries';
import { MOVE_NOTE } from '../../actions/graphql/mutations';
import { FetchUserWorkspaceNotes, FetchUserWorkspacesNotes, MoveNote } from '../../actions/types';
import { NoteInput } from '../../../core/types/note';
import { OperationType, updatedCacheData } from '../../utils';

const useMoveNote = (noteToMove?: NoteInput | null) => {
  return useMutation<MoveNote>(MOVE_NOTE, {
    update(cache, { data }) {
      const { fetchUserWorkspacesNotes = [] } =
        cache.readQuery<FetchUserWorkspacesNotes>({
          query: FETCH_ALL_NOTES,
        }) || {};

      const { fetchUserWorkspaceNotes: oldWorkspaceNotes = [] } =
        cache.readQuery<FetchUserWorkspaceNotes>({
          query: FETCH_NOTES,
          variables: {
            workspaceId: noteToMove?.workspaceId,
          },
        }) || {};

      const { fetchUserWorkspaceNotes: newWorkspaceNotes = [] } =
        cache.readQuery<FetchUserWorkspaceNotes>({
          query: FETCH_NOTES,
          variables: {
            workspaceId: data?.moveUserWorkspaceNote?.workspaceId,
          },
        }) || {};

      cache.writeQuery<FetchUserWorkspaceNotes>({
        query: FETCH_NOTES,
        variables: {
          workspaceId: noteToMove?.workspaceId,
        },
        data: {
          fetchUserWorkspaceNotes: updatedCacheData(
            oldWorkspaceNotes,
            data?.moveUserWorkspaceNote,
            OperationType.DELETE,
          ),
        },
      });

      cache.writeQuery<FetchUserWorkspaceNotes>({
        query: FETCH_NOTES,
        variables: {
          workspaceId: data?.moveUserWorkspaceNote?.workspaceId,
        },
        data: {
          fetchUserWorkspaceNotes: updatedCacheData(
            newWorkspaceNotes,
            data?.moveUserWorkspaceNote,
            OperationType.CREATE,
          ),
        },
      });

      cache.writeQuery<FetchUserWorkspacesNotes>({
        query: FETCH_ALL_NOTES,
        data: {
          fetchUserWorkspacesNotes: updatedCacheData(
            fetchUserWorkspacesNotes,
            data?.moveUserWorkspaceNote,
            OperationType.UPDATE,
          ),
        },
      });
    },
  });
};

export default useMoveNote;
