import React from 'react';
import styled from 'styled-components';
import { Space, Typography } from 'antd';

import theme from 'core/styles/styled-components/theme';
import { PageView } from 'pages/PageView';
import contactChannels from './contactChannels';
import { ReactComponent as MailIcon } from './svg/mail.svg';
import { ContactChannel } from './types';

const { Link, Text, Title } = Typography;

const StyledContainer = styled.div``;

const StyledTitle = styled(Title)`
  && {
    margin-bottom: 1.334rem;
    font-size: 1.77rem;
    font-weight: 700;
  }
`;

const StyledSubtitle = styled(Title)`
  && {
    margin-bottom: 0.571rem;
    font-size: 1.286rem;
    font-weight: 700;

    @media ${theme.device.mobile.max} {
      font-size: 1.45rem;
    }
  }
`;

const StyledContent = styled.div`
  @media ${theme.device.tablet.min} {
    display: flex;
    align-items: center;
  }
`;

const StyledAddressAndMailContainer = styled.div``;

const StyledInfoSection = styled.div`
  margin-bottom: 2.5rem;
`;

const StyledMailSection = styled(StyledInfoSection)`
  @media ${theme.device.tablet.min} {
    margin-bottom: 0;
  }
`;

const StyledContactChannelSection = styled(StyledInfoSection)`
  margin-bottom: 0;

  @media ${theme.device.tablet.min} {
    margin-left: 9rem;
  }
`;

const StyledAddressWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

const StyledAddress = styled(Text)``;

const StyledMailWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const StyledMailIcon = styled(MailIcon)`
  margin-right: 0.357rem;
`;

const StyledContactChannelWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const StyledContactChannelLink = styled(Link)`
  && {
    color: var(--color-main-dark-blue);
    margin-left: 0.357rem;
  }
`;

const renderContactChannel = ({ icon: Icon, name, url }: ContactChannel) => (
  <StyledContactChannelWrapper key={name}>
    <Icon />
    <StyledContactChannelLink href={url} target="_blank">
      {name}
    </StyledContactChannelLink>
  </StyledContactChannelWrapper>
);

const ContactUs = () => (
  <PageView>
    <StyledContainer>
      <StyledTitle>Contact Us</StyledTitle>
      <StyledContent>
        <StyledAddressAndMailContainer>
          <StyledInfoSection>
            <StyledSubtitle level={2}>Billion Minds, Inc.</StyledSubtitle>
            <StyledAddressWrapper>
              <StyledAddress>One Technology Center</StyledAddress>
              <StyledAddress>100 S Cincinnati Ave, 5th Floor</StyledAddress>
              <StyledAddress>Tulsa OK 74103</StyledAddress>
            </StyledAddressWrapper>
          </StyledInfoSection>
          <StyledMailSection>
            <StyledSubtitle level={2}>Any questions?</StyledSubtitle>
            <StyledMailWrapper>
              <StyledMailIcon />
              <StyledContactChannelLink href={'mailto:info@billionminds.com'}>
                info@billionminds.com
              </StyledContactChannelLink>
            </StyledMailWrapper>
          </StyledMailSection>
        </StyledAddressAndMailContainer>
        <StyledContactChannelSection>
          <Space direction="vertical">
            {contactChannels.map(renderContactChannel)}
          </Space>
        </StyledContactChannelSection>
      </StyledContent>
    </StyledContainer>
  </PageView>
);

export { ContactUs };
