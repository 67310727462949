import React from 'react';
import { DatePicker } from 'antd';
import styled from 'styled-components';
import { Moment } from 'moment';
import { useMediaQuery } from 'react-responsive';
import theme from '../../../../core/styles/styled-components/theme';

const StyledDatePicker = styled(DatePicker)`
  margin-left: 15px;
`;

const OutcomeDatePicker = ({ onChange, value }: { onChange: any; value?: Moment | undefined }) => {
  const isTouchDevice = useMediaQuery({ query: theme.device.tablet.max });

  return (
    <StyledDatePicker
      inputReadOnly={isTouchDevice}
      format="MMMM YYYY"
      picker="month"
      onChange={onChange}
      value={value}
    />
  );
};

export { OutcomeDatePicker };
