import { ENTITY_NAME_BY_OPERATION_NAME } from './types';
import { CUSTOM_ENTITY_TYPES } from './stateFields/error/errorFields';

export const getEntityByOperationName = (
  operationName: string,
): CUSTOM_ENTITY_TYPES | undefined => {
  if (
    ENTITY_NAME_BY_OPERATION_NAME.OUTCOME.some((outOperation) => outOperation === operationName)
  ) {
    return CUSTOM_ENTITY_TYPES.OUTCOME;
  }
  if (ENTITY_NAME_BY_OPERATION_NAME.ACTION.some((outOperation) => outOperation === operationName)) {
    return CUSTOM_ENTITY_TYPES.ACTION;
  }
  if (ENTITY_NAME_BY_OPERATION_NAME.NOTE.some((outOperation) => outOperation === operationName)) {
    return CUSTOM_ENTITY_TYPES.NOTE;
  }
  if (
    ENTITY_NAME_BY_OPERATION_NAME.WORKSPACE.some((outOperation) => outOperation === operationName)
  ) {
    return CUSTOM_ENTITY_TYPES.WORKSPACE;
  }
};
