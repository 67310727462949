import React from 'react';
import styled from 'styled-components';

import { LabelProps } from './types';

const StyledLabel = styled.label``;

const Label = ({
  className,
  text,
  htmlFor,
  onClick,
}: LabelProps) => (
  <StyledLabel className={className} htmlFor={htmlFor} onClick={onClick}>
    {text}
  </StyledLabel>
);

export { Label };
