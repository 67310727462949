import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, Drawer, Input } from 'antd';
import { theme } from '../../../../styles/styled-components';
import {
  checkDomain,
  checkEmail,
} from '../../../../../features/workspaces/components/ShareWorkspace/utils';
import useTextInputField from '../../../../../hooks/useInputField';
import { useMutation, useQuery } from '@apollo/client';
import {
  RECOMMEND_STATE,
  RecommendValue,
} from '../../../../../apollo/stateFields/recommend/recommendFields';
import { recommendMutation } from '../../../../../apollo/stateFields/recommend';
import { ReactComponent as SuccessIcon } from '../../../../svg/common/successRecommend.svg';
import useDndContext from '../../../../../context/dndContext/useDndContext';
import {
  UPDATE_USER_RECOMMENDED_COMPANY,
  UPDATE_USER_REMIND_DATE,
} from '../../../../../graphql/mutations';
import { isBefore } from 'date-fns';
import { firebase } from '../../../../integrations/firebase/init';

const StyledDrawer = styled(Drawer)`
  && {
    z-index: 1060;

    .ant-drawer-content-wrapper {
      width: 100% !important;

      @media ${theme.device.desktop.min} {
        width: 552px !important;
        height: auto;
        top: 0;
        bottom: 0;
      }

      @media ${theme.device.tablet.max} {
        width: 552px !important;
        z-index: 1002;
      }

      @media ${theme.device.mobile.max} {
        width: 100vw !important;
      }
    }

    .ant-drawer-content {
      border-top-left-radius: 4px;

      @media ${theme.device.tablet.max} {
        border-radius: 0;
      }
    }

    .ant-drawer-body {
      padding: 0;
    }
  }
`;

const StyledRecommendContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const StyledContentInner = styled.div`
  padding: 56px 48px 0 48px;

  @media ${theme.device.mobile.max} {
    padding: 48px 16px 0 16px;
  }
`;
const StyledDontShow = styled.div`
  padding: 0 48px 0 48px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e9e9eb;
`;

const StyledTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 20px;

  @media ${theme.device.mobile.max} {
    font-size: 24px;
    line-height: 24px;
  }
`;

const StyledText = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
`;

export const StyledLabel = styled.label`
  display: block;
  margin-bottom: 0.4285em;
  color: var(--color-dark-blue);
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
`;

export const StyledSpan = styled.span`
  display: inline-block;
  color: var(--color-dark-blue);
  font-weight: 400;
`;

export const StyledInputWrapper = styled.div`
  position: relative;
`;

const StyledInput = styled(({ isInvalid, ...rest }) => <Input {...rest} />)<{
  isInvalid?: boolean;
}>`
  && {
    height: 56px;
    margin-bottom: 32px;
    box-shadow: none;
    background: rgba(32, 38, 53, 0.01);
    border-radius: 8px;

    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: var(--color-dark-blue);

    &:hover,
    &:focus,
    &:active {
      outline: none;
      border: 1px solid #202635;
      border: ${({ isInvalid }) => (isInvalid ? '1px solid #BA0000' : '1px solid #202635')};
    }

    border: ${({ isInvalid }) =>
      isInvalid ? '1px solid #BA0000' : '1px solid rgba(32, 38, 53, 0.2)'};
  }
`;

const StyledInputError = styled.span`
  position: absolute;
  bottom: 12px;
  left: 0;
  color: #ba0000;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;

  @media ${theme.device.mobile.max} {
    flex-direction: column;
  }
`;

const StyledButtonLink = styled(Button)`
  opacity: 0.4;
  font-size: 12px;
  padding: 0 5px;
  color: var(--color-dark-blue);
  border: none;

  span {
    text-decoration: underline;
  }

  @media ${theme.device.mobile.max} {
    margin-top: 20px;
  }
`;

const StyledDontShowButtonLink = styled(StyledButtonLink)`
  font-weight: 400;

  @media ${theme.device.mobile.max} {
    margin-top: 0;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  height: 48px;
  width: 131px;
  border: none;
  border-radius: 36px;
  background-color: var(--color-dark-blue);
  color: var(--color-white);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;

  &:hover,
  &:focus {
    background-color: var(--color-dark-blue);
    color: var(--color-white);
  }

  @media ${theme.device.mobile.max} {
    width: 100%;
  }
`;

const StyledSuccessContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 24px;
`;

const StyledSuccessInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const StyledSuccessTitle = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`;

const StyledSuccessText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 0;
`;

const StyledSuccessIcon = styled(SuccessIcon)`
  margin-right: 10px;
`;

const ALLOWED_EMAILS = [
  'outlook.com',
  'hotmail.com',
  'gmail.com',
  // yahoo
  'yahoo.com',
  'yahoodns.net',
  'mailyahoo.com',
  'yahoomail.com',
  'yahoo.mail.com',
  'mail.yahoo.com',

  'inbox.com',
  'icloud.com',
  'mail.com',
  'zoho.com',

  // yandex
  'yandex.ru',
  'yandex.com',
  'yandex.mail',

  // proton
  'proton.me',
  'protonmail.com',

  'mailto.plus',
  'live.com',
  'aol.com',
];

const RecommendCompanyDrawer = () => {
  const [successState, setSuccessState] = useState<boolean>(false);
  const {
    state: name,
    setState: setName,
    handleChange: handleNameChange,
    error: nameError,
    handleBlur: handleNameBlur,
  } = useTextInputField({
    initialValue: '',
    checkFn: (value) => !!value.length,
    errorMsg: 'Mandatory field',
  });
  const {
    state: site,
    setState: setSite,
    error: siteError,
    handleBlur: handleSiteBlur,
    handleChange: handleSiteChange,
  } = useTextInputField({
    initialValue: '',
    checkFn: (value) => checkDomain(value),
    errorMsg: 'Invalid website',
  });
  const {
    state: mail,
    setState: setMail,
    error: mailError,
    handleBlur: handleMailBlur,
    handleChange: handleMailChange,
  } = useTextInputField({
    initialValue: '',
    checkFn: (value) => (!!value.length ? checkEmail(value) : true),
    errorMsg: 'Invalid email',
  });
  const { data: recommendData } = useQuery(RECOMMEND_STATE);
  const {
    showRecommendPopup,
    withAdditionalBtns,
    remindDate,
    shouldRemind,
    isAlreadyReminded,
  }: RecommendValue = recommendData.recommend;
  const {
    setRemindDate,
    setShouldRemind,
    setWithAdditionalBtns,
    setShowRecommendPopup,
    setIsAlreadyReminded,
  } = recommendMutation;
  const { isUpdated } = useDndContext();
  const [updateCompany, { loading }] = useMutation(UPDATE_USER_RECOMMENDED_COMPANY);
  const [updateRemindDate, { loading: updateDateLoading }] = useMutation(UPDATE_USER_REMIND_DATE);
  const isAllowedEmail = firebase.auth().currentUser?.email?.length
    ? ALLOWED_EMAILS.some((emailPart) =>
        (firebase.auth().currentUser?.email || '').includes(emailPart),
      )
    : true;

  const clearFields = () => {
    setName('');
    setSite('');
    setMail('');
  };

  const handleSubmit = () => {
    if (name.length && site.length && checkDomain(site)) {
      const sendValue = { name, url: site, email: mail };

      if (mail.length && checkEmail(mail)) {
        updateCompany({
          variables: { recommendedCompany: sendValue },
        })
          .then(() => {
            setSuccessState(true);
          })
          .then(() => {
            updateRemindDate({
              variables: {
                settings: { recommendedCompanyRemindDate: null },
              },
            });
          });
        clearFields();
        return;
      }
      updateCompany({
        variables: { recommendedCompany: sendValue },
      })
        .then(() => {
          setSuccessState(true);
        })
        .then(() => {
          updateRemindDate({
            variables: {
              settings: { recommendedCompanyRemindDate: null },
            },
          });
        });
      clearFields();
      return;
    }
  };

  const handleRemindLater = () => {
    const respDate = Date.now() + 6.048e8;
    setRemindDate(respDate.toString());
    updateRemindDate({
      variables: {
        settings: { recommendedCompanyRemindDate: respDate.toString() },
      },
    });
    setShowRecommendPopup(false);
    setWithAdditionalBtns(false);
  };
  const handleCancelRemind = () => {
    setRemindDate(null);
    updateRemindDate({
      variables: {
        settings: { recommendedCompanyRemindDate: null },
      },
    });
    setShowRecommendPopup(false);
    setWithAdditionalBtns(false);
  };

  const successEl = (
    <StyledSuccessContainer>
      <StyledSuccessIcon />
      <StyledSuccessInnerWrapper>
        <StyledSuccessTitle>Thank you for your submission</StyledSuccessTitle>
        <StyledSuccessText>We will be in touch with the next steps</StyledSuccessText>
      </StyledSuccessInnerWrapper>
    </StyledSuccessContainer>
  );

  useEffect(() => {
    if (successState) {
      setTimeout(() => {
        setSuccessState(false);
      }, 3000);
    }
  }, [successState]);

  useEffect(() => {
    if (remindDate && isAllowedEmail) {
      if (isBefore(Number(remindDate), Date.now())) {
        setWithAdditionalBtns(true);
        setShouldRemind(true);
      }
    }
  }, [remindDate]);

  useEffect(() => {
    if (isUpdated && shouldRemind && !isAlreadyReminded) {
      setShowRecommendPopup(true);
      setIsAlreadyReminded(true);
    }
  }, [shouldRemind, isUpdated]);

  return (
    <>
      {showRecommendPopup && (
        <StyledDrawer
          maskClosable
          placement="right"
          closable={true}
          onClose={handleCancelRemind}
          visible={showRecommendPopup}
        >
          <StyledRecommendContainer>
            <StyledContentInner>
              <StyledTitle>Can We Help Your Organization?</StyledTitle>
              <StyledText>
                Did you know that most BillionMinders get the service for FREE through their
                organization, and this often includes 1:1 coaching from a BillionMinds coach?
              </StyledText>
              <StyledText>Your organization might already be one of them!</StyledText>
              <StyledText>
                If you would like to find out if we are already working with your organization, or
                nominate them to work with BillionMinds, please enter your work details below.
              </StyledText>
              <StyledText style={{ marginBottom: '40px' }}>
                We will then e-mail you at your work address with more information.
              </StyledText>

              <StyledInputWrapper>
                <StyledLabel>My Organization Name</StyledLabel>
                <StyledInput
                  isInvalid={!!nameError}
                  size="large"
                  maxLength={100}
                  autoComplete="off"
                  value={name}
                  onChange={handleNameChange}
                  onBlur={handleNameBlur}
                />
                {!!nameError && <StyledInputError>{nameError}</StyledInputError>}
              </StyledInputWrapper>

              <StyledInputWrapper>
                <StyledLabel>My Organization Website</StyledLabel>
                <StyledInput
                  isInvalid={!!siteError}
                  size="large"
                  maxLength={100}
                  autoComplete="off"
                  value={site}
                  onChange={handleSiteChange}
                  onBlur={handleSiteBlur}
                />
                {!!siteError && <StyledInputError>{siteError}</StyledInputError>}
              </StyledInputWrapper>

              <StyledInputWrapper>
                <StyledLabel>
                  My Organization E-mail <StyledSpan>(optional)</StyledSpan>
                </StyledLabel>
                <StyledInput
                  isInvalid={!!mailError.length}
                  size="large"
                  maxLength={100}
                  autoComplete="off"
                  value={mail}
                  onChange={handleMailChange}
                  onBlur={handleMailBlur}
                />
                {!!mailError.length && <StyledInputError>{mailError}</StyledInputError>}
              </StyledInputWrapper>
              {successState ? (
                successEl
              ) : (
                <>
                  <StyledButtonsContainer>
                    <StyledButton
                      disabled={
                        !name.length ||
                        !site.length ||
                        !!mailError ||
                        !!siteError ||
                        !!nameError ||
                        loading ||
                        updateDateLoading
                      }
                      onClick={handleSubmit}
                    >
                      Send
                    </StyledButton>
                    {!loading && !updateDateLoading && withAdditionalBtns && (
                      <StyledButtonLink onClick={handleRemindLater}>
                        Remind me later
                      </StyledButtonLink>
                    )}
                  </StyledButtonsContainer>
                </>
              )}
            </StyledContentInner>
            {!loading && !updateDateLoading && !successState && withAdditionalBtns && (
              <StyledDontShow>
                <StyledDontShowButtonLink onClick={handleCancelRemind}>
                  Don't show me again
                </StyledDontShowButtonLink>
              </StyledDontShow>
            )}
          </StyledRecommendContainer>
        </StyledDrawer>
      )}
    </>
  );
};

export default RecommendCompanyDrawer;
