import { gql, makeVar } from '@apollo/client';

export interface HighlightedEntitiesValue {
  hoveredOutcomeId: number | null;
}

export const highlightedEntitiesInitialValue: HighlightedEntitiesValue = {
  hoveredOutcomeId: null,
};

export const highlightedEntitiesVar = makeVar<HighlightedEntitiesValue>(
  highlightedEntitiesInitialValue,
);

export const HIGHLIGHTED_ENTITIES = gql`
  query HighlightedEntities {
    highlightedEntities @client
  }
`;
