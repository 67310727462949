import React from 'react';
import { Input } from 'formik-antd';
import styled from 'styled-components';

const InputField = styled(({ $error, ...rest }) => <Input {...rest} />)<{ $error?: boolean }>`
  && {
    border: 1px solid ${(props) => (props.$error ? 'var(--color-red)' : 'transparent')};
    border-radius: 4px;
  }
`;

export { InputField };
