import React from 'react';
import styled from 'styled-components';

import { Header } from 'core/components/layout';
import { PageNavigationBar } from './PageNavigationBar';
import { PageViewProps } from './types';
import { theme } from '../../core/styles/styled-components';
import links from './PageNavigationBar/links';
import { useMediaQuery } from 'react-responsive';

const StyledContainer = styled.div`
  background-color: var(--color-main-grey);
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-bottom: 15px;
`;

const StyledContentContainer = styled.div`
  display: flex;

  @media ${theme.device.tablet.max} {
    padding-top: 70px;
  }

  @media ${theme.device.mobile.max} {
    padding-top: 70px;
  }
`;

const StyledPageContainer = styled.div`
  flex: 1;
  font-size: 1rem;
  margin-top: 2.112rem;
  padding: 0 1rem;
`;

const PageView = ({ children }: PageViewProps) => {
  const isDesktop = useMediaQuery({ query: theme.device.desktop.min });
  return (
    <StyledContainer>
      <Header isThin={true} />
      <StyledContentContainer>
        <PageNavigationBar shoulShow={isDesktop} links={links} />
        <StyledPageContainer>{children}</StyledPageContainer>
      </StyledContentContainer>
    </StyledContainer>
  );
};

export { PageView };
