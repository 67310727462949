import { ComponentProps } from 'react';
import { Modal } from 'antd';

export enum ArchiveOptions {
  WITH_ACTIONS = 'WITH_ACTIONS',
  KEEP_ACTIONS = 'KEEP_ACTIONS',
}

export type OutcomeArchiveModalProps = ComponentProps<typeof Modal> & {
  onConfirm: (option: ArchiveOptions) => void;
  count: number;
};
