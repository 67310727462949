import { FetchOutcomes, UpdateOutcome } from '../../outcomes/types';
import { FETCH_OUTCOMES } from '../../outcomes/graphql/queries';
import { FetchUserWorkspaceActions } from '../../actions/types';
import { FETCH_ACTIONS } from '../../actions/graphql/queries';
import {
  checkIsShownEntity,
  Filter,
  OperationType,
  updatedCacheData,
  updateWorkspaceTagsData,
} from '../../utils';
import { mutateOutcome } from '../../../context/userWorkspaceContext';
import { ApolloCache, ApolloClient } from '@apollo/client';
import { Outcome } from '../../../core/types/outcome';
import { Action } from '../../../core/types/action';
import { checkOutcomeForComplete } from '../utils';
import { EntityType } from '../../../apollo/stateFields/userSettings/userSettingsField';

interface Variables {
  filterInput: Filter[];
  apolloClient: ApolloClient<any>;
  setOutcomeToComplete: (value: Outcome | null) => void;
  setOutcomeToIncomplete: (value: Outcome | null) => void;
  setUpdateFilterInputId: (
    payload: null | {
      type: EntityType;
      workspaceId: number;
      id: number;
    },
  ) => void;
}

export const getUpdateFuncForUpdateOutcome = (
  outcomeToUpdate: Outcome,
  dataForUpdateFunction: Variables,
) => {
  const {
    filterInput,
    apolloClient,
    setOutcomeToComplete,
    setOutcomeToIncomplete,
    setUpdateFilterInputId,
  } = dataForUpdateFunction;
  return (cache: ApolloCache<UpdateOutcome>, { data }: any) => {
    const { fetchUserWorkspaceOutcomes: oldWorkspaceOutcomes = [] } =
      cache.readQuery<FetchOutcomes>({
        query: FETCH_OUTCOMES,
        variables: {
          workspaceId: outcomeToUpdate.workspaceId,
        },
      }) || {};

    const { fetchUserWorkspaceOutcomes: newWorkspaceOutcomes = [] } =
      cache.readQuery<FetchOutcomes>({
        query: FETCH_OUTCOMES,
        variables: {
          workspaceId: data?.updateUserWorkspaceOutcome.workspaceId,
        },
      }) || {};

    const { fetchUserWorkspaceActions = [] } =
      cache.readQuery<FetchUserWorkspaceActions>({
        query: FETCH_ACTIONS,
        variables: {
          workspaceId: outcomeToUpdate.workspaceId,
        },
      }) || {};

    let oldWorkspaceResult = oldWorkspaceOutcomes;
    let newWorkspaceResult = newWorkspaceOutcomes;

    if (data?.updateUserWorkspaceOutcome.workspaceId === outcomeToUpdate.workspaceId) {
      oldWorkspaceResult = updatedCacheData(
        oldWorkspaceOutcomes,
        data?.updateUserWorkspaceOutcome,
        OperationType.UPDATE,
      );
    } else if (data?.updateUserWorkspaceOutcome.workspaceId !== outcomeToUpdate.workspaceId) {
      oldWorkspaceResult = updatedCacheData(
        oldWorkspaceOutcomes,
        data?.updateUserWorkspaceOutcome,
        OperationType.DELETE,
      );
      newWorkspaceResult = updatedCacheData(
        newWorkspaceOutcomes,
        data?.updateUserWorkspaceOutcome,
        OperationType.CREATE,
      );

      cache.writeQuery<FetchUserWorkspaceActions>({
        query: FETCH_ACTIONS,
        data: {
          fetchUserWorkspaceActions: fetchUserWorkspaceActions.filter((action) => {
            return !data?.updateUserWorkspaceOutcome?.actions?.some(
              (item: Action) => item.id === action.id,
            );
          }),
        },
        variables: {
          workspaceId: outcomeToUpdate.workspaceId,
        },
      });
    }

    cache.writeQuery<FetchOutcomes>({
      query: FETCH_OUTCOMES,
      data: {
        fetchUserWorkspaceOutcomes: oldWorkspaceResult,
      },
      variables: {
        workspaceId: outcomeToUpdate.workspaceId,
      },
    });

    data?.updateUserWorkspaceOutcome.workspaceId !== outcomeToUpdate.workspaceId &&
      cache.writeQuery<FetchOutcomes>({
        query: FETCH_OUTCOMES,
        data: {
          fetchUserWorkspaceOutcomes: newWorkspaceResult,
        },
        variables: {
          workspaceId: data?.updateUserWorkspaceOutcome.workspaceId,
        },
      });

    if (apolloClient && data?.updateUserWorkspaceOutcome) {
      mutateOutcome(
        apolloClient,
        data.updateUserWorkspaceOutcome,
        'update',
        filterInput,
        outcomeToUpdate,
      );
      updateWorkspaceTagsData(apolloClient, data?.updateUserWorkspaceOutcome);
      checkOutcomeForComplete(
        setOutcomeToComplete,
        setOutcomeToIncomplete,
        data?.updateUserWorkspaceOutcome,
      );

      if (
        !checkIsShownEntity(data.updateUserWorkspaceOutcome, filterInput) &&
        data.updateUserWorkspaceOutcome.workspaceId !== outcomeToUpdate.workspaceId
      ) {
        setUpdateFilterInputId({
          type: 'Outcome',
          workspaceId: data.updateUserWorkspaceOutcome.workspaceId,
          id: data.updateUserWorkspaceOutcome.id,
        });
      }
    }
  };
};
