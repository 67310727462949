import React from 'react';
import styled, { css } from 'styled-components';
import { kebabCase } from 'lodash-es';
import { ActiveLink } from 'core/components/common';
import { useQuery } from '@apollo/client';
import { AUTH_STATE, AuthValue } from '../../../apollo/stateFields/auth/authFields';
import { PageLinkProps } from '../types';
import { ACTION_PATH } from '../../../core/constants/routePaths';
import { ReactComponent as LeftArrowIcon } from '../../img/arrow-left-big.svg';
import { ReactComponent as LogoutIcon } from '../../../core/svg/common/logout.svg';
import { useAuth } from '../../../context/authContext';

// TODO: Check if we can use the function that `styled-components` uses to generate unique
// CSS class names.
const ACTIVE_CLASS_NAME = 'page-nav-bar__active-link';

const StyledContainer = styled.div<{ shouldShow: boolean }>`
  width: 14.4rem;
  display: flex;
  flex-direction: column;
  margin-top: 4.4rem;
  padding-left: 2.167rem;
  min-width: 230px;

  ${({ shouldShow }) =>
    !shouldShow &&
    css`
      display: none;
    `}
`;

const StyledBoardLink = styled(ActiveLink)`
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  margin-bottom: 44px;
`;

const StyledLogoutLink = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  padding-left: 18px;
  margin-top: 40px;

  display: flex;
  align-items: center;
`;

const StyledLeftArrowIcon = styled(LeftArrowIcon)`
  margin-right: 8px;
  margin-left: -12px;
`;

const StyledLogoutIcon = styled(LogoutIcon)`
  margin-left: 12px;
`;

const StyledActiveLink = styled(ActiveLink).attrs({
  activeClassName: ACTIVE_CLASS_NAME,
})`
  height: 1.2rem;
  padding-left: 0.889rem;
  border-left: 2px solid transparent;

  &:not(:last-child) {
    margin-bottom: 1.167rem;
  }

  &.${ACTIVE_CLASS_NAME} {
    border-left-color: var(--color-green);
    font-weight: 700;
  }
`;

const PageNavigationBar = ({
  links,
  shoulShow,
}: {
  links: PageLinkProps[];
  shoulShow: boolean;
}) => {
  const { signOut } = useAuth();
  const { data } = useQuery(AUTH_STATE);
  const { idToken }: AuthValue = data?.auth;

  const handleLogout = async () => {
    signOut && (await signOut());
  };

  const renderLink = ({
    to,
    text,
    isVisibleForUnLogged,
    isVisibleForLogged,
  }: typeof links[number]) => {
    if ((idToken && isVisibleForLogged) || (!idToken && isVisibleForUnLogged)) {
      return (
        <StyledActiveLink key={`${kebabCase(text)}-link`} to={to}>
          {text}
        </StyledActiveLink>
      );
    }
  };

  return (
    <StyledContainer shouldShow={shoulShow}>
      {idToken && (
        <StyledBoardLink key={`back-link`} to={ACTION_PATH}>
          <StyledLeftArrowIcon />
          Back to App
        </StyledBoardLink>
      )}
      {links.map(renderLink)}
      {idToken && (
        <StyledLogoutLink key={`logout-link`} onClick={handleLogout}>
          Log out
          <StyledLogoutIcon />
        </StyledLogoutLink>
      )}
    </StyledContainer>
  );
};

export { PageNavigationBar };
