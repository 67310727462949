import React from 'react';
import { Container, Features, Header } from '../../../features/Auth/components';
import { Col, Row } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import { theme } from '../../styles/styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    background: var(--gradient-sign-in-background);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`;

const StyledContainer = styled(Container)`
  min-height: var(--app-height);
`;

const StyledCol = styled(Col)`
  @media ${theme.device.mobile.max} {
    width: 100%;
  }
`;

const SignInLayoutWrapper = ({ children }: any) => {
  return (
    <StyledContainer data-id="sign-in-page">
      <GlobalStyle />
      <Header />
      <Row justify="space-between">
        <StyledCol flex={1}>
          <Features />
        </StyledCol>
        <StyledCol>{children}</StyledCol>
      </Row>
    </StyledContainer>
  );
};

export default SignInLayoutWrapper;
