import React, { useEffect, useState } from 'react';

export const HeightPreservingItem = ({ children, ...props }: any) => {
  const [size, setSize] = useState(0);
  const knownSize = props['data-known-size'];
  useEffect(() => {
    setSize((prevSize) => {
      return knownSize == 0 ? prevSize : knownSize;
    });
  }, [knownSize]);
  // check style.css for the height-preserving-container rule
  return (
    <div
      {...props}
      className="height-preserving-container"
      style={{
        '--child-height': `${size}px`,
        '--data-known-size': props['data-known-size'] + 'px',
      }}
    >
      {children}
    </div>
  );
};
