import { tutorialInitialValue, tutorialVar } from './tutorialField';
import { Activity, Question } from '../../../pages/Onboarding/types';

const resetTutorial = () => {
  const value = {
    ...tutorialInitialValue,
  };
  tutorialVar(value);
};

const setShowTutorialVideo = (payload: boolean) => {
  const value = {
    ...tutorialVar(),
    showTutorialVideo: payload,
  };
  tutorialVar(value);
};

const setCurrentStep = (payload: number) => {
  const value = {
    ...tutorialVar(),
    currentStep: payload === 6 ? -1 : payload,
  };
  tutorialVar(value);
};

const setOnboardingStep = (payload: number) => {
  const value = {
    ...tutorialVar(),
    onboardingStep: payload,
  };
  tutorialVar(value);
};

const setOnboardingSpaces = (payload: Activity) => {
  const value = {
    ...tutorialVar(),
    onboardingSpaces: payload,
  };
  tutorialVar(value);
};

const setInitCompletedStep = (payload: number) => {
  if (!tutorialVar().completedSteps.find((s) => s === payload)) {
    const value = {
      ...tutorialVar(),
      completedSteps: tutorialVar().completedSteps.concat(payload),
    };
    tutorialVar(value);
  }
};

const setCompletedStep = (payload: number) => {
  let value = {
    ...tutorialVar(),
    currentStep: payload === 5 ? -1 : payload + 1,
  };

  if (!tutorialVar().completedSteps.find((s) => s === payload)) {
    value.completedSteps = tutorialVar().completedSteps.concat(payload);
  }

  tutorialVar(value);
};

const setUserPreferences = (payload: Activity[]) => {
  const value = {
    ...tutorialVar(),
    userPreferences: payload,
  };
  tutorialVar(value);
};

const setOnboardingQuestionnaire = (payload: Question) => {
  const value = {
    ...tutorialVar(),
    onboardingQuestionnaire: [
      ...tutorialVar().onboardingQuestionnaire.filter(
        (question) => question.question !== payload.question,
      ),
      payload,
    ],
  };
  tutorialVar(value);
};

export {
  setShowTutorialVideo,
  setCurrentStep,
  setOnboardingStep,
  setOnboardingSpaces,
  setInitCompletedStep,
  setCompletedStep,
  setUserPreferences,
  resetTutorial,
  setOnboardingQuestionnaire,
};
