import { gql } from '@apollo/client';

//TODO ==> add field outcome with name and id, when server is ready
export const ACTION_FIELDS = gql`
  fragment actionFields on Action {
    id
    name
    status
    description
    startDate
    actualStartDate
    actualPoints
    estimatedPoints
    endDate
    updateDate
    createDate
    workspaceId
    actualEndDate
    priority
    tags {
      id
      name
    }
    outcome {
      id
      name
      status
      isAllActionsDone
    }
    isArchived
    actionTimeFormat
    estimatedTime
    spendTime
  }
`;

export const NOTE_FIELDS = gql`
  fragment noteFields on Note {
    createDate
    updateDate
    description
    id
    isDeleted
    name
    updateDate
    workspaceId
    isArchived
  }
`;

export const FOCUS_TIMER_FIELDS = gql`
  fragment focusTimerFields on FocusTimer {
    actionId
    createDate
    fixedPeriodSelected
    id
    resumeTime
    spendTime
    startTime
    status
    updateDate
    userId
    isBipSound
  }
`;
