import React, { useCallback } from 'react';
import { getPriorityIcon, getPriorityTitle } from './utils';
import { StyledIconWrapper } from './StyledComponents';
import { Action } from '../../../../../core/types/action';
import { ContextMenuOption } from '../../../../../core/components/common/Card/types';
import { Dropdown, Menu } from 'antd';
import { kebabCase } from 'lodash-es';
import styled from 'styled-components';
import { CONTEXT_SUBMENU_OPTIONS } from './constants';

const StyledWrapper = styled.div<{ disabled: boolean }>`
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
`;
const StyledSubMenu = styled(Menu)`
  && {
    position: relative;

    .ant-dropdown-menu-submenu-expand-icon {
      display: none !important;
    }
    .ant-dropdown-menu-submenu-title {
      display: flex;
      font-size: 12px;
      font-weight: 700;
      padding: 5px 25px;
      line-height: unset;

      &:hover {
        background-color: #e0e0e0;
      }

      span {
        align-items: center;
        display: flex;
        position: relative;

        & > svg {
          height: 16px;
          left: -20px;
          position: absolute;
        }
      }
    }
  }
  .ant-dropdown-menu-submenu-popup ul {
    //background-color: var(--color-main-grey-2);
  }
`;
const StyledSubMenuOption = styled(Menu.Item)<{ highlighted?: boolean }>`
  font-size: 12px;
  font-weight: ${(props) => (props.highlighted ? 700 : 400)};
  padding: 5px 10px 5px 25px;
  line-height: unset;

  &:hover {
    background-color: #e0e0e0;
  }

  span {
    align-items: center;
    display: flex;
    position: relative;

    & > svg {
      height: 16px;
      left: -20px;
      position: absolute;
    }
  }
`;

interface RenderContextProps {
  options: ContextMenuOption[];
  onOptionClick: (selectedOption: ContextMenuOption, item?: any) => void;
  highlightedContextMenuOptions?: string[];
  item?: any;
  count?: number;
}
const renderContextMenu = ({
  options,
  onOptionClick,
  highlightedContextMenuOptions = [],
  item,
}: RenderContextProps): JSX.Element => {
  return (
    <StyledSubMenu>
      {options.map((option) => {
        return (
          <StyledSubMenuOption
            key={kebabCase(option.label)}
            onClick={() => onOptionClick(option, item)}
            highlighted={!!highlightedContextMenuOptions.find((opt) => opt === option.label)}
          >
            {option.icon && <option.icon />} {option.label}
          </StyledSubMenuOption>
        );
      })}
    </StyledSubMenu>
  );
};

const PriorityMenu = ({
  action,
  disabled,
  handleContextMenuOptionClick,
  className,
}: {
  action: Action;
  disabled: boolean;
  handleContextMenuOptionClick: (option: ContextMenuOption) => void;
  className?: string;
}) => {
  const getDropdownContainer = useCallback(() => document.body, []);

  return (
    <StyledWrapper disabled={disabled} className={className}>
      <Dropdown
        disabled={disabled}
        trigger={['click']}
        overlay={renderContextMenu({
          options: CONTEXT_SUBMENU_OPTIONS.PRIORITY,
          onOptionClick: handleContextMenuOptionClick,
          highlightedContextMenuOptions: [getPriorityTitle(action.priority)],
        })}
        getPopupContainer={getDropdownContainer}
      >
        <StyledIconWrapper>{getPriorityIcon(action)}</StyledIconWrapper>
      </Dropdown>
    </StyledWrapper>
  );
};

export default PriorityMenu;
