import React, { useEffect, useState } from 'react';
import { StyledButtonsContainer, StyledDescription, StyledTabContainer } from '../StyledComponents';
import { Collapse, Input } from 'antd';
import { toast } from 'react-toastify';
import { Button } from '../../../core/components/common';

const { Panel } = Collapse;

const Curriculum = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  // First section
  const [field1, setField1] = useState('');
  const [field2, setField2] = useState('');
  const handleFirstSectionSubmit = () => {
    console.log(
      field1.split(',').map((val) => val.trim()),
      field2.split(',').map((val) => val.trim()),
    );
    setIsSuccess(true);
  };
  const handleFirstSectionReset = () => {
    setField1('');
    setField2('');

    setErrorMessage('Test error');
  };
  // Second section
  const [field3, setField3] = useState('');
  const [field4, setField4] = useState('');
  const handleSecondSectionSubmit = () => {
    console.log(field3, field4);
  };
  const handleSecondSectionReset = () => {
    setField3('');
    setField4('');
  };
  // Third section
  const [field5, setField5] = useState('');
  const [field6, setField6] = useState('');
  const handleThirdSectionSubmit = () => {
    console.log(field5, field6);
    setIsSuccess(true);
  };
  const handleThirdSectionReset = () => {
    setField5('');
    setField6('');
    setErrorMessage('Test error');
  };

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      setErrorMessage(null);
    }
  }, [errorMessage]);
  useEffect(() => {
    if (isSuccess) {
      toast.success('Successful');
      setIsSuccess(false);
    }
  }, [isSuccess]);

  return (
    <StyledTabContainer>
      <Collapse>
        {/*<Panel header="Change curriculum" key="1">*/}
        {/*  <StyledDescription>*/}
        {/*    Change curriculum option gives Admin possibility to enrol a user on a different*/}
        {/*    curriculum. Admin has to chose curriculum and add user email. The user will start a new*/}
        {/*    curriculum from scratch, all progress on the previous curriculum is lost.*/}
        {/*  </StyledDescription>*/}
        {/*  <Input*/}
        {/*    value={field1}*/}
        {/*    onChange={(e) => setField1(e.target.value)}*/}
        {/*    bordered*/}
        {/*    size="large"*/}
        {/*    placeholder="Emails"*/}
        {/*  />*/}
        {/*  <Input*/}
        {/*    value={field2}*/}
        {/*    onChange={(e) => setField2(e.target.value)}*/}
        {/*    bordered*/}
        {/*    size="large"*/}
        {/*    placeholder="Curriculum"*/}
        {/*  />*/}
        {/*  <StyledButtonsContainer>*/}
        {/*    <Button isSecondary onClick={handleFirstSectionReset}>*/}
        {/*      Reset*/}
        {/*    </Button>*/}
        {/*    <Button onClick={handleFirstSectionSubmit}>Submit</Button>*/}
        {/*  </StyledButtonsContainer>*/}
        {/*</Panel>*/}
        {/*<Panel header="This is second query" key="2">*/}
        {/*  <StyledDescription>*/}
        {/*    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus labore, nostrum! A,*/}
        {/*    accusamus, adipisci aut cupiditate eius esse incidunt molestiae numquam obcaecati.*/}
        {/*  </StyledDescription>*/}
        {/*  <Input*/}
        {/*    value={field3}*/}
        {/*    onChange={(e) => setField3(e.target.value)}*/}
        {/*    bordered*/}
        {/*    size="large"*/}
        {/*    placeholder="large size"*/}
        {/*  />*/}
        {/*  <Input*/}
        {/*    value={field4}*/}
        {/*    onChange={(e) => setField4(e.target.value)}*/}
        {/*    bordered*/}
        {/*    size="large"*/}
        {/*    placeholder="large size"*/}
        {/*  />*/}
        {/*  <StyledButtonsContainer>*/}
        {/*    <Button isSecondary onClick={handleSecondSectionReset}>*/}
        {/*      Reset*/}
        {/*    </Button>*/}
        {/*    <Button onClick={handleSecondSectionSubmit}>Submit</Button>*/}
        {/*  </StyledButtonsContainer>*/}
        {/*</Panel>*/}
        {/*<Panel header="This is third query" key="3">*/}
        {/*  <StyledDescription>*/}
        {/*    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus labore, nostrum! A,*/}
        {/*    accusamus, adipisci aut cupiditate eius esse incidunt molestiae numquam obcaecati.*/}
        {/*  </StyledDescription>*/}
        {/*  <Input*/}
        {/*    value={field5}*/}
        {/*    onChange={(e) => setField5(e.target.value)}*/}
        {/*    bordered*/}
        {/*    size="large"*/}
        {/*    placeholder="large size"*/}
        {/*  />*/}
        {/*  <Input*/}
        {/*    value={field6}*/}
        {/*    onChange={(e) => setField6(e.target.value)}*/}
        {/*    bordered*/}
        {/*    size="large"*/}
        {/*    placeholder="large size"*/}
        {/*  />*/}
        {/*  <StyledButtonsContainer>*/}
        {/*    <Button isSecondary onClick={handleThirdSectionReset}>*/}
        {/*      Reset*/}
        {/*    </Button>*/}
        {/*    <Button onClick={handleThirdSectionSubmit}>Submit</Button>*/}
        {/*  </StyledButtonsContainer>*/}
        {/*</Panel>*/}
      </Collapse>
    </StyledTabContainer>
  );
};

export default Curriculum;
