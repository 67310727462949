import { Action } from '../../core/types/action';
import { Outcome } from '../../core/types/outcome';

export const checkActionForComplete = (
  setOutcomeToComplete: (value: Outcome | null) => void,
  setOutcomeToIncomplete: (value: Outcome | null) => void,
  entityAction: Action,
) => {
  const entity = JSON.parse(JSON.stringify(entityAction));
  if (!!entity.outcome && entity?.outcome?.isAllActionsDone !== null) {
    entity.outcome.workspaceId = entity.workspaceId;
    if (entity.outcome?.isAllActionsDone) {
      setOutcomeToComplete(entity.outcome);
      return;
    } else {
      setOutcomeToIncomplete(entity.outcome);
      return;
    }
  }
  setOutcomeToComplete(null);
  setOutcomeToIncomplete(null);
};

export const checkOutcomeForComplete = (
  setOutcomeToComplete: (value: Outcome | null) => void,
  setOutcomeToIncomplete: (value: Outcome | null) => void,
  entity: Outcome,
) => {
  if (entity && entity?.isAllActionsDone !== null) {
    if (entity.isAllActionsDone) {
      setOutcomeToComplete(entity);
      return;
    } else {
      setOutcomeToIncomplete(entity);
      return;
    }
  }
  setOutcomeToComplete(null);
  setOutcomeToIncomplete(null);
};
