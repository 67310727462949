import React, { useCallback, useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';
import { Flip, toast, ToastContainer, ToastContent } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// TODO: Check why I can't import the minified version.
import 'react-toastify/dist/ReactToastify.css';

import 'core/integrations/firebase/init';
import AppController from 'core/components/AppController';
import ErrorBoundary from 'core/components/ErrorBoundary';
import { GlobalStyle, theme } from 'core/styles/styled-components';
import { NODE_ENV } from 'core/constants/others';
import { AuthProvider } from './context/authContext';
import { ConfigProvider } from 'antd';
import { setAppHeight } from './core/utils/setAppHeight';
import { apolloClient } from './apollo/client';
import { firebase } from './core/integrations/firebase/init';
import { checkWebView } from './features/utils/checkWebView';
import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module';
import { tagManagerArgs } from './core/integrations/gtm/conf';
import { GoogleOAuthProvider } from '@react-oauth/google';

const history = createBrowserHistory();

// list of acceptable node envs for sentry initialisation
const NODE_ENVS: string[] = [
  // 'dev', 'main', 'test', 'stage', 'production'
];
const initSentry = NODE_ENVS.includes(NODE_ENV);

if (initSentry) {
  Sentry.init({
    dsn: 'https://3553194da9bf46eeb6e831a23b12ef92@o589722.ingest.sentry.io/5740098',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
    environment: NODE_ENV,
  });
}

if (NODE_ENV === 'production') {
  TagManager.initialize(tagManagerArgs);
}

const App = () => {
  const [token, setToken] = useState('none');
  const [isPassRefreshed, setIsPassRefreshed] = useState(false);
  setAppHeight();
  checkWebView();

  const idTokenWatcher = useCallback(
    async (u) => {
      if (!u) {
        if (localStorage.getItem('sign-out')) {
          setToken('sign-out');
          setTimeout(() => {
            setToken('none');
          }, 0);
          localStorage.removeItem('sign-out');
        }
      }
    },
    [token],
  );

  useEffect(() => {
    let unsubscribe: any;
    try {
      unsubscribe = firebase.auth().onIdTokenChanged(idTokenWatcher);
    } catch (error) {
      console.error(error);
      toast(error as ToastContent);
    }
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const listener = (e: any) => {
      if (
        e.message === 'ResizeObserver loop completed with undelivered notifications.' ||
        e.message === 'ResizeObserver loop limit exceeded'
      ) {
        e.stopImmediatePropagation();
      }
    };
    window.addEventListener('error', listener);
    return () => window.removeEventListener('error', listener);
  }, []);

  return (
    <Sentry.ErrorBoundary>
      <ErrorBoundary>
        <GoogleOAuthProvider clientId="509296487748-8i6d8e0p2dkqj741pt9q2t3vvovd4mb7.apps.googleusercontent.com">
          <ApolloProvider client={apolloClient} key={token}>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <Helmet>
                <meta
                  name="title"
                  key="title"
                  content="BillionMinds App and Productivity Platform"
                />
                <meta
                  name="description"
                  key="description"
                  content="Work/life optimization software integrated with skills exercises, micro-learning videos, and one-on-one coaching. Based on behavioral science."
                />
              </Helmet>
              <ConfigProvider
                getPopupContainer={(trigger) => trigger && (trigger.parentElement as HTMLElement)}
              >
                <Router history={history}>
                  <AuthProvider>
                    <AppController
                      isPassRefreshed={isPassRefreshed}
                      setIsPassRefreshed={setIsPassRefreshed}
                    />
                  </AuthProvider>
                </Router>
              </ConfigProvider>
              <ToastContainer
                hideProgressBar
                draggable={false}
                position="bottom-center"
                transition={Flip}
              />
            </ThemeProvider>
          </ApolloProvider>
        </GoogleOAuthProvider>
      </ErrorBoundary>
    </Sentry.ErrorBoundary>
  );
};

export default App;
