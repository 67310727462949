import React from 'react';
import { ReactComponent as AddPersonIcon } from './svg/add-person.svg';
import styled, { css } from 'styled-components';

const StyledIconWrapper = styled.div<{ isFocusTimer?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  width: 19px;
  height: 15px;
  background-color: transparent;

  svg {
    // fix for safari
    width: 16px;
    height: 12px;
  }

  ${({ isFocusTimer }) => {
    if (isFocusTimer) {
      return css`
        color: var(--color-white);
      `;
    } else {
      return css`
        color: var(--color-dark-blue);
      `;
    }
  }}
`;

const StyledAddPersonIcon = styled(AddPersonIcon)``;

const StyledNumber = styled.div`
  position: absolute;
  left: 16px;
  bottom: 7px;

  font-size: 7px;
  font-weight: 600;
  opacity: 0.5;
`;

export type SharedProps = { count?: number; className?: string; isFocusTimer?: boolean };

const SharedPeopleIcon = ({ count, className, isFocusTimer }: SharedProps) => {
  return (
    <StyledIconWrapper className={className} isFocusTimer={isFocusTimer}>
      <StyledAddPersonIcon />
      {!!count && <StyledNumber>{count}</StyledNumber>}
    </StyledIconWrapper>
  );
};

export { SharedPeopleIcon };
