import React, { useEffect, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { useQuery } from '@apollo/client';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../apollo/stateFields/filterInput/filterInputFields';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../apollo/stateFields/userSettings/userSettingsField';

const MouseOverCursorWrapper = ({
  children,
  targetClassName,
  cursorImage,
  isOpen,
}: {
  children: any;
  targetClassName: string;
  cursorImage: string;
  isOpen: boolean;
}) => {
  const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
  const { isEditing }: UserSettingsValue = userSettingsData?.userSettingsField;
  const { data: filterData } = useQuery(FILTER_STATE);
  const { updateFilterInputId }: FilterInputValue = filterData?.filterInput;
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);

  const handleMouseOver = (e: any) => {
    if (e.target.className === targetClassName) {
      setIsMouseOver(true);
    } else {
      setIsMouseOver(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsMouseOver(true);
      }, 300);
    }
  }, [isOpen]);

  const GlobalStyle = createGlobalStyle`
    html {
      cursor: ${
        isOpen &&
        isMouseOver &&
        !(updateFilterInputId && updateFilterInputId.length >= 1 && !isEditing)
          ? `url(${cursorImage}), auto !important`
          : 'auto'
      };
    }
  `;

  return (
    <div onMouseOver={handleMouseOver}>
      <GlobalStyle />
      {children}
    </div>
  );
};

export { MouseOverCursorWrapper };
