import { BoardColumns } from 'apollo/stateFields/dnd/dndFields';
import { Filter } from '../features/utils';
import { Activity } from '../pages/Onboarding/types';
import { ModalSetting } from '../apollo/stateFields/userSettings/userSettingsField';
import {
  DoneColumnFilterTypes,
  ToDoColumnFilterTypes,
} from '../apollo/stateFields/boardFilters/boardFiltersFields';
import { PLANS_TYPES } from '../apollo/stateFields/stripe/stripeFields';
import { FocusTimerType } from '../features/actions/components/ActionBoard/ActionCard/FocusTimer/FocusSettingsModal';
import { CoursesTitle } from '../apollo/stateFields/courses/coursesFields';

export type ColumnName = 'ToDo' | 'Done';

interface ColumnFilterData {
  name: ColumnName;
  filter: DoneColumnFilterTypes | ToDoColumnFilterTypes;
  startDate: string | null;
  endDate: string | null;
}
interface UserEntity {
  email: string;
  firstName: string;
  lastName: string;
  name: string;
}
interface UserByEmailEntity {
  bmSubscription: {
    expiration?: number;
    bmSubscriptionPlan: BmSubscriptionTypes;
    bmSubscriptionPlanId: string;
  };
  coursesAvailableList: [CoursesTitle, CoursesTitle];
  email: string;
  name?: string;
  id: string;
}
interface UpdateUserEntityResponse {
  email?: string;
  firstName?: string;
  lastName?: string;
  isActivated?: boolean;
  isFBaccountWithPhone?: boolean;
}

export type SectionName =
  | 'Core_Skills'
  | 'Take_Back_Control_of_Your_Day'
  | 'Find_Meaning_in_Your_Day'
  | 'Integrate_Work_With_Your_Life'
  | 'Master_Your_Unpredictable_Day';

export interface CoursesVideo {
  duration: string;
  id: number;
  isAvailable: boolean;
  name: string;
  section: SectionName;
  url: string;
}

export interface CoursesVars {
  dateLastWatched: string | null;
  lastWatchedId: number | null;
  watchedVideoIds: number[] | null;
}

export interface CoursesConsts {
  title: string;
  certificateUrl: string | null;
  isCourseAvailable: boolean;
  videos: CoursesVideo[];
}

export interface Settings {
  boardColumns: BoardColumns;
  filterInput: Filter[];
  shouldUpdateStatusToComplete: ModalSetting;
  shouldUpdateStatusToCurrent: ModalSetting;
  setStartDateToToday: ModalSetting;
  setUpdateFilterInput: ModalSetting;
  shouldOutcomeLinkAction: ModalSetting;
  shouldActionLinkOutcome: ModalSetting;
  defaultWorkspaceId: number | null;
  recommendedCompanyRemindDate: string;
  recommendedCompany: string[];
  tutorialPassedStep: number[];
  userPreferences: Activity[];
  doneColumnFilter: ColumnFilterData;
  toDoColumnFilter: ColumnFilterData;
  timezone: string | null;
  subscriptionReminderTime: null | number;
  freeTrialReminderTime: null | number;
}

export interface FocusTimerSettings {
  focusTimerFixedPeriod: null | number;
  focusTimerIsBipSound: null | boolean;
  focusTimerType: FocusTimerType;
}

export interface CoursesSettings {
  coursePassedStep: number | null;
  coursesConsts: {
    Foundations: CoursesConsts | null;
    MasteringPersonalEffectiveness: CoursesConsts | null;
    Fundamentals: CoursesConsts | null;
    RemoteWorkProfessional: CoursesConsts | null;
    FundamentalsManager: CoursesConsts | null;
    RemoteWorkProfessionalManager: CoursesConsts | null;
  };
  coursesVars: {
    Foundations: CoursesVars;
    MasteringPersonalEffectiveness: CoursesVars;
    Fundamentals: CoursesVars;
    RemoteWorkProfessional: CoursesVars;
    FundamentalsManager: CoursesVars;
    RemoteWorkProfessionalManager: CoursesVars;
  };
  skipNotificationTime: null | number;
}

export enum BmSubscriptionTypes {
  Trial_Free = 'Trial_Free',
  Trial_ThreeMonth = 'Trial_ThreeMonth',

  SelfGuided_Free = 'SelfGuided_Free',
  SelfGuided_Individual = 'SelfGuided_Individual',
  CoachLed_Individual = 'CoachLed_Individual',
  FlexibleWorkProfessional_Individual = 'FlexibleWorkProfessional_Individual',

  CertificationPackage_Teams = 'CertificationPackage_Teams',
  RemoteWorkSkillsDevelopment_Teams = 'RemoteWorkSkillsDevelopment_Teams',
  Enterprise_Teams = 'Enterprise_Teams',
}

export enum StripeSubscriptionStatus {
  active = 'active',
  canceled = 'canceled',
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  past_due = 'past_due',
  trialing = 'trialing',
  unpaid = 'unpaid',
}

export enum StripeSubscriptionIntervals {
  month = 'month',
  year = 'year',
  oneTime = 'oneTime',
}

export interface StripePricesMetadata {
  category: PLANS_TYPES;
  description: string;
  features: string[];
  title: string;
  label?: string;
}

export interface Invoice {
  hostedInvoiceUrl: string;
  id: string;
  periodEnd: number;
  periodStart: number;
  subtotal: number;
  total: number;
  totalDiscountAmounts: {
    amount: number;
    discount: string;
  };
}

export interface StripePricesResponse {
  amount: number;
  bmSubscriptionPlan: BmSubscriptionTypes;
  bmSubscriptionPlanId: string;
  created: number;
  currency: string;
  interval: StripeSubscriptionIntervals;
  updated: number;
  metadata: string;
}

export interface StripePrices {
  amount: number;
  bmSubscriptionPlan: BmSubscriptionTypes;
  bmSubscriptionPlanId: string;
  created: number;
  currency: string;
  interval: StripeSubscriptionIntervals;
  updated: number;
  metadata: StripePricesMetadata;
  bmSubscriptionProductId: string;
}

export interface UserSubscriptionInfo {
  amount: number;
  bmSubscriptionPlan: BmSubscriptionTypes;
  bmSubscriptionPlanId: string;
  bmSubscriptionProvider: string;
  createDate: string;
  createDateFreeSubscription: string;
  error: string;
  expiration: string;
  externalCustomerId: string;
  externalUnfinishedSubscriptionId: string;
  externalSubscriptionId: string;
  id: number;
  interval: StripeSubscriptionIntervals;
  isEligableToFreeSubscription: boolean;
  isTrialUsed: boolean;
}

export interface StripeSubscriptionInfo {
  amount: number;
  currency: string;
  clientSecret: string;
  canceledAt: number;
  currentPeriodEnd: number;
  currentPeriodStart: number;
  interval: StripeSubscriptionIntervals;
  status: StripeSubscriptionStatus;
  subscriptionId: string;
  isCancelAtPeriodEnd: boolean;
}

export interface FetchStripeSubscriptionInfo {
  bmSubscription: UserSubscriptionInfo;
  subscription: StripeSubscriptionInfo;
  unfinishedSubscription: StripeSubscriptionInfo;
}

export interface ValidatePromoCodeResponse {
  active: boolean;
  code: string;
  coupon: {
    amountOff: number;
    currency: string;
    duration: string;
    durationInMonth: number;
    id: number;
    maxRedemptions: number;
    name: string;
    percentOff: number;
    timesRedeemed: number;
    valid: boolean;
  };
  expiresAt: number;
  id: string;
  maxRedemptions: number;
}

export interface CreateSubscriptionResponse {
  clientSecret: string | null;
  subscriptionId: string;
}

export interface CardInfo {
  brand: string;
  country: string;
  exp_month: string;
  exp_year: string;
  last4: string;
}

export type FetchUserSettings = {
  fetchUserSettings: Settings;
};

export type FetchFocusTimerSettings = {
  fetchUserSettings: FocusTimerSettings;
};

export type FetchCoursesSettings = {
  fetchUserSettings: CoursesSettings;
};

export type UpdateUserSettings = {
  updateUserSettings: Settings;
};

export type UpdateCoursesSettings = {
  updateUserSettings: CoursesSettings;
};

export type UpdateFocusTimerSettings = {
  updateUserSettings: FocusTimerSettings;
};

export type UpdateUserPreferences = {
  updateUserSettings: {
    userPreferences: Activity[];
  };
};

export type UpdateUserEntity = {
  updateUserEntity: UpdateUserEntityResponse;
};

export type UpdateUserEntityByEmail = {
  updateUserEntityByEmail: UpdateUserEntityResponse;
};

export type FetchStripePrices = {
  fetchStripePrices: StripePrices[];
};

export type FetchUserBmSubscription = {
  fetchUserBmSubscription: UserSubscriptionInfo;
};

export type FetchStripeSubscription = {
  fetchStripeSubscription: FetchStripeSubscriptionInfo;
};

export type ValidateStripePromoCode = {
  validateStripePromoCode: ValidatePromoCodeResponse;
};

export type FetchSubscriptionChangeDiscount = {
  fetchSubscriptionChangeDiscount: { discount: number };
};

export type CreateStripeSubscription = {
  createStripeSubscription: CreateSubscriptionResponse;
};

export type ChangeStripeSubscription = {
  changeStripeSubscription: CreateSubscriptionResponse;
};

export type InitStripeCustomer = {
  initStripeCustomer: {
    id: string;
  };
};

export type CancelStripeSubscription = {
  createStripeSubscription: boolean;
};

export type FetchStripeCreditCard = {
  fetchStripeCreditCard: CardInfo;
};

export type CancelStripeSubscriptionAtPeriodEnd = {
  cancelStripeSubscriptionAtPeriodEnd: FetchStripeSubscriptionInfo;
};

export type FetchStripeUpcomingInvoice = {
  fetchStripeUpcomingInvoice: Invoice;
};

export type FetchStripeLatestInvoice = {
  fetchStripeLatestInvoice: Invoice;
};

export type FetchUser = {
  fetchUser: UserEntity;
};
export type FetchUserByEmail = {
  fetchUserByEmail: UserByEmailEntity;
};
