import React, { useCallback, useMemo } from 'react';
import { Permission, Workspace } from '../../../../core/types/workspace';
import { useQuery } from '@apollo/client';
import { renderContextMenuWithSharedCount } from '../../../../core/components/common';
import { ContextMenuOptions } from './constants';
import { Button, Dropdown } from 'antd';
import { ContextMenuOption } from '../../../../core/components/common/Card/types';
import styled from 'styled-components';
import { ReactComponent as ContextMenuIcon } from '../../../../core/components/common/Card/svg/dots.svg';
import { getMenuOptionsForTagFilter } from '../../utils';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../apollo/stateFields/userSettings/userSettingsField';
import useWorkspaceInvites from '../../../../hooks/useWorkspaceInvites';

const StyledContextMenuButton = styled(Button)`
  margin-left: 4px;
  width: auto;
  height: 20px;
  //padding: 0 5px;
  min-width: 0;
  line-height: 1;
  background-color: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledContextMenuIcon = styled(ContextMenuIcon)`
  cursor: pointer;
`;

const TagFilterOption = ({
  workspace,
  handleContextMenuOptionClick,
}: {
  workspace: Workspace;
  handleContextMenuOptionClick: (option: ContextMenuOption, workspace: Workspace) => void;
}) => {
  const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
  const { defaultWorkspaceId }: UserSettingsValue = userSettingsData?.userSettingsField;
  const getDropdownContainer = useCallback(() => document.body, []);
  const { getWorkspaceInvitesById } = useWorkspaceInvites();

  const sharedCount = getWorkspaceInvitesById(workspace.id)?.count || 0;
  const viewerPermission = useMemo(
    () => workspace?.permission === Permission.VIEWER || false,
    [workspace],
  );
  const editorPermission = useMemo(
    () => workspace?.permission === Permission.EDITOR || false,
    [workspace],
  );

  if (renderContextMenuWithSharedCount) {
    const menuOptions = getMenuOptionsForTagFilter(
      workspace.id === defaultWorkspaceId,
      viewerPermission,
      editorPermission,
    );

    const highlightedContextMenuOptions = [
      ContextMenuOptions.EDIT,
      ContextMenuOptions.REMOVE_FROM_BOARD,
      ContextMenuOptions.SET_DEFAULT,
      ContextMenuOptions.SHARE,
      ContextMenuOptions.LEAVE,
    ];

    return (
      <Dropdown
        trigger={['click']}
        overlay={renderContextMenuWithSharedCount(
          menuOptions,
          handleContextMenuOptionClick,
          highlightedContextMenuOptions,
          [],
          workspace,
          sharedCount,
        )}
        getPopupContainer={getDropdownContainer}
      >
        <StyledContextMenuButton shape="circle" type="text" icon={<StyledContextMenuIcon />} />
      </Dropdown>
    );
  }
  return null;
};

export default TagFilterOption;
