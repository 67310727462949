import { ModalSetting, userSettingsValueInitialValue, userSettingsVar } from './userSettingsField';

const resetUserSettings = () => {
  const value = {
    ...userSettingsValueInitialValue,
  };
  userSettingsVar(value);
};

const setShouldUpdateStatus = (payload: ModalSetting) => {
  const value = {
    ...userSettingsVar(),
    shouldUpdateStatus: payload,
  };

  userSettingsVar(value);
};

const setShouldUpdateFilterInput = (payload: ModalSetting) => {
  const value = {
    ...userSettingsVar(),
    shouldUpdateFilterInput: payload,
  };

  userSettingsVar(value);
};

const setShouldUpdateStartDate = (payload: ModalSetting) => {
  const value = {
    ...userSettingsVar(),
    shouldUpdateStartDate: payload,
  };

  userSettingsVar(value);
};

const setIsEditing = (payload: boolean) => {
  const value = {
    ...userSettingsVar(),
    isEditing: payload,
  };

  userSettingsVar(value);
};

const setIsSettingLoaded = (payload: boolean) => {
  const value = {
    ...userSettingsVar(),
    isSettingsLoaded: payload,
  };

  userSettingsVar(value);
};

const setShouldActionLinkOutcome = (payload: ModalSetting) => {
  const value = {
    ...userSettingsVar(),
    shouldActionLinkOutcome: payload,
  };

  userSettingsVar(value);
};

const setShouldOutcomeLinkAction = (payload: ModalSetting) => {
  const value = {
    ...userSettingsVar(),
    shouldOutcomeLinkAction: payload,
  };

  userSettingsVar(value);
};

const setDefaultWorkspaceId = (payload: number) => {
  const value = {
    ...userSettingsVar(),
    defaultWorkspaceId: payload,
  };

  userSettingsVar(value);
};

const setDefaultWorkspaceName = (payload: string) => {
  const value = {
    ...userSettingsVar(),
    defaultWorkspaceName: payload,
  };

  userSettingsVar(value);
};

const setStartDefaultWorkspaceId = (payload: number) => {
  const value = {
    ...userSettingsVar(),
    startDefaultWorkspaceId: payload,
  };

  userSettingsVar(value);
};

const setUnassignedWorkspaceId = (payload: number) => {
  const value = {
    ...userSettingsVar(),
    unassignedWorkspaceId: payload,
  };

  userSettingsVar(value);
};

export {
  setShouldUpdateStatus,
  setShouldUpdateFilterInput,
  setShouldUpdateStartDate,
  setIsEditing,
  setIsSettingLoaded,
  setShouldActionLinkOutcome,
  setShouldOutcomeLinkAction,
  setDefaultWorkspaceId,
  setDefaultWorkspaceName,
  setStartDefaultWorkspaceId,
  setUnassignedWorkspaceId,
  resetUserSettings,
};
