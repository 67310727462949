import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isAfter, isBefore } from 'date-fns';
import { noop } from 'lodash-es';

import { ReactComponent as ArrowDownIcon } from 'core/svg/arrow-down.svg';

import { DatePicker } from '../DatePicker';
import { IconButton } from '../IconButton';

import { ReactComponent as ClearIcon } from './svg/clear.svg';
import { createInterval } from './utils';
import { DateRangePickerProps, Interval } from './types';

const StyledContainer = styled.div``;

const StyledDatePickerWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const StyledDateLabel = styled.span``;

const StyledStartDateLabel = styled(StyledDateLabel)`
  margin-right: 16px;
`;

const StyledEndDateLabel = styled(StyledDateLabel)`
  margin-right: 21px;
`;

const StyledDatePicker = styled(DatePicker)`
  && {
    margin: 0;
  }
`;

const DateRangePicker = ({
  startDate,
  endDate,
  onChange = noop,
  format = 'DD / MM / YYYY',
  dropdownIcon: DropdownIcon = ArrowDownIcon,
  startDateName,
  endDateName,
  startDateLabel = 'Start date',
  endDateLabel = 'End date',
  startDatePlaceholder = 'Select start date',
  endDatePlaceholder = 'Select end date',
  showClearButton = true,
  ...restOfDatePickerProps
}: DateRangePickerProps) => {
  const [dateRange, setDateRange] = useState<Interval>(createInterval(startDate, endDate));

  useEffect(() => {
    setDateRange(createInterval(startDate, endDate));
  }, [startDate, endDate]);

  const updateDateRange = (startDate?: Date | null, endDate?: Date | null) => {
    const newDateRange = createInterval(startDate, endDate);

    setDateRange(newDateRange);
    onChange(newDateRange);
  };

  return (
    <StyledContainer>
      <StyledDatePickerWrapper>
        <StyledStartDateLabel>{startDateLabel}</StyledStartDateLabel>
        <StyledDatePicker
          name={startDateName}
          disabledDate={(date) => !!dateRange.end && isAfter(date, dateRange.end)}
          value={dateRange.start}
          onChange={(date) => updateDateRange(date, dateRange.end)}
          format={format}
          suffixIcon={<DropdownIcon />}
          allowClear={false}
          placeholder={startDatePlaceholder}
          {...restOfDatePickerProps}
        />
        {dateRange.start && showClearButton && (
          <IconButton
            icon={ClearIcon}
            onClick={() => updateDateRange(undefined, dateRange.end)}
          />
        )}
      </StyledDatePickerWrapper>
      <StyledDatePickerWrapper>
        <StyledEndDateLabel>{endDateLabel}</StyledEndDateLabel>
        <StyledDatePicker
          name={endDateName}
          disabledDate={(date) => !!dateRange.start && isBefore(date, dateRange.start)}
          value={dateRange.end}
          onChange={(date) => updateDateRange(dateRange.start, date)}
          format={format}
          suffixIcon={<DropdownIcon />}
          allowClear={false}
          placeholder={endDatePlaceholder}
          {...restOfDatePickerProps}
        />
        {dateRange.end && showClearButton && (
          <IconButton
            icon={ClearIcon}
            onClick={() => updateDateRange(dateRange.start)}
          />
        )}
      </StyledDatePickerWrapper>
    </StyledContainer>
  );
};

export { DateRangePicker };
