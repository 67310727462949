import { gql } from '@apollo/client';

export const WORKSPACE_FIELDS = gql`
  fragment workspaceFields on Workspace {
    name
    description
    id
    permission
    isNewShared
  }
`;

export const WORKSPACE_USER_FIELDS = gql`
  fragment userFields on User {
    #    assigneeActions
    #    assigneeOutcomes
    createDate
    email
    #    externalAccount
    externalIdentity
    firstName
    id
    invitedUsersIds
    isActivated
    lastName
    name
    permission
    #    role
    updateDate
    #    workspaces
  }
`;

export const USER_INVITE_FIELDS = gql`
  fragment userInviteFields on FetchUserInvitesResult {
    createDate
    description
    id
    inviteFromUser {
      ...userFields
    }
    name
    permission
    updateDate
    usersCount
  }
  ${WORKSPACE_USER_FIELDS}
`;

export const WORKSPACE_USER_INVITE_FIELDS = gql`
  fragment workspaceUserInviteFields on AcceptInviteResult {
    email
    externalIdentity
    firstName
    id
    isAcceptedInvite
    isActivated
    lastName
    name
    permission
    workspaceId
    workspace {
      id
      name
      description
      permission
      usersCount
      actions {
        name
        id
        status
      }
      outcomes {
        id
        name
        status
      }
      notes {
        id
        name
      }
    }
  }
`;
