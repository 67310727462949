import React, { useState } from 'react';
import styled from 'styled-components';
import { Switch } from 'antd';
import { Button } from '../../../core/components/common';
import NewVideoEnabledIcon from '../svg/new-video-enabled.png';
import { ReactComponent as CloseIcon } from '../../../core/svg/close.svg';
import { theme } from '../../../core/styles/styled-components';

const StyledCustomModal = styled.div<{ isVisible: boolean }>`
  && {
    position: fixed;
    min-width: 344px;
    width: 414px;
    bottom: 20px;
    right: 20px;
    left: auto;
    top: auto;
    pointer-events: auto;
    background-color: transparent;

    border-radius: 12px;
    height: auto;
    transition: all ease-in-out 0.2s;

    transform: ${(props) => `${props.isVisible ? 'none' : 'translateY(150px)'}`};

    @media ${theme.device.tablet.max} {
      width: 344px;
    }
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 12px;
  overflow: hidden;
  background-color: var(--color-white);
`;
const StyledContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 22px 20px 25px 76px;

  @media ${theme.device.tablet.max} {
    padding: 22px 15px 25px 66px;
  }
`;
const StyledMainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 8px;
`;
const StyledImg = styled.div`
  position: absolute;
  left: 0;
  top: 15px;
  width: 55px;
  height: 72px;

  background-image: url(${NewVideoEnabledIcon});
`;
const StyledLabel = styled.div`
  padding: 4px;
  margin-bottom: 6px;
  background-color: #1697f4;
  border-radius: 4px;

  font-weight: 700;
  font-size: 9px;
  line-height: 8px;
  color: var(--color-white);
`;
const StyledTitle = styled.div`
  max-width: 180px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #1e1e1e;
`;
const StyledButton = styled(Button)`
  min-width: 110px;
  height: 32px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;

  &:first-child {
    margin-bottom: 4px;
  }

  &:last-child {
    background-color: transparent;
    border: 1px solid var(--color-dark-blue);
    color: var(--color-dark-blue);

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
`;
const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  background-color: #e9eff3;

  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: rgba(32, 38, 53, 0.7);
`;
const StyledRemindSwitcher = styled.div``;
const StyledSwitch = styled(Switch)<{ isChecked: boolean }>`
  && {
    margin: 0 6px 0 0;
    background-color: ${(props) =>
      `${props.isChecked ? 'var(--color-dark-blue)' : 'gba(32, 38, 53, 0.7)'}`};
  }
`;
const StyledLink = styled.div`
  cursor: pointer;
  padding: 4px;
`;
const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 6px;
  right: 6px;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;
const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface CourseVideoNotificationProps {
  visible: boolean;
  title?: string;
  onClose: () => void;
  onWatch: () => void;
  isMasterclass?: boolean;
}

const CourseVideoNotification = ({
  isMasterclass,
  visible,
  title,
  onWatch,
  onClose,
}: CourseVideoNotificationProps) => {
  const [shouldRemind, setShouldRemind] = useState<boolean>(false);

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <StyledCustomModal isVisible={visible}>
        <StyledWrapper>
          <StyledContent>
            <StyledCloseIcon onClick={handleClose} />
            <StyledMainContent>
              <StyledImg />
              <StyledLabel>Next lesson</StyledLabel>
              <StyledTitle>{title || 'Why Personal Effectiveness is Persona'}</StyledTitle>
            </StyledMainContent>
            <StyledButtonWrapper>
              <StyledButton onClick={() => onWatch()}>Watch Now</StyledButton>
              <StyledButton onClick={handleClose}>Watch Later</StyledButton>
            </StyledButtonWrapper>
          </StyledContent>
          {/*<StyledFooter>*/}
          {/*  <StyledRemindSwitcher>*/}
          {/*    <StyledSwitch*/}
          {/*      checked={shouldRemind}*/}
          {/*      size="small"*/}
          {/*      isChecked={shouldRemind}*/}
          {/*      onChange={() => {*/}
          {/*        setShouldRemind((old) => !old);*/}
          {/*      }}*/}
          {/*    />*/}
          {/*    Don't remind me daily*/}
          {/*  </StyledRemindSwitcher>*/}
          {/*  {isMasterclass && <StyledLink onClick={() => {}}>Skip masterclass</StyledLink>}*/}
          {/*</StyledFooter>*/}
        </StyledWrapper>
      </StyledCustomModal>
    </>
  );
};

export default CourseVideoNotification;
