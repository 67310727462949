import {
  CUSTOM_ENTITY_TYPES,
  CUSTOM_ERROR_MESSAGE,
  errorInitialValue,
  errorVar,
  ErrorWorkspace,
  OperationVariables,
} from './errorFields';

const resetError = () => {
  const value = {
    ...errorInitialValue,
  };
  errorVar(value);
};

const setErrorMessage = (payload: CUSTOM_ERROR_MESSAGE) => {
  const value = {
    ...errorVar(),
    errorMessage: payload,
  };
  errorVar(value);
};

const setErrorCode = (payload: number) => {
  const value = {
    ...errorVar(),
    errorCode: payload,
  };
  errorVar(value);
};

const setEntityType = (payload: CUSTOM_ENTITY_TYPES) => {
  const value = {
    ...errorVar(),
    entityType: payload,
  };
  errorVar(value);
};

const setErrorWorkspaces = (payload: ErrorWorkspace[]) => {
  const value = {
    ...errorVar(),
    errorWorkspaces: payload,
  };
  errorVar(value);
};

const setErrorVariables = (payload: OperationVariables | undefined) => {
  const value = {
    ...errorVar(),
    errorVariables: payload,
  };
  errorVar(value);
};

export {
  setErrorMessage,
  setErrorCode,
  setEntityType,
  setErrorWorkspaces,
  setErrorVariables,
  resetError,
};
