import { ExtendedWorkSpace } from './components/ManageWorkSpacesModal/types';
import { WORKSPACE_TYPE_UNSELECTED } from '../../core/constants/others';
import { WorkSpaceMenuOptions } from './components/WorkspaceItem/types';
import { ContextMenuOptions } from './components/HeaderTagFilter/constants';

export const getMenuOptionsForWorkspaceItem = (
  workspace: ExtendedWorkSpace,
  viewerPermission: boolean,
  editorPermission: boolean,
) => {
  let options: { label: string }[] = [];
  const { isDefault, type } = workspace;

  if (type === WORKSPACE_TYPE_UNSELECTED) {
    options.push(WorkSpaceMenuOptions.ADD_WORKSPACE);
  } else {
    options.push(WorkSpaceMenuOptions.REMOVE_WORKSPACE);
  }

  if (viewerPermission) {
    options.push(WorkSpaceMenuOptions.LEAVE);
    return options;
  }

  if (!isDefault) {
    options.push(WorkSpaceMenuOptions.SET_AS_DEFAULT_WORKSPACE);
  }

  if (!editorPermission) {
    options.push(WorkSpaceMenuOptions.EDIT_WORKSPACE);
  }

  options.push(WorkSpaceMenuOptions.SHARE);
  return options;
};

export const getMenuOptionsForTagFilter = (
  isDefault: boolean,
  viewerPermission: boolean,
  editorPermission: boolean,
) => {
  let options: { label: string }[] = [];

  options.push(ContextMenuOptions.REMOVE_FROM_BOARD);
  if (viewerPermission) {
    options.push(ContextMenuOptions.LEAVE);
    return options;
  }

  if (!editorPermission) {
    options.push(ContextMenuOptions.EDIT);
  }

  if (!isDefault) {
    options.push(ContextMenuOptions.SET_DEFAULT);
  }

  options.push(ContextMenuOptions.SHARE);
  return options;
};
