import { authMutations } from './auth';
import { filterMutation } from './filterInput';
import { userSettingsMutation } from './userSettings';
import { boardFiltersMutation } from './boardFilters';
import { completeMutation } from './complete';
import { coursesMutation } from './courses';
import { manageWorkspacesMutation } from './manageWorkspaces';
import { tutorialMutation } from './tutorial';
import { utilsMutation } from './utils';
import { recommendMutation } from './recommend';
import { stripeMutation } from './stripe';
import { boardEditedEntityMutation } from './boardEditedEntity';
import { errorMutation } from './error';
import { searchBarMutation } from './searchBar';

const useClearApolloFields = () => {
  const { resetAuth } = authMutations;
  const { resetComplete } = completeMutation;
  const { resetStripe } = stripeMutation;
  const { resetManageWorkspace } = manageWorkspacesMutation;
  const { resetTutorial } = tutorialMutation;
  const { resetUserSettings } = userSettingsMutation;
  const { resetBoardFilters } = boardFiltersMutation;
  const { resetCourses } = coursesMutation;
  const { resetUtils } = utilsMutation;
  const { resetFilterInput } = filterMutation;
  const { resetRecommend } = recommendMutation;
  const { resetEditedEntity } = boardEditedEntityMutation;
  const { resetError } = errorMutation;
  const { resetSearchBar } = searchBarMutation;

  const clearCache = () => {
    resetAuth();
    resetEditedEntity();
    resetBoardFilters();
    resetComplete();
    resetCourses();
    resetError();
    resetFilterInput();
    resetManageWorkspace();
    resetRecommend();
    resetSearchBar();
    resetStripe();
    resetTutorial();
    resetUserSettings();
    resetUtils();
  };

  return { clearCache };
};

export default useClearApolloFields;
