import React, { memo, useEffect, useState } from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { stripeMutation } from '../../../apollo/stateFields/stripe';
import { useMediaQuery } from 'react-responsive';
import theme from '../../../core/styles/styled-components/theme';

const StyledCardDetailsContainer = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
`;

const StyledInnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const StyledCardNumberElement = styled.label<{ isError: boolean }>`
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 10px;

  div.StripeElement {
    border: ${({ isError }) =>
      isError ? '1px solid var(--color-main-error-red)' : '1px solid var(--color-main-light-grey)'};
    border-radius: 4px;
    padding: 12px;
    margin-top: 4px;
  }
`;

const StyledCardExpirationElement = styled.label<{ isError: boolean }>`
  position: relative;
  width: 48%;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 10px;

  div.StripeElement {
    border: ${({ isError }) =>
      isError ? '1px solid var(--color-main-error-red)' : '1px solid var(--color-main-light-grey)'};
    border-radius: 4px;
    padding: 12px;
    margin-top: 4px;
  }
`;

const StyledCardCvcElement = styled.label<{ isError: boolean }>`
  position: relative;
  width: 48%;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 10px;

  div.StripeElement {
    border: ${({ isError }) =>
      isError ? '1px solid var(--color-main-error-red)' : '1px solid var(--color-main-light-grey)'};
    border-radius: 4px;
    padding: 12px;
    margin-top: 4px;
  }
`;

const StyledErrorInfo = styled.div`
  position: absolute;
  bottom: -8px;
  left: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: var(--color-main-error-red);
`;

const styleOptions = {
  style: {
    base: {
      fontSize: '14px',
      color: '#202635',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#8E929A',
      },
    },
    invalid: {
      color: '#202635',
    },
  },
};

const styleOptionsForMobile = {
  style: {
    base: {
      fontSize: '16px',
      color: '#202635',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#8E929A',
      },
    },
    invalid: {
      color: '#202635',
    },
  },
};

const StripeDetails = () => {
  const { setIsCardDataFulfilled } = stripeMutation;
  const [isNumberComplete, setIsNumberComplete] = useState<boolean>(false);
  const [isNumberError, setIsNumberError] = useState<boolean>(false);
  const [isDateComplete, setIsDateComplete] = useState<boolean>(false);
  const [isDateError, setIsDateError] = useState<boolean>(false);
  const [isCvcComplete, setIsCvcComplete] = useState<boolean>(false);
  const [isCvcError, setIsCvcError] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: theme.device.mobile.max });

  const options = isMobile ? styleOptionsForMobile : styleOptions;

  useEffect(() => {
    if (isNumberComplete && isDateComplete && isCvcComplete) {
      setIsCardDataFulfilled(true);
    } else {
      setIsCardDataFulfilled(false);
    }
  }, [isNumberComplete, isDateComplete, isCvcComplete]);

  return (
    <StyledCardDetailsContainer>
      <StyledCardNumberElement isError={!isNumberComplete && isNumberError}>
        Card Number
        <CardNumberElement
          options={options}
          onChange={(event) => {
            setIsNumberError(false);
            if (event.complete) {
              setIsNumberComplete(true);
            } else {
              isNumberComplete && setIsNumberComplete(false);
            }
          }}
          onBlur={() => {
            !isNumberComplete && setIsNumberError(true);
          }}
        />
        {!isNumberComplete && isNumberError && <StyledErrorInfo>Invalid card number</StyledErrorInfo>}
      </StyledCardNumberElement>
      <StyledInnerWrapper>
        <StyledCardExpirationElement isError={isDateError}>
          Expiration
          <CardExpiryElement
            options={options}
            onChange={(event) => {
              setIsDateError(false);
              if (event.complete) {
                setIsDateComplete(true);
              } else {
                isDateComplete && setIsDateComplete(false);
              }
            }}
            onBlur={() => {
              !isDateComplete && setIsDateError(true);
            }}
          />
          {isDateError && <StyledErrorInfo>Invalid card date</StyledErrorInfo>}
        </StyledCardExpirationElement>
        <StyledCardCvcElement isError={isCvcError}>
          Card Verification Code
          <CardCvcElement
            options={options}
            onChange={(event) => {
              setIsCvcError(false);
              if (event.complete) {
                setIsCvcComplete(true);
              } else {
                isCvcComplete && setIsCvcComplete(false);
              }
            }}
            onBlur={() => {
              !isCvcComplete && setIsCvcError(true);
            }}
          />
          {isCvcError && <StyledErrorInfo>Invalid CVC</StyledErrorInfo>}
        </StyledCardCvcElement>
      </StyledInnerWrapper>
    </StyledCardDetailsContainer>
  );
};

export default memo(StripeDetails);
