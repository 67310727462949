import React from 'react';
import { Select } from 'formik-antd';
import { Select as AntSelect } from 'antd';
import { SelectProps } from 'formik-antd/src/select/index';
import styled from 'styled-components';

import { ArrowDownIcon } from '../../../icons';
import { StatusSelectProps } from './types';

const { Option } = Select;
const { Option: AntOption } = AntSelect;

const StyledSelect = styled(Select).attrs<SelectProps>({
  suffixIcon: <ArrowDownIcon />,
})<{ small?: boolean; disabled?: boolean }>`
  && {
    display: inline-block;
    width: auto;

    .ant-select-arrow svg path {
      fill: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.25)' : 'var(--color-dark-blue)')};
    }

    &:not(.ant-select-customize-input) .ant-select-selector {
      height: ${({ small }) => (small ? '20px' : '32px')};
      background: rgba(32, 38, 53, 0.07);
      border-radius: 16px;
    }

    &.ant-select-single {
      .ant-select-selector .ant-select-selection-item,
      .ant-select-selector .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
        line-height: ${({ small }) => (small ? '20px' : '32px')};
        font-size: ${({ small }) => (small ? '12px' : '14px')};
        padding-right: 10px;
      }

      &:not(.ant-select-customize-input) .ant-select-selector {
        padding-right: ${({ small }) => (small ? '16px' : '32px')};
        padding-left: ${({ small }) => (small ? '4px' : '16px')};
      }
    }

    .ant-select-arrow {
      right: ${({ small }) => (small ? '7px' : '21px')};
    }
  }
`;

const StyledAntSelect = styled(AntSelect).attrs<SelectProps>({
  suffixIcon: <ArrowDownIcon />,
})<{ small?: boolean; disabled?: boolean }>`
  && {
    display: inline-block;
    width: auto;
    min-width: 95px;
    font-size: 12px;

    .ant-select-arrow svg path {
      fill: ${(props) => (props.disabled ? 'rgba(0, 0, 0, 0.25)' : 'var(--color-dark-blue)')};
    }

    &:not(.ant-select-customize-input) .ant-select-selector {
      height: ${({ small }) => (small ? '20px' : '32px')};
      background: rgba(32, 38, 53, 0.07);
      border-radius: 16px;
    }

    &.ant-select-single {
      .ant-select-selector .ant-select-selection-item,
      .ant-select-selector .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
        line-height: ${({ small }) => (small ? '20px' : '32px')};
        font-size: ${({ small }) => (small ? '12px' : '14px')};
        padding-right: 10px;
      }

      &:not(.ant-select-customize-input) .ant-select-selector {
        padding-right: ${({ small }) => (small ? '16px' : '32px')};
        padding-left: ${({ small }) => (small ? '4px' : '16px')};
      }
    }

    &.ant-select {
      margin-bottom: 0;
    }

    .ant-select-arrow {
      right: ${({ small }) => (small ? '7px' : '21px')};
    }
  }
`;

const StyledIcon = styled.div`
  flex: none;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;

  svg {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
`;

const StatusSelect = ({ name, options, withoutFormik, ...props }: StatusSelectProps) => {
  return (
    <>
      {withoutFormik ? (
        <StyledAntSelect {...props}>
          {options.map((option) => (
            <AntOption value={option.value} key={option.value}>
              <StyledIcon>{option.icon}</StyledIcon>
              {option.label}
            </AntOption>
          ))}
        </StyledAntSelect>
      ) : (
        <StyledSelect name={name} {...props}>
          {options.map((option) => (
            <Option value={option.value} key={option.value}>
              <StyledIcon>{option.icon}</StyledIcon>
              {option.label}
            </Option>
          ))}
        </StyledSelect>
      )}
    </>
  );
};

export { StatusSelect };
