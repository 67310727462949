import React, { forwardRef, Ref } from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { Input } from 'antd';

import { Label } from 'core/components/common';

import { TextAreaFieldProps } from './types';
import { TextAreaRef } from 'antd/es/input/TextArea';

const { TextArea } = Input;

const StyledContainer = styled.div``;

const StyledLabelAndTextAreaContainer = styled.div<Pick<TextAreaFieldProps, 'labelPosition'>>`
  display: flex;
  flex-direction: ${({ labelPosition }) => (labelPosition === 'above' ? 'column' : 'row')};
  width: 100%;
`;

const StyledTextArea = styled(TextArea)`
  && {
    margin: 0;
  }
`;

const StyledError = styled.span``;

const StyledLabel = styled(Label)`
  margin-bottom: 8px;
`;

// TODO: Check if abstracting this functionality into a HOC is a good idea.
const TextAreaField = forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
  (
    {
      className,
      textAreaClassName,
      errorClassName,
      name,
      displayError = false,
      id,
      label,
      labelPosition = 'above',
      labelClassName,
      maxLength,
      autoFocus,
      disabled,
      ...restOfProps
    },
    ref,
  ) => {
    const [field, meta] = useField(name);
    return (
      <StyledContainer className={className}>
        <StyledLabelAndTextAreaContainer labelPosition={labelPosition}>
          {label && <StyledLabel className={labelClassName} htmlFor={id} text={label} />}
          <StyledTextArea
            disabled={disabled}
            ref={ref as Ref<TextAreaRef>}
            className={textAreaClassName}
            id={id}
            {...field}
            autoFocus={autoFocus}
            onChange={(e) => {
              if (maxLength) {
                if (e.target.value.length <= maxLength) {
                  field.onChange(e);
                } else {
                  e.target.value = e.target.value.slice(0, maxLength);
                  field.onChange(e);
                }
              } else {
                field.onChange(e);
              }
            }}
            {...restOfProps}
          />
        </StyledLabelAndTextAreaContainer>
        {displayError && meta.error && meta.touched && (
          <StyledError className={errorClassName}>{meta.error}</StyledError>
        )}
      </StyledContainer>
    );
  },
);

export { TextAreaField };
