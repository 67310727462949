import React, { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Radio, Space, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { ACTION_PATH } from '../../../core/constants/routePaths';
import { ReactComponent as ElipsIcon } from '../../img/elips.svg';
import { ReactComponent as TriangleIcon } from '../../img/triangle.svg';
import { ReactComponent as BothIcon } from '../../img/both.svg';
import { UserActivities } from '../types';
import { IHeaderHistory } from '../../../core/components/layout/Header/types';
import SelectDefaultSpace from '../SelectDefaultSpace/SelectDefaultSpace';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_USER_WORKSPACES,
  UPDATE_USER_WORKSPACE,
} from '../../../features/workspaces/graphql/mutations';
import { CreateUserWorkspaces, UpdateUserWorkspace } from '../../../features/workspaces/types';
import { UpdateUserPreferences, UpdateUserSettings } from '../../../graphql/types';
import {
  UPDATE_SETTINGS,
  UPDATE_USER_PREFERENCES_AND_QUESTIONNAIRE,
} from '../../../graphql/mutations';
import { theme } from '../../../core/styles/styled-components';
import { replaceBackSlash } from '../../../core/utils/global';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../apollo/stateFields/filterInput/filterInputFields';
import { filterMutation } from '../../../apollo/stateFields/filterInput';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../apollo/stateFields/userSettings/userSettingsField';
import { userSettingsMutation } from '../../../apollo/stateFields/userSettings';
import { TUTORIAL_STATE, TutorialValue } from '../../../apollo/stateFields/tutorial/tutorialField';
import { tutorialMutation } from '../../../apollo/stateFields/tutorial';
import Questionnaire from '../Questionnaire/Questionnaire';
import ReadyContent from '../ReadyContent/ReadyContent';
import ExpectContent from '../ExpectContent/ExpectContent';

const StyledTitle = styled(Typography.Text)<{ step: number }>`
  display: block;
  color: var(--color-dark-blue);
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 20px;
  width: 100%;

  ${({ step }) =>
    step === 1 &&
    css`
      margin-bottom: 0;
    `}

  @media ${theme.device.tablet.min} {
    ${({ step }) =>
      step === 2 &&
      css`
        width: 50%;
      `}
  }

  @media (min-width: 1440px) {
    ${({ step }) =>
      step === 2 &&
      css`
        width: 100%;
      `}
  }
`;

const StyledActivityTitle = styled(Typography.Text)`
  display: block;
  color: var(--color-black);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
`;

const StyledSubTitle = styled(Typography.Text)<{ step: number }>`
  display: block;
  color: var(--color-black);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 40px;
  width: 100%;

  ${({ step }) =>
    step === 1 &&
    css`
      margin-bottom: 0;
    `}

  @media ${theme.device.tablet.min} {
    ${({ step }) =>
      step === 2 &&
      css`
        width: 50%;
      `}
  }

  @media (min-width: 1440px) {
    ${({ step }) =>
      step === 2 &&
      css`
        width: 100%;
      `}
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;

  @media ${theme.device.mobile.max} {
    flex-direction: column;
  }
`;

const StyledButtonsWrapper2 = styled(StyledButtonsWrapper)`
  flex-direction: row;
  justify-content: center;
  margin-top: 0;

  @media ${theme.device.mobile.max} {
    flex-direction: row;
  }
`;

const StyledButtonLink = styled(Button)`
  opacity: 0.4;
  font-size: 12px;
  padding: 0 5px;
  color: var(--color-dark-blue);
  border: none;

  span {
    text-decoration: underline;
  }

  @media ${theme.device.mobile.max} {
    margin-top: 20px;
  }
`;

const StyledButtonLink2 = styled(StyledButtonLink)`
  margin-top: 30px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  height: 48px;
  width: 200px;
  border: none;
  background-color: var(--color-dark-blue);
  color: var(--color-white);

  &:hover,
  &:focus {
    background-color: var(--color-dark-blue);
    color: var(--color-white);
  }

  @media ${theme.device.mobile.max} {
    width: 100%;
  }
`;

const StyledButtonBack = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  height: 48px;
  width: 200px;
  background-color: transparent;
  color: var(--color-dark-blue);
  font-weight: 400;

  @media ${theme.device.mobile.max} {
    width: 100%;
    margin-top: 20px;
  }
`;

const StyledRadioButtonWrapper = styled.div<{ isChecked?: boolean }>`
  width: 100%;
  height: 72px;
  padding: 0 45px 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: rgba(32, 38, 53, 0.01);
  border: 1px solid rgba(32, 38, 53, 0.1);
  border-radius: 16px;
  cursor: pointer;

  span.ant-radio + * {
    font-size: 14px;
    line-height: 16px;
    margin-top: 3px;
    margin-left: 24px;

    ${({ isChecked }) =>
      isChecked &&
      css`
        font-weight: 700;
      `}
  }

  ${({ isChecked }) =>
    isChecked &&
    css`
      background: #ffffff;
      box-shadow: 9px 8px 53px #eae3e3;
    `}

  @media ${theme.device.tablet.min} {
    height: 88px;
    span.ant-radio + * {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

const StyledRadioButton = styled(Radio)`
  display: flex;
  align-items: center;

  span.ant-radio-inner {
    width: 32px;
    height: 32px;

    &:after {
      width: 16px;
      height: 16px;
      top: 7px;
      left: 7px;
    }
  }
`;

const StyledActivity = styled.div``;

const OnboardingActivities = ({ className }: { className?: string }) => {
  const { data: tutorialData } = useQuery(TUTORIAL_STATE);
  const { onboardingStep, onboardingSpaces, onboardingQuestionnaire }: TutorialValue =
    tutorialData?.tutorial;
  const { setOnboardingSpaces, setOnboardingStep } = tutorialMutation;
  const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
  const { defaultWorkspaceId, startDefaultWorkspaceId }: UserSettingsValue =
    userSettingsData?.userSettingsField;
  const { setDefaultWorkspaceId } = userSettingsMutation;
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const { setFilterInput } = filterMutation;
  const history = useHistory<IHeaderHistory>();
  // const [radioVariant, setRadioVariant] = useState<UserActivities>(UserActivities.BOTH);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [defaultSpace, setDefaultSpace] = useState<string>(
    onboardingSpaces.roles[0].defaultSpaceName,
  );
  const [createWorkspaces] = useMutation<CreateUserWorkspaces>(CREATE_USER_WORKSPACES);
  const [updateUserWorkspace] = useMutation<UpdateUserWorkspace>(UPDATE_USER_WORKSPACE);
  const [updateSettings] = useMutation<UpdateUserSettings>(UPDATE_SETTINGS);
  const [updatePreferences] = useMutation<UpdateUserPreferences>(
    UPDATE_USER_PREFERENCES_AND_QUESTIONNAIRE,
  );
  const workspacesList = useMemo(() => {
    return onboardingSpaces.roles;
  }, [onboardingSpaces]);

  const handleChooseActivity = useCallback((value: UserActivities) => {
    switch (value) {
      case UserActivities.WORK:
        setOnboardingSpaces({
          title: value,
          roles: [
            { defaultSpaceName: 'Work', spaceName: 'Work' },
            { defaultSpaceName: 'Self', spaceName: 'Self' },
          ],
        });
        setDefaultSpace('Work');
        return;
      case UserActivities.NON_WORK:
        setOnboardingSpaces({
          title: value,
          roles: [
            { defaultSpaceName: 'Non-Work', spaceName: 'Non-Work' },
            { defaultSpaceName: 'Self', spaceName: 'Self' },
          ],
        });
        setDefaultSpace('Non-Work');
        return;
      case UserActivities.BOTH:
        setOnboardingSpaces({
          title: UserActivities.BOTH,
          roles: [
            { defaultSpaceName: 'Self', spaceName: 'Self' },
            { defaultSpaceName: 'Work', spaceName: 'Work' },
            { defaultSpaceName: 'Non-Work', spaceName: 'Non-Work' },
          ],
        });
        setDefaultSpace('Self');
        return;
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    switch (onboardingStep) {
      case 1:
        handleChooseActivity(UserActivities.BOTH);
        startDefaultWorkspaceId !== -1 &&
          setFilterInput([{ workspaceId: startDefaultWorkspaceId, tagIds: [] }]);
        setOnboardingStep(2);
        return;
      case 2:
        setOnboardingStep(3);
        return;
      // case 3:
      //   setOnboardingStep(4);
      //   return;
      // case 4:
      //   setOnboardingStep(5);
      //   return;
      case 3:
        setIsLoading(true);
        const workspaces = workspacesList
          .filter((selectedSpace) => selectedSpace.defaultSpaceName !== 'Self')
          .map((selectedSpace) => ({ name: replaceBackSlash(selectedSpace.spaceName) }));
        const selfSpace = onboardingSpaces.roles.find((space) => space.defaultSpaceName === 'Self');
        if (selfSpace && selfSpace?.spaceName !== 'Self') {
          await updateUserWorkspace({
            variables: {
              workspace: { id: defaultWorkspaceId, name: replaceBackSlash(selfSpace.spaceName) },
            },
          });
        }
        const { data } = await createWorkspaces({
          variables: {
            workspaces,
          },
        });

        let ids = data?.createUserWorkspaces.map((w) => w.id) || [];
        ids.push(startDefaultWorkspaceId);

        if (defaultSpace !== 'Self') {
          const newDefaultWorkspaceId = data?.createUserWorkspaces.find(
            (createdWorkspace) =>
              createdWorkspace.name.toLowerCase() ===
              onboardingSpaces.roles
                .find((role) => role.defaultSpaceName.toLowerCase() === defaultSpace.toLowerCase())
                ?.spaceName.toLowerCase(),
          )?.id;

          if (newDefaultWorkspaceId) {
            const settings = {
              filterInput: ids.map((id) => ({
                workspaceId: id,
                tagIds: [],
              })),
              defaultWorkspaceId: newDefaultWorkspaceId,
            };
            updateSettings({
              variables: {
                settings,
              },
            }).then(() => {
              newDefaultWorkspaceId && setDefaultWorkspaceId(newDefaultWorkspaceId);
            });
          }
        }
        setFilterInput(
          ids
            .map((id) => ({
              workspaceId: id,
              tagIds: [],
            }))
            .filter((f) => f.workspaceId !== -1),
        );
        await updatePreferences({
          variables: {
            settings: {
              filterInput: ids.map((id) => ({
                workspaceId: id,
                tagIds: [],
              })),
              onboardingQuestionnaire: onboardingQuestionnaire.map((q) => ({
                question: q.question,
                answer: q.answer,
              })),
              userPreferences: [
                {
                  title: onboardingSpaces.title,
                  roles: onboardingSpaces.roles.map((role) => role.spaceName),
                },
              ],
            },
          },
        });

        await setTimeout(() => {}, 900);

        history.push(ACTION_PATH);
        return;
    }
  }, [
    onboardingSpaces,
    startDefaultWorkspaceId,
    onboardingStep,
    defaultSpace,
    filterInput,
    onboardingQuestionnaire,
    updateSettings,
    createWorkspaces,
  ]);
  const handleBack = useCallback(async () => {
    setOnboardingStep(onboardingStep - 1);
  }, [onboardingStep]);
  const handleSkip = useCallback(async () => {
    // if (onboardingStep === 1) {
    //   await updatePreferences({
    //     variables: {
    //       settings: {
    //         userPreferences: [
    //           {
    //             title: null,
    //             roles: onboardingSpaces.roles.map((role) => role.spaceName),
    //           },
    //         ],
    //       },
    //     },
    //   });
    // } else
    if (onboardingStep === 1) {
      await updatePreferences({
        variables: {
          settings: {
            userPreferences: [
              {
                title: onboardingSpaces.title,
                roles: onboardingSpaces.roles.map((role) => role.spaceName),
              },
            ],
          },
        },
      });
    }
    history.push(ACTION_PATH);
    return;
  }, [onboardingSpaces, onboardingStep]);

  const getTitle = useCallback(() => {
    switch (onboardingStep) {
      // case 1:
      //   return `What do you want to use BillionMinds for?`;
      case 1:
        return `Personalizing Your Experience`;
      case 2:
        return `What to Expect`;
      case 3:
        return `Are you ready?`;
      // case 4:
      //   return `BillionMinds: Configured`;
      default:
        return `Personalizing Your Experience`;
    }
  }, [onboardingStep]);
  const getSubTitle = useCallback(() => {
    switch (onboardingStep) {
      // case 1:
      //   return `Don't worry, your choice here won't affect how you can use BillionMinds in the future`;
      case 1:
        return null;
      case 2:
        return 'More than just a tool, our platform embeds skills you can use to thrive wherever and whenever you work';
      case 3:
        return 'Just a few of our 10 minute Learn/Do experiences will begin to transform how you approach work each day!';
      // case 4:
      //   if (onboardingSpaces.title === UserActivities.BOTH) {
      //     return (
      //       <span>
      //         <span>
      //           You've told us that you want to use BillionMinds to track your work and non-work
      //           activities - that's great! We've created spaces called "Work" and "Non-Work" for
      //           that. Please feel free to rename them below if you prefer, for example you might
      //           want to rename your Work space after your job role or the company you work for.
      //         </span>
      //         <br />
      //         <br />
      //         <span>
      //           We've also created an extra space called "Self" where you can put all your personal
      //           stuff, such as self-improvement activities and goals.
      //         </span>
      //         <br />
      //         <br />
      //         <span>
      //           We cannot be sure where you will spend most of your time, so we've made the Self
      //           Space default for now. You can change the default here if you want, or just get
      //           straight into the tool.
      //         </span>
      //       </span>
      //     );
      //   } else if (onboardingSpaces.title === UserActivities.WORK) {
      //     return (
      //       <span>
      //         <span>
      //           You've told us that you want to use BillionMinds to track your work activities -
      //           that's great! We've created a space called "Work" for that. Please feel free to
      //           rename it below if you prefer, for example you might want to rename it after your
      //           job role or the company you work for.
      //         </span>
      //         <br />
      //         <br />
      //         <span>
      //           We've also created an extra space called "Self" where you can put all your personal
      //           stuff, such as self-improvement activities and goals.
      //         </span>
      //         <br />
      //         <br />
      //         <span>
      //           We cannot be sure where you will spend most of your time, so we've made the Work
      //           Space default for now. You can change the default here if you want, or just get into
      //           the tool.
      //         </span>
      //       </span>
      //     );
      //   } else if (onboardingSpaces.title === UserActivities.NON_WORK) {
      //     return (
      //       <span>
      //         <span>
      //           You've told us that you want to use BillionMinds to track your non-work activities -
      //           that's great! We've created a space called "Non-Work" for that. Please feel free to
      //           rename it below if you prefer, for example you might want to rename it "Home" or
      //           "Life".
      //         </span>
      //         <br />
      //         <br />
      //         <span>
      //           We've also created an extra space called "Self" where you can put all your personal
      //           stuff, such as self-improvement activities and goals.
      //         </span>
      //         <br />
      //         <br />
      //         <span>
      //           We cannot be sure where you will spend most of your time, so we've made the
      //           "Non-Work" Space default for now. You can change the default here if you want, or
      //           just get into the tool.
      //         </span>
      //       </span>
      //     );
      //   }
      //   return;
      default:
        return null;
    }
  }, [onboardingStep]);

  return (
    <div className={className}>
      <StyledTitle step={onboardingStep}>{getTitle()}</StyledTitle>
      <StyledSubTitle step={onboardingStep}>{getSubTitle()}</StyledSubTitle>

      <StyledActivity>
        {/*{onboardingStep === 1 && (*/}
        {/*  <>*/}
        {/*    <StyledActivityTitle>I would like to use BillionMinds for:</StyledActivityTitle>*/}
        {/*    <Radio.Group*/}
        {/*      onChange={(e) => {*/}
        {/*        setRadioVariant(e.target.value);*/}
        {/*      }}*/}
        {/*      value={radioVariant}*/}
        {/*      style={{ width: '100%', margin: '16px 0' }}*/}
        {/*    >*/}
        {/*      <Space direction="vertical" style={{ width: '100%' }}>*/}
        {/*        <StyledRadioButtonWrapper*/}
        {/*          isChecked={radioVariant === UserActivities.WORK}*/}
        {/*          onClick={() => setRadioVariant(UserActivities.WORK)}*/}
        {/*        >*/}
        {/*          <StyledRadioButton value={UserActivities.WORK}>Just Work Stuff</StyledRadioButton>*/}
        {/*          <ElipsIcon />*/}
        {/*        </StyledRadioButtonWrapper>*/}
        {/*        <StyledRadioButtonWrapper*/}
        {/*          isChecked={radioVariant === UserActivities.NON_WORK}*/}
        {/*          onClick={() => setRadioVariant(UserActivities.NON_WORK)}*/}
        {/*        >*/}
        {/*          <StyledRadioButton value={UserActivities.NON_WORK}>*/}
        {/*            Just Non-Work Stuff*/}
        {/*          </StyledRadioButton>*/}
        {/*          <TriangleIcon />*/}
        {/*        </StyledRadioButtonWrapper>*/}
        {/*        <StyledRadioButtonWrapper*/}
        {/*          isChecked={radioVariant === UserActivities.BOTH}*/}
        {/*          onClick={() => setRadioVariant(UserActivities.BOTH)}*/}
        {/*        >*/}
        {/*          <StyledRadioButton value={UserActivities.BOTH}>*/}
        {/*            Both (Recommended)*/}
        {/*          </StyledRadioButton>*/}
        {/*          <BothIcon />*/}
        {/*        </StyledRadioButtonWrapper>*/}
        {/*      </Space>*/}
        {/*    </Radio.Group>*/}
        {/*  </>*/}
        {/*)}*/}

        {onboardingStep === 1 && <Questionnaire />}
        {onboardingStep === 2 && <ExpectContent />}
        {onboardingStep === 3 && <ReadyContent />}
        {/*{onboardingStep === 4 && (*/}
        {/*  <SelectDefaultSpace*/}
        {/*    defaultSpace={defaultSpace}*/}
        {/*    setDefault={(value: string) => setDefaultSpace(value)}*/}
        {/*  />*/}
        {/*)}*/}
      </StyledActivity>

      <StyledButtonsWrapper>
        <StyledButton
          shape="round"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={
            onboardingStep === 1
              ? onboardingQuestionnaire.filter((question) => !!question?.answer)?.length < 2
              : false
          }
        >
          {onboardingStep === 3 ? 'Take me to the tool!' : 'Continue'}
        </StyledButton>
        {onboardingStep === 1 ? (
          <StyledButtonLink onClick={handleSkip}>Skip Configuration Steps</StyledButtonLink>
        ) : (
          <StyledButtonBack shape="round" type={'default'} onClick={handleBack}>
            Back
          </StyledButtonBack>
        )}
      </StyledButtonsWrapper>
      {/*<StyledButtonsWrapper2>*/}
      {/*  {onboardingStep === 1 && (*/}
      {/*    <StyledButtonLink2 onClick={handleSkip}>Skip Configuration Steps</StyledButtonLink2>*/}
      {/*  )}*/}
      {/*</StyledButtonsWrapper2>*/}
    </div>
  );
};

export default OnboardingActivities;
