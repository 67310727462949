import { gql } from '@apollo/client';

export const DND_COLUMN_FIELDS = gql`
  fragment dndColumnFields on BoardColumn {
    id
    title
    entityIds
  }
`;

export const COURSES_CONSTS_FIELDS = gql`
  fragment coursesConstsFields on CoursesConstsArgs {
    title
    certificateUrl
    isCourseAvailable
    videos {
      duration
      id
      isAvailable
      name
      section
      url
    }
  }
`;
export const COURSES_VARS_FIELDS = gql`
  fragment coursesVarsFields on CoursesVarsArgs {
    dateLastWatched
    lastWatchedId
    watchedVideoIds
  }
`;

export const COURSES_VAR = gql`
  fragment coursesVarFields on CoursesVars {
    Foundations {
      ...coursesVarsFields
    }
    MasteringPersonalEffectiveness {
      ...coursesVarsFields
    }
    Fundamentals {
      ...coursesVarsFields
    }
    RemoteWorkProfessional {
      ...coursesVarsFields
    }
    FundamentalsManager {
      ...coursesVarsFields
    }
    RemoteWorkProfessionalManager {
      ...coursesVarsFields
    }
  }
  ${COURSES_VARS_FIELDS}
`;

export const COURSES_CONST = gql`
  fragment coursesConstFields on CoursesConsts {
    Foundations {
      ...coursesConstsFields
    }
    MasteringPersonalEffectiveness {
      ...coursesConstsFields
    }
    Fundamentals {
      ...coursesConstsFields
    }
    RemoteWorkProfessional {
      ...coursesConstsFields
    }
    FundamentalsManager {
      ...coursesConstsFields
    }
    RemoteWorkProfessionalManager {
      ...coursesConstsFields
    }
  }
  ${COURSES_CONSTS_FIELDS}
`;
