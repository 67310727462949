import { gql } from '@apollo/client';

import { ACTION_FIELDS, FOCUS_TIMER_FIELDS, NOTE_FIELDS } from './fragments';

//Actions
export const FETCH_ACTIONS = gql`
  query FetchActions($workspaceId: Int = -1) {
    fetchUserWorkspaceActions(workspaceId: $workspaceId) {
      ...actionFields
    }
  }

  ${ACTION_FIELDS}
`;

export const FETCH_ACTION = gql`
  query FetchAction($actionId: Int!, $workspaceId: Int!) {
    fetchUserWorkspaceAction(actionId: $actionId, workspaceId: $workspaceId) {
      ...actionFields
    }
  }

  ${ACTION_FIELDS}
`;

export const FIX_ACTION = gql`
  query FixAction($actionId: Int!, $workspaceId: Int!) {
    fixUserWorkspaceActionWithMultipleOutcomes(actionId: $actionId, workspaceId: $workspaceId) {
      ...actionFields
    }
  }

  ${ACTION_FIELDS}
`;

//Notes
export const FETCH_ALL_NOTES = gql`
  query FetchAllNotes {
    fetchUserWorkspacesNotes {
      ...noteFields
    }
  }
  ${NOTE_FIELDS}
`;

export const FETCH_NOTES = gql`
  query FetchNotes($workspaceId: Int = -1) {
    fetchUserWorkspaceNotes(workspaceId: $workspaceId) {
      ...noteFields
    }
  }
  ${NOTE_FIELDS}
`;

export const FETCH_NOTE = gql`
  query FetchUserWorkspaceNote($noteId: Int!, $workspaceId: Int!) {
    fetchUserWorkspaceNote(noteId: $noteId, workspaceId: $workspaceId) {
      ...noteFields
    }
  }
  ${NOTE_FIELDS}
`;

export const FETCH_FOCUS_TIMER = gql`
  query FetchFocusTimer {
    fetchFocusTimer {
      ...focusTimerFields
    }
  }
  ${FOCUS_TIMER_FIELDS}
`;
