import { StripeError } from '@stripe/stripe-js';

export const getErrorMessage = (error: StripeError | undefined) => {
  if (error) {
    switch (error.decline_code) {
      case 'authentication_required':
        return 'The card was declined as the transaction requires authentication.';
      case 'call_issuer':
      case 'do_not_honor':
      case 'do_not_try_again':
      case 'fraudulent':
      case 'generic_decline':
      case 'lost_card':
      case 'merchant_blacklist':
      case 'new_account_information_available':
      case 'no_action_taken':
      case 'pickup_card':
      case 'restricted_card':
      case 'revocation_of_all_authorizations':
      case 'revocation_of_authorization':
      case 'security_violation':
      case 'service_not_allowed':
      case 'stolen_card':
      case 'stop_payment_order':
      case 'transaction_not_allowed':
        return 'The card was declined for an unknown reason. Please contact your bank for more information.';
      case 'approve_with_id':
        return 'The payment can’t be authorised. Please attempt the payment again.';
      case 'card_not_supported':
        return 'The card does not support this type of purchase. Please contact your bank for more information.';
      case 'card_velocity_exceeded':
      case 'withdrawal_count_limit_exceeded':
        return 'The balance or credit limit has been exceeded. Please contact your bank for more information.';
      case 'currency_not_supported':
        return 'The card does not support the specified currency.';
      case 'duplicate_transaction':
        return 'Check your User Profile page. If there is no active subscription, attempt the payment again.';
      case 'expired_card':
        return 'The card has expired. Please use another card.';
      case 'incorrect_number':
      case 'invalid_number':
        return 'The card number is incorrect. Please try again using the correct card number.';
      case 'incorrect_cvc':
      case 'invalid_cvc':
        return 'The CVC number is incorrect. Please try again using the correct CVC.';
      case 'insufficient_funds':
        return 'The card has insufficient funds to complete the purchase.';
      case 'invalid_account':
        return 'The card, or account the card is connected to, is invalid. Please contact your bank for more information.';
      case 'invalid_amount':
        return 'The payment amount exceeds the amount that’s allowed. Please contact your bank for more information.';
      case 'invalid_expiry_month':
        return 'The expiration month is invalid. Please try again using the correct expiry date.';
      case 'invalid_expiry_year':
        return 'The expiration year is invalid. Please try again using the correct expiry date.';
      case 'issuer_not_available':
        return 'The payment couldn’t be authorised. Please attempt the payment again.';
      case 'not_permitted':
        return 'The payment isn’t permitted. Please contact your bank for more information.';
      case 'processing_error':
        return 'The payment needs to be attempted again. If it still can’t be processed, try again later.';
      case 'reenter_transaction':
        return 'The payment needs to be attempted again. If it still can’t be processed, please contact your bank.';
      case 'testmode_decline':
        return 'Please use a genuine card to make a payment.';
      case 'try_again_later':
        return 'The card was declined for an unknown reason. Please attempt the payment again.';
      default:
        if (error.message) {
          return error.message;
        } else {
          return 'The card was declined for an unknown reason. Please contact your bank for more information.';
        }
    }
  } else {
    return '';
  }
};
