import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IHeaderHistory } from '../../core/components/layout/Header/types';
import { ACTION_PATH } from '../../core/constants/routePaths';
import { Col, Row } from 'antd';
import styled, { css } from 'styled-components';
import { ReactComponent as LogoIcon } from '../img/logo.svg';
import OnboardingImage from './OnboardingImage/OnboardingImage';
import OnboardingActivities from './OnboardingActivities/OnboardingActivities';
import { theme } from '../../core/styles/styled-components';
import { useMediaQuery } from 'react-responsive';
import { useQuery } from '@apollo/client';
import { AUTH_STATE, AuthValue } from '../../apollo/stateFields/auth/authFields';
import { TUTORIAL_STATE, TutorialValue } from '../../apollo/stateFields/tutorial/tutorialField';

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledRow = styled(Row)`
  height: 100%;
`;

const StyledCol = styled(({ isDesktop, step, isStandardDesktop, ...rest }) => <Col {...rest} />)<{
  isDesktop?: boolean;
  step?: number;
  isStandardDesktop?: boolean;
}>`
  height: 100%;

  ${({ isDesktop, step, isStandardDesktop }) =>
    step !== 2
      ? isDesktop &&
        css`
          display: block;
          flex: 0 0 50%;
          max-width: 50%;
        `
      : isStandardDesktop
      ? css`
          display: block;
          flex: 0 0 50%;
          max-width: 50%;
        `
      : css`
          width: 100%;
        `}

  @media ${theme.device.tablet.max} {
    width: 100%;
  }
`;

const StyledActivitiesWrapper = styled.div<{ step: number }>`
  position: relative;
  background-color: var(--color-white);
  height: 100%;
  max-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 79px 130px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #a4a6ad;
    border-radius: 10px;
  }

  @media (max-width: 1439px) {
    padding: 19px 15px;

    ${({ step }) =>
      step === 2 &&
      css`
        padding: 131px 230px;
      `}
  }

  @media ${theme.device.tablet.max} {
    padding: 131px 150px;
  }

  @media ${theme.device.mobile.max} {
    padding: 39px 19px;
  }
`;
const StyledLogoIcon = styled(LogoIcon)`
  position: absolute;
  height: 43px;
  width: 85px;
  z-index: 1001;

  // top: 160px;
  //
  // @media ${theme.device.largeDesktop.min} {
  //   top: 160px;
  // }
  //
  // @media ${theme.device.desktop.max} {
  //   top: 80px;
  // }
  //
  // @media ${theme.device.tablet.max} {
  //   top: 160px;
  // }
  //
  // @media ${theme.device.mobile.max} {
  //   top: 80px;
  // }
`;
const StyledOnboardingActivities = styled(OnboardingActivities)`
  //margin-top: 160px;
  margin-top: 80px;
  width: 100%;
`;

const Onboarding = () => {
  const { data } = useQuery(AUTH_STATE);
  const { isNewUser }: AuthValue = data?.auth;
  const { data: tutorialData } = useQuery(TUTORIAL_STATE);
  const { userPreferences, onboardingStep }: TutorialValue = tutorialData?.tutorial;
  const wrapperRef = useRef<HTMLDivElement>(null);

  const history = useHistory<IHeaderHistory>();
  const isDesktop = useMediaQuery({ query: theme.device.desktop.min });
  const isStandardDesktop = useMediaQuery({
    query: `(min-width: ${theme.size.standardDesktop}px)`,
  });

  useEffect(() => {
    if (!isNewUser || !!userPreferences[0]?.roles?.length) {
      history.push(ACTION_PATH);
    }
  }, []);

  useEffect(() => {
    wrapperRef?.current?.scrollTo(0, 0);
  }, [onboardingStep]);

  return (
    <StyledWrapper>
      <StyledRow>
        <StyledCol
          isDesktop={isDesktop}
          step={onboardingStep}
          isStandardDesktop={isStandardDesktop}
        >
          <StyledActivitiesWrapper ref={wrapperRef} step={onboardingStep}>
            <StyledLogoIcon />
            <StyledOnboardingActivities />
          </StyledActivitiesWrapper>
        </StyledCol>
        {onboardingStep !== 2
          ? isDesktop && (
              <StyledCol isDesktop={isDesktop}>
                <OnboardingImage />
              </StyledCol>
            )
          : isStandardDesktop && (
              <StyledCol isDesktop={isDesktop}>
                <OnboardingImage />
              </StyledCol>
            )}
      </StyledRow>
    </StyledWrapper>
  );
};

export default Onboarding;
