import { Date } from './date';
import { DurationType } from './duration';
import { Outcome } from './outcome';
import { Tag } from './tag';
import { TagOption } from '../../features/common/components/form/AddTags/AddTags';
import { ActionTimeFormat } from '../../features/actions/components/ActionBoard/types';

export enum ActionStatus {
  BACKLOG = 'BACKLOG',
  TODO = 'TODO',
  DOING = 'DOING',
  DONE = 'DONE',
}

export enum ActionPriority {
  IMPORTANT = 'Important',
  IMPORTANT_URGENT = 'Important_Urgent',
  URGENT = 'Urgent',
}

export interface Action {
  __typename?: 'Action';
  actualEndDate: Date;
  actualPoints?: string;
  estimatedPoints?: string;
  actualStartDate: Date;
  assigneeId?: number;
  description?: string;
  endDate: Date;
  id: number;
  name: string;
  outcome?: Outcome | null;
  startDate: Date;
  status: ActionStatus;
  tags?: Tag[];
  updateDate?: Date;
  createDate: string;
  workspaceId: number;
  durationType?: DurationType;
  isArchived?: boolean;
  priority?: ActionPriority | null;
  actionTimeFormat?: ActionTimeFormat | null;
  estimatedTime?: number | null;
  spendTime?: number | null;
}

export interface ActionInput {
  actualEndDate?: Date;
  actualPoints?: string;
  estimatedPoints?: string;
  actualStartDate?: Date;
  assigneeId?: number;
  description?: string;
  endDate?: Date;
  id?: number;
  name?: string;
  inputTags?: TagOption[];
  outcomeId?: number;
  startDate?: Date;
  status?: ActionStatus;
  tagIds?: number[];
  updateDate?: Date;
  workspaceId?: number;
  isArchived?: boolean;
  durationType?: string;
  priority?: ActionPriority | null;
  actionTimeFormat?: ActionTimeFormat | null;
  estimatedTime?: number | null;
  spendTime?: number | null;
}

export interface ActionMoveResponce {
  action: Action;
  historicOutcome?: Outcome;
}
