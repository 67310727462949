import React, { SVGAttributes, FC } from "react";

export const SearchIcon: FC<SVGAttributes<SVGElement>> = ({ color = '#202635', ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="11.5" cy="11.5" r="8" stroke={color}/>
      <path d="M17.5 17.5L21 21" stroke={color} strokeWidth="2" strokeLinecap="round"/>
    </svg>
  );
};
