import { gql } from '@apollo/client';
import { COURSES_VAR, DND_COLUMN_FIELDS } from './fragments';

export const UPDATE_SETTINGS = gql`
  mutation UpdateUserSettings($settings: UserSettingsInput!) {
    updateUserSettings(settings: $settings) {
      boardColumns {
        outcomeColumn {
          ...dndColumnFields
        }
        backlogColumn {
          ...dndColumnFields
        }
        toDoColumn {
          ...dndColumnFields
        }
        doingColumn {
          ...dndColumnFields
        }
        doneColumn {
          ...dndColumnFields
        }
        noteColumn {
          ...dndColumnFields
        }
      }
      filterInput {
        workspaceId
        tagIds
      }
      timezone
      shouldUpdateStatusToComplete
      shouldUpdateStatusToCurrent
      setStartDateToToday
      setUpdateFilterInput
      defaultWorkspaceId
      tutorialPassedStep
      doneColumnFilter {
        name
        filter
        startDate
        endDate
      }
      toDoColumnFilter {
        name
        filter
        startDate
        endDate
      }
      subscriptionReminderTime
    }
  }
  ${DND_COLUMN_FIELDS}
`;

export const UPDATE_FOCUS_TIMER_SETTINGS = gql`
  mutation UpdateUserSettings($settings: UserSettingsInput!) {
    updateUserSettings(settings: $settings) {
      focusTimerFixedPeriod
      focusTimerIsBipSound
      focusTimerType
    }
  }
`;

export const UPDATE_COURSES_SETTINGS = gql`
  mutation UpdateUserSettings($settings: UserSettingsInput!) {
    updateUserSettings(settings: $settings) {
      coursesVars {
        ...coursesVarFields
      }
      coursePassedStep
      skipNotificationTime
    }
  }
  ${COURSES_VAR}
`;

export const UPDATE_USER_PREFERENCES_AND_QUESTIONNAIRE = gql`
  mutation UpdateUserSettings($settings: UserSettingsInput!) {
    updateUserSettings(settings: $settings) {
      userPreferences {
        title
        roles
      }
      onboardingQuestionnaire {
        question
        answer
      }
    }
  }
`;

export const UPDATE_USER_ENTITY = gql`
  mutation UpdateUserEntity($userParams: UserInput!) {
    updateUserEntity(userParams: $userParams) {
      email
      firstName
      isFBaccountWithPhone
      lastName
      name
      isActivated
    }
  }
`;
export const UPDATE_USER_ENTITY_BY_EMAIL = gql`
  mutation UpdateUserEntityByEmail($email: String!, $userParams: UserInput!) {
    updateUserEntityByEmail(email: $email, userParams: $userParams) {
      firstName
      lastName
      email
      isActivated
      isFBaccountWithPhone
    }
  }
`;

export const UPDATE_USER_RECOMMENDED_COMPANY = gql`
  mutation UpdateUserRecommendedCompany($recommendedCompany: RecommendedCompanyInput!) {
    updateUserRecommendedCompany(recommendedCompany: $recommendedCompany)
  }
`;

export const UPDATE_USER_REMIND_DATE = gql`
  mutation UpdateUserSettings($settings: UserSettingsInput!) {
    updateUserSettings(settings: $settings) {
      recommendedCompanyRemindDate
    }
  }
`;

export const INIT_STRIPE_CUSTOMER = gql`
  mutation InitStripeCustomer {
    initStripeCustomer {
      id
    }
  }
`;

export const CREATE_STRIPE_SUBSCRIPTION = gql`
  mutation CreateStripeSubscription(
    $bmSubscriptionPlanId: String!
    $isCancelAtPeriodEnd: Boolean
    $promoCodeId: String
  ) {
    createStripeSubscription(
      bmSubscriptionPlanId: $bmSubscriptionPlanId
      isCancelAtPeriodEnd: $isCancelAtPeriodEnd
      promoCodeId: $promoCodeId
    ) {
      clientSecret
      subscriptionId
    }
  }
`;

export const CHANGE_STRIPE_SUBSCRIPTION = gql`
  mutation ChangeStripeSubscription(
    $newBmSubscriptionPlanId: String!
    $isCancelAtPeriodEnd: Boolean
    $promoCodeId: String
  ) {
    changeStripeSubscription(
      newBmSubscriptionPlanId: $newBmSubscriptionPlanId
      isCancelAtPeriodEnd: $isCancelAtPeriodEnd
      promoCodeId: $promoCodeId
    ) {
      clientSecret
      subscriptionId
    }
  }
`;

export const CANCEL_STRIPE_SUBSCRIPTION = gql`
  mutation CancelStripeSubscription($subscriptionId: String!) {
    cancelStripeSubscription(subscriptionId: $subscriptionId)
  }
`;

export const CANCEL_STRIPE_SUBSCRIPTION_AT_PERIOD_END = gql`
  mutation CancelStripeSubscriptionAtPeriodEnd {
    cancelStripeSubscriptionAtPeriodEnd {
      bmSubscription {
        amount
        bmSubscriptionPlan
        bmSubscriptionPlanId
        bmSubscriptionProvider
        createDate
        createDateFreeSubscription
        error
        expiration
        externalCustomerId
        externalSubscriptionId
        externalUnfinishedSubscriptionId
        id
        interval
        isEligableToFreeSubscription
        isTrialUsed
      }
      subscription {
        amount
        clientSecret
        currency
        currentPeriodEnd
        currentPeriodStart
        interval
        isCancelAtPeriodEnd
        startDate
        status
        subscriptionId
      }
      unfinishedSubscription {
        amount
        clientSecret
        currency
        currentPeriodEnd
        currentPeriodStart
        interval
        isCancelAtPeriodEnd
        startDate
        status
        subscriptionId
      }
    }
  }
`;

// For Admin

// Individual User
export const ADMIN_SUBSCRIPTION_EXP_BY_EMAIL = gql`
  mutation UpdateFreeSubscriptionExpirationByEmail(
    $values: UpdateFreeSubscriptionExpirationByEmailInput!
  ) {
    updateFreeSubscriptionExpirationByEmail(values: $values) {
      expiration
    }
  }
`;
export const ADMIN_COURSES_BY_EMAIL = gql`
  mutation UpdateCoursesAvailableList($values: UpdateCoursesAvailableListInput!) {
    updateCoursesAvailableList(values: $values) {
      coursesAvailableList
    }
  }
`;

// Corporate Users
export const ADMIN_CREATE_CORPORATE_ACC = gql`
  mutation CreateCorporateAccount($values: CorporateAccountCreateInput!) {
    createCorporateAccount(values: $values) {
      domain
    }
  }
`;
export const ADMIN_COURSES_BY_DOMAIN = gql`
  mutation UpdateCorporateAccount($values: CorporateAccountUpdateInput!) {
    updateCorporateAccount(values: $values) {
      domain
    }
  }
`;
export const ADMIN_EXPIRATION_BY_DOMAIN = gql`
  mutation SwitchSubscriptionToSelfGuidedFreeByCorporateAccount(
    $values: SwitchSubscriptionToSelfGuidedFreeByCorporateAccountInput!
  ) {
    switchSubscriptionToSelfGuidedFreeByCorporateAccount(values: $values) {
      updatedUserList
    }
  }
`;
