import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { TagsProps } from './types';
import { useQuery } from '@apollo/client';
import { FETCH_USER_WORKSPACES_WITH_INVITED_COUNT } from '../../../../../common/graphql/queries';
import { FetchUserWorkspacesWithInvitedCount } from '../../../../../workspaces/types';

const StyledTagsContainer = styled.div<{ isReady: boolean }>`
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
  visibility: ${(props) => (props.isReady ? 'visible' : 'hidden')};
`;

const StyledTag = styled.span<{ isFocusTimer?: boolean }>`
  margin-right: 2px;
  padding: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  font-size: 0.5rem;

  ${({ isFocusTimer }) => {
    if (isFocusTimer) {
      return css`
        color: var(--color-white);
        border: 1px solid rgba(255, 255, 255, 0.3);
      `;
    }
  }}
`;

const StyledCount = styled.span<{ isFocusTimer?: boolean }>`
  margin-left: 3px;
  font-size: 0.5rem;

  ${({ isFocusTimer }) => {
    if (isFocusTimer) {
      return css`
        color: var(--color-white);
      `;
    }
  }}
`;

const Tags = React.memo(({ tags, loadingOtherComponent, isFocusTimer }: TagsProps) => {
  if (!tags || !tags.length) {
    return null;
  }
  const { data: workspacesResponse } = useQuery<FetchUserWorkspacesWithInvitedCount>(
    FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
  );

  const [visibleTags, setVisibleTags] = useState(tags);
  const [isReady, setIsReady] = useState(false);
  const container = useRef<HTMLDivElement>(null!);
  const itemsRef = useRef<HTMLSpanElement[]>([]);
  const countLeft = tags.length - visibleTags.length;

  useEffect(() => {
    setVisibleTags(tags);
  }, [tags]);

  useEffect(() => {
    const width = Math.floor(container.current.getBoundingClientRect().width);
    let itemsWidth = 0;
    itemsRef.current = itemsRef.current.slice(0, visibleTags.length);
    itemsRef.current.forEach((i) => {
      if (i) {
        itemsWidth += i.getBoundingClientRect().width + 2;
      }
    });

    if ((itemsWidth > width || width - itemsWidth < 15) && visibleTags.length) {
      // 15 is count width
      setVisibleTags((prevTags) => prevTags.slice(0, prevTags.length - 1));
      return;
    }
    if (loadingOtherComponent === undefined) {
      setIsReady(true);
    } else {
      setIsReady(!loadingOtherComponent);
    }
  }, [
    visibleTags,
    tags,
    loadingOtherComponent,
    workspacesResponse?.fetchUserWorkspacesWithInvitedCount,
  ]);

  return (
    <StyledTagsContainer isReady={isReady} ref={container}>
      {visibleTags.map((tag, index) => (
        <StyledTag
          isFocusTimer={isFocusTimer}
          key={tag.id}
          ref={(el) => {
            if (itemsRef.current) {
              itemsRef.current[index] = el as HTMLSpanElement;
            }
          }}
        >
          {tag.name}
        </StyledTag>
      ))}
      {countLeft > 0 && <StyledCount isFocusTimer={isFocusTimer}>+{countLeft}</StyledCount>}
    </StyledTagsContainer>
  );
});

export { Tags };
