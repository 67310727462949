import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FetchUserByEmail, UpdateUserEntityByEmail } from '../../../graphql/types';
import {
  ADMIN_COURSES_BY_EMAIL,
  ADMIN_SUBSCRIPTION_EXP_BY_EMAIL,
  UPDATE_USER_ENTITY_BY_EMAIL,
} from '../../../graphql/mutations';
import { Input, Modal, Select } from 'antd';
import styled from 'styled-components';
import { theme } from '../../../core/styles/styled-components';
import { Button, DatePicker } from '../../../core/components/common';
import { EditModalType } from '../UserInfo/UserInfo';
import { firebase } from '../../../core/integrations/firebase/init';
import { CoursesGroups } from '../../const';
import { getTime, isPast } from 'date-fns';
import { FETCH_USER_BY_EMAIL } from '../../../graphql/queries';

const StyledModal = styled(Modal)`
  margin: auto;
  padding: 0;
  z-index: 2500;
  max-width: 360px;
  top: 20%;

  .ant-modal-content {
    background-color: var(--color-main-grey-2);
    border-radius: 3px;
    height: auto;
  }

  .ant-modal-body {
    position: relative;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${theme.device.mobile.max} {
    height: 100%;
  }
`;
const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 24px;
`;
const StyledInputContainer = styled.div``;
const StyledLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #202635;
`;
const StyledButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;

  @media ${theme.device.mobile.max} {
    margin-top: auto;
  }
`;

const StyledButton = styled(Button)`
  height: 32px;
  font-size: 14px;
`;

const StyledCancelButton = styled(StyledButton)`
  background-color: transparent;
  color: #202635;
  margin-left: 10px;

  @media ${theme.device.mobile.max} {
    border: 1px solid var(--color-dark-blue);
  }
`;

const EditUserModal = ({
  type,
  email,
  onSuccess,
  onError,
  onClose,
}: {
  type: EditModalType | null;
  email: string;
  onSuccess: () => void;
  onError: () => void;
  onClose: (displayName?: string) => void;
}) => {
  const { data: userData } = useQuery<FetchUserByEmail>(FETCH_USER_BY_EMAIL, {
    variables: { email },
  });
  const [updateUserEntity] = useMutation<UpdateUserEntityByEmail>(UPDATE_USER_ENTITY_BY_EMAIL);
  const [changeExpirationByEmail] = useMutation<any>(ADMIN_SUBSCRIPTION_EXP_BY_EMAIL);
  const [changeCoursesByEmail] = useMutation<any>(ADMIN_COURSES_BY_EMAIL);

  const [firstNameInput, setFirstNameInput] = useState<string>('');
  const [lastNameInput, setLastNameInput] = useState<string>('');
  const [curriculumType, setCurriculumType] = useState(CoursesGroups[1]);
  const [expiration, setExpiration] = useState<number | undefined>(undefined);

  const [loading, setLoading] = useState(false);
  const user = firebase.auth().currentUser;

  const handleUpdateUser = async () => {
    setLoading(true);
    switch (type) {
      case EditModalType.DisplayName:
        await updateUserEntity({
          variables: {
            email: email,
            userParams: {
              name: `${firstNameInput} ${lastNameInput}`,
            },
          },
        })
          .then((resp) => {
            onSuccess();
          })
          .catch((e) => {
            onError();
          });
        break;
      case EditModalType.CurriculumType:
        changeCoursesByEmail({
          variables: {
            values: {
              email,
              coursesAvailableList: curriculumType,
            },
          },
        })
          .then((resp) => {
            onSuccess();
          })
          .catch((e) => {
            onError();
          });
        break;
      case EditModalType.SubscriptionExpiration:
        changeExpirationByEmail({
          variables: {
            values: { email, expiration },
          },
        })
          .then((resp) => {
            onSuccess();
          })
          .catch((e) => {
            onError();
          });
        break;
      default:
        break;
    }
    setLoading(false);
    onClose(`${firstNameInput} ${lastNameInput}`);
  };

  const isDisabled = useMemo(() => {
    switch (type) {
      case EditModalType.DisplayName:
        return !firstNameInput.trim().length || !lastNameInput.trim().length;
    }
  }, [type]);

  useEffect(() => {
    const firstName = userData?.fetchUserByEmail?.name?.split(' ')[0];
    const lastName = userData?.fetchUserByEmail?.name?.split(' ')[1];
    if (firstName) setFirstNameInput(firstName);
    if (lastName) setLastNameInput(lastName);
  }, [userData?.fetchUserByEmail?.name]);

  return (
    <StyledModal
      destroyOnClose
      getContainer={() => document.body}
      visible={!!type}
      onCancel={() => onClose()}
      footer={null}
      mask={false}
      closable={true}
    >
      <StyledContentWrapper>
        <StyledTitle>Edit User Profile</StyledTitle>
        <StyledInputContainer>
          {type === EditModalType.DisplayName && (
            <>
              <StyledLabel>First Name</StyledLabel>
              <Input
                value={firstNameInput}
                minLength={3}
                maxLength={100}
                placeholder={'Enter first name'}
                onChange={(e) => setFirstNameInput(e.target.value)}
              />
              <StyledLabel>Last Name</StyledLabel>
              <Input
                value={lastNameInput}
                minLength={3}
                maxLength={100}
                placeholder={'Enter last name'}
                onChange={(e) => setLastNameInput(e.target.value)}
              />
            </>
          )}
          {type === EditModalType.CurriculumType && (
            <>
              <StyledLabel>Curriculum type</StyledLabel>
              <Select
                labelInValue
                defaultValue={{ value: 1, label: 'Fundamentals, Mastery' }}
                style={{ width: '100%' }}
                onChange={(value) => {
                  setCurriculumType(CoursesGroups[value.value]);
                }}
                options={[
                  {
                    value: 0,
                    label: 'Foundations, Mastering Personal Effectiveness',
                  },
                  {
                    value: 1,
                    label: 'Fundamentals, Mastery',
                  },
                  {
                    value: 2,
                    label: 'Fundamentals (For Managers), Mastery (For Managers)',
                  },
                ]}
              />
            </>
          )}
          {type === EditModalType.SubscriptionExpiration && (
            <>
              <StyledLabel>Expiration date</StyledLabel>
              <DatePicker
                style={{ width: '100%' }}
                size={'large'}
                disabledDate={(current) => current && isPast(current)}
                onChange={(date) => {
                  if (date) {
                    setExpiration(Math.floor(getTime(date) / 1000));
                  }
                }}
              />
            </>
          )}
        </StyledInputContainer>

        <StyledButtonContainer>
          <StyledButton
            isDisabled={isDisabled}
            isLoading={loading}
            onClick={() => handleUpdateUser()}
          >
            Save
          </StyledButton>
          <StyledCancelButton onClick={() => onClose()}>Cancel</StyledCancelButton>
        </StyledButtonContainer>
      </StyledContentWrapper>
    </StyledModal>
  );
};

export default EditUserModal;
