import { gql, makeVar } from '@apollo/client';

export type SearchQueryVariables = {
  query: string;
  offset: number;
  limit: number;
};

export interface SearchBarValue {
  queryVariables: SearchQueryVariables | undefined;
  isOpened: boolean;
}

export const searchBarValueInitialValue: SearchBarValue = {
  queryVariables: undefined,
  isOpened: false,
};

export const searchBarVar = makeVar<SearchBarValue>(searchBarValueInitialValue);
export const SEARCH_BAR_STATE = gql`
  query SearchBar {
    searchBar @client
  }
`;
