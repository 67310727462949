import React, { useEffect, useState } from 'react';
import { FirebaseSocialAuth } from '../index';
import styled from 'styled-components';
import { Formik } from 'formik';
import { validationSchema } from './validation';
import { Form, SubmitButton } from 'formik-antd';
import { Link } from 'react-router-dom';
import { PASSWORD_RECOVERY_PATH, SIGN_UP_PATH } from '../../../../core/constants/routePaths';
import { InputTextField } from '../../../../core/components/form';
import SignInAndSignUpLayout from '../SignInAndSignUpLayout';
import { useAuth } from '../../../../context/authContext';
import { SignInData } from '../../types';
import { ReactComponent as Icon } from '../../svg/verified.svg';

const StyledButtonsContainer = styled.div`
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLink = styled(Link)`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  color: var(--color-main-dark-blue);
`;

const StyledButtonLink = styled(Link)`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  color: var(--color-main-dark-blue);
  margin-right: 24px;
`;

const StyledErrorButtonLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

const StyledText = styled.p`
  font-size: 14px;
  line-height: 16px;
  margin: 0;
`;

const StyledVerifiedMessageContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 12px;

  svg {
    margin-left: 6px;
  }
`;

const initialValues = {
  email: '',
  password: '',
};

const SignIn = () => {
  const { signInWithEmail, sendEmailVerification } = useAuth();
  const [isNotVerified, setIsNotVerified] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showVerifiedMessage, setShowVerifiedMessage] = useState(false);
  const label = 'Sign in';

  const handleLogin = async (values: SignInData) => {
    if (signInWithEmail) {
      const isNotVerified = await signInWithEmail(values);
      setIsNotVerified(isNotVerified);
    }
  };

  const handleSendEmailVerification = async () => {
    if (sendEmailVerification) {
      const res = await sendEmailVerification();
      if (res) {
        setShowSuccessMessage(true);
      } else {
        setIsNotVerified(false);
      }
    }
  };

  const verifiedError = isNotVerified && (
    <>
      You did not verify this email address.{' '}
      <StyledErrorButtonLink onClick={handleSendEmailVerification}>
        Resend verification email?
      </StyledErrorButtonLink>
    </>
  );

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const isVerified = query.get('verified') === 'true';

    isVerified && setShowVerifiedMessage(true);
  }, []);

  const signInFormEl = (
    <Formik
      enableReinitialize
      validateOnChange={true}
      validateOnBlur={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleLogin}
    >
      {({ isSubmitting, errors }) => {
        return (
          <Form>
            <InputTextField
              name="email"
              placeholder="Email"
              autoComplete="off"
              displayError={!!errors.email || isNotVerified}
              errorMessage={verifiedError}
            />
            <InputTextField
              name="password"
              type="password"
              placeholder="Password"
              autoComplete="off"
              displayError={!!errors.password}
            />
            <StyledButtonsContainer>
              <StyledLink to={PASSWORD_RECOVERY_PATH}>Forgot password?</StyledLink>
              <div>
                <StyledButtonLink to={SIGN_UP_PATH}>Sign up</StyledButtonLink>
                <SubmitButton loading={isSubmitting} type="primary" shape="round">
                  Login
                </SubmitButton>
              </div>
            </StyledButtonsContainer>
          </Form>
        );
      }}
    </Formik>
  );

  const subtitleEl = showVerifiedMessage && (
    <>
      <StyledVerifiedMessageContainer>
        <StyledText>Thank you for verifying your email address</StyledText>
        <Icon />
      </StyledVerifiedMessageContainer>
      <StyledText>
        Your new account has been activated and you can now login to the BillionMinds
      </StyledText>
    </>
  );

  return (
    <SignInAndSignUpLayout
      title={label}
      subtitle={subtitleEl}
      showSuccessMessage={showSuccessMessage}
      formComponent={signInFormEl}
      socialAuthComponent={!showVerifiedMessage && <FirebaseSocialAuth label={label} />}
    />
  );
};

export default SignIn;
