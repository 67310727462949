import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ConfirmModal } from '../../../features/common/components/ConfirmModal';
import { useMutation, useQuery } from '@apollo/client';
import {
  BmSubscriptionTypes,
  FetchStripeSubscription,
  FetchUserSettings,
  UpdateUserSettings,
} from '../../../graphql/types';
import { FETCH_SETTINGS, FETCH_STRIPE_SUBSCRIPTION } from '../../../graphql/queries';
import { stripeMutation } from '../../../apollo/stateFields/stripe';
import { STRIPE_STATE, StripeValue } from '../../../apollo/stateFields/stripe/stripeFields';
import { USER_PLANS_PATH } from '../../../core/constants/routePaths';
import { useHistory } from 'react-router-dom';
import { shouldShowExpiredPopup } from '../utils';
import { UPDATE_SETTINGS } from '../../../graphql/mutations';
import { addDays, differenceInDays, startOfDay } from 'date-fns';
import { setTimeoutPromise } from '../../../core/utils/promiseTimeout';
import useDndContext from '../../../context/dndContext/useDndContext';
import pluralize from 'pluralize';

const StyledDescription = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  text-align: center;
`;

const data = {
  trial: {
    title: 'Your free trial has ended',
    description:
      'Some of the features of BillionMinds have been disabled. To continue using the full service, please purchase one of our plans',
    buttonLabel: 'Choose a plan',
  },
  subscription: {
    title: 'Your subscription has expired',
    description: 'Some features will be unavailable to you until you select a paid plan.',
    buttonLabel: 'Choose a Plan',
  },
  threeDaysReminder: {
    title: 'Your free trial will end in 3 days',
    description:
      'Some of the features in BillionMinds will soon be disabled. To continue using the full service, please purchase one of our plans.',
    buttonLabel: 'Choose a Plan',
  },
};

const ExpiredSubscriptionModal = () => {
  const { data: settingsData, refetch } = useQuery<FetchUserSettings>(FETCH_SETTINGS);
  const [updateSettings] = useMutation<UpdateUserSettings>(UPDATE_SETTINGS);
  const { data: stripeData } = useQuery(STRIPE_STATE);
  const { isSubscriptionExpired, isSubscriptionExpiredTrial }: StripeValue = stripeData?.stripe;
  const { setIsSubscriptionExpiredTrial } = stripeMutation;
  const { data: userSubscriptionData } = useQuery<FetchStripeSubscription>(
    FETCH_STRIPE_SUBSCRIPTION,
    { fetchPolicy: 'no-cache' },
  );
  const history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { isUpdated } = useDndContext();
  const [is3DayReminder, setIs3DayReminder] = useState<boolean>(false);
  const isTrial = useMemo(() => {
    return (
      userSubscriptionData?.fetchStripeSubscription?.bmSubscription?.bmSubscriptionPlan ===
        BmSubscriptionTypes.Trial_Free ||
      userSubscriptionData?.fetchStripeSubscription?.bmSubscription?.bmSubscriptionPlan === null
    );
  }, [userSubscriptionData?.fetchStripeSubscription]);

  const remindDays =
    userSubscriptionData?.fetchStripeSubscription.bmSubscription.bmSubscriptionPlan ===
      BmSubscriptionTypes.Trial_Free ||
    userSubscriptionData?.fetchStripeSubscription.bmSubscription.bmSubscriptionPlan ===
      BmSubscriptionTypes.Trial_ThreeMonth
      ? userSubscriptionData?.fetchStripeSubscription.bmSubscription.expiration
        ? differenceInDays(
            startOfDay(
              +userSubscriptionData?.fetchStripeSubscription.bmSubscription.expiration * 1000,
            ),
            startOfDay(Date.now()),
          )
        : undefined
      : undefined;

  // useEffect(() => {
  //   if (isSubscriptionExpired && isTrial && userSubscriptionData?.fetchStripeSubscription) {
  //     setIsSubscriptionExpiredTrial(true);
  //   } else if (isSubscriptionExpired && userSubscriptionData?.fetchStripeSubscription) {
  //     setIsSubscriptionExpiredTrial(false);
  //   }
  // }, [isSubscriptionExpired, isTrial, userSubscriptionData?.fetchStripeSubscription]);

  useEffect(() => {
    if (
      isUpdated &&
      settingsData?.fetchUserSettings.freeTrialReminderTime === null &&
      remindDays &&
      remindDays <= 3 &&
      remindDays >= 1
    ) {
      setIs3DayReminder(true);
      setShowModal(true);
      return;
    }
    if (isUpdated && isSubscriptionExpired && userSubscriptionData?.fetchStripeSubscription) {
      if (
        isSubscriptionExpiredTrial &&
        shouldShowExpiredPopup(
          settingsData?.fetchUserSettings?.freeTrialReminderTime,
          isSubscriptionExpiredTrial,
        )
      ) {
        setShowModal(true);
        return;
      }
      if (
        isSubscriptionExpiredTrial === false &&
        shouldShowExpiredPopup(
          settingsData?.fetchUserSettings?.subscriptionReminderTime,
          isSubscriptionExpiredTrial,
        )
      ) {
        setShowModal(true);
        return;
      }
    }
  }, [isSubscriptionExpired, isSubscriptionExpiredTrial, isUpdated]);

  const handleRemindDate = () => {
    if (is3DayReminder && remindDays) {
      setTimeoutPromise(300).then(() =>
        updateSettings({
          variables: {
            settings: {
              freeTrialReminderTime: addDays(startOfDay(Date.now()), remindDays).getTime() / 1000,
            },
          },
        }),
      );
    }
    if (isSubscriptionExpired && isTrial) {
      setIsSubscriptionExpiredTrial(true);
      if (
        (settingsData?.fetchUserSettings?.freeTrialReminderTime &&
          settingsData.fetchUserSettings.freeTrialReminderTime <
            startOfDay(new Date()).getTime() / 1000) ||
        settingsData?.fetchUserSettings?.freeTrialReminderTime === null
      ) {
        setTimeoutPromise(300).then(() =>
          updateSettings({
            variables: {
              settings: {
                freeTrialReminderTime: startOfDay(new Date()).getTime() / 1000,
              },
            },
          }),
        );
      }
    } else if (isSubscriptionExpired) {
      setIsSubscriptionExpiredTrial(false);
      if (
        (settingsData?.fetchUserSettings?.subscriptionReminderTime &&
          settingsData.fetchUserSettings.subscriptionReminderTime <
            startOfDay(new Date()).getTime() / 1000) ||
        settingsData?.fetchUserSettings?.subscriptionReminderTime === null
      ) {
        setTimeoutPromise(300).then(() =>
          updateSettings({
            variables: {
              settings: {
                subscriptionReminderTime: startOfDay(new Date()).getTime() / 1000,
              },
            },
          }),
        );
      }
    }
  };

  return (
    <ConfirmModal
      cancelText={"I'll do it later"}
      closable
      visible={is3DayReminder || (isSubscriptionExpired && showModal)}
      title={
        is3DayReminder
          ? `Your free trial will end in ${remindDays ? remindDays : 'few'} ${pluralize(
              'day',
              remindDays,
            )}`
          : isSubscriptionExpiredTrial
          ? data.trial.title
          : data.subscription.title
      }
      confirmText={
        is3DayReminder
          ? data.threeDaysReminder.buttonLabel
          : isSubscriptionExpiredTrial
          ? data.trial.buttonLabel
          : data.subscription.buttonLabel
      }
      middleContent={
        <StyledDescription>
          {is3DayReminder
            ? data.threeDaysReminder.description
            : isSubscriptionExpiredTrial
            ? data.trial.description
            : data.subscription.description}
        </StyledDescription>
      }
      onCancel={() => {
        handleRemindDate();
        if (is3DayReminder) {
          setIs3DayReminder(false);
        }
        refetch().then(() => setShowModal(false));
      }}
      onOk={() => {
        handleRemindDate();
        if (is3DayReminder) {
          setIs3DayReminder(false);
        }
        refetch().then(() => history.push(USER_PLANS_PATH));
      }}
    />
  );
};

export default ExpiredSubscriptionModal;
