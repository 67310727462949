import { useMutation } from '@apollo/client';

import { CREATE_USER_WORKSPACE } from '../graphql/mutations';
import {
  FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
  FETCH_USER_WORKSPACES_WITH_ENTITIES,
} from '../../common/graphql/queries';
import {
  CreateUserWorkspace,
  FetchUserWorkspacesWithEntities,
  FetchUserWorkspacesWithInvitedCount,
} from '../types';

const useCreateUserWorkspace = () => {
  return useMutation<CreateUserWorkspace>(CREATE_USER_WORKSPACE, {
    update(cache, { data }) {
      const { fetchUserWorkspacesWithInvitedCount = [] } =
        cache.readQuery<FetchUserWorkspacesWithInvitedCount>({
          query: FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
        }) || {};
      const { fetchUserWorkspacesWithEntities = [] } =
        cache.readQuery<FetchUserWorkspacesWithEntities>({
          query: FETCH_USER_WORKSPACES_WITH_ENTITIES,
        }) || {};

      cache.writeQuery<FetchUserWorkspacesWithInvitedCount>({
        query: FETCH_USER_WORKSPACES_WITH_INVITED_COUNT,
        data: {
          fetchUserWorkspacesWithInvitedCount: data?.createUserWorkspace
            ? [data?.createUserWorkspace, ...fetchUserWorkspacesWithInvitedCount]
            : fetchUserWorkspacesWithInvitedCount,
        },
      });

      cache.writeQuery<FetchUserWorkspacesWithEntities>({
        query: FETCH_USER_WORKSPACES_WITH_ENTITIES,
        data: {
          fetchUserWorkspacesWithEntities: data?.createUserWorkspace
            ? [
                ...fetchUserWorkspacesWithEntities,
                {
                  ...data?.createUserWorkspace,
                  actions: [],
                  notes: [],
                  outcomes: [],
                  tags: [],
                },
              ]
            : fetchUserWorkspacesWithEntities,
        },
      });
    },
  });
};

export default useCreateUserWorkspace;
