import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';

import { Button } from 'core/components/common';
import { ConfirmModalProps } from './types';
import theme from '../../../../core/styles/styled-components/theme';

const StyledModal = styled(Modal)`
  && {
    z-index: 9999999;
    
    .ant-modal-content {
      max-height: 480px;
      height: 100%;
      width: 312px;
      overflow-y: hidden;
      margin: auto;
    }

    .ant-modal-body {
      padding: 32px;
      height: 100%;
    }

    @media ${theme.device.tablet.min} {
      .ant-modal-content {
        width: 480px;
      }
    }

    @media ${theme.device.smallMobile.max} {
      .ant-modal-content {
        width: 100%;
      }
    }
  }
`;

const StyledModalInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: none;
  height: 100%;
`;

const StyledMessageWrapper = styled.div`
  text-align: center;
`;

const StyledSubtitleWrapper = styled.div`
  text-align: center;
`;

const StyledMessage = styled.h2`
  line-height: 1;
  font-size: 1.112rem;
  font-weight: 600;
`;

const StyledSubtitle = styled.h3`
  line-height: 14px;
  font-size: 12px;
  font-weight: 400;
`;

const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  height: 32px;
  font-size: 14px;
`;

const StyledCancelButton = styled(StyledButton)`
  border: 1px solid var(--color-grey);
  margin-right: 48px;
  font-weight: 600;
  white-space: nowrap;
`;

const StyledSubmitButton = styled(StyledButton)`
  white-space: nowrap;
`;

const ConfirmModal = ({
  onCancel,
  onOk,
  title,
  subtitle,
  cancelText = 'No, leave it',
  confirmText = 'Yes',
  isConfirmLoading,
  endContent,
  middleContent,
  closable = false,
  ...modalProps
}: ConfirmModalProps) => (
  <StyledModal
    centered
    getContainer={() => document.documentElement}
    closable={closable}
    onCancel={onCancel}
    footer={null}
    width="26.723rem"
    bodyStyle={{
      backgroundColor: 'var(--color-main-grey-2)',
    }}
    {...modalProps}
  >
    <StyledModalInnerContainer>
      {subtitle && (
        <StyledSubtitleWrapper>
          <StyledSubtitle>{subtitle}</StyledSubtitle>
        </StyledSubtitleWrapper>
      )}
      <StyledMessageWrapper>
        <StyledMessage>{title}</StyledMessage>
      </StyledMessageWrapper>
      {middleContent}
      <StyledButtonContainer>
        <StyledCancelButton isSecondary onClick={onCancel} data-type="cancel">
          {cancelText}
        </StyledCancelButton>
        <StyledSubmitButton isLoading={isConfirmLoading} onClick={onOk}>
          {confirmText}
        </StyledSubmitButton>
      </StyledButtonContainer>
      {endContent}
    </StyledModalInnerContainer>
  </StyledModal>
);

export { ConfirmModal };
