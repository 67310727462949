import { FetchUserWorkspaceActions, UpdateAction } from '../../actions/types';
import { FETCH_ACTIONS } from '../../actions/graphql/queries';
import {
  checkIsShownEntity,
  Filter,
  OperationType,
  updatedCacheData,
  updateWorkspaceTagsData,
} from '../../utils';
import { mutateAction } from '../../../context/userWorkspaceContext';
import { ApolloCache, ApolloClient } from '@apollo/client';
import { Action } from '../../../core/types/action';
import { checkActionForComplete } from '../utils';
import { completeMutation } from '../../../apollo/stateFields/complete';
import { filterMutation } from '../../../apollo/stateFields/filterInput';
import { addEntityId, deleteEntityId } from '../../../context/dndContext/DndActions';
import { capitalize } from 'lodash-es';
import { ColumnType } from '../../../apollo/stateFields/dnd/dndFields';
import { dndDispatch } from '../../../context/dndContext/dndContext';

export const getUpdateFuncForMoveUpdateAction = (
  apolloClient: ApolloClient<any>,
  actionToUpdate: Action,
  filterInput: Filter[],
) => {
  const { setOutcomeToComplete, setOutcomeToIncomplete } = completeMutation;
  const { setUpdateFilterInputId } = filterMutation;
  const dispatch = dndDispatch;

  return (cache: ApolloCache<UpdateAction>, { data }: any) => {
    if (actionToUpdate === null) {
      return;
    }

    const { fetchUserWorkspaceActions: oldWorkspaceActions = [] } =
      cache.readQuery<FetchUserWorkspaceActions>({
        query: FETCH_ACTIONS,
        variables: {
          workspaceId: actionToUpdate.workspaceId,
        },
      }) || {};

    const { fetchUserWorkspaceActions: newWorkspaceActions = [] } =
      cache.readQuery<FetchUserWorkspaceActions>({
        query: FETCH_ACTIONS,
        variables: {
          workspaceId: data?.updateUserWorkspaceAction.workspaceId,
        },
      }) || {};

    let oldWorkspaceResult = oldWorkspaceActions;
    let newWorkspaceResult = newWorkspaceActions;

    if (data?.updateUserWorkspaceAction.isArchived) {
      oldWorkspaceResult = updatedCacheData(
        oldWorkspaceActions,
        data?.updateUserWorkspaceAction,
        OperationType.DELETE,
      );
    } else if (data?.updateUserWorkspaceAction.workspaceId === actionToUpdate.workspaceId) {
      oldWorkspaceResult = updatedCacheData(
        oldWorkspaceActions,
        data?.updateUserWorkspaceAction,
        OperationType.UPDATE,
      );
    } else if (data?.updateUserWorkspaceAction.workspaceId !== actionToUpdate.workspaceId) {
      oldWorkspaceResult = updatedCacheData(
        oldWorkspaceActions,
        data?.updateUserWorkspaceAction,
        OperationType.DELETE,
      );
      newWorkspaceResult = updatedCacheData(
        newWorkspaceActions,
        data?.updateUserWorkspaceAction,
        OperationType.CREATE,
      );
    }

    cache.writeQuery<FetchUserWorkspaceActions>({
      query: FETCH_ACTIONS,
      data: {
        fetchUserWorkspaceActions: oldWorkspaceResult,
      },
      variables: {
        workspaceId: actionToUpdate.workspaceId,
      },
    });

    data?.updateUserWorkspaceAction.workspaceId !== actionToUpdate.workspaceId &&
      cache.writeQuery<FetchUserWorkspaceActions>({
        query: FETCH_ACTIONS,
        data: {
          fetchUserWorkspaceActions: newWorkspaceResult,
        },
        variables: {
          workspaceId: data?.updateUserWorkspaceAction.workspaceId,
        },
      });

    if (data?.updateUserWorkspaceAction) {
      const operation = data.updateUserWorkspaceAction.isArchived ? 'remove' : 'update';
      if (
        operation === 'update' &&
        data.updateUserWorkspaceAction.status !== actionToUpdate.status &&
        !actionToUpdate.isArchived
      ) {
        dispatch(
          deleteEntityId({
            entityId: actionToUpdate.id,
            columnTitle: capitalize(actionToUpdate.status.toLowerCase()) as ColumnType,
          }),
        ),
          dispatch(
            addEntityId({
              entityId: data.updateUserWorkspaceAction.id,
              columnTitle: capitalize(
                data.updateUserWorkspaceAction.status.toLowerCase(),
              ) as ColumnType,
            }),
          );
      } else if (!data.updateUserWorkspaceAction.isArchived && actionToUpdate.isArchived) {
        dispatch(
          addEntityId({
            entityId: data.updateUserWorkspaceAction.id,
            columnTitle: capitalize(
              data.updateUserWorkspaceAction.status.toLowerCase(),
            ) as ColumnType,
          }),
        );
      } else if (data.updateUserWorkspaceAction.isArchived && !actionToUpdate.isArchived) {
        dispatch(
          deleteEntityId({
            entityId: data.updateUserWorkspaceAction.id,
            columnTitle: capitalize(
              data.updateUserWorkspaceAction.status.toLowerCase(),
            ) as ColumnType,
          }),
        );
      }

      mutateAction(
        apolloClient,
        data.updateUserWorkspaceAction,
        operation,
        filterInput,
        actionToUpdate,
      );
      operation === 'update' &&
        updateWorkspaceTagsData(apolloClient, data?.updateUserWorkspaceAction);

      if (
        !checkIsShownEntity(data.updateUserWorkspaceAction, filterInput) &&
        data.updateUserWorkspaceAction.workspaceId !== actionToUpdate.workspaceId
      ) {
        setUpdateFilterInputId({
          type: 'Action',
          workspaceId: data.updateUserWorkspaceAction.workspaceId,
          id: data.updateUserWorkspaceAction.id,
        });
      }
      if (data?.updateUserWorkspaceAction?.outcome) {
        checkActionForComplete(
          setOutcomeToComplete,
          setOutcomeToIncomplete,
          data?.updateUserWorkspaceAction,
        );
      }
    }
  };
};
