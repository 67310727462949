import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Action } from 'core/types/action';
import { Outcome } from 'core/types/outcome';
import { FetchOutcomes, UpdateOutcome } from 'features/outcomes/types';
import { UPDATE_OUTCOME } from 'features/outcomes/graphql/mutations';
import { FETCH_OUTCOMES } from 'features/outcomes/graphql/queries';
import {
  checkIsShownEntity,
  OperationType,
  updatedCacheData,
  updateWorkspaceTagsData,
} from 'features/utils';
import { FetchUserWorkspaceActions } from 'features/actions/types';
import { FETCH_ACTIONS } from 'features/actions/graphql/queries';
import { mutateOutcome } from 'context/userWorkspaceContext';
import { checkOutcomeForComplete } from '../utils';
import {
  FILTER_STATE,
  FilterInputValue,
} from '../../../apollo/stateFields/filterInput/filterInputFields';
import { filterMutation } from '../../../apollo/stateFields/filterInput';
import { completeMutation } from '../../../apollo/stateFields/complete';

const useUpdateOutcome = (outcomeToUpdate: Outcome | null) => {
  const apolloClient = useApolloClient();
  const { setOutcomeToComplete, setOutcomeToIncomplete } = completeMutation;
  const { data: filterData } = useQuery(FILTER_STATE);
  const { filterInput }: FilterInputValue = filterData?.filterInput;
  const { setUpdateFilterInputId } = filterMutation;

  return useMutation<UpdateOutcome>(UPDATE_OUTCOME, {
    update(cache, { data }) {
      if (outcomeToUpdate === null) {
        return;
      }
      const { fetchUserWorkspaceOutcomes: oldWorkspaceOutcomes = [] } =
        cache.readQuery<FetchOutcomes>({
          query: FETCH_OUTCOMES,
          variables: {
            workspaceId: outcomeToUpdate.workspaceId,
          },
        }) || {};

      const { fetchUserWorkspaceOutcomes: newWorkspaceOutcomes = [] } =
        cache.readQuery<FetchOutcomes>({
          query: FETCH_OUTCOMES,
          variables: {
            workspaceId: data?.updateUserWorkspaceOutcome.workspaceId,
          },
        }) || {};

      const { fetchUserWorkspaceActions = [] } =
        cache.readQuery<FetchUserWorkspaceActions>({
          query: FETCH_ACTIONS,
          variables: {
            workspaceId: outcomeToUpdate.workspaceId,
          },
        }) || {};

      let oldWorkspaceResult = oldWorkspaceOutcomes;
      let newWorkspaceResult = newWorkspaceOutcomes;

      if (data?.updateUserWorkspaceOutcome.workspaceId === outcomeToUpdate.workspaceId) {
        oldWorkspaceResult = updatedCacheData(
          oldWorkspaceOutcomes,
          data?.updateUserWorkspaceOutcome,
          OperationType.UPDATE,
        );
      } else if (data?.updateUserWorkspaceOutcome.workspaceId !== outcomeToUpdate.workspaceId) {
        oldWorkspaceResult = updatedCacheData(
          oldWorkspaceOutcomes,
          data?.updateUserWorkspaceOutcome,
          OperationType.DELETE,
        );
        newWorkspaceResult = updatedCacheData(
          newWorkspaceOutcomes,
          data?.updateUserWorkspaceOutcome,
          OperationType.CREATE,
        );

        cache.writeQuery<FetchUserWorkspaceActions>({
          query: FETCH_ACTIONS,
          data: {
            fetchUserWorkspaceActions: fetchUserWorkspaceActions.filter((action) => {
              return !data?.updateUserWorkspaceOutcome?.actions?.some(
                (item: Action) => item.id === action.id,
              );
            }),
          },
          variables: {
            workspaceId: outcomeToUpdate.workspaceId,
          },
        });
      }

      cache.writeQuery<FetchOutcomes>({
        query: FETCH_OUTCOMES,
        data: {
          fetchUserWorkspaceOutcomes: oldWorkspaceResult,
        },
        variables: {
          workspaceId: outcomeToUpdate.workspaceId,
        },
      });

      data?.updateUserWorkspaceOutcome.workspaceId !== outcomeToUpdate.workspaceId &&
        cache.writeQuery<FetchOutcomes>({
          query: FETCH_OUTCOMES,
          data: {
            fetchUserWorkspaceOutcomes: newWorkspaceResult,
          },
          variables: {
            workspaceId: data?.updateUserWorkspaceOutcome.workspaceId,
          },
        });

      if (data?.updateUserWorkspaceOutcome) {
        mutateOutcome(
          apolloClient,
          data.updateUserWorkspaceOutcome,
          'update',
          filterInput,
          outcomeToUpdate,
        );
        updateWorkspaceTagsData(apolloClient, data?.updateUserWorkspaceOutcome);
        checkOutcomeForComplete(
          setOutcomeToComplete,
          setOutcomeToIncomplete,
          data?.updateUserWorkspaceOutcome,
        );

        if (
          !checkIsShownEntity(data.updateUserWorkspaceOutcome, filterInput) &&
          data.updateUserWorkspaceOutcome.workspaceId !== outcomeToUpdate.workspaceId
        ) {
          setUpdateFilterInputId({
            type: 'Outcome',
            workspaceId: data.updateUserWorkspaceOutcome.workspaceId,
            id: data.updateUserWorkspaceOutcome.id,
          });
        }
      }
    },
  });
};

export default useUpdateOutcome;
