import { authInitialValue, AuthUser, authVar } from './authFields';

const resetAuth = () => {
  const value = {
    ...authInitialValue,
  };
  authVar(value);
};

const setIsNewUser = (payload: boolean) => {
  const value = {
    ...authVar(),
    isNewUser: payload,
  };
  authVar(value);
};

const setIsAdmin = (payload: boolean) => {
  const value = {
    ...authVar(),
    isAdmin: payload,
  };
  authVar(value);
};

const setIsRegistered = (payload: boolean) => {
  const value = {
    ...authVar(),
    isRegistered: payload,
  };
  authVar(value);
};

const setIdToken = (payload: string | undefined) => {
  const value = {
    ...authVar(),
    idToken: payload,
  };
  authVar(value);
};

const setUserId = (payload: number) => {
  const value = {
    ...authVar(),
    userId: payload,
  };
  authVar(value);
};

const setFirebaseUser = (payload: AuthUser | undefined) => {
  const value = {
    ...authVar(),
    authUser: payload,
  };
  authVar(value);
};

const setUserDisplayName = (payload: string) => {
  const value = {
    ...authVar(),
    authUser: {
      ...authVar().authUser,
      displayName: payload,
    },
  };
  authVar(value);
};

export {
  setIsRegistered,
  setIsAdmin,
  setIsNewUser,
  setIdToken,
  setUserId,
  setFirebaseUser,
  setUserDisplayName,
  resetAuth,
};
