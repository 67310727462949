import { boardEditedEntityInitialValue, boardEditedEntityVar } from './boardEditedEntityFileds';
import { Entity } from '../../../core/types/entity';
import { DataChangeLinkModal } from '../../../features/actions/components/ActionBoard/ChangeLinkModal/types';
import { Action } from '../../../core/types/action';
import { Outcome } from '../../../core/types/outcome';

const showEditActionModal = (payload: Action | null) => {
  const value = {
    ...boardEditedEntityVar(),
    actionEditedEntity: payload,
  };
  boardEditedEntityVar(value);
};

const showEditNoteModal = (payload: Entity | null) => {
  const value = {
    ...boardEditedEntityVar(),
    noteEditedEntity: payload,
  };
  boardEditedEntityVar(value);
};

const showEditOutcomeModal = (payload: Outcome | null) => {
  const value = {
    ...boardEditedEntityVar(),
    outcomeEditedEntity: payload,
  };
  boardEditedEntityVar(value);
};

const showArchiveOutcomeModal = (payload: Entity | null) => {
  const value = {
    ...boardEditedEntityVar(),
    outcomeArchiveEditedEntity: payload,
  };
  boardEditedEntityVar(value);
};

const showConvertNoteToActionModal = (payload: Entity | null) => {
  const value = {
    ...boardEditedEntityVar(),
    convertNoteToActionEntity: payload,
  };
  boardEditedEntityVar(value);
};

const showConvertNoteToOutcomeModal = (payload: Entity | null) => {
  const value = {
    ...boardEditedEntityVar(),
    convertNoteToOutcomeEntity: payload,
  };
  boardEditedEntityVar(value);
};

const setIsNewInformationDrawerOpen = (payload: boolean) => {
  const value = {
    ...boardEditedEntityVar(),
    isNewInformationDrawerOpen: payload,
  };
  boardEditedEntityVar(value);
};

const setDataChangeLinkModal = (payload: DataChangeLinkModal) => {
  const value = {
    ...boardEditedEntityVar(),
    dataChangeLinkModal: payload,
  };
  boardEditedEntityVar(value);
};

//to update outcome entity
const setUpdateOutcomeWithActionEntity = (payload: null | Action) => {
  const value = {
    ...boardEditedEntityVar(),
    updateOutcomeWithActionEntity: payload,
  };

  boardEditedEntityVar(value);
};

const setShowFocusSettingsForActionId = (payload: null | number) => {
  const value = {
    ...boardEditedEntityVar(),
    showFocusSettingsForActionId: payload,
  };
  boardEditedEntityVar(value);
};

const updateFocusTimerCounter = () => {
  const value = {
    ...boardEditedEntityVar(),
    focusTimerCounter: boardEditedEntityVar().focusTimerCounter + 1,
  };
  boardEditedEntityVar(value);
};

const closeAllEditModals = () => {
  const value = {
    ...boardEditedEntityInitialValue,
  };
  boardEditedEntityVar(value);
};

const resetEditedEntity = () => {
  const value = {
    ...boardEditedEntityInitialValue,
  };
  boardEditedEntityVar(value);
};

export {
  showEditActionModal,
  showEditNoteModal,
  showConvertNoteToActionModal,
  showConvertNoteToOutcomeModal,
  showEditOutcomeModal,
  showArchiveOutcomeModal,
  setIsNewInformationDrawerOpen,
  setUpdateOutcomeWithActionEntity,
  setShowFocusSettingsForActionId,
  updateFocusTimerCounter,
  closeAllEditModals,
  setDataChangeLinkModal,
  resetEditedEntity,
};
