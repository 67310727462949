import * as routePaths from 'core/constants/routePaths';
import { PageLinkProps } from '../types';

const links: PageLinkProps[] = [
  // {
  //   text: 'My Board',
  //   to: routePaths.ACTION_PATH,
  //   isVisibleForLogged: true,
  //   isVisibleForUnLogged: false,
  // },
  {
    text: 'Contact us',
    to: routePaths.CONTACT_US_PATH,
    isVisibleForLogged: true,
    isVisibleForUnLogged: true,
  },
  {
    text: 'F.A.Q.',
    to: routePaths.FAQ_PATH,
    isVisibleForLogged: true,
    isVisibleForUnLogged: true,
  },
  {
    text: 'Sign in',
    to: routePaths.SIGN_IN_PATH,
    isVisibleForLogged: false,
    isVisibleForUnLogged: true,
  },
];

export default links;
