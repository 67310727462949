import React, { FC } from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'

import Logo from './Logo'
import Menu from './Menu'
import theme from 'core/styles/styled-components/theme';

const StyledRow = styled(Row)`
  margin-bottom: 15px;

  @media ${theme.device.tablet.min} {
    margin-bottom: 60px;
  }

  @media ${theme.device.desktop.min} {
    margin-bottom: 80px;
  }
`

const Header: FC = () => {
  return (
    <StyledRow align="bottom" justify="space-between">
      <Col>
        <Logo />
      </Col>
      <Col>
        <Menu />
      </Col>
    </StyledRow>
  )
}

export default Header
