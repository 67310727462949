import React, {
  useEffect,
  useState,
  useContext,
  createContext,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast, ToastContent } from 'react-toastify';
import axios from 'axios';
import queryString from 'query-string';
import { firebase } from 'core/integrations/firebase/init';
import * as endpoints from 'core/integrations/api/endpoints';
import { LINKEDIN_REDIRECT_URI } from 'core/integrations/linkedin/config';
import { sendEvent } from 'core/integrations/sentry/events';
import {
  ACTION_PATH,
  SIGN_IN_PATH,
  LINKEDIN_REDIRECT_PATH,
  SIGN_UP_PATH,
  SET_NEW_PASSWORD_PATH,
  PASSWORD_RECOVERY_PATH,
  FAQ_PATH,
  CONTACT_US_PATH,
  ONBOARDING_PATH,
  USER_PROFILE_PATH,
  USER_PLANS_PATH,
  SET_EMAIL_PATH,
  ACCESS_SIGN_UP_PATH,
} from 'core/constants/routePaths';
import { API_GRAPHQL_URL } from '../core/integrations/api/config';
import {
  FirebaseError,
  authUser,
  initUserData,
  SignInData,
  SignUpData,
} from '../features/Auth/types';
import { parseJwt } from '../features/utils';
import {
  generatePasswordChangedEmail,
  generatePasswordRecoveryEmail,
  generateVerificationEmail,
  getUserInfoFromDB,
  setUserEmailVerified,
} from '../features/Auth/mutation';
import { randomIntFromInterval, setCookie } from '../core/utils/global';
import { useApolloClient, useQuery } from '@apollo/client';
import { setApolloLink } from 'apollo/client';
import useClearApolloFields from '../apollo/stateFields/useClearApolloFields';
import { isWebView } from '../features/utils/checkWebView';
import { PROD_DOMAIN } from '../core/constants/others';
import * as Sentry from '@sentry/browser';
import { authMutations } from '../apollo/stateFields/auth';
import { AUTH_STATE, AuthUser, AuthValue } from '../apollo/stateFields/auth/authFields';
import { userSettingsMutation } from '../apollo/stateFields/userSettings';

export interface IAuthContext {
  idToken: string | undefined;
  user: AuthUser;
  signIn: (user: firebase.User, newIdToken: string) => Promise<false | undefined>;
  signOut: (redirect?: string | null) => Promise<void>;
  signInWithEmail: (data: SignInData) => Promise<boolean>;
  signUpWithEmail: (data: SignUpData) => Promise<boolean>;
  signInWithFacebook: () => void;
  signInWithGoogle: () => void;
  signInWithSaml: (slug: string) => void;
  signInWithApple: () => void;
  sendEmailVerification: (email?: string, link?: string) => Promise<boolean>;
  sentPasswordResetLink: (email: string) => void;
  setNewPassword: (password: string, appToken?: string) => void;
  firebaseSignOut: () => void;
}

const AuthContext = createContext<Partial<IAuthContext>>({});

function AuthProvider({ children }: { children: any }) {
  const { clearCache } = useClearApolloFields();
  const { setDefaultWorkspaceId, setStartDefaultWorkspaceId } = userSettingsMutation;
  const { data } = useQuery(AUTH_STATE);
  const { authUser, isRegistered }: AuthValue = data?.auth;
  const {
    setUserId,
    setIsRegistered,
    setIsNewUser,
    setFirebaseUser,
    setIsAdmin,
    setIdToken: setStateIdToken,
  } = authMutations;

  const apolloClient = useApolloClient();
  const [idToken, setIdToken] = useState<string | undefined>(undefined);
  const [user, setUser] = useState<any>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const shouldSendUserInfo = useRef(true);
  const alreadyRegistered = useRef(false);
  const firebaseUser = firebase.auth().currentUser;

  const setLoginData = (isLogin: boolean) => {
    setCookie('bil-user', isLogin ? '1' : '0', 365, PROD_DOMAIN);
  };

  const signIn = useCallback(
    async (user: firebase.User, newIdToken: string) => {
      try {
        const currentUser = await firebase.auth().currentUser;
        const idTokenResult = await currentUser?.getIdTokenResult();
        const { uid, email: userEmail, displayName, photoURL } = user;
        const updatedUser = { uid, displayName, photoURL, email: userEmail };
        const pathname = window.location.pathname;
        const pathList = pathname.split('/');
        const email = window.localStorage.getItem('emailForSignIn');
        localStorage.setItem('user-email', userEmail || email || '');
        localStorage.removeItem(`${user?.email}-user-logged`);

        if (photoURL && idTokenResult?.signInProvider === 'password') {
          const fileImg = await fetch(photoURL).then((r) => r.blob());
          if (fileImg && fileImg.size !== 998) {
            // default images size from facebook
            updatedUser.photoURL = photoURL;
          } else {
            updatedUser.photoURL = '';
          }
        }

        if (isWebView()) {
          const redirectData = await firebase.auth().getRedirectResult();

          if (redirectData && redirectData?.credential?.signInMethod === 'facebook.com') {
            const user = redirectData.user;
            if (!user?.emailVerified) {
              const token = await user?.getIdToken();
              await setUserEmailVerified(token as string);
            }
            cleanLocalStorageData();
          }
        }

        if (pathname === SET_NEW_PASSWORD_PATH || pathname === PASSWORD_RECOVERY_PATH) {
          return;
        }

        if (idTokenResult?.signInProvider === 'facebook.com') {
          const photoURL = user.providerData.find((p) => p?.providerId === 'facebook.com')
            ?.photoURL as string;
          const fileImg = await fetch(photoURL).then((r) => r.blob());

          if (fileImg && fileImg.size !== 998) {
            // default images size from facebook
            updatedUser.photoURL = photoURL;
          } else {
            updatedUser.photoURL = '';
          }
        }

        if (idTokenResult?.signInProvider === 'custom') {
          updatedUser.displayName = idTokenResult?.claims.name;
          updatedUser.email = idTokenResult?.claims.email;
          updatedUser.photoURL = idTokenResult?.claims.photoURL;
        }

        if (idTokenResult?.signInProvider === 'password' && !currentUser?.emailVerified) {
          return false;
        }

        if (email) {
          return false;
        }

        if (!updatedUser?.email) {
          updatedUser.email = idTokenResult?.claims.name || 'UnsetUserEmail';
        }

        const initUser = await axios.post(
          `${API_GRAPHQL_URL}`,
          {
            query: `mutation {
                initUser {
                  isActivated
                  id
                  email
                  firstName
                  lastName
                  name
                  isRegistered
                  externalIdentity
                  isAdmin
                  workspace {
                    id
                  }
                }
              }`,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              authorization: `${newIdToken}`,
            },
          },
        );
        updateUserEmailAndNames(initUser?.data?.data?.initUser, currentUser!, newIdToken);
        const unassignedId = initUser?.data?.data?.initUser?.workspace?.id;
        const isRegistered = initUser?.data?.data?.initUser?.isRegistered;
        const isActivated = initUser?.data?.data?.initUser?.isActivated;
        const isAdmin = initUser?.data?.data?.initUser?.isAdmin;
        setIsNewUser(!isActivated);
        setIsRegistered(isRegistered);
        setUserId(initUser?.data?.data?.initUser?.id);
        setStartDefaultWorkspaceId(unassignedId);
        if (!isActivated) {
          setDefaultWorkspaceId(unassignedId);
        }
        const firebaseUserData = {
          uid:
            updatedUser?.uid ||
            currentUser?.uid ||
            initUser?.data?.data?.initUser?.externalIdentity ||
            user.uid,
          email: updatedUser?.email || currentUser?.email || user.email || undefined,
          displayName:
            updatedUser?.displayName ||
            currentUser?.displayName ||
            initUser?.data?.data?.initUser?.name ||
            user.displayName ||
            undefined,
          photoURL: updatedUser?.photoURL || currentUser?.photoURL || undefined,
        };
        setFirebaseUser(firebaseUserData);
        setUser(firebaseUserData);
        setIdToken(newIdToken);
        setStateIdToken(newIdToken);
        if (isAdmin) {
          setIsAdmin(true);
        }

        const { data: userSettings } = await axios.post(
          `${API_GRAPHQL_URL}`,
          {
            query: `query fetchUserSettings {
                  fetchUserSettings {
                   userPreferences {
                    roles
                    title
                   }
                  }
                }`,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              authorization: `${newIdToken}`,
            },
          },
        );

        const isPreferencesExist =
          userSettings.data?.fetchUserSettings?.userPreferences[0]?.roles?.length;

        clearSignInFlagForActiveTab();

        setLoginData(true);

        if (!isActivated && !isPreferencesExist) {
          history.push(ONBOARDING_PATH);
          return;
        }

        if (
          // !(pathList.length > 2 && (pathList[1] === 'pages' || pathList[1] === 'set-new-password'))
          ![
            ACTION_PATH,
            CONTACT_US_PATH,
            FAQ_PATH,
            USER_PROFILE_PATH,
            PASSWORD_RECOVERY_PATH,
            SET_NEW_PASSWORD_PATH,
            SET_EMAIL_PATH,
            USER_PLANS_PATH,
          ].some((path) => path === pathname)
        ) {
          history.push(ACTION_PATH);
        }

        if (currentUser) {
          sendEvent('user-sign-in', '', {
            id: currentUser.uid,
            email: currentUser.email,
            time: new Date(),
          });
        }
      } catch (error) {
        console.error(error);
        toast(error as ToastContent);
      }
    },
    [history, idToken, authUser],
  );

  const signOut = useCallback(
    async (path?: string | null) => {
      setLoginData(false);
      localStorage.setItem('sign-out', 'true');
      localStorage.removeItem(`${authUser?.email}-user-logged`);
      localStorage.removeItem(`${authUser?.email}-user-registered`);
      localStorage.removeItem(`user-email`);
      await apolloClient.clearStore();
      await firebase.auth().signOut();
      clearCache();
      setIdToken(undefined);
      shouldSendUserInfo.current = true;
      localStorage.removeItem('sign-out');
      if (path) {
        history.push(path);
      } else if (path === undefined) {
        history.push(SIGN_IN_PATH);
      }
    },
    [history, firebase, authUser],
  );

  const checkRoute = (pathname: string, route: string): boolean => {
    return pathname.indexOf(route) >= 0;
  };

  const getSharePlatform = () => {
    const sharePlatform = localStorage.getItem('share');

    return sharePlatform ? sharePlatform : 'none';
  };

  const idTokenWatcher = useCallback(
    async (u) => {
      const pathname = window.location.pathname;
      if (u) {
        const newIdToken = await u.getIdToken();
        const userEmail = u?.email;

        if (
          idToken &&
          idToken !== newIdToken &&
          !!userEmail &&
          u?.emailVerified &&
          userEmail !== 'unknown' &&
          pathname !== SET_EMAIL_PATH
        ) {
          setIdToken(newIdToken);
          setStateIdToken(newIdToken);
        }
        if (
          !idToken &&
          checkActiveTab() &&
          !!userEmail &&
          u?.emailVerified &&
          userEmail !== 'unknown' &&
          pathname !== SET_EMAIL_PATH
        ) {
          await signIn(u, newIdToken);
        }

        if (u && pathname === LINKEDIN_REDIRECT_PATH && newIdToken) {
          !firebase?.auth()?.currentUser?.emailVerified && (await setUserEmailVerified(newIdToken));
          await signIn(u, newIdToken);
        }
      } else {
        if (
          pathname !== LINKEDIN_REDIRECT_PATH &&
          pathname !== SIGN_IN_PATH &&
          pathname !== SIGN_UP_PATH &&
          pathname !== ACCESS_SIGN_UP_PATH &&
          pathname !== PASSWORD_RECOVERY_PATH &&
          pathname !== SET_EMAIL_PATH &&
          pathname !== FAQ_PATH &&
          pathname !== CONTACT_US_PATH &&
          !checkRoute(pathname, SET_NEW_PASSWORD_PATH)
        ) {
          await signOut();
        }
      }

      setLoaded(true);
    },
    [location.pathname, idToken],
  );

  useEffect(() => {
    if (loaded && shouldSendUserInfo.current && authUser) {
      if (!isRegistered) {
        setTimeout(() => {
          if (!localStorage.getItem(`${authUser?.email}-user-registered`)) {
            localStorage.setItem(`${authUser?.email}-user-registered`, 'true');
            if (!alreadyRegistered.current) {
              Sentry.setTag('sharePlatform', getSharePlatform());
              sendEvent('user-registered', 'User registered');
              localStorage.removeItem('share');
              alreadyRegistered.current = true;
            }
          }
        }, randomIntFromInterval(1, 3000)); // fix for duplicate tabs
      } else {
        setTimeout(() => {
          if (!localStorage.getItem(`${authUser?.email}-user-logged`)) {
            localStorage.setItem(`${authUser?.email}-user-logged`, 'true');
            sendEvent('user-logged', 'User logged');
          }
        }, randomIntFromInterval(1, 3000)); // fix for duplicate tabs
      }
      shouldSendUserInfo.current = false;
    }
  }, [loaded, isRegistered, authUser]);

  useEffect(() => {
    let unsubscribe: any;
    try {
      unsubscribe = firebase.auth().onIdTokenChanged(idTokenWatcher);
    } catch (error) {
      console.error(error);
      toast(error as ToastContent);
    }
    return () => unsubscribe();
  }, [idToken]);

  useEffect(() => {
    if (location.pathname === LINKEDIN_REDIRECT_PATH) {
      const { code, error, error_description } = queryString.parse(location.search);

      if (error) {
        console.error(`${error}: ${error_description}`);
        toast.error(`${error}: ${error_description}`);
        history.push(SIGN_IN_PATH);
        return;
      }

      if (code) {
        signInWithLinkedIn(code as string).then();
      }
    }
  }, [location.pathname, location.search, history]);

  useEffect(() => {
    if (idToken) {
      apolloClient.setLink(setApolloLink(idToken));
    } else {
      clearCache();
    }
  }, [idToken]);

  const getFirstNameAndLastName = (displayName?: string | null) => {
    let value = {
      firstName: '',
      lastName: '',
    };
    if (displayName) {
      const separateData = displayName.split(' ');
      if (separateData.length === 1) {
        value.firstName = separateData[0];
      }
      if (separateData.length === 2) {
        value.firstName = separateData[0];
        value.lastName = separateData[1];
      }
    }
    return value;
  };

  const checkActiveTab = () => {
    const sessionData = sessionStorage.getItem('sign-in-data');
    const localData = localStorage.getItem('sign-in-data');
    if (localData) {
      return localData === sessionData;
    }
    return true;
  };

  const setSignInFlagForActiveTab = () => {
    const value = `${Date.now()}`;
    sessionStorage.setItem('sign-in-data', value);
    localStorage.setItem('sign-in-data', value);
  };

  const clearSignInFlagForActiveTab = () => {
    setTimeout(() => {
      sessionStorage.removeItem('sign-in-data');
      localStorage.removeItem('sign-in-data');
    }, 1000);
  };

  function cleanLocalStorageData() {
    window.localStorage.removeItem('emailForSignIn');
  }

  function showAuthMessage(code: string) {
    switch (code) {
      case 'auth/email-already-in-use':
        toast.error('User with this account already exist. Please select Go to Sign in');
        return true;
      case 'auth/user-not-found':
      case 'There is no user record corresponding to this identifier. The user may have been deleted.':
        toast.error('You have entered an incorrect email or password');
        return true;
      case 'An internal error has occurred. Raw server response: "{"error":{"code":400,"message":"TOO_MANY_ATTEMPTS_TRY_LATER","errors":[{"message":"TOO_MANY_ATTEMPTS_TRY_LATER","domain":"global","reason":"invalid"}]}}"':
        toast.error('Too many attempts have been made. Please try later');
        return true;
      default:
        return false;
    }
  }

  function getProviderForProviderId(id: string) {
    switch (id) {
      case 'google.com':
        return new firebase.auth.GoogleAuthProvider();
      default:
        return null;
    }
  }

  async function signInWithLinkedIn(linkedinCode: string) {
    try {
      setSignInFlagForActiveTab();
      const { data } = await axios.post(endpoints.getAuthToken, {
        linkedinCode,
        redirectUri: encodeURIComponent(LINKEDIN_REDIRECT_URI),
      });
      const credential = await firebase.auth().signInWithCustomToken(data);
      const parsedData = parseJwt(data);
      !firebase?.auth()?.currentUser?.emailVerified && (await setUserEmailVerified(data as string));

      cleanLocalStorageData();

      if (parsedData) {
        const { claims } = parsedData;
        const { email, photoURL, name } = claims;
        const { user } = credential;

        await user?.updateProfile({ displayName: name });

        if (!user?.photoURL) {
          await user?.updateProfile({ photoURL });
        }

        if (!user?.email) {
          await user?.updateEmail(email);
        }
      }
    } catch (error) {
      toast.error(error as ToastContent);
      console.error(error);
    }
  }

  async function signInWithEmail({ email, password }: SignInData) {
    try {
      setSignInFlagForActiveTab();
      const currentUser = await firebase.auth().currentUser;

      if (currentUser) {
        await firebase.auth().signOut();
      }
      const { user } = await firebase.auth().signInWithEmailAndPassword(email, password);
      cleanLocalStorageData();

      return !user?.emailVerified;
    } catch (error) {
      const { message = '', code } = error as FirebaseError;

      if (showAuthMessage(code) || showAuthMessage(message)) {
        // fix for other code
        return false;
      }

      toast.error('Invalid credentials');
      console.error(message);
      return false;
    }
  }

  async function signUpWithEmail({ email, password, firstName, lastName }: SignUpData) {
    try {
      const { user } = await firebase.auth().createUserWithEmailAndPassword(email, password);
      const displayName = `${firstName} ${lastName}`;

      await user?.updateProfile({ displayName });
      await firebase.auth().signOut();
      const res = await generateVerificationEmail(firstName, lastName, email);

      if (res.data.errors && res.data.errors.length) {
        if (showAuthMessage(res.data.errors[0].code)) {
          return false;
        }
        throw Error(res.data.errors[0].message);
      }
      return true;
    } catch (error) {
      await firebase.auth().signOut();
      const { message = '', code } = error as FirebaseError;

      if (showAuthMessage(code)) {
        return false;
      }

      toast.error(message as ToastContent);
      console.error(message);
      return false;
    }
  }

  async function signInWithGoogle() {
    try {
      setSignInFlagForActiveTab();
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account',
      });

      if (isWebView()) {
        await firebase.auth().signInWithRedirect(provider);
      } else {
        await firebase.auth().signInWithPopup(provider);
      }

      cleanLocalStorageData();
    } catch (error) {
      const { message = '' } = error as FirebaseError;
      toast.error(message as ToastContent);
      console.error(message);
    }
  }
  async function signInWithSaml(slug: string) {
    try {
      setSignInFlagForActiveTab();
      const provider = new firebase.auth.SAMLAuthProvider('saml.' + slug);
      firebase
        .auth()
        .signInWithPopup(provider)
        .catch((error) => {
          const { message = '' } = error as FirebaseError;
          toast.error(message as ToastContent);
        });
      cleanLocalStorageData();
    } catch (error) {
      const { message = '' } = error as FirebaseError;
      toast.error(message as ToastContent);
    }
  }

  async function signInWithApple() {
    try {
      setSignInFlagForActiveTab();
      const provider = new firebase.auth.OAuthProvider('apple.com');
      provider.setCustomParameters({
        prompt: 'select_account',
      });
      provider.addScope('email');
      provider.addScope('name');

      if (isWebView()) {
        await firebase.auth().signInWithRedirect(provider);
      } else {
        await firebase.auth().signInWithPopup(provider);
      }
      cleanLocalStorageData();
    } catch (error) {
      const { message = '' } = error as FirebaseError;
      toast.error(message as ToastContent);
      console.error(message);
    }
  }

  async function signInWithFacebook() {
    try {
      setSignInFlagForActiveTab();
      const provider = new firebase.auth.FacebookAuthProvider();

      const handleUserEmail = () => {
        cleanLocalStorageData();
        history.push(SET_EMAIL_PATH);
      };

      const handleLoginWithoutEmail = async (user: firebase.User | null) => {
        if (!user) {
          return;
        }
        const token = await user.getIdToken();
        if (!user?.emailVerified) {
          if (!user.email) {
            await handleUserEmail();
          } else {
            const userData = await getUserInfoFromDB(token);
            const isFbAccountWithPhone = userData?.data?.data?.fetchUser?.isFBaccountWithPhone;

            if (isFbAccountWithPhone) {
              handleUserEmail();
            } else {
              await setUserEmailVerified(token as string);
              await signIn(user, token);
            }
          }
        } else {
          if (!user.email) {
            await handleUserEmail();
          }
        }
      };

      if (isWebView()) {
        await firebase.auth().signInWithRedirect(provider);
        const user = firebase.auth().currentUser;
        await handleLoginWithoutEmail(user);
      } else {
        const { user } = await firebase.auth().signInWithPopup(provider);
        await handleLoginWithoutEmail(user);
      }
    } catch (error) {
      const { code = '' } = error as FirebaseError;
      if (code === 'auth/account-exists-with-different-credential') {
        const { email = '', credential } = error as FirebaseError;
        const methods = await firebase.auth().fetchSignInMethodsForEmail(email);
        const provider = getProviderForProviderId(methods[0]);
        if (provider) {
          const { user } = await firebase.auth().signInWithPopup(provider);
          await user?.linkAndRetrieveDataWithCredential(credential);

          const token = await user?.getIdToken();
          await setUserEmailVerified(token as string);
        } else {
          const { message = '' } = error as FirebaseError;
          toast.error(message as ToastContent);
          console.error(message);
        }
      } else {
        const { message = '' } = error as FirebaseError;
        toast.error(message as ToastContent);
        console.error(message);
      }
    }
  }

  async function sendEmailVerification(email?: string, link?: string) {
    const user = firebase.auth().currentUser;
    try {
      const displayName = getFirstNameAndLastName(user?.displayName);
      const res = await generateVerificationEmail(
        displayName.firstName,
        displayName.lastName,
        email ? email : (user?.email as string),
        link ? link : undefined,
      );
      await firebaseSignOut();

      if (res.data.errors && res.data.errors.length) {
        const error = res.data.errors[0].message;

        if (showAuthMessage(error)) {
          return false;
        }
        throw Error(error);
      }
      return true;
    } catch (error) {
      await firebaseSignOut();

      const { message = '' } = error as FirebaseError;
      toast.error(message as ToastContent);
      console.error(message);
      return false;
    }
  }

  async function updateUserEmailAndNames(
    initUserData: initUserData,
    authUser: authUser,
    idToken: string,
  ) {
    const { email = '', firstName = '', lastName = '', name = '' } = initUserData;
    let isChange = false;
    let data = {
      email,
      name,
    };

    if (
      !initUserData.email ||
      initUserData.email === 'unknown' ||
      initUserData.email === 'undefined'
    ) {
      data.email = authUser.email as string;
      isChange = true;
    }
    if (
      !initUserData.name ||
      initUserData.name === 'unknown' ||
      initUserData.name === 'undefined'
    ) {
      const userName = authUser.displayName || '';
      if (userName) {
        data.name = userName;
        isChange = true;
      }
    }

    if (isChange) {
      await axios.post(
        `${API_GRAPHQL_URL}`,
        {
          query: `mutation updateUser($userParams: UserInput!) {
                  updateUserEntity(userParams: $userParams) {
                   email
                   name
                  }
                }`,
          variables: {
            userParams: {
              ...data,
            },
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            authorization: `${idToken}`,
          },
        },
      );
    }
  }

  async function sentPasswordResetLink(email: string) {
    try {
      const res = await generatePasswordRecoveryEmail(email);
      if (res.data.errors && res.data.errors.length) {
        return true;
      } else {
        localStorage.setItem('emailForSignIn', email);
      }
    } catch (error) {
      const { message = '' } = error as FirebaseError;
      toast.error(message as ToastContent);
      console.error(message);
    }
  }

  async function firebaseSignOut() {
    await firebase.auth().signOut();
  }

  async function setNewPassword(password: string, appToken?: string) {
    try {
      const user = firebase.auth().currentUser;
      const token = await user?.getIdToken();

      await user?.updatePassword(password);
      await firebaseSignOut();
      const res = await generatePasswordChangedEmail(appToken ? appToken : (token as string));

      if (res.data.errors && res.data.errors.length) {
        throw Error(res.data.errors[0].message);
      }
    } catch (error) {
      const { message = '' } = error as FirebaseError;
      toast.error(message as ToastContent);
      console.error(message);
    }
  }

  const value = useMemo(() => {
    return {
      idToken,
      user,
      signIn,
      signOut,
      setIsNewUser,
      signInWithEmail,
      signUpWithEmail,
      signInWithGoogle,
      signInWithSaml,
      signInWithApple,
      signInWithFacebook,
      sendEmailVerification,
      sentPasswordResetLink,
      setNewPassword,
      firebaseSignOut,
    };
  }, [
    idToken,
    user,
    signIn,
    signOut,
    setIsNewUser,
    signInWithEmail,
    signUpWithEmail,
    signInWithApple,
    signInWithGoogle,
    signInWithSaml,
    signInWithFacebook,
    sendEmailVerification,
    sentPasswordResetLink,
    setNewPassword,
    firebaseSignOut,
  ]);

  if (!loaded) {
    return null;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth, AuthContext };
