import { useMutation } from '@apollo/client';
import { FETCH_ALL_NOTES, FETCH_NOTES } from '../../actions/graphql/queries';
import { UPDATE_NOTE } from '../../actions/graphql/mutations';
import { FetchUserWorkspaceNotes, FetchUserWorkspacesNotes, UpdateNote } from '../../actions/types';
import { OperationType, updatedCacheData } from '../../utils';
import { Note } from '../../../core/types/note';
import { ColumnType } from 'apollo/stateFields/dnd/dndFields';
import { dndDispatch } from '../../../context/dndContext/dndContext';
import { addEntityId, deleteEntityId } from '../../../context/dndContext/DndActions';

const useUpdateNote = (noteToUpdate: Note | null) => {
  const dispatch = dndDispatch;

  return useMutation<UpdateNote>(UPDATE_NOTE, {
    update(cache, { data }) {
      if (noteToUpdate === null) {
        return;
      }
      const { fetchUserWorkspacesNotes = [] } =
        cache.readQuery<FetchUserWorkspacesNotes>({
          query: FETCH_ALL_NOTES,
        }) || {};

      const { fetchUserWorkspaceNotes = [] } =
        cache.readQuery<FetchUserWorkspaceNotes>({
          query: FETCH_NOTES,
          variables: {
            workspaceId: data?.updateUserWorkspaceNote?.workspaceId,
          },
        }) || {};

      const operation =
        data?.updateUserWorkspaceNote?.isArchived === true
          ? OperationType.ARCHIVE
          : noteToUpdate.isArchived && !data?.updateUserWorkspaceNote.isArchived
          ? OperationType.CREATE
          : OperationType.UPDATE;

      cache.writeQuery<FetchUserWorkspacesNotes>({
        query: FETCH_ALL_NOTES,
        data: {
          fetchUserWorkspacesNotes: updatedCacheData(
            fetchUserWorkspacesNotes,
            data?.updateUserWorkspaceNote,
            operation,
          ),
        },
      });

      cache.writeQuery<FetchUserWorkspaceNotes>({
        query: FETCH_NOTES,
        variables: {
          workspaceId: data?.updateUserWorkspaceNote?.workspaceId,
        },
        data: {
          fetchUserWorkspaceNotes: updatedCacheData(
            fetchUserWorkspaceNotes,
            data?.updateUserWorkspaceNote,
            operation,
          ),
        },
      });

      if (data?.updateUserWorkspaceNote) {
        if (data.updateUserWorkspaceNote.isArchived && !noteToUpdate.isArchived) {
          dispatch(
            deleteEntityId({
              entityId: data.updateUserWorkspaceNote.id,
              columnTitle: ColumnType.Note,
            }),
          );
        }

        if (!data.updateUserWorkspaceNote.isArchived && noteToUpdate.isArchived) {
          dispatch(
            addEntityId({
              entityId: data?.updateUserWorkspaceNote.id,
              columnTitle: ColumnType.Note,
            }),
          );
        }
      }
    },
  });
};

export default useUpdateNote;
