import React, { useEffect } from 'react';
import { SharedPeopleIcon } from '../ShareWorkspace/SharedPeopleIcon/SharedPeopleIcon';
import TagFilterOption from './TagFilterOption';
import styled, { css } from 'styled-components';
import { ReactComponent as DotIcon } from '../../../../core/svg/dot.svg';
import { Workspace } from '../../../../core/types/workspace';
import { Filter } from '../../../utils';
import { ContextMenuOption } from '../../../../core/components/common/Card/types';
import { Tag } from '../../../../core/types/tag';
import { useQuery } from '@apollo/client';
import {
  USER_SETTINGS_STATE,
  UserSettingsValue,
} from '../../../../apollo/stateFields/userSettings/userSettingsField';
import useWorkspaceInvites from '../../../../hooks/useWorkspaceInvites';

const StyledWorkspaceTags = styled.div<{
  isDefault: boolean;
  isList?: boolean;
  isShared?: boolean;
}>`
  backdrop-filter: blur(10px);
  background-color: var(--color-bright-purple);
  border: 1px solid rgba(239, 238, 238, 0.12);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  align-items: center;
  display: flex;
  height: 30px;
  margin-right: 5px;
  padding: 4px 4px 4px 8px;

  ${({ isShared }) =>
    isShared &&
    css`
      background-color: #ffd870;
      border: 1px solid rgba(177, 190, 249, 0.4);
    `};

  ${({ isList }) =>
    isList &&
    css`
      margin-bottom: 6px;
      width: fit-content;
      align-items: flex-start;
      flex-direction: column;
      height: auto;
    `};
`;

const StyledWorkspaceTagsInner = styled.div`
  align-items: center;
  display: flex;
`;

const StyledWorkspaceNameText = styled.div`
  color: var(--color-dark-blue);
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
  max-width: 100px;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const StyledDefaultIcon = styled(({ isShared, ...rest }) => <DotIcon {...rest} />)<{
  isShared?: boolean;
}>`
  margin-right: 8px;
  circle {
    fill: #92a3f6;

    ${({ isShared }) =>
      isShared &&
      css`
        fill: #ecae13;
      `};
  }
`;

const TagFilterItem = React.memo(
  ({
    workspace,
    isList,
    index,
    itemsRef,
    filter,
    handleContextMenuOptionClick,
    renderTags,
    loadedCallback,
  }: {
    workspace: Workspace;
    isList?: boolean;
    index: number;
    itemsRef: React.MutableRefObject<HTMLDivElement[]>;
    filter: Filter;
    handleContextMenuOptionClick: (option: ContextMenuOption, workspace: Workspace) => void;
    renderTags: (filter: Filter, tags: Tag[], isList?: boolean) => any;
    loadedCallback: () => any;
  }) => {
    const { data: userSettingsData } = useQuery(USER_SETTINGS_STATE);
    const { defaultWorkspaceId }: UserSettingsValue = userSettingsData?.userSettingsField;
    const { getWorkspaceInvitesById } = useWorkspaceInvites();

    const tags = workspace.tags || [];
    const isDefault = defaultWorkspaceId === workspace.id;
    let sharedCount = getWorkspaceInvitesById(workspace.id)?.count || 0;

    useEffect(() => {
      if (getWorkspaceInvitesById(workspace.id)) {
        loadedCallback();
      }
    }, [workspace.id]);

    return (
      <StyledWorkspaceTags
        isList={isList}
        key={filter.workspaceId}
        ref={(el) => {
          if (itemsRef.current && !isList) {
            itemsRef.current[index] = el as HTMLDivElement;
          }
        }}
        isDefault={isDefault}
        isShared={sharedCount > 1}
      >
        <StyledWorkspaceTagsInner>
          {isDefault && <StyledDefaultIcon isShared={sharedCount > 1} />}
          <StyledWorkspaceNameText>{workspace.name}</StyledWorkspaceNameText>
          {!!sharedCount && sharedCount > 1 && <SharedPeopleIcon count={sharedCount} />}
          <TagFilterOption
            workspace={workspace}
            handleContextMenuOptionClick={handleContextMenuOptionClick}
          />
        </StyledWorkspaceTagsInner>
        {renderTags(filter, tags, isList)}
      </StyledWorkspaceTags>
    );
  },
);

export default TagFilterItem;
